import { FormikProps } from "formik";
import {
  ListItem,
  ListItemText,
  SwitchProps,
  TypographyProps,
} from "@mui/material";
import IOSSwitch from "./IOSSwitch";

import { getFieldValueByColumnNotation } from "../../helpers/utils";

interface Props {
  name: string;
  formik: FormikProps<any>;
  primaryText: string;
  secondaryText?: string;
  onChangeSwitch?: (checked: boolean) => void;
}
const FormikListItemSwitch = (props: Props & SwitchProps) => {
  const {
    name,
    formik,
    primaryText,
    secondaryText = "",
    onChangeSwitch,
    ...rest
  } = props;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    !!onChangeSwitch
      ? onChangeSwitch(checked)
      : formik.setFieldValue(name, checked);
  };
  const value = getFieldValueByColumnNotation(name, formik.values) || false;

  return (
    <ListItem className="list-item-field">
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        primaryTypographyProps={primaryTextTypographyProps}
        secondaryTypographyProps={secondaryTextTypographyProps}
      />
      <IOSSwitch checked={value} onChange={handleChange} {...rest} />
    </ListItem>
  );
};

export default FormikListItemSwitch;

const primaryTextTypographyProps:
  | TypographyProps<
      "span",
      {
        component?: "span" | undefined;
      }
    >
  | undefined = {
  variant: "subtitle1",
  className: "list-item-field-primary-text",
};

const secondaryTextTypographyProps:
  | TypographyProps<
      "span",
      {
        component?: "span" | undefined;
      }
    >
  | undefined = {
  variant: "subtitle2",
  className: "list-item-field-secondary-text",
};
