class IgType {
  id?: number;
  name?: string;
  code?: string;

  constructor(id?: number, name?: string, code?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  mapToClass(obj: any): IgType | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

class Category {
  id?: number;
  name?: string;
  code?: string;

  constructor(id?: number, name?: string, code?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  mapToClass(obj: any): Category | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

class QualityRegime {
  id?: number;
  name?: string;
  code?: string;
  igType?: IgType;
  category?: Category;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    igType?: IgType,
    category?: Category
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.igType = igType;
    this.category = category;
  }

  mapToClass(obj: any): QualityRegime | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.igType = new IgType().mapToClass(obj?.igType);
    this.category = new Category().mapToClass(obj?.category);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    obj.igType = this.igType?.mapToApiModel();
    obj.category = this.category?.mapToApiModel();
    return obj;
  }
}

export default QualityRegime;
