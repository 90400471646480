import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl } from "@mui/material";
import { OilBarrel } from "@mui/icons-material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";

import { Column } from "../../models/Generic";
import FertilizerTank from "../../models/fertiliz/FertilizerTank";

import { updateItemOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";

const ExploitationFertilizerTankScreen = () => {
  const FertilizerTankValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  const fertilizerTankColumns: Column[] = [
    {
      id: "name",
      label: i18n.t("fertilizerTanks.column.name"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const { data: fertilizerTanks, isFetching: isFetchingTanks } = useFetch<
    FertilizerTank[]
  >({
    queryKey: [
      "fertilizerTanks",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const handleSubmit = (values: FertilizerTank) => {
    switch (formMode) {
      case FormMode.CREATE:
        fertilizerTankCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        fertilizerTankEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new FertilizerTank(),
    validationSchema: FertilizerTankValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("fertilizerTanks.drawerTitleCreate")
      : i18n.t("fertilizerTanks.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const fertilizerTankCreateMutation = useCrud<FertilizerTank>({
    key: "postFertilizerTank",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizerTankEditMutation = useCrud<FertilizerTank>({
    key: "putFertilizerTank",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizerTankDeleteMutation = useCrud({
    key: "deleteFertilizerTanks",
    values: fertilizerTanks?.filter((ft: FertilizerTank) =>
      selectedRowIds.includes(ft?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmDeleteAlertDialog = () => {
    fertilizerTankDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetchingTanks} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("fertilizerTanks.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "fertilizerTanks.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={fertilizerTankDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("fertilizerTanks.tableTitle")}
        headerText={i18n.t("fertilizerTanks.headerText")}
        colums={fertilizerTankColumns}
        rows={fertilizerTanks}
        selectedRows={selectedRowIds}
        emptyTableIcon={<OilBarrel sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("fertilizerTanks.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("fertilizerTanks.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("fertilizerTanks.emptyTableBtnText")}
        addBtnVariant="contained"
        isLoading={isFetchingTanks}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          fertilizerTankCreateMutation.isLoading ||
          fertilizerTankEditMutation.isLoading
        }
        onClose={closeDrawer}
        onConfirm={handleClickSave}
      >
        <FertilizerTankForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? fertilizerTanks?.find(
                  (ft: FertilizerTank) => ft.id === selectedRowIds[0]
                )
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationFertilizerTankScreen;

interface FertilizerTankFormProps {
  formik: FormikProps<FertilizerTank>;
  selectedEditRow?: FertilizerTank;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const FertilizerTankForm = (props: FertilizerTankFormProps) => {
  const { formik, selectedEditRow, onError } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<FertilizerTank>({
    queryKey: ["fertilizerTank", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: FertilizerTank) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<FertilizerTank[]>(
        ["fertilizerTanks", selectedABPartition?.id, selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <DataAndWaterMixsForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const DataAndWaterMixsForm = (props: FertilizerTankFormProps) => {
  const { formik } = props;

  return (
    <BrioCard
      title={i18n.t("fertilizerTanks.dataForm.title")}
      defaultExpanded
      disableAccordion
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("fertilizerTanks.dataForm.nameLabel")}
            required
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
