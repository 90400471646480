import { FERTILIZER_PRODUCT_TYPES } from "../../providers/YupProvider";
import FBFertilizerProduct from "../_apiModels/fertiliz/FBFertilizerProduct";
import Document from "../files/Document";

export const MEASUREMENT_UNIT_CODES = ["3", "4", "5", "6"];

export interface FertilizerProductType {
  id: number;
  code: string;
  name: string;
}

class FertilizerProduct extends FBFertilizerProduct {
  productType?: FertilizerProductType;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.productType = FERTILIZER_PRODUCT_TYPES[0];
    this.documents = documents;
  }

  mapToClass(obj: any): FertilizerProduct | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.productType = obj?.registeredFertilizerProduct
      ? FERTILIZER_PRODUCT_TYPES[0]
      : FERTILIZER_PRODUCT_TYPES[1];
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Name is always required
    if (this.isRegistered()) obj.name = this.registeredFertilizerProduct?.name;

    // Delete custom variables
    delete obj.productType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isRegistered(): boolean {
    return this.productType?.code === "1";
  }
}

export default FertilizerProduct;
