import { createContext, useMemo, useContext, useState, useEffect } from "react";
import { Cookies } from "react-cookie";

import AlertSnackbar from "../components/elements/AlertSnackbar";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessPartition from "../models/agrobusiness/AgroBusinessPartition";
import PersonWithRole from "../models/account/PersonWithRole";

import useFetch from "./useFetch";

import { SnackbarInterface } from "../constants/interfaces";
import { useAuth } from "./useAuth";
import { FBPersonRole } from "../constants/enums";
import SiexRequestItem from "../models/siex/SiexRequestItem";

interface Session {
  agroBusinessAccounts: AgroBusinessAccount[];
  agroBusinessPartitions: AgroBusinessPartition[];
  agroBusinessAccountPermissions: PersonWithRole[];
  isLoadingPartitions: boolean;
  isFetchingPermissions: boolean;
  selectedABAccount: AgroBusinessAccount | null;
  selectedABPartition: AgroBusinessPartition | null;
  changeSelectedABA: (aba: AgroBusinessAccount) => void;
  changeSelectedABPartition: (abp: AgroBusinessPartition | null) => void;
  isUpdatingFromREA: boolean;
  setIsUpdatingFromREA: (value: boolean) => void;
  siexErrors: SiexRequestItem[];
  setSiexErrors: (value: SiexRequestItem[]) => void;
}

const SessionContext = createContext<Session>({} as Session);

interface Props {
  children: React.ReactNode;
  agroBusinessAccounts?: AgroBusinessAccount[] | null;
}
export const SessionProvider = ({
  children,
  agroBusinessAccounts = [],
}: Props) => {
  const { user, setUserRole } = useAuth();
  const cookies = new Cookies();

  const [selectedABAccount, setSelectedABAccount] =
    useState<AgroBusinessAccount | null>(null);
  const [selectedABPartition, setSelectedABPartition] =
    useState<AgroBusinessPartition | null>(null);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isUpdatingFromREA, setIsUpdatingFromREA] = useState<boolean>(false);
  const [siexErrors, setSiexErrors] = useState<SiexRequestItem[]>([]);

  useEffect(() => {
    // Get last saved from cookies if exits
    const lastSavedABAId = cookies.get("selectedABAId");
    if (lastSavedABAId) {
      const lastSavedABA = agroBusinessAccounts?.find(
        (aba) => aba.id === lastSavedABAId
      );
      if (lastSavedABA) return setSelectedABAccount(lastSavedABA);
    }
    setSelectedABAccount(agroBusinessAccounts?.[0] || null);
  }, [agroBusinessAccounts]);

  const { data: agroBusinessPartitions, isFetching: isLoadingPartitions } =
    useFetch<AgroBusinessPartition[]>({
      queryKey: ["agroBusinessPartitions", selectedABAccount?.agroBusiness?.id],
      refetchOnWindowFocus: false, // Input file not work if true
      selected: selectedABAccount,
      enabled: !!selectedABAccount?.agroBusiness?.id,
      onError: setSnackbarMsg,
    });

  useEffect(() => {
    // Get last saved from cookies if exits
    const lastSavedABPId = cookies.get("selectedABPId");
    const lastSavedABP =
      lastSavedABPId &&
      agroBusinessPartitions?.find((abp) => abp.id === lastSavedABPId);
    const selected =
      lastSavedABP ||
      (agroBusinessPartitions && agroBusinessPartitions[0]) ||
      null;
    setSelectedABPartition(selected);
  }, [agroBusinessPartitions]);

  const {
    data: agroBusinessAccountPermissions,
    isFetching: isFetchingPermissions,
  } = useFetch<PersonWithRole[]>({
    queryKey: ["agroBusinessAccountPermissions", selectedABAccount?.id],
    refetchOnWindowFocus: false, // Input file not work if true
    selected: selectedABAccount,
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  // Update agroBusinessAccount user role
  useEffect(() => {
    const personWithRole = agroBusinessAccountPermissions?.find(
      (pwr) => pwr.person?.id === user?.person?.id
    );
    const personRole = personWithRole?.personRole?.role;
    setUserRole(personRole ? personRole : FBPersonRole.NONE);
  }, [agroBusinessAccountPermissions]);

  // Trigger the useFetch
  const changeSelectedABA = (aba: AgroBusinessAccount) => {
    setSelectedABAccount(aba);
    // Save last selection in cookies
    if (aba) {
      cookies.set("selectedABAId", aba.id, { path: "/" });
      cookies.remove("selectedABPId", { path: "/" }); // Reset selected ABP
    }
  };

  // The useFetch of screens depend of selectedABPartition
  const changeSelectedABPartition = (abp: AgroBusinessPartition | null) => {
    setSelectedABPartition(abp);
    // Save last selection in cookies
    if (abp) cookies.set("selectedABPId", abp.id, { path: "/" });
  };

  const value = useMemo(
    () => ({
      agroBusinessAccounts: agroBusinessAccounts || [],
      agroBusinessPartitions: agroBusinessPartitions || [],
      agroBusinessAccountPermissions: agroBusinessAccountPermissions || [],
      selectedABAccount,
      selectedABPartition,
      changeSelectedABA,
      changeSelectedABPartition,
      isLoadingPartitions,
      isFetchingPermissions,
      isUpdatingFromREA,
      setIsUpdatingFromREA,
      siexErrors,
      setSiexErrors,
    }),
    [
      agroBusinessAccounts,
      agroBusinessPartitions,
      agroBusinessAccountPermissions,
      selectedABAccount,
      selectedABPartition,
      changeSelectedABA,
      changeSelectedABPartition,
      isLoadingPartitions,
      isFetchingPermissions,
      isUpdatingFromREA,
      setIsUpdatingFromREA,
      siexErrors,
      setSiexErrors,
    ]
  );

  return (
    <SessionContext.Provider value={value}>
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = (): Session => {
  return useContext<Session>(SessionContext);
};
