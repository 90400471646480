import Facility from "../../agrobusiness/Facility";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import PhytosanitaryProductType from "../../catalogue/PhytosanitaryProductType";

class FBPhytosanitaryProductStock {
  id?: number;
  type?: PhytosanitaryProductType;
  registerId?: string;
  name?: string;
  owner?: string;
  formulation?: string;
  measurementUnit?: MeasurementUnit;
  expirationDate?: string;
  enabledDate?: string;
  disabledDate?: string;
  stock?: number;
  facility?: Facility;
  agroBusinessPartition?: AgroBusinessPartition;

  constructor(
    id?: number,
    type?: PhytosanitaryProductType,
    registerId?: string,
    name?: string,
    owner?: string,
    formulation?: string,
    measurementUnit?: MeasurementUnit,
    expirationDate?: string,
    enabledDate?: string,
    disabledDate?: string,
    stock?: number,
    facility?: Facility,
    agroBusinessPartition?: AgroBusinessPartition
  ) {
    this.id = id;
    this.type = type;
    this.registerId = registerId;
    this.name = name;
    this.owner = owner;
    this.formulation = formulation;
    this.measurementUnit = measurementUnit;
    this.expirationDate = expirationDate;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.stock = stock;
    this.facility = facility;
    this.agroBusinessPartition = agroBusinessPartition;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.type = new PhytosanitaryProductType().mapToClass(obj?.type);
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.owner = obj?.owner;
    this.formulation = obj?.formulation;
    this.measurementUnit = new MeasurementUnit().mapToClass(
      obj?.measurementUnit
    );
    this.expirationDate = obj?.expirationDate;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.stock = obj?.stock;
    this.facility = new Facility().mapToClass(obj?.facility);
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.measurementUnit = this.measurementUnit?.mapToApiModel();
    obj.facility = this.facility?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryProductStock;
