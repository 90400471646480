import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";

import QualityRegime from "../../models/catalogue/QualityRegime";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

interface DialogQualityRegimeProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (qualityRegimes: QualityRegime[]) => void;
  selected?: QualityRegime[];
}

const DialogQualityRegime = (props: DialogQualityRegimeProps) => {
  const YupValidatorSchema = Yup.object().shape({
    qualityRegimes: Yup.array().min(1, i18n.t("formErrors.requiredField")),
  });

  const { open, onClose, onConfirm, selected } = props;
  const { selectedABAccount } = useSession();

  const handleSubmit = (values: any) => {
    onConfirm(values.qualityRegimes);
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      qualityRegimes: selected || [],
    },
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setFieldValue("qualityRegimes", selected || []);
  }, [selected]);

  const { data: qualityRegimes, isFetching } = useFetch<QualityRegime[]>({
    queryKey: ["qualityRegimes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleClose = () => {
    formik.setFieldValue("qualityRegimes", selected || []);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogQualityRegime.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogQualityRegime.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="qualityRegimes"
              label={i18n.t("components.dialogQualityRegime.qualityRegimes")}
              placeholder={i18n.t(
                "components.dialogQualityRegime.qualityRegimesPlaceholder"
              )}
              multiple
              required
              options={qualityRegimes || []}
              optionLabelFieldName="name"
              groupBy={(option) => option.category?.name || ""}
              loading={isFetching}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected && selected.length > 0
            ? i18n.t("words.update")
            : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogQualityRegime;
