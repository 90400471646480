import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import FormikTextField from "../elements/FormikTextField";

import Sector from "../../models/agrobusiness/Sector";

import useCrud from "../../hooks/useCrud";

import { SnackbarInterface } from "../../constants/interfaces";
import { SIGPAC_MAP_URL } from "../../constants/constants";

const validateSigpacCode = (values: Sector) => {
  const errors: any = {};
  if (values.sigpacCode && values.sigpacCode.length > 0) {
    const sigpacCode = values.sigpacCode?.split(":");
    const filteredSigpacCode = sigpacCode?.filter(
      (code: string) => code?.length > 0
    );
    if (!filteredSigpacCode || filteredSigpacCode.length < 2) {
      errors.sigpacCode = i18n.t("formErrors.sigpacCode1");
    } else if (
      values.sigpacCode &&
      values.sigpacCode.replaceAll(":", "").length > 14
    ) {
      errors.sigpacCode = i18n.t("formErrors.sigpacCode2");
    }
  }
  return errors;
};

interface DialogSectorProps {
  open: boolean;
  onClose: () => void;
  selected?: Sector | null;
  onMessage?: (snackBarMessage: SnackbarInterface) => void;
}

const DialogSector = (props: DialogSectorProps) => {
  const YupValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
    area: Yup.number().required(),
  });

  const { open, onClose, onMessage, selected } = props;

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: Sector) => {
    selected
      ? sectorEditMutation.mutate(values)
      : sectorCreateMutation.mutate(values);
  };

  const formik = useFormik({
    initialValues: selected || new Sector(),
    validate: validateSigpacCode,
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setValues(selected || new Sector());
  }, [selected]);

  const sectorCreateMutation = useCrud<Sector>({
    key: "postSector",
    values: formik.values,
    onSuccess: closeDialog,
    onError: onMessage,
  });

  const sectorEditMutation = useCrud<Sector>({
    key: "putSector",
    values: formik.values,
    onSuccess: closeDialog,
    onError: onMessage,
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleChangeSigpacCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remove no numeric characters except ":"
    const newValue = value.replace(/[^0-9:]/g, "");
    formik.setFieldValue("sigpacCode", newValue);
  };

  const SigpacLink = () => (
    <Link
      style={helperTextStyle}
      sx={{
        color: (theme) => `${theme.palette.primary.light} !important`,
      }}
      href={SIGPAC_MAP_URL}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      {i18n.t("sectors.sectorTab.locationForm.sigpacLink")}
    </Link>
  );

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("onBoarding.sectors.dialog.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("onBoarding.sectors.dialog.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="name"
              label={i18n.t("sectors.sectorTab.locationForm.name")}
              required
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="sigpacCode"
              label={i18n.t("sectors.sectorTab.locationForm.sigpacCode")}
              placeholder={i18n.t(
                "sectors.sectorTab.locationForm.sigpacCodePlaceholder"
              )}
              onChange={handleChangeSigpacCode}
            />
            <SigpacLink />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="area"
              label={i18n.t("sectors.sectorTab.areaForm.area")}
              type="number"
              required
              valueUnit="ha"
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="primary"
          disabled={
            sectorCreateMutation.isLoading || sectorEditMutation.isLoading
          }
        >
          {i18n.t("words.cancel")}
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          color="primary"
          loading={
            sectorCreateMutation.isLoading || sectorEditMutation.isLoading
          }
        >
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSector;

const helperTextStyle: React.CSSProperties = {
  fontSize: 12,
  margin: 4,
  marginBottom: 0,
  color: "#c62828",
};
