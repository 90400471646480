import { PlantProblemTypeInterface } from "../../constants/interfaces";
import { PLANT_PROBLEM_TYPES } from "../../providers/YupProvider";
import FBPlantProblem from "../_apiModels/catalogue/FBPlantProblem";

class PlantProblem extends FBPlantProblem {
  problemType?: PlantProblemTypeInterface;

  constructor(problemType?: PlantProblemTypeInterface) {
    super();
    this.problemType = problemType;
  }

  mapToClass(obj: any): PlantProblem | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.problemType = PLANT_PROBLEM_TYPES.find(
      (type) => type.code === this.type
    );
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.type = this.problemType?.code;

    delete obj.problemType;
    return obj;
  }
}

export default PlantProblem;
