import FBCueExportData from "../_apiModels/cueExport/FBCueExportData";
import AgroBusinessPartition from "../agrobusiness/AgroBusinessPartition";
import Crop from "../crop/Crop";

class CueExportData extends FBCueExportData {
  crops?: Crop[];
  agroBusinessPartition?: AgroBusinessPartition;

  constructor() {
    super();
  }

  mapToClass(obj: any): CueExportData | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.cropIds = this.crops?.map((crop) => crop.id);
    const partitionId = this.agroBusinessPartition?.id;
    obj.partitionId = partitionId === -1 ? null : partitionId;

    delete obj.crops;
    delete obj.agroBusinessPartition;

    return obj;
  }
}

export default CueExportData;
