import FBSector from "../_apiModels/agrobusiness/FBSector";
import Document from "../files/Document";

import { LESSOR_TENURES_CODES } from "../../constants/lists";

class Sector extends FBSector {
  isCadastralRef?: boolean;
  sigpacCode?: string;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(
    isCadastralRef?: boolean,
    sigpacCode?: string,
    documents?: Document[]
  ) {
    super();
    this.isCadastralRef = isCadastralRef || false;
    this.sigpacCode = sigpacCode;
    this.documents = documents;
  }

  mapToClass(obj: any): Sector | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.isCadastralRef = !!this.cadastralRef;
    this.sigpacCode = this.getSigpacCode();
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    if (!this.isCadastralRef) obj.cadastralRef = null;
    if (this.sigpacCode) {
      const [
        provinceCode = null,
        townshipCode = null,
        aggregateCode = null,
        zoneCode = null,
        polygonCode = null,
        sectorCode = null,
        enclosureCode = null,
      ] = this.sigpacCode?.split(":") || [];
      obj.provinceCode = provinceCode;
      obj.townshipCode = townshipCode;
      obj.aggregateCode = aggregateCode;
      obj.zoneCode = zoneCode;
      obj.polygonCode = polygonCode;
      obj.sectorCode = sectorCode;
      obj.enclosureCode = enclosureCode;
    } else {
      obj.provinceCode = null;
      obj.townshipCode = null;
      obj.aggregateCode = null;
      obj.zoneCode = null;
      obj.polygonCode = null;
      obj.sectorCode = null;
      obj.enclosureCode = null;
    }
    // Remove custom variables
    delete obj.isCadastralRef;
    delete obj.sigpacCode;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isLessor(): boolean {
    return LESSOR_TENURES_CODES.includes(this.tenureType?.code || "");
  }

  getSigpacCode(): string {
    let sigpacCode = "";
    if (this.provinceCode !== null) sigpacCode += String(this.provinceCode);
    if (this.townshipCode !== null)
      sigpacCode += `:${String(this.townshipCode)}`;
    if (this.aggregateCode !== null)
      sigpacCode += `:${String(this.aggregateCode)}`;
    if (this.zoneCode !== null) sigpacCode += `:${String(this.zoneCode)}`;
    if (this.polygonCode !== null) sigpacCode += `:${String(this.polygonCode)}`;
    if (this.sectorCode !== null) sigpacCode += `:${String(this.sectorCode)}`;
    if (this.enclosureCode !== null)
      sigpacCode += `:${String(this.enclosureCode)}`;
    return sigpacCode;
  }
}

export default Sector;
