import Entity from "../../agrobusiness/Entity";
import Address from "../../address/Address";

class FBOwnerRepresentative {
  id?: number;
  entity?: Entity; //*
  address?: Address; //*
  enabledDate?: string;
  disabledDate?: string;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    entity?: Entity,
    address?: Address,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.entity = entity;
    this.address = address;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.entity = new Entity().mapToClass(obj?.entity);
    this.address = new Address().mapToClass(obj?.address);
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.entity = this.entity?.mapToApiModel();
    obj.address = this.address?.mapToApiModel();
    return obj;
  }
}

export default FBOwnerRepresentative;
