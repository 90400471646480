import AgroBusiness from "../../agrobusiness/AgroBusiness";
import CueAccount from "../../cueAccount/CueAccount";
import LegalContext from "../../LegalContext";
import RegisterRegion from "../../RegisterRegion";

class FBAgroBusinessAccount {
  id?: number;
  name?: string;
  cueAccount?: CueAccount;
  ownerId?: string;
  siexEnabled?: boolean;
  siexAutoSync?: boolean;
  userRequestSiexSync?: boolean;
  registerRegion?: RegisterRegion;
  context?: LegalContext;
  agroBusiness?: AgroBusiness;

  constructor(
    id?: number,
    name?: string,
    cueAccount?: CueAccount,
    ownerId?: string,
    siexEnabled?: boolean,
    siexAutoSync?: boolean,
    userRequestSiexSync?: boolean,
    registerRegion?: RegisterRegion,
    context?: LegalContext,
    agroBusiness?: AgroBusiness
  ) {
    this.id = id;
    this.name = name;
    this.cueAccount = cueAccount;
    this.ownerId = ownerId;
    this.siexEnabled = siexEnabled;
    this.siexAutoSync = siexAutoSync;
    this.userRequestSiexSync = userRequestSiexSync;
    this.registerRegion = registerRegion;
    this.context = context;
    this.agroBusiness = agroBusiness;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.cueAccount = new CueAccount().mapToClass(obj?.cueAccount);
    this.ownerId = obj?.ownerId;
    this.siexEnabled = obj?.siexEnabled;
    this.siexAutoSync = obj?.siexAutoSync;
    this.userRequestSiexSync = obj?.userRequestSiexSync;
    this.registerRegion = new RegisterRegion().mapToClass(obj?.registerRegion);
    this.context = new LegalContext().mapToClass(obj?.context);
    this.agroBusiness = new AgroBusiness().mapToClass(obj?.agroBusiness);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.cueAccount = this.cueAccount?.mapToApiModel();
    obj.registerRegion = this.registerRegion?.mapToApiModel();
    obj.context = this.context?.mapToApiModel();
    obj.agroBusiness = this.agroBusiness?.mapToApiModel();
    return obj;
  }
}

export default FBAgroBusinessAccount;
