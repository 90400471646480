import Crop from "../../crop/Crop";
import CropIrrigation from "../../crop/CropIrrigation";
import FertilizerStockMovement from "../../fertiliz/FertilizerStockMovement";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import FertigationWaterMix from "../../fertiliz/FertigationWaterMix";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import FertilizationType from "../../catalogue/FertilizationType";
import FertilizerApplicationMethod from "../../catalogue/FertilizerApplicationMethod";
import FertilizationGoodPracticeType from "../../catalogue/FertilizationGoodPracticeType";
import FertilizationSector from "../../fertiliz/FertilizationSector";
import Tool from "../../agrobusiness/Tool";
import FertilizationPlan from "../../fertiliz/FertilizationPlan";
import { FBESiexRequestStatus } from "../../../constants/enums";

class FBFertilization {
  id?: number;
  crop?: Crop; //*
  startDate?: string;
  endDate?: string;
  stockMovements?: FertilizerStockMovement[];
  applicationArea?: number;
  dose?: number;
  doseUnit?: MeasurementUnit; //*
  type?: FertilizationType; //*
  applicationMethod?: FertilizerApplicationMethod; //*
  fertilizationPlanUsed?: boolean;
  wasteAuthorizationNumber?: string;
  sewageSludgeApplication?: boolean;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  adviceDate?: string;
  fertilizerProviderName?: string;
  fertilizerProviderId?: string;
  cropIrrigation?: CropIrrigation;
  fertigationWaterMix?: FertigationWaterMix;
  declareGoodPractices?: boolean;
  goodPractices?: FertilizationGoodPracticeType[];
  fertilizedSectors?: FertilizationSector[];
  applicator?: AgroBusinessPhytosanitaryInvolvedEntity;
  tool?: Tool;
  fertilizationPlan?: FertilizationPlan;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    crop?: Crop,
    startDate?: string,
    endDate?: string,
    stockMovements?: FertilizerStockMovement[],
    applicationArea?: number,
    dose?: number,
    doseUnit?: MeasurementUnit,
    type?: FertilizationType,
    applicationMethod?: FertilizerApplicationMethod,
    fertilizationPlanUsed?: boolean,
    wasteAuthorizationNumber?: string,
    sewageSludgeApplication?: boolean,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    adviceDate?: string,
    fertilizerProviderName?: string,
    fertilizerProviderId?: string,
    cropIrrigation?: CropIrrigation,
    fertigationWaterMix?: FertigationWaterMix,
    declareGoodPractices?: boolean,
    goodPractices?: FertilizationGoodPracticeType[],
    fertilizedSectors?: FertilizationSector[],
    applicator?: AgroBusinessPhytosanitaryInvolvedEntity,
    tool?: Tool,
    fertilizationPlan?: FertilizationPlan
  ) {
    this.id = id;
    this.crop = crop;
    this.startDate = startDate;
    this.endDate = endDate;
    this.stockMovements = stockMovements;
    this.applicationArea = applicationArea;
    this.dose = dose;
    this.doseUnit = doseUnit;
    this.type = type;
    this.applicationMethod = applicationMethod;
    this.fertilizationPlanUsed = fertilizationPlanUsed;
    this.wasteAuthorizationNumber = wasteAuthorizationNumber;
    this.sewageSludgeApplication = sewageSludgeApplication;
    this.advisor = advisor;
    this.adviceDate = adviceDate;
    this.fertilizerProviderName = fertilizerProviderName;
    this.fertilizerProviderId = fertilizerProviderId;
    this.cropIrrigation = cropIrrigation;
    this.fertigationWaterMix = fertigationWaterMix;
    this.declareGoodPractices = declareGoodPractices;
    this.goodPractices = goodPractices;
    this.fertilizedSectors = fertilizedSectors;
    this.applicator = applicator;
    this.tool = tool;
    this.fertilizationPlan = fertilizationPlan;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.stockMovements = obj?.stockMovements.map((stockMovement: any) =>
      new FertilizerStockMovement().mapToClass(stockMovement)
    );
    this.applicationArea = obj?.applicationArea;
    this.dose = obj?.dose;
    this.doseUnit = new MeasurementUnit().mapToClass(obj?.doseUnit);
    this.type = new FertilizationType().mapToClass(obj?.type);
    this.applicationMethod = new FertilizerApplicationMethod().mapToClass(
      obj?.applicationMethod
    );
    this.fertilizationPlanUsed = obj?.fertilizationPlanUsed;
    this.wasteAuthorizationNumber = obj?.wasteAuthorizationNumber;
    this.sewageSludgeApplication = obj?.sewageSludgeApplication;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.adviceDate = obj?.adviceDate;
    this.fertilizerProviderName = obj?.fertilizerProviderName;
    this.fertilizerProviderId = obj?.fertilizerProviderId;
    this.cropIrrigation = new CropIrrigation().mapToClass(obj?.cropIrrigation);
    this.fertigationWaterMix = new FertigationWaterMix().mapToClass(
      obj?.fertigationWaterMix
    );
    this.declareGoodPractices = obj?.declareGoodPractices;
    this.goodPractices = obj?.goodPractices.map((goodPractice: any) =>
      new FertilizationGoodPracticeType().mapToClass(goodPractice)
    );
    this.fertilizedSectors =
      obj?.fertilizedSectors
        ?.map((fertilizedSector: any) =>
          new FertilizationSector().mapToClass(fertilizedSector)
        )
        ?.filter(
          (fertilizedSector: FertilizationSector) =>
            !fertilizedSector?.sector?.disabledDate
        ) || [];
    this.applicator = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.applicator
    );
    this.tool = new Tool().mapToClass(obj?.tool);
    this.fertilizationPlan = new FertilizationPlan().mapToClass(
      obj?.fertilizationPlan
    );
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.crop = this.crop?.mapToApiModel();
    obj.stockMovements = this.stockMovements?.map(
      (stockMovement: FertilizerStockMovement) => stockMovement.mapToApiModel()
    );
    obj.doseUnit = this.doseUnit?.mapToApiModel();
    obj.type = this.type?.mapToApiModel();
    obj.applicationMethod = this.applicationMethod?.mapToApiModel();
    obj.advisor = this.advisor?.mapToApiModel();
    obj.cropIrrigation = this.cropIrrigation?.mapToApiModel();
    obj.fertigationWaterMix = this.fertigationWaterMix?.mapToApiModel();
    obj.goodPractices = this.goodPractices?.map(
      (goodPractice: FertilizationGoodPracticeType) =>
        goodPractice.mapToApiModel()
    );
    obj.fertilizedSectors = this.fertilizedSectors?.map(
      (fertilizedSector: FertilizationSector) =>
        fertilizedSector.mapToApiModel()
    );
    obj.applicator = this.applicator?.mapToApiModel();
    obj.tool = this.tool?.mapToApiModel();
    obj.fertilizationPlan = this.fertilizationPlan?.mapToApiModel();
    return obj;
  }

  isFertirrigation(): boolean {
    return ["5", "6"].includes(this.applicationMethod?.code || "");
  }
}

export default FBFertilization;
