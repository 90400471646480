import FBGipPreventiveAction from "../_apiModels/phyto/FBGipPreventiveAction";
import Document from "../files/Document";

class GipPreventiveAction extends FBGipPreventiveAction {
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): GipPreventiveAction | undefined {
    if (!obj) return undefined;
    super.mapToBaseClass(obj);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    // Remove custom variables
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default GipPreventiveAction;
