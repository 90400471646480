import Person from "../../Person";
import { FBPersonRole } from "../../../constants/enums";

class FBPersonWithRole {
  person?: Person;
  role?: FBPersonRole;

  constructor(person?: Person, role?: FBPersonRole) {
    this.person = person;
    this.role = role;
  }

  mapToBaseClass(obj: any): void {
    this.person = new Person().mapToClass(obj?.person);
    this.role = obj?.role;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.person = this.person;
    obj.role = this.role;
    return obj;
  }
}

export default FBPersonWithRole;
