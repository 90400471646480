import { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";

import { Receipt } from "@mui/icons-material";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import DialogPolicies from "../components/dialogs/DialogPolicies";

import Person from "../models/Person";
import Notification from "../models/notification/Notification";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "../routes/routeNames";
import { gTagOptionsInterface } from "../constants/interfaces";
import {
  MAX_VISIBLE_NOTIFICATIONS,
  REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
} from "../constants/constants";
import { getQueryClientKey } from "../helpers/utils";

const ProtectedLayout = () => {
  const { user, setUserPerson } = useAuth();
  const {
    agroBusinessAccounts,
    selectedABAccount,
    setIsUpdatingFromREA,
    changeSelectedABA,
    setSiexErrors,
  } = useSession();
  const queryQlient = useQueryClient();
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [scrollOnTop, setScrollOnTop] = useState(true);
  const [isOpenPoliciesDialog, setIsOpenPoliciesDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: unreadNotifications } = useFetch<Notification[]>({
    queryKey: ["notifications", selectedABAccount?.id],
    refetchInterval: REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
    enabled: !!selectedABAccount,
  });

  // Update last selected AB account siexUpdatedOnMillis
  const handleUpdateFromREA = () => {
    const updatedABAccount = new AgroBusinessAccount().mapToClass({
      ...selectedABAccount,
    });
    if (updatedABAccount && updatedABAccount.agroBusiness) {
      updatedABAccount.agroBusiness.siexUpdatedOnMillis = Date.now();
      changeSelectedABA(updatedABAccount);
    }
    const currentPath = window.location.pathname;
    const currentQueryKey = getQueryClientKey(currentPath);
    queryQlient.invalidateQueries([currentQueryKey]);
  };

  // Download SIEX data if available
  const { isFetching: isUpdatingFromREA } = useFetch({
    queryKey: ["updateFromREA", selectedABAccount?.agroBusiness?.id],
    enabled:
      !!selectedABAccount?.agroBusiness?.id &&
      !!selectedABAccount?.agroBusiness?.siexEntity,
    onSuccess: handleUpdateFromREA,
  });

  // Get SIEX errors if siex is active
  useFetch({
    queryKey: ["siexErrors", selectedABAccount?.agroBusiness?.id],
    enabled: !!selectedABAccount?.agroBusiness?.id,
    onSuccess: setSiexErrors,
  });

  // Update session variable
  useEffect(() => {
    setIsUpdatingFromREA(isUpdatingFromREA);
  }, [isUpdatingFromREA]);

  const markNotificationAsReadMutation = useCrud({
    key: "markNotificationAsRead",
  });

  useEffect(() => {
    // Initialize GA4 if production environment and not master user
    if (user) {
      const user_id = user?.person?.id;
      const traffic_type = user?.person?.master ? "internal" : "organic";
      const options: gTagOptionsInterface = { user_id, traffic_type };
      if (process.env.REACT_APP_ENV !== "prod")
        options.debug_mode = "debug_mode";
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA4_ID || "",
          gaOptions: options,
          gtagOptions: options,
        },
      ]);
    }

    // Show dialog if user has not accepted a privacy policy update
    setIsOpenPoliciesDialog(
      !!user?.person?.pendingPrivacyPolicyUrl &&
        !!user?.person?.pendingPrivacyPolicyVersion
    );
  }, [user]);

  // Set GA4 page view
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  // Show phyto recipes notifications and mark them as read
  useEffect(() => {
    const showNotifications = () => {
      // Show maximum notifications at the same time
      const visibleNotifications =
        unreadNotifications &&
        unreadNotifications.length > MAX_VISIBLE_NOTIFICATIONS
          ? unreadNotifications.slice(-MAX_VISIBLE_NOTIFICATIONS)
          : unreadNotifications;

      visibleNotifications?.map((notification) =>
        toast(notification.text || "", {
          id: String(notification.id),
          position: "top-right",
          icon: <Receipt />,
          style: {
            background: "#d1ffa0",
          },
          duration: 5000,
        })
      );

      // Mark notifications as read
      unreadNotifications?.map((notification) =>
        markNotificationAsReadMutation.mutate(notification)
      );
    };

    showNotifications();
  }, [unreadNotifications]);

  const handleOnClick = () => {
    setIsSidebarOpen((state) => !state);
  };

  const handleOnCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleBodyScroll = (e: any) => {
    const scrollPos = e.target.scrollTop;
    setScrollOnTop(scrollPos === 0);
  };

  const handleConfirmPolicies = () => {
    const person = user?.person;
    if (person) {
      setIsLoading(true);
      const version = person?.pendingPrivacyPolicyVersion;
      fetch(
        `${process.env.REACT_APP_API_URL}/person/acceptPrivacyPolicy?version=${version}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${user?.idToken}` },
        }
      )
        .then(async (response: Response) => {
          if (response.status === 200) {
            const data = await response.json();
            const updatedPerson = new Person().mapToClass(data);
            setUserPerson(updatedPerson);
          }
          setIsLoading(false);
          setIsOpenPoliciesDialog(false);
        })
        .catch(() => {
          setIsOpenPoliciesDialog(false);
          setIsLoading(false);
        });
    }
  };

  if (!user?.idToken) {
    return <Navigate to={PUBLIC_ROUTES.LOGIN} replace />;
  }

  if (user?.idToken && agroBusinessAccounts.length === 0)
    return (
      <>
        <Outlet />
        <Navigate to={PROTECTED_ROUTES.ONBOARDING} replace />
      </>
    );

  return (
    <>
      <DialogPolicies
        open={isOpenPoliciesDialog}
        isLoading={isLoading}
        onConfirm={handleConfirmPolicies}
        onClose={() => setIsOpenPoliciesDialog(false)}
      />
      <Navbar onClick={handleOnClick} scrollOnTop={scrollOnTop} />
      <div className="dashboard-container">
        <div className="dashboard-content">
          <div className="dashboard-sidebar">
            <Sidebar
              isOpen={isSidebarOpen}
              setIsOpen={setIsSidebarOpen}
              onClose={handleOnCloseSidebar}
            />
          </div>
          <div className="dashboard-body" onScroll={handleBodyScroll}>
            <div className="dashboard-body-content">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectedLayout;
