import FBAgroBusinessPartition from "../_apiModels/agrobusiness/FBAgroBusinessPartition";

class AgroBusinessPartition extends FBAgroBusinessPartition {
  constructor() {
    super();
  }

  mapToClass(obj: any): AgroBusinessPartition | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    if (!this.id || this.id < 0) return null; // If id == -1, means all partitions and we set to null
    return obj;
  }
}

export default AgroBusinessPartition;
