import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    // Removes default breakpoints
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    // Add custom breakpoints (follow Material Design 3)
    small: true;
    medium: true;
    large: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      small: 0,
      medium: 600,
      large: 840,
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#3b6a00",
    },
    secondary: {
      main: "#57624A",
    },
    error: {
      main: "#c62828",
    },
    mode: "light",
  },
});

export default theme;
