import Entity from "../../agrobusiness/Entity";
import ProfessionalTrainingType from "../../catalogue/ProfessionalTrainingType";

class FBManager {
  id?: number;
  entity?: Entity; //*
  agroWorkUnits?: number;
  professionalTrainingType?: ProfessionalTrainingType;
  enabledDate?: string;
  disabledDate?: string;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    entity?: Entity,
    agroWorkUnits?: number,
    professionalTrainingType?: ProfessionalTrainingType,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.entity = entity;
    this.agroWorkUnits = agroWorkUnits;
    this.professionalTrainingType = professionalTrainingType;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.entity = new Entity().mapToClass(obj?.entity);
    this.agroWorkUnits = obj?.agroWorkUnits;
    this.professionalTrainingType = new ProfessionalTrainingType().mapToClass(
      obj?.professionalTrainingType
    );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.entity = this.entity?.mapToApiModel();
    obj.professionalTrainingType =
      this.professionalTrainingType?.mapToApiModel();
    return obj;
  }
}

export default FBManager;
