import { useEffect } from "react";
import { FormikProps } from "formik";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl } from "@mui/material";

import { BrioCard } from "../../components/BrioCard";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";

import AgroBusinessPartition from "../../models/agrobusiness/AgroBusinessPartition";

import { getFieldValueByColumnNotation } from "../../helpers/utils";

interface Props {
  name: string;
  formik: FormikProps<any>;
  agroBusinessPartitions: AgroBusinessPartition[];
}
const PartitionForm = (props: Props) => {
  const { name, formik, agroBusinessPartitions } = props;

  const allPartitionsOption = new AgroBusinessPartition();
  allPartitionsOption.id = -1;
  allPartitionsOption.name = i18n.t("words.unassigned");

  // Set no partitions as default value if there aren't partitions or is editing a sector without partition attached
  useEffect(() => {
    const value = getFieldValueByColumnNotation(name, formik.values);
    if (!value) formik.setFieldValue(name, allPartitionsOption);
  }, [formik.values]);

  return (
    <BrioCard title={i18n.t("components.partitionForm.title")}>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name={name}
            options={[allPartitionsOption, ...agroBusinessPartitions]}
            label={i18n.t("components.partitionForm.label")}
            optionLabelFieldName="name"
            placeholder={i18n.t("components.partitionForm.placeholder")}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

export default PartitionForm;
