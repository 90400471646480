import { useState, useEffect } from "react";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  createFilterOptions,
  FormGroup,
  FormControl,
} from "@mui/material";

import AgroBusinessPartition from "../../models/agrobusiness/AgroBusinessPartition";

const filter = createFilterOptions<AgroBusinessPartition>();

interface DialogChoosePartitionProps {
  open: boolean;
  options: AgroBusinessPartition[];
  onClose: () => void;
  onConfirm: (agroBusinessPartition: AgroBusinessPartition) => void;
  selected: AgroBusinessPartition | null;
}
const DialogChoosePartition = (props: DialogChoosePartitionProps) => {
  const { open, onClose, onConfirm, options, selected } = props;

  const [newSelected, setNewSelected] = useState<AgroBusinessPartition | null>(
    selected
  );
  useEffect(() => {
    setNewSelected(selected);
  }, [selected]);

  const optionsFilter = (options: AgroBusinessPartition[], params: any) => {
    const filtered = filter(options, params);
    // Configuration option
    const configOption = new AgroBusinessPartition().mapToClass({
      id: 0,
      name: i18n.t("components.dialogChoosePartition.configPartitions"),
    });
    if (configOption) filtered.push(configOption);
    return filtered;
  };

  const handleChangeSelected = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessPartition | null
  ) => {
    if (newValue) {
      if (newValue.id === 0) {
        onConfirm(newValue);
        onClose();
      } else setNewSelected(newValue);
    }
  };

  const handleConfirm = () => {
    if (newSelected) {
      onConfirm(newSelected);
      onClose();
    }
  };

  const handleClose = () => {
    setNewSelected(selected);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} className="dialog">
      <DialogTitle className="title">
        {i18n.t("formErrors.selectOneOption")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <Autocomplete
              id="exploitations-navbar"
              className="exploitation-select"
              options={options}
              filterOptions={optionsFilter}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText={i18n.t("formErrors.notFoundResults")}
              value={newSelected}
              onChange={handleChangeSelected}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={i18n.t("components.dialogChoosePartition.partition")}
                  placeholder={i18n.t(
                    "components.dialogChoosePartition.partitionPlaceholder"
                  )}
                />
              )}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {i18n.t("words.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChoosePartition;
