class FBVadeProductOtherName {
  id?: number;
  otherName?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.otherName = obj?.otherName;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeProductOtherName;
