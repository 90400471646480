import CropPhytosanitaryTreatment from "../../phyto/CropPhytosanitaryTreatement";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";

class FBPhytosanitaryTreatmentAdvisor {
  id?: number;
  cropTreatment?: CropPhytosanitaryTreatment; //*
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity; //*
  validationDate?: string;
  validationDetail?: string;
  validationConfirmed?: boolean;
  withContract?: boolean;

  constructor(
    id?: number,
    cropTreatment?: CropPhytosanitaryTreatment,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    validationDate?: string,
    validationDetail?: string,
    validationConfirmed?: boolean,
    withContract?: boolean
  ) {
    this.id = id;
    this.cropTreatment = cropTreatment;
    this.advisor = advisor;
    this.validationDate = validationDate;
    this.validationDetail = validationDetail;
    this.validationConfirmed = validationConfirmed;
    this.withContract = withContract;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.cropTreatment = new CropPhytosanitaryTreatment().mapToClass(
      obj?.cropTreatment
    );
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.validationDate = obj?.validationDate;
    this.validationDetail = obj?.validationDetail;
    this.validationConfirmed = obj?.validationConfirmed;
    this.withContract = obj?.withContract;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.cropTreatment = this.cropTreatment?.mapToApiModel();
    obj.advisor = this.advisor?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryTreatmentAdvisor;
