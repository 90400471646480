import FBTool from "../_apiModels/agrobusiness/FBTool";
import Document from "../files/Document";

class Tool extends FBTool {
  idRegistered?: boolean;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(idRegistered?: boolean, documents?: Document[]) {
    super();
    this.idRegistered = idRegistered;
    this.documents = documents;
  }

  mapToClass(obj: any): Tool | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idRegistered = !!(this.registerId || this.aeroRegisterId);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    if (!this.idRegistered) {
      obj.registerId = null;
      obj.aeroRegisterId = null;
    } else obj.referenceId = null;
    if (!this.ownership) obj.purchaseDate = null;

    // Remove custom variables
    delete obj.idRegistered;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default Tool;
