import { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";

let timeout: NodeJS.Timeout;

interface Props {
  isVisible: boolean;
  delay?: number; // Delay in milliseconds
}
const LoadingWithDelay = (props: Props) => {
  const { isVisible, delay = 300 } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    clearTimeout(timeout);
    if (isVisible)
      timeout = setTimeout(() => {
        setIsLoading(isVisible);
      }, delay);
    else setIsLoading(false);

    return () => clearTimeout(timeout);
  }, [isVisible]);

  return isLoading ? <LinearProgress /> : null;
};

export default LoadingWithDelay;
