import { PlantProblemType } from "../../../constants/enums";

class FBPlantProblem {
  id?: number;
  type?: PlantProblemType;
  name?: string;
  code?: string;
  description?: string;
  scientificName?: string;
  eppoCode?: string;
  hierarchicalCode?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    type?: PlantProblemType,
    name?: string,
    code?: string,
    description?: string,
    scientificName?: string,
    eppoCode?: string,
    hierarchicalCode?: string,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.code = code;
    this.description = description;
    this.scientificName = scientificName;
    this.eppoCode = eppoCode;
    this.hierarchicalCode = hierarchicalCode;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.type = obj?.type;
    this.name = obj?.name;
    this.code = obj?.code;
    this.description = obj?.description;
    this.scientificName = obj?.scientificName;
    this.eppoCode = obj?.eppoCode;
    this.hierarchicalCode = obj?.hierarchicalCode;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBPlantProblem;
