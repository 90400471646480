class FBCueAccount {
  id?: number;
  name?: string;
  maxAgroBusiness?: number;
  managerUnitId?: string;
  advisor?: boolean;

  constructor(
    id?: number,
    name?: string,
    maxAgroBusiness?: number,
    managerUnitId?: string,
    advisor?: boolean
  ) {
    this.id = id;
    this.name = name;
    this.maxAgroBusiness = maxAgroBusiness;
    this.managerUnitId = managerUnitId;
    this.advisor = advisor;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.maxAgroBusiness = obj?.maxAgroBusiness;
    this.managerUnitId = obj?.managerUnitId;
    this.advisor = obj?.advisor;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBCueAccount;
