import { AutoFixHigh } from "@mui/icons-material";
import { Button } from "@mui/material";
import i18n from "../../config/configI18n";

import { useSession } from "../../hooks/useSession";

interface Props {
  onSubscribeRequested?: () => void;
  onFinishTutorial?: () => void;
}
const OnBoardingSiexBanner = (props: Props) => {
  const { onSubscribeRequested, onFinishTutorial } = props;

  const { selectedABAccount } = useSession();
  const isSubscribeRequested = selectedABAccount?.userRequestSiexSync;

  if (isSubscribeRequested)
    return (
      <div className="onboarding-siex-banner onboarding-siex-banner__requested">
        <div className="content">
          <div className="header">
            <AutoFixHigh className="icon" />
            <h1>{i18n.t("components.onBoardingSiexBanner.titleRequested")}</h1>
          </div>
          <p>{i18n.t("components.onBoardingSiexBanner.p1Requested")}</p>
          <p>{i18n.t("components.onBoardingSiexBanner.p2Requested")}</p>
          <p>{i18n.t("components.onBoardingSiexBanner.p3Requested")}</p>
          <Button
            variant="contained"
            className="btn"
            size="large"
            onClick={onFinishTutorial}
          >
            {i18n.t("components.onBoardingSiexBanner.btnLabelRequested")}
          </Button>
        </div>
      </div>
    );

  return (
    <div className="onboarding-siex-banner">
      <div className="content">
        <div className="header">
          <AutoFixHigh className="icon" />
          <h1> {i18n.t("components.onBoardingSiexBanner.title")}</h1>
        </div>
        <p>{i18n.t("components.onBoardingSiexBanner.p1")}</p>
        <p>{i18n.t("components.onBoardingSiexBanner.p2")}</p>
        <p>{i18n.t("components.onBoardingSiexBanner.p3")}</p>
        <Button
          variant="contained"
          className="btn"
          size="large"
          onClick={onSubscribeRequested}
        >
          {i18n.t("components.onBoardingSiexBanner.btnLabel")}
        </Button>
      </div>
    </div>
  );
};

export default OnBoardingSiexBanner;
