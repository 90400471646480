class FBRegisterRegion {
  id?: number;
  code?: string;
  name?: string;
  registerEndPointUrl?: string;

  constructor(
    id?: number,
    code?: string,
    name?: string,
    registerEndPointUrl?: string
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.registerEndPointUrl = registerEndPointUrl;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.code = obj?.code;
    this.name = obj?.name;
    this.registerEndPointUrl = obj?.registerEndPointUrl;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBRegisterRegion;
