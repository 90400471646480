import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import AlertEmptyList from "../AlertEmptyList";

import AgroBusinessPhytosanitaryInvolvedEntity from "../../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import PhytosanitaryTreatmentApplicator from "../../models/phyto/PhytosanitaryTreatmentApplicator";
import Tool from "../../models/agrobusiness/Tool";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface DialogTreatmentApplicatorProps {
  open: boolean;
  isSiexActive: boolean;
  onClose: () => void;
  onConfirm: (applicator: PhytosanitaryTreatmentApplicator) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  selected?: PhytosanitaryTreatmentApplicator | null;
  applicators?: PhytosanitaryTreatmentApplicator[];
}

const filter = createFilterOptions();
const DialogTreatmentApplicator = (props: DialogTreatmentApplicatorProps) => {
  const {
    open,
    isSiexActive,
    onClose,
    onConfirm,
    onError,
    selected,
    applicators = [],
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] = useState<
    "applicator" | "tool" | false
  >(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const validateForm = (values: PhytosanitaryTreatmentApplicator) => {
    const errors: any = {};
    if (!values.applicator)
      errors.applicator = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.tools || values.tools.length === 0)
        errors.tools = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: PhytosanitaryTreatmentApplicator) => {
    // Find the max idx
    const maxIdx = getMaxValueOfList(applicators, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new PhytosanitaryTreatmentApplicator(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: involvedEntities, isFetching: isFetchingEntities } = useFetch<
    AgroBusinessPhytosanitaryInvolvedEntity[]
  >({
    queryKey: [
      "phytoApplicatorInvolvedEntities",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  const { data: tools, isFetching: isFetchingTools } = useFetch<Tool[]>({
    queryKey: ["tools", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const openRedirectAlertDialog = (type: "applicator" | "tool") =>
    setIsOpenRedirectAlertDialog(type);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewApplicator = () => openRedirectAlertDialog("applicator");
  const handleClickNewTool = () => openRedirectAlertDialog("tool");

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(
      `/${
        isOpenRedirectAlertDialog === "applicator"
          ? PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES
          : PROTECTED_ROUTES.EXPLOITATION_TOOLS
      }`
    );
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  // Filter selected involved entities
  const involvedEntitiesFilter = (
    options: AgroBusinessPhytosanitaryInvolvedEntity[],
    params: any
  ) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (involvedEntity: any) =>
        !applicators.find(
          (applicator) => applicator?.applicator?.id === involvedEntity.id
        )
    );
    if (selected)
      filterSelected.unshift(
        selected.applicator as AgroBusinessPhytosanitaryInvolvedEntity
      );
    return filterSelected;
  };

  return (
    <Dialog open={open} onClose={closeDialog} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogTreatmentApplicator.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogTreatmentApplicator.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetchingEntities && involvedEntities?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewApplicator}>
                {i18n.t(
                  "components.dialogTreatmentApplicator.emptyListApplicator"
                )}
              </AlertEmptyList>
            )}
            <FormikAutocomplete
              formik={formik}
              name="applicator"
              label={i18n.t("components.dialogTreatmentApplicator.applicator")}
              optionLabelFieldName="entity.nameAndSurname"
              required
              filterOptions={involvedEntitiesFilter}
              options={involvedEntities || []}
              loading={isFetchingEntities}
              addNewOption
              onClickNewOption={handleClickNewApplicator}
              disabled={!!selected}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            {!isFetchingTools && tools?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewTool}>
                {i18n.t("components.dialogTreatmentApplicator.emptyListTool")}
              </AlertEmptyList>
            )}
            <FormikAutocomplete
              formik={formik}
              name="tools"
              label={i18n.t("components.dialogTreatmentApplicator.tools")}
              optionLabelFieldName="name"
              multiple
              options={tools || []}
              loading={isFetchingTools}
              addNewOption
              required={isSiexActive}
              onClickNewOption={handleClickNewTool}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogTreatmentApplicator.redirectAlertDialogTitle"
        )}
        contentText={
          isOpenRedirectAlertDialog === "applicator"
            ? i18n.t(
                "components.dialogTreatmentApplicator.redirectApplicatorsAlertDialogContentText"
              )
            : i18n.t(
                "components.dialogTreatmentApplicator.redirectToolsAlertDialogContentText"
              )
        }
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogTreatmentApplicator;
