import { PhytosanitaryTrainingLevelInterface } from "../../constants/interfaces";
import { PHYTOSANITARY_TRAINING_LEVELS } from "../../providers/YupProvider";
import FBAgroBusinessPhytosanitaryInvolvedEntity from "../_apiModels/agrobusiness/FBAgroBusinessPhytosanitaryInvolvedEntity";
import Document from "../files/Document";

class AgroBusinessPhytosanitaryInvolvedEntity extends FBAgroBusinessPhytosanitaryInvolvedEntity {
  trainingLevelType?: PhytosanitaryTrainingLevelInterface;
  isAdvisorUser?: boolean;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(
    documents?: Document[],
    trainingLevelType?: PhytosanitaryTrainingLevelInterface
  ) {
    super();
    this.trainingLevelType = trainingLevelType;
    this.documents = documents;
  }

  mapToClass(obj: any): AgroBusinessPhytosanitaryInvolvedEntity | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (obj.trainingLevel)
      this.trainingLevelType = PHYTOSANITARY_TRAINING_LEVELS.find(
        (trainingLevelType) => trainingLevelType.code === obj.trainingLevel
      );
    this.isAdvisorUser = !!obj?.advisorUser?.id;
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.trainingLevel = this.trainingLevelType?.code;

    // Remove custom variables
    delete obj.trainingLevelType;
    delete obj.isAdvisorUser;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default AgroBusinessPhytosanitaryInvolvedEntity;
