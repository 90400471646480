import FertilizerStockMovement from "../../fertiliz/FertilizerStockMovement";

class FBFertilizerProductPurchase {
  id?: number;
  date?: string;
  providerName?: string;
  providerId?: number;
  invoiceNumber?: string;
  stockMovements?: FertilizerStockMovement[];

  constructor(
    id?: number,
    date?: string,
    providerName?: string,
    providerId?: number,
    invoiceNumber?: string,
    stockMovements?: FertilizerStockMovement[]
  ) {
    this.id = id;
    this.date = date;
    this.providerName = providerName;
    this.providerId = providerId;
    this.invoiceNumber = invoiceNumber;
    this.stockMovements = stockMovements;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.date = obj?.date;
    this.providerName = obj?.providerName;
    this.providerId = obj?.providerId;
    this.invoiceNumber = obj?.invoiceNumber;
    if (obj?.stockMovements) {
      this.stockMovements = obj.stockMovements.map((stockMovement: any) =>
        new FertilizerStockMovement().mapToClass(stockMovement)
      );
    }
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.stockMovements = this.stockMovements?.map(
      (stockMovement: FertilizerStockMovement) => {
        const stockMovementObj: any = stockMovement.mapToApiModel();
        // Copy purchase date
        stockMovementObj.date = this.date;
        return stockMovementObj;
      }
    );
    return obj;
  }
}

export default FBFertilizerProductPurchase;
