import { Badge, Tooltip, styled, BadgeProps } from "@mui/material";
import { AttachmentOutlined } from "@mui/icons-material";
import i18n from "../../config/configI18n";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 4,
    top: 4,
  },
}));

interface Props {
  nDocuments?: number;
}
const AttachmentDocumentsBadge = (props: Props) => {
  const { nDocuments = 0 } = props;
  return (
    <Tooltip title={`${nDocuments} ${i18n.t("words.attachedFiles")}`}>
      <StyledBadge
        badgeContent={nDocuments}
        color="primary"
        sx={{ mr: 1 }}
        showZero
      >
        <AttachmentOutlined color="primary" fontSize="large" />
      </StyledBadge>
    </Tooltip>
  );
};

export default AttachmentDocumentsBadge;
