import FBCropIrrigationFertilizerTank from "../_apiModels/crop/FBCropIrrigationFertilizerTank";

class CropIrrigationFertilizerTank extends FBCropIrrigationFertilizerTank {
  idx?: number;

  constructor(idx?: number) {
    super();
    this.idx = idx;
  }

  mapToClass(obj: any): CropIrrigationFertilizerTank | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Remove custom variables
    delete obj.idx;
    return obj;
  }
}

export default CropIrrigationFertilizerTank;
