import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
  Alert,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import AlertEmptyList from "../AlertEmptyList";
import FormAlertDialog from "./FormAlertDialog";

import Sector from "../../models/agrobusiness/Sector";
import CropSector from "../../models/crop/CropSector";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface DialogCropSectorProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (cropSector: CropSector) => void;
  selected?: CropSector | null;
  cropSectors?: CropSector[];
}

const filter = createFilterOptions();
const DialogCropSector = (props: DialogCropSectorProps) => {
  const YupValidatorSchema = Yup.object().shape({
    sector: Yup.object().required(),
  });

  const { open, onClose, onConfirm, selected, cropSectors = [] } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: CropSector) => {
    // Find the max idx of the cropSectors
    const maxIdx = getMaxValueOfList(cropSectors, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new CropSector(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
  };

  const handleChangeSector = (sector: Sector) => {
    formik.setFieldValue("sector", sector);
  };

  const handleChangeArea = (event: any) => {
    const area = event.target.value || 0;
    if (formik.values?.sector?.area && area > formik.values.sector.area)
      event.target.value = formik.values.sector.area;
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  // Filter selected sectors
  const sectorsFilter = (options: Sector[], params: any) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (s: any) => !cropSectors.find((cs) => cs.sector?.id === s.id)
    );
    if (selected) filterSelected.unshift(selected.sector as Sector);
    return filterSelected;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("components.dialogCropSector.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogCropSector.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {sectors?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewOption}>
                {i18n.t("components.dialogCropSector.emptyListSectors")}
              </AlertEmptyList>
            )}
            {sectors &&
              sectors.length > 0 &&
              sectors.length === cropSectors?.length && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {i18n.t("components.dialogCropSector.noMoreSectors")}
                </Alert>
              )}
            <FormikAutocomplete
              formik={formik}
              name="sector"
              label={i18n.t("components.dialogCropSector.sector")}
              optionLabelFieldName="name"
              required
              filterOptions={sectorsFilter}
              options={sectors || []}
              loading={isFetching}
              disabled={!!selected}
              addNewOption
              onClickNewOption={handleClickNewOption}
              onChange={handleChangeSector}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <FormikTextField
              formik={formik}
              className="form-input-row-60"
              name="area"
              label={i18n.t("components.dialogCropSector.area")}
              helperText={i18n.t("components.dialogCropSector.areaHelperText")}
              type="number"
              valueUnit="ha"
              inputProps={{ min: 0, max: formik.values.sector?.area || 0 }}
              onInput={handleChangeArea}
            />
            <FormikTextField
              formik={formik}
              className="form-input-row-40"
              name="sector.area"
              label={i18n.t("components.dialogCropSector.totalArea")}
              helperText={i18n.t(
                "components.dialogCropSector.totalAreaHelperText"
              )}
              type="number"
              valueUnit="ha"
              disabled
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="plantNumber"
              label={i18n.t("components.dialogCropSector.plantNumber")}
              helperText={i18n.t(
                "components.dialogCropSector.plantNumberHelperText"
              )}
              type="number"
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t("components.dialogCropSector.redirectAlertDialogTitle")}
        contentText={i18n.t(
          "components.dialogCropSector.redirectAlertDialogContentText"
        )}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogCropSector;
