import moment from "moment";

import i18n from "../../config/configI18n";
import FBPhytoRecipeStatusHistory from "../_apiModels/phytoRecipe/FBPhytoRecipeStatusHistory";
import { PhytoRecipeStatus } from "../../constants/enums";

interface RecipeStatus {
  label: string;
  color: string;
}

const getRecipeStatus = (status: PhytoRecipeStatus): RecipeStatus => {
  switch (status) {
    case PhytoRecipeStatus.DRAFT:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
    case PhytoRecipeStatus.PRESCRIBED:
      return {
        label: i18n.t("recipes.prescribedRecipeStatusLabel"),
        color: "black",
      };
    case PhytoRecipeStatus.ACCEPTED:
      return {
        label: i18n.t("recipes.acceptedRecipeStatusLabel"),
        color: "#3b6a00",
      };
    case PhytoRecipeStatus.REFUSED:
      return {
        label: i18n.t("recipes.rejectedRecipeStatusLabel"),
        color: "#c62828",
      };
    case PhytoRecipeStatus.APPLIED:
      return {
        label: i18n.t("recipes.appliedRecipeStatusCreate"),
        color: "#0288d1",
      };
    default:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
  }
};

class PhytoRecipeStatusHistory extends FBPhytoRecipeStatusHistory {
  startDateFormatted?: string;
  recipeStatus?: RecipeStatus;

  constructor() {
    super();
  }

  mapToClass(obj: any): PhytoRecipeStatusHistory | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.startTimestamp)
      this.startDateFormatted = moment(this.startTimestamp * 1000).format(
        "DD MMMM YYYY HH:mm"
      );
    if (this.status) this.recipeStatus = getRecipeStatus(this.status);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    delete obj.startDateFormatted;
    delete obj.recipeStatus;
    return obj;
  }
}

export default PhytoRecipeStatusHistory;
