import FertilizerMicronutrient from "../../catalogue/FertilizerMicronutrient";
import FertilizerProduct from "../../fertiliz/FertilizerProduct";

class FBFertilizerProductMicronutrient {
  id?: number;
  product?: FertilizerProduct;
  micronutrient?: FertilizerMicronutrient;
  percentage?: number;

  constructor(
    id?: number,
    product?: FertilizerProduct,
    micronutrient?: FertilizerMicronutrient,
    percentage?: number
  ) {
    this.id = id;
    this.product = product;
    this.micronutrient = micronutrient;
    this.percentage = percentage;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.product = new FertilizerProduct().mapToClass(obj?.product);
    this.micronutrient = new FertilizerMicronutrient().mapToClass(
      obj?.micronutrient
    );
    this.percentage = obj?.percentage;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.product = this.product?.mapToApiModel();
    obj.micronutrient = this.micronutrient?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerProductMicronutrient;
