import FacilitySuperType from "./FacilitySuperType";

class FacilityType {
  id?: number;
  name?: string;
  code?: string;
  superType?: FacilitySuperType;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    superType?: FacilitySuperType
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.superType = superType;
  }

  mapToClass(obj: any): FacilityType | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.superType = new FacilitySuperType().mapToClass(obj?.superType);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    obj.superType = this.superType?.mapToApiModel();
    return obj;
  }
}

export default FacilityType;
