import FBCropSector from "../_apiModels/crop/FBCropSector";

class CropSector extends FBCropSector {
  idx?: number;

  constructor(idx?: number) {
    super();
    this.idx = idx;
  }

  mapToClass(obj: any): CropSector | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Remove custom variables
    delete obj.idx;
    return obj;
  }
}

export default CropSector;
