import React from "react";

interface Props {
  children?: React.ReactNode;
}

const ScreenContentLayout = ({ children }: Props) => {
  return <div className="screen-content-layout">{children}</div>;
};

export default ScreenContentLayout;
