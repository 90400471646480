class FBVadeFertilizerManufacturer {
  id?: number;
  name?: string;
  code?: string;
  address?: string;
  municipality?: string;
  province?: string;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    address?: string,
    municipality?: string,
    province?: string
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.address = address;
    this.municipality = municipality;
    this.province = province;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.address = obj?.address;
    this.municipality = obj?.municipality;
    this.province = obj?.province;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeFertilizerManufacturer;
