import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";

import {
  Collapse,
  Alert,
  Button,
  AlertTitle,
  Typography,
  Box,
} from "@mui/material";

import {
  FertilizerStockMovementType,
  PhytosanitaryStockMovementType,
  StockFertilizerTab,
  StockPhytosanitaryTab,
} from "../../constants/enums";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface Props {
  isOpen: boolean;
  type: "fertilizer" | "phyto";
}
const StockEmptyBanner = (props: Props) => {
  const { isOpen, type } = props;

  const navigate = useNavigate();

  const handleClickPurchase = () => {
    const relativeUrl =
      type === "fertilizer"
        ? `${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}`
        : `${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/${StockPhytosanitaryTab.MOVEMENTS}`;
    const urlParam =
      type === "fertilizer"
        ? FertilizerStockMovementType.PURCHASE
        : PhytosanitaryStockMovementType.PURCHASE;
    navigate(`/${relativeUrl}/${urlParam}`);
  };

  const handleClickStockAdjust = () => {
    const relativeUrl =
      type === "fertilizer"
        ? `${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}`
        : `${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/${StockPhytosanitaryTab.MOVEMENTS}`;
    const urlParam =
      type === "fertilizer"
        ? FertilizerStockMovementType.STOCK_ADJUST
        : PhytosanitaryStockMovementType.STOCK_ADJUST;
    navigate(`/${relativeUrl}/${urlParam}`);
  };

  return (
    <Collapse in={isOpen} timeout={500}>
      <Alert severity="error">
        <AlertTitle>{i18n.t("components.stockEmptyBanner.title")}</AlertTitle>

        <Typography variant="body2">
          {i18n.t("components.stockEmptyBanner.description")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 1,
          }}
        >
          <Button sx={{ ml: 0 }} onClick={handleClickPurchase}>
            {i18n.t("components.stockEmptyBanner.buyBtn")}
          </Button>
          <Button onClick={handleClickStockAdjust}>
            {i18n.t("components.stockEmptyBanner.stockAdjustBtn")}
          </Button>
        </Box>
      </Alert>
    </Collapse>
  );
};

export default StockEmptyBanner;
