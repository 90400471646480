import moment from "moment";

import FBPhytosanitaryStockMovement from "../_apiModels/phyto/FBPhytosanitaryStockMovement";
import Document from "../files/Document";

import { dateConverter } from "../../helpers/utils";
import { PhytosanitaryStockMovementType } from "../../constants/enums";
import { PhytosanitaryStockMovementTypeInterface } from "../../constants/interfaces";
import { PHYTOSANITARY_STOCK_MOVEMENT_TYPES } from "../../providers/YupProvider";

class PhytosanitaryStockMovement extends FBPhytosanitaryStockMovement {
  idx?: number;
  movementType?: PhytosanitaryStockMovementTypeInterface;
  beforeQuantity?: number;
  editDisabled?: boolean;
  childRows?: PhytosanitaryStockMovement[];
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;
  quantityUnitFormatted?: string;
  isInventoryProduct?: boolean;
  priceFormatted?: string;
  totalPrice?: number;

  constructor(
    idx?: number,
    movementType?: PhytosanitaryStockMovementTypeInterface,
    beforeQuantity?: number,
    editDisabled?: boolean,
    childRows?: PhytosanitaryStockMovement[],
    documents?: Document[]
  ) {
    super();
    this.idx = idx;
    this.movementType = movementType;
    this.beforeQuantity = beforeQuantity;
    this.editDisabled = editDisabled;
    this.childRows = childRows;
    this.documents = documents;
  }

  mapToClass(obj: any): PhytosanitaryStockMovement | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id || obj.idx;
    if (obj.type)
      this.movementType = PHYTOSANITARY_STOCK_MOVEMENT_TYPES.find(
        (movementType) => movementType.code === obj.type
      );
    this.quantity =
      this.movementType?.code !== PhytosanitaryStockMovementType.STOCK_ADJUST
        ? Math.abs(this.quantity || 0)
        : this.quantity;
    this.beforeQuantity = this.quantity;
    this.editDisabled =
      this.movementType?.code === PhytosanitaryStockMovementType.TREATMENT;
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    this.dateFormatted = dateConverter({ date: this.date });
    this.quantityUnitFormatted = `${this.quantity} ${
      this.product?.measurementUnit?.name ||
      this.registeredProductMeasurementUnit?.name ||
      ""
    }`;
    this.isInventoryProduct = !!obj?.product;
    if (this.priceByUnit)
      this.priceFormatted = this.getPriceFormatted(this.priceByUnit);
    if (this.quantity && this.priceByUnit)
      this.totalPrice =
        Math.round(this.priceByUnit * this.quantity * 100) / 100;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.type = this.movementType?.code;
    if (this.movementType?.code === PhytosanitaryStockMovementType.PURCHASE)
      obj.quantity = Math.abs(this.quantity || 0);
    else if (
      this.movementType?.code === PhytosanitaryStockMovementType.TREATMENT
    )
      obj.quantity = -1 * Math.abs(this.quantity || 0);
    // Set current date if not exits
    if (!obj.date) obj.date = moment().format("YYYY-MM-DD");

    // Delete this class variables
    delete obj.idx;
    delete obj.movementType;
    delete obj.beforeQuantity;
    delete obj.editDisabled;
    delete obj.childRows;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    delete obj.quantityUnitFormatted;
    delete obj.isInventoryProduct;
    delete obj.priceFormatted;
    delete obj.totalPrice;
    return obj;
  }

  getPriceFormatted(price: number): string {
    return `${price} €/${this.product?.measurementUnit?.name}`;
  }

  setQuantityUnitFormatted(): void {
    this.quantityUnitFormatted = `${this.quantity} ${
      this.product?.measurementUnit?.name ||
      this.registeredProductMeasurementUnit?.name ||
      ""
    }`;
  }
}

export default PhytosanitaryStockMovement;
