import i18n from "../config/configI18n";

const PoliciesScreen = () => {
  return (
    <div className="policies">
      <div className="content">
        <h1>{i18n.t("policies.title_v2")}</h1>
        <p>{i18n.t("policies.description")}</p>
        <ol>
          <li>{i18n.t("policies.liTitle1")}</li>
          <p>{i18n.t("policies.liDescription1_v2")}</p>
          <li>{i18n.t("policies.liTitle2")}</li>
          <div>
            <span>{i18n.t("policies.liDescription2")}</span>
            <ul>
              <li>{i18n.t("policies.li2ul1_v2")}</li>
              <li>{i18n.t("policies.li2ul2_v2")}</li>
              <li>{i18n.t("policies.li2ul3_v2")}</li>
            </ul>
          </div>
          <li>{i18n.t("policies.liTitle3")}</li>
          <p>{i18n.t("policies.liDescription3_v2")}</p>
          <li>{i18n.t("policies.liTitle4_v2")}</li>
          <p>{i18n.t("policies.liDescription4_v2")}</p>
          <li>{i18n.t("policies.liTitle5_v2")}</li>
          <p>{i18n.t("policies.liDescription5_v2")}</p>
          <li>{i18n.t("policies.liTitle6_v2")}</li>
          <p>{i18n.t("policies.liDescription6_v2")}</p>
          <li>{i18n.t("policies.liTitle7_v2")}</li>
          <p>{i18n.t("policies.liDescription7_v2")}</p>
          <li>{i18n.t("policies.liTitle8_v2")}</li>
          <p>{i18n.t("policies.liDescription8_v2")}</p>
          <li>{i18n.t("policies.liTitle9_v2")}</li>
          <p>{i18n.t("policies.liDescription9_v2")}</p>
          <li>{i18n.t("policies.liTitle10")}</li>
          <p>{i18n.t("policies.liDescription10")}</p>
          <li>{i18n.t("policies.liTitle11")}</li>
          <p>{i18n.t("policies.liDescription11")}</p>
          <li>{i18n.t("policies.liTitle12")}</li>
          <p>{i18n.t("policies.liDescription12")}</p>
        </ol>
      </div>
    </div>
  );
};

export default PoliciesScreen;
