import FBFacilityPhytosanitaryTreatmentFacility from "../_apiModels/phyto/FBFacilityPhytosanitaryTreatmentFacility";

class FacilityPhytosanitaryTreatmentFacility extends FBFacilityPhytosanitaryTreatmentFacility {
  idx?: number;

  constructor(idx?: number) {
    super();
    this.idx = idx;
  }

  mapToClass(obj: any): FacilityPhytosanitaryTreatmentFacility | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.idx;
    return obj;
  }
}

export default FacilityPhytosanitaryTreatmentFacility;
