import CoOwner from "../../agrobusiness/CoOwner";
import Manager from "../../agrobusiness/Manager";
import Owner from "../../agrobusiness/Owner";
import OwnerRepresentative from "../../agrobusiness/OwnerRepresentative";

class FBAgroBusinessLeadershipList {
  owner?: Owner;
  coOwners?: CoOwner[];
  ownerRepresentative?: OwnerRepresentative;
  manager?: Manager;

  constructor(
    owner?: Owner,
    coOwners?: CoOwner[],
    ownerRepresentative?: OwnerRepresentative,
    manager?: Manager
  ) {
    this.owner = owner;
    this.coOwners = coOwners;
    this.ownerRepresentative = ownerRepresentative;
    this.manager = manager;
  }

  mapToBaseClass(obj: any): void {
    this.owner = new Owner().mapToClass(obj?.owner);
    this.coOwners = obj?.coOwners?.map((coOwner: any) =>
      new CoOwner().mapToClass(coOwner)
    );
    this.ownerRepresentative = new OwnerRepresentative().mapToClass(
      obj?.ownerRepresentative
    );
    this.manager = new Manager().mapToClass(obj?.manager);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.owner = this.owner?.mapToApiModel();
    obj.coOwners = this.coOwners?.map((coOwner) => coOwner.mapToApiModel());
    obj.ownerRepresentative = this.ownerRepresentative?.mapToApiModel();
    obj.manager = this.manager?.mapToApiModel();
    return obj;
  }
}

export default FBAgroBusinessLeadershipList;
