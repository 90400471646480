import { useState, useEffect } from "react";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  FormGroup,
  FormControl,
} from "@mui/material";

import AgroBusinessAccount from "../../models/account/AgroBusinessAccount";

interface DialogChooseExploitationProps {
  open: boolean;
  options: AgroBusinessAccount[];
  onClose: () => void;
  onConfirm: (agroBusinessAccount: AgroBusinessAccount) => void;
  selected: AgroBusinessAccount | null;
}
const DialogChooseExploitation = (props: DialogChooseExploitationProps) => {
  const { open, onClose, onConfirm, options, selected } = props;

  const [newSelected, setNewSelected] = useState<AgroBusinessAccount | null>(
    selected
  );

  useEffect(() => {
    setNewSelected(selected);
  }, [selected]);

  const handleChangeSelected = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessAccount | null
  ) => {
    if (newValue) {
      if (newValue.id === 0) {
        onConfirm(newValue);
        onClose();
      } else setNewSelected(newValue);
    }
  };

  const handleConfirm = () => {
    if (newSelected) {
      onConfirm(newSelected);
      onClose();
    }
  };

  const handleClose = () => {
    setNewSelected(selected);
    onClose();
  };

  return (
    <Dialog open={open} className="dialog" onClose={handleClose}>
      <DialogTitle className="title">
        {i18n.t("components.dialogChooseExploitation.title")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <Autocomplete
              id="exploitations-navbar"
              className="exploitation-select"
              options={options}
              getOptionLabel={(option) => option.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText={i18n.t("formErrors.notFoundResults")}
              value={newSelected}
              onChange={handleChangeSelected}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={i18n.t(
                    "components.dialogChooseExploitation.exploitation"
                  )}
                  placeholder={i18n.t(
                    "components.dialogChooseExploitation.exploitationPlaceholder"
                  )}
                />
              )}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {i18n.t("words.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChooseExploitation;
