import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import i18n from "../config/configI18n";

import {
  Autocomplete,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowBack, QuestionMark, Search } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import EmptyTable from "../components/EmptyTable";

import RegisteredFertilizerProduct from "../models/vademecumFertiliz/RegisteredFertilizerProduct";
import VadeFertilizerProductType from "../models/vademecumFertiliz/VadeFertilizerProductType";
import VadeFertilizerManufacturer from "../models/vademecumFertiliz/VadeFertilizerManufacturer";
import { Column } from "../models/Generic";

import useFetch from "../hooks/useFetch";
import { useSession } from "../hooks/useSession";

import { getFieldValueByColumnNotation } from "../helpers/utils";
import {
  SearchFieldInterface,
  SnackbarInterface,
} from "../constants/interfaces";

import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../constants/constants";

const VademecumFertilizScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] =
    useState<RegisteredFertilizerProduct | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsDrawerOpen(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const openDrawer = () => {
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setTimeout(() => {
      setSelectedProduct(null);
      navigate(-1);
    }, 500);
  };

  const handleClickProduct = (product: RegisteredFertilizerProduct | null) => {
    setSelectedProduct(product);
    openDrawer();
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <VademecumTable onClick={handleClickProduct} onError={setSnackbarMsg} />
      <VadeProductView
        isOpen={isDrawerOpen}
        selected={selectedProduct}
        onClose={closeDrawer}
        onError={setSnackbarMsg}
      />
    </ScreenContentLayout>
  );
};

export default VademecumFertilizScreen;

interface VademecumTableProps {
  onClick?: (product: RegisteredFertilizerProduct | null) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VademecumTable = (props: VademecumTableProps) => {
  const { onClick, onError } = props;

  const columns: Column[] = [
    {
      id: "name",
      label: i18n.t("vademecum.fertiliz.columns.name"),
      numeric: false,
    },
    {
      id: "type.name",
      label: i18n.t("vademecum.fertiliz.columns.type"),
      numeric: false,
    },
    {
      id: "manufacturer.name",
      label: i18n.t("vademecum.fertiliz.columns.manufacturer"),
      numeric: false,
    },
  ];

  const { selectedABAccount } = useSession();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [productSearch, setProductSearch] = useState<
    SearchFieldInterface<RegisteredFertilizerProduct>
  >({
    searchWord: "",
    selected: null,
  });
  const [typeSearch, setTypeSearch] = useState<
    SearchFieldInterface<VadeFertilizerProductType>
  >({ searchWord: "", selected: null });
  const [manufacturerSearch, setManufacturerSearch] = useState<
    SearchFieldInterface<VadeFertilizerManufacturer>
  >({ searchWord: "", selected: null });

  const { data: products, isFetching: isFetchingProducts } = useFetch<
    RegisteredFertilizerProduct[]
  >({
    queryKey: [
      "vadeFertilizProducts",
      selectedABAccount?.context?.id,
      productSearch.searchWord,
      typeSearch.searchWord,
      manufacturerSearch.searchWord,
      typeSearch.selected,
      manufacturerSearch.selected,
    ],
    selected: {
      productSearch,
      typeSearch,
      manufacturerSearch,
    },
    enabled:
      !!selectedABAccount?.context?.id &&
      (productSearch.searchWord.length > 0 ||
        typeSearch.searchWord.length > 0 ||
        manufacturerSearch.searchWord.length > 0 ||
        !!typeSearch.selected ||
        !!manufacturerSearch.selected),
    onError,
  });

  const { data: productTypes, isFetching: isFetchingTypes } = useFetch<
    VadeFertilizerProductType[]
  >({
    queryKey: [
      "vadeFertilizerProductTypes",
      selectedABAccount?.context?.id,
      typeSearch.searchWord,
    ],
    selected: typeSearch,
    enabled:
      !!selectedABAccount?.context?.id && typeSearch.searchWord.length > 0,
    onError,
  });

  const { data: manufacturers, isFetching: isFetchingManufacturers } = useFetch<
    VadeFertilizerManufacturer[]
  >({
    queryKey: [
      "vadeFertilizerManufacturers",
      selectedABAccount?.context?.id,
      manufacturerSearch.searchWord,
    ],
    selected: manufacturerSearch,
    enabled:
      !!selectedABAccount?.context?.id &&
      manufacturerSearch.searchWord.length > 0,
    onError,
  });

  const notFilters: boolean = useMemo(
    () =>
      typeSearch.searchWord.length === 0 &&
      !typeSearch.selected &&
      manufacturerSearch.searchWord.length === 0 &&
      !manufacturerSearch.selected,
    [typeSearch, manufacturerSearch]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows: RegisteredFertilizerProduct[] = useMemo(
    () =>
      products
        ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [products, page, rowsPerPage]
  );

  const handleChangeProductSearchWord = (value: string) => {
    setProductSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeTypeSearchWord = (value: string) => {
    setTypeSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeManufacturerSearchWord = (value: string) => {
    setManufacturerSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeType = (value: VadeFertilizerProductType | null) => {
    setTypeSearch((state) => ({ ...state, selected: value }));
  };

  const handleChangeManufacturer = (
    value: VadeFertilizerManufacturer | null
  ) => {
    setManufacturerSearch((state) => ({ ...state, selected: value }));
  };

  const handleClickRow = (product: RegisteredFertilizerProduct) => {
    onClick && onClick(product);
  };

  return (
    <div className="vademecum-fertiliz-table">
      <LoadingWithDelay isVisible={isFetchingProducts} />
      <div className="header">
        <h1>{i18n.t("vademecum.fertiliz.title")}</h1>
      </div>
      <div className="content">
        <div className="content-table-filters">
          <VadeProductFilter
            onChangeSearchWord={handleChangeProductSearchWord}
          />
          <VadeProductTypeFilter
            types={productTypes || []}
            selected={typeSearch.selected}
            isLoading={isFetchingTypes}
            onChangeSearchWord={handleChangeTypeSearchWord}
            onChange={handleChangeType}
          />
          <VadeManufacturerFilter
            manufacturers={manufacturers || []}
            selected={manufacturerSearch.selected}
            isLoading={isFetchingManufacturers}
            onChangeSearchWord={handleChangeManufacturerSearchWord}
            onChange={handleChangeManufacturer}
          />
        </div>
        {visibleRows.length === 0 ? (
          <EmptyTable
            icon={<QuestionMark fontSize="large" />}
            title={
              notFilters
                ? i18n.t("vademecum.fertiliz.notFiltersTitle")
                : i18n.t("vademecum.fertiliz.notResultsTitle")
            }
          />
        ) : (
          <TableContainer className="table-container">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="table-cell-head"
                      align={
                        column.align || (column.numeric ? "right" : "left")
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {visibleRows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="table-row"
                    onClick={() => handleClickRow(row)}
                  >
                    {columns.map((column) => (
                      <TableRowItem key={column.id} row={row} column={column} />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              className="table-pagination"
              component="div"
              count={products?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={i18n.t("words.rowsByPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
    </div>
  );
};

interface TableRowItemProps {
  row: RegisteredFertilizerProduct;
  column: Column;
}
const TableRowItem = (props: TableRowItemProps) => {
  const { row, column } = props;

  const value = getFieldValueByColumnNotation(
    column.id,
    row,
    column.valueLabel,
    column.arrayFieldName,
    column.unitFieldName,
    column.unitValue,
    column.id2
  );
  const align = column.align || (column.numeric ? "right" : "left");

  return (
    <TableCell className="table-cell" align={align}>
      {value}
    </TableCell>
  );
};

interface ProductsFilterProps {
  onChangeSearchWord?: (searchWord: string) => void;
}
let productsSearchWordTimeout: NodeJS.Timeout | null;

const VadeProductFilter = (props: ProductsFilterProps) => {
  const { onChangeSearchWord } = props;

  const handleChangeProductSearchWord = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  return (
    <div className="table-filter">
      <TextField
        size="small"
        className="filter-text-field"
        label={i18n.t("vademecum.fertiliz.productsFilterLabel")}
        placeholder={i18n.t("vademecum.fertiliz.productsFilterPlaceholder")}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeProductSearchWord}
      />
    </div>
  );
};

interface VadeProductTypeFilterProps {
  types: VadeFertilizerProductType[];
  selected: VadeFertilizerProductType | null;
  isLoading?: boolean;
  onChangeSearchWord?: (searchWord: string) => void;
  onChange?: (type: VadeFertilizerProductType | null) => void;
}
let typesSearchWordTimeout: NodeJS.Timeout | null;

const VadeProductTypeFilter = (props: VadeProductTypeFilterProps) => {
  const { types, isLoading, selected, onChangeSearchWord, onChange } = props;

  const handleChangeProductTypeSearchWord = (event: any, value: string) => {
    if (typesSearchWordTimeout) {
      clearTimeout(typesSearchWordTimeout);
      typesSearchWordTimeout = null;
    }
    typesSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeType = (
    event: any,
    value: VadeFertilizerProductType | null | string
  ) => {
    if (typeof value !== "string") onChange && onChange(value);
  };

  return (
    <div className="table-filter">
      <Autocomplete
        id="types-filter"
        className="filter-text-field"
        options={types || []}
        getOptionLabel={(option) =>
          option instanceof VadeFertilizerProductType
            ? option?.name || ""
            : option
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        freeSolo
        loading={isLoading}
        value={selected}
        onChange={handleChangeType}
        onInputChange={handleChangeProductTypeSearchWord}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.fertiliz.productTypesFilterLabel")}
            placeholder={i18n.t(
              "vademecum.fertiliz.productTypesFilterPlaceholder"
            )}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeManufacturerFilterProps {
  manufacturers: VadeFertilizerManufacturer[];
  selected: VadeFertilizerManufacturer | null;
  isLoading?: boolean;
  onChangeSearchWord?: (searchWord: string) => void;
  onChange?: (manufacturer: VadeFertilizerManufacturer | null) => void;
}
let manufacturerSearchWordTimeout: NodeJS.Timeout | null;

const VadeManufacturerFilter = (props: VadeManufacturerFilterProps) => {
  const { manufacturers, isLoading, selected, onChangeSearchWord, onChange } =
    props;

  const handleChangeManufacturerSearchWord = (event: any, value: string) => {
    if (manufacturerSearchWordTimeout) {
      clearTimeout(manufacturerSearchWordTimeout);
      manufacturerSearchWordTimeout = null;
    }
    manufacturerSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeManufacturer = (
    event: any,
    value: VadeFertilizerManufacturer | null | string
  ) => {
    if (typeof value !== "string") onChange && onChange(value);
  };

  return (
    <div className="table-filter">
      <Autocomplete
        id="types-filter"
        className="filter-text-field"
        options={manufacturers || []}
        getOptionLabel={(option) =>
          option instanceof VadeFertilizerManufacturer
            ? option?.name || ""
            : option
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        freeSolo
        loading={isLoading}
        value={selected}
        onChange={handleChangeManufacturer}
        onInputChange={handleChangeManufacturerSearchWord}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.fertiliz.manufacturerFilterLabel")}
            placeholder={i18n.t(
              "vademecum.fertiliz.manufacturerFilterPlaceholder"
            )}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeProductViewProps {
  isOpen: boolean;
  selected: RegisteredFertilizerProduct | null;
  onClose: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VadeProductView = (props: VadeProductViewProps) => {
  const { isOpen, selected, onClose, onError } = props;

  const [product, setProduct] = useState<RegisteredFertilizerProduct | null>(
    null
  );

  const { isFetching: isUpdating } = useFetch<RegisteredFertilizerProduct>({
    queryKey: ["registeredFertilizProductById", selected?.id],
    selected,
    onSuccess: setProduct,
    enabled: !!selected?.id,
    onError,
  });

  if (!selected) return null;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="vademecum-fertiliz-drawer"
      PaperProps={{ className: "vademecum-fertiliz-drawer" }}
    >
      <div className="container">
        <div className="header">
          <IconButton onClick={onClose}>
            <ArrowBack fontSize="large" color="action" />
          </IconButton>
          <div className="header-titles">
            <h1>{product?.name}</h1>
          </div>
        </div>

        {isUpdating ? (
          <LoadingWithDelay isVisible />
        ) : (
          <div className="content">
            <ResumeSection product={product} />
            <PackagingSection product={product} />
            <NutrientsSection product={product} />
            <HeavyMetalsSection product={product} />
            <OthersSection product={product} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

interface SectionProps {
  product: RegisteredFertilizerProduct | null;
}

export const ResumeSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.fertiliz.productView.resumeSectionTitle")}</h3>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.registerIdLabel")}</h4>
          <p>{product?.registerId}</p>
        </div>
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.productTypeLabel")}</h4>
          <p>{product?.type?.name}</p>
        </div>
        <div className="content-row-item">
          <h4>
            {i18n.t("vademecum.fertiliz.productView.manufacturerNameLabel")}
          </h4>
          <p>{product?.manufacturer?.name}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const PackagingSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.packagingSectionTitle")}
        </h3>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          <h4>
            {i18n.t("vademecum.fertiliz.productView.packagingFormatLabel")}
          </h4>
          <p>{product?.packagingFormat}</p>
        </div>
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.useMethodLabel")}</h4>
          <p>{product?.useMethod}</p>
        </div>
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.packagingLabel")}</h4>
          <p>{product?.packaging}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const NutrientsSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.nutrientsSectionTitle")}
        </h3>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t("vademecum.fertiliz.productView.nutrientColumnLabel")}
              </th>
              <th>
                {i18n.t(
                  "vademecum.fertiliz.productView.nutrientColumnValueLabel"
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {product?.nitrogenTotal && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.nitrogenTotalPercentagePercentage"
                  )}
                </td>
                <td>{product.nitrogenTotal}</td>
              </tr>
            )}
            {product?.ammoniacalNitrogen && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.ammoniacalNitrogenPercentagePercentage"
                  )}
                </td>
                <td>{product.ammoniacalNitrogen}</td>
              </tr>
            )}
            {product?.nitricNitrogen && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.nitricNitrogenPercentagePercentage"
                  )}
                </td>
                <td>{product.nitricNitrogen}</td>
              </tr>
            )}
            {product?.organicNitrogen && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.organicNitrogenPercentagePercentage"
                  )}
                </td>
                <td>{product.organicNitrogen}</td>
              </tr>
            )}
            {product?.ureicNitrogen && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.ureicNitrogenPercentagePercentage"
                  )}
                </td>
                <td>{product.ureicNitrogen}</td>
              </tr>
            )}
            {product?.P2O5 && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.P2O5PercentagePercentage"
                  )}
                </td>
                <td>{product.P2O5}</td>
              </tr>
            )}
            {product?.waterP2O5 && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.waterP2O5PercentagePercentage"
                  )}
                </td>
                <td>{product.waterP2O5}</td>
              </tr>
            )}
            {product?.K2O && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.K2OPercentagePercentage"
                  )}
                </td>
                <td>{product.K2O}</td>
              </tr>
            )}
            {product?.SO3 && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.SO3PercentagePercentage"
                  )}
                </td>
                <td>{product.SO3}</td>
              </tr>
            )}
            {product?.Na2O && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.Na2OPercentagePercentage"
                  )}
                </td>
                <td>{product.Na2O}</td>
              </tr>
            )}
            {product?.CaO && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.CaOPercentagePercentage"
                  )}
                </td>
                <td>{product.CaO}</td>
              </tr>
            )}
            {product?.MgO && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.MgOPercentagePercentage"
                  )}
                </td>
                <td>{product.MgO}</td>
              </tr>
            )}
            {product?.B && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.BPercentagePercentage"
                  )}
                </td>
                <td>{product.B}</td>
              </tr>
            )}
            {product?.Co && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.CoPercentagePercentage"
                  )}
                </td>
                <td>{product.Co}</td>
              </tr>
            )}
            {product?.Cu && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.CuPercentagePercentage"
                  )}
                </td>
                <td>{product.Cu}</td>
              </tr>
            )}
            {product?.Fe && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.FePercentagePercentage"
                  )}
                </td>
                <td>{product.Fe}</td>
              </tr>
            )}
            {product?.Mn && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.MnPercentagePercentage"
                  )}
                </td>
                <td>{product.Mn}</td>
              </tr>
            )}
            {product?.Mb && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.MbPercentagePercentage"
                  )}
                </td>
                <td>{product.Mb}</td>
              </tr>
            )}
            {product?.Zn && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.ZnPercentagePercentage"
                  )}
                </td>
                <td>{product.Zn}</td>
              </tr>
            )}
            {product?.nutrientPH && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.pHRange")}</td>
                <td>{product.nutrientPH}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export const HeavyMetalsSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.heavyMetalsSectionTitle")}
        </h3>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t("vademecum.fertiliz.productView.heavyMetalColumnLabel")}
              </th>
              <th>
                {i18n.t(
                  "vademecum.fertiliz.productView.heavyMetalColumnValueLabel"
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {product?.cadmiumLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.cadmiumLabel")}</td>
                <td>{product.cadmiumLabel}</td>
              </tr>
            )}
            {product?.copperLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.copperLabel")}</td>
                <td>{product.copperLabel}</td>
              </tr>
            )}
            {product?.nickelLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.nickelLabel")}</td>
                <td>{product.nickelLabel}</td>
              </tr>
            )}
            {product?.leadLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.leadLabel")}</td>
                <td>{product.leadLabel}</td>
              </tr>
            )}
            {product?.zincLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.zincLabel")}</td>
                <td>{product.zincLabel}</td>
              </tr>
            )}
            {product?.mercuryLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.mercuryLabel")}</td>
                <td>{product.mercuryLabel}</td>
              </tr>
            )}
            {product?.chromeTotalLabel && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.chromeTotalLabel")}
                </td>
                <td>{product.chromeTotalLabel}</td>
              </tr>
            )}
            {product?.chromeVILabel && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.chromeVILabel")}
                </td>
                <td>{product.chromeVILabel}</td>
              </tr>
            )}
            {product?.productClassificationLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.productClassificationLabel"
                  )}
                </td>
                <td>{product.productClassificationLabel}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export const OthersSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.fertiliz.productView.othersSectionTitle")}</h3>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t("vademecum.fertiliz.productView.othersColumnLabel")}
              </th>
              <th>
                {i18n.t(
                  "vademecum.fertiliz.productView.othersColumnValueLabel"
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {product?.organicMaterialPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.organicMaterialPercentageLabel"
                  )}
                </td>
                <td>{product.organicMaterialPercentageLabel}</td>
              </tr>
            )}
            {product?.organicCarbonPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.organicCarbonPercentageLabel"
                  )}
                </td>
                <td>{product.organicCarbonPercentageLabel}</td>
              </tr>
            )}
            {product?.humicExtractTotalPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.humicExtractTotalPercentageLabel"
                  )}
                </td>
                <td>{product.humicExtractTotalPercentageLabel}</td>
              </tr>
            )}
            {product?.humicAcidsTotalPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.humicAcidsTotalPercentageLabel"
                  )}
                </td>
                <td>{product.humicAcidsTotalPercentageLabel}</td>
              </tr>
            )}
            {product?.fulvicAcidsTotalPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.fulvicAcidsTotalPercentageLabel"
                  )}
                </td>
                <td>{product.fulvicAcidsTotalPercentageLabel}</td>
              </tr>
            )}
            {product?.clurideContentLabel && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.clurideContentLabel")}
                </td>
                <td>{product.clurideContentLabel}</td>
              </tr>
            )}
            {product?.maxHumidityLabel && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.maxHumidityLabel")}
                </td>
                <td>{product.maxHumidityLabel}</td>
              </tr>
            )}
            {product?.electricalConductivityLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.electricalConductivityLabel"
                  )}
                </td>
                <td>{product.electricalConductivityLabel}</td>
              </tr>
            )}
            {product?.densityLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.densityLabel")}</td>
                <td>{product.densityLabel}</td>
              </tr>
            )}
            {product?.phLabel && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.phLabel")}</td>
                <td>{product.phLabel}</td>
              </tr>
            )}
            {product?.CNRelationLabel && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.CNRelationLabel")}
                </td>
                <td>{product.CNRelationLabel}</td>
              </tr>
            )}
            {product?.solubilityDegreeTotalPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.solubilityDegreeTotalPercentageLabel"
                  )}
                </td>
                <td>{product.solubilityDegreeTotalPercentageLabel}</td>
              </tr>
            )}
            {product?.polyphenolsPercentageLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.polyphenolsPercentageLabel"
                  )}
                </td>
                <td>{product.polyphenolsPercentageLabel}</td>
              </tr>
            )}
            {product?.waterSolubleProductLabel && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.waterSolubleProductLabel"
                  )}
                </td>
                <td>{product.waterSolubleProductLabel}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
