import FBAgroBusinessAccount from "../_apiModels/account/FBAgrobusinessAccount";

class AgroBusinessAccount extends FBAgroBusinessAccount {
  constructor() {
    super();
  }

  mapToClass(obj: any): AgroBusinessAccount | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default AgroBusinessAccount;
