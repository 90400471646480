import PhytosanitaryTreatment from "../../phyto/PhytosanitaryTreatment";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import VegetableProduct from "../../catalogue/VegetableProduct";
import PhytosanitaryTreatmentApplicator from "../../phyto/PhytosanitaryTreatmentApplicator";
import PhytosanitaryTreatmentAdvisor from "../../phyto/PhytosanitaryTreatmentAdvisor";
import PlantRegulatorsAndOthers from "../../catalogue/PlantRegulatorsAndOthers";

class FBPostHarvestPhytosanitaryTreatment extends PhytosanitaryTreatment {
  id?: number;
  product?: VegetableProduct;
  quantity?: number;
  quantityUnit?: MeasurementUnit;
  regulators?: PlantRegulatorsAndOthers[];
  applicators?: PhytosanitaryTreatmentApplicator[];
  advisors?: PhytosanitaryTreatmentAdvisor[];

  constructor(
    id?: number,
    product?: VegetableProduct,
    quantity?: number,
    quantityUnit?: MeasurementUnit,
    regulators?: PlantRegulatorsAndOthers[],
    applicators?: PhytosanitaryTreatmentApplicator[],
    advisors?: PhytosanitaryTreatmentAdvisor[]
  ) {
    super();
    this.id = id;
    this.product = product;
    this.quantity = quantity;
    this.quantityUnit = quantityUnit;
    this.regulators = regulators;
    this.applicators = applicators;
    this.advisors = advisors;
  }

  mapToBaseClass(obj: any): void {
    super.mapToClass(obj);
    this.id = obj?.id;
    this.product = new VegetableProduct().mapToClass(obj?.product);
    this.quantity = obj?.quantity;
    this.quantityUnit = new MeasurementUnit().mapToClass(obj?.quantityUnit);
    this.regulators = obj?.regulators?.map((regulator: any) =>
      new PlantRegulatorsAndOthers().mapToClass(regulator)
    );
    this.applicators = obj?.applicators?.map((applicator: any) =>
      new PhytosanitaryTreatmentApplicator().mapToClass(applicator)
    );
    this.advisors = obj?.advisors?.map((advisor: any) =>
      new PhytosanitaryTreatmentAdvisor().mapToClass(advisor)
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = super.mapToApiModel();
    obj.product = this.product?.mapToApiModel();
    obj.quantityUnit = this.quantityUnit?.mapToApiModel();
    obj.regulators = this.regulators?.map(
      (regulator: PlantRegulatorsAndOthers) => regulator.mapToApiModel()
    );
    obj.applicators = this.applicators?.map(
      (applicator: PhytosanitaryTreatmentApplicator) =>
        applicator.mapToApiModel()
    );
    obj.advisors = this.advisors?.map(
      (advisor: PhytosanitaryTreatmentAdvisor) => advisor.mapToApiModel()
    );
    return obj;
  }
}

export default FBPostHarvestPhytosanitaryTreatment;
