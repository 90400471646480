import PhytosanitaryProductPurchase from "../../phyto/PhytosanitaryProductPurchase";
import Facility from "../../agrobusiness/Facility";
import PhytosanitaryProduct from "../../phyto/PhytosanitaryProduct";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import RegisteredPhytosanitaryProduct from "../../vademecum/RegisteredPhytosanitaryProduct";
import MeasurementUnit from "../../catalogue/MeasurementUnit";

class FBPhytosanitaryStockMovement {
  id?: number;
  date?: string;
  product?: PhytosanitaryProduct;
  registeredProduct?: RegisteredPhytosanitaryProduct;
  registeredProductMeasurementUnit?: MeasurementUnit;
  type?: string;
  quantity?: number;
  priceByUnit?: number;
  description?: string;
  facility?: Facility;
  purchase?: PhytosanitaryProductPurchase;
  agroBusinessPartition?: AgroBusinessPartition;

  constructor(
    id?: number,
    date?: string,
    product?: PhytosanitaryProduct,
    registeredProduct?: RegisteredPhytosanitaryProduct,
    registeredProductMeasurementUnit?: MeasurementUnit,
    type?: string,
    quantity?: number,
    priceByUnit?: number,
    description?: string,
    facility?: Facility,
    purchase?: PhytosanitaryProductPurchase,
    agroBusinessPartition?: AgroBusinessPartition
  ) {
    this.id = id;
    this.date = date;
    this.product = product;
    this.registeredProduct = registeredProduct;
    this.registeredProductMeasurementUnit = registeredProductMeasurementUnit;
    this.type = type;
    this.quantity = quantity;
    this.priceByUnit = priceByUnit;
    this.description = description;
    this.facility = facility;
    this.purchase = purchase;
    this.agroBusinessPartition = agroBusinessPartition;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.date = obj?.date;
    if (obj?.product)
      this.product = new PhytosanitaryProduct().mapToClass(obj.product);
    if (obj?.registeredProduct)
      this.registeredProduct = new RegisteredPhytosanitaryProduct().mapToClass(
        obj.registeredProduct
      );
    if (obj?.registeredProductMeasurementUnit)
      this.registeredProductMeasurementUnit = new MeasurementUnit().mapToClass(
        obj.registeredProductMeasurementUnit
      );
    this.type = obj?.type;
    this.quantity = obj?.quantity;
    this.priceByUnit = obj?.priceByUnit;
    this.description = obj?.description;
    this.facility = new Facility().mapToClass(obj?.facility);
    this.purchase = new PhytosanitaryProductPurchase().mapToClass(
      obj?.purchase
    );
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.product = this.product?.mapToApiModel();
    obj.registeredProduct = this.registeredProduct?.mapToApiModel();
    obj.registeredProductMeasurementUnit =
      this.registeredProductMeasurementUnit?.mapToApiModel();
    obj.facility = this.facility?.mapToApiModel();
    obj.purchase = this.purchase?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryStockMovement;
