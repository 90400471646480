import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import { useQueryClient, QueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  Tabs,
  Tab,
  FormGroup,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  TextField,
  InputAdornment,
  Divider,
  useMediaQuery,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Search, Science, Inventory } from "@mui/icons-material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import PhytosanitaryProductForm from "../../components/forms/PhytosanitaryProductForm";
import TestBanner from "../../components/banners/TestBanner";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import PartitionForm from "../../components/forms/PartitionForm";
import SectionTutorial from "../../components/elements/SectionTutorial";
import AlertEmptyList from "../../components/AlertEmptyList";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import StockEmptyBanner from "../../components/banners/StockEmptyBanner";
import DialogPhytoPurchaseProduct from "../../components/dialogs/DialogPhytoPurchaseProduct";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";

import PhytosanitaryStockMovement from "../../models/phyto/PhytosanitaryStockMovement";
import PhytosanitaryProduct from "../../models/phyto/PhytosanitaryProduct";
import Document from "../../models/files/Document";
import PhytosanitaryProductStock from "../../models/phyto/PhytosanitaryProductStock";
import PhytosanitaryProductPurchase from "../../models/phyto/PhytosanitaryProductPurchase";

import {
  PhytosanitaryStockMovementType,
  FormMode,
  StockPhytosanitaryTab,
} from "../../constants/enums";
import {
  SnackbarInterface,
  SectionTutorialInterface,
  PhytosanitaryStockMovementTypeInterface,
} from "../../constants/interfaces";
import {
  EMPTY_TABLE_ICON_SIZE,
  SECTION_TABLE_FONT_SIZE,
} from "../../constants/constants";

import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { PHYTOSANITARY_STOCK_MOVEMENT_TYPES } from "../../providers/YupProvider";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
  groupBy,
  dateConverter,
} from "../../helpers/utils";

interface UpdateStockMovementParams {
  queryClient: QueryClient;
}
const updateStockMovements = (props: UpdateStockMovementParams) => {
  const { queryClient } = props;
  queryClient.refetchQueries({
    queryKey: ["phytosanitaryStockMovements"],
    type: "active",
  });
};

const ExploitationStockPhytosanitaryScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");
  const { movementTypeCode, tabName } = useParams<{
    movementTypeCode: PhytosanitaryStockMovementType;
    tabName: StockPhytosanitaryTab;
  }>();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState<StockPhytosanitaryTab>(
    StockPhytosanitaryTab.INVENTORY
  );

  // Redirect to the correct tab according the url
  useEffect(() => {
    setTabValue(tabName || StockPhytosanitaryTab.INVENTORY);
  }, [tabName]);

  const handleTabChange = (
    event: React.SyntheticEvent | null,
    newValue: any
  ) => {
    setTabValue(newValue as StockPhytosanitaryTab);
    navigate(
      `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/${newValue}`,
      {
        replace: true,
      }
    );
  };

  const renderTab = () => {
    switch (tabValue) {
      case StockPhytosanitaryTab.INVENTORY:
        return <InventoryTab />;
      case StockPhytosanitaryTab.MOVEMENTS:
        return (
          <StockMovementsTab
            movementTypeCode={movementTypeCode}
            onChangeTab={handleTabChange}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <ScreenContentLayout>
      {!isLargeScreen && <Divider />}
      <TestBanner />
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("phytoStock.tab.inventory")}
          value={StockPhytosanitaryTab.INVENTORY}
        />
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("phytoStock.tab.movements")}
          value={StockPhytosanitaryTab.MOVEMENTS}
        />
      </Tabs>
      {renderTab()}
    </ScreenContentLayout>
  );
};

export default ExploitationStockPhytosanitaryScreen;

const InventoryTab = () => {
  const inventoryColumns: Column[] = [
    {
      id: "name",
      label: i18n.t("phytoStock.inventoryTab.column.name"),
      numeric: false,
    },
    {
      id: "stock",
      label: i18n.t("phytoStock.inventoryTab.column.quantity"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "measurementUnit.name",
    },
  ];

  const YupValidatorSchema = Yup.object().shape({
    name: Yup.string().when("type.code", {
      is: (val: string) => val !== "1",
      then: (schema) => schema.required(i18n.t("formErrors.requiredField")),
      otherwise: (schema) => schema.notRequired(),
    }),
    type: Yup.object().required(),
    measurementUnit: Yup.object().required(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<
    PhytosanitaryProduct | undefined
  >(undefined);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);
  const [isOpenNonStockBanner, setIsOpenNonStockBanner] =
    useState<boolean>(false);

  const handleProductsStockResponse = (data: PhytosanitaryProductStock[]) => {
    setIsOpenNonStockBanner(!!data.find((p) => !p.stock || p.stock <= 0));
  };

  const { data: productsStock, isFetching } = useFetch<
    PhytosanitaryProductStock[]
  >({
    queryKey: [
      "phytosanitaryProductsStock",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onSuccess: handleProductsStockResponse,
    onError: setSnackbarMsg,
  });

  const handleSubmit = (values: PhytosanitaryProduct) => {
    switch (formMode) {
      case FormMode.CREATE:
        phytoProductCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        phytoProductEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<PhytosanitaryProduct>({
    initialValues: new PhytosanitaryProduct(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.EDIT
      ? i18n.t("phytoStock.inventoryTab.drawerTitleEdit")
      : i18n.t("phytoStock.inventoryTab.drawerTitleCreate");
  const drawerBtnText =
    formik.status === FormMode.EDIT
      ? i18n.t("words.update")
      : i18n.t("words.create");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setSelectedProduct(undefined);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const phytoProductCreateMutate = useCrud<PhytosanitaryProduct>({
    key: "postPhytosanitaryProduct",
    values: formik.values,
    onSuccess: (data: PhytosanitaryProduct) => {
      // Update product stock list
      queryClient.refetchQueries({
        queryKey: ["phytosanitaryProductsStock"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const phytoProductEditMutate = useCrud<PhytosanitaryProduct>({
    key: "putPhytosanitaryProduct",
    values: formik.values,
    onSuccess: (data: PhytosanitaryProduct) => {
      const productStock = productsStock?.find((p) => p.id === data.id);
      if (productStock) {
        const updatedProduct = new PhytosanitaryProductStock().mapToClass(data);
        if (updatedProduct) {
          updatedProduct.stock = productStock?.stock || 0;
          // Update without refetch
          queryClient.setQueryData<PhytosanitaryProductStock[]>(
            [
              "phytosanitaryProductsStock",
              selectedABAccount?.id,
              selectedABPartition?.id,
            ],
            (oldData) => updateItemOfList(oldData, updatedProduct, "id")
          );
        }
      }
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const phytoProductDeleteMutate = useCrud({
    key: "deletePhytosanitaryProducts",
    values: productsStock?.filter((p: PhytosanitaryProductStock) =>
      selectedRowIds.includes(p?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["phytosanitaryProductsStock"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const productStock = productsStock?.find((p) => p.id === id);
    const phytoProduct = new PhytosanitaryProduct().mapToClass(productStock);
    if (productStock && phytoProduct) {
      setSelectedProduct(phytoProduct);
      formik.setStatus(FormMode.EDIT);
      setFormMode(FormMode.EDIT);
      openDrawer(FormMode.EDIT);
    }
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    phytoProductDeleteMutate.mutate({});
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isFetching} />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <StockEmptyBanner isOpen={isOpenNonStockBanner} type="phyto" />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("phytoStock.inventoryTab.backAlertTitle")}
        contentText={i18n.t("phytoStock.inventoryTab.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("phytoStock.inventoryTab.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "phytoStock.inventoryTab.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={phytoProductDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("phytoStock.inventoryTab.tableTitle")}
        headerText={i18n.t("phytoStock.inventoryTab.headerText")}
        colums={inventoryColumns}
        rows={productsStock}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Science sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("phytoStock.inventoryTab.emptyTableTitle")}
        emptyTableSubtitle={i18n.t(
          "phytoStock.inventoryTab.emptyTableSubtitle"
        )}
        emptyTableBtnText={i18n.t("phytoStock.inventoryTab.emptyTableBtnText")}
        addBtnVariant="contained"
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={i18n.t("phytoStock.inventoryTab.drawerSubtitle")}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          phytoProductCreateMutate.isLoading || phytoProductEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <PhytosanitaryProductForm
          movementType={PhytosanitaryStockMovementType.PURCHASE}
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selectedProduct}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface StockMovementTabProps {
  movementTypeCode?: PhytosanitaryStockMovementType;
  onChangeTab?: (
    event: React.SyntheticEvent | null,
    newValue: StockPhytosanitaryTab
  ) => void;
}
const StockMovementsTab = (props: StockMovementTabProps) => {
  const stockMovementTutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t(
        "phytoStock.movementsTab.tutorial.createPhytoProduct.label"
      ),
      description: i18n.t(
        "phytoStock.movementsTab.tutorial.createPhytoProduct.description"
      ),
      nextBtnText: i18n.t(
        "phytoStock.movementsTab.tutorial.createPhytoProduct.nextBtnText"
      ),
    },
  ];
  const stockMovementsColumns: Column[] = [
    {
      id: "movementType.name",
      label: i18n.t("phytoStock.movementsTab.column.type"),
      numeric: false,
    },
    {
      id: "dateFormatted",
      label: i18n.t("phytoStock.movementsTab.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "product.name",
      label: i18n.t("phytoStock.movementsTab.column.product"),
      numeric: false,
    },
    {
      id: "quantity",
      label: i18n.t("phytoStock.movementsTab.column.quantity"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "product.measurementUnit.name",
    },
  ];
  const stockMovementsChildColumns: Column[] = [
    { id: "product.name", label: "Producto", numeric: false },
    {
      id: "quantity",
      label: i18n.t("phytoStock.movementsTab.column.quantity"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "product.measurementUnit.name",
    },
  ];

  const { movementTypeCode, onChangeTab } = props;

  const stockMovementRef = useRef<StockMovementFormMethods>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [movementType, setMovementType] = useState<
    PhytosanitaryStockMovementTypeInterface | undefined
  >(PHYTOSANITARY_STOCK_MOVEMENT_TYPES[0]);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedToEdit, setSelectedToEdit] = useState<
    PhytosanitaryStockMovement | undefined
  >(undefined);
  const [groupedStockMovements, setGroupedStockMovements] = useState<
    PhytosanitaryStockMovement[]
  >([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);
  const [attachedDocs, setAttachedDocs] = useState<Document[]>([]);

  const { data: phytosanitaryProductsCount, isFetching: isFetchingProducts } =
    useFetch<number>({
      queryKey: [
        "phytosanitaryProductsCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
      onError: setSnackbarMsg,
    });

  const {
    data: stockMovements,
    isFetching,
    refresh,
  } = useFetch<PhytosanitaryStockMovement[]>({
    queryKey: [
      "phytosanitaryStockMovements",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  useEffect(() => {
    if (stockMovements && stockMovements.length > 0) {
      const purchases = stockMovements.filter(
        (stockMovement) =>
          stockMovement.movementType?.code ===
          PhytosanitaryStockMovementType.PURCHASE
      );
      const notGroupedMovements = stockMovements.filter(
        (stockMovement) =>
          ![PhytosanitaryStockMovementType.PURCHASE].includes(
            (stockMovement.movementType?.code ||
              "0") as PhytosanitaryStockMovementType
          )
      );

      const groupedPurchases = Object.entries(
        groupBy(purchases, "purchase.id")
      )?.map((key: any) => {
        // Set product purchase as fertilizer stock movement (reset quantity and product fields)
        const purchases = key[1];
        const productPurchase =
          new PhytosanitaryStockMovement().mapToClass({
            id: key[0],
            date: purchases[0].date,
            description: purchases[0].description,
            type: PhytosanitaryStockMovementType.PURCHASE,
            purchase: { id: key[0] },
          }) || new PhytosanitaryStockMovement();
        productPurchase.childRows = purchases;
        return productPurchase;
      });

      setGroupedStockMovements([...groupedPurchases, ...notGroupedMovements]);
    } else setGroupedStockMovements([]);
  }, [stockMovements]);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    const mt = PHYTOSANITARY_STOCK_MOVEMENT_TYPES.find(
      (item) => item.code === movementTypeCode
    );
    if (mt) {
      setMovementType(mt);
      setIsOpenDrawer(true);
    }
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [movementTypeCode, location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      return setSelectedRowIds([]);

      /*  TODO:     if (!isOpenFilesForm && isOpenDrawer) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]); */
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm]);

  const handleChangeMovementType = (
    newValue: PhytosanitaryStockMovementType
  ) => {
    setMovementType(
      PHYTOSANITARY_STOCK_MOVEMENT_TYPES.find((item) => item.code === newValue)
    );
  };

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    setSelectedToEdit(undefined);
    setSelectedRowIds([]);
    setAttachedDocs([]);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(
      `${location.pathname}/${
        selectedToEdit?.movementType?.code ||
        PhytosanitaryStockMovementType.PURCHASE
      }`
    );
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    if (stockMovementRef?.current) stockMovementRef.current.clickClose();
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const phytoStockMovementDeleteMutate = useCrud({
    key: "deletePhytosanitaryStockMovements",
    values: groupedStockMovements?.filter(
      (sm: PhytosanitaryStockMovement) =>
        selectedRowIds.includes(sm?.id || 0) &&
        sm?.type === PhytosanitaryStockMovementType.STOCK_ADJUST
    ),
    onSuccess: () => {
      // Stock movement list updated from useCrud
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const phytoProductPurchasesDeleteMutate = useCrud({
    key: "deletePhytosanitaryProductPurchases",
    values: groupedStockMovements?.filter(
      (sm: PhytosanitaryStockMovement) =>
        selectedRowIds.includes(sm?.id || 0) &&
        sm?.type === PhytosanitaryStockMovementType.PURCHASE
    ),
    onSuccess: () => {
      // Refresh
      refresh();
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    openBackAlertDialog();
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = () => {
    if (stockMovementRef?.current) stockMovementRef.current.clickSave();
  };

  const handleClickEdit = (id: number) => {
    const stockMovement = groupedStockMovements?.find((sm) => sm.id === id);
    setSelectedToEdit(stockMovement);
    setMovementType(stockMovement?.movementType);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormSuccess = (msg: string) => {
    setSnackbarMsg({
      severity: "success",
      message: msg,
    });
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    phytoStockMovementDeleteMutate.mutate({});
    phytoProductPurchasesDeleteMutate.mutate({});
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleClickCreateProduct = () => {
    onChangeTab && onChangeTab(null, StockPhytosanitaryTab.INVENTORY); // Go to inventory tab
  };

  const handleChangeAttachedDocs = (files: Document[]) => {
    setAttachedDocs(files);
  };

  const drawerTitle =
    formMode === FormMode.EDIT
      ? i18n.t("phytoStock.movementsTab.drawerTitleEdit")
      : i18n.t("phytoStock.movementsTab.drawerTitleCreate");
  const drawerBtnText =
    formMode === FormMode.EDIT
      ? i18n.t("words.update")
      : i18n.t("words.create");

  return (
    <>
      <LoadingWithDelay isVisible={isFetching || isFetchingProducts} />
      {phytosanitaryProductsCount === 0 && (
        <SectionTutorial
          steps={stockMovementTutorialSteps}
          activeStep={0}
          onGo={handleClickCreateProduct}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("phytoStock.movementsTab.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "phytoStock.movementsTab.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={
          phytoStockMovementDeleteMutate.isLoading ||
          phytoProductPurchasesDeleteMutate.isLoading
        }
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("phytoStock.movementsTab.backAlertTitle")}
        contentText={i18n.t("phytoStock.movementsTab.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("phytoStock.movementsTab.tableTitle")}
        headerText={i18n.t("phytoStock.movementsTab.headerText")}
        colums={stockMovementsColumns}
        rows={groupedStockMovements}
        childColumns={stockMovementsChildColumns}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Inventory sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("phytoStock.movementsTab.emptyTableTitle")}
        emptyTableSubtitle={i18n.t(
          "phytoStock.movementsTab.emptyTableSubtitle"
        )}
        emptyTableBtnText={i18n.t("phytoStock.movementsTab.emptyTableBtnText")}
        addBtnVariant="contained"
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={selectedToEdit?.movementType?.name || movementType?.name}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={isLoading}
        iconBtn={<AttachmentDocumentsBadge nDocuments={attachedDocs.length} />}
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <StockMovementForm
          ref={stockMovementRef}
          isOpenFilesForm={isOpenFilesForm}
          attachedDocs={attachedDocs}
          movementTypeCode={movementType?.code}
          formMode={formMode}
          selected={selectedToEdit}
          onMovementTypeChange={handleChangeMovementType}
          onCloseAll={closeDrawer}
          onLoading={setIsLoading}
          onSuccess={handleOnFormSuccess}
          onError={handleOnFormError}
          onChangeTab={onChangeTab}
          onChangeAttachedDocs={handleChangeAttachedDocs}
          onCloseFilesForm={handleCloseAttachments}
        />
      </RightDrawer>
    </>
  );
};

interface StockMovementFormProps {
  isOpenFilesForm?: boolean;
  attachedDocs?: Document[];
  movementTypeCode?: PhytosanitaryStockMovementType;
  formMode?: FormMode;
  selected?: PhytosanitaryStockMovement;
  description?: string;
  onMovementTypeChange: (newValue: PhytosanitaryStockMovementType) => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onCloseAll?: () => void;
  onLoading?: (isLoading: boolean) => void;
  onChangeTab?: (
    event: React.SyntheticEvent | null,
    newValue: StockPhytosanitaryTab
  ) => void;
  onChangeAttachedDocs?: (files: Document[]) => void;
  onCloseFilesForm?: () => void;
}
interface StockMovementFormMethods {
  clickSave: () => void;
  clickClose: () => void;
}
const StockMovementForm = forwardRef<
  StockMovementFormMethods,
  StockMovementFormProps
>((props, ref) => {
  const {
    movementTypeCode,
    selected,
    formMode,
    isOpenFilesForm = false,
    attachedDocs = [],
    onCloseFilesForm,
    onChangeAttachedDocs,
    onMovementTypeChange,
    onError,
  } = props;

  const purchaseRef = useRef<StockMovementFormMethods>(null);
  const stockAdjustRef = useRef<StockMovementFormMethods>(null);
  const fertilizerMixRef = useRef<StockMovementFormMethods>(null);

  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (selected) {
      setDescription(selected.description || "");
    }
  }, [selected]);

  useImperativeHandle(
    ref,
    () => {
      return {
        clickSave() {
          if (purchaseRef?.current) purchaseRef.current.clickSave();
          if (stockAdjustRef?.current) stockAdjustRef.current.clickSave();
          if (fertilizerMixRef?.current) fertilizerMixRef.current.clickSave();
        },
        clickClose() {
          if (purchaseRef?.current) purchaseRef.current.clickClose();
          if (stockAdjustRef?.current) stockAdjustRef.current.clickClose();
          if (fertilizerMixRef?.current) fertilizerMixRef.current.clickClose();
        },
      };
    },
    []
  );

  const handleChangeFiles = (files: Document[]) => {
    onChangeAttachedDocs && onChangeAttachedDocs(files);
  };

  return (
    <>
      <MovementTypeForm
        movementTypeCode={selected?.movementType?.code || movementTypeCode}
        description={description}
        isDisabled={formMode === FormMode.EDIT}
        onMovementTypeChange={onMovementTypeChange}
        onDescriptionChange={setDescription}
      />
      {(selected?.movementType?.code || movementTypeCode) ===
        PhytosanitaryStockMovementType.PURCHASE && (
        <PurchaseForm
          ref={purchaseRef}
          {...props}
          description={description}
          onChangeAttachedDocs={handleChangeFiles}
        />
      )}
      {(selected?.movementType?.code || movementTypeCode) ===
        PhytosanitaryStockMovementType.STOCK_ADJUST && (
        <StockAdjustementForm
          ref={stockAdjustRef}
          {...props}
          description={description}
          onChangeAttachedDocs={handleChangeFiles}
        />
      )}
      <FilesForm
        open={isOpenFilesForm}
        files={attachedDocs}
        drawerSubtitle={i18n.t("phytoStock.movementsTab.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
});

interface MovementTypeFormProps {
  movementTypeCode?: PhytosanitaryStockMovementType;
  description?: string;
  isDisabled?: boolean;
  onMovementTypeChange: (type: PhytosanitaryStockMovementType) => void;
  onDescriptionChange: (description: string) => void;
}
const MovementTypeForm = (props: MovementTypeFormProps) => {
  const {
    movementTypeCode,
    description = "",
    isDisabled = false,
    onMovementTypeChange,
    onDescriptionChange,
  } = props;

  const handleChangeMovement = (event: SelectChangeEvent) => {
    onMovementTypeChange(event.target.value as PhytosanitaryStockMovementType);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onDescriptionChange(event.target.value);
  };

  return (
    <BrioCard
      title={i18n.t("phytoStock.movementsTab.movementTypeForm.title")}
      defaultExpanded
      required
    >
      <FormGroup className="form-group">
        <FormControl
          variant="outlined"
          className="form-control"
          disabled={isDisabled}
        >
          <InputLabel required>
            {i18n.t("phytoStock.movementsTab.movementTypeForm.source")}
          </InputLabel>
          <Select
            id="source"
            label={i18n.t("phytoStock.movementsTab.movementTypeForm.source")}
            value={movementTypeCode}
            onChange={handleChangeMovement}
          >
            {PHYTOSANITARY_STOCK_MOVEMENT_TYPES.filter(
              (movementType) =>
                movementType.code !== PhytosanitaryStockMovementType.TREATMENT
            ).map((movementType) => (
              <MenuItem key={movementType.id} value={movementType.code}>
                {movementType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <TextField
            id="description"
            name="description"
            className="form-input"
            variant="outlined"
            type="text"
            label={i18n.t(
              "phytoStock.movementsTab.movementTypeForm.description"
            )}
            multiline
            value={description}
            onChange={handleChangeDescription}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const PurchaseForm = forwardRef<
  StockMovementFormMethods,
  StockMovementFormProps
>((props, ref) => {
  const {
    formMode,
    selected,
    description,
    attachedDocs,
    onSuccess,
    onError,
    onCloseAll,
    onLoading,
    onChangeTab,
    onChangeAttachedDocs,
  } = props;

  const queryClient = useQueryClient();
  const { selectedABPartition, agroBusinessPartitions } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const resetForm = () => {
    formik.setStatus(null);
    formik.setErrors({});
    formik.resetForm();
  };

  const closeAll = () => {
    onCloseAll && onCloseAll();
    resetForm();
  };

  const onFetchPhytosanitaryProductPurchaseSuccess = (
    phytoPurchase: PhytosanitaryProductPurchase
  ) => {
    formik.setValues(phytoPurchase);
    // Update attached docs state variable
    if (onChangeAttachedDocs && phytoPurchase.documents)
      onChangeAttachedDocs(phytoPurchase.documents);
    // Update items in stock movement list
    updateStockMovements({
      queryClient,
    });
  };

  const { isFetching: isUpdating } = useFetch<PhytosanitaryProductPurchase>({
    queryKey: ["phytosanitaryProductPurchase", selected?.purchase?.id],
    refetchOnWindowFocus: false, // Input file not work if true
    selected: selected?.purchase,
    enabled: !!selected?.purchase?.id,
    onSuccess: onFetchPhytosanitaryProductPurchaseSuccess,
    onError,
  });

  const customValidator = (values: PhytosanitaryProductPurchase) => {
    const errors: any = {};
    if (!values.stockMovements || values.stockMovements.length === 0)
      errors.stockMovements = i18n.t("formErrors.requiredField");
    if (!values.date) {
      errors.date = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: PhytosanitaryProductPurchase) => {
    switch (formMode) {
      case FormMode.CREATE:
        phytoProductPurchaseCreateMutation.mutate({});
        break;
      case FormMode.EDIT:
        phytoProductPurchaseEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<PhytosanitaryProductPurchase>({
    initialValues: new PhytosanitaryProductPurchase(),
    validate: customValidator,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formMode === FormMode.CREATE && selectedABPartition)
      formik.setFieldValue("agroBusinessPartition", selectedABPartition);
  }, [formMode]);

  useEffect(() => {
    formik.setFieldValue("description", description);
  }, [description]);

  useEffect(() => {
    if (attachedDocs) formik.setFieldValue("documents", attachedDocs);
  }, [attachedDocs]);

  // Callback from parent
  useImperativeHandle(
    ref,
    () => {
      return {
        async clickSave() {
          const formErrors = await formik.validateForm();
          if (Object.keys(formErrors).length > 0) {
            forceExpandCards();
            onError &&
              onError({
                severity: "warning",
                message: i18n.t("formErrors.addAtLeastOneBuyProduct"),
              });
            // Mark all fields as touched to show errors
            formik.setTouched(formErrors as FormikTouched<any>);
          } else formik.submitForm();
        },
        clickClose() {
          resetForm();
        },
      };
    },
    []
  );

  const phytoProductPurchaseCreateMutation =
    useCrud<PhytosanitaryProductPurchase>({
      key: "postPhytosanitaryProductPurchase",
      values: formik.values,
      onSuccess: (phytoPurchase: PhytosanitaryProductPurchase) => {
        // Update stock movement list
        updateStockMovements({
          queryClient,
        });
        onSuccess && onSuccess(i18n.t("apiResponses.createSuccess"));
        closeAll();
      },
      onError,
    });

  const phytoProductPurchaseEditMutation =
    useCrud<PhytosanitaryProductPurchase>({
      key: "putPhytosanitaryProductPurchase",
      values: formik.values,
      onSuccess: (phytosanitaryPurchase: PhytosanitaryProductPurchase) => {
        // Update stock movement list
        queryClient.refetchQueries({
          queryKey: ["phytosanitaryStockMovements"],
          type: "active",
        });
        onSuccess && onSuccess(i18n.t("apiResponses.updateSuccess"));
        closeAll();
      },
      onError,
    });

  onLoading &&
    onLoading(
      phytoProductPurchaseCreateMutation.isLoading ||
        phytoProductPurchaseEditMutation.isLoading
    );

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <PurchaseDataForm
        formik={formik}
        forceExpanded={expandCards}
        onSuccess={onSuccess}
        onError={onError}
      />
      <PurchaseProductsForm
        formik={formik}
        onSuccess={onSuccess}
        onError={onError}
        onChangeTab={onChangeTab}
      />
      {agroBusinessPartitions?.length > 0 && formMode === FormMode.EDIT && (
        <PartitionForm
          name="agroBusinessPartition"
          formik={formik}
          agroBusinessPartitions={agroBusinessPartitions}
        />
      )}
    </>
  );
});

interface PurchaseFormProps {
  formik: FormikProps<PhytosanitaryProductPurchase>;
  forceExpanded?: boolean;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTab?: (
    event: React.SyntheticEvent | null,
    newValue: StockPhytosanitaryTab
  ) => void;
}
const PurchaseDataForm = (props: PurchaseFormProps) => {
  const { formik, forceExpanded } = props;

  const handleChangeDate = (date: any) => {
    const dateStr = date?.format("YYYY-MM-DD");
    if (dateStr) {
      formik.setFieldValue("date", dateStr);
      formik.setFieldValue("dateFormatted", dateConverter({ date: dateStr }));
    }
  };

  return (
    <BrioCard
      title={i18n.t("phytoStock.movementsTab.purchaseDataForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={i18n.t("phytoStock.movementsTab.purchaseDataForm.date")}
            value={
              formik.values?.date
                ? moment(formik.values.date, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeDate}
          />
          {formik.touched.date && formik.errors.date && (
            <FormLabel className="form-label">Campo requerido</FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="providerName"
            label={i18n.t(
              "phytoStock.movementsTab.purchaseDataForm.providerName"
            )}
            placeholder={i18n.t(
              "phytoStock.movementsTab.purchaseDataForm.providerNamePlaceholder"
            )}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="providerId"
            label={i18n.t(
              "phytoStock.movementsTab.purchaseDataForm.providerId"
            )}
            placeholder={i18n.t(
              "phytoStock.movementsTab.purchaseDataForm.providerIdPlaceholder"
            )}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="invoiceNumber"
            label={i18n.t(
              "phytoStock.movementsTab.purchaseDataForm.invoiceNumber"
            )}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const PurchaseProductsForm = (props: PurchaseFormProps) => {
  const phytosanitaryProductPurchaseColumns: Column[] = [
    {
      id: "product.name",
      label: i18n.t("phytoStock.movementsTab.purchaseProductsForm.column.name"),
      numeric: false,
    },
    {
      id: "quantity",
      label: i18n.t(
        "phytoStock.movementsTab.purchaseProductsForm.column.quantity"
      ),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "product.measurementUnit.name",
    },
    {
      id: "priceFormatted",
      label: i18n.t(
        "phytoStock.movementsTab.purchaseProductsForm.column.priceByUnit"
      ),
      numeric: true,
    },
  ];

  const { formik, onError, onChangeTab } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [editSelected, setEditSelected] = useState<
    PhytosanitaryStockMovement | undefined
  >(undefined);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => setIsOpenDialog(false);

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };
  const handleClickClose = () => {
    closeDialog();
    setEditSelected(undefined);
  };

  const handleClickSave = (phytoStockMovement: PhytosanitaryStockMovement) => {
    const stockMovements = formik.values?.stockMovements || [];
    const newStockMovements =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            stockMovements,
            phytoStockMovement,
            "idx"
          )
        : updateItemOfList(stockMovements, phytoStockMovement, "idx");
    formik.setFieldValue("stockMovements", newStockMovements);
    setFormMode(FormMode.CREATE);
    setSelectedRows([]);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    setFormMode(FormMode.EDIT);
    setEditSelected(formik.values?.stockMovements?.find((sm) => sm.idx === id));
    openDialog();
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      const stockMovements = formik.values?.stockMovements || [];
      const newStockMovements = deleteItemsOfListByField(
        stockMovements,
        ids,
        "idx"
      );
      formik.setFieldValue("stockMovements", newStockMovements);
      setFormMode(FormMode.CREATE);
      setSelectedRows([]);
    }
  };

  const handleClickAddNew = () => {
    closeDialog();
    onChangeTab && onChangeTab(null, StockPhytosanitaryTab.INVENTORY); // Go to inventory tab
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t(
          "phytoStock.movementsTab.purchaseProductsForm.tableTitle"
        )}
        headerText={i18n.t(
          "phytoStock.movementsTab.purchaseProductsForm.headerText"
        )}
        colums={phytosanitaryProductPurchaseColumns}
        rows={formik.values?.stockMovements || []}
        selectedRows={selectedRows}
        emptyTableCard={false}
        required
        onChangeSelectedRows={setSelectedRows}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogPhytoPurchaseProduct
        title={i18n.t(
          "phytoStock.movementsTab.purchaseProductsForm.dialog.title"
        )}
        body={i18n.t(
          "phytoStock.movementsTab.purchaseProductsForm.dialog.description"
        )}
        open={isOpenDialog}
        movementType={PhytosanitaryStockMovementType.PURCHASE}
        selected={editSelected}
        stockMovements={formik.values?.stockMovements || []}
        onConfirm={handleClickSave}
        onClose={handleClickClose}
        onAddNew={handleClickAddNew}
        onError={onError}
      />
    </>
  );
};

const StockAdjustementForm = forwardRef<
  StockMovementFormMethods,
  StockMovementFormProps
>((props, ref) => {
  const {
    formMode,
    selected,
    description,
    attachedDocs,
    onSuccess,
    onError,
    onCloseAll,
    onLoading,
    onChangeTab,
    onChangeAttachedDocs,
  } = props;

  const queryClient = useQueryClient();
  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenEmptyPriceAlertDialog, setIsOpenEmptyPriceAlertDialog] =
    useState<boolean>(false);

  const resetForm = () => {
    formik.setStatus(null);
    formik.setErrors({});
    formik.resetForm();
  };

  const closeAll = () => {
    onCloseAll && onCloseAll();
    resetForm();
  };

  const onFetchPhytosanitaryStockMovementSuccess = (
    phytoStockMovement: PhytosanitaryStockMovement
  ) => {
    formik.setValues(phytoStockMovement);
    // Update attached docs state variable
    if (onChangeAttachedDocs && phytoStockMovement.documents)
      onChangeAttachedDocs(phytoStockMovement.documents);
    // Update movement in stock movement list
    queryClient.setQueryData<PhytosanitaryStockMovement[]>(
      [
        "phytosanitaryStockMovements",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      (oldData) => updateItemOfList(oldData, phytoStockMovement, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<PhytosanitaryStockMovement>({
    queryKey: ["phytosanitaryStockMovement", selected?.id],
    selected,
    enabled: !!selected?.id,
    onSuccess: onFetchPhytosanitaryStockMovementSuccess,
    onError,
  });

  const yupValidator = Yup.object().shape({
    product: Yup.object().required(),
    quantity: Yup.number().required().notOneOf([0]),
  });

  const handleSubmit = (values: PhytosanitaryStockMovement) => {
    if (!values.priceByUnit || values.priceByUnit <= 0)
      openEmptyPriceAlertDialog();
    else {
      cancelEmptyPriceAlertDialog();
      submitForm();
    }
  };

  const submitForm = () => {
    switch (formMode) {
      case FormMode.CREATE:
        phytoStockMovementCreateMutation.mutate({});
        break;
      case FormMode.EDIT:
        phytoStockMovementEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  // Set movement type as stock adjust
  const formik = useFormik<PhytosanitaryStockMovement>({
    initialValues:
      new PhytosanitaryStockMovement().mapToClass({
        type: PhytosanitaryStockMovementType.STOCK_ADJUST,
      }) || new PhytosanitaryStockMovement(),
    validationSchema: yupValidator,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formMode === FormMode.CREATE && selectedABPartition)
      formik.setFieldValue("agroBusinessPartition", selectedABPartition);
  }, [formMode]);

  useEffect(() => {
    formik.setFieldValue("description", description);
  }, [description]);

  useEffect(() => {
    if (attachedDocs) formik.setFieldValue("documents", attachedDocs);
  }, [attachedDocs]);

  // Callback from parent
  useImperativeHandle(
    ref,
    () => {
      return {
        async clickSave() {
          const errors = await formik.validateForm();
          if (Object.keys(errors).length > 0) {
            forceExpandCards();
            onError &&
              onError({
                severity: "warning",
                message: i18n.t("formErrors.requiredFields"),
              });
            // Mark all fields as touched to show errors
            formik.setTouched(errors as FormikTouched<any>);
          } else formik.submitForm();
        },
        clickClose() {
          resetForm();
        },
      };
    },
    []
  );

  const phytoStockMovementCreateMutation = useCrud<PhytosanitaryStockMovement>({
    key: "postPhytosanitaryStockMovement",
    values: formik.values,
    onSuccess: () => {
      onSuccess && onSuccess(i18n.t("apiResponses.createSuccess"));
      closeAll();
    },
    onError,
  });

  const phytoStockMovementEditMutation = useCrud<PhytosanitaryStockMovement>({
    key: "putPhytosanitaryStockMovement",
    values: formik.values,
    onSuccess: () => {
      onSuccess && onSuccess(i18n.t("apiResponses.updateSuccess"));
      closeAll();
    },
    onError,
  });

  onLoading &&
    onLoading(
      phytoStockMovementCreateMutation.isLoading ||
        phytoStockMovementEditMutation.isLoading
    );

  const openEmptyPriceAlertDialog = () => setIsOpenEmptyPriceAlertDialog(true);
  const cancelEmptyPriceAlertDialog = () =>
    setIsOpenEmptyPriceAlertDialog(false);

  const confirmDialog = () => {
    cancelEmptyPriceAlertDialog();
    submitForm();
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <StockAdjustProductForm
        formik={formik}
        forceExpanded={expandCards}
        onSuccess={onSuccess}
        onError={onError}
        onChangeTab={onChangeTab}
      />
      <StockAdjustDateForm
        formik={formik}
        onSuccess={onSuccess}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 && formMode === FormMode.EDIT && (
        <PartitionForm
          name="agroBusinessPartition"
          formik={formik}
          agroBusinessPartitions={agroBusinessPartitions}
        />
      )}
      <FormAlertDialog
        id="confirmNotPriceAddedAlertDialog"
        title={i18n.t(
          "components.dialogPhytoPurchaseProduct.confirmNotPriceAddedAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogPhytoPurchaseProduct.confirmNotPriceAddedAlertDialogDescription"
        )}
        open={isOpenEmptyPriceAlertDialog}
        formAction={FormMode.DELETE}
        confirmBtnText={i18n.t(
          "components.dialogPhytoPurchaseProduct.confirmNotPriceAddedAlertDialogConfirmBtnLabel"
        )}
        cancelBtnText={i18n.t(
          "components.dialogPhytoPurchaseProduct.confirmNotPriceAddedAlertDialogCancelBtnLabel"
        )}
        onCancel={cancelEmptyPriceAlertDialog}
        onConfirm={confirmDialog}
      />
    </>
  );
});

interface StockAdjustFormProps {
  formik: FormikProps<PhytosanitaryStockMovement>;
  formMode?: FormMode;
  forceExpanded?: boolean;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTab?: (
    event: React.SyntheticEvent | null,
    newValue: StockPhytosanitaryTab
  ) => void;
}
const StockAdjustProductForm = (props: StockAdjustFormProps) => {
  const { formik, formMode, forceExpanded, onError, onChangeTab } = props;

  const { selectedABAccount, selectedABPartition } = useSession();

  const { data: phytosanitaryProducts, isFetching: isFetchingProducts } =
    useFetch<PhytosanitaryProduct[]>({
      queryKey: [
        "phytosanitaryProducts",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
      onError,
    });

  const {
    data: phytosanitaryProductStock,
    isFetching: isFetchingProductStock,
  } = useFetch<any>({
    queryKey: ["phytosanitaryProductStock", formik.values?.product?.id],
    enabled: !!formik.values?.product?.id,
    selected: formik.values?.product,
    onError,
  });

  const handleClickNewOption = () =>
    onChangeTab && onChangeTab(null, StockPhytosanitaryTab.INVENTORY); // Go to inventory tab

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const quantity = Number(value);
    formik.setFieldValue("quantity", value.length === 0 ? "" : quantity);
    if (formik.values.priceByUnit && formik.values.priceByUnit > 0)
      formik.setFieldValue(
        "totalPrice",
        Math.round(formik.values.priceByUnit * quantity * 100) / 100
      );
    else if (formik.values.totalPrice && formik.values.totalPrice > 0) {
      const priceByUnit =
        Math.round((formik.values.totalPrice / quantity) * 100) / 100;
      formik.setFieldValue("priceByUnit", priceByUnit);
      formik.setFieldValue(
        "priceFormatted",
        formik.values.getPriceFormatted(priceByUnit)
      );
    }
  };

  const handleChangePriceByUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const priceByUnit = Number(value);
    formik.setFieldValue("priceByUnit", value.length === 0 ? "" : priceByUnit);
    formik.setFieldValue(
      "priceFormatted",
      formik.values.getPriceFormatted(priceByUnit)
    );
    if (formik.values.quantity)
      formik.setFieldValue(
        "totalPrice",
        Math.round(priceByUnit * formik.values.quantity * 100) / 100
      );
  };

  const handleChangeTotalPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const totalPrice = Number(value);
    formik.setFieldValue("totalPrice", value.length === 0 ? "" : totalPrice);
    if (formik.values.quantity) {
      const priceByUnit =
        Math.round((totalPrice / formik.values.quantity) * 100) / 100;
      formik.setFieldValue("priceByUnit", priceByUnit);
      formik.setFieldValue(
        "priceFormatted",
        formik.values.getPriceFormatted(priceByUnit)
      );
    }
  };

  // If editing, ignore saved quantity to calculate the stock
  const resultStock =
    Math.round(
      ((phytosanitaryProductStock?.stock || 0) +
        (formik.values?.quantity || 0) -
        (formMode === FormMode.EDIT ? formik.values?.beforeQuantity || 0 : 0)) *
        100
    ) / 100;

  return (
    <BrioCard
      title={i18n.t("phytoStock.movementsTab.stockAdjustForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl className="form-control">
          {!isFetchingProducts && phytosanitaryProducts?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewOption}>
              {i18n.t("phytoStock.movementsTab.stockAdjustForm.emptyList")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="product"
            label={i18n.t("phytoStock.movementsTab.stockAdjustForm.product")}
            placeholder={i18n.t(
              "phytoStock.movementsTab.stockAdjustForm.productPlaceholder"
            )}
            options={phytosanitaryProducts || []}
            optionLabelFieldName="name"
            required
            startIcon={Search}
            loading={isFetchingProducts}
            addNewOption
            onClickNewOption={handleClickNewOption}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row"
            formik={formik}
            name="quantity"
            label="+/-"
            placeholder={i18n.t(
              "phytoStock.movementsTab.stockAdjustQtyForm.quantityPlaceholder"
            )}
            type="number"
            required
            valueUnit={formik.values?.product?.measurementUnit?.name}
            disabled={!formik.values?.product}
            onChange={handleChangeQuantity}
          />
          <TextField
            id="resultStock"
            name="resultStock"
            disabled
            className="form-input-row"
            variant="outlined"
            type="number"
            label={i18n.t(
              "phytoStock.movementsTab.stockAdjustQtyForm.resultStock"
            )}
            InputProps={{
              endAdornment: isFetchingProductStock ? (
                <CircularProgress size={20} />
              ) : (
                <InputAdornment position="end">
                  {phytosanitaryProductStock?.unit || ""}
                </InputAdornment>
              ),
            }}
            value={resultStock}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="priceByUnit"
            label={i18n.t("components.dialogPhytoPurchaseProduct.priceByUnit")}
            type="number"
            disabled={
              !formik.values?.product?.measurementUnit?.name ||
              (formik.values.quantity || 0) <= 0
            }
            valueUnit={
              formik.values?.product?.measurementUnit?.name &&
              `€/${formik.values?.product?.measurementUnit?.name}`
            }
            onChange={handleChangePriceByUnit}
          />
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="totalPrice"
            label={i18n.t("components.dialogPhytoPurchaseProduct.totalPrice")}
            type="number"
            disabled={
              !formik.values?.product?.measurementUnit?.name ||
              (formik.values.quantity || 0) <= 0
            }
            valueUnit="€"
            onChange={handleChangeTotalPrice}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const StockAdjustDateForm = (props: StockAdjustFormProps) => {
  const { formik } = props;

  const handleChangeDate = (date: any) => {
    const dateStr = date?.format("YYYY-MM-DD");
    if (dateStr) {
      formik.setFieldValue("date", dateStr);
      formik.setFieldValue("dateFormatted", dateConverter({ date: dateStr }));
    }
  };

  return (
    <BrioCard
      title={i18n.t("phytoStock.movementsTab.stockAdjustDateForm.title")}
      optional
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={i18n.t("phytoStock.movementsTab.stockAdjustDateForm.date")}
            value={
              formik.values?.date
                ? moment(formik.values.date, "YYYY-MM-DD")
                : moment()
            }
            onChange={handleChangeDate}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
