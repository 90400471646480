import Person from "./Person";
import { AuthProviders, FBPersonRole } from "../constants/enums";

class UserData {
  constructor(
    public person: Person | null,
    public idToken?: string,
    public authProvider?: AuthProviders,
    public role?: FBPersonRole
  ) {}
}
export default UserData;
