import FBLegalContext from "./_apiModels/FBLegalContext";

class LegalContext extends FBLegalContext {
  constructor() {
    super();
  }

  mapToClass(obj: any): LegalContext | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default LegalContext;
