import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  createFilterOptions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import FormikAutocomplete from "../elements/FormikAutocomplete";

import AgroBusinessClassification from "../../models/agrobusiness/AgroBusinessClassification";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

interface DialogABClassTypeProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (classif: AgroBusinessClassification) => void;
  selected?: AgroBusinessClassification | null;
  classifications?: AgroBusinessClassification[];
}

const filter = createFilterOptions();

const DialogABClassType = (props: DialogABClassTypeProps) => {
  const ABClassificationValidatorSchema = Yup.object().shape({
    classificationType: Yup.object().required(),
    startDate: Yup.string().required(),
  });

  const { open, onClose, onConfirm, selected, classifications = [] } = props;

  const { selectedABAccount } = useSession();

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: AgroBusinessClassification) => {
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new AgroBusinessClassification(),
    validationSchema: ABClassificationValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: agroBusinessClassificationTypes, isFetching } = useFetch<
    AgroBusinessClassification[]
  >({
    queryKey: [
      "agroBusinessClassificationTypes",
      selectedABAccount?.context?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const handleConfirm = () => {
    formik.submitForm();
  };

  // Filter selected sectors
  const abClassificationsFilter = (
    options: AgroBusinessClassification[],
    params: any
  ) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (s: any) =>
        !classifications.find(
          (cs: AgroBusinessClassification) =>
            cs.classificationType?.code === s.code
        )
    );
    if (selected)
      filterSelected.unshift(selected as AgroBusinessClassification);
    return filterSelected;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {selected
          ? i18n.t("components.dialogABClassType.titleEdit")
          : i18n.t("components.dialogABClassType.titleCreate")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="classificationType"
              label={i18n.t("components.dialogABClassType.classificationType")}
              optionLabelFieldName="name"
              required
              filterOptions={abClassificationsFilter}
              options={agroBusinessClassificationTypes || []}
              loading={isFetching}
              disabled={!!selected}
            />
          </FormControl>
          <FormControl className="form-control">
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("components.dialogABClassType.startDate") + "*"}
              value={
                formik.values?.startDate
                  ? moment(formik.values.startDate, "YYYY-MM-DD")
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue("startDate", date?.format("YYYY-MM-DD"))
              }
            />
            {formik.touched.startDate && formik.errors.startDate && (
              <FormLabel className="form-label">
                {i18n.t("formErrors.requiredField")}
              </FormLabel>
            )}
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogABClassType;
