import { FBESiexRequestStatus } from "../../constants/enums";
import { dateConverter } from "../../helpers/utils";
import FBPhytosanitaryTreatment from "../_apiModels/phyto/FBPhytosanitaryTreatment";

class PhytosanitaryTreatment extends FBPhytosanitaryTreatment {
  dateRange?: string;

  constructor(dateRange?: string) {
    super();
    this.dateRange = dateRange;
  }

  mapToClass(obj: any): PhytosanitaryTreatment | undefined {
    if (!obj) return undefined;
    super.mapToBaseClass(obj);
    this.dateRange =
      this.startDate && this.endDate
        ? `${dateConverter({ date: this.startDate })} - ${dateConverter({
            date: this.endDate,
          })}`
        : "";
    if (this.siexStatus === null) this.siexStatus = FBESiexRequestStatus.NOSYNC;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    if (this.siexStatus === FBESiexRequestStatus.NOSYNC) obj.siexStatus = null;

    // Remove not API fields
    delete obj.dateRange;
    return obj;
  }
}

export default PhytosanitaryTreatment;
