import ReproductiveMaterialType from "./ReproductiveMaterialType";

class ReproductiveMaterialDetail {
  id?: number;
  name?: string;
  code?: string;
  type?: ReproductiveMaterialType;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    type?: ReproductiveMaterialType
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.type = type;
  }

  mapToClass(obj: any): ReproductiveMaterialDetail | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.type = new ReproductiveMaterialType().mapToClass(obj?.type);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    return obj;
  }
}

export default ReproductiveMaterialDetail;
