class FBLegalContext {
  id?: number;
  name?: string;
  description?: string;

  constructor(id?: number, name?: string, description?: string) {
    this.id = id;
    this.name = name;
    this.description = description;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.description = obj?.description;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBLegalContext;
