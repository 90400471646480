import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  browserLocalPersistence,
  setPersistence,
} from "firebase/auth";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
} from "@mui/material";
import { Google, Apple, Microsoft, Email, WhatsApp } from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import AlertSnackbar from "../components/elements/AlertSnackbar";
import TestBanner from "../components/banners/TestBanner";
import FormikTextField, {
  helperTextStyle,
} from "../components/elements/FormikTextField";

import LocalAuthSendCodeRequest from "../models/auth/LocalAuthSendCodeRequest";

import useCrud from "../hooks/useCrud";

import { SnackbarInterface } from "../constants/interfaces";
import { AuthProviders } from "../constants/enums";
import { isValidEmail } from "../helpers/validators";
import { PUBLIC_ROUTES } from "../routes/routeNames";

const getProvider = (method: AuthProviders) => {
  switch (method) {
    case AuthProviders.GOOGLE:
      return new GoogleAuthProvider();
    case AuthProviders.APPLE:
      const appleAuthProvider = new OAuthProvider("apple.com");
      appleAuthProvider.addScope("email"); // Not included by default
      return appleAuthProvider;
    case AuthProviders.MICROSOFT:
      return new OAuthProvider("microsoft.com");
    default:
      return null;
  }
};

const Login = () => {
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [tabValue, setTabValue] = useState<number>(0);

  // Preload recaptcha script
  useEffect(() => {
    const loadRecaptchaScript = () => {
      const url = `https://www.google.com/recaptcha/enterprise.js?render=${
        process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ""
      }`;
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="login">
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <TestBanner />

      <div className="container">
        <div className="image-section">
          <div className="logo-container">
            <div className="logo" />
            <h1>FarmLog</h1>
          </div>
          <h2>{i18n.t("words.fieldBook")}</h2>
          <h3>{i18n.t("login.loginDescription_v2")}</h3>
          <h4>{i18n.t("login.loginWhatsappLabel")}</h4>
        </div>
        <div className="login-section">
          <h3 style={{ padding: "24px", paddingBottom: "16px" }}>
            {i18n.t("words.login")}
          </h3>
          <AppBar
            className="tabbar"
            component="nav"
            position="static"
            elevation={1}
          >
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                className="tab"
                label={i18n.t("login.tab.whatsapp")}
                icon={<WhatsApp />}
              />
              <Tab
                className="tab"
                label={i18n.t("login.tab.email")}
                icon={<Email />}
              />
            </Tabs>
          </AppBar>
          {tabValue === 1 ? (
            <LoginWithEmailSection onError={setSnackbarMsg} />
          ) : (
            <LoginWithWhatsappSection onError={setSnackbarMsg} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;

interface LoginSectionProps {
  onError?: (snackBarError: SnackbarInterface) => void;
}
const LoginWithEmailSection = (props: LoginSectionProps) => {
  const { onError } = props;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<AuthProviders | null>(null);
  const [isCheckingCaptcha, setIsCheckingCaptcha] = useState<boolean>(false);

  const validateForm = (values: LocalAuthSendCodeRequest) => {
    const errors: any = {};
    if (values?.email && values.email.length > 0 && !isValidEmail(values.email))
      errors.email = i18n.t("formErrors.invalidEmail");
    if (!values.reCaptchaToken)
      errors.reCaptchaToken = i18n.t("formErrors.requiredField");
    return errors;
  };

  const handleSubmit = (values: LocalAuthSendCodeRequest) => {
    sendAuthCodeByEmailMutation.mutate(values);
  };

  const formik = useFormik({
    initialValues: new LocalAuthSendCodeRequest(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const sendAuthCodeByEmailMutation = useCrud<LocalAuthSendCodeRequest>({
    key: "sendAuthCodeByEmail",
    values: formik.values,
    onSuccess: () => {
      navigate(PUBLIC_ROUTES.EMAIL_VERIFICATION, {
        state: { email: formik.values.email },
      });
    },
    onError,
  });

  const onLoginSuccess = async (authProvider: AuthProviders) => {
    setIsLoading(null);
    // Reload the page to get the new user data
    navigate(0);
    // Send event to GA4
    ReactGA.event("login", { method: authProvider });
  };

  const onLoginError = (error: any) => {
    setIsLoading(null);
    let message = "";
    switch (error.code) {
      case "auth/cancelled-popup-request":
        message = i18n.t("login.cancelledPopupError");
        break;
      case "auth/popup-blocked":
        message = i18n.t("login.popupBlockedError");
        break;
      case "auth/popup-closed-by-user":
        message = i18n.t("login.popupClosedByUserError");
        break;
      case "auth/network-request-failed":
        message = i18n.t("login.networkRequestFailedError");
        break;
      default:
        message = i18n.t("login.loginError");
        break;
    }
    onError && onError({ severity: "error", message });
  };

  const handleLoginProvider = async (method: AuthProviders) => {
    try {
      setIsLoading(method);
      const auth = getAuth();
      auth.useDeviceLanguage();
      await setPersistence(auth, browserLocalPersistence);
      const provider = getProvider(method);
      if (provider) {
        await signInWithPopup(auth, provider);
        onLoginSuccess(method);
      }
    } catch (error) {
      onLoginError(error);
      Sentry.captureException(error);
    }
  };

  const handleLoginEmailCode = () => {
    if (!formik.values.email || formik.values.email.length === 0)
      formik.setErrors({ email: i18n.t("formErrors.requiredField") });
    else if (!isValidEmail(formik.values.email))
      formik.setErrors({ email: i18n.t("formErrors.invalidEmail") });
    else {
      try {
        window.grecaptcha.enterprise.ready(() => {
          setIsCheckingCaptcha(true);
          window.grecaptcha.enterprise
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "", {
              action: "send_auth_code_email",
            })
            .then((token) => {
              formik.setFieldValue("reCaptchaToken", token);
              sendAuthCodeByEmailMutation.mutate({
                email: formik.values.email,
                reCaptchaToken: token,
              } as LocalAuthSendCodeRequest);
              setIsCheckingCaptcha(false);
            })
            .catch((error) => {
              formik.setFieldValue("reCaptchaToken", null);
              setIsCheckingCaptcha(false);
              onError &&
                onError({
                  severity: "error",
                  message: i18n.t("apiResponses.recaptchaError"),
                });
              setTimeout(() => {
                navigate(0);
              }, 2000);
              // Send recaptcha error to Sentry
              Sentry.withScope((scope) => {
                scope.setExtra("data", "Google recatpcha error");
                Sentry.captureException(error);
              });
            });
        });
      } catch (error) {
        formik.setFieldValue("reCaptchaToken", null);
        setIsCheckingCaptcha(false);
        onError &&
          onError({
            severity: "error",
            message: i18n.t("apiResponses.recaptchaError"),
          });
        setTimeout(() => {
          navigate(0);
        }, 2000);
        // Send recaptcha error to Sentry
        Sentry.withScope((scope) => {
          scope.setExtra("data", "Typescript recaptcha global type error");
          Sentry.captureException(error);
        });
      }
    }
  };

  return (
    <div className="login-section-content">
      <div className="login-email">
        <FormikTextField
          formik={formik}
          fullWidth
          name="email"
          type="email"
          label={i18n.t("login.emailLabel")}
          helperLabel={i18n.t("login.emailHelperText")}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 1 }}
          endIcon={
            (isCheckingCaptcha || sendAuthCodeByEmailMutation.isLoading) && (
              <CircularProgress size={20} />
            )
          }
          disabled={isCheckingCaptcha || sendAuthCodeByEmailMutation.isLoading}
          onClick={handleLoginEmailCode}
        >
          {i18n.t("login.loginWithEmailCode")}
        </Button>
      </div>
      <Divider sx={{ width: "100%", mt: 2, mb: 2 }}>
        {i18n.t("words.or")}
      </Divider>
      <div className="login-providers">
        <Button
          className="provider-btn-container"
          variant="outlined"
          endIcon={
            isLoading === AuthProviders.GOOGLE && <CircularProgress size={20} />
          }
          onClick={() => handleLoginProvider(AuthProviders.GOOGLE)}
        >
          <div className="provider-btn-content">
            <Google />
            <span>{i18n.t("login.loginWithGoogleText")}</span>
          </div>
        </Button>
        <Button
          className="provider-btn-container"
          variant="outlined"
          endIcon={
            isLoading === AuthProviders.APPLE && <CircularProgress size={20} />
          }
          onClick={() => handleLoginProvider(AuthProviders.APPLE)}
        >
          <div className="provider-btn-content">
            <Apple />
            <span>{i18n.t("login.loginWithAppleText")}</span>
          </div>
        </Button>
        <Button
          className="provider-btn-container"
          variant="outlined"
          endIcon={
            isLoading === AuthProviders.MICROSOFT && (
              <CircularProgress size={20} />
            )
          }
          onClick={() => handleLoginProvider(AuthProviders.MICROSOFT)}
        >
          <div className="provider-btn-content">
            <Microsoft />
            <span>{i18n.t("login.loginWithMicrosoftText")}</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

const LoginWithWhatsappSection = (props: LoginSectionProps) => {
  const { onError } = props;

  const navigate = useNavigate();

  const [isCheckingCaptcha, setIsCheckingCaptcha] = useState<boolean>(false);
  const [userNotRegisteredError, setUserNotRegisteredError] =
    useState<boolean>(false);

  const validateForm = (values: LocalAuthSendCodeRequest) => {
    const errors: any = {};
    if (!matchIsValidTel(values?.phone || ""))
      errors.phone = i18n.t("formErrors.invalidPhoneNumber");
    if (!values.reCaptchaToken)
      errors.reCaptchaToken = i18n.t("formErrors.requiredField");
    return errors;
  };

  const handleSubmit = (values: LocalAuthSendCodeRequest) => {
    sendAuthCodeByWhatsappMutation.mutate(values);
  };

  const formik = useFormik({
    initialValues: new LocalAuthSendCodeRequest(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const sendAuthCodeByWhatsappMutation = useCrud<LocalAuthSendCodeRequest>({
    key: "sendAuthCodeByWhatsapp",
    values: formik.values,
    onSuccess: () => {
      navigate(PUBLIC_ROUTES.WHATSAPP_VERIFICATION, {
        state: { phone: formik.values.phone },
      });
    },
    onError: (snackbarError: SnackbarInterface) => {
      if (
        snackbarError.message ===
        i18n.t("apiResponses.userNotRegisteredByWhatsappError")
      ) {
        setUserNotRegisteredError(true);
        onError &&
          onError({
            severity: "error",
            message: i18n.t("apiResponses.userNotRegisteredByWhatsappError"),
          });
      }
    },
  });

  const handleLoginWhatsappCode = () => {
    if (!formik.values.phone || formik.values.phone.length === 0) {
      formik.setErrors({ phone: i18n.t("formErrors.requiredField") });
      formik.setFieldTouched("phone", true);
    } else if (!matchIsValidTel(formik.values?.phone || "")) {
      formik.setErrors({ phone: i18n.t("formErrors.invalidPhoneNumber") });
      formik.setFieldTouched("phone", true);
    } else {
      try {
        window.grecaptcha.enterprise.ready(() => {
          setIsCheckingCaptcha(true);
          window.grecaptcha.enterprise
            .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "", {
              action: "send_auth_code_whatsapp",
            })
            .then((token) => {
              formik.setFieldValue("reCaptchaToken", token);
              sendAuthCodeByWhatsappMutation.mutate({
                phone: formik.values.phone,
                reCaptchaToken: token,
              } as LocalAuthSendCodeRequest);
              setIsCheckingCaptcha(false);
            })
            .catch((error) => {
              formik.setFieldValue("reCaptchaToken", null);
              setIsCheckingCaptcha(false);
              onError &&
                onError({
                  severity: "error",
                  message: i18n.t("apiResponses.recaptchaError"),
                });
              setTimeout(() => {
                navigate(0);
              }, 2000);
              // Send recaptcha error to Sentry
              Sentry.withScope((scope) => {
                scope.setExtra("data", "Google recatpcha error");
                Sentry.captureException(error);
              });
            });
        });
      } catch (error) {
        formik.setFieldValue("reCaptchaToken", null);
        setIsCheckingCaptcha(false);
        onError &&
          onError({
            severity: "error",
            message: i18n.t("apiResponses.recaptchaError"),
          });
        setTimeout(() => {
          navigate(0);
        }, 2000);
        // Send recaptcha error to Sentry
        Sentry.withScope((scope) => {
          scope.setExtra("data", "Typescript recaptcha global type error");
          Sentry.captureException(error);
        });
      }
    }
  };

  const handleChangePhoneNumber = (phone: string) => {
    formik.setFieldTouched("phone", true);
    formik.setFieldValue("phone", phone);
  };

  const getWhatsappUrl = () => {
    const baseUrl = "https://api.whatsapp.com/send?phone=";
    const phone = process.env.REACT_APP_BRIOAGRO_WHATSAPP_NUMBER || "";
    return `${baseUrl}${phone}`;
  };

  return (
    <div className="login-section-content">
      {userNotRegisteredError && (
        <p className="whatsapp-warning">
          {`${i18n.t("login.loginWhatsappWarning")} `}{" "}
          <a href={getWhatsappUrl()}>{i18n.t("login.loginHere")}</a>
        </p>
      )}

      <MuiTelInput
        id="whatsappNumber"
        required
        error={!!formik.touched.phone && !!formik.errors?.phone}
        className="form-input"
        FormHelperTextProps={{
          style:
            !!formik.touched.phone && !!formik.errors?.phone
              ? helperTextStyle
              : {},
        }}
        helperText={
          !!formik.touched.phone && !!formik.errors?.phone
            ? formik.errors?.phone
            : i18n.t("login.phoneHelperText")
        }
        label={i18n.t("login.phoneLabel")}
        defaultCountry="ES"
        langOfCountryName="ES"
        value={formik.values.phone}
        onChange={handleChangePhoneNumber}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 1 }}
        endIcon={
          (isCheckingCaptcha || sendAuthCodeByWhatsappMutation.isLoading) && (
            <CircularProgress size={20} />
          )
        }
        disabled={isCheckingCaptcha || sendAuthCodeByWhatsappMutation.isLoading}
        onClick={handleLoginWhatsappCode}
      >
        {i18n.t("login.loginWithWhatsappText")}
      </Button>
    </div>
  );
};
