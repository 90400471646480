import { PhytoRecipeStatus } from "../../../constants/enums";
import Person from "../../Person";

class FBPhytoRecipeStatusHistory {
  id?: number;
  status?: PhytoRecipeStatus; //TODO: Translate
  startTimestamp?: number;
  endTimestamp?: number;
  observations?: string;
  user?: Person;

  constructor(
    id?: number,
    status?: PhytoRecipeStatus,
    startTimestamp?: number,
    endTimestamp?: number,
    observations?: string,
    user?: Person
  ) {
    this.id = id;
    this.status = status;
    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
    this.observations = observations;
    this.user = user;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj.id;
    this.status = obj.status;
    this.startTimestamp = obj.startTimestamp;
    this.endTimestamp = obj.endTimestamp;
    this.observations = obj.observations;
    this.user = new Person().mapToClass(obj?.user);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBPhytoRecipeStatusHistory;
