import Sector from "../../agrobusiness/Sector";

class FBCropSector {
  id?: number;
  area?: number;
  plantNumber?: number;
  sector?: Sector;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    area?: number,
    plantNumber?: number,
    sector?: Sector
  ) {
    this.id = id;
    this.area = area;
    this.plantNumber = plantNumber;
    this.sector = sector;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.area = obj?.area;
    this.plantNumber = obj?.plantNumber;
    this.sector = new Sector().mapToClass(obj?.sector);
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.sector = this.sector?.mapToApiModel();
    return obj;
  }
}

export default FBCropSector;
