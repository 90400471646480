import { Snackbar, Alert } from "@mui/material";

import { SnackbarInterface } from "../../constants/interfaces";

interface Props {
  open: boolean;
  snackbarMsg: SnackbarInterface | null;
  onClose: () => void;
}
const AlertSnackbar = (props: Props) => {
  const { open, onClose, snackbarMsg } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={onClose}
    >
      <Alert severity={snackbarMsg?.severity}>
        {snackbarMsg?.message || ""}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
