import FBAgroBusinessWineInfo from "../_apiModels/agrobusiness/FBAgroBusinessWineInfo";

class AgroBusinessWineInfo extends FBAgroBusinessWineInfo {
  constructor() {
    super();
  }

  mapToClass(obj: any): AgroBusinessWineInfo | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default AgroBusinessWineInfo;
