import { useEffect } from "react";
import { useFormik } from "formik";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
} from "@mui/material";

import FormikSelect from "../elements/FormikSelect";
import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";

import AgroBusinessWineInfo from "../../models/agrobusiness/AgroBusinessWineInfo";
import PotentialAreaType from "../../models/catalogue/PotentialAreaType";
import Province from "../../models/address/Province";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import QualityRegime from "../../models/catalogue/QualityRegime";
import Country from "../../models/address/Country";

interface DialogABWineInfoProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (abWineInfo: AgroBusinessWineInfo) => void;
  selected?: AgroBusinessWineInfo | null;
  winesInfo?: AgroBusinessWineInfo[];
}

//TODO: Generalize
const SPAIN_COUNTRY_CODE = "724";
const DialogABWineArea = (props: DialogABWineInfoProps) => {
  const ABWineInfoValidatorSchema = Yup.object().shape({
    potentialAreaType: Yup.object().required(),
    name: Yup.string().required(),
  });

  const { open, onClose, onConfirm, selected } = props;

  const { selectedABAccount } = useSession();

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: AgroBusinessWineInfo) => {
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new AgroBusinessWineInfo(),
    validationSchema: ABWineInfoValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: potentialWineAreas, isFetching: isFetchingPotWineAreas } =
    useFetch<PotentialAreaType[]>({
      queryKey: ["potentialWineAreas", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount,
    });

  const { data: provinces, isFetching: isFetchingProvinces } = useFetch<
    Province[]
  >({
    queryKey: ["provincesByCountryCode", SPAIN_COUNTRY_CODE],
    selected: new Country().mapToClass({ code: SPAIN_COUNTRY_CODE }),
  });

  const { data: qualityRegimes, isFetching: isFetchingQualityRegimes } =
    useFetch<QualityRegime[]>({
      queryKey: ["qualityRegimes", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount,
    });

  const handleConfirm = () => {
    formik.submitForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {selected
          ? i18n.t("components.dialogABWineArea.titleEdit")
          : i18n.t("components.dialogABWineArea.titleCreate")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl className="form-control" variant="outlined">
            <FormikSelect
              formik={formik}
              name="potentialAreaType"
              label={i18n.t("components.dialogABWineArea.potentialAreaType")}
              options={potentialWineAreas || []}
              optionLabelFieldName="name"
              required
              isLoading={isFetchingPotWineAreas}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="name"
              label={i18n.t("components.dialogABWineArea.name")}
              required
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <FormikTextField
              formik={formik}
              className="form-input-row"
              name="potentialArea"
              type="number"
              label={i18n.t("components.dialogABWineArea.potentialArea")}
              valueUnit="ha"
            />
            <FormikAutocomplete
              formik={formik}
              className="form-input-row"
              name="province"
              label={i18n.t("components.dialogABWineArea.province")}
              optionLabelFieldName="name"
              options={provinces || []}
              loading={isFetchingProvinces}
            />
          </FormControl>

          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="dopProtectedMarkName"
              label={i18n.t("components.dialogABWineArea.dopProtectedMarkName")}
              placeholder={i18n.t(
                "components.dialogABWineArea.dopProtectedMarkNamePlaceholder"
              )}
              options={qualityRegimes || []}
              optionLabelFieldName="name"
              groupBy={(option) => option.category?.name || ""}
              loading={isFetchingQualityRegimes}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogABWineArea;
