import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import FertilizerStockMovement from "../../fertiliz/FertilizerStockMovement";

class FBFertigationWaterMix {
  id?: number;
  name?: string;
  code?: string;
  mixDate?: string;
  waterVolume?: number;
  totalVolume?: number;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  adviceDate?: string;
  stockMovements?: FertilizerStockMovement[];

  constructor(
    id?: number,
    name?: string,
    code?: string,
    mixDate?: string,
    waterVolume?: number,
    totalVolume?: number,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    adviceDate?: string,
    stockMovements?: FertilizerStockMovement[]
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.mixDate = mixDate;
    this.waterVolume = waterVolume;
    this.totalVolume = totalVolume;
    this.advisor = advisor;
    this.adviceDate = adviceDate;
    this.stockMovements = stockMovements;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.mixDate = obj?.mixDate;
    this.waterVolume = obj?.waterVolume;
    this.totalVolume = obj?.totalVolume;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.adviceDate = obj?.adviceDate;
    if (obj?.stockMovements) {
      this.stockMovements = obj.stockMovements.map((stockMovement: any) =>
        new FertilizerStockMovement().mapToClass(stockMovement)
      );
    }
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.advisor = this.advisor?.mapToApiModel();
    obj.stockMovements = this.stockMovements?.map(
      (stockMovement: FertilizerStockMovement) => {
        const stockMovementObj: any = stockMovement.mapToApiModel();
        // Copy purchase date
        stockMovementObj.date = this.mixDate;
        return stockMovementObj;
      }
    );
    return obj;
  }
}

export default FBFertigationWaterMix;
