class FertilizationGoodPracticeType {
  id?: number;
  name?: string;
  code?: string;

  constructor(id?: number, name?: string, code?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  mapToClass(obj: any): FertilizationGoodPracticeType | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FertilizationGoodPracticeType;
