class FBProduct {
  id?: number;
  code?: string;
  name?: string;
  horticultural?: boolean;
  energetic?: boolean;
  fruity?: boolean;
  aromatic?: boolean;
  directSowing?: boolean;
  woody?: boolean;
  farmland?: boolean;
  permanentCrop?: boolean;
  permanentGrass?: boolean;
  forest?: boolean;

  constructor(
    id?: number,
    code?: string,
    name?: string,
    horticultural?: boolean,
    energetic?: boolean,
    fruity?: boolean,
    aromatic?: boolean,
    directSowing?: boolean,
    woody?: boolean,
    farmland?: boolean,
    permanentCrop?: boolean,
    permanentGrass?: boolean,
    forest?: boolean
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.horticultural = horticultural;
    this.energetic = energetic;
    this.fruity = fruity;
    this.aromatic = aromatic;
    this.directSowing = directSowing;
    this.woody = woody;
    this.farmland = farmland;
    this.permanentCrop = permanentCrop;
    this.permanentGrass = permanentGrass;
    this.forest = forest;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.code = obj?.code;
    this.name = obj?.name;
    this.horticultural = obj?.horticultural;
    this.energetic = obj?.energetic;
    this.fruity = obj?.fruity;
    this.aromatic = obj?.aromatic;
    this.directSowing = obj?.directSowing;
    this.woody = obj?.woody;
    this.farmland = obj?.farmland;
    this.permanentCrop = obj?.permanentCrop;
    this.permanentGrass = obj?.permanentGrass;
    this.forest = obj?.forest;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBProduct;
