import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import SectorDisableReason from "../../catalogue/SectorDisableReason";
import TenureType from "../../catalogue/TenureType";
import WaterSource from "../../agrobusiness/WaterSource";

class FBSector {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  name?: string;
  code?: string;
  provinceCode?: number;
  townshipCode?: number;
  aggregateCode?: number;
  zoneCode?: number;
  polygonCode?: number;
  sectorCode?: number;
  enclosureCode?: number;
  enabledDate?: string;
  disabledDate?: string;
  disableReason?: SectorDisableReason;
  cadastralRef?: string;
  sigpacSector?: boolean; // Ignored
  inSectorConcentration?: boolean;
  communalGrass?: boolean;
  communalPasture?: boolean;
  tenureType?: TenureType;
  lessorId?: string;
  foreignLessorId?: boolean;
  area?: number;
  gisPolygon?: any;
  waterSources?: WaterSource[];
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    area?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    provinceCode?: number,
    townshipCode?: number,
    aggregateCode?: number,
    zoneCode?: number,
    polygonCode?: number,
    sectorCode?: number,
    enclosureCode?: number,
    enabledDate?: string,
    disabledDate?: string,
    disableReason?: SectorDisableReason,
    cadastralRef?: string,
    sigpacSector?: boolean,
    inSectorConcentration?: boolean,
    communalGrass?: boolean,
    communalPasture?: boolean,
    tenureType?: TenureType,
    lessorId?: string,
    foreignLessorId?: boolean,
    gisPolygon?: any,
    waterSources?: WaterSource[]
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.name = name || "";
    this.code = code;
    this.provinceCode = provinceCode;
    this.townshipCode = townshipCode;
    this.aggregateCode = aggregateCode;
    this.zoneCode = zoneCode;
    this.polygonCode = polygonCode;
    this.sectorCode = sectorCode;
    this.enclosureCode = enclosureCode;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.disableReason = disableReason;
    this.cadastralRef = cadastralRef;
    this.sigpacSector = typeof sigpacSector === "boolean" ? sigpacSector : true;
    this.inSectorConcentration = inSectorConcentration;
    this.communalGrass = communalGrass || false;
    this.communalPasture = communalPasture || false;
    this.tenureType = tenureType;
    this.lessorId = lessorId;
    this.foreignLessorId = foreignLessorId || false;
    this.area = area;
    this.gisPolygon = gisPolygon;
    this.waterSources = waterSources;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.name = obj?.name || "";
    this.code = obj?.code;
    this.provinceCode = obj?.provinceCode;
    this.townshipCode = obj?.townshipCode;
    this.aggregateCode = obj?.aggregateCode;
    this.zoneCode = obj?.zoneCode;
    this.polygonCode = obj?.polygonCode;
    this.sectorCode = obj?.sectorCode;
    this.enclosureCode = obj?.enclosureCode;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.disableReason = new SectorDisableReason().mapToClass(
      obj?.disableReason
    );
    this.cadastralRef = obj?.cadastralRef;
    this.sigpacSector =
      typeof obj?.sigpacSector === "boolean" ? obj?.sigpacSector : true;
    this.inSectorConcentration = obj?.inSectorConcentration;
    this.communalGrass = obj?.communalGrass || false;
    this.communalPasture = obj?.communalPasture || false;
    this.tenureType = new TenureType().mapToClass(obj?.tenureType);
    this.lessorId = obj?.lessorId;
    this.foreignLessorId = obj?.foreignLessorId || false;
    this.area = obj?.area;
    this.gisPolygon = obj?.gisPolygon;
    this.waterSources =
      obj?.waterSources?.map((ws: any) => new WaterSource().mapToClass(ws)) ||
      [];
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.disableReason = this.disableReason?.mapToApiModel();
    obj.tenureType = this.tenureType?.mapToApiModel();
    obj.waterSources = this.waterSources?.map((ws: WaterSource) =>
      ws.mapToApiModel()
    );
    return obj;
  }
}

export default FBSector;
