import { EntityClassNameInterface } from "../../constants/interfaces";
import { ENTITY_CLASS_NAMES } from "../../providers/YupProvider";
import FBSiexRequestItem from "../_apiModels/siex/FBSiexRequestItem";

class SiexRequestItem extends FBSiexRequestItem {
  section?: EntityClassNameInterface;

  constructor() {
    super();
  }

  mapToClass(obj: any): SiexRequestItem | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.entityClassName)
      this.section = ENTITY_CLASS_NAMES.find(
        (s) => s.name === this.entityClassName
      );
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.section;
    return obj;
  }
}

export default SiexRequestItem;
