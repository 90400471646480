import Entity from "../../agrobusiness/Entity";
import Address from "../../address/Address";

class FBCoOwner {
  id?: number;
  entity?: Entity; //*
  address?: Address; //*
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(id?: number, entity?: Entity, address?: Address) {
    this.id = id;
    this.entity = entity;
    this.address = address;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.entity = new Entity().mapToClass(obj?.entity);
    this.address = new Address().mapToClass(obj?.address);
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.entity = this.entity?.mapToApiModel();
    obj.address = this.address?.mapToApiModel();
    return obj;
  }
}

export default FBCoOwner;
