import { useEffect } from "react";
import { FormikTouched, useFormik } from "formik";
import moment from "moment";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  Collapse,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";

import FormikListItemSwitch from "../elements/FormikListItemSwitch";
import FormikSelect from "../elements/FormikSelect";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import ReproductiveMaterialType from "../../models/catalogue/ReproductiveMaterialType";

import { SnackbarInterface } from "../../constants/interfaces";

export interface ReproductionMaterialInterface {
  reproductiveMaterialType?: ReproductiveMaterialType;
  permanentCrop?: boolean;
  sowingPlantationStartDate?: string;
  sowingPlantationEndDate?: string;
  plantingYear?: number;
  sowingVsPlantation?: boolean;
  helpUserMarketHarvest?: boolean;
}

interface Props {
  open: boolean;
  isLoading: boolean;
  selected?: ReproductionMaterialInterface;
  onConfirm: (
    reproductionMaterialValues: ReproductionMaterialInterface
  ) => void;
  onCancel: () => void;
  onError?: (snackbarError: SnackbarInterface) => void;
}
const DialogCropHarvestHelper = (props: Props) => {
  const { open, selected, onConfirm, onCancel, onError } = props;

  const YupValidatorSchema = Yup.object().shape({
    reproductiveMaterialType: Yup.object().when("helpUserMarketHarvest", {
      is: (helpUserMarketHarvest?: boolean) => !!helpUserMarketHarvest,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    sowingPlantationStartDate: Yup.string().when(
      ["helpUserMarketHarvest", "permanentCrop"],
      {
        is: (helpUserMarketHarvest?: boolean, permanentCrop?: boolean) =>
          !!helpUserMarketHarvest && !permanentCrop,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  });

  const { selectedABAccount } = useSession();

  // Set plant value as default
  const handleReproductiveMaterials = (
    reproductiveMaterials: ReproductiveMaterialType[]
  ) => {
    if (
      !formik.values.reproductiveMaterialType &&
      reproductiveMaterials.length > 0
    ) {
      const plantType = reproductiveMaterials.find((rm) => rm.code === "2");
      formik.setFieldValue(
        "reproductiveMaterialType",
        plantType || reproductiveMaterials[0]
      );
    }
  };

  const {
    data: reproductiveMaterials,
    isFetching: isFetchingReproductiveMaterials,
  } = useFetch<ReproductiveMaterialType[]>({
    queryKey: ["reproductiveMaterials", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount && open,
    onSuccess: handleReproductiveMaterials,
    onError,
  });

  const handleSubmit = (values: ReproductionMaterialInterface) => {
    onConfirm(values);
    formik.resetForm();
  };

  const formik = useFormik<ReproductionMaterialInterface>({
    initialValues: {
      helpUserMarketHarvest: true,
    } satisfies ReproductionMaterialInterface,
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) {
      formik.setValues(selected);
    }
  }, [selected]);

  const handleChangeReproductiveMaterial = (
    option: ReproductiveMaterialType
  ) => {
    formik.setFieldValue("reproductiveMaterialType", option);
    formik.setFieldValue("sowingVsPlantation", option?.code === "1");
    if (option?.code === "1") formik.setFieldValue("permanentCrop", false);
  };

  const handleChangePermanentCrop = (checked: boolean) => {
    formik.setFieldValue("permanentCrop", checked);
    if (checked) {
      formik.setFieldValue("sowingPlantationStartDate", null);
      formik.setFieldValue("sowingPlantationEndDate", null);
    } else formik.setFieldValue("plantingYear", null);
  };

  const handleOnConfirm = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      onError &&
        onError({
          severity: "warning",
          message: i18n.t("formErrors.requiredFields"),
        });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleOnCancel = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <Dialog className="dialog" open={props.open} onClose={handleOnCancel}>
      <DialogTitle className="title">
        {i18n.t("components.dialogCropHarvestHelper.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText>
          {i18n.t("components.dialogCropHarvestHelper.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl className="form-control">
            <FormikListItemSwitch
              formik={formik}
              name="helpUserMarketHarvest"
              primaryText={i18n.t(
                "components.dialogCropHarvestHelper.cropHarvestHelperPrimmaryLabel"
              )}
              secondaryText={i18n.t(
                "components.dialogCropHarvestHelper.cropHarvestHelperSecondaryLabel"
              )}
            />
          </FormControl>
          <Collapse in={formik.values.helpUserMarketHarvest}>
            <div className="grow-container">
              <FormControl
                variant="outlined"
                className="form-control"
                sx={{ mt: "0px !important" }}
              >
                <FormikSelect
                  formik={formik}
                  name="reproductiveMaterialType"
                  label={i18n.t(
                    "crops.reproductionMaterialForm.reproductiveMaterialType"
                  )}
                  optionLabelFieldName="name"
                  options={reproductiveMaterials || []}
                  isLoading={isFetchingReproductiveMaterials}
                  onChange={handleChangeReproductiveMaterial}
                />
              </FormControl>
              <Collapse in={!formik.values.sowingVsPlantation}>
                <div className="grow-container">
                  <FormControl
                    className="form-control"
                    sx={{ mt: "0px !important" }}
                  >
                    <FormikListItemSwitch
                      formik={formik}
                      name="permanentCrop"
                      primaryText={i18n.t("crops.datesForm.permanentCrop")}
                      secondaryText={i18n.t(
                        "crops.datesForm.permanentCropSubtitle"
                      )}
                      onChangeSwitch={handleChangePermanentCrop}
                    />
                  </FormControl>
                </div>
              </Collapse>
              <Collapse
                className="collapse-container"
                in={formik.values.permanentCrop}
                sx={{
                  mb: !formik.values.sowingVsPlantation
                    ? "20px !important"
                    : "",
                }}
              >
                <div className="grow-container">
                  <FormControl variant="outlined" className="form-control">
                    <DatePicker
                      className="form-input"
                      format="YYYY"
                      label={i18n.t("crops.datesForm.plantingYear")}
                      view="year"
                      views={["year"]}
                      disableFuture
                      closeOnSelect
                      value={
                        formik.values.plantingYear
                          ? moment(formik.values.plantingYear, "YYYY")
                          : null
                      }
                      onChange={(date) =>
                        formik.setFieldValue("plantingYear", date?.year())
                      }
                    />
                  </FormControl>
                </div>
              </Collapse>
              <Collapse
                className="collapse-container"
                in={!formik.values.permanentCrop}
              >
                <div className="grow-container">
                  <FormControl variant="outlined" className="form-control-row">
                    <DatePicker
                      className="form-input-row"
                      format="DD/MM/YYYY"
                      label={
                        formik.values.sowingVsPlantation
                          ? i18n.t(
                              "components.dialogCropHarvestHelper.sowingStartDate"
                            )
                          : i18n.t(
                              "components.dialogCropHarvestHelper.plantationStartDate"
                            )
                      }
                      value={
                        formik.values.sowingPlantationStartDate
                          ? moment(
                              formik.values.sowingPlantationStartDate,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      onChange={(date) =>
                        formik.setFieldValue(
                          "sowingPlantationStartDate",
                          date?.format("YYYY-MM-DD")
                        )
                      }
                    />
                    <DatePicker
                      className="form-input-row"
                      format="DD/MM/YYYY"
                      label={
                        formik.values.sowingVsPlantation
                          ? i18n.t(
                              "components.dialogCropHarvestHelper.sowingEndDate_v2"
                            )
                          : i18n.t(
                              "components.dialogCropHarvestHelper.plantationEndDate_v2"
                            )
                      }
                      value={
                        formik.values.sowingPlantationEndDate
                          ? moment(
                              formik.values.sowingPlantationEndDate,
                              "YYYY-MM-DD"
                            )
                          : null
                      }
                      onChange={(date) =>
                        formik.setFieldValue(
                          "sowingPlantationEndDate",
                          date?.format("YYYY-MM-DD")
                        )
                      }
                    />
                  </FormControl>
                </div>
              </Collapse>
            </div>
          </Collapse>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnCancel} color="inherit">
          {i18n.t("components.dialogCropHarvestHelper.cancelBtnLabel")}
        </Button>
        <LoadingButton
          color="primary"
          autoFocus
          loading={props.isLoading}
          onClick={handleOnConfirm}
        >
          {i18n.t("components.dialogCropHarvestHelper.confirmBtnLabel")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCropHarvestHelper;
