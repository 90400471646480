class FBLocalAuthSendCodeRequest {
  email?: string;
  phone?: string;
  language?: string;
  reCaptchaToken?: string;
  code?: string;

  constructor(
    email?: string,
    phone?: string,
    language?: string,
    reCaptchaToken?: string,
    code?: string
  ) {
    this.email = email;
    this.phone = phone;
    this.language = language;
    this.reCaptchaToken = reCaptchaToken;
    this.code = code;
  }

  mapToBaseClass(obj: any): void {
    this.email = obj?.email;
    this.phone = obj?.phone;
    this.language = obj?.language;
    this.reCaptchaToken = obj?.reCaptchaToken;
    this.code = obj?.code;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBLocalAuthSendCodeRequest;
