import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Autocomplete,
  TextField,
  createFilterOptions,
  CircularProgress,
  useMediaQuery,
  Tooltip,
  Badge,
  BadgeProps,
  styled,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  CorporateFare,
  AccountTree,
  Settings,
  Grade,
  SyncProblem,
} from "@mui/icons-material";

import DialogChooseExploitation from "./dialogs/DialogChooseExploitation";
import DialogChoosePartition from "./dialogs/DialogChoosePartition";
import AdvisorAccountLabel from "./elements/AdvisorAccountLabel";
import simpleLogoUrl from "../assets/logo_simple.png";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessPartition from "../models/agrobusiness/AgroBusinessPartition";
import PersonMessage from "../models/messages/PersonMessage";

import { PROTECTED_ROUTES } from "../routes/routeNames";
import { REFRESH_OFFERS_INTERVAL } from "../constants/constants";

const filter = createFilterOptions<AgroBusinessAccount>();

const SiexErrorsBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": { right: -8 },
}));

interface Props {
  onClick?: React.MouseEventHandler;
  scrollOnTop?: boolean;
}
const Navbar = ({ onClick, scrollOnTop }: Props) => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const {
    agroBusinessAccounts,
    agroBusinessPartitions,
    isLoadingPartitions,
    selectedABAccount,
    selectedABPartition,
    changeSelectedABA,
    changeSelectedABPartition,
    siexErrors,
  } = useSession();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpenAvatarMenu, setIsOpenAvatarMenu] =
    React.useState<boolean>(false);

  const elevate = isLargeScreen || !scrollOnTop;

  const { data: unreadOffers } = useFetch<PersonMessage[]>({
    queryKey: ["unreadOffers", selectedABAccount?.id],
    refetchInterval: REFRESH_OFFERS_INTERVAL,
    enabled: !!selectedABAccount,
  });

  const handleClickLogo = () => {
    navigate(PROTECTED_ROUTES.DASHBOARD);
  };

  const handleClickGoToSiexErrors = () => {
    navigate(PROTECTED_ROUTES.EXPLOITATION_SIEX_ERRORS);
  };

  const handleClickGoToOffers = () => {
    navigate(PROTECTED_ROUTES.EXPLOITATION_OFFERS);
  };

  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenAvatarMenu(true);
  };
  const handleClickSettings = () => {
    navigate(PROTECTED_ROUTES.SETTINGS);
    handleCloseAvatarMenu();
  };
  const handleClickLogout = () => {
    logout();
    handleCloseAvatarMenu();
  };

  const handleCloseAvatarMenu = () => {
    setAnchorEl(null);
    setIsOpenAvatarMenu(false);
  };

  const handleChangeSelectedABA = (newValue: AgroBusinessAccount) =>
    changeSelectedABA(newValue);

  const handleChangeSelectedABP = (newValue: AgroBusinessPartition) => {
    if (newValue.id === 0) navigate(PROTECTED_ROUTES.EXPLOITATION_PARTITIONS);
    else changeSelectedABPartition(newValue);
  };

  return (
    <AppBar component="nav" className="navbar" elevation={elevate ? 4 : 0}>
      <Toolbar className="toolbar" component="nav">
        <div className="toolbar-left">
          <IconButton className="sidebar-btn" size="large" onClick={onClick}>
            <MenuIcon />
          </IconButton>
          <img
            className="logo"
            alt={i18n.t("components.navbar.altLogo")}
            src={simpleLogoUrl}
            onClick={handleClickLogo}
          />
          <AdvisorAccountLabel isVisible={isLargeScreen} />
          {siexErrors.length > 0 && (
            <Tooltip
              title={`${siexErrors.length} ${
                siexErrors.length === 1
                  ? i18n.t("components.navbar.siexErrorsTooltip")
                  : i18n.t("components.navbar.siexErrorsTooltipPlural")
              }`}
            >
              <IconButton
                className="siex-errors-btn"
                onClick={handleClickGoToSiexErrors}
              >
                <SiexErrorsBadge
                  className="siex-badge"
                  badgeContent={siexErrors.length}
                  color="error"
                >
                  <SyncProblem fontSize="small" sx={{ color: "white" }} />
                </SiexErrorsBadge>
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className="toolbar-right">
          <NavbarMenuCollapsed
            classname="menu-collapsed"
            isLoadingPartitions={isLoadingPartitions}
            agroBusinessAccounts={agroBusinessAccounts}
            agroBusinessPartitions={agroBusinessPartitions}
            selectedABAccount={selectedABAccount}
            selectedABPartition={selectedABPartition}
            onChangeABA={handleChangeSelectedABA}
            onChangeABP={handleChangeSelectedABP}
          />

          <NavbarMenuExpanded
            classname="menu-expanded"
            isLoadingPartitions={isLoadingPartitions}
            agroBusinessAccounts={agroBusinessAccounts}
            agroBusinessPartitions={agroBusinessPartitions}
            selectedABAccount={selectedABAccount}
            selectedABPartition={selectedABPartition}
            onChangeABA={handleChangeSelectedABA}
            onChangeABP={handleChangeSelectedABP}
          />

          <Tooltip title={i18n.t("components.navbar.messagesBtnTooltip")}>
            <IconButton sx={{ ml: 1, mr: 1 }} onClick={handleClickGoToOffers}>
              <Badge badgeContent={unreadOffers?.length} color="primary">
                <Grade />
              </Badge>
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{ mr: 1, p: 0 }}
            size="large"
            onClick={handleClickAvatar}
          >
            <Avatar className="avatar">
              {user?.person?.email?.charAt(0)?.toUpperCase() || ""}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isOpenAvatarMenu}
            onClick={handleCloseAvatarMenu}
          >
            <MenuItem onClick={handleClickSettings}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              {i18n.t("words.settings")}
            </MenuItem>
            <MenuItem onClick={handleClickLogout}>
              <ListItemIcon>
                <Logout color="error" />
              </ListItemIcon>
              {i18n.t("logout")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

interface NavbarMenuCollapsedProps {
  classname?: string;
  agroBusinessAccounts: AgroBusinessAccount[];
  agroBusinessPartitions: AgroBusinessPartition[];
  isLoadingPartitions: boolean;
  selectedABAccount: AgroBusinessAccount | null;
  selectedABPartition: AgroBusinessPartition | null;
  onChangeABA: (aba: AgroBusinessAccount) => void;
  onChangeABP: (abp: AgroBusinessPartition) => void;
}
const NavbarMenuCollapsed = (props: NavbarMenuCollapsedProps) => {
  const {
    classname,
    agroBusinessAccounts,
    agroBusinessPartitions,
    selectedABAccount,
    selectedABPartition,
    onChangeABA,
    onChangeABP,
  } = props;

  const [isOpenExploitationsDialog, setIsOpenExploitationsDialog] =
    useState<boolean>(false);
  const [isOpenPartitionsDialog, setIsOpenPartitionsDialog] =
    useState<boolean>(false);

  const openExploitationsDialog = () => setIsOpenExploitationsDialog(true);
  const openPartitionsDialog = () => setIsOpenPartitionsDialog(true);
  const closeExploitationsDialog = () => setIsOpenExploitationsDialog(false);
  const closePartitionsDialog = () => setIsOpenPartitionsDialog(false);

  return (
    <div className={classname}>
      <IconButton
        className="exploitation-btn"
        onClick={openExploitationsDialog}
      >
        <CorporateFare />
      </IconButton>
      {agroBusinessPartitions.length > 0 && (
        <IconButton className="exploitation-btn" onClick={openPartitionsDialog}>
          <AccountTree />
        </IconButton>
      )}

      <DialogChooseExploitation
        open={isOpenExploitationsDialog}
        options={agroBusinessAccounts}
        selected={selectedABAccount}
        onClose={closeExploitationsDialog}
        onConfirm={onChangeABA}
      />

      <DialogChoosePartition
        open={isOpenPartitionsDialog}
        options={agroBusinessPartitions}
        selected={selectedABPartition}
        onClose={closePartitionsDialog}
        onConfirm={onChangeABP}
      />
    </div>
  );
};

interface NavbarMenuExpandedProps {
  classname?: string;
  agroBusinessAccounts: AgroBusinessAccount[];
  agroBusinessPartitions: AgroBusinessPartition[];
  isLoadingPartitions: boolean;
  selectedABAccount: AgroBusinessAccount | null;
  selectedABPartition: AgroBusinessPartition | null;
  onChangeABA: (aba: AgroBusinessAccount) => void;
  onChangeABP: (abp: AgroBusinessPartition) => void;
}
const NavbarMenuExpanded = (props: NavbarMenuExpandedProps) => {
  const {
    agroBusinessAccounts,
    agroBusinessPartitions,
    isLoadingPartitions,
    selectedABAccount,
    selectedABPartition,
    onChangeABA,
    onChangeABP,
    classname,
  } = props;

  const partitionsFilter = (options: AgroBusinessPartition[], params: any) => {
    const filtered = filter(options, params);
    // Configuration option
    const configOption = new AgroBusinessPartition().mapToClass({
      id: 0,
      name: i18n.t("components.navbar.configPartitions"),
    });
    if (configOption) filtered.push(configOption);
    return filtered;
  };

  const handleChangeSelectedABA = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessAccount | null
  ) => {
    if (newValue) onChangeABA(newValue);
  };

  const handleChangeSelectedABP = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessPartition | null
  ) => {
    if (newValue) onChangeABP(newValue);
  };

  return (
    <div className={classname}>
      <Autocomplete
        id="exploitations-navbar"
        className="exploitation-select"
        options={agroBusinessAccounts}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        popupIcon={agroBusinessAccounts.length > 1 ? undefined : false}
        clearIcon={agroBusinessAccounts.length > 1 ? undefined : false}
        readOnly={agroBusinessAccounts.length < 2}
        value={selectedABAccount}
        onChange={handleChangeSelectedABA}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("components.navbar.exploitation")}
            placeholder={i18n.t("components.navbar.exploitationPlaceholder")}
          />
        )}
        disableClearable={agroBusinessAccounts.length === 0}
        sx={{
          "& .MuiAutocomplete-endAdornment": {
            display: agroBusinessAccounts.length === 0 ? "none" : "flex",
          },
        }}
      />
      {agroBusinessPartitions.length > 0 && (
        <Autocomplete
          id="partitions-navbar"
          className="exploitation-select"
          options={agroBusinessPartitions}
          filterOptions={partitionsFilter}
          getOptionLabel={(option: any) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          value={selectedABPartition}
          onChange={handleChangeSelectedABP}
          loading={isLoadingPartitions}
          loadingText={i18n.t("components.navbar.loadingPartitions")}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={i18n.t("components.navbar.partition")}
              placeholder={i18n.t("components.navbar.partitionPlaceholder")}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingPartitions ? (
                      <CircularProgress size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    </div>
  );
};
