import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";

import FormikSelect from "../elements/FormikSelect";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import AlertEmptyList from "../AlertEmptyList";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import WaterSource from "../../models/agrobusiness/WaterSource";

import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface DialogAddWaterSourceProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (waterSources: WaterSource[]) => void;
  selected?: WaterSource[];
}

const DialogAddWaterSource = (props: DialogAddWaterSourceProps) => {
  const { open, onClose, onConfirm, selected } = props;
  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const handleSubmit = (values: any) => {
    onConfirm(values.waterSources);
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      waterSources: selected || [],
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setFieldValue("waterSources", selected || []);
  }, [selected]);

  const { data: waterSources, isFetching } = useFetch<WaterSource[]>({
    queryKey: ["waterSources", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleClose = () => {
    formik.setFieldValue("waterSources", selected || []);
    onClose();
  };

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_WATER_SOURCES}`);
  };

  return (
    <Dialog open={open} onClose={handleClose} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogAddWaterSource.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogAddWaterSource.description")}
        </DialogContentText>
        {!isFetching && waterSources?.length === 0 && (
          <AlertEmptyList onClick={handleClickNewOption}>
            {i18n.t("components.dialogAddWaterSource.emptyList")}
          </AlertEmptyList>
        )}
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikSelect
              formik={formik}
              name="waterSources"
              label={i18n.t("components.dialogAddWaterSource.waterSources")}
              multiple
              options={waterSources || []}
              optionLabelFieldName="name"
              addNewOption
              onClickNewOption={handleClickNewOption}
              isLoading={isFetching}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected && selected.length > 0
            ? i18n.t("words.update")
            : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogAddWaterSource.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogAddWaterSource.redirectAlertDialogContentText"
        )}
        open={isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogAddWaterSource;
