import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import FormikTextField from "../elements/FormikTextField";
import FormikAutocomplete from "../elements/FormikAutocomplete";

import FertilizerProductMacronutrient from "../../models/fertiliz/FertilizerProductMacronutrient";
import FertilizerMacronutrient from "../../models/catalogue/FertilizerMacronutrient";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

interface DialogFertilizerProductMacronutrientProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (macronutrient: FertilizerProductMacronutrient) => void;
  selected?: FertilizerProductMacronutrient | null;
  macronutrients?: FertilizerProductMacronutrient[];
  productUnit?: MeasurementUnit;
}

const DialogFertilizerProductMacronutrient = (
  props: DialogFertilizerProductMacronutrientProps
) => {
  const YupValidatorSchema = Yup.object().shape({
    macronutrient: Yup.object().required(),
  });

  const {
    open,
    onClose,
    onConfirm,
    selected,
    macronutrients = [],
    productUnit,
  } = props;

  const { selectedABAccount } = useSession();

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: FertilizerProductMacronutrient) => {
    // Needs id to be unique in the table
    if (!values.idx) {
      // Find the max idx of the macronutrients
      const maxIdx = getMaxValueOfList(macronutrients, "idx") + 1;
      values.idx = maxIdx;
    }
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FertilizerProductMacronutrient(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: fertilizerProductMacronutrients, isFetching } = useFetch<
    FertilizerMacronutrient[]
  >({
    queryKey: [
      "fertilizerProductMacronutrients",
      selectedABAccount?.context?.id,
    ],
    enabled: !!selectedABAccount?.context?.id,
  });

  const handleConfirm = () => {
    formik.submitForm();
  };

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogFertilizerProductMacronutrient.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t(
            "components.dialogFertilizerProductMacronutrient.description"
          )}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="macronutrient"
              label={i18n.t(
                "components.dialogFertilizerProductMacronutrient.substance"
              )}
              placeholder={i18n.t(
                "components.dialogFertilizerProductMacronutrient.substancePlaceholder"
              )}
              options={fertilizerProductMacronutrients || []}
              optionLabelFieldName="name"
              required
              startIcon={Search}
              loading={isFetching}
            />
          </FormControl>

          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="percentage"
              type="number"
              label={i18n.t(
                "components.dialogFertilizerProductMacronutrient.percentage"
              )}
              placeholder={i18n.t(
                "components.dialogFertilizerProductMacronutrient.percentagePlaceholder"
              )}
              valueUnit={productUnit?.name ? `%/${productUnit.name}` : "%"}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFertilizerProductMacronutrient;
