import { useState, useEffect } from "react";
import i18n from "../../config/configI18n";

import { FormikProps } from "formik";
import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  SelectChangeEvent,
  Checkbox,
  CircularProgress,
  FormControl,
} from "@mui/material";
import {
  removeDuplicatedItemsOfListByField,
  getFieldValueByColumnNotation,
} from "../../helpers/utils";
import AddNewOptionListItem from "./AddNewOptionListItem";

interface Props {
  name: string;
  formik: FormikProps<any>;
  options: any[];
  optionLabelFieldName: string;
  label: string;
  style?: React.CSSProperties;
  className?: string;
  required?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  helperText?: string;
  multiple?: boolean;
  addNewOption?: boolean;
  type?: "fullWidth" | "row" | "wrap";
  onClickNewOption?: () => void;
  onChange?: (option: any | any[]) => void;
}
const FormikSelect = (props: Props) => {
  return props.multiple ? (
    <FormikSelectMultiple {...props} />
  ) : (
    <FormikSelectSimple {...props} />
  );
};

export default FormikSelect;

const FormikSelectSimple = (props: Props) => {
  const {
    className,
    style,
    name,
    formik,
    options,
    optionLabelFieldName,
    label,
    required = false,
    isLoading = false,
    disabled = false,
    type = "fullWidth",
    helperText,
    onChange,
  } = props;
  const errorTouched = getFieldValueByColumnNotation(name, formik.touched);
  const errorFormik = getFieldValueByColumnNotation(name, formik.errors);
  const error = errorTouched && Boolean(errorFormik);
  const errorMsg = i18n.t("formErrors.selectOneOption");
  const value = getFieldValueByColumnNotation(name, formik.values) || "";

  const handleChange = (event: SelectChangeEvent) => {
    const option = options.find((option) => option.id === event.target.value);
    (onChange && onChange(option)) || formik.setFieldValue(name, option);
  };

  const marginLeft = type === "row" ? 8 : 0;
  return (
    <FormControl style={{ maxWidth: "100%", minWidth: "30%" }}>
      <InputLabel required={required} error={error}>
        {label}
      </InputLabel>
      <Select
        id={name}
        name={name}
        className={className}
        style={{ height: "56px", marginLeft, ...style }}
        label={label}
        value={(!isLoading && value?.id) || ""}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={error}
        disabled={disabled}
      >
        {isLoading ? (
          <MenuItem style={styles.formHelperText} disabled>
            <CircularProgress size={20} />
            <Typography style={styles.loadingText}>
              {i18n.t("words.loadingValues")}
            </Typography>
          </MenuItem>
        ) : (
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Typography variant="subtitle1" overflow="auto">
                {option[optionLabelFieldName]}
              </Typography>
            </MenuItem>
          ))
        )}
      </Select>
      {helperText && (
        <FormHelperText
          style={{ ...styles.formHelperText, color: error ? "#c62828" : "" }}
          error={error}
        >
          {error ? errorMsg : helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const FormikSelectMultiple = (props: Props) => {
  const {
    className,
    style,
    name,
    formik,
    options,
    optionLabelFieldName,
    label,
    required = false,
    isLoading = false,
    disabled = false,
    addNewOption = false,
    onClickNewOption,
    type = "fullWidth",
    helperText,
    onChange,
  } = props;
  const errorTouched = getFieldValueByColumnNotation(name, formik.touched);
  const errorFormik = getFieldValueByColumnNotation(name, formik.errors);
  const error = errorTouched && Boolean(errorFormik);
  const errorMsg = i18n.t("formErrors.selectAtLeastOption");
  const value = getFieldValueByColumnNotation(name, formik.values) || [];

  const [menuOptions, setMenuOptions] = useState<any[]>(options);

  // Add new option to menu options
  useEffect(() => {
    if (addNewOption) setMenuOptions([...options, { id: "new" }]);
    else setMenuOptions(options);
  }, [addNewOption, options]);

  const handleChange = (event: SelectChangeEvent<any[]>) => {
    const opts = event.target.value as any[];
    // New option clicked
    if (opts.find((o: any) => o.id === "new")) {
      event.preventDefault();
      return onClickNewOption && onClickNewOption();
    }
    // Selected options
    const newOpts = removeDuplicatedItemsOfListByField(opts, "id"); //TODO: To solve element bug: Duplicated values in onChange when render with initial values in value property
    (onChange && onChange(newOpts)) || formik.setFieldValue(name, newOpts);
  };

  const renderValue = (selected: any) =>
    selected
      .map((option: any) => option[optionLabelFieldName] as string)
      .join(", ");

  const marginLeft = type === "row" ? 8 : 0;
  return (
    <>
      <InputLabel required={required} error={error}>
        {label}
      </InputLabel>
      <Select
        id={name}
        name={name}
        style={{ height: "56px", marginLeft, ...style }}
        className={className}
        label={label}
        multiple
        renderValue={renderValue}
        value={value}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        error={error}
        disabled={disabled}
      >
        {isLoading ? (
          <MenuItem style={styles.formHelperText} disabled>
            <CircularProgress size={20} />
            <Typography style={styles.loadingText}>
              {i18n.t("words.loadingValues")}
            </Typography>
          </MenuItem>
        ) : (
          menuOptions.map((option) => {
            if (option.id === "new")
              return <AddNewOptionListItem key={option.id} value={option} />;

            const checked = !!value?.find((o: any) => o.id === option.id);
            return (
              <MenuItem key={option.id} value={option}>
                <Checkbox checked={checked} />
                <Typography variant="subtitle1" overflow="auto">
                  {option[optionLabelFieldName]}
                </Typography>
              </MenuItem>
            );
          })
        )}
      </Select>
      {helperText && (
        <FormHelperText
          style={{ ...styles.formHelperText, color: error ? "#c62828" : "" }}
          error={error}
        >
          {error ? errorMsg : helperText}
        </FormHelperText>
      )}
    </>
  );
};

type StyleObject = {
  [key: string]: React.CSSProperties;
};
export const styles: StyleObject = {
  loadingMenuItemContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  formHelperText: {
    fontSize: 12,
    margin: 4,
    marginBottom: 0,
  },
  loadingText: {
    fontSize: 14,
    textAlign: "center",
    marginLeft: 8,
  },
};
