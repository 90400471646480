import { useState, MouseEventHandler, SyntheticEvent } from "react";
import i18n from "../config/configI18n";

import { Popover } from "@mui/material";

import { FBESiexRequestStatus } from "../constants/enums";
import { useSession } from "../hooks/useSession";

interface Props {
  status?: boolean | FBESiexRequestStatus;
}
const SiexStatusIcon = (props: Props) => {
  const { status } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isOpenPopover = Boolean(anchorEl);

  const closePopover = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickStatus: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div
        className="siex-status-icon-container"
        onMouseEnter={handleClickStatus}
        onClick={handleClickStatus}
      >
        <div
          className={`siex-status-icon siex-status-icon-${
            typeof status === "boolean"
              ? status
                ? "ok"
                : "error"
              : status?.toLowerCase()
          }`}
        />
      </div>
      <PopoverComponent
        open={isOpenPopover}
        anchorEl={anchorEl}
        status={status}
        onClose={closePopover}
      />
    </>
  );
};

export default SiexStatusIcon;

interface PopOverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  status?: boolean | FBESiexRequestStatus;
  onClose?: (event: SyntheticEvent) => void;
}
const PopoverComponent = (props: PopOverProps) => {
  const { open, anchorEl, status, onClose } = props;

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      onClick={onClose}
      onClose={onClose}
    >
      {typeof status === "boolean" ? (
        <PopoverDownloadElement status={status} />
      ) : (
        <PopoverUploadElement status={status} />
      )}
    </Popover>
  );
};

const PopoverDownloadElement = ({ status = false }: { status?: boolean }) => {
  return (
    <div className="popover-container">
      <h1>{i18n.t("components.siexStatusIcon.legendTitle")}</h1>
      <div className="popover-legend">
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-ok ${
              status ? "siex-status-icon-ok-active" : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__ok">
              {i18n.t("components.siexStatusIcon.downloadSyncLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__ok">
              {i18n.t("components.siexStatusIcon.downloadSyncSubLabel")}
            </h4>
          </div>
        </div>
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-error ${
              !status ? "siex-status-icon-error-active" : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__error">
              {i18n.t("components.siexStatusIcon.downloadManualLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__error">
              {i18n.t("components.siexStatusIcon.downloadManualSubLabel")}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopoverUploadElement = ({
  status = FBESiexRequestStatus.OK,
}: {
  status?: FBESiexRequestStatus;
}) => {
  const { selectedABAccount } = useSession();

  return (
    <div className="popover-container">
      <h1>{i18n.t("components.siexStatusIcon.legendTitle")}</h1>
      <div className="popover-legend">
        {!selectedABAccount?.siexAutoSync && (
          <div className="popover-legend-item">
            <div
              className={`siex-status-icon-legend siex-status-icon-advisor_pending ${
                status === FBESiexRequestStatus.ADVISOR_PENDING
                  ? "siex-status-icon-advisor_pending-active"
                  : ""
              }`}
            />
            <div className="popover-legend-item-content">
              <h3 className="popover-legend-title popover-text__advisor_pending">
                {i18n.t("components.siexStatusIcon.uploadAdvisorPendingLabel")}
              </h3>
              <h4 className="popover-legend-subtitle popover-text__advisor_pending">
                {i18n.t(
                  "components.siexStatusIcon.uploadAdvisorPendingSubLabel"
                )}
              </h4>
            </div>
          </div>
        )}
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-pending ${
              status === FBESiexRequestStatus.PENDING
                ? "siex-status-icon-pending-active"
                : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__pending">
              {i18n.t("components.siexStatusIcon.uploadPendingLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__pending">
              {i18n.t("components.siexStatusIcon.uploadPendingSubLabel")}
            </h4>
          </div>
        </div>
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-sent_pending ${
              status === FBESiexRequestStatus.SENT_PENDING
                ? "siex-status-icon-sent_pending-active"
                : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__sent_pending">
              {i18n.t("components.siexStatusIcon.uploadSentPendingLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__sent_pending">
              {i18n.t("components.siexStatusIcon.uploadSentPendingSubLabel")}
            </h4>
          </div>
        </div>
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-ok ${
              status === FBESiexRequestStatus.OK
                ? "siex-status-icon-ok-active"
                : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__ok">
              {i18n.t("components.siexStatusIcon.uploadOkLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__ok">
              {i18n.t("components.siexStatusIcon.uploadOkSubLabel")}
            </h4>
          </div>
        </div>
        <div className="popover-legend-item">
          <div
            className={`siex-status-icon-legend siex-status-icon-error ${
              [
                FBESiexRequestStatus.ERROR,
                FBESiexRequestStatus.NOSYNC,
              ].includes(status)
                ? "siex-status-icon-error-active"
                : ""
            }`}
          />
          <div className="popover-legend-item-content">
            <h3 className="popover-legend-title popover-text__error">
              {i18n.t("components.siexStatusIcon.uploadErrorLabel")}
            </h3>
            <h4 className="popover-legend-subtitle popover-text__error">
              {i18n.t("components.siexStatusIcon.uploadErrorSubLabel")}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
