class Person {
  id?: number;
  email?: string;
  disabled?: boolean;
  master?: boolean;
  emailForNotifications?: string;
  whatsappNumber?: string;
  timeZone?: string;
  termsOfUseShow?: boolean;
  creationDate?: string;
  timeZoneOffset?: number;
  language?: string;
  acceptedPrivacyPolicyVersion?: string;
  privacyPolicyAcceptedOn?: string;
  pendingPrivacyPolicyUrl?: string;
  pendingPrivacyPolicyVersion?: string;

  constructor(
    id?: number,
    email?: string,
    disabled?: boolean,
    master?: boolean,
    creationDate?: string,
    timeZoneOffset?: number,
    emailForNotifications?: string,
    whatsappNumber?: string,
    language?: string,
    acceptedPrivacyPolicyVersion?: string,
    privacyPolicyAcceptedOn?: string,
    pendingPrivacyPolicyUrl?: string,
    pendingPrivacyPolicyVersion?: string
  ) {
    this.id = id;
    this.email = email;
    this.disabled = disabled;
    this.master = master;
    this.creationDate = creationDate;
    this.timeZoneOffset = timeZoneOffset;
    this.emailForNotifications = emailForNotifications;
    this.whatsappNumber = whatsappNumber;
    this.language = language;
    this.acceptedPrivacyPolicyVersion = acceptedPrivacyPolicyVersion;
    this.privacyPolicyAcceptedOn = privacyPolicyAcceptedOn;
    this.pendingPrivacyPolicyUrl = pendingPrivacyPolicyUrl;
    this.pendingPrivacyPolicyVersion = pendingPrivacyPolicyVersion;
  }

  mapToClass(obj: any): Person {
    this.id = obj?.id;
    this.email = obj?.email;
    this.disabled = obj?.disabled;
    this.master = obj?.master;
    this.creationDate = obj?.creationDate;
    this.timeZoneOffset = obj?.timeZoneOffset;
    this.emailForNotifications = obj?.emailForNotifications;
    this.whatsappNumber = obj?.whatsappNumber;
    this.language = obj?.language;
    this.acceptedPrivacyPolicyVersion = obj?.acceptedPrivacyPolicyVersion;
    this.privacyPolicyAcceptedOn = obj?.privacyPolicyAcceptedOn;
    this.pendingPrivacyPolicyUrl = obj?.pendingPrivacyPolicyUrl;
    this.pendingPrivacyPolicyVersion = obj?.pendingPrivacyPolicyVersion;
    return this;
  }
}

export default Person;
