import { EnergyTypeEnum } from "../../constants/enums";

class EnergyType {
  id?: number;
  name?: string;
  code?: EnergyTypeEnum;

  constructor(id?: number, name?: string, code?: EnergyTypeEnum) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  mapToClass(obj: any): EnergyType | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    return this;
  }

  mapToApiModel(): any {
    if (this.code === EnergyTypeEnum.NONE) return null; // Code not exits in API
    return this.code;
  }
}

export default EnergyType;
