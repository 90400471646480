import FBFacility from "../_apiModels/agrobusiness/FBFacility";
import Document from "../files/Document";

import { LESSOR_TENURES_CODES } from "../../constants/lists";

class Facility extends FBFacility {
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): Facility | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove custom variables
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isLessor(): boolean {
    return LESSOR_TENURES_CODES.includes(this.tenureType?.code || "");
  }
}

export default Facility;
