import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
  Alert,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import FormikTextField from "../elements/FormikTextField";
import AlertEmptyList from "../AlertEmptyList";

import Facility from "../../models/agrobusiness/Facility";
import FacilityPhytosanitaryTreatmentFacility from "../../models/phyto/FacilityPhytosanitaryTreatmentFacility";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface DialogTreatmentFacilityProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (
    treatmentFacility: FacilityPhytosanitaryTreatmentFacility
  ) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  selected?: FacilityPhytosanitaryTreatmentFacility | null;
  treatmentFacilities?: FacilityPhytosanitaryTreatmentFacility[];
}

const filter = createFilterOptions();
const DialogTreatmentFacility = (props: DialogTreatmentFacilityProps) => {
  const YupValidatorSchema = Yup.object().shape({
    facility: Yup.object().required(),
    treatedVolume: Yup.number().required().positive(),
  });

  const {
    open,
    onClose,
    onConfirm,
    onError,
    selected,
    treatmentFacilities = [],
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: FacilityPhytosanitaryTreatmentFacility) => {
    // Find the max idx
    const maxIdx = getMaxValueOfList(treatmentFacilities, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FacilityPhytosanitaryTreatmentFacility(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: facilities, isFetching } = useFetch<Facility[]>({
    queryKey: ["facilities", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);
  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewFacility = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_FACILITIES}`);
  };

  const handleChangeFacility = (facility: Facility) => {
    formik.setFieldValue("facility", facility);
    if (facility.storageVolume)
      formik.setFieldValue("treatedVolume", facility.storageVolume);
  };

  const handleChangeTreatedVolume = (event: any) => {
    const treatedVolume = event.target.value || 0;
    if (
      formik.values.facility?.storageVolume &&
      treatedVolume > formik.values.facility.storageVolume
    )
      event.target.value = formik.values.facility.storageVolume;
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  // Filter selected treatment facilities
  const facilitiesFilter = (
    options: FacilityPhytosanitaryTreatmentFacility[],
    params: any
  ) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (facility: any) =>
        !treatmentFacilities.find(
          (treatmentFacility) => treatmentFacility?.facility?.id === facility.id
        )
    );
    if (selected)
      filterSelected.unshift(
        selected.facility as FacilityPhytosanitaryTreatmentFacility
      );
    return filterSelected;
  };

  return (
    <Dialog open={open} onClose={closeDialog} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogTreatmentFacility.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogTreatmentFacility.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetching && facilities?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewFacility}>
                {i18n.t("components.dialogTreatmentFacility.emptyList")}
              </AlertEmptyList>
            )}
            {!selected &&
              facilities &&
              facilities.length > 0 &&
              facilities.length === treatmentFacilities?.length && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {i18n.t(
                    "components.dialogTreatmentFacility.noMoreFacilities"
                  )}
                </Alert>
              )}
            <FormikAutocomplete
              formik={formik}
              name="facility"
              label={i18n.t("components.dialogTreatmentFacility.facility")}
              optionLabelFieldName="name"
              required
              filterOptions={facilitiesFilter}
              options={facilities || []}
              loading={isFetching}
              addNewOption
              onClickNewOption={handleClickNewFacility}
              onChange={handleChangeFacility}
              disabled={!!selected}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              className="form-input"
              name="treatedVolume"
              label={i18n.t("components.dialogTreatmentFacility.treatedVolume")}
              type="number"
              valueUnit="m3"
              required
              onInput={handleChangeTreatedVolume}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogTreatmentFacility.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogTreatmentFacility.redirectAlertDialogContentText"
        )}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogTreatmentFacility;
