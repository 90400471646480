import AgroBusinessPartition from "../agrobusiness/AgroBusinessPartition";
import FBFertilizerProductPurchase from "../_apiModels/fertiliz/FBFertilizerProductPurchase";
import Document from "../files/Document";

class FertilizerProductPurchase extends FBFertilizerProductPurchase {
  agroBusinessPartition?: AgroBusinessPartition; // AgroBusinessPartition from Stock movements
  documents?: Document[];
  lastSavedDocuments?: Document[];
  description?: string;

  constructor(
    agroBusinessPartition?: AgroBusinessPartition,
    documents?: Document[],
    description?: string
  ) {
    super();
    this.agroBusinessPartition = agroBusinessPartition;
    this.documents = documents;
    this.description = description;
  }

  mapToClass(obj: any): FertilizerProductPurchase | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.stockMovements && this.stockMovements.length > 0)
      this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
        this.stockMovements[0]?.agroBusinessPartition
      );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Set purchase description and agrobusinessPartition to all purchase stock movements
    if (obj.stockMovements)
      obj.stockMovements = obj.stockMovements.map((stockMovement: any) => ({
        ...stockMovement,
        description: this.description,
        agroBusinessPartition: this.agroBusinessPartition?.mapToApiModel(),
      }));
    // Remove custom variables
    delete obj.agroBusinessPartition;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.description;
    return obj;
  }
}

export default FertilizerProductPurchase;
