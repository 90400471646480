import { useState } from "react";
import i18n from "../config/configI18n";

import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import packageJson from "../../package.json";
import ShortcutsMenu from "./ShortcutsMenu";

const Footer = () => {
  const [shortcutsMenuOpen, setShortcutsMenuOpen] = useState<boolean>(false);

  const handleClickMenu = () => setShortcutsMenuOpen((state) => !state);

  return (
    <footer className="footer">
      <div className="container">
        <div className="shortcuts-menu">
          <ListItem disablePadding onClick={handleClickMenu}>
            <ListItemButton>
              <ListItemIcon>
                {shortcutsMenuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemIcon>
              <ListItemText
                primary={i18n.t("components.shortcutsMenu.title")}
              />
            </ListItemButton>
          </ListItem>
        </div>
        <Divider sx={{ width: "100%", marginBottom: 2 }} />
        <div className="content">
          <span>2023 © BrioAgro Technologies.</span>
          <span className="version">v{packageJson.version}</span>
        </div>
      </div>
      <ShortcutsMenu open={shortcutsMenuOpen} onChange={setShortcutsMenuOpen} />
    </footer>
  );
};

export default Footer;
