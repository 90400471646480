import { MenuItem, Typography } from "@mui/material";
import { AddCircleRounded } from "@mui/icons-material";
import i18n from "../../config/configI18n";

interface Props {
  label?: string;
  value?: string;
  onClick?: () => void;
}
const AddNewOptionListItem = (props: Props) => {
  const { label = i18n.t("words.createNew"), value, onClick } = props;

  return (
    <MenuItem sx={{ pl: 3 }} value={value} onClick={onClick}>
      <AddCircleRounded sx={{ color: "primary.light", mr: 1 }} />
      <Typography variant="button">{label}</Typography>
    </MenuItem>
  );
};

export default AddNewOptionListItem;
