import FBAgroBusinessLeadershipList from "../_apiModels/agrobusiness/FBAgroBusinessLeadershipList";

import { LEADER_TYPES } from "../../providers/YupProvider";
import { LeaderType } from "../../constants/enums";

class AgroBusinessLeadershipList extends FBAgroBusinessLeadershipList {
  notCreatedLeadershipList?: any[]; // Rest of not created leadership list minus coOwners

  constructor(notCreatedLeadershipList?: any[]) {
    super();
    this.notCreatedLeadershipList = notCreatedLeadershipList || [];
  }

  mapToClass(obj: any): AgroBusinessLeadershipList | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    const restLeaderList = [];
    if (!this.owner) {
      const owner = LEADER_TYPES.find(
        (leaderType) => leaderType.code === LeaderType.OWNER
      );
      if (owner) restLeaderList.push(owner);
    }
    // Always include coOwner because can be exits more than one
    const coOwner = LEADER_TYPES.find(
      (leaderType) => leaderType.code === LeaderType.COOWNER
    );
    if (coOwner) restLeaderList.push(coOwner);
    if (!this.ownerRepresentative) {
      const ownerRepresentative = LEADER_TYPES.find(
        (leaderType) => leaderType.code === LeaderType.OWNERREPRESENTATIVE
      );
      if (ownerRepresentative) restLeaderList.push(ownerRepresentative);
    }
    if (!this.manager) {
      const manager = LEADER_TYPES.find(
        (leaderType) => leaderType.code === LeaderType.MANAGER
      );
      if (manager) restLeaderList.push(manager);
    }
    this.notCreatedLeadershipList = restLeaderList;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.notCreatedLeadershipList;
    return obj;
  }
}

export default AgroBusinessLeadershipList;
