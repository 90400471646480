import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
  Alert,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import AlertEmptyList from "../AlertEmptyList";
import FormAlertDialog from "./FormAlertDialog";

import FertilizerTank from "../../models/fertiliz/FertilizerTank";
import CropIrrigationFertilizerTank from "../../models/crop/CropIrrigationFertilizerTank";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { SnackbarInterface } from "../../constants/interfaces";

interface DialogCropIrrigationFertilizerTankProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (cropFertilizerTank: CropIrrigationFertilizerTank) => void;
  onError?: (snackbarError: SnackbarInterface) => void;
  selected?: CropIrrigationFertilizerTank | null;
  cropFertilizerTanks?: CropIrrigationFertilizerTank[];
}

const filter = createFilterOptions();
const DialogCropIrrigationFertilizerTank = (
  props: DialogCropIrrigationFertilizerTankProps
) => {
  const YupValidatorSchema = Yup.object().shape({
    fertilizerTank: Yup.object().required(),
  });

  const {
    open,
    onClose,
    onConfirm,
    selected,
    cropFertilizerTanks = [],
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: CropIrrigationFertilizerTank) => {
    // Find the max idx of the fertilizerTanks
    const maxIdx = getMaxValueOfList(cropFertilizerTanks, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new CropIrrigationFertilizerTank(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: fertilizerTanks, isFetching } = useFetch<FertilizerTank[]>({
    queryKey: [
      "fertilizerTanks",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION_TANKS}`);
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  // Filter selected tanks
  const fertilizerTanksFilter = (options: FertilizerTank[], params: any) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (ft: any) =>
        !cropFertilizerTanks.find((cft) => cft.fertilizerTank?.id === ft.id)
    );
    if (selected)
      filterSelected.unshift(selected.fertilizerTank as FertilizerTank);
    return filterSelected;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("components.dialogCropFertilizerTank.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogCropFertilizerTank.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {fertilizerTanks?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewOption}>
                {i18n.t("components.dialogCropFertilizerTank.emptyList")}
              </AlertEmptyList>
            )}
            {fertilizerTanks &&
              fertilizerTanks.length > 0 &&
              fertilizerTanks.length === cropFertilizerTanks?.length && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {i18n.t("components.dialogCropFertilizerTank.noMoreTanks")}
                </Alert>
              )}
            <FormikAutocomplete
              formik={formik}
              name="fertilizerTank"
              label={i18n.t(
                "components.dialogCropFertilizerTank.fertilizerTank"
              )}
              optionLabelFieldName="name"
              required
              filterOptions={fertilizerTanksFilter}
              options={fertilizerTanks || []}
              loading={isFetching}
              disabled={!!selected}
              addNewOption
              onClickNewOption={handleClickNewOption}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="quantity"
              label={i18n.t("components.dialogCropFertilizerTank.quantity")}
              helperText={i18n.t(
                "components.dialogCropFertilizerTank.quantityHelperText"
              )}
              type="number"
              valueUnit="l"
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogCropFertilizerTank.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogCropFertilizerTank.redirectAlertDialogContentText"
        )}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogCropIrrigationFertilizerTank;
