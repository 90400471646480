import { PlantProblemType } from "../../constants/enums";
import FBPostHarvestPhytosanitaryTreatment from "../_apiModels/phyto/FBPostHarvestPhytosanitaryTreatment";
import PlantDisease from "../catalogue/PlantDisease";
import PlantPlague from "../catalogue/PlantPlague";
import PlantProblem from "../catalogue/PlantProblem";
import PlantWeed from "../catalogue/PlantWeed";
import Document from "../files/Document";

export const MEASUREMENT_UNIT_CODES = ["5", "6", "11"];

class PostHarvestPhytosanitaryTreatment extends FBPostHarvestPhytosanitaryTreatment {
  plantProblems?: PlantProblem[];
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): PostHarvestPhytosanitaryTreatment | undefined {
    if (!obj) return undefined;
    super.mapToBaseClass(obj);
    this.plantProblems = this.getPlantProblems(
      this.weeds,
      this.plagues,
      this.diseases
    );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    [obj.weeds, obj.plagues, obj.diseases] = this.setPlantProblems(
      this.plantProblems || []
    );

    // Remove custom variables
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  getPlantProblems = (
    weeds?: PlantWeed[],
    plagues?: PlantPlague[],
    diseases?: PlantDisease[]
  ): PlantProblem[] => {
    const plantProblems: PlantProblem[] = [];
    if (weeds && weeds.length > 0) {
      weeds.forEach((weed) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...weed,
          type: PlantProblemType.WEED,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (plagues && plagues.length > 0) {
      plagues.forEach((plague) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...plague,
          type: PlantProblemType.PLAGUE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (diseases && diseases.length > 0) {
      diseases.forEach((disease) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...disease,
          type: PlantProblemType.DISEASE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    return plantProblems;
  };

  setPlantProblems(
    plantProblems: PlantProblem[]
  ): [weeds: PlantWeed[], plagues: PlantPlague[], diseases: PlantDisease[]] {
    const weeds: PlantWeed[] = [];
    const plagues: PlantPlague[] = [];
    const diseases: PlantDisease[] = [];

    plantProblems.forEach((plantProblem) => {
      switch (plantProblem.type) {
        case PlantProblemType.WEED:
          const plantWeed = new PlantWeed().mapToClass({ ...plantProblem });
          if (plantWeed) weeds.push(plantWeed);
          break;
        case PlantProblemType.PLAGUE:
          const plantPlague = new PlantPlague().mapToClass({ ...plantProblem });
          if (plantPlague) plagues.push(plantPlague);
          break;
        case PlantProblemType.DISEASE:
          const plantDisease = new PlantDisease().mapToClass({
            ...plantProblem,
          });
          if (plantDisease) diseases.push(plantDisease);
          break;

        default:
          break;
      }
    });

    return [weeds, plagues, diseases];
  }
}

export default PostHarvestPhytosanitaryTreatment;
