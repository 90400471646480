import { MenuOption } from "../constants/interfaces";

interface ShortcutItemProps {
  option: MenuOption;
  onClick?: (option: MenuOption) => void;
}
const ShortcutItem = (props: ShortcutItemProps) => {
  const { option, onClick } = props;

  const handleClick = () => {
    if (onClick) {
      onClick(option);
    }
  };

  return (
    <div className="shortcut-item" onClick={handleClick}>
      <div className="icon">{option?.icon}</div>
      <span> {option?.label}</span>
    </div>
  );
};

export default ShortcutItem;
