import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  CircularProgress,
} from "@mui/material";

import PoliciesScreen from "../../screens/PoliciesScreen";

interface Props {
  open: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}
const DialogPolicies = (props: Props) => {
  const { open, isLoading = false, onConfirm, onClose } = props;

  return (
    <Dialog
      sx={{ zIndex: 9999 }}
      className="dialog"
      disableEscapeKeyDown
      open={open}
    >
      <DialogTitle className="title">
        {i18n.t("components.dialogPolicies.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="text">
          {i18n.t("components.dialogPolicies.description")}
        </DialogContentText>
        <PoliciesScreen />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={isLoading}>
          {i18n.t("words.reject")}
        </Button>
        <Button onClick={onConfirm} color="primary" disabled={isLoading}>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            i18n.t("words.acceptAll")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPolicies;
