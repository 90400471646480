import FBFertilizationPlan from "../_apiModels/fertiliz/FBFertilizationPlan";
import Document from "../files/Document";

class FertilizationPlan extends FBFertilizationPlan {
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): FertilizationPlan | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };

    // Remove custom variables
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default FertilizationPlan;
