import Person from "../../Person";

class FBPersonMessage {
  id?: number;
  person?: Person;
  timestamp?: number;
  expirationTimestamp?: number;
  messageRead?: boolean;
  readTimestamp?: number;
  messageText?: string;

  constructor(
    id?: number,
    person?: Person,
    timestamp?: number,
    expirationTimestamp?: number,
    messageRead?: boolean,
    readTimestamp?: number,
    messageText?: string
  ) {
    this.id = id;
    this.person = person;
    this.timestamp = timestamp;
    this.expirationTimestamp = expirationTimestamp;
    this.messageRead = messageRead;
    this.readTimestamp = readTimestamp;
    this.messageText = messageText;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj.id;
    this.person = new Person().mapToClass(obj.person);
    this.timestamp = obj.timestamp;
    this.expirationTimestamp = obj.expirationTimestamp;
    this.messageRead = obj.messageRead;
    this.readTimestamp = obj.readTimestamp;
    this.messageText = obj.messageText;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBPersonMessage;
