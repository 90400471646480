import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { FormMode } from "../../constants/enums";

interface Props {
  id?: string;
  open: boolean;
  title: string;
  contentText: string;
  formAction?: FormMode;
  isLoading?: boolean;
  confirmBtnText?: string;
  cancelBtnText?: string;
  onConfirm: () => void;
  onCancel: () => void;
}
const FormAlertDialog = (props: Props) => {
  const { confirmBtnText, cancelBtnText = i18n.t("words.cancel") } = props;

  return (
    <Dialog
      id={props.id}
      className="dialog"
      open={props.open}
      onClose={props.onCancel}
    >
      <DialogTitle className="title">{props.title}</DialogTitle>
      <DialogContent className="content">
        <DialogContentText>{props.contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel} color="inherit">
          {cancelBtnText}
        </Button>
        <LoadingButton
          onClick={props.onConfirm}
          color={props.formAction === FormMode.DELETE ? "error" : "primary"}
          autoFocus
          loading={props.isLoading}
        >
          {confirmBtnText ||
            (props.formAction === FormMode.DELETE
              ? i18n.t("words.delete")
              : i18n.t("words.yes"))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FormAlertDialog;
