import FBAgroBusinessClassification from "../_apiModels/agrobusiness/FBAgroBusinessClassification";

class AgroBusinessClassification extends FBAgroBusinessClassification {
  constructor() {
    super();
  }

  mapToClass(obj: any): AgroBusinessClassification | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default AgroBusinessClassification;
