import FBProduct from "../_apiModels/catalogue/FBProduct";

class CropProduct extends FBProduct {
  constructor() {
    super();
  }

  mapToClass(obj: any): CropProduct | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default CropProduct;
