import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import { FormGroup, FormControl, Collapse, FormLabel } from "@mui/material";
import { Troubleshoot } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import PartitionForm from "../../components/forms/PartitionForm";
import TestBanner from "../../components/banners/TestBanner";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import AlertEmptyList from "../../components/AlertEmptyList";
import SiexUploadStatusBanner from "../../components/banners/SiexUploadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import AnalizedMaterialType from "../../models/catalogue/AnalizedMaterialType";
import { Column } from "../../models/Generic";
import Analysis from "../../models/analysis/Analysis";
import AnalysisType from "../../models/catalogue/AnalysisType";
import Document from "../../models/files/Document";
import Sector from "../../models/agrobusiness/Sector";
import SiexRequestItem from "../../models/siex/SiexRequestItem";

import { dateConverter } from "../../helpers/utils";
import { SnackbarInterface } from "../../constants/interfaces";
import { FBEntitiesTypes, FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

const ExploitationAnalysisScreen = () => {
  const analysisColumns: Column[] = [
    {
      id: "analizedMaterial.name",
      label: i18n.t("analysis.column.analizedMaterial"),
      numeric: false,
    },
    {
      id: "dateFormatted",
      label: i18n.t("analysis.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "types",
      label: i18n.t("analysis.column.types"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "name",
    },
  ];

  const AnalysisValidatorSchema = Yup.object().shape({
    date: Yup.string().required(),
    analizedMaterial: Yup.object().required(),
    types: Yup.array().required(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: analysis, isFetching } = useFetch<Analysis[]>({
    queryKey: ["analysis", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? analysis?.find((a: Analysis) => a.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, analysis]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const handleSubmit = (values: Analysis) => {
    switch (formMode) {
      case FormMode.CREATE:
        analysisCreateMutation.mutate({});
        break;
      case FormMode.EDIT:
        analysisEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<Analysis>({
    initialValues: new Analysis(),
    validationSchema: AnalysisValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("analysis.drawerTitleCreate")
      : i18n.t("analysis.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.ANALYSYS &&
      siexRegister.entityClassId
    ) {
      setSelectedRowIds([siexRegister.entityClassId]);
      openDrawer(FormMode.EDIT);
    }
  }, [siexRegister]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, { state: { siexRegister } });
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_ANALYSIS}`, { replace: true });
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) {
      queryClient.refetchQueries({
        queryKey: ["analysis"],
        type: "active",
      });
      closeDialogAndUnselectedRows();
    }
    setSnackbarMsg(snackBarError);
  };

  const analysisCreateMutation = useCrud<Analysis>({
    key: "postAnalysis",
    values: formik.values,
    onSuccess: (data: Analysis) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["analysis"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const analysisEditMutation = useCrud<Analysis>({
    key: "putAnalysis",
    values: formik.values,
    onSuccess: (data: Analysis) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["analysis"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const analysisDeleteMutation = useCrud({
    key: "deleteAnalysis",
    values: analysis?.filter((d: Analysis) =>
      selectedRowIds.includes(d?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["analysis"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    analysisDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("analysis.backAlertTitle")}
        contentText={i18n.t("analysis.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("analysis.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("analysis.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={analysisDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("analysis.tableTitle")}
        headerText={i18n.t("analysis.headerText")}
        colums={analysisColumns}
        rows={analysis}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="date"
        emptyTableIcon={
          <Troubleshoot sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("analysis.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("analysis.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("analysis.emptyTableBtnText")}
        isLoading={isFetching}
        addBtnVariant="contained"
        siexRows
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          analysisCreateMutation.isLoading || analysisEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <AnalysisForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationAnalysisScreen;

interface AnalysisFormProps {
  formik: FormikProps<Analysis>;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: Analysis;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const AnalysisForm = (props: AnalysisFormProps) => {
  const {
    formik,
    isSiexActive,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { agroBusinessPartitions } = useSession();
  const location = useLocation();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const { isFetching: isUpdating } = useFetch<Analysis>({
    queryKey: ["analysisById", selectedEditRow?.id],
    selected: selectedEditRow,
    refetchOnWindowFocus: false, // Input file not work if true
    enabled: !!selectedEditRow?.id,
    onSuccess: (data: Analysis) => formik.setValues(data),
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <AnalysisTypeForm {...props} />
      <AnalizedSectorsForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("analysis.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const AnalysisTypeForm = (props: AnalysisFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount } = useSession();

  const {
    data: analizedMaterialTypes,
    isFetching: isFetchingAnalizedMaterials,
  } = useFetch<AnalizedMaterialType[]>({
    queryKey: ["analizedMaterialTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    refetchOnWindowFocus: false, // Input file not work if true
    onError,
  });

  const { data: analysisTypes, isFetching: isFetchingAnalysisTypes } = useFetch<
    AnalysisType[]
  >({
    queryKey: ["analysisTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    refetchOnWindowFocus: false, // Input file not work if true
    onError,
  });

  const handleChangeDate = (date: any) => {
    const dateStr = date?.format("YYYY-MM-DD");
    if (dateStr) {
      formik.setFieldValue("date", dateStr);
      formik.setFieldValue("dateFormatted", dateConverter({ date: dateStr }));
    }
  };

  return (
    <BrioCard
      title={i18n.t("analysis.analysisTypeForm.title_v2")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            className="form-input"
            formik={formik}
            name="analizedMaterial"
            label={i18n.t("analysis.analysisTypeForm.analizedMaterial")}
            required
            options={analizedMaterialTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetchingAnalizedMaterials}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="types"
            label={i18n.t("analysis.analysisTypeForm.types")}
            placeholder={i18n.t("analysis.analysisTypeForm.typesPlaceholder")}
            multiple
            required
            options={analysisTypes || []}
            optionLabelFieldName="name"
            loading={isFetchingAnalysisTypes}
          />
        </FormControl>
        <Collapse in={formik.values.isSoilParametersCode()}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control-row">
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilOrganicMaterial"
                label={i18n.t("analysis.analysisTypeForm.soilOrganicMaterial")}
                placeholder={i18n.t(
                  "analysis.analysisTypeForm.soilOrganicMaterialPlaceholder"
                )}
                type="number"
                valueUnit="%"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilSand"
                label={i18n.t("analysis.analysisTypeForm.soilSand")}
                type="number"
                valueUnit="%"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilSilt"
                label={i18n.t("analysis.analysisTypeForm.soilSilt")}
                type="number"
                valueUnit="%"
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control-row">
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilClay"
                label={i18n.t("analysis.analysisTypeForm.soilClay")}
                type="number"
                valueUnit="%"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilPH"
                label={i18n.t("analysis.analysisTypeForm.soilPH")}
                type="number"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilAvailablePhosphorus"
                label={i18n.t(
                  "analysis.analysisTypeForm.soilAvailablePhosphorus"
                )}
                type="number"
                valueUnit="ppm"
              />
            </FormControl>
            <FormControl
              variant="outlined"
              className="form-control-row"
              style={{ marginBottom: "20px" }}
            >
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilAvailablePotassium"
                label={i18n.t(
                  "analysis.analysisTypeForm.soilAvailablePotassium"
                )}
                type="number"
                valueUnit="ppm"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilTotalNitrogen"
                label={i18n.t("analysis.analysisTypeForm.soilTotalNitrogen")}
                type="number"
                valueUnit="%"
              />
              <FormikTextField
                formik={formik}
                className="form-input-row-32"
                name="soilConductivity"
                label={i18n.t("analysis.analysisTypeForm.soilConductivity")}
                type="number"
                valueUnit="dS/m"
              />
            </FormControl>
          </div>
        </Collapse>
        <FormControl variant="outlined" className="form-control" required>
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={i18n.t("analysis.analysisTypeForm.date")}
            value={
              formik.values?.date
                ? moment(formik.values.date, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeDate}
          />
          {formik.touched.date && formik.errors.date && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-60"
            formik={formik}
            name="laboratory"
            label={i18n.t("analysis.analysisTypeForm.laboratory")}
            placeholder={i18n.t(
              "analysis.analysisTypeForm.laboratoryPlaceholder"
            )}
          />
          <FormikTextField
            className="form-input-row-40"
            formik={formik}
            name="laboratoryId"
            label={i18n.t("analysis.analysisTypeForm.laboratoryId")}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="bulletinNumber"
            label={i18n.t("analysis.analysisTypeForm.bulletinNumber")}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const AnalizedSectorsForm = (props: AnalysisFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const handleClickNewOption = () => openRedirectAlertDialog();

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
  };

  return (
    <BrioCard title={i18n.t("analysis.analizedSectorsForm.title")} optional>
      {!isFetching && sectors?.length === 0 && (
        <AlertEmptyList severity="info" onClick={handleClickNewOption}>
          {i18n.t("analysis.analizedSectorsForm.emptyList")}
        </AlertEmptyList>
      )}
      <FormGroup className="form-group">
        <FormikAutocomplete
          formik={formik}
          name="sectors"
          label={i18n.t("analysis.analizedSectorsForm.sectors")}
          placeholder={i18n.t(
            "analysis.analizedSectorsForm.sectorsPlaceholder"
          )}
          multiple
          options={sectors || []}
          optionLabelFieldName="name"
          loading={isFetching}
          addNewOption
          onClickNewOption={handleClickNewOption}
        />
      </FormGroup>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t("analysis.analizedSectorsForm.redirectAlertDialogTitle")}
        contentText={i18n.t(
          "analysis.analizedSectorsForm.redirectAlertDialogDescription"
        )}
        open={isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </BrioCard>
  );
};
