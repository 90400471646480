import { useEffect } from "react";
import { setLocale } from "yup";
import i18n from "../config/configI18n";

import {
  Opacity,
  GasMeter,
  Science,
  Inventory,
  ImportExport,
  Hive,
  PestControl,
  InsertDriveFile,
} from "@mui/icons-material";

import EnergyType from "../models/catalogue/EnergyType";

import {
  EntitySexInterface,
  EntityTypeInterface,
  ToolInspectionResultInterface,
  WaterSourceTypeInterface,
  PhytosanitaryTrainingLevelInterface,
  FertilizationApplicationOriginInterface,
  FertilizerProductSubstanceInterface,
  FertilizerStockMovementTypeInterface,
  PhytosanitaryStockMovementTypeInterface,
  SourceTypeInterface,
  PersonPermissionRoleInterface,
  LeaderTypeInterface,
  MenuOption,
  PhytoRecipeTypeInterface,
  PlantProblemTypeInterface,
  EntityClassNameInterface,
} from "../constants/interfaces";
import {
  EnergyTypeEnum,
  EntitySex,
  EntityType,
  FBEntitiesTypes,
  FBPersonRole,
  FertilizationOriginType,
  FertilizerStockMovementType,
  FertilizerSubstanceType,
  LeaderType,
  PhytoRecipeType,
  PhytosanitaryStockMovementType,
  PhytosanitaryTrainingLevel,
  PlantProblemType,
  PluviometrySource,
  StockFertilizerTab,
  StockPhytosanitaryTab,
  ToolInspectionResult,
  WaterSourceType,
} from "../constants/enums";
import { PROTECTED_ROUTES } from "../routes/routeNames";
import { FertilizerProductType } from "../models/fertiliz/FertilizerProduct";

export let PHYTOSANITARY_TRAINING_LEVELS: PhytosanitaryTrainingLevelInterface[] =
  [];
export let ENTITY_TYPES: EntityTypeInterface[] = [];
export let ENTITY_SEX_TYPES: EntitySexInterface[] = [];
export let TOOL_INSPECTION_RESULT_LIST: ToolInspectionResultInterface[] = [];
export let WATER_SOURCES_TYPES: WaterSourceTypeInterface[] = [];
export let ENERGY_TYPES: EnergyType[] = [];
export let APPLICATION_ORIGIN_LIST: FertilizationApplicationOriginInterface[] =
  [];
export let FERTILIZER_SUBSTANCE_TYPES: FertilizerProductSubstanceInterface[] =
  [];
export let FERTILIZER_STOCK_MOVEMENT_TYPES: FertilizerStockMovementTypeInterface[] =
  [];
export let PHYTOSANITARY_STOCK_MOVEMENT_TYPES: PhytosanitaryStockMovementTypeInterface[] =
  [];
export let PLUVIOMETRY_SOURCE_TYPES: SourceTypeInterface[] = [];
export let PERSON_PERMISSION_ROLES_LIST: PersonPermissionRoleInterface[] = [];
export let LEADER_TYPES: LeaderTypeInterface[] = [];
export let SHORTCUTS_LIST: MenuOption[] = [];
export let PHYTO_RECIPE_TYPES: PhytoRecipeTypeInterface[] = [];
export let PLANT_PROBLEM_TYPES: PlantProblemTypeInterface[] = [];
export let FERTILIZER_PRODUCT_TYPES: FertilizerProductType[] = [];
export let ENTITY_CLASS_NAMES: EntityClassNameInterface[] = [];

const updateTranslationLists = () => {
  SHORTCUTS_LIST = [
    {
      id: "create-irrigation",
      label: i18n.t("components.shortcutsMenu.createIrrigation"),
      icon: <Opacity fontSize="large" />,
      smallIcon: <Opacity />,
      route: PROTECTED_ROUTES.EXPLOITATION_IRRIGATIONS,
    },
    {
      id: "create-fertilization",
      label: i18n.t("components.shortcutsMenu.createFertilization"),
      icon: <Hive fontSize="large" />,
      smallIcon: <Hive />,
      route: PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION,
    },
    {
      id: "create-crop-treatement",
      label: i18n.t("components.shortcutsMenu.createCropTreatement"),
      icon: <PestControl fontSize="large" />,
      smallIcon: <PestControl />,
      route: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
    {
      id: "create-purchase",
      label: i18n.t("components.shortcutsMenu.createPurchase"),
      icon: <Inventory fontSize="large" />,
      smallIcon: <Inventory />,
      children: [
        {
          id: "fertilizer-purchase",
          label: i18n.t("components.shortcutsMenu.fertilizerPurchase"),
          icon: <GasMeter fontSize="large" />,
          smallIcon: <GasMeter />,
          route: `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}/${FertilizerStockMovementType.PURCHASE}`,
        },
        {
          id: "phyto-purchase",
          label: i18n.t("components.shortcutsMenu.phytoPurchase"),
          icon: <Science fontSize="large" />,
          smallIcon: <Science />,
          route: `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/${StockPhytosanitaryTab.MOVEMENTS}/${PhytosanitaryStockMovementType.PURCHASE}`,
        },
      ],
    },
    {
      id: "stock-adjust",
      label: i18n.t("components.shortcutsMenu.stockAdjust"),
      icon: <ImportExport fontSize="large" />,
      smallIcon: <ImportExport />,
      children: [
        {
          id: "fertilizer-stock-adjust",
          label: i18n.t("components.shortcutsMenu.fertilizerStockAdjust"),
          icon: <GasMeter fontSize="large" />,
          smallIcon: <GasMeter />,
          route: `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}/${FertilizerStockMovementType.STOCK_ADJUST}`,
        },
        {
          id: "phyto-stock-adjust",
          label: i18n.t("components.shortcutsMenu.phytoStockAdjust"),
          icon: <Science fontSize="large" />,
          smallIcon: <Science />,
          route: `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/${StockPhytosanitaryTab.MOVEMENTS}/${PhytosanitaryStockMovementType.STOCK_ADJUST}`,
        },
      ],
    },
    {
      id: "create-water-mix",
      label: i18n.t("components.shortcutsMenu.createWaterMix"),
      icon: <GasMeter fontSize="large" />,
      smallIcon: <GasMeter />,
      route: `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}/${FertilizerStockMovementType.ELABORATION}`,
    },
    {
      id: "create-document",
      label: i18n.t("components.shortcutsMenu.createDocument"),
      icon: <InsertDriveFile fontSize="large" />,
      smallIcon: <InsertDriveFile />,
      route: PROTECTED_ROUTES.EXPLOITATION_FILES,
    },
  ];
  PHYTOSANITARY_TRAINING_LEVELS = [
    {
      id: 1,
      name: i18n.t("phytosanitaryTrainningLevels.basic"),
      code: PhytosanitaryTrainingLevel.BASIC,
    },
    {
      id: 2,
      name: i18n.t("phytosanitaryTrainningLevels.skilled"),
      code: PhytosanitaryTrainingLevel.SKILLED,
    },
    {
      id: 3,
      name: i18n.t("phytosanitaryTrainningLevels.fumigator"),
      code: PhytosanitaryTrainingLevel.FUMIGATOR,
    },
    {
      id: 4,
      name: i18n.t("phytosanitaryTrainningLevels.pilot"),
      code: PhytosanitaryTrainingLevel.PILOT,
    },
  ];
  ENTITY_TYPES = [
    {
      id: 1,
      name: i18n.t("entityTypes.physical"),
      code: EntityType.PHYSICAL,
    },
    { id: 2, name: i18n.t("entityTypes.legal"), code: EntityType.LEGAL },
  ];
  ENTITY_SEX_TYPES = [
    { id: 1, name: i18n.t("entitySexTypes.male"), code: EntitySex.MALE },
    { id: 2, name: i18n.t("entitySexTypes.female"), code: EntitySex.FEMALE },
    {
      id: 3,
      name: i18n.t("entitySexTypes.intersex"),
      code: EntitySex.INTERSEX,
    },
  ];
  TOOL_INSPECTION_RESULT_LIST = [
    {
      id: 1,
      name: i18n.t("toolInspectionResult.ok"),
      code: ToolInspectionResult.OK,
    },
    {
      id: 2,
      name: i18n.t("toolInspectionResult.okWarnings"),
      code: ToolInspectionResult.OK_WARNINGS,
    },
    {
      id: 3,
      name: i18n.t("toolInspectionResult.noOk"),
      code: ToolInspectionResult.NO_OK,
    },
  ];
  WATER_SOURCES_TYPES = [
    {
      id: 1,
      name: i18n.t("waterSourceType.river"),
      code: WaterSourceType.RIVER,
    },
    { id: 2, name: i18n.t("waterSourceType.well"), code: WaterSourceType.WELL },
    {
      id: 3,
      name: i18n.t("waterSourceType.spring"),
      code: WaterSourceType.SPRING,
    },
    {
      id: 4,
      name: i18n.t("waterSourceType.fountain"),
      code: WaterSourceType.FOUNTAIN,
    },
    {
      id: 5,
      name: i18n.t("waterSourceType.reservoir"),
      code: WaterSourceType.RESERVOIR,
    },
  ];
  ENERGY_TYPES = [
    new EnergyType(1, i18n.t("energyType.none"), EnergyTypeEnum.NONE),
    new EnergyType(2, i18n.t("energyType.renewable"), EnergyTypeEnum.RENEWABLE),
    new EnergyType(
      3,
      i18n.t("energyType.nonRenewable"),
      EnergyTypeEnum.NON_RENEWABLE
    ),
    new EnergyType(4, i18n.t("energyType.mixed"), EnergyTypeEnum.MIXED),
  ];
  APPLICATION_ORIGIN_LIST = [
    {
      id: 1,
      name: i18n.t("fertilizationApplicationProductOrigin.product"),
      code: FertilizationOriginType.PRODUCT,
    },
    {
      id: 2,
      name: i18n.t("fertilizationApplicationProductOrigin.mix"),
      code: FertilizationOriginType.MIXTURE,
    },
  ];
  FERTILIZER_SUBSTANCE_TYPES = [
    {
      id: 1,
      name: i18n.t("fertilizerSubstanceType.macronutrient"),
      code: FertilizerSubstanceType.MACRONUTRIENT,
    },
    {
      id: 2,
      name: i18n.t("fertilizerSubstanceType.micronutrient"),
      code: FertilizerSubstanceType.MICRONUTRIENT,
    },
    {
      id: 3,
      name: i18n.t("fertilizerSubstanceType.heavymetal"),
      code: FertilizerSubstanceType.HEAVYMETAL,
    },
  ];
  FERTILIZER_STOCK_MOVEMENT_TYPES = [
    {
      id: 1,
      name: i18n.t("fertilizerStockMovementType.purchase"),
      code: FertilizerStockMovementType.PURCHASE,
    },
    {
      id: 2,
      name: i18n.t("fertilizerStockMovementType.elaboration"),
      code: FertilizerStockMovementType.ELABORATION,
    },
    {
      id: 3,
      name: i18n.t("fertilizerStockMovementType.stockAdjust"),
      code: FertilizerStockMovementType.STOCK_ADJUST,
    },
    {
      id: 4,
      name: i18n.t("fertilizerStockMovementType.fertilization"),
      code: FertilizerStockMovementType.FERTILIZATION,
    },
  ];
  PHYTOSANITARY_STOCK_MOVEMENT_TYPES = [
    {
      id: 1,
      name: i18n.t("phytosanitaryStockMovementType.purchase"),
      code: PhytosanitaryStockMovementType.PURCHASE,
    },
    {
      id: 2,
      name: i18n.t("phytosanitaryStockMovementType.stockAdjust"),
      code: PhytosanitaryStockMovementType.STOCK_ADJUST,
    },
    {
      id: 3,
      name: i18n.t("phytosanitaryStockMovementType.treatment"),
      code: PhytosanitaryStockMovementType.TREATMENT,
    },
  ];
  PLUVIOMETRY_SOURCE_TYPES = [
    {
      id: 1,
      name: i18n.t("pluviometrySourceType.brioagroStation"),
      code: PluviometrySource.BRIOAGRO_STATION,
    },
    {
      id: 2,
      name: i18n.t("pluviometrySourceType.brioagroMeteo"),
      code: PluviometrySource.BRIOAGRO_METEO,
    },
    {
      id: 3,
      name: i18n.t("pluviometrySourceType.manual"),
      code: PluviometrySource.MANUAL,
    },
  ];
  PERSON_PERMISSION_ROLES_LIST = [
    { id: 1, name: i18n.t("personRoles.admin"), role: FBPersonRole.ADMIN },
    { id: 2, name: i18n.t("personRoles.writer"), role: FBPersonRole.WRITER },
    { id: 3, name: i18n.t("personRoles.reader"), role: FBPersonRole.READER },
  ];
  LEADER_TYPES = [
    { id: 1, code: LeaderType.OWNER, name: i18n.t("leadership.owner") },
    { id: 2, code: LeaderType.COOWNER, name: i18n.t("leadership.coowner") },
    {
      id: 3,
      code: LeaderType.OWNERREPRESENTATIVE,
      name: i18n.t("leadership.ownerRepresentative"),
    },
    { id: 4, code: LeaderType.MANAGER, name: i18n.t("leadership.manager") },
  ];
  PHYTO_RECIPE_TYPES = [
    {
      id: 1,
      name: i18n.t("recipes.recipeTypeCropLabel"),
      code: PhytoRecipeType.CROP,
    },
    {
      id: 2,
      name: i18n.t("recipes.recipeTypePostharvestLabel"),
      code: PhytoRecipeType.POSTHARVEST,
    },
    {
      id: 3,
      name: i18n.t("recipes.recipeTypeFacility"),
      code: PhytoRecipeType.FACILITY,
    },
  ];
  PLANT_PROBLEM_TYPES = [
    {
      id: 1,
      name: i18n.t("plantProblemTypes.plagues"),
      code: PlantProblemType.PLAGUE,
    },
    {
      id: 2,
      name: i18n.t("plantProblemTypes.diseases"),
      code: PlantProblemType.DISEASE,
    },
    {
      id: 3,
      name: i18n.t("plantProblemTypes.weeds"),
      code: PlantProblemType.WEED,
    },
    {
      id: 4,
      name: i18n.t("plantProblemTypes.regulators"),
      code: PlantProblemType.REGULATORS,
    },
  ];
  FERTILIZER_PRODUCT_TYPES = [
    {
      id: 1,
      code: "1",
      name: i18n.t("fertilizerProductTypes.registered"),
    },
    {
      id: 2,
      code: "2",
      name: i18n.t("fertilizerProductTypes.custom"),
    },
  ];
  ENTITY_CLASS_NAMES = [
    {
      id: 1,
      name: FBEntitiesTypes.ANALYSYS,
      label: i18n.t("entityClassNamesTypes.analysis"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_ANALYSIS,
    },
    {
      id: 2,
      name: FBEntitiesTypes.CROP_IRRIGATION,
      label: i18n.t("entityClassNamesTypes.cropIrrigation"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_IRRIGATIONS,
    },
    {
      id: 3,
      name: FBEntitiesTypes.FERTILIZATION,
      label: i18n.t("entityClassNamesTypes.fertilization"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION,
    },
    {
      id: 4,
      name: FBEntitiesTypes.CROP_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.cropPhytosanitaryTreatment"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
    {
      id: 5,
      name: FBEntitiesTypes.POSTHARVEST_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.postHarvestPhytosanitaryTreatment"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
    {
      id: 6,
      name: FBEntitiesTypes.FACILITY_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.facilityPhytosanitaryTreatment"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
    {
      id: 7,
      name: FBEntitiesTypes.TREATED_SEED,
      label: i18n.t("entityClassNamesTypes.treatedSeed"),
      routePath: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
  ];
};

interface Props {
  children: React.ReactNode;
}
const YupProvider = (props: Props) => {
  // Neccessary to translate the yup errors
  updateTranslationLists();
  // Translate lists when changing language
  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      setLocale({
        mixed: {
          required: i18n.t("formErrors.requiredField"),
        },
        string: {
          email: i18n.t("formErrors.invalidEmail"),
        },
      });
      updateTranslationLists();
    });
  }, []);

  return <>{props.children}</>;
};

export default YupProvider;
