import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import i18n from "../../config/configI18n";

import TestBanner from "../../components/banners/TestBanner";
import ShortcutItem from "../../components/ShortCutItem";

import { SHORTCUTS_LIST } from "../../providers/YupProvider";
import { MenuOption } from "../../constants/interfaces";

const DashboardScreen = () => {
  const navigate = useNavigate();

  const [shortcuts, setShortcuts] = useState(SHORTCUTS_LIST);

  useEffect(() => {
    setShortcuts(SHORTCUTS_LIST);
  }, [SHORTCUTS_LIST]);

  const handleClickShortcut = (option: MenuOption) => {
    if (option.route) {
      if (!option.route.startsWith("/")) navigate(`/${option.route}`);
      else if (option.route) {
        // Insert nested route to navigation history
        const nestedRoute = option.route.split("/");
        const lastPath = nestedRoute[nestedRoute.length - 1];
        nestedRoute.pop();
        navigate(nestedRoute.join("/"));
        navigate(lastPath);
      }
    }
  };

  return (
    <div>
      <TestBanner />
      <div className="dashboard-screen">
        <h1>{i18n.t("dashboard.title")}</h1>
        <div className="content">
          {shortcuts.map((option) =>
            option.children ? (
              option.children.map((option) => (
                <div key={option.id} className="item">
                  <ShortcutItem option={option} onClick={handleClickShortcut} />
                </div>
              ))
            ) : (
              <div key={option.id} className="item">
                <ShortcutItem option={option} onClick={handleClickShortcut} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen;
