import React from "react";
import * as Sentry from "@sentry/react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import packageJson from "../../package.json";

// Initialize Sentry for error and performance monitoring
// In dev mode, only init to test the performance
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  debug: process.env.REACT_APP_ENV === "dev",
  environment: process.env.REACT_APP_ENV,
  release: packageJson.version,
  enabled: process.env.REACT_APP_ENV !== "dev",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", process.env.REACT_APP_WEB_URL || ""],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === "dev" ? 1 : 0.1, // Capture 10% of the transactions. In dev 100% to test the performance
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
