import FBCrop from "../_apiModels/crop/FBCrop";
import Document from "../files/Document";
import CropProduct from "../catalogue/CropProduct";
import EnergyType from "../catalogue/EnergyType";

import { capitalize } from "../../helpers/utils";

import { ENERGY_TYPES } from "../../providers/YupProvider";

class Crop extends FBCrop {
  irrigationEType?: EnergyType;
  product?: CropProduct;
  permanentCrop?: boolean;
  isTreatedSeed?: boolean;
  productName?: string;
  productNameWithVarieties?: string;
  productVarietiesNames?: string;
  description?: string;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(
    irrigationEType?: EnergyType,
    product?: CropProduct,
    isTreatedSeed?: boolean,
    permanentCrop?: boolean,
    productName?: string,
    productNameWithVarieties?: string,
    productVarietiesNames?: string,
    documents?: Document[]
  ) {
    super();
    this.irrigationEType = irrigationEType;
    this.product = product;
    this.isTreatedSeed = isTreatedSeed || false;
    this.permanentCrop = permanentCrop || false;
    this.productName = productName;
    this.productNameWithVarieties = productNameWithVarieties;
    this.productVarietiesNames = productVarietiesNames;
    this.documents = documents;
  }

  mapToClass(obj: any): Crop | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.irrigationEType =
      ENERGY_TYPES.find(
        (energyType) => energyType.code === obj.irrigationEnergyType
      ) || ENERGY_TYPES[0];
    if (this.varieties && this.varieties.length > 0) {
      // Varieties of the same product
      this.product = new CropProduct().mapToClass(
        this.varieties[0].productType
      );
      this.productName = capitalize(this.product?.name || "");
      // Example: "tomate (rama)"
      this.productNameWithVarieties = `${this.productName} (${capitalize(
        this.varieties[0].name
      )}${this.varieties.length > 1 ? "..." : ""})`;
      this.productVarietiesNames = `${
        this.varieties?.map(
          (variety, idx) =>
            `${idx !== 0 ? " " : ""}${capitalize(variety?.name)}`
        ) || ""
      }`;
    }
    this.description = obj.name || this.productNameWithVarieties;
    this.permanentCrop = !!this.plantingYear;
    this.isTreatedSeed = !!this?.treatedSeed;
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.irrigationEnergyType = this.irrigationEType?.mapToApiModel();
    // If !isTreatedSeed, set treatedSeed to null
    if (!this.isTreatedSeed) obj.treatedSeed = null;
    // Crop area is the sum of the areas of the cropSectors
    obj.area = this.cropSectors?.reduce(
      (acc, sector) => acc + (sector?.area || 0),
      0
    );

    // Delete variables of this class
    delete obj.irrigationEType;
    delete obj.product;
    delete obj.permanentCrop;
    delete obj.isTreatedSeed;
    delete obj.productName;
    delete obj.productNameWithVarieties;
    delete obj.productVarietiesNames;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default Crop;
