import { useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import { SyncProblem } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import { TableBrioCard } from "../components/BrioCard";
import TestBanner from "../components/banners/TestBanner";

import { useSession } from "../hooks/useSession";

import { Column } from "../models/Generic";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";

const SiexErrorsScreen = () => {
  const navigate = useNavigate();
  const { selectedABAccount, siexErrors } = useSession();

  const emptyTableTitle = selectedABAccount?.agroBusiness?.siexEntity
    ? i18n.t("siexErrors.emptyTableTitle")
    : i18n.t("siexErrors.emptyTableTitleNoSiexExploitation");

  const siexErrorsColumns: Column[] = [
    {
      id: "section.label",
      label: i18n.t("siexErrors.column.sectionName"),
      numeric: false,
    },
    {
      id: "request.dateFormatted",
      label: i18n.t("siexErrors.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "errorMessage",
      label: i18n.t("siexErrors.column.errorMessage"),
      numeric: false,
    },
  ];

  const onClick = (id: number) => {
    const siexRegister = siexErrors.find((s) => s.id === id);
    if (siexRegister && siexRegister.section?.routePath) {
      navigate(`/${siexRegister.section.routePath}/edit`, {
        state: { siexRegister },
      });
    }
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <TableBrioCard
        title={i18n.t("siexErrors.tableTitle")}
        headerText={i18n.t("siexErrors.headerText")}
        colums={siexErrorsColumns}
        rows={siexErrors}
        defaultOrderFieldName="request.responseTimestamp"
        addBtnVariant="contained"
        hideBtn
        hideCheckbox
        emptyTableIcon={
          <SyncProblem sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={emptyTableTitle}
        onClickEdit={onClick}
      />
    </ScreenContentLayout>
  );
};

export default SiexErrorsScreen;
