import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import Sector from "../../agrobusiness/Sector";

class FBPluviometryRegister {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  source?: string; //*
  sourceId?: string; //*
  startDate?: string; //*
  endDate?: string; //*
  totalPrecipitation?: number; //*
  sectors?: Sector[];
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    source?: string,
    sourceId?: string,
    startDate?: string,
    endDate?: string,
    totalPrecipitation?: number,
    sectors?: Sector[],
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.source = source;
    this.sourceId = sourceId;
    this.startDate = startDate;
    this.endDate = endDate;
    this.totalPrecipitation = totalPrecipitation;
    this.sectors = sectors;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.source = obj?.source;
    this.sourceId = obj?.sourceId;
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.totalPrecipitation = obj?.totalPrecipitation;
    this.sectors =
      obj?.sectors?.map((sector: any) => new Sector().mapToClass(sector)) || [];
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.sectors = this.sectors?.map((sector: Sector) =>
      sector?.mapToApiModel()
    );
    return obj;
  }
}

export default FBPluviometryRegister;
