class FBActiveSubstance {
  id?: number;
  name?: string;
  code?: string;

  constructor(id?: number, name?: string, code?: string) {
    this.id = id;
    this.name = name;
    this.code = code;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBActiveSubstance;
