import Crop from "../../crop/Crop";
import IrrigationGoodPractice from "../../catalogue/IrrigationGoodPractice";
import IrrigationSystemType from "../../catalogue/IrrigationSystemType";
import IrrigationWaterSourceType from "../../catalogue/IrrigationWaterSourceType";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import CropIrrigationAreaSector from "../../crop/CropIrrigationAreaSector";
import CropIrrigationFertilizerTank from "../../crop/CropIrrigationFertilizerTank";
import { FBESiexRequestStatus } from "../../../constants/enums";

class FBCropIrrigation {
  id?: number;
  crop?: Crop; //*
  startTime?: string;
  endTime?: string;
  irrigationArea?: number;
  irrigationSystem?: IrrigationSystemType;
  quantity?: number;
  quantityUnit?: MeasurementUnit;
  irrigationDose?: number;
  irrigationDoseUnit?: MeasurementUnit;
  irrigationCounterId?: string;
  waterSourceTypes?: IrrigationWaterSourceType[];
  goodPracticesDeclared?: boolean;
  goodPractices?: IrrigationGoodPractice[];
  energyType?: string;
  fertigationApplied?: boolean;
  fertigationStartTime?: string;
  fertigationEndTime?: string;
  fertigationNitrogenDose?: number;
  fertigationNitrogenDoseUnit?: MeasurementUnit;
  fertigationPhosphorusDose?: number;
  fertigationPhosphorusDoseUnit?: MeasurementUnit;
  irrigatedSectors?: CropIrrigationAreaSector[];
  fertilizerTanks?: CropIrrigationFertilizerTank[];
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    crop?: Crop,
    startTime?: string,
    endTime?: string,
    irrigationArea?: number,
    irrigationSystem?: IrrigationSystemType,
    quantity?: number,
    quantityUnit?: MeasurementUnit,
    irrigationDose?: number,
    irrigationDoseUnit?: MeasurementUnit,
    irrigationCounterId?: string,
    waterSourceTypes?: IrrigationWaterSourceType[],
    goodPracticesDeclared?: boolean,
    goodPractices?: IrrigationGoodPractice[],
    energyType?: string,
    fertigationApplied?: boolean,
    fertigationStartTime?: string,
    fertigationEndTime?: string,
    fertigationNitrogenDose?: number,
    fertigationNitrogenDoseUnit?: MeasurementUnit,
    fertigationPhosphorusDose?: number,
    fertigationPhosphorusDoseUnit?: MeasurementUnit,
    irrigatedSectors?: CropIrrigationAreaSector[],
    fertilizerTanks?: CropIrrigationFertilizerTank[]
  ) {
    this.id = id;
    this.crop = crop;
    this.startTime = startTime;
    this.endTime = endTime;
    this.irrigationArea = irrigationArea;
    this.irrigationSystem = irrigationSystem;
    this.quantity = quantity;
    this.quantityUnit = quantityUnit;
    this.irrigationDose = irrigationDose;
    this.irrigationDoseUnit = irrigationDoseUnit;
    this.irrigationCounterId = irrigationCounterId;
    this.waterSourceTypes = waterSourceTypes;
    this.goodPracticesDeclared = goodPracticesDeclared;
    this.goodPractices = goodPractices;
    this.energyType = energyType;
    this.fertigationApplied = fertigationApplied;
    this.fertigationStartTime = fertigationStartTime;
    this.fertigationEndTime = fertigationEndTime;
    this.fertigationNitrogenDose = fertigationNitrogenDose;
    this.fertigationNitrogenDoseUnit = fertigationNitrogenDoseUnit;
    this.fertigationPhosphorusDose = fertigationPhosphorusDose;
    this.fertigationPhosphorusDoseUnit = fertigationPhosphorusDoseUnit;
    this.irrigatedSectors = irrigatedSectors;
    this.fertilizerTanks = fertilizerTanks;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.startTime = obj?.startTime;
    this.endTime = obj?.endTime;
    this.irrigationArea = obj?.irrigationArea;
    this.irrigationSystem = new IrrigationSystemType().mapToClass(
      obj?.irrigationSystem
    );
    this.quantity = obj?.quantity;
    this.quantityUnit = new MeasurementUnit().mapToClass(obj?.quantityUnit);
    this.irrigationDose = obj?.irrigationDose;
    this.irrigationDoseUnit = new MeasurementUnit().mapToClass(
      obj?.irrigationDoseUnit
    );
    this.irrigationCounterId = obj?.irrigationCounterId;
    this.waterSourceTypes =
      obj?.waterSourceTypes?.map((waterSourceType: any) =>
        new IrrigationWaterSourceType().mapToClass(waterSourceType)
      ) || [];
    this.goodPracticesDeclared = obj?.goodPracticesDeclared;
    this.goodPractices = obj?.goodPractices?.map((goodPractice: any) =>
      new IrrigationGoodPractice().mapToClass(goodPractice)
    );
    this.energyType = obj?.energyType;
    this.fertigationApplied = obj?.fertigationApplied;
    this.fertigationStartTime = obj?.fertigationStartTime;
    this.fertigationEndTime = obj?.fertigationEndTime;
    this.fertigationNitrogenDose = obj?.fertigationNitrogenDose;
    this.fertigationNitrogenDoseUnit = new MeasurementUnit().mapToClass(
      obj?.fertigationNitrogenDoseUnit
    );
    this.fertigationPhosphorusDose = obj?.fertigationPhosphorusDose;
    this.fertigationPhosphorusDoseUnit = new MeasurementUnit().mapToClass(
      obj?.fertigationPhosphorusDoseUnit
    );
    this.irrigatedSectors =
      obj?.irrigatedSectors
        ?.map((irrigatedSector: any) =>
          new CropIrrigationAreaSector().mapToClass(irrigatedSector)
        )
        ?.filter(
          (irrigatedSector: CropIrrigationAreaSector) =>
            !irrigatedSector?.sector?.disabledDate
        ) || [];
    this.fertilizerTanks = obj?.fertilizerTanks?.map((fertilizerTank: any) =>
      new CropIrrigationFertilizerTank().mapToClass(fertilizerTank)
    );
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.crop = this.crop?.mapToApiModel();
    obj.irrigationSystem = this.irrigationSystem?.mapToApiModel();
    obj.quantityUnit = this.quantityUnit?.mapToApiModel();
    obj.irrigationDoseUnit = this.irrigationDoseUnit?.mapToApiModel();
    obj.fertigationNitrogenDoseUnit =
      this.fertigationNitrogenDoseUnit?.mapToApiModel();
    obj.fertigationPhosphorusDoseUnit =
      this.fertigationPhosphorusDoseUnit?.mapToApiModel();
    obj.waterSourceTypes = this.waterSourceTypes?.map(
      (irrigationWaterSourceType: IrrigationWaterSourceType) =>
        irrigationWaterSourceType?.mapToApiModel()
    );
    obj.goodPractices = this.goodPractices?.map(
      (goodPractice: IrrigationGoodPractice) => goodPractice?.mapToApiModel()
    );
    obj.irrigatedSectors = this.irrigatedSectors?.map(
      (irrigatedSector: CropIrrigationAreaSector) =>
        irrigatedSector?.mapToApiModel()
    );
    obj.fertilizerTanks = this.fertilizerTanks?.map(
      (fertilizerTank: CropIrrigationFertilizerTank) =>
        fertilizerTank?.mapToApiModel()
    );
    return obj;
  }
}

export default FBCropIrrigation;
