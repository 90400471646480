class CropDestination {
  id?: number;
  name?: string;
  code?: string;
  observations?: string;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    observations?: string
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.observations = observations;
  }

  mapToClass(obj: any): CropDestination | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.observations = obj?.observations;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default CropDestination;
