import PotentialAreaType from "../../catalogue/PotentialAreaType";
import Province from "../../address/Province";
import QualityRegime from "../../catalogue/QualityRegime";

class FBAgroBusinessWineInfo {
  id?: number;
  name?: string; //*
  potentialAreaType?: PotentialAreaType;
  potentialArea?: number;
  province?: Province;
  dopProtectedMarkName?: QualityRegime;
  enabledDate?: string;
  disabledDate?: string;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    name?: string,
    potentialAreaType?: PotentialAreaType,
    potentialArea?: number,
    province?: Province,
    dopProtectedMarkName?: QualityRegime,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.name = name;
    this.potentialAreaType = potentialAreaType;
    this.potentialArea = potentialArea;
    this.province = province;
    this.dopProtectedMarkName = dopProtectedMarkName;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.potentialAreaType = new PotentialAreaType().mapToClass(
      obj?.potentialAreaType
    );
    this.potentialArea = obj?.potentialArea;
    this.province = new Province().mapToClass(obj?.province);
    this.dopProtectedMarkName = new QualityRegime().mapToClass(
      obj?.dopProtectedMarkName
    );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.potentialAreaType = this.potentialAreaType?.mapToApiModel();
    obj.province = this.province?.mapToApiModel();
    obj.dopProtectedMarkName = this.dopProtectedMarkName?.mapToApiModel();
    return obj;
  }
}

export default FBAgroBusinessWineInfo;
