import { FBENotificationType } from "../../../constants/enums";
class FBNotification {
  id?: number;
  type?: FBENotificationType;
  timestamp?: number;
  startTimestamp?: number;
  expirationTimestamp?: number;
  text?: string;
  read?: boolean;
  readTimestamp?: number;

  constructor(
    id?: number,
    type?: FBENotificationType,
    timestamp?: number,
    startTimestamp?: number,
    expirationTimestamp?: number,
    text?: string,
    read?: boolean,
    readTimestamp?: number
  ) {
    this.id = id;
    this.type = type;
    this.timestamp = timestamp;
    this.startTimestamp = startTimestamp;
    this.expirationTimestamp = expirationTimestamp;
    this.text = text;
    this.read = read;
    this.readTimestamp = readTimestamp;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.type = obj?.type;
    this.timestamp = obj?.timestamp;
    this.startTimestamp = obj?.startTimestamp;
    this.expirationTimestamp = obj?.expirationTimestamp;
    this.text = obj?.text;
    this.read = obj?.read;
    this.readTimestamp = obj?.readTimestamp;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBNotification;
