import VadeFertilizerManufacturer from "../../vademecumFertiliz/VadeFertilizerManufacturer";
import VadeFertilizerProductType from "../../vademecumFertiliz/VadeFertilizerProductType";

class FBRegisteredFertilizerProduct {
  id?: number;
  url?: string;
  registerId?: string;
  name?: string; //*
  type?: VadeFertilizerProductType;
  manufacturer?: VadeFertilizerManufacturer;
  packagingFormat?: string;
  useMethod?: string;
  packaging?: string;
  nitrogenTotalPercentage?: string;
  ammoniacalNitrogenPercentage?: string;
  nitricNitrogenPercentage?: string;
  organicNitrogenPercentage?: string;
  ureicNitrogenPercentage?: string;
  P2O5Percentage?: string;
  waterP2O5Percentage?: string;
  K2OPercentage?: string;
  SO3Percentage?: string;
  Na2OPercentage?: string;
  CaOPercentage?: string;
  MgOPercentage?: string;
  BPercentage?: string;
  CoPercentage?: string;
  CuPercentage?: string;
  FePercentage?: string;
  MnPercentage?: string;
  MbPercentage?: string;
  ZnPercentage?: string;
  minPH?: string;
  maxPH?: string;
  organicMaterialPercentage?: string;
  organicCarbonPercentage?: string;
  humicExtractTotalPercentage?: string;
  humicAcidsTotalPercentage?: string;
  fulvicAcidsTotalPercentage?: string;
  clurideContent?: string;
  maxHumidity?: string;
  electricalConductivity?: string;
  density?: string;
  ph?: string;
  CNRelation?: string;
  solubilityDegreeTotalPercentage?: string;
  polyphenolsPercentage?: string;
  waterSolubleProduct?: boolean;
  cadmium?: string;
  copper?: string;
  nickel?: string;
  lead?: string;
  zinc?: string;
  mercury?: string;
  chromeTotal?: string;
  chromeVI?: string;
  productClassification?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    url?: string,
    registerId?: string,
    name?: string,
    type?: VadeFertilizerProductType,
    manufacturer?: VadeFertilizerManufacturer,
    packagingFormat?: string,
    useMethod?: string,
    packaging?: string,
    nitrogenTotalPercentage?: string,
    ammoniacalNitrogenPercentage?: string,
    nitricNitrogenPercentage?: string,
    organicNitrogenPercentage?: string,
    ureicNitrogenPercentage?: string,
    P2O5Percentage?: string,
    waterP2O5Percentage?: string,
    K2OPercentage?: string,
    SO3Percentage?: string,
    Na2OPercentage?: string,
    CaOPercentage?: string,
    MgOPercentage?: string,
    BPercentage?: string,
    CoPercentage?: string,
    CuPercentage?: string,
    FePercentage?: string,
    MnPercentage?: string,
    MbPercentage?: string,
    ZnPercentage?: string,
    minPH?: string,
    maxPH?: string,
    organicMaterialPercentage?: string,
    organicCarbonPercentage?: string,
    humicExtractTotalPercentage?: string,
    humicAcidsTotalPercentage?: string,
    fulvicAcidsTotalPercentage?: string,
    clurideContent?: string,
    maxHumidity?: string,
    electricalConductivity?: string,
    density?: string,
    ph?: string,
    CNRelation?: string,
    solubilityDegreeTotalPercentage?: string,
    polyphenolsPercentage?: string,
    waterSolubleProduct?: boolean,
    cadmium?: string,
    copper?: string,
    nickel?: string,
    lead?: string,
    zinc?: string,
    mercury?: string,
    chromeTotal?: string,
    chromeVI?: string,
    productClassification?: string,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.url = url;
    this.registerId = registerId;
    this.name = name;
    this.type = type;
    this.manufacturer = manufacturer;
    this.packagingFormat = packagingFormat;
    this.useMethod = useMethod;
    this.packaging = packaging;
    this.nitrogenTotalPercentage = nitrogenTotalPercentage;
    this.ammoniacalNitrogenPercentage = ammoniacalNitrogenPercentage;
    this.nitricNitrogenPercentage = nitricNitrogenPercentage;
    this.organicNitrogenPercentage = organicNitrogenPercentage;
    this.ureicNitrogenPercentage = ureicNitrogenPercentage;
    this.P2O5Percentage = P2O5Percentage;
    this.waterP2O5Percentage = waterP2O5Percentage;
    this.K2OPercentage = K2OPercentage;
    this.SO3Percentage = SO3Percentage;
    this.Na2OPercentage = Na2OPercentage;
    this.CaOPercentage = CaOPercentage;
    this.MgOPercentage = MgOPercentage;
    this.BPercentage = BPercentage;
    this.CoPercentage = CoPercentage;
    this.CuPercentage = CuPercentage;
    this.FePercentage = FePercentage;
    this.MnPercentage = MnPercentage;
    this.MbPercentage = MbPercentage;
    this.ZnPercentage = ZnPercentage;
    this.minPH = minPH;
    this.maxPH = maxPH;
    this.organicMaterialPercentage = organicMaterialPercentage;
    this.organicCarbonPercentage = organicCarbonPercentage;
    this.humicExtractTotalPercentage = humicExtractTotalPercentage;
    this.humicAcidsTotalPercentage = humicAcidsTotalPercentage;
    this.fulvicAcidsTotalPercentage = fulvicAcidsTotalPercentage;
    this.clurideContent = clurideContent;
    this.maxHumidity = maxHumidity;
    this.electricalConductivity = electricalConductivity;
    this.density = density;
    this.ph = ph;
    this.CNRelation = CNRelation;
    this.solubilityDegreeTotalPercentage = solubilityDegreeTotalPercentage;
    this.polyphenolsPercentage = polyphenolsPercentage;
    this.waterSolubleProduct = waterSolubleProduct;
    this.cadmium = cadmium;
    this.copper = copper;
    this.nickel = nickel;
    this.lead = lead;
    this.zinc = zinc;
    this.mercury = mercury;
    this.chromeTotal = chromeTotal;
    this.chromeVI = chromeVI;
    this.productClassification = productClassification;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj.id;
    this.url = obj.url;
    this.registerId = obj.registerId;
    this.name = obj.name;
    this.type = new VadeFertilizerProductType().mapToClass(obj?.type);
    this.manufacturer = new VadeFertilizerManufacturer().mapToClass(
      obj?.manufacturer
    );
    this.packagingFormat = obj.packagingFormat;
    this.useMethod = obj.useMethod;
    this.packaging = obj.packaging;
    this.nitrogenTotalPercentage = obj.nitrogenTotalPercentage;
    this.ammoniacalNitrogenPercentage = obj.ammoniacalNitrogenPercentage;
    this.nitricNitrogenPercentage = obj.nitricNitrogenPercentage;
    this.organicNitrogenPercentage = obj.organicNitrogenPercentage;
    this.ureicNitrogenPercentage = obj.ureicNitrogenPercentage;
    this.P2O5Percentage = obj.P2O5Percentage;
    this.waterP2O5Percentage = obj.waterP2O5Percentage;
    this.K2OPercentage = obj.K2OPercentage;
    this.SO3Percentage = obj.SO3Percentage;
    this.Na2OPercentage = obj.Na2OPercentage;
    this.CaOPercentage = obj.CaOPercentage;
    this.MgOPercentage = obj.MgOPercentage;
    this.BPercentage = obj.BPercentage;
    this.CoPercentage = obj.CoPercentage;
    this.CuPercentage = obj.CuPercentage;
    this.FePercentage = obj.FePercentage;
    this.MnPercentage = obj.MnPercentage;
    this.MbPercentage = obj.MbPercentage;
    this.ZnPercentage = obj.ZnPercentage;
    this.minPH = obj.minPH;
    this.maxPH = obj.maxPH;
    this.organicMaterialPercentage = obj.organicMaterialPercentage;
    this.organicCarbonPercentage = obj.organicCarbonPercentage;
    this.humicExtractTotalPercentage = obj.humicExtractTotalPercentage;
    this.humicAcidsTotalPercentage = obj.humicAcidsTotalPercentage;
    this.fulvicAcidsTotalPercentage = obj.fulvicAcidsTotalPercentage;
    this.clurideContent = obj.clurideContent;
    this.maxHumidity = obj.maxHumidity;
    this.electricalConductivity = obj.electricalConductivity;
    this.density = obj.density;
    this.ph = obj.ph;
    this.CNRelation = obj.CNRelation;
    this.solubilityDegreeTotalPercentage = obj.solubilityDegreeTotalPercentage;
    this.polyphenolsPercentage = obj.polyphenolsPercentage;
    this.waterSolubleProduct = obj.waterSolubleProduct;
    this.cadmium = obj.cadmium;
    this.copper = obj.copper;
    this.nickel = obj.nickel;
    this.lead = obj.lead;
    this.zinc = obj.zinc;
    this.mercury = obj.mercury;
    this.chromeTotal = obj.chromeTotal;
    this.chromeVI = obj.chromeVI;
    this.productClassification = obj.productClassification;
    this.enabledDate = obj.enabledDate;
    this.disabledDate = obj.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.manufacturer = this.manufacturer?.mapToApiModel();
    return obj;
  }
}

export default FBRegisteredFertilizerProduct;
