import FertilizerMacronutrient from "../../catalogue/FertilizerMacronutrient";
import FertilizerProduct from "../../fertiliz/FertilizerProduct";

class FBFertilizerProductMacronutrient {
  id?: number;
  product?: FertilizerProduct;
  macronutrient?: FertilizerMacronutrient;
  percentage?: number;

  constructor(
    id?: number,
    product?: FertilizerProduct,
    macronutrient?: FertilizerMacronutrient,
    percentage?: number
  ) {
    this.id = id;
    this.product = product;
    this.macronutrient = macronutrient;
    this.percentage = percentage;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.product = new FertilizerProduct().mapToClass(obj?.product);
    this.macronutrient = new FertilizerMacronutrient().mapToClass(
      obj?.macronutrient
    );
    this.percentage = obj?.percentage;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.product = this.product?.mapToApiModel();
    obj.macronutrient = this.macronutrient?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerProductMacronutrient;
