import AgroBusinessClassType from "../../catalogue/AgroBusinessClassType";

class FBAgroBusinessClassification {
  id?: number;
  classificationType?: AgroBusinessClassType; //*
  startDate?: string; //*
  disabledDate?: string;

  constructor(
    id?: number,
    classificationType?: AgroBusinessClassType,
    startDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.classificationType = classificationType;
    this.startDate = startDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.classificationType = new AgroBusinessClassType().mapToClass(
      obj?.classificationType
    );
    this.startDate = obj?.startDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.classificationType = this.classificationType?.mapToApiModel();
    return obj;
  }
}

export default FBAgroBusinessClassification;
