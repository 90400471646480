import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import { FormGroup, FormControl, InputAdornment } from "@mui/material";
import { Water } from "@mui/icons-material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import WaterSource from "../../models/agrobusiness/WaterSource";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";

import { updateItemOfList } from "../../helpers/utils";
import { WATER_SOURCES_TYPES } from "../../providers/YupProvider";

const WaterSourcesSchema = Yup.object().shape({
  name: Yup.string().required("Campo requerido"),
  waterSourceType: Yup.object({
    code: Yup.string().required("Campo requerido"),
  }),
});

const ExploitationWaterSourcesScreen = () => {
  const waterSourcesColumns: Column[] = [
    { id: "name", label: i18n.t("waterSources.column.name"), numeric: false },
    {
      id: "waterSourceType.name",
      label: i18n.t("waterSources.column.type"),
      numeric: false,
    },
    {
      id: "distanceToFarm",
      label: i18n.t("waterSources.column.distanceToFarm"),
      numeric: true,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: waterSources, isFetching } = useFetch<WaterSource[]>({
    queryKey: ["waterSources", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
  });

  const handleSubmit = (values: WaterSource) => {
    switch (formMode) {
      case FormMode.CREATE:
        waterSourceCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        waterSourceEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new WaterSource(),
    validationSchema: WaterSourcesSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("waterSources.drawerTitleCreate")
      : i18n.t("waterSources.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const waterSourceCreateMutation = useCrud<WaterSource>({
    key: "postWaterSource",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const waterSourceEditMutation = useCrud<WaterSource>({
    key: "putWaterSource",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const waterSourceDeleteMutation = useCrud({
    key: "deleteWaterSources",
    values: waterSources?.filter((ws: WaterSource) =>
      selectedRowIds.includes(ws?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    waterSourceDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <SiexBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("waterSources.backAlertTitle")}
        contentText={i18n.t("waterSources.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("waterSources.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("waterSources.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={waterSourceDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("waterSources.tableTitle")}
        headerText={i18n.t("waterSources.headerText")}
        colums={waterSourcesColumns}
        rows={waterSources}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Water sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("waterSources.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("waterSources.emptyTableSubtitleSIEX")
            : i18n.t("waterSources.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("waterSources.emptyTableBtnText")
            : undefined
        }
        addBtnVariant="contained"
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={i18n.t("waterSources.drawerSubtitle")}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          waterSourceCreateMutation.isLoading ||
          waterSourceEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <WaterSourcesForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? waterSources?.find(
                  (ws: WaterSource) => ws.id === selectedRowIds[0]
                )
              : undefined
          }
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationWaterSourcesScreen;

interface WaterSourceFormProps {
  formik: FormikProps<WaterSource>;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: WaterSource;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const WaterSourcesForm = (props: WaterSourceFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<WaterSource>({
    queryKey: ["waterSource", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: WaterSource) => {
      formik.setValues(data);
      // Update WS in array without refetch
      queryClient.setQueryData<WaterSource[]>(
        ["waterSources", selectedABPartition?.id, selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <div className="exploitation-water-sources">
      <LoadingWithDelay isVisible={isUpdating} />
      <SiexBanner adviceText />
      <DefinitionForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("waterSources.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const DefinitionForm = (props: WaterSourceFormProps) => {
  const { formik, forceExpanded } = props;

  return (
    <BrioCard
      title={i18n.t("waterSources.definitionForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="waterSourceType"
            label={i18n.t("waterSources.definitionForm.waterSourceType")}
            required
            options={WATER_SOURCES_TYPES}
            optionLabelFieldName="name"
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("waterSources.definitionForm.name")}
            helperText={i18n.t("waterSources.definitionForm.nameHelperText")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="description"
            label={i18n.t("waterSources.definitionForm.description")}
            multiline
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="distanceToFarm"
            label={i18n.t("waterSources.definitionForm.distanceToFarm")}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
