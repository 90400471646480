import FBRegisterRegion from "./_apiModels/FBRegisterRegion";

class RegisterRegion extends FBRegisterRegion {
  constructor() {
    super();
  }

  mapToClass(obj: any): RegisterRegion | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default RegisterRegion;
