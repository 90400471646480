import FBFertilizerProductMacronutrient from "../_apiModels/fertiliz/FBFertilizerProductMacronutrient";

class FertilizerProductMacronutrient extends FBFertilizerProductMacronutrient {
  idx?: number;

  constructor(idx?: number) {
    super();
    this.idx = idx;
  }

  mapToClass(obj: any): FertilizerProductMacronutrient | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.idx;
    return obj;
  }
}

export default FertilizerProductMacronutrient;
