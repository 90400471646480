import moment from "moment";

import FBTreatedSeed from "../_apiModels/phyto/FBTreatedSeed";
import Document from "../files/Document";

import { dateConverter } from "../../helpers/utils";
import { FBESiexRequestStatus } from "../../constants/enums";

class TreatedSeed extends FBTreatedSeed {
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): TreatedSeed | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    this.dateFormatted = dateConverter({ date: this.date });
    if (this.siexStatus === null) this.siexStatus = FBESiexRequestStatus.NOSYNC;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    if (!this.isTreatedInSitu()) obj.productMovement = null;
    // Copy treatedSeed adquisition or treatment date to product movement date
    else if (obj?.productMovement?.product) {
      const date = this.date || moment().format("YYYY-MM-DD");
      obj.date = date;
      obj.productMovement.date = date;
    }
    if (this.siexStatus === FBESiexRequestStatus.NOSYNC) obj.siexStatus = null;

    // Remove custom variables
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    return obj;
  }

  isTreatedInSitu(): boolean {
    return this.treatmentType?.code === "2";
  }

  isAcquiredSeed(): boolean {
    return ["4", "5"].includes(this.treatmentType?.code || "");
  }
}

export default TreatedSeed;
