import Entity from "../../agrobusiness/Entity";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import Person from "../../Person";

class FBAgroBusinessPhytosanitaryInvolvedEntity {
  id?: number;
  entity?: Entity; //*
  agroBusinessPartition?: AgroBusinessPartition;
  phytosanitaryAdvisor?: boolean;
  phytosanitaryApplicator?: boolean;
  phytosanitaryRegisterId?: string;
  trainingLevel?: string;
  fertilizationAdvisor?: boolean;
  fertilizationApplicator?: boolean;
  fertilizationRegisterId?: string;
  advisorUser?: Person;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    entity?: Entity,
    agroBusinessPartition?: AgroBusinessPartition,
    phytosanitaryAdvisor?: boolean,
    phytosanitaryApplicator?: boolean,
    phytosanitaryRegisterId?: string,
    trainingLevel?: string,
    fertilizationAdvisor?: boolean,
    fertilizationApplicator?: boolean,
    fertilizationRegisterId?: string,
    advisorUser?: Person,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.entity = entity;
    this.agroBusinessPartition = agroBusinessPartition;
    this.phytosanitaryAdvisor = phytosanitaryAdvisor;
    this.phytosanitaryApplicator = phytosanitaryApplicator;
    this.phytosanitaryRegisterId = phytosanitaryRegisterId;
    this.trainingLevel = trainingLevel;
    this.fertilizationAdvisor = fertilizationAdvisor;
    this.fertilizationApplicator = fertilizationApplicator;
    this.fertilizationRegisterId = fertilizationRegisterId;
    this.advisorUser = advisorUser;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.entity = new Entity().mapToClass(obj?.entity);
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.phytosanitaryAdvisor = obj?.phytosanitaryAdvisor;
    this.phytosanitaryApplicator = obj?.phytosanitaryApplicator;
    this.phytosanitaryRegisterId = obj?.phytosanitaryRegisterId;
    this.trainingLevel = obj?.trainingLevel;
    this.fertilizationAdvisor = obj?.fertilizationAdvisor;
    this.fertilizationApplicator = obj?.fertilizationApplicator;
    this.fertilizationRegisterId = obj?.fertilizationRegisterId;
    if (obj?.advisorUser?.id)
      this.advisorUser = new Person().mapToClass(obj?.advisorUser);
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.entity = this.entity?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    if (!this.phytosanitaryAdvisor) obj.advisorUser = null;
    return obj;
  }
}

export default FBAgroBusinessPhytosanitaryInvolvedEntity;
