import React, { useState, useEffect, useMemo, useRef } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { FormikProps, FormikTouched, useFormik } from "formik";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  TablePagination,
  useTheme,
  useMediaQuery,
  Tooltip,
  Drawer,
  IconButton,
  FormGroup,
  FormControl,
  TextField,
  InputAdornment,
  Button,
  LinearProgress,
  Collapse,
  Autocomplete,
  ListItem,
  Checkbox,
  ListItemText,
  Typography,
  SxProps,
  Theme,
  ListItemSecondaryAction,
  CircularProgress,
  Divider,
  ListItemButton,
  List,
  ListSubheader,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  Receipt,
  Send,
  Done,
  Close,
  DoneAll,
  ArrowBack,
  Delete,
  Grass,
  Science,
  Sanitizer,
  ShortText,
  Add,
  Edit,
  Save,
  Search,
  ExpandMore,
  Business,
  PestControl,
  CheckCircle,
  Error,
  Warning,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import TestBanner from "../../components/banners/TestBanner";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import EmptyTable from "../../components/EmptyTable";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import { BrioCard } from "../../components/BrioCard";
import AlertEmptyList from "../../components/AlertEmptyList";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import {
  ApplicationSection,
  CompositionSection,
  ProviderSection,
  StatusSection,
} from "../VademecumPhytoScreen";

import useFetch from "../../hooks/useFetch";
import { useSession } from "../../hooks/useSession";
import useCrud from "../../hooks/useCrud";

import PhytoRecipe, {
  MEASUREMENT_UNIT_CODES,
} from "../../models/phytoRecipe/PhytoRecipe";
import PhytoRecipeStatusHistory from "../../models/phytoRecipe/PhytoRecipeStatusHistory";
import RegisteredPhytosanitaryProduct from "../../models/vademecum/RegisteredPhytosanitaryProduct";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";
import Crop from "../../models/crop/Crop";
import PhytosanitaryProduct from "../../models/phyto/PhytosanitaryProduct";
import PlantProblem from "../../models/catalogue/PlantProblem";
import PhytosanitaryActionJustificationType from "../../models/catalogue/PhytosanitaryActionJustificationType";

import {
  ChangePhytoRecipeStatusInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import {
  FormMode,
  PhytoRecipeStatus,
  PhytoRecipeType,
  PhytosanitaryTreatmentType,
  PlantProblemType,
} from "../../constants/enums";
import { PHYTO_RECIPE_TYPES } from "../../providers/YupProvider";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../../constants/constants";
import { helperTextStyle } from "../../components/elements/FormikTextField";

const ExploitationRecipesScreen = () => {
  const { recipeId } = useParams<{ recipeId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount } = useSession();

  const selectedProduct = location.state?.product
    ? (location.state?.product as RegisteredPhytosanitaryProduct)
    : null;

  const { data: isPhytoAdvisor } = useFetch<boolean>({
    queryKey: ["isPhytoAdvisor", selectedABAccount?.agroBusiness?.id],
    enabled: !!selectedABAccount?.agroBusiness?.id,
  });

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedRecipe, setSelectedRecipe] = useState<PhytoRecipe | null>(
    null
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    if (!recipeId || recipeId === "0") setSelectedRecipe(null);
    setIsDrawerOpen(location.pathname.includes(`/${recipeId}`));
  }, [recipeId]);

  // Open drawer if there is a selected product from vademecum
  useEffect(() => {
    if (selectedProduct !== null) setIsDrawerOpen(true);
  }, [selectedProduct]);

  const openDrawer = (selected?: PhytoRecipe) => {
    navigate(`${location.pathname}/${selected?.id || 0}`);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRecipe(null);
    navigate(-1);
  };

  const handleClickOnCreateRecipe = () => {
    setSelectedRecipe(null);
    openDrawer();
  };

  const handleClickOnRecipe = (recipe: PhytoRecipe) => {
    setSelectedRecipe(recipe);
    openDrawer(recipe);
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <RecipesTable
        isPhytoAdvisor={isPhytoAdvisor}
        onCreate={handleClickOnCreateRecipe}
        onClick={handleClickOnRecipe}
        onMessage={setSnackbarMsg}
        onError={setSnackbarMsg}
      />
      <RecipeView
        isOpen={isDrawerOpen}
        selected={selectedRecipe}
        isPhytoAdvisor={isPhytoAdvisor}
        onMessage={setSnackbarMsg}
        onClose={closeDrawer}
        onError={setSnackbarMsg}
      />
    </ScreenContentLayout>
  );
};

export default ExploitationRecipesScreen;

interface RecipesTableProps {
  isPhytoAdvisor?: boolean;
  onCreate?: () => void;
  onClick?: (recipe: PhytoRecipe) => void;
  onMessage?: (snackBarMsg: SnackbarInterface) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const RecipesTable = (props: RecipesTableProps) => {
  const {
    isPhytoAdvisor = false,
    onCreate,
    onClick,
    onMessage,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: recipes, isFetching } = useFetch<PhytoRecipe[]>({
    queryKey: ["phytoRecipes", selectedABAccount?.agroBusiness?.id],
    enabled: !!selectedABAccount?.agroBusiness?.id,
    onError,
  });

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isConfirmRecipeRejectDialogOpen, setIsConfirmRecipeRejectDialogOpen] =
    useState<boolean>(false);

  const [changeRecipeStatusValues, setChangeRecipeStatusValues] =
    useState<ChangePhytoRecipeStatusInterface>({ id: null, status: null });

  const handleChangePhytoRecipeStatusResponse = (recipe: PhytoRecipe) => {
    setChangeRecipeStatusValues({ id: null, status: null });

    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytoRecipes"],
      type: "active",
    });

    let message: string;
    switch (recipe.status) {
      case PhytoRecipeStatus.DRAFT:
        message = i18n.t("recipes.changeStatusToDraftSuccess");
        break;
      case PhytoRecipeStatus.PRESCRIBED:
        message = i18n.t("recipes.changeStatusToPrescribed");
        break;
      case PhytoRecipeStatus.ACCEPTED:
        message = i18n.t("recipes.changeStatusToAccepted");
        break;
      case PhytoRecipeStatus.REFUSED:
        message = i18n.t("recipes.changeStatusToRejected");
        break;
      default:
        message = "";
        break;
    }
    onMessage && onMessage({ severity: "success", message });
  };

  const changePhytoRecipeStatusMutation = useCrud<PhytoRecipe>({
    key: "changePhytoRecipeStatus",
    onSuccess: handleChangePhytoRecipeStatusResponse,
    onError,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Advisor change status from DRAFT to PRESCRIBED
  const handleOnSend = (recipe: PhytoRecipe) => {
    if (recipe.id) {
      const statusChangeValues: ChangePhytoRecipeStatusInterface = {
        id: recipe.id,
        status: PhytoRecipeStatus.PRESCRIBED,
      };
      setChangeRecipeStatusValues(statusChangeValues);
      changePhytoRecipeStatusMutation.mutate(statusChangeValues);
    }
  };

  // User change status from PRESCRIBED to ACCEPTED (force view the recipe before)
  const handleOnAccept = (recipe: PhytoRecipe) => {
    if (recipe.id) {
      onClick && onClick(recipe);
    }
  };

  // User change status from PRESCRIBED to REJECTED
  const handleOnReject = (recipe: PhytoRecipe) => {
    if (recipe.id) {
      setChangeRecipeStatusValues({
        id: recipe.id,
        status: PhytoRecipeStatus.REFUSED,
      });
      setIsConfirmRecipeRejectDialogOpen(true);
    }
  };

  // User confirm reject the recipe
  const handleConfirmRejectRecipe = () => {
    changePhytoRecipeStatusMutation.mutate(changeRecipeStatusValues);
    setIsConfirmRecipeRejectDialogOpen(false);
  };

  // User start the treatment passing from ACCEPTED to APPLIED
  const handleOnApply = (recipe: PhytoRecipe) => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}`, {
      state: { recipeId: recipe.id, recipeType: recipe.type },
    });
  };

  const handleClickOnRecipe = (recipe: PhytoRecipe) => {
    onClick && onClick(recipe);
  };

  const visibleRecipes: PhytoRecipe[] = useMemo(() => {
    if (!recipes) return [];

    const filterRecipes = isPhytoAdvisor
      ? recipes
      : recipes.filter((recipe) => recipe.status !== PhytoRecipeStatus.DRAFT);
    return filterRecipes.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [recipes, page, rowsPerPage, isPhytoAdvisor]);

  if (visibleRecipes.length === 0)
    return (
      <EmptyTable
        icon={<Receipt fontSize="large" />}
        title={
          isPhytoAdvisor
            ? i18n.t("recipes.advisorEmptyTableTitle")
            : i18n.t("recipes.emptyTableTitle")
        }
        subtitle={
          isPhytoAdvisor
            ? i18n.t("recipes.advisorEmptyTableSubtitle")
            : undefined
        }
        btnText={
          isPhytoAdvisor
            ? i18n.t("recipes.advisorEmptyTableBtnText")
            : undefined
        }
        onClick={onCreate}
      />
    );

  return (
    <div className="briocard">
      <LoadingWithDelay isVisible={isFetching} />
      <div className="header-table">
        <div className="header-left">
          <h1>{i18n.t("recipes.title")}</h1>
        </div>
        <div className="toolbar-actions">
          {isPhytoAdvisor && (
            <Button
              variant="contained"
              startIcon={<Add style={{ color: "white" }} />}
              onClick={onCreate}
            >
              {i18n.t("words.create")}
            </Button>
          )}
        </div>
      </div>
      <div className="recipes-table">
        <table>
          <thead>
            <tr>
              <th>{i18n.t("recipes.column.dateColumnLabel")}</th>
              <th>{i18n.t("recipes.column.descriptionColumnLabel")}</th>
              <th>{i18n.t("recipes.column.cropColumnLabel")}</th>
              <th>{i18n.t("recipes.column.advisorColumnLabel")}</th>
              <th>{i18n.t("recipes.column.statusColumnLabel")}</th>
              <th>{i18n.t("recipes.column.actionsColumnLabel")}</th>
            </tr>
          </thead>
          <tbody>
            {visibleRecipes.map((recipe) => (
              <tr key={recipe.id} onClick={() => handleClickOnRecipe(recipe)}>
                <td>{recipe.dateFormatted}</td>
                <td>{recipe.description}</td>
                <td>{recipe.crop?.productName}</td>
                <td>{recipe.advisor?.entity?.nameAndSurname}</td>
                <td style={{ color: recipe.recipeStatus?.color }}>
                  {recipe.recipeStatus?.label}
                </td>
                <td>
                  <ButtonActions
                    status={recipe.status}
                    isPhytoAdvisor={isPhytoAdvisor}
                    isLoading={
                      changePhytoRecipeStatusMutation.isLoading &&
                      changeRecipeStatusValues.id === recipe.id
                    }
                    onSend={() => handleOnSend(recipe)}
                    onAccept={() => handleOnAccept(recipe)}
                    onReject={() => handleOnReject(recipe)}
                    onApply={() => handleOnApply(recipe)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="table-pagination">
          <TablePagination
            sx={{ border: "none" }}
            count={recipes?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={i18n.t("words.rowsByPage")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count}`
            }
            rowsPerPageOptions={[5, 10, 25]}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <FormAlertDialog
        id="confirmRejectRecipeAlertDialog"
        title={i18n.t("recipes.confirmRejectRecipeTitle")}
        contentText={i18n.t("recipes.confirmRejectRecipeDescription")}
        open={isConfirmRecipeRejectDialogOpen}
        onCancel={() => setIsConfirmRecipeRejectDialogOpen(false)}
        onConfirm={handleConfirmRejectRecipe}
      />
    </div>
  );
};

interface ButtonActionsProps {
  status?: PhytoRecipeStatus;
  isPhytoAdvisor?: boolean;
  isLoading?: boolean;
  onSend?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
  onApply?: () => void;
}
const ButtonActions = (props: ButtonActionsProps) => {
  const {
    status,
    isPhytoAdvisor = false,
    isLoading = false,
    onSend,
    onAccept,
    onReject,
    onApply,
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  if (!status) return null;

  const handleOnSend = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onSend && onSend();
  };

  const handleOnAccept = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onAccept && onAccept();
  };

  const handleOnReject = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onReject && onReject();
  };

  const handleOnApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onApply && onApply();
  };

  let btns: React.ReactNode;

  if (isPhytoAdvisor && status === PhytoRecipeStatus.DRAFT) {
    btns = (
      <>
        <Tooltip title={i18n.t("words.send")}>
          <LoadingButton
            variant="contained"
            color="info"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleOnSend}
          >
            {isSmallScreen ? <Send /> : i18n.t("words.send")}
          </LoadingButton>
        </Tooltip>
      </>
    );
  } else if (!isPhytoAdvisor) {
    switch (status) {
      case PhytoRecipeStatus.PRESCRIBED:
        btns = (
          <>
            <Tooltip title={i18n.t("words.accept")}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleOnAccept}
              >
                {isSmallScreen ? <Done /> : i18n.t("words.accept")}
              </LoadingButton>
            </Tooltip>
            <Tooltip title={i18n.t("words.reject")}>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                color="error"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleOnReject}
              >
                {isSmallScreen ? <Close /> : i18n.t("words.reject")}
              </LoadingButton>
            </Tooltip>
          </>
        );
        break;
      case PhytoRecipeStatus.ACCEPTED:
        btns = (
          <>
            <Tooltip title={i18n.t("words.apply")}>
              <LoadingButton
                variant="contained"
                color="info"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleOnApply}
              >
                {isSmallScreen ? <DoneAll /> : i18n.t("words.apply")}
              </LoadingButton>
            </Tooltip>
            <Tooltip title={i18n.t("words.reject")}>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                color="error"
                loading={isLoading}
                disabled={isLoading}
                onClick={handleOnReject}
              >
                {isSmallScreen ? <Close /> : i18n.t("words.reject")}
              </LoadingButton>
            </Tooltip>
          </>
        );
        break;

      default:
        return null;
    }
  }

  return <div className="action-btns-container">{btns}</div>;
};

interface RecipeViewProps {
  isOpen: boolean;
  selected: PhytoRecipe | null;
  isPhytoAdvisor?: boolean;
  onClose?: () => void;
  onMessage?: (snackBarMsg: SnackbarInterface) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const RecipeView = (props: RecipeViewProps) => {
  const {
    isOpen,
    selected,
    isPhytoAdvisor = false,
    onMessage,
    onClose,
    onError,
  } = props;

  const validateForm = (values: PhytoRecipe) => {
    const errors: any = {};
    if (!values?.dose || values?.dose <= 0)
      errors.dose = i18n.t("formErrors.requiredField");
    if (!values?.doseUnit) errors.doseUnit = i18n.t("formErrors.requiredField");
    if (!values?.plantProblems || values?.plantProblems.length === 0)
      errors.plantProblems = i18n.t("formErrors.requiredField");
    if (!values?.product && !values?.registeredProduct)
      errors.product = i18n.t("formErrors.requiredField");
    if (!values?.description || values?.description?.length === 0)
      errors.description = i18n.t("formErrors.requiredField");
    if (values?.recipeType?.code === PhytoRecipeType.CROP && !values?.crop)
      errors.crop = i18n.t("formErrors.requiredField");
    return errors;
  };

  const { recipeId, productId } = useParams<{
    recipeId: string;
    productId: string;
  }>();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedProduct = location.state?.product
    ? (location.state?.product as RegisteredPhytosanitaryProduct)
    : null;

  const handleOnSuccess = (data: PhytoRecipe) => {
    formik.setValues(data);
  };

  const onFetchCropSuccess = (data: Crop) => {
    phytoRecipe?.setCrop(data);
    formik.setFieldValue("crop", data);
  };

  const { data: phytoRecipe, isFetching } = useFetch<PhytoRecipe>({
    queryKey: ["phytoRecipeById", selected?.id],
    selected,
    enabled: !!selected?.id,
    onSuccess: handleOnSuccess,
    onError,
  });

  const { isFetching: isFetchingSelectedCrop } = useFetch<Crop>({
    queryKey: ["crop", selected?.crop?.id],
    selected: selected?.crop,
    enabled: !!selected?.crop?.id,
    onSuccess: onFetchCropSuccess,
    onError,
  });

  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [isConfirmRecipeRejectDialogOpen, setIsConfirmRecipeRejectDialogOpen] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [changeRecipeStatusValues, setChangeRecipeStatusValues] =
    useState<ChangePhytoRecipeStatusInterface>({ id: null, status: null });
  const [isProductDetailOpen, setIsProductDetailOpen] =
    useState<boolean>(false);
  const [
    isOpenConfirmWithConflictsDialog,
    setIsOpenConfirmWithConflictsDialog,
  ] = useState<boolean>(false);
  const [plantProblemConflicts, setPlantProblemConflicts] =
    useState<boolean>(false);

  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const openConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(true);
  const cancelConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(false);

  const handleSubmit = (values: PhytoRecipe) => {
    selected
      ? phytoRecipeEditMutation.mutate(values)
      : phytoRecipeCreateMutation.mutate(values);
  };

  const formik = useFormik<PhytoRecipe>({
    initialValues: new PhytoRecipe(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    setIsProductDetailOpen(
      location.pathname.includes(`/${recipeId}/${productId}`) &&
        productId !== "0"
    );
    if (recipeId === "0") formik.resetForm();
  }, [recipeId, productId]);

  useEffect(() => {
    setIsViewMode(!!selected);
    formik.setValues(selected || new PhytoRecipe());
  }, [selected]);

  // Set selected product from vademecum if case
  useEffect(() => {
    if (selectedProduct !== null)
      formik.setFieldValue("registeredProduct", selectedProduct);
  }, [selectedProduct]);

  const phytoRecipeCreateMutation = useCrud<PhytoRecipe>({
    key: "postPhytoRecipe",
    values: formik.values,
    onSuccess: (data: PhytoRecipe) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["phytoRecipes"],
        type: "active",
      });
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.createSuccess"),
        });
      onClose && onClose();
    },
    onError,
  });

  const phytoRecipeEditMutation = useCrud<PhytoRecipe>({
    key: "putPhytoRecipe",
    values: formik.values,
    onSuccess: (data: PhytoRecipe) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["phytoRecipes"],
        type: "active",
      });
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.updateSuccess"),
        });
      onClose && onClose();
    },
    onError,
  });

  const phytoRecipeDeleteMutation = useCrud({
    key: "deletePhytoRecipe",
    values: phytoRecipe,
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["phytoRecipes"],
        type: "active",
      });
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.deleteSuccess"),
        });
      cancelConfirmDeleteAlertDialog();
      onClose && onClose();
    },
    onError,
  });

  const handleChangePhytoRecipeStatusResponse = (recipe: PhytoRecipe) => {
    setChangeRecipeStatusValues({ id: null, status: null });

    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytoRecipes"],
      type: "active",
    });

    switch (recipe.status) {
      case PhytoRecipeStatus.DRAFT:
        setIsViewMode(false);
        break;
      case PhytoRecipeStatus.ACCEPTED:
        onMessage &&
          onMessage({
            severity: "success",
            message: i18n.t("recipes.changeStatusToAccepted"),
          });
        onClose && onClose();
        break;
      case PhytoRecipeStatus.REFUSED:
        onMessage &&
          onMessage({
            severity: "success",
            message: i18n.t("recipes.changeStatusToRejected"),
          });
        onClose && onClose();
        break;
      default:
        break;
    }
  };

  const changePhytoRecipeStatusMutation = useCrud<PhytoRecipe>({
    key: "changePhytoRecipeStatus",
    onSuccess: handleChangePhytoRecipeStatusResponse,
    onError,
  });

  const handleOnEdit = () => {
    if (phytoRecipe?.id) {
      if (phytoRecipe?.status === PhytoRecipeStatus.REFUSED) {
        // Advisor change status from REFUSED to DRAFT
        const statusChangeValues: ChangePhytoRecipeStatusInterface = {
          id: phytoRecipe.id,
          status: PhytoRecipeStatus.DRAFT,
        };
        setChangeRecipeStatusValues(statusChangeValues);
        changePhytoRecipeStatusMutation.mutate(statusChangeValues);
      } else setIsViewMode(false);
    }
  };

  const handleOnSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0 && onMessage) {
      onMessage({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else if (plantProblemConflicts) openConfirmWithConflictsAlertDialog();
    else formik.submitForm();
  };

  const handleConfirmWithConflictsAlertDialog = () => {
    cancelConfirmWithConflictsAlertDialog();
    formik.submitForm();
  };

  // User change status from PRESCRIBED to ACCEPTED
  const handleOnAccept = () => {
    if (phytoRecipe?.id) {
      const statusChangeValues: ChangePhytoRecipeStatusInterface = {
        id: phytoRecipe.id,
        status: PhytoRecipeStatus.ACCEPTED,
      };
      setChangeRecipeStatusValues(statusChangeValues);
      changePhytoRecipeStatusMutation.mutate(statusChangeValues);
    }
  };

  // User change status from PRESCRIBED to REJECTED
  const handleOnReject = () => {
    if (phytoRecipe?.id) {
      setChangeRecipeStatusValues({
        id: phytoRecipe.id,
        status: PhytoRecipeStatus.REFUSED,
      });
      setIsConfirmRecipeRejectDialogOpen(true);
    }
  };

  // User confirm reject the recipe
  const handleConfirmRejectRecipe = () => {
    changePhytoRecipeStatusMutation.mutate(changeRecipeStatusValues);
    setIsConfirmRecipeRejectDialogOpen(false);
  };

  const handleOnDelete = () => {
    openConfirmDeleteAlertDialog();
  };

  const handleConfirmDeleteAlertDialog = () => {
    phytoRecipeDeleteMutation.mutate(phytoRecipe);
  };

  // User start the treatment passing from ACCEPTED to APPLIED
  const handleOnApply = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}`, {
      state: { recipeId: phytoRecipe?.id, recipeType: phytoRecipe?.type },
    });
  };

  const handleOnCloseProductDetail = () => {
    navigate(-1);
  };

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="recipes-view"
      PaperProps={{ className: "recipes-view" }}
    >
      <div className="container">
        <div className="header">
          <IconButton onClick={onClose}>
            <ArrowBack fontSize="large" color="action" />
          </IconButton>
          <div className="header-titles">
            <h1>
              {formik.values.description ||
                (selected
                  ? i18n.t("recipes.recipeViewTitle")
                  : i18n.t("recipes.recipeViewCreateTitle"))}
            </h1>
            <h2>
              {phytoRecipe?.advisor?.entity?.nameAndSurname ||
                moment().format("DD MMMM YYYY")}
            </h2>
          </div>
          <div className="header-actions">
            <RecipeViewButtonActions
              status={phytoRecipe?.status}
              isPhytoAdvisor={isPhytoAdvisor}
              isDeletable={
                phytoRecipe?.status === PhytoRecipeStatus.DRAFT &&
                phytoRecipe?.statusHistory?.length === 1
              }
              viewMode={isViewMode}
              isLoading={
                changePhytoRecipeStatusMutation.isLoading
                  ? changeRecipeStatusValues.status
                  : null
              }
              onEdit={handleOnEdit}
              onSave={handleOnSave}
              onAccept={handleOnAccept}
              onReject={handleOnReject}
              onApply={handleOnApply}
              onDelete={handleOnDelete}
            />
          </div>
        </div>

        {isFetching || isFetchingSelectedCrop ? (
          <LoadingWithDelay isVisible />
        ) : isViewMode && phytoRecipe ? (
          <RecipeViewMode recipe={phytoRecipe} />
        ) : (
          <RecipeEditMode
            formik={formik}
            onConflicts={setPlantProblemConflicts}
            onError={onError}
          />
        )}

        <FormAlertDialog
          id="confirmRejectRecipeAlertDialogRecipeView"
          title={i18n.t("recipes.confirmRejectRecipeTitle")}
          contentText={i18n.t("recipes.confirmRejectRecipeDescription")}
          open={isConfirmRecipeRejectDialogOpen}
          onCancel={() => setIsConfirmRecipeRejectDialogOpen(false)}
          onConfirm={handleConfirmRejectRecipe}
        />
        <FormAlertDialog
          id="confirmDeleteAlertDialog"
          title={i18n.t("recipes.confirmDeleteAlertDialogTitle")}
          contentText={i18n.t("recipes.confirmDeleteAlertDialogDescription")}
          open={isOpenConfirmDeleteAlertDialog}
          formAction={FormMode.DELETE}
          isLoading={phytoRecipeDeleteMutation.isLoading}
          onCancel={cancelConfirmDeleteAlertDialog}
          onConfirm={handleConfirmDeleteAlertDialog}
        />
        <FormAlertDialog
          id="confirmRecipeWithConflictsAlertDialog"
          title={i18n.t("recipes.confirmWithConflictsAlertDialogTitle")}
          contentText={i18n.t(
            "recipes.confirmWithConflictsAlertDialogDescription"
          )}
          confirmBtnText={i18n.t(
            "recipes.confirmWithConflictsAlertDialogConfirmBtnText"
          )}
          open={isOpenConfirmWithConflictsDialog}
          formAction={FormMode.DELETE}
          onCancel={cancelConfirmWithConflictsAlertDialog}
          onConfirm={handleConfirmWithConflictsAlertDialog}
        />
        <ProductDetailView
          isOpen={isProductDetailOpen}
          product={
            phytoRecipe?.registeredProduct ||
            phytoRecipe?.product?.registeredPhytosanitaryProduct ||
            phytoRecipe?.product
          }
          onClose={handleOnCloseProductDetail}
        />
      </div>
    </Drawer>
  );
};

interface RecipeViewButtonActionsProps {
  status?: PhytoRecipeStatus;
  isDeletable?: boolean;
  isPhytoAdvisor?: boolean;
  viewMode?: boolean;
  isLoading?: PhytoRecipeStatus | null;
  onEdit?: () => void;
  onSave?: () => void;
  onAccept?: () => void;
  onReject?: () => void;
  onApply?: () => void;
  onDelete?: () => void;
}
const RecipeViewButtonActions = (props: RecipeViewButtonActionsProps) => {
  const {
    status,
    isDeletable = false,
    isPhytoAdvisor = false,
    viewMode = true,
    isLoading = null,
    onEdit,
    onSave,
    onAccept,
    onReject,
    onApply,
    onDelete,
  } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  let btns: React.ReactNode;

  if (!isPhytoAdvisor) {
    switch (status) {
      case PhytoRecipeStatus.PRESCRIBED:
        btns = (
          <>
            <Tooltip title={i18n.t("words.accept")}>
              <LoadingButton
                variant="contained"
                color="primary"
                loading={isLoading === PhytoRecipeStatus.ACCEPTED}
                disabled={!!isLoading}
                onClick={onAccept}
              >
                {isSmallScreen ? <Done /> : i18n.t("words.accept")}
              </LoadingButton>
            </Tooltip>
            <Tooltip title={i18n.t("words.reject")}>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                color="error"
                loading={isLoading === PhytoRecipeStatus.REFUSED}
                disabled={!!isLoading}
                onClick={onReject}
              >
                {isSmallScreen ? <Close /> : i18n.t("words.reject")}
              </LoadingButton>
            </Tooltip>
          </>
        );
        break;
      case PhytoRecipeStatus.ACCEPTED:
        btns = (
          <>
            <Tooltip title={i18n.t("words.apply")}>
              <LoadingButton
                variant="contained"
                color="info"
                loading={isLoading === PhytoRecipeStatus.APPLIED}
                disabled={!!isLoading}
                onClick={onApply}
              >
                {isSmallScreen ? <DoneAll /> : i18n.t("words.apply")}
              </LoadingButton>
            </Tooltip>
            <Tooltip title={i18n.t("words.reject")}>
              <LoadingButton
                sx={{ ml: 1 }}
                variant="contained"
                color="error"
                loading={isLoading === PhytoRecipeStatus.REFUSED}
                disabled={!!isLoading}
                onClick={onReject}
              >
                {isSmallScreen ? <Close /> : i18n.t("words.reject")}
              </LoadingButton>
            </Tooltip>
          </>
        );
        break;

      default:
        return null;
    }
  } else {
    if (!status)
      btns = (
        <Tooltip title={i18n.t("words.create")}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={!!isLoading}
            disabled={!!isLoading}
            onClick={onSave}
          >
            {i18n.t("words.create")}
          </LoadingButton>
        </Tooltip>
      );
    else if (
      [PhytoRecipeStatus.DRAFT, PhytoRecipeStatus.REFUSED].includes(status)
    ) {
      btns = (
        <>
          {viewMode && isDeletable && (
            <Tooltip title={i18n.t("words.delete")}>
              <LoadingButton
                variant="contained"
                color="error"
                sx={{ mr: 1 }}
                startIcon={
                  !isSmallScreen && <Delete style={{ color: "white" }} />
                }
                loading={!!isLoading}
                disabled={!!isLoading}
                onClick={onDelete}
              >
                {isSmallScreen ? (
                  <Delete style={{ color: "white" }} />
                ) : (
                  i18n.t("words.delete")
                )}
              </LoadingButton>
            </Tooltip>
          )}
          {viewMode ? (
            <Tooltip title={i18n.t("words.edit")}>
              <LoadingButton
                variant="contained"
                color="info"
                startIcon={
                  !isSmallScreen && <Edit style={{ color: "white" }} />
                }
                loading={!!isLoading}
                disabled={!!isLoading}
                onClick={onEdit}
              >
                {isSmallScreen ? (
                  <Edit style={{ color: "white" }} />
                ) : (
                  i18n.t("words.edit")
                )}
              </LoadingButton>
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t("words.save")}>
              <LoadingButton
                variant="contained"
                color="info"
                startIcon={
                  !isSmallScreen && <Save style={{ color: "white" }} />
                }
                loading={!!isLoading}
                disabled={!!isLoading}
                onClick={onSave}
              >
                {isSmallScreen ? (
                  <Save style={{ color: "white" }} />
                ) : (
                  i18n.t("words.save")
                )}
              </LoadingButton>
            </Tooltip>
          )}
        </>
      );
    }
  }

  return <div className="action-btns-container">{btns}</div>;
};

const RecipeViewMode = ({ recipe }: { recipe: PhytoRecipe }) => {
  return (
    <div className="content-view">
      <DetailsSectionViewMode recipe={recipe} />
      <PlantProblemsSectionViewMode recipe={recipe} />
      <TreatmentJustificationSectionViewMode recipe={recipe} />
      <DatePeriodSectionViewMode recipe={recipe} />
      <StatusHistoryViewMode recipe={recipe} />
    </div>
  );
};

const DetailsSectionViewMode = ({ recipe }: { recipe: PhytoRecipe }) => {
  const location = useLocation();

  return (
    <>
      <div className="section-header">
        <h3>{i18n.t("recipes.detailsSectionTitle")}</h3>
        <p style={{ color: recipe.recipeStatus?.color || "black" }}>
          {recipe.recipeStatus?.label}
        </p>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          {recipe.type === PhytoRecipeType.FACILITY ? (
            <Business className="icon" />
          ) : (
            <Grass className="icon" />
          )}
          <div className="content-row-item-value">
            <h4>{i18n.t("recipes.recipeDestinationLabel")}</h4>
            <p>
              {recipe.type === PhytoRecipeType.CROP
                ? recipe.crop?.productName
                : recipe.recipeType?.name}
            </p>
          </div>
        </div>
        <div className="content-row-item">
          <Science className="icon" />
          <div className="content-row-item-value">
            <h4>
              {i18n.t("recipes.recipeProductLabel")}
              <NavLink to={`${location.pathname}/${recipe.product?.id}`}>
                {i18n.t("recipes.recipeProductLinkLabel")}
              </NavLink>
            </h4>
            <p>{recipe.product?.name}</p>
          </div>
        </div>
        <div className="content-row-item">
          <Sanitizer className="icon" />
          <div className="content-row-item-value">
            <h4>{i18n.t("recipes.recipeDosageLabel")}</h4>
            <p>{`${recipe.dose}${recipe.doseUnit?.name}`}</p>
          </div>
        </div>
      </div>
      {recipe.observations && (
        <div className="content-row">
          <div className="content-row-item-full-width">
            <h4>{i18n.t("recipes.recipeObservationsLabel")}</h4>
            <p className="large-text">{recipe.observations}</p>
          </div>
        </div>
      )}
    </>
  );
};

const PlantProblemsSectionViewMode = ({ recipe }: { recipe: PhytoRecipe }) => {
  const plantProblemsByType = useMemo(() => {
    const problemsByType = new Map<string, PlantProblem[]>();
    recipe.plantProblems?.forEach((problem) => {
      const type = problem.problemType?.name || "";
      if (problemsByType.has(type)) {
        problemsByType.get(type)?.push(problem);
      } else {
        problemsByType.set(type, [problem]);
      }
    });
    return problemsByType;
  }, [recipe.plantProblems]);

  return (
    <>
      <div className="section-header">
        <h3>{i18n.t("recipes.plantProblemsSectionTitle")}</h3>
      </div>
      <div className="content-row">
        {Array.from(plantProblemsByType.keys()).map((type) => {
          // Get plant problems names separated by commas
          const plantProblems = plantProblemsByType
            .get(type)
            ?.map((problem) => problem.name)
            .join(", ");

          return (
            <div key={type} className="content-row-item-full-width">
              <h4>{type}</h4>
              <p className="large-text">{plantProblems}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

const TreatmentJustificationSectionViewMode = ({
  recipe,
}: {
  recipe: PhytoRecipe;
}) => {
  if (recipe.justifications?.length === 0) return null;

  const justifications = recipe.justifications
    ?.map((justification) => justification.name)
    .join(", ");

  return (
    <>
      <div className="section-header">
        <h3>{i18n.t("recipes.treatmentJustificationSectionTitle")}</h3>
      </div>
      <div className="content-row">
        <div className="content-row-item-full-width">
          <p className="large-text">{justifications}</p>
        </div>
      </div>
    </>
  );
};

const DatePeriodSectionViewMode = ({ recipe }: { recipe: PhytoRecipe }) => {
  const [progressValue, setProgressValue] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      const percentage = recipe.recipePeriodPercentage || 0;
      if (percentage < 0) setProgressValue(0);
      else if (percentage > 100) setProgressValue(100);
      else setProgressValue(percentage);
    }, 200);
  }, [recipe.recipePeriodPercentage]);

  return (
    <>
      <div className="section-header">
        <h3>{i18n.t("recipes.statusSectionTitle")}</h3>
        <p style={{ color: recipe.recipePeriodStatus?.color }}>
          {recipe.recipePeriodStatus?.label}
        </p>
      </div>
      <LinearProgress
        sx={{ width: "100%", borderRadius: 5, height: "8px" }}
        variant="determinate"
        color={
          (recipe.recipePeriodPercentage || 0) >= 0 &&
          (recipe.recipePeriodPercentage || 0) <= 100
            ? "primary"
            : "error"
        }
        value={progressValue}
      />
      <div className="content-row">
        <div className="content-row-item">
          <p className="large-text">{recipe.startRecipeDateFormattedLong}</p>
        </div>
        <div className="content-row-item">
          <p className="large-text">{recipe.endRecipeDateFormattedLong}</p>
        </div>
      </div>
    </>
  );
};

const StatusHistoryViewMode = ({ recipe }: { recipe: PhytoRecipe }) => {
  return (
    <>
      <div className="section-header">
        <h3>{i18n.t("recipes.recipeStatusHistorySectionTitle")}</h3>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t("recipes.recipeStatusHistoryStatusColumnLabel")}
                </th>
                <th>{i18n.t("recipes.recipeStatusHistoryDateColumnLabel")}</th>
                <th>
                  {i18n.t("recipes.recipeStatusHistoryAdvisorColumnLabel")}
                </th>
              </tr>
            </thead>
            <tbody>
              {recipe.statusHistory
                ?.sort(
                  (a: PhytoRecipeStatusHistory, b: PhytoRecipeStatusHistory) =>
                    (b.startTimestamp || 0) - (a.startTimestamp || 0)
                )
                ?.map((statusHistory) => (
                  <tr key={statusHistory.id}>
                    <td>{statusHistory.recipeStatus?.label}</td>
                    <td>{statusHistory.startDateFormatted}</td>
                    <td>{statusHistory.user?.email}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

interface RecipeFormEditProps {
  formik: FormikProps<PhytoRecipe>;
  onConflicts?: (conflicts: boolean) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const RecipeEditMode = (props: RecipeFormEditProps) => {
  return (
    <>
      <RecipeDescriptionForm {...props} />
      <RecipeProductForm {...props} />
      <RecipeTreatmentForm {...props} />
    </>
  );
};

const RecipeDescriptionForm = (props: RecipeFormEditProps) => {
  const { formik, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const handleCropsResponse = (crops: Crop[]) => {
    if (crops.length === 1) {
      formik.setFieldValue("crop", crops[0]);
    }
  };

  const { data: crops, isFetching } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onSuccess: handleCropsResponse,
    onError,
  });

  const handleChangeCrop = (crop: Crop) => {
    formik.setFieldValue("crop", crop);
  };

  const handleClickNewOption = () =>
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);

  return (
    <BrioCard
      title={i18n.t("recipes.recipeForm.recipeDescriptionTitle")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl className="form-control" variant="outlined">
          <FormikTextField
            formik={formik}
            className="form-input"
            name="description"
            label={i18n.t("recipes.recipeForm.recipeDescriptionLabel")}
            placeholder={i18n.t(
              "recipes.recipeForm.recipeDescriptionPlaceholder"
            )}
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ShortText />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl className="form-control" variant="outlined">
          <FormikSelect
            className="form-input"
            formik={formik}
            name="recipeType"
            label={i18n.t("recipes.recipeForm.recipeTypeLabel")}
            required
            options={PHYTO_RECIPE_TYPES}
            optionLabelFieldName="name"
          />
        </FormControl>
        <Collapse in={formik.values.recipeType?.code === PhytoRecipeType.CROP}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              {!isFetching && crops?.length === 0 && (
                <AlertEmptyList onClick={handleClickNewOption}>
                  {i18n.t("recipes.recipeForm.emptyCrops")}
                </AlertEmptyList>
              )}
              <FormikAutocomplete
                formik={formik}
                name="crop"
                label={i18n.t("recipes.recipeForm.recipeCropLabel")}
                options={crops || []}
                optionLabelFieldName="productNameWithVarieties"
                required
                startIcon={Grass}
                loading={isFetching}
                addNewOption
                onClickNewOption={handleClickNewOption}
                onChange={handleChangeCrop}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const RecipeProductForm = (props: RecipeFormEditProps) => {
  const { formik, onConflicts, onError } = props;

  const handleOnChangeProduct = (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  ) => {
    if (!product || product instanceof RegisteredPhytosanitaryProduct) {
      formik.setFieldValue("registeredProduct", product);
      formik.setFieldValue("product", null);
    } else if (product.registeredPhytosanitaryProduct) {
      formik.setFieldValue(
        "registeredProduct",
        product.registeredPhytosanitaryProduct
      );
      formik.setFieldValue("product", null);
    } else {
      formik.setFieldValue("product", product);
      formik.setFieldValue("registeredProduct", null);
    }
  };

  const handleOnChangePlantProblems = (plantProblems: PlantProblem[]) => {
    formik.setFieldValue("plantProblems", plantProblems);
  };

  const handleOnRemovePlantProblem = (plantProblem: PlantProblem) => {
    const plantProblems = formik.values.plantProblems?.filter(
      (problem) => problem.id !== plantProblem.id
    );
    formik.setFieldValue("plantProblems", plantProblems);
  };

  return (
    <BrioCard
      title={i18n.t("recipes.recipeForm.recipeProductFormTitle")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <ProductInput
          selectedCrop={formik.values.crop}
          selectedPlantProblems={formik.values.plantProblems}
          value={
            formik.values.registeredProduct ||
            formik.values.product?.registeredPhytosanitaryProduct ||
            formik.values.product
          }
          inputError={
            (formik.touched.product && !!formik.errors.product) ||
            (formik.touched.registeredProduct &&
              !!formik.errors.registeredProduct)
          }
          onChange={handleOnChangeProduct}
          onError={onError}
        />
        <PlantProblemsInput
          selectedCrop={formik.values.crop}
          selectedProduct={
            formik.values.registeredProduct ||
            formik.values.product?.registeredPhytosanitaryProduct ||
            formik.values.product
          }
          value={formik.values.plantProblems}
          inputError={
            formik.touched.plantProblems && !!formik.errors.plantProblems
          }
          onChange={handleOnChangePlantProblems}
          onError={onError}
        />
      </FormGroup>
      <PlantProblemList
        plantProblems={formik.values?.plantProblems || []}
        selectedCrop={formik.values?.crop}
        selectedProduct={
          formik.values.registeredProduct ||
          formik.values.product?.registeredPhytosanitaryProduct ||
          formik.values.product
        }
        onConflicts={onConflicts}
        onRemove={handleOnRemovePlantProblem}
        onError={onError}
      />
    </BrioCard>
  );
};

interface ProductInputProps {
  value?: RegisteredPhytosanitaryProduct;
  selectedCrop?: Crop;
  selectedPlantProblems?: PlantProblem[];
  inputError?: boolean;
  disabled?: boolean;
  onChange?: (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  ) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
let productsSearchWordTimeout: NodeJS.Timeout | null;
export const ProductInput = (props: ProductInputProps) => {
  const {
    value,
    selectedCrop,
    selectedPlantProblems = [],
    onChange,
    inputError = false,
    disabled = false,
    onError,
  } = props;

  const autocompleteMenuRef = useRef<HTMLDivElement>(null);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const { selectedABAccount, selectedABPartition } = useSession();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [productFilterSearchWord, setProductFilterSearchWord] =
    useState<string>("");
  const [productNameValue, setProductNameValue] = useState<string>("");
  const [siexProductIdFilter, setSiexProductIdFilter] = useState<number | null>(
    null
  );
  const [plantProblemsIdsFilter, setPlantProblemsIdsFilter] = useState<
    number[]
  >([]);
  const [isInventoryFilterChecked, setIsInventoryFilterChecked] =
    useState<boolean>(false);
  const [isOpenProductDetails, setIsOpenProductDetails] =
    useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<
    RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  >(null);
  const [productDetails, setProductDetails] =
    useState<PhytosanitaryProduct | null>(null);

  const { data: products, isFetching: isFetchingProducts } = useFetch<
    RegisteredPhytosanitaryProduct[]
  >({
    queryKey: [
      "phytoRecipeProducts",
      selectedABAccount?.context?.id,
      productFilterSearchWord,
      siexProductIdFilter,
      plantProblemsIdsFilter,
    ],
    selected: {
      nameContains: productFilterSearchWord,
      siexProductId: siexProductIdFilter,
      plantProblemIds: plantProblemsIdsFilter,
    },
    enabled:
      !!selectedABAccount?.context?.id &&
      (productFilterSearchWord.length > 0 ||
        siexProductIdFilter !== null ||
        plantProblemsIdsFilter.length > 0),
    onError,
  });

  const { data: inventoryProducts, isFetching: isFetchingInventoryProducts } =
    useFetch<PhytosanitaryProduct[]>({
      queryKey: [
        "phytosanitaryProducts",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
    });

  const openProductDetails = (
    product: PhytosanitaryProduct | RegisteredPhytosanitaryProduct
  ) => {
    setProductDetails(
      product instanceof PhytosanitaryProduct
        ? product
        : new PhytosanitaryProduct().mapToClass({
            registeredPhytosanitaryProduct: product,
          }) || null
    );
    setIsOpenProductDetails(true);
  };
  const closeProductDetails = () => {
    setIsOpenProductDetails(false);
    setSelectedProduct(null);
  };

  // Fetch phytosanitaryProductById for inventory products
  const { isFetching: isFetchingInventoryProduct } =
    useFetch<PhytosanitaryProduct>({
      queryKey: ["phytosanitaryProduct", selectedProduct?.id],
      enabled:
        !!selectedProduct && selectedProduct instanceof PhytosanitaryProduct,
      selected: { id: selectedProduct?.id }, // The endpoint only need the id
      onSuccess: openProductDetails,
      onError,
    });

  // Fetch vademecum productById for registered products
  const { isFetching: isFetchingVadeProduct } =
    useFetch<RegisteredPhytosanitaryProduct>({
      queryKey: ["registeredPhytoProductById", selectedProduct?.id],
      enabled:
        !!selectedProduct &&
        selectedProduct instanceof RegisteredPhytosanitaryProduct,
      selected: selectedProduct,
      onSuccess: openProductDetails,
      onError,
    });

  const plantProblemsIds = useMemo(
    () => selectedPlantProblems?.map((pp) => pp?.id || 0) || [],
    [selectedPlantProblems]
  );

  // Filter not registered products and registered products according to the filters
  const filteredInventoryProducts = useMemo(() => {
    return (
      inventoryProducts?.filter(
        (phytosanitaryProduct) =>
          phytosanitaryProduct.type?.code !== "1" ||
          (siexProductIdFilter === null &&
            plantProblemsIdsFilter.length === 0) ||
          !!products?.find(
            (p) =>
              p.id === phytosanitaryProduct?.registeredPhytosanitaryProduct?.id
          )
      ) || []
    );
  }, [inventoryProducts, siexProductIdFilter, plantProblemsIdsFilter]);

  useEffect(() => {
    if (!value) {
      setSiexProductIdFilter(selectedCrop?.product?.id || null);
      setPlantProblemsIdsFilter(plantProblemsIds);
    }
    setProductNameValue(value?.name || "");
  }, [value]);

  useEffect(() => {
    if (!isOpen) {
      //onChange && onChange(value || null);
      setProductNameValue(value?.name || "");
    }
  }, [isOpen]);

  // Needed to close the form input menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !isOpenProductDetails &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node) &&
        autocompleteMenuRef.current &&
        !autocompleteMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenProductDetails]);

  const handleChangeCropFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSiexProductIdFilter((state) =>
      state || !selectedCrop?.product?.id ? null : selectedCrop.product.id
    );
  };

  const handleChangeInventoryFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsInventoryFilterChecked((state) => !state);
  };

  const handleChangePlantProblemsFilter = () => {
    setPlantProblemsIdsFilter((state) =>
      state.length > 0 ? [] : plantProblemsIds
    );
  };

  const handleChangeProductSearchWord = (event: any, v: string) => {
    setProductNameValue(v);

    // Open the popup if the user is typing and avoid open after select a product
    if (!isOpen && value?.name !== v.trim()) setIsOpen(true);

    // Unselect product if clear the input
    if (v === "") {
      onChange && onChange(null);
    }

    // Launch search after a delay from the last keydown event
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = v.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      setProductFilterSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleOnChangeProduct = (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct
  ) => {
    onChange && onChange(product);
    setIsOpen(false);
  };

  const PopupMenu = ({ children }: { children?: React.ReactNode }) => {
    const emptyFilters = useMemo(
      () =>
        productFilterSearchWord.length === 0 &&
        !isInventoryFilterChecked &&
        !siexProductIdFilter &&
        plantProblemsIdsFilter.length === 0,
      [
        productFilterSearchWord,
        isInventoryFilterChecked,
        siexProductIdFilter,
        plantProblemsIdsFilter,
      ]
    );

    const noResults = useMemo(
      () =>
        (!isFetchingInventoryProducts &&
          isInventoryFilterChecked &&
          filteredInventoryProducts.length === 0) ||
        (!isFetchingProducts &&
          !isInventoryFilterChecked &&
          (!products || products.length === 0)),
      [
        isInventoryFilterChecked,
        isFetchingProducts,
        isFetchingInventoryProducts,
        filteredInventoryProducts,
        products,
      ]
    );

    return (
      <div ref={autocompleteMenuRef} className="recipes-product-popup">
        {selectedCrop?.id && (
          <ListItem className="filter-item" onClick={handleChangeCropFilter}>
            <Checkbox
              sx={productMenuCheckboxStyle}
              checked={siexProductIdFilter !== null}
            />
            <Typography noWrap sx={productMenuFilterLabelStyle}>
              {`${i18n.t("recipes.recipeForm.recipeProductCropFilterLabel")} ${
                selectedCrop?.productName
              }`}
            </Typography>
          </ListItem>
        )}
        {plantProblemsIds.length > 0 && (
          <ListItem
            className="filter-item"
            onClick={handleChangePlantProblemsFilter}
          >
            <Checkbox
              sx={productMenuCheckboxStyle}
              checked={plantProblemsIdsFilter.length > 0}
            />
            <Typography noWrap sx={productMenuFilterLabelStyle}>
              {i18n.t(
                "recipes.recipeForm.recipeProductPlantProblemsFilterLabel"
              )}
            </Typography>
          </ListItem>
        )}
        {filteredInventoryProducts.length > 0 && (
          <ListItem
            className="filter-item"
            onClick={handleChangeInventoryFilter}
          >
            <Checkbox
              sx={productMenuCheckboxStyle}
              checked={isInventoryFilterChecked}
            />
            <Typography noWrap sx={productMenuFilterLabelStyle}>
              {i18n.t("recipes.recipeForm.recipeProductInventoryFilterLabel")}
            </Typography>
          </ListItem>
        )}

        {isFetchingProducts || isFetchingInventoryProducts ? (
          <LinearProgress />
        ) : (
          <Divider />
        )}

        {emptyFilters && noResults && (
          <p className="not-results-text">
            {i18n.t("recipes.recipeForm.recipeProductSearchNameRequired")}
          </p>
        )}

        {!emptyFilters && noResults && (
          <p className="not-results-text">
            {i18n.t("recipes.recipeForm.recipeProductsNotFoundLabel")}
          </p>
        )}

        {children}
      </div>
    );
  };

  const ProductDetailsMemo = useMemo(
    () => (
      <ProductDetails
        open={isOpenProductDetails}
        product={productDetails}
        onClose={closeProductDetails}
      />
    ),
    [isOpenProductDetails, productDetails]
  );

  return (
    <FormControl variant="outlined" className="form-control">
      <Autocomplete
        ref={autocompleteRef}
        className="form-input"
        open={isOpen}
        freeSolo
        disableCloseOnSelect
        loadingText={false}
        options={
          isInventoryFilterChecked ? filteredInventoryProducts : products || []
        }
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name || ""
        }
        value={productNameValue}
        disabled={disabled}
        onInputChange={handleChangeProductSearchWord}
        onFocus={() => setIsOpen(true)}
        renderOption={(props, option) => (
          <ProductMenuItem
            key={option.id}
            isLoading={
              selectedProduct?.id === option.id &&
              (isFetchingInventoryProduct || isFetchingVadeProduct)
            }
            product={option}
            onViewProduct={setSelectedProduct}
            onClick={handleOnChangeProduct}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={i18n.t("recipes.recipeForm.productLabel")}
            placeholder={i18n.t("recipes.recipeForm.productPlaceholder")}
            InputProps={{
              ...params.InputProps,
              startAdornment: <Science sx={{ mr: 1 }} />,
              endAdornment:
                isFetchingProducts || isFetchingInventoryProducts ? (
                  <CircularProgress color="primary" size={20} />
                ) : productFilterSearchWord.length === 0 ? (
                  <Search />
                ) : (
                  params.InputProps.endAdornment
                ),
            }}
            helperText={
              inputError ? i18n.t("formErrors.requiredField") : undefined
            }
            FormHelperTextProps={{
              style: { ...helperTextStyle, color: "#c62828" },
            }}
            error={inputError}
            onClick={() => !isOpen && setIsOpen(true)}
          />
        )}
        PaperComponent={PopupMenu}
      />
      {ProductDetailsMemo}
    </FormControl>
  );
};

const ProductMenuItem = ({
  product,
  isLoading = false,
  onClick,
  onViewProduct,
}: {
  product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct;
  isLoading?: boolean;
  onViewProduct?: (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct
  ) => void;
  onClick?: (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct
  ) => void;
}) => {
  const isNotRegisteredProduct = useMemo(
    () =>
      product instanceof PhytosanitaryProduct && product?.type?.code !== "1",
    [product]
  );

  const handleClickOnProductView = (e: React.MouseEvent) => {
    e.stopPropagation();
    onViewProduct && onViewProduct(product);
  };

  const handleClickOnProduct = () => {
    onClick && onClick(product);
  };

  return (
    <ListItem className="menu-item" onClick={handleClickOnProduct}>
      <ListItemText className="title-container">
        <span>{product.name}</span>
        {isNotRegisteredProduct && <Warning color="warning" fontSize="small" />}
      </ListItemText>
      {isNotRegisteredProduct && (
        <span className="warning-text">
          {i18n.t("recipes.recipeForm.recipeNotRegisteredProduct")}
        </span>
      )}
      <ListItemSecondaryAction onClick={handleClickOnProductView}>
        <LoadingButton variant="contained" loading={isLoading}>
          {i18n.t("recipes.recipeForm.recipeProductViewButton")}
        </LoadingButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const ProductDetails = ({
  open,
  product,
  onClose,
}: {
  open: boolean;
  product: PhytosanitaryProduct | null;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  return (
    <Drawer
      open={open}
      anchor={isSmallScreen ? "bottom" : "left"}
      className="recipe-product-details"
      PaperProps={{ className: "recipe-product-details" }}
      sx={{ zIndex: 9999 }} // To be over the product menu modal
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="header">
        <div className="header-titles">
          <h1>
            {product?.registeredPhytosanitaryProduct?.name || product?.name}
          </h1>
          {product?.registeredPhytosanitaryProduct && (
            <h2>{product.registeredPhytosanitaryProduct.otherNamesAsString}</h2>
          )}
        </div>
        <Button
          variant="contained"
          endIcon={isSmallScreen && <ExpandMore />}
          onClick={onClose}
        >
          {i18n.t("words.close")}
        </Button>
      </div>

      <div className="content">
        {product?.registeredPhytosanitaryProduct ? (
          <>
            <StatusSection product={product.registeredPhytosanitaryProduct} />
            <ApplicationSection
              product={product.registeredPhytosanitaryProduct}
            />
            <CompositionSection
              product={product.registeredPhytosanitaryProduct}
            />
            <ProviderSection product={product.registeredPhytosanitaryProduct} />
          </>
        ) : (
          <>
            <div className="section-header">
              <h3>
                {i18n.t("recipes.recipeForm.recipeProductStatusSectionTitle")}
              </h3>
            </div>
            <div className="content-row">
              <div className="content-row-item">
                <h4>{i18n.t("recipes.recipeForm.recipeProductTypeLabel")}</h4>
                <p>{product?.type?.name}</p>
              </div>
              <div className="content-row-item">
                <h4>
                  {i18n.t(
                    "recipes.recipeForm.recipeProductExpirationDateLabel"
                  )}
                </h4>
                <p
                  className={product?.isExpired ? "error-text" : "success-text"}
                >
                  {product?.expirationDateFormatted}
                </p>
              </div>
            </div>
            <div className="content-row">
              <div className="content-row-item">
                <h4>{i18n.t("recipes.recipeForm.recipeProductOwnerLabel")}</h4>
                <p>{product?.owner}</p>
              </div>
            </div>

            <div className="section-header">
              <h3>
                {i18n.t(
                  "recipes.recipeForm.recipeProductCompositionSectionTitle"
                )}
              </h3>
            </div>
            <div className="content-row">
              <div className="content-row-item">
                <p style={{ marginBottom: "16px" }}>{product?.formulation}</p>
              </div>
            </div>
            {product?.activeSubstances &&
              product.activeSubstances.length > 0 && (
                <div className="content-row">
                  <div className="content-row-item">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            {i18n.t(
                              "recipes.recipeForm.recipeProductActiveSubstanceColumnLabel"
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {product.activeSubstances.map((activeSubstance) => (
                          <tr key={activeSubstance.id}>
                            <td>{activeSubstance.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
          </>
        )}
      </div>
    </Drawer>
  );
};

interface PlantProblemsInputProps {
  selectedCrop?: Crop;
  selectedProduct?: RegisteredPhytosanitaryProduct | PhytosanitaryProduct;
  value?: PlantProblem[];
  inputError?: boolean;
  type?: PhytosanitaryTreatmentType;
  onChange?: (plantProblems: PlantProblem[]) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
let plantProblemsSearchWordTimeout: NodeJS.Timeout | null;
export const PlantProblemsInput = (props: PlantProblemsInputProps) => {
  const {
    value = [],
    selectedCrop,
    selectedProduct,
    onChange,
    inputError = false,
    type = PhytosanitaryTreatmentType.CROP,
    onError,
  } = props;
  const placeholder =
    type === PhytosanitaryTreatmentType.POST_HARVEST
      ? i18n.t("recipes.recipeForm.plantProblemPostHarvestPlaceholder")
      : type === PhytosanitaryTreatmentType.FACILITY
      ? i18n.t("recipes.recipeForm.plantProblemFacilityPlaceholder")
      : i18n.t("recipes.recipeForm.plantProblemPlaceholder");

  const autocompleteMenuRef = useRef<HTMLDivElement>(null);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const { selectedABAccount } = useSession();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [plantProblemSearchWord, setPlantProblemSearchWord] =
    useState<string>("");
  const [plantProblemNameValue, setPlantProblemNameValue] =
    useState<string>("");
  const [siexProductIdFilter, setSiexProductIdFilter] = useState<number | null>(
    null
  );
  const [productIdFilter, setProductIdFilter] = useState<number | null>(null);

  const filterPlantProblems = (data: PlantProblem[]) => {
    if (type === PhytosanitaryTreatmentType.POST_HARVEST)
      return data.filter(
        (plantProblem) => plantProblem.type !== PlantProblemType.WEED
      );
    if (type === PhytosanitaryTreatmentType.FACILITY)
      return data.filter(
        (plantProblem) =>
          plantProblem.type !== PlantProblemType.WEED &&
          plantProblem.type !== PlantProblemType.REGULATORS
      );
    return data;
  };

  const { data: plantProblems, isFetching: isFetchingPlantProblems } = useFetch<
    PlantProblem[]
  >({
    queryKey: [
      "plantProblems",
      selectedABAccount?.context?.id,
      plantProblemSearchWord,
      siexProductIdFilter,
    ],
    selected: {
      nameContains: plantProblemSearchWord,
      siexProductId: siexProductIdFilter,
    },
    filter: filterPlantProblems,
    enabled: !!selectedABAccount?.context?.id && siexProductIdFilter !== null,
    onError,
  });

  const {
    data: plantProblemsByName,
    isFetching: isFetchingPlantProblemsByName,
  } = useFetch<PlantProblem[]>({
    queryKey: [
      "plantProblemsCatalogue",
      selectedABAccount?.context?.id,
      plantProblemSearchWord,
    ],
    selected: {
      nameContains: plantProblemSearchWord,
    },
    filter: filterPlantProblems,
    enabled:
      !!selectedABAccount?.context?.id &&
      siexProductIdFilter === null &&
      plantProblemSearchWord.length > 0,
    onError,
  });

  const { data: selectedProductDetails, isFetching: isFetchingProductDetails } =
    useFetch<RegisteredPhytosanitaryProduct>({
      queryKey: ["registeredPhytoProductById", selectedProduct?.id],
      enabled: !!selectedProduct?.id && productIdFilter !== null,
      selected: selectedProduct,
      onError,
    });

  const isNotRegisteredProduct = useMemo(
    () => selectedProduct instanceof PhytosanitaryProduct,
    [selectedProduct]
  );

  const selectedProductPlantProblems: PlantProblem[] = useMemo(() => {
    const plantProblemsByProduct: PlantProblem[] = [];
    if (siexProductIdFilter !== null) {
      plantProblems?.forEach((plantProblem) => {
        if (
          selectedProductDetails?.uses?.find(
            (use) => use.agent?.plantProblem?.id === plantProblem.id
          )
        )
          plantProblemsByProduct.push(plantProblem);
      });
    } else {
      selectedProductDetails?.uses?.forEach((use) => {
        if (
          !plantProblemsByProduct.find(
            (plantProblem) => plantProblem.id === use.agent?.plantProblem?.id
          ) &&
          use.agent?.plantProblem?.id
        )
          plantProblemsByProduct.push(use.agent.plantProblem);
      });
    }
    return filterPlantProblems(plantProblemsByProduct);
  }, [selectedProductDetails, plantProblems, siexProductIdFilter]);

  const options = useMemo(() => {
    if (productIdFilter !== null) return selectedProductPlantProblems;

    return siexProductIdFilter === null
      ? plantProblemsByName || []
      : plantProblems || [];
  }, [
    plantProblems,
    plantProblemsByName,
    selectedProductPlantProblems,
    siexProductIdFilter,
    productIdFilter,
  ]);

  useEffect(() => {
    if (!value || value.length === 0) {
      setSiexProductIdFilter(selectedCrop?.product?.id || null);
      setProductIdFilter(selectedProduct?.id || null);
    }
  }, [value, selectedCrop, selectedProduct]);

  useEffect(() => {
    if (!isOpen) {
      onChange && onChange(value || []);
      setPlantProblemNameValue("");
    }
  }, [isOpen]);

  // Needed to close the form input menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node) &&
        autocompleteMenuRef.current &&
        !autocompleteMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isNotRegisteredProduct) {
      setSiexProductIdFilter(null);
      setProductIdFilter(null);
    }
  }, [isNotRegisteredProduct]);

  const handleChangeCropFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSiexProductIdFilter((state) =>
      state || !selectedCrop?.product?.id ? null : selectedCrop.product.id
    );
  };

  const handleChangeProductFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setProductIdFilter((state) =>
      state || !selectedProduct?.id ? null : selectedProduct.id
    );
  };

  const handleChangePlantProblemSearchWord = (event: any, value: string) => {
    setPlantProblemNameValue(value);

    // Launch search after a delay from the last keydown event
    if (plantProblemsSearchWordTimeout) {
      clearTimeout(plantProblemsSearchWordTimeout);
      plantProblemsSearchWordTimeout = null;
    }
    plantProblemsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      setPlantProblemSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleClickOnPlantProblem = (plantProblem: PlantProblem) => {
    const newPlantProblems = value?.find((pp) => pp.id === plantProblem.id)
      ? value?.filter((pp) => pp.id !== plantProblem.id)
      : [...(value || []), plantProblem];
    onChange && onChange(newPlantProblems);
  };

  const PopupMenu = ({ children }: { children?: React.ReactNode }) => {
    const emptyFilters = useMemo(
      () =>
        plantProblemSearchWord.length === 0 &&
        !siexProductIdFilter &&
        !productIdFilter,
      [plantProblemSearchWord, siexProductIdFilter, productIdFilter]
    );

    const noResults = useMemo(
      () =>
        (!isFetchingPlantProblems &&
          siexProductIdFilter !== null &&
          productIdFilter === null &&
          (!plantProblems || plantProblems.length === 0)) ||
        (!isFetchingPlantProblemsByName &&
          siexProductIdFilter === null &&
          productIdFilter === null &&
          (!plantProblemsByName || plantProblemsByName.length === 0)) ||
        (!isFetchingProductDetails &&
          productIdFilter !== null &&
          selectedProductPlantProblems.length === 0),
      [
        isFetchingPlantProblems,
        isFetchingPlantProblemsByName,
        isFetchingProductDetails,
        plantProblemsByName,
        plantProblems,
        siexProductIdFilter,
        selectedProductPlantProblems,
        productIdFilter,
      ]
    );

    return (
      <div ref={autocompleteMenuRef} className="recipes-product-popup">
        {selectedCrop?.id && !isNotRegisteredProduct && (
          <ListItem className="filter-item" onClick={handleChangeCropFilter}>
            <Checkbox
              sx={productMenuCheckboxStyle}
              checked={siexProductIdFilter !== null}
            />
            <Typography noWrap sx={productMenuFilterLabelStyle}>
              {`${i18n.t(
                "recipes.recipeForm.recipePlantProblemCropFilterLabel"
              )} ${selectedCrop?.productName}`}
            </Typography>
          </ListItem>
        )}
        {selectedProduct &&
          selectedProduct?.id !== null &&
          !isNotRegisteredProduct && (
            <ListItem
              className="filter-item"
              onClick={handleChangeProductFilter}
            >
              <Checkbox
                sx={productMenuCheckboxStyle}
                checked={productIdFilter !== null}
              />
              <Typography noWrap sx={productMenuFilterLabelStyle}>
                {`${i18n.t(
                  "recipes.recipeForm.recipePlantProblemsProductFilterLabel"
                )} ${selectedProduct.name}`}
              </Typography>
            </ListItem>
          )}

        {isFetchingPlantProblems || isFetchingPlantProblemsByName ? (
          <LinearProgress />
        ) : (
          <Divider />
        )}

        {emptyFilters && noResults && (
          <p className="not-results-text">
            {i18n.t("recipes.recipeForm.recipePlantProblemSearchNameRequired")}
          </p>
        )}

        {!emptyFilters && noResults && (
          <p className="not-results-text">
            {i18n.t("recipes.recipeForm.recipePlantProblemsNotFoundLabel")}
          </p>
        )}

        {children}
      </div>
    );
  };

  return (
    <FormControl variant="outlined" className="form-control">
      <Autocomplete
        ref={autocompleteRef}
        className="form-input"
        role="listbox"
        ListboxProps={{ role: "listbox" }}
        open={isOpen}
        freeSolo
        disableCloseOnSelect
        loadingText={false}
        options={options.sort((a, b) =>
          (a?.type || 0) > (b?.type || 0) ? 1 : -1
        )} // Needed for groupBy
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name || ""
        }
        groupBy={(option) => option.problemType?.name || ""}
        value={plantProblemNameValue}
        onInputChange={handleChangePlantProblemSearchWord}
        onFocus={() => setIsOpen(true)}
        renderOption={(props, option) => (
          <PlantProblemMenuItem
            key={option.id}
            plantProblem={option}
            selectedPlantProblems={value}
            onClick={handleClickOnPlantProblem}
          />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label={i18n.t("recipes.recipeForm.plantProblemLabel")}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: <PestControl sx={{ mr: 1 }} />,
              endAdornment:
                isFetchingPlantProblems || isFetchingPlantProblemsByName ? (
                  <CircularProgress color="primary" size={20} />
                ) : plantProblemSearchWord.length === 0 ? (
                  <Search />
                ) : (
                  params.InputProps.endAdornment
                ),
            }}
            helperText={
              inputError ? i18n.t("formErrors.requiredField") : undefined
            }
            FormHelperTextProps={{
              style: { ...helperTextStyle, color: "#c62828" },
            }}
            error={inputError}
            onClick={() => !isOpen && setIsOpen(true)}
          />
        )}
        PaperComponent={PopupMenu}
      />
    </FormControl>
  );
};

const PlantProblemMenuItem = ({
  plantProblem,

  selectedPlantProblems,
  onClick,
}: {
  plantProblem: PlantProblem;

  selectedPlantProblems: PlantProblem[];
  onClick?: (plantProblem: PlantProblem) => void;
}) => {
  const handleClick = () => {
    onClick && onClick(plantProblem);
  };

  return (
    <ListItem className="menu-item" onClick={handleClick}>
      <ListItemButton>
        <Checkbox
          edge="start"
          disableRipple
          checked={
            !!selectedPlantProblems.find((pp) => pp.id === plantProblem.id)
          }
        />
        <ListItemText>{plantProblem.name}</ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

export const PlantProblemList = ({
  plantProblems,
  selectedCrop,
  selectedProduct,
  disabled = false,
  onConflicts,
  onRemove,
  onError,
}: {
  plantProblems: PlantProblem[];
  selectedCrop?: Crop;
  selectedProduct?: RegisteredPhytosanitaryProduct | PhytosanitaryProduct;
  disabled?: boolean;
  onConflicts?: (conflicts: boolean) => void;
  onRemove?: (plantProblem: PlantProblem) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}) => {
  const { selectedABAccount } = useSession();

  // Group plantProblems by type
  const plantProblemsByType = useMemo(() => {
    const problemsByType = new Map<string, PlantProblem[]>();
    plantProblems.forEach((problem) => {
      const type = problem.problemType?.name || "";
      if (problemsByType.has(type)) {
        problemsByType.get(type)?.push(problem);
      } else {
        problemsByType.set(type, [problem]);
      }
    });
    return problemsByType;
  }, [plantProblems]);

  const { data: plantProblemsByCrop, isFetching: isFetchingPlantProblems } =
    useFetch<PlantProblem[]>({
      queryKey: [
        "plantProblems",
        selectedABAccount?.context?.id,
        selectedCrop?.product?.id,
      ],
      selected: {
        siexProductId: selectedCrop?.product?.id,
      },
      enabled: !!selectedABAccount?.context?.id && !!selectedCrop?.product?.id,
      onError,
    });

  const { data: selectedProductDetails, isFetching: isFetchingProductDetails } =
    useFetch<RegisteredPhytosanitaryProduct>({
      queryKey: ["registeredPhytoProductById", selectedProduct?.id],
      enabled: !!selectedProduct?.id,
      selected: selectedProduct,
      onError,
    });

  useEffect(() => {
    if (onConflicts) {
      if (selectedProduct instanceof PhytosanitaryProduct)
        return onConflicts(true);

      // Check if there are any plantProblem id into plantProblemsByCrop
      if (plantProblemsByCrop && plantProblemsByCrop?.length > 0) {
        const conflicts = plantProblems?.filter(
          (pp) => !plantProblemsByCrop.find((p) => p.id === pp.id)
        );
        if (conflicts?.length > 0) return onConflicts(true);
      }

      // Check if there are any plantProblem id into selectedProductDetails uses
      if (
        selectedProductDetails?.uses &&
        selectedProductDetails?.uses?.length > 0
      ) {
        const conflicts = plantProblems.filter(
          (p) =>
            !selectedProductDetails?.uses?.find(
              (use) => p.id === use.agent?.plantProblem?.id
            )
        );
        if (conflicts?.length > 0) return onConflicts(true);
      }

      onConflicts(false);
    }
  }, [
    plantProblemsByType,
    plantProblemsByCrop,
    selectedProduct,
    selectedProductDetails,
  ]);

  const CheckIcon = ({ problem }: { problem: PlantProblem }) => {
    const isNotRegisteredProduct =
      selectedProduct instanceof PhytosanitaryProduct;
    const isValidForCrop = plantProblemsByCrop?.find(
      (pp) => pp.id === problem.id
    );
    const isValidForProduct = selectedProductDetails?.uses?.find(
      (use) => use?.agent?.plantProblem?.id === problem.id
    );
    const checkStatusVisible = selectedCrop || selectedProduct;

    if (!checkStatusVisible) return null;

    if (isNotRegisteredProduct)
      return <Warning color="warning" fontSize="small" />;

    if (isFetchingPlantProblems || isFetchingProductDetails)
      return <CircularProgress color="inherit" size={14} sx={{ ml: 1 }} />;

    if (
      (!selectedCrop || isValidForCrop) &&
      (!selectedProduct || isValidForProduct)
    )
      return <CheckCircle color="primary" fontSize="small" />;

    return <Error color="error" fontSize="small" />;
  };

  return (
    <List className="plant-problem">
      {Array.from(plantProblemsByType.keys()).map((type) => (
        <React.Fragment key={type}>
          <ListSubheader className="subheader">{type}</ListSubheader>
          {plantProblemsByType.get(type)?.map((problem) => {
            const isNotRegisteredProduct =
              selectedProduct instanceof PhytosanitaryProduct;
            const isValidForCrop = plantProblemsByCrop?.find(
              (pp) => pp.id === problem.id
            );
            const isValidForProduct = selectedProductDetails?.uses?.find(
              (use) => use?.agent?.plantProblem?.id === problem.id
            );

            return (
              <ListItem key={problem.id} className="list-item">
                <ListItemText className="title-container">
                  <span>{problem.name}</span>
                  {!disabled && <CheckIcon problem={problem} />}
                </ListItemText>
                {!disabled && isNotRegisteredProduct && (
                  <span className="warning-text">{`${
                    selectedProduct?.name || ""
                  } ${i18n.t(
                    "recipes.recipeForm.recipePlantProblemNotRegisteredProduct"
                  )}`}</span>
                )}
                {!disabled &&
                  !isNotRegisteredProduct &&
                  selectedCrop &&
                  !isValidForCrop && (
                    <span className="error-text">{`${i18n.t(
                      "recipes.recipeForm.recipeCropNotValidForPlantProblem"
                    )} ${selectedCrop.productName}`}</span>
                  )}
                {!disabled &&
                  !isNotRegisteredProduct &&
                  selectedProduct &&
                  !isValidForProduct && (
                    <span className="error-text">{`${
                      selectedProduct.name
                    } ${i18n.t(
                      "recipes.recipeForm.recipeProductNotValidForPlantProblem"
                    )}`}</span>
                  )}

                {!disabled && (
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => onRemove && onRemove(problem)}>
                      <Close />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </React.Fragment>
      ))}
    </List>
  );
};

const RecipeTreatmentForm = ({
  formik,
  onError,
}: {
  formik: FormikProps<PhytoRecipe>;
  onError?: (snackBarError: SnackbarInterface) => void;
}) => {
  const { selectedABAccount } = useSession();

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const {
    data: phytoActionJustificationTypes,
    isFetching: isFetchingJustificationTypes,
  } = useFetch<PhytosanitaryActionJustificationType[]>({
    queryKey: [
      "phytosanitaryActionJustificationTypes",
      selectedABAccount?.context?.id,
    ],
    enabled: !!selectedABAccount?.context?.id,
    onError,
  });

  const handleChangeStartDate = (date: moment.Moment | null) => {
    const fromDateStr = date?.format("YYYY-MM-DD");
    formik.setFieldValue("startRecipeDate", fromDateStr);
    if (date?.isAfter(formik.values.endRecipeDate)) {
      formik.setFieldValue(
        "endRecipeDate",
        moment(date).add(7, "days").format("YYYY-MM-DD")
      );
    }
  };

  const handleChangeEndDate = (date: moment.Moment | null) => {
    const toDateStr = date?.format("YYYY-MM-DD");
    formik.setFieldValue("endRecipeDate", toDateStr);
    if (date?.isBefore(formik.values.startRecipeDate)) {
      formik.setFieldValue(
        "startRecipeDate",
        moment(date).subtract(7, "days").format("YYYY-MM-DD")
      );
    }
  };

  return (
    <BrioCard
      title={i18n.t("recipes.recipeForm.recipeTreatmentFormTitle")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            style={{ display: "flex", flex: 1 }}
            formik={formik}
            name="dose"
            label={i18n.t("recipes.recipeForm.recipeDoseLabel")}
            type="number"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Sanitizer />
                </InputAdornment>
              ),
            }}
          />
          <FormikSelect
            style={{ minWidth: "120px" }}
            formik={formik}
            type="row"
            name="doseUnit"
            label={i18n.t("recipes.recipeForm.recipeDoseUnitLabel")}
            optionLabelFieldName="name"
            required
            options={measurementUnits || []}
            isLoading={isFetchingMeasurementUnits}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={i18n.t("recipes.recipeForm.recipeStartDateLabel")}
            value={
              formik.values.startRecipeDate
                ? moment(formik.values.startRecipeDate, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeStartDate}
          />
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={i18n.t("recipes.recipeForm.recipeEndDateLabel")}
            value={
              formik.values.endRecipeDate
                ? moment(formik.values.endRecipeDate, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeEndDate}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="justifications"
            label={i18n.t(
              "recipes.recipeForm.recipeTreatmentJustificationsLabel"
            )}
            multiple
            options={phytoActionJustificationTypes || []}
            optionLabelFieldName="name"
            loading={isFetchingJustificationTypes}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="observations"
            label={i18n.t("recipes.recipeForm.recipeObservationsLabel")}
            placeholder={i18n.t(
              "recipes.recipeForm.recipeObservationsPlaceholder"
            )}
            multiline
            rows={4}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const ProductDetailView = ({
  isOpen,
  product,
  onClose,
}: {
  isOpen: boolean;
  product?: RegisteredPhytosanitaryProduct | PhytosanitaryProduct;
  onClose?: () => void;
}) => {
  // Fetch registered product details if the product is a registered product
  const { data: registeredProduct, isFetching: isFetchingRegisteredProduct } =
    useFetch<RegisteredPhytosanitaryProduct>({
      queryKey: ["registeredPhytoProductById", product?.id],
      enabled:
        !!product?.id && product instanceof RegisteredPhytosanitaryProduct,
      selected: product,
    });

  // Fetch inventory product details if the product is an inventory product
  const { data: inventoryProduct, isFetching: isFetchingInventoryProduct } =
    useFetch<PhytosanitaryProduct>({
      queryKey: ["phytosanitaryProduct", product?.id],
      enabled: !!product?.id && product instanceof PhytosanitaryProduct,
      selected: { id: product?.id }, // The endpoint only need the id
    });

  const phytoProduct = useMemo(
    () =>
      product instanceof RegisteredPhytosanitaryProduct
        ? registeredProduct || null
        : new RegisteredPhytosanitaryProduct().mapToClass(inventoryProduct) ||
          null,
    [registeredProduct, inventoryProduct]
  );

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="vademecum-phyto-drawer"
      PaperProps={{ className: "vademecum-phyto-drawer" }}
    >
      <div className="container">
        <div className="header">
          <IconButton onClick={onClose}>
            <ArrowBack fontSize="large" color="action" />
          </IconButton>
          <div className="header-titles">
            <h1>{phytoProduct?.name}</h1>
            <h2>{phytoProduct?.otherNamesAsString}</h2>
          </div>
        </div>
        {product && (
          <div className="content">
            <LoadingWithDelay
              isVisible={
                isFetchingRegisteredProduct || isFetchingInventoryProduct
              }
            />
            <ApplicationSection product={phytoProduct} />
            <CompositionSection product={phytoProduct} />
            <ProviderSection product={phytoProduct} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

const productMenuCheckboxStyle: SxProps<Theme> = { p: 0.5, pl: 0, m: 0 };
const productMenuFilterLabelStyle: SxProps<Theme> = { fontSize: 14 };
