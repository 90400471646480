import FBMunicipality from "../_apiModels/address/FBMunicipality";

class Municipality extends FBMunicipality {
  constructor() {
    super();
  }

  mapToClass(obj: any): Municipality | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default Municipality;
