import FBAnalysis from "../_apiModels/analysis/FBAnalysis";
import Document from "../files/Document";

import { ANALYSIS_SOIL_TYPES_CODES } from "../../constants/lists";
import { dateConverter } from "../../helpers/utils";
import { FBESiexRequestStatus } from "../../constants/enums";

class Analysis extends FBAnalysis {
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): Analysis | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    this.dateFormatted = dateConverter({ date: this.date });
    if (this.siexStatus === null) this.siexStatus = FBESiexRequestStatus.NOSYNC;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.soilParameters = this.isSoilParametersCode();
    if (!this.isSoilParametersCode()) {
      obj.soilOrganicMaterial = null;
      obj.soilSand = null;
      obj.soilSilt = null;
      obj.soilClay = null;
      obj.soilPH = null;
      obj.soilAvailablePhosphorus = null;
      obj.soilAvailablePotassium = null;
      obj.soilTotalNitrogen = null;
      obj.soilConductivity = null;
    }
    if (this.siexStatus === FBESiexRequestStatus.NOSYNC) obj.siexStatus = null;

    // Remove not API fields
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    return obj;
  }

  isSoilParametersCode(): boolean {
    return ANALYSIS_SOIL_TYPES_CODES.includes(
      this.analizedMaterial?.code || ""
    );
  }
}

export default Analysis;
