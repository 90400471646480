class FBSiexRequest {
  id?: number;
  requestTimestamp?: number;
  responseTimestamp?: number;

  constructor(
    id?: number,
    requestTimestamp?: number,
    responseTimestamp?: number
  ) {
    this.id = id;
    this.requestTimestamp = requestTimestamp;
    this.responseTimestamp = responseTimestamp;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.requestTimestamp = obj?.requestTimestamp;
    this.responseTimestamp = obj?.responseTimestamp;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBSiexRequest;
