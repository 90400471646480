import Crop from "../../crop/Crop";
import PlantRegulatorsAndOthers from "../../catalogue/PlantRegulatorsAndOthers";
import PhytosanitaryActionType from "../../catalogue/PhytosanitaryActionType";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import PhytosanitaryTreatmentApplicator from "../../phyto/PhytosanitaryTreatmentApplicator";
import PhytosanitaryTreatmentAdvisor from "../../phyto/PhytosanitaryTreatmentAdvisor";
import PhytosanitaryTreatment from "../../phyto/PhytosanitaryTreatment";
import CropPhytosanitaryTreatmentSector from "../../phyto/CropPhytosanitaryTreatmentSector";

class FBCropPhytosanitaryTreatment extends PhytosanitaryTreatment {
  id?: number;
  crop?: Crop;
  startTime?: string; //HH:mm:ss
  endTime?: string; //HH:mm:ss
  treatedArea?: number;
  regulators?: PlantRegulatorsAndOthers[];
  defenseActionType?: PhytosanitaryActionType;
  defenseActionQuantity?: number;
  defenseActionQuantityUnit?: MeasurementUnit;
  defenseActionRegisterId?: string;
  applicators?: PhytosanitaryTreatmentApplicator[];
  advisors?: PhytosanitaryTreatmentAdvisor[];
  treatedSectors?: CropPhytosanitaryTreatmentSector[];

  constructor(
    id?: number,
    crop?: Crop,
    startTime?: string,
    endTime?: string,
    treatedArea?: number,
    regulators?: PlantRegulatorsAndOthers[],
    defenseActionType?: PhytosanitaryActionType,
    defenseActionQuantity?: number,
    defenseActionQuantityUnit?: MeasurementUnit,
    defenseActionRegisterId?: string,
    applicators?: PhytosanitaryTreatmentApplicator[],
    advisors?: PhytosanitaryTreatmentAdvisor[],
    treatedSectors?: CropPhytosanitaryTreatmentSector[]
  ) {
    super();
    this.id = id;
    this.crop = crop;
    this.startTime = startTime;
    this.endTime = endTime;
    this.treatedArea = treatedArea;
    this.regulators = regulators;
    this.defenseActionType = defenseActionType;
    this.defenseActionQuantity = defenseActionQuantity;
    this.defenseActionQuantityUnit = defenseActionQuantityUnit;
    this.defenseActionRegisterId = defenseActionRegisterId;
    this.applicators = applicators;
    this.advisors = advisors;
    this.treatedSectors = treatedSectors;
  }

  mapToBaseClass(obj: any): void {
    super.mapToClass(obj);
    this.id = obj?.id;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.startTime = obj?.startTime;
    this.endTime = obj?.endTime;
    this.treatedArea = obj?.treatedArea;
    this.regulators = obj?.regulators?.map((regulator: any) =>
      new PlantRegulatorsAndOthers().mapToClass(regulator)
    );
    this.defenseActionType = new PhytosanitaryActionType().mapToClass(
      obj?.defenseActionType
    );
    this.defenseActionQuantity = obj?.defenseActionQuantity;
    this.defenseActionQuantityUnit = new MeasurementUnit().mapToClass(
      obj?.defenseActionQuantityUnit
    );
    this.defenseActionRegisterId = obj?.defenseActionRegisterId;
    this.applicators = obj?.applicators?.map((applicator: any) =>
      new PhytosanitaryTreatmentApplicator().mapToClass(applicator)
    );
    this.advisors = obj?.advisors?.map((advisor: any) =>
      new PhytosanitaryTreatmentAdvisor().mapToClass(advisor)
    );
    this.treatedSectors =
      obj?.treatedSectors
        ?.map((treatedSector: any) =>
          new CropPhytosanitaryTreatmentSector().mapToClass(treatedSector)
        )
        ?.filter(
          (treatedSector: CropPhytosanitaryTreatmentSector) =>
            !treatedSector?.sector?.disabledDate
        ) || [];
  }

  mapBaseToApiModel(): any {
    const obj: any = super.mapToApiModel();
    obj.id = this.id;
    obj.crop = this.crop?.mapToApiModel();
    obj.treatedArea = this.treatedArea;
    obj.regulators = this.regulators?.map(
      (regulator: PlantRegulatorsAndOthers) => regulator.mapToApiModel()
    );
    obj.defenseActionType = this.defenseActionType?.mapToApiModel();
    obj.defenseActionQuantity = this.defenseActionQuantity;
    obj.defenseActionQuantityUnit =
      this.defenseActionQuantityUnit?.mapToApiModel();
    obj.defenseActionRegisterId = this.defenseActionRegisterId;
    obj.applicators = this.applicators?.map(
      (applicator: PhytosanitaryTreatmentApplicator) =>
        applicator.mapToApiModel()
    );
    obj.advisors = this.advisors?.map(
      (advisor: PhytosanitaryTreatmentAdvisor) => advisor.mapToApiModel()
    );
    obj.treatedSectors = this.treatedSectors?.map(
      (treatedSector: CropPhytosanitaryTreatmentSector) =>
        treatedSector.mapToApiModel()
    );
    return obj;
  }
}

export default FBCropPhytosanitaryTreatment;
