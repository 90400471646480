import FBManager from "../_apiModels/agrobusiness/FBManager";
import Document from "../files/Document";

import { LeaderTypeInterface } from "../../constants/interfaces";
import { LEADER_TYPES } from "../../providers/YupProvider";
import { LeaderType } from "../../constants/enums";

class Manager extends FBManager {
  idx?: string;
  leaderType?: LeaderTypeInterface;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(idx?: string, leaderType?: LeaderTypeInterface) {
    super();
    this.idx = idx;
    this.leaderType = leaderType;
  }

  mapToClass(obj: any): Manager | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = LeaderType.MANAGER;
    this.leaderType = LEADER_TYPES.find(
      (leaderType) => leaderType.code === LeaderType.MANAGER
    );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.idx;
    delete obj.leaderType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default Manager;
