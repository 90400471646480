import { FBESiexRequestStatus } from "../../../constants/enums";
import SeedTreatmentType from "../../catalogue/SeedTreatmentType";
import TreatmentEfficacy from "../../catalogue/TreatmentEfficacy";
import Crop from "../../crop/Crop";
import PhytosanitaryStockMovement from "../../phyto/PhytosanitaryStockMovement";

class FBTreatedSeed {
  id?: number;
  crop?: Crop; //*
  sowingArea?: number;
  treatmentType?: SeedTreatmentType;
  productMovement?: PhytosanitaryStockMovement;
  date?: string;
  lotNumber?: string;
  quantity?: number;
  efficacy?: TreatmentEfficacy;
  observations?: string;
  justificationDetail?: string;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    crop?: Crop,
    sowingArea?: number,
    treatmentType?: SeedTreatmentType,
    productMovement?: PhytosanitaryStockMovement,
    date?: string,
    lotNumber?: string,
    quantity?: number,
    efficacy?: TreatmentEfficacy,
    observations?: string,
    justificationDetail?: string
  ) {
    this.id = id;
    this.crop = crop;
    this.sowingArea = sowingArea;
    this.treatmentType = treatmentType;
    this.productMovement = productMovement;
    this.date = date;
    this.lotNumber = lotNumber;
    this.quantity = quantity;
    this.efficacy = efficacy;
    this.observations = observations;
    this.justificationDetail = justificationDetail;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.sowingArea = obj?.sowingArea;
    this.treatmentType = new SeedTreatmentType().mapToClass(obj?.treatmentType);
    this.productMovement = new PhytosanitaryStockMovement().mapToClass(
      obj?.productMovement
    );
    this.date = obj?.date;
    this.lotNumber = obj?.lotNumber;
    this.quantity = obj?.quantity;
    this.efficacy = new TreatmentEfficacy().mapToClass(obj?.efficacy);
    this.observations = obj?.observations;
    this.justificationDetail = obj?.justificationDetail;
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.crop = this.crop?.mapToApiModel();
    obj.treatmentType = this.treatmentType?.mapToApiModel();
    obj.productMovement = this.productMovement?.mapToApiModel();
    obj.efficacy = this.efficacy?.mapToApiModel();
    return obj;
  }
}

export default FBTreatedSeed;
