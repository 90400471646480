import Facility from "../../agrobusiness/Facility";
import Fertilization from "../../fertiliz/Fertilization";
import FertilizerProduct from "../../fertiliz/FertilizerProduct";
import FertilizerProductPurchase from "../../fertiliz/FertilizerProductPurchase";
import FertigationWaterMix from "../../fertiliz/FertigationWaterMix";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";

class FBFertilizerStockMovement {
  id?: number;
  date?: string; //*
  product?: FertilizerProduct; //*
  type?: string;
  quantity?: number;
  priceByUnit?: number;
  description?: string;
  purchase?: FertilizerProductPurchase;
  facility?: Facility;
  fertilization?: Fertilization;
  fertigationWaterMix?: FertigationWaterMix;
  agroBusinessPartition?: AgroBusinessPartition;

  constructor(
    id?: number,
    date?: string,
    product?: FertilizerProduct,
    type?: string,
    quantity?: number,
    priceByUnit?: number,
    description?: string,
    facility?: Facility,
    fertilization?: Fertilization,
    fertigationWaterMix?: FertigationWaterMix,
    purchase?: FertilizerProductPurchase,
    agroBusinessPartition?: AgroBusinessPartition
  ) {
    this.id = id;
    this.date = date;
    this.product = product;
    this.type = type;
    this.quantity = quantity;
    this.priceByUnit = priceByUnit;
    this.description = description;
    this.facility = facility;
    this.fertilization = fertilization;
    this.fertigationWaterMix = fertigationWaterMix;
    this.purchase = purchase;
    this.agroBusinessPartition = agroBusinessPartition;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.date = obj?.date;
    this.product = new FertilizerProduct().mapToClass(obj?.product);
    this.type = obj?.type;
    this.quantity = obj?.quantity;
    this.priceByUnit = obj?.priceByUnit;
    this.description = obj?.description;
    this.facility = new Facility().mapToClass(obj?.facility);
    this.fertilization = new Fertilization().mapToClass(obj?.fertilization);
    this.fertigationWaterMix = new FertigationWaterMix().mapToClass(
      obj?.fertigationWaterMix
    );
    this.purchase = new FertilizerProductPurchase().mapToClass(obj?.purchase);
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.product = this.product?.mapToApiModel();
    obj.facility = this.facility?.mapToApiModel();
    obj.fertilization = this.fertilization?.mapToApiModel();
    obj.fertigationWaterMix = this.fertigationWaterMix?.mapToApiModel();
    obj.purchase = this.purchase?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerStockMovement;
