import VadeAgent from "../../vademecum/VadeAgent";
import VadeCrop from "../../vademecum/VadeCrop";

class FBVadeProductUse {
  id?: number;
  crop?: VadeCrop;
  agent?: VadeAgent;
  specificConditioning?: string;
  scope?: string;
  applications?: string;
  applicationsInterval?: string;
  applicationMethod?: string;
  cropSystem?: string;
  bbch?: string;
  securityPeriod?: string;
  userType?: string;
  minDose?: number;
  maxDose?: number;
  doseUnit?: string;
  brothVolume?: string;
  minVolume?: number;
  maxVolume?: number;
  volumeUnit?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.crop = new VadeCrop(obj?.crop);
    this.agent = new VadeAgent(obj?.agent);
    this.specificConditioning = obj?.specificConditioning;
    this.scope = obj?.scope;
    this.applications = obj?.applications;
    this.applicationsInterval = obj?.applicationsInterval;
    this.applicationMethod = obj?.applicationMethod;
    this.cropSystem = obj?.cropSystem;
    this.bbch = obj?.bbch;
    this.securityPeriod = obj?.securityPeriod;
    this.userType = obj?.userType;
    this.minDose = obj?.minDose;
    this.maxDose = obj?.maxDose;
    this.doseUnit = obj?.doseUnit;
    this.brothVolume = obj?.brothVolume;
    this.minVolume = obj?.minVolume;
    this.maxVolume = obj?.maxVolume;
    this.volumeUnit = obj?.volumeUnit;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeProductUse;
