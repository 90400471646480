import FBNotification from "../_apiModels/notification/FBNotification";

class Notification extends FBNotification {
  constructor() {
    super();
  }

  mapToClass(obj: any): Notification | undefined {
    if (!obj) return undefined;
    super.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default Notification;
