import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import PhytosanitaryActionJustificationType from "../../catalogue/PhytosanitaryActionJustificationType";
import PlantWeed from "../../catalogue/PlantWeed";
import PlantPlague from "../../catalogue/PlantPlague";
import PlantDisease from "../../catalogue/PlantDisease";
import PhytosanitaryStockMovement from "../../phyto/PhytosanitaryStockMovement";
import TreatmentEfficacy from "../../catalogue/TreatmentEfficacy";
import PhytoRecipe from "../../phytoRecipe/PhytoRecipe";
import { FBESiexRequestStatus } from "../../../constants/enums";

class FBPhytosanitaryTreatment {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  startDate?: string;
  endDate?: string;
  justifications?: PhytosanitaryActionJustificationType[];
  efficacy?: TreatmentEfficacy;
  observations?: string;
  weeds?: PlantWeed[];
  plagues?: PlantPlague[];
  diseases?: PlantDisease[];
  products?: PhytosanitaryStockMovement[];
  recipe?: PhytoRecipe;
  enabledDate?: string;
  disabledDate?: string;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    startDate?: string,
    endDate?: string,
    justifications?: PhytosanitaryActionJustificationType[],
    efficacy?: TreatmentEfficacy,
    observations?: string,
    weeds?: PlantWeed[],
    plagues?: PlantPlague[],
    diseases?: PlantDisease[],
    products?: PhytosanitaryStockMovement[],
    recipe?: PhytoRecipe,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.startDate = startDate;
    this.endDate = endDate;
    this.justifications = justifications;
    this.efficacy = efficacy;
    this.observations = observations;
    this.weeds = weeds;
    this.plagues = plagues;
    this.diseases = diseases;
    this.products = products;
    this.recipe = recipe;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.justifications =
      obj?.justifications?.map((justification: any) =>
        new PhytosanitaryActionJustificationType().mapToClass(justification)
      ) || [];
    this.efficacy = new TreatmentEfficacy().mapToClass(obj?.efficacy);
    this.observations = obj?.observations;
    this.weeds =
      obj?.weeds?.map((weed: any) => new PlantWeed().mapToClass(weed)) || [];
    this.plagues =
      obj?.plagues?.map((plague: any) =>
        new PlantPlague().mapToClass(plague)
      ) || [];
    this.diseases =
      obj?.diseases?.map((disease: any) =>
        new PlantDisease().mapToClass(disease)
      ) || [];
    this.products =
      obj?.products?.map((product: any) =>
        new PhytosanitaryStockMovement().mapToClass(product)
      ) || [];
    this.recipe = new PhytoRecipe().mapToClass(obj?.recipe);
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.justifications = this.justifications?.map((justification: any) =>
      justification?.mapToApiModel()
    );
    obj.efficacy = this.efficacy?.mapToApiModel();
    obj.weeds = this.weeds?.map((weed: PlantWeed) => weed.mapToApiModel());
    obj.plagues = this.plagues?.map((plague: PlantPlague) =>
      plague.mapToApiModel()
    );
    obj.diseases = this.diseases?.map((disease: PlantDisease) =>
      disease.mapToApiModel()
    );
    obj.products = this.products?.map(
      (stockMovement: PhytosanitaryStockMovement) =>
        stockMovement.mapToApiModel()
    );
    obj.recipe = this.recipe?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryTreatment;
