import {
  useRouteError,
  isRouteErrorResponse,
  useNavigate,
} from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";

import { PROTECTED_ROUTES } from "../routes/routeNames";

interface Props {
  errorStatus?: number;
}
const ErrorScreen = (props: Props) => {
  const { errorStatus } = props;
  const error = useRouteError();

  if (errorStatus) return <RouteError errorStatus={errorStatus} />;

  if (isRouteErrorResponse(error))
    return <RouteError errorStatus={error.status} />;

  // Send unexpected error to Sentry
  Sentry.captureException(error);
  return undefined; //TODO: UnexpectedError
};

export default ErrorScreen;

const RouteError = (props: { errorStatus: any }) => {
  const { errorStatus } = props;
  const navigate = useNavigate();

  let errorMsg = "";
  switch (errorStatus) {
    case 404:
      errorMsg = i18n.t("routeErrors.error404");
      break;
    case 401:
      errorMsg = i18n.t("routeErrors.error401");
      break;
    case 500:
    case 503:
      errorMsg = i18n.t("routeErrors.error500");
      break;
    default:
      errorMsg = i18n.t("routeErrors.unexpectedError");
      break;
  }

  const handleClickOk = () => navigate(`/${PROTECTED_ROUTES.DASHBOARD}`);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 12, gap: 2 }}>
      <Typography variant="h2">{`Error ${errorStatus}`}</Typography>
      <Typography variant="h4">{errorMsg}</Typography>
      <Button size="large" variant="contained" onClick={handleClickOk}>
        {i18n.t("words.understood")}
      </Button>
    </Box>
  );
};

/* const UnexpectedError = () => {
  const navigate = useNavigate();

  const handleClickOk = () => navigate(`/${PROTECTED_ROUTES.DASHBOARD}`);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p: 12, gap: 2 }}>
      <Typography variant="h2">
        {i18n.t("routeErrors.unexpectedErrorMsg1")}
      </Typography>
      <Typography variant="h4">
        {i18n.t("routeErrors.unexpectedErrorMsg2")}
      </Typography>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "primary.main" }}
      >
        {i18n.t("routeErrors.unexpectedErrorMsg3")}
      </Typography>
      <Typography variant="h5">
        {i18n.t("routeErrors.unexpectedErrorMsg4")}
      </Typography>
      <Button size="large" variant="contained" onClick={handleClickOk}>
        {i18n.t("words.understood")}
      </Button>
    </Box>
  );
}; */
