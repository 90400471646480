import { Alert, Button } from "@mui/material";
import i18n from "../config/configI18n";

interface Props {
  children?: React.ReactNode;
  btnText?: string;
  severity?: "error" | "warning" | "info" | "success";
  btn?: boolean;
  onClick?: () => void;
}
const AlertEmptyList = (props: Props) => {
  const {
    children,
    btnText = i18n.t("words.createNow"),
    severity = "error",
    btn = true,
    onClick,
  } = props;

  return (
    <Alert
      severity={severity}
      action={
        btn && (
          <Button variant="text" color="primary" size="small" onClick={onClick}>
            {btnText}
          </Button>
        )
      }
      sx={{ mb: 2 }}
    >
      {children}
    </Alert>
  );
};

export default AlertEmptyList;
