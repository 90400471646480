import moment from "moment";

import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";

export class FBDocumentType {
  id?: number;
  name?: string;

  constructor(id?: number, name?: string) {
    this.id = id;
    this.name = name;
  }

  mapToClass(obj: any): FBDocumentType | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.name = obj?.name;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

class FBDocument {
  id?: number;
  name?: string; //*
  description?: string;
  type?: FBDocumentType; //*
  date?: string; //*
  mimeType?: string;
  entityClassName?: string;
  entityClassId?: number;
  enabledDate?: string;
  disabledDate?: string;
  creationInstant?: number;
  documentUrl?: string;
  thumbnailUrl?: string;
  agroBusinessPartition?: AgroBusinessPartition;

  constructor(
    id?: number,
    name?: string,
    description?: string,
    type?: FBDocumentType,
    date?: string,
    mimeType?: string,
    entityClassName?: string,
    entityClassId?: number,
    enabledDate?: string,
    disabledDate?: string,
    creationInstant?: number,
    documentUrl?: string,
    thumbnailUrl?: string,
    agroBusinessPartition?: AgroBusinessPartition
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
    this.date = date || moment().format("YYYY-MM-DD");
    this.mimeType = mimeType;
    this.entityClassName = entityClassName;
    this.entityClassId = entityClassId;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.creationInstant = creationInstant;
    this.documentUrl = documentUrl;
    this.thumbnailUrl = thumbnailUrl;
    this.agroBusinessPartition = agroBusinessPartition;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.description = obj?.description;
    this.type = new FBDocumentType().mapToClass(obj?.type);
    this.date = obj?.date;
    this.mimeType = obj?.mimeType;
    this.entityClassName = obj?.entityClassName;
    this.entityClassId = obj?.entityClassId;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.creationInstant = obj?.creationInstant;
    this.documentUrl = obj?.documentUrl;
    this.thumbnailUrl = obj?.thumbnailUrl;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBDocument;
