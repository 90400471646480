import Facility from "../../agrobusiness/Facility";

class FBFacilityPhytosanitaryTreatmentFacility {
  id?: number;
  facility?: Facility;
  treatedVolume?: number;

  constructor(id?: number, facility?: Facility, treatedVolume?: number) {
    this.id = id;
    this.facility = facility;
    this.treatedVolume = treatedVolume;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.facility = new Facility().mapToClass(obj?.facility);
    this.treatedVolume = obj?.treatedVolume;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.facility = this.facility?.mapToApiModel();
    return obj;
  }
}

export default FBFacilityPhytosanitaryTreatmentFacility;
