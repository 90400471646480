import FBPhytosanitaryTreatmentAdvisor from "../_apiModels/phyto/FBPhytosanitaryTreatmentAdvisor";

class PhytosanitaryTreatmentAdvisor extends FBPhytosanitaryTreatmentAdvisor {
  idx?: number;

  constructor(idx?: number) {
    super();
    this.idx = idx;
  }

  mapToClass(obj: any): PhytosanitaryTreatmentAdvisor | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    obj.validationConfirmed = !!this.validationConfirmed;
    obj.withContract = !!this.withContract;

    // Remove not API fields
    delete obj.idx;
    return obj;
  }
}

export default PhytosanitaryTreatmentAdvisor;
