import FBAddress from "../_apiModels/address/FBAddress";
import Province from "./Province";

class Address extends FBAddress {
  province?: Province;

  constructor(province?: Province) {
    super();
    this.province = province;
  }

  mapToClass(obj: any): Address | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.province = new Province().mapToClass(this.municipality?.province);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.province;
    return obj;
  }
}

export default Address;
