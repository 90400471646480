import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import FacilityType from "../../catalogue/FacilityType";
import TenureType from "../../catalogue/TenureType";

class FBFacility {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  type?: FacilityType;
  name?: string; //*
  description?: string;
  cadastralRef?: string;
  latitude?: string;
  longitude?: string;
  geoRefSystem?: string;
  area?: number;
  storageVolume?: number;
  unitNumber?: number;
  constructionYear?: number;
  tenureType?: TenureType;
  lessorId?: string;
  foreignLessorId?: boolean;
  registerId?: string;
  enabledDate?: string;
  disabledDate?: string;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    type?: FacilityType,
    name?: string,
    description?: string,
    cadastralRef?: string,
    latitude?: string,
    longitude?: string,
    geoRefSystem?: string,
    area?: number,
    storageVolume?: number,
    unitNumber?: number,
    constructionYear?: number,
    tenureType?: TenureType,
    lessorId?: string,
    foreignLessorId?: boolean,
    registerId?: string,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.type = type;
    this.name = name;
    this.description = description;
    this.cadastralRef = cadastralRef;
    this.latitude = latitude;
    this.longitude = longitude;
    this.geoRefSystem = geoRefSystem;
    this.area = area;
    this.storageVolume = storageVolume;
    this.unitNumber = unitNumber;
    this.constructionYear = constructionYear;
    this.tenureType = tenureType;
    this.lessorId = lessorId;
    this.foreignLessorId = foreignLessorId;
    this.registerId = registerId;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.type = new FacilityType().mapToClass(obj?.type);
    this.name = obj?.name;
    this.description = obj?.description;
    this.cadastralRef = obj?.cadastralRef;
    this.latitude = obj?.latitude;
    this.longitude = obj?.longitude;
    this.geoRefSystem = obj?.geoRefSystem;
    this.area = obj?.area;
    this.storageVolume = obj?.storageVolume;
    this.unitNumber = obj?.unitNumber;
    this.constructionYear = obj?.constructionYear;
    this.tenureType = new TenureType().mapToClass(obj?.tenureType);
    this.lessorId = obj?.lessorId;
    this.foreignLessorId = obj?.foreignLessorId;
    this.registerId = obj?.registerId;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.type = this.type?.mapToApiModel();
    obj.tenureType = this.tenureType?.mapToApiModel();
    return obj;
  }
}

export default FBFacility;
