import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import moment from "moment";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import FormikListItemSwitch from "../elements/FormikListItemSwitch";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import AlertEmptyList from "../AlertEmptyList";

import AgroBusinessPhytosanitaryInvolvedEntity from "../../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import PhytosanitaryTreatmentAdvisor from "../../models/phyto/PhytosanitaryTreatmentAdvisor";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

interface DialogTreatmentAdvisorProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (advisor: PhytosanitaryTreatmentAdvisor) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  selected?: PhytosanitaryTreatmentAdvisor | null;
  advisors?: PhytosanitaryTreatmentAdvisor[];
}

const DialogTreatmentAdvisor = (props: DialogTreatmentAdvisorProps) => {
  const YupValidatorSchema = Yup.object().shape({
    advisor: Yup.object().required(),
  });

  const { open, onClose, onConfirm, onError, selected, advisors = [] } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: PhytosanitaryTreatmentAdvisor) => {
    // Find the max idx
    const maxIdx = getMaxValueOfList(advisors, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new PhytosanitaryTreatmentAdvisor(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: involvedEntities, isFetching } = useFetch<
    AgroBusinessPhytosanitaryInvolvedEntity[]
  >({
    queryKey: [
      "phytoAdvisorInvolvedEntities",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNew = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES}`);
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open={open} onClose={closeDialog} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogTreatmentAdvisor.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogTreatmentAdvisor.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetching && involvedEntities?.length === 0 && (
              <AlertEmptyList onClick={handleClickNew}>
                {i18n.t("components.dialogTreatmentAdvisor.emptyListAdvisor")}
              </AlertEmptyList>
            )}
            <FormikAutocomplete
              formik={formik}
              name="advisor"
              label={i18n.t("components.dialogTreatmentAdvisor.advisor")}
              optionLabelFieldName="entity.nameAndSurname"
              required
              options={involvedEntities || []}
              loading={isFetching}
              addNewOption
              onClickNewOption={handleClickNew}
              disabled={!!selected}
            />
          </FormControl>
          <FormControl className="form-control">
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("components.dialogTreatmentAdvisor.validationDate")}
              value={
                formik.values?.validationDate
                  ? moment(formik.values.validationDate, "YYYY-MM-DD")
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue(
                  "validationDate",
                  date?.format("YYYY-MM-DD")
                )
              }
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="validationDetail"
              label={i18n.t(
                "components.dialogTreatmentAdvisor.validationDetail"
              )}
              placeholder={i18n.t(
                "components.dialogTreatmentAdvisor.validationDetailPlaceholder"
              )}
              multiline
            />
          </FormControl>
          <FormControl className="form-control">
            <FormikListItemSwitch
              formik={formik}
              name="validationConfirmed"
              primaryText={i18n.t(
                "components.dialogTreatmentAdvisor.validationConfirmed"
              )}
              secondaryText={i18n.t(
                "components.dialogTreatmentAdvisor.validationConfirmedSubtitle"
              )}
            />
          </FormControl>
          <FormControl className="form-control">
            <FormikListItemSwitch
              formik={formik}
              name="withContract"
              primaryText={i18n.t(
                "components.dialogTreatmentAdvisor.withContract"
              )}
              secondaryText={i18n.t(
                "components.dialogTreatmentAdvisor.withContractSubtitle"
              )}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogTreatmentAdvisor.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogTreatmentAdvisor.redirectAlertDialogContentText"
        )}
        open={isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogTreatmentAdvisor;
