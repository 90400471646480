import moment from "moment";

import FBSiexRequest from "../_apiModels/siex/FBSiexRequest";

class SiexRequest extends FBSiexRequest {
  dateFormatted?: string;

  constructor() {
    super();
  }

  mapToClass(obj: any): SiexRequest | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.requestTimestamp) {
      this.dateFormatted = moment(this.requestTimestamp).format(
        "DD MMMM HH:mm"
      );
    }
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.dateFormatted;
    return obj;
  }
}

export default SiexRequest;
