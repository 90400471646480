import { useEffect, useState } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import moment from "moment";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  FormGroup,
  Collapse,
  FormLabel,
  createFilterOptions,
} from "@mui/material";
import { Search, FileUpload } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import FormikSelect from "../elements/FormikSelect";
import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import LoadingWithDelay from "../elements/LoadingWithDelay";

import Document from "../../models/files/Document";

import { useSession } from "../../hooks/useSession";
import useFetch, { DocumentSearchProps } from "../../hooks/useFetch";

import { FBDocumentType } from "../../models/_apiModels/files/FBDocument";

import { SnackbarInterface } from "../../constants/interfaces";
import { getMaxValueOfList } from "../../helpers/utils";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (file: Document) => void;
  selected?: Document | null;
  files?: Document[];
  onError?: (snackBarError: SnackbarInterface) => void;
}

const DialogFile = (props: Props) => {
  const FileValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
    type: Yup.object().required(),
    date: Yup.string().required(),
  });

  const { open, onClose, onConfirm, onError, selected, files = [] } = props;

  const { isFetching: isUpdating } = useFetch<Document>({
    queryKey: ["document", selected?.id],
    selected,
    refetchOnWindowFocus: false, // Input file not work if true
    enabled: !!selected?.id,
    onError,
  });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: Document) => {
    // Needs id to be unique in the table
    if (!values.idx) {
      const maxIdx = getMaxValueOfList(files, "idx") + 1;
      values.idx = maxIdx;
    }
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new Document(),
    validationSchema: FileValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const handleConfirm = () => {
    formik.submitForm();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <LoadingWithDelay isVisible={isUpdating} />
      <DialogTitle className="title">
        {selected
          ? i18n.t("components.dialogFile.titleEdit")
          : i18n.t("components.dialogFile.titleCreate")}
      </DialogTitle>

      <DialogContent className="content">
        <DialogContentText>
          {selected
            ? i18n.t("components.dialogFile.descriptionEdit")
            : i18n.t("components.dialogFile.descriptionCreate")}
        </DialogContentText>
        <FileForm
          formik={formik}
          files={files}
          selectedEditRow={selected}
          onError={onError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.attach")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFile;

interface FileFormProps {
  formik: FormikProps<Document>;
  selectedEditRow?: Document | null;
  files?: Document[];
  onError?: (snackBarError: SnackbarInterface) => void;
}
const FileForm = (props: FileFormProps) => {
  const { formik, files, selectedEditRow, onError } = props;
  const filter = createFilterOptions();

  const { selectedABAccount, selectedABPartition } = useSession();

  const [isNewFile, setIsNewFile] = useState<boolean>(false);

  // Fetch uncategorized files (FBAgroBusiness entityClassName)
  const params = { entityClassName: "FBAgroBusiness" } as DocumentSearchProps;
  const { data: uncategorizedFiles, isFetching: isFetchingFiles } = useFetch<
    Document[]
  >({
    queryKey: [
      "documents",
      selectedABAccount?.id,
      selectedABPartition?.id,
      params.entityClassName,
    ],
    params,
    refetchOnWindowFocus: false, // Input file not work if true
    enabled: !!selectedABAccount?.id,
    onSuccess: (data) => setIsNewFile(data?.length === 0),
    onError,
  });

  const { data: types, isFetching: isFetchingTypes } = useFetch<
    FBDocumentType[]
  >({
    queryKey: ["documentTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    refetchOnWindowFocus: false, // Input file not work if true
    onError,
  });

  const handleClickUpload = () => {
    const inputElement = document.getElementById("fileInput");
    if (inputElement) inputElement.click();
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      formik.setFieldValue("file", file);
      formik.setFieldValue("name", file.name);
      formik.setFieldValue("mimeType", file.type);
    }
  };

  const handleChangeExistingFile = (file: Document) => {
    formik.setValues(file);
    setIsNewFile(false);
  };

  const handleClickNewFile = () => {
    setIsNewFile(true);
    formik.setValues(new Document());
  };

  const selectedFilesFilter = (options: any[], params: any) => {
    const filtered = filter(options, params).filter(
      (option: any) => !files?.find((f) => f.idx === option.idx)
    );
    filtered.push({ id: "new" });
    return filtered;
  };

  return (
    <FormGroup className="form-group">
      <input
        id="fileInput"
        type="file"
        style={{ display: "none" }}
        onChange={handleChangeFile}
      />
      <LoadingWithDelay isVisible={isFetchingFiles} />
      {!selectedEditRow &&
        uncategorizedFiles &&
        uncategorizedFiles?.length > 0 && (
          <FormControl className="form-control" sx={{ mt: 2 }}>
            <FormikAutocomplete
              formik={formik}
              name="existingFile"
              label={i18n.t("components.dialogFile.existingFile")}
              placeholder={i18n.t(
                "components.dialogFile.existingFilePlaceholder"
              )}
              noOptionsText={i18n.t("formErrors.notFoundResults")}
              options={uncategorizedFiles || []}
              filterOptions={selectedFilesFilter}
              optionLabelFieldName="name"
              startIcon={Search}
              loading={isFetchingFiles}
              onChange={handleChangeExistingFile}
              onClickNewOption={handleClickNewFile}
            />
          </FormControl>
        )}
      <Collapse in={isNewFile || !!selectedEditRow}>
        <div className="grow-container">
          {!selectedEditRow && isNewFile && (
            <FormControl variant="outlined" className="form-control">
              <Button
                variant="contained"
                size="large"
                startIcon={<FileUpload />}
                onClick={handleClickUpload}
              >
                {i18n.t("components.dialogFile.uploadFile")}
              </Button>
            </FormControl>
          )}
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="name"
              label={i18n.t("components.dialogFile.name")}
              required
            />
          </FormControl>
          {/*  TODO: RE-RENDERS         <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="description"
              label={i18n.t("components.dialogFile.description")}
              multiline
            />
          </FormControl> */}
          <FormControl variant="outlined" className="form-control">
            <FormikSelect
              className="form-input"
              formik={formik}
              name="type"
              label={i18n.t("components.dialogFile.type")}
              required
              options={types || []}
              optionLabelFieldName="name"
              isLoading={isFetchingTypes}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control" required>
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("components.dialogFile.date")}
              value={
                formik.values?.date
                  ? moment(formik.values.date, "YYYY-MM-DD")
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue("date", date?.format("YYYY-MM-DD"))
              }
            />
            {formik.touched.date && formik.errors.date && (
              <FormLabel className="form-label">
                {i18n.t("formErrors.requiredField")}
              </FormLabel>
            )}
          </FormControl>
        </div>
      </Collapse>
    </FormGroup>
  );
};
