import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import {
  FormGroup,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Box,
  TextField,
  InputAdornment,
  useMediaQuery,
  Collapse,
  Typography,
  FormHelperText,
} from "@mui/material";
import { ArrowDropDown, Search } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import TestBanner from "../components/banners/TestBanner";
import { BrioCard } from "../components/BrioCard";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import RightDrawer from "../components/RightDrawer";

import { useAuth } from "../hooks/useAuth";
import useCrud from "../hooks/useCrud";
import useFetch from "../hooks/useFetch";
import { useSession } from "../hooks/useSession";

import CueAccount from "../models/cueAccount/CueAccount";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { LANGUAGE_LIST } from "../constants/lists";
import { LanguageInterface, SnackbarInterface } from "../constants/interfaces";
import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../constants/constants";
import { styles } from "../components/elements/FormikSelect";
import { helperTextStyle } from "../components/elements/FormikTextField";

const SettingsScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  const handleOnMessage = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <Box sx={{ mt: isLargeScreen ? "8px" : 0 }}>
        <AccountForm onMessage={handleOnMessage} />
        <AdvisorForm onMessage={handleOnMessage} />
      </Box>
    </ScreenContentLayout>
  );
};

export default SettingsScreen;

interface FormProps {
  onMessage?: (snackBarError: SnackbarInterface) => void;
}
const AccountForm = (props: FormProps) => {
  const { onMessage } = props;

  const { user } = useAuth();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageInterface | null>(null);

  useEffect(() => {
    const userLanguageCode = user?.person?.language || i18n.language;
    if (userLanguageCode && userLanguageCode !== selectedLanguage?.code) {
      setSelectedLanguage(
        LANGUAGE_LIST.find((lng) => lng.code === userLanguageCode) || null
      );
    }
  }, [user]);

  const updateUserLanguageMutation = useCrud({
    key: "putPersonLanguage",
    onSuccess: () => navigate(0),
    onError: onMessage,
  });

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const code = event.target.value;
    updateUserLanguageMutation.mutate(code);
  };

  return (
    <BrioCard title={i18n.t("words.account")} defaultExpanded disableAccordion>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <TextField
            name="email"
            disabled
            className="form-input"
            variant="outlined"
            type="email"
            label="Email"
            value={user?.person?.email || ""}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <InputLabel>{i18n.t("words.language")}</InputLabel>
          <Select
            label={i18n.t("words.language")}
            value={selectedLanguage?.code || ""}
            disabled={updateUserLanguageMutation.isLoading}
            IconComponent={() =>
              updateUserLanguageMutation.isLoading ? (
                <CircularProgress size={20} sx={{ mr: 2 }} />
              ) : (
                <ArrowDropDown sx={{ mr: 2, color: "grey" }} />
              )
            }
            onChange={handleChangeLanguage}
          >
            {LANGUAGE_LIST.map((lng) => (
              <MenuItem key={lng.id} value={lng.code}>
                {lng.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

let searchWordTimeout: NodeJS.Timeout | null;
const AdvisorForm = (props: FormProps) => {
  const { onMessage } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, changeSelectedABA } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [selectedAdvisorCueAccount, setSelectedAdvisorCueAccount] =
    useState<CueAccount | null>(null);
  const [hasAdvisor, setHasAdvisor] = useState<boolean>(false);

  useEffect(() => {
    setHasAdvisor(!!selectedABAccount?.cueAccount?.advisor);
  }, [selectedABAccount]);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const openDrawer = () => {
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    navigate(-1);
  };

  const moveToAdvisorAccountMutation = useCrud<any>({
    key: "moveToAdvisorCueAccount",
    values: {},
    onSuccess: (data: AgroBusinessAccount) => {
      closeDrawer();
      changeSelectedABA(data);
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.moveToAdvisorCueAccountSuccess"),
        });
    },
    onError: onMessage,
  });

  const handleClickSave = () => {
    const cueAccountId = selectedAdvisorCueAccount?.id;
    if (cueAccountId) {
      moveToAdvisorAccountMutation.mutate(cueAccountId);
    } else {
      onMessage &&
        onMessage({
          severity: "warning",
          message: i18n.t("formErrors.notSelectedAdvisorCueAccounts"),
        });
    }
  };

  return (
    <BrioCard
      title={i18n.t("settings.exportCueAccountForm.title")}
      headerText={
        !hasAdvisor
          ? i18n.t("settings.exportCueAccountForm.headerTextEmpty")
          : undefined
      }
      addBtnVariant="contained"
      rightBtnTitle={
        hasAdvisor
          ? i18n.t("settings.exportCueAccountForm.actionBtnTextEdit")
          : i18n.t("settings.exportCueAccountForm.actionBtnText")
      }
      actionBtn
      rightIcon={hasAdvisor ? <></> : undefined}
      disableAccordion
      defaultExpanded
      onClickAdd={openDrawer}
    >
      <FormGroup className="form-group">
        {hasAdvisor && (
          <FormControl variant="outlined" className="form-control">
            <TextField
              name="name"
              disabled
              className="form-input"
              variant="outlined"
              type="text"
              label={i18n.t("settings.exportCueAccountForm.nameLabel")}
              value={selectedABAccount?.cueAccount?.name || ""}
            />
          </FormControl>
        )}
      </FormGroup>
      <RightDrawer
        title={
          hasAdvisor
            ? i18n.t("settings.exportCueAccountForm.drawerTitleEdit")
            : i18n.t("settings.exportCueAccountForm.drawerTitle")
        }
        titleBtn={
          hasAdvisor
            ? i18n.t("settings.exportCueAccountForm.drawerBtnTextEdit")
            : i18n.t("settings.exportCueAccountForm.drawerBtnText")
        }
        isOpen={isOpenDrawer}
        iconBtn
        isLoading={moveToAdvisorAccountMutation.isLoading}
        onClose={closeDrawer}
        onConfirm={handleClickSave}
      >
        <ExportCueAccountForm
          selectedAdvisorCueAccount={selectedAdvisorCueAccount}
          onChangeSelectedAdvisorCueAccount={setSelectedAdvisorCueAccount}
          onError={onMessage}
        />
      </RightDrawer>
    </BrioCard>
  );
};

interface ExportCueAccountFormProps {
  selectedAdvisorCueAccount: CueAccount | null;
  onChangeSelectedAdvisorCueAccount: (
    advisorCueAccount: CueAccount | null
  ) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
interface FormErrors {
  email: boolean | string;
  advisorCueAccount: boolean | string;
  notFoundAdvisorCueAccounts: boolean | string;
}
const ExportCueAccountForm = (props: ExportCueAccountFormProps) => {
  const EmailValidatorSchema = Yup.object({
    email: Yup.string().email().required(),
  });

  const {
    selectedAdvisorCueAccount,
    onChangeSelectedAdvisorCueAccount,
    onError,
  } = props;

  const [searchWord, setSearchWord] = useState<string>("");
  const [advisorEmail, setAdvisorEmail] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({
    email: false,
    advisorCueAccount: false,
    notFoundAdvisorCueAccounts: false,
  });

  const handleFoundAdvisorCueAccounts = (
    foundAdvisorCueAccounts: CueAccount[]
  ) => {
    onChangeSelectedAdvisorCueAccount(
      foundAdvisorCueAccounts.length === 1 ? foundAdvisorCueAccounts[0] : null
    );
  };

  const { data: foundAdvisorAccounts, isFetching: isSearching } = useFetch<
    CueAccount[]
  >({
    queryKey: ["listByAdvisorEmail", advisorEmail],
    enabled: EmailValidatorSchema.isValidSync({ email: advisorEmail }),
    selected: advisorEmail,
    onSuccess: handleFoundAdvisorCueAccounts,
    onError,
  });

  useEffect(() => {
    setFormErrors({
      email:
        searchWord.length > 0 &&
        !EmailValidatorSchema.isValidSync({ email: searchWord })
          ? i18n.t("formErrors.invalidEmail")
          : false,
      advisorCueAccount:
        !isSearching && !selectedAdvisorCueAccount ? i18n.t("") : false,
      notFoundAdvisorCueAccounts:
        !isSearching &&
        EmailValidatorSchema.isValidSync({ email: searchWord }) &&
        (!foundAdvisorAccounts || foundAdvisorAccounts.length === 0)
          ? i18n.t("formErrors.notFoundAdvisorCueAccounts")
          : false,
    });
  }, [
    isSearching,
    advisorEmail,
    selectedAdvisorCueAccount,
    searchWord,
    foundAdvisorAccounts,
  ]);

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || "";
    setSearchWord(value);
    if (searchWordTimeout) {
      clearTimeout(searchWordTimeout);
      searchWordTimeout = null;
    }
    searchWordTimeout = setTimeout(() => {
      const searchWord = value?.trim();
      if (EmailValidatorSchema.isValidSync({ email: searchWord })) {
        setAdvisorEmail(searchWord);
      }
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeAdvisorCueAccount = (event: SelectChangeEvent) => {
    const advisorId = event.target.value;
    const advisorCueAccount = foundAdvisorAccounts?.find(
      (acc) => acc.id?.toString() === advisorId
    );
    onChangeSelectedAdvisorCueAccount(advisorCueAccount || null);
  };

  return (
    <BrioCard
      title={i18n.t("settings.exportCueAccountForm.advisorSearch")}
      defaultExpanded
      disableAccordion
    >
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <TextField
            className="form-input"
            variant="outlined"
            type="email"
            label={i18n.t("settings.exportCueAccountForm.advisorEmail")}
            placeholder={i18n.t(
              "settings.exportCueAccountForm.advisorEmailPlaceholder"
            )}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: isSearching ? (
                <CircularProgress color="primary" size={20} sx={{ mr: 2 }} />
              ) : null,
            }}
            helperText={
              formErrors.email || formErrors.notFoundAdvisorCueAccounts || ""
            }
            FormHelperTextProps={{
              style: {
                ...helperTextStyle,
                color: formErrors.email ? "#c62828" : "",
              },
            }}
            value={searchWord}
            error={
              !!formErrors.email || !!formErrors.notFoundAdvisorCueAccounts
            }
            onChange={handleChangeEmail}
          />
          <Collapse
            className="collapse-container"
            in={
              !isSearching &&
              foundAdvisorAccounts &&
              foundAdvisorAccounts.length > 0
            }
          >
            <div className="grow-container">
              <FormControl
                className="form-control"
                sx={{ marginTop: "20px !important" }}
              >
                <InputLabel>
                  {i18n.t("settings.exportCueAccountForm.advisorCueAccount")}
                </InputLabel>
                <Select
                  label={i18n.t(
                    "settings.exportCueAccountForm.advisorCueAccount"
                  )}
                  value={selectedAdvisorCueAccount?.id?.toString() || ""}
                  disabled={isSearching}
                  IconComponent={() =>
                    isSearching ? (
                      <CircularProgress size={20} sx={{ mr: 2 }} />
                    ) : (
                      <ArrowDropDown sx={{ mr: 2, color: "grey" }} />
                    )
                  }
                  onChange={handleChangeAdvisorCueAccount}
                >
                  {foundAdvisorAccounts?.map((option) => (
                    <MenuItem key={option.id} value={option.id?.toString()}>
                      <Typography variant="subtitle1" overflow="auto">
                        {option.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  style={{
                    ...styles.formHelperText,
                    color: formErrors.advisorCueAccount ? "#c62828" : "",
                  }}
                  error={!!formErrors.advisorCueAccount}
                >
                  {formErrors.advisorCueAccount
                    ? i18n.t("formErrors.selectAtLeastOption")
                    : i18n.t(
                        "settings.exportCueAccountForm.advisorCueAccountHelperText"
                      )}
                </FormHelperText>
              </FormControl>
            </div>
          </Collapse>
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
