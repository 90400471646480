import CropPhytosanitaryTreatment from "../../phyto/CropPhytosanitaryTreatement";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import Tool from "../../agrobusiness/Tool";

class FBPhytosanitaryTreatmentApplicator {
  id?: number;
  cropTreatment?: CropPhytosanitaryTreatment; //*
  applicator?: AgroBusinessPhytosanitaryInvolvedEntity; //*
  tools?: Tool[];

  constructor(
    id?: number,
    cropTreatment?: CropPhytosanitaryTreatment,
    applicator?: AgroBusinessPhytosanitaryInvolvedEntity,
    tools?: Tool[]
  ) {
    this.id = id;
    this.cropTreatment = cropTreatment;
    this.applicator = applicator;
    this.tools = tools;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.cropTreatment = new CropPhytosanitaryTreatment().mapToClass(
      obj?.cropTreatment
    );
    this.applicator = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.applicator
    );
    this.tools =
      obj?.tools?.map((tool: any) => new Tool().mapToClass(tool)) || [];
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.cropTreatment = this.cropTreatment?.mapToApiModel();
    obj.applicator = this.applicator?.mapToApiModel();
    obj.tools = this.tools?.map((tool: Tool) => tool.mapToApiModel());
    return obj;
  }
}

export default FBPhytosanitaryTreatmentApplicator;
