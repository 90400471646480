import i18n from "../../config/configI18n";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { SectionTutorialInterface } from "../../constants/interfaces";

interface Props {
  steps: SectionTutorialInterface[];
  activeStep: number;
  onGo?: () => void;
  onNext?: () => void;
  onBack?: () => void;
}
const SectionTutorial = (props: Props) => {
  const { steps, activeStep, onGo, onNext, onBack } = props;

  return (
    <Box sx={{ m: "12px", pl: "4px", pd: "4px" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} completed={step.completed}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant="contained"
                    onClick={onGo}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {step.nextBtnText || i18n.t("words.continue")}
                  </Button>
                  {step.completed && index < steps.length - 1 && (
                    <Button
                      disabled={index === steps.length - 1}
                      onClick={onNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {i18n.t("words.next")}
                    </Button>
                  )}
                  <Button
                    disabled={index === 0}
                    onClick={onBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {i18n.t("words.back")}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default SectionTutorial;
