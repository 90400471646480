import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import FormikSelect from "../elements/FormikSelect";
import FormikTextField from "../elements/FormikTextField";

import ToolInspection from "../../models/agrobusiness/ToolInspection";

import { getMaxValueOfList } from "../../helpers/utils";

import { TOOL_INSPECTION_RESULT_LIST } from "../../providers/YupProvider";

interface DialogToolInspectionProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (toolInspection: ToolInspection) => void;
  selected?: ToolInspection | null;
  inspections?: ToolInspection[];
}

const DialogToolInspection = (props: DialogToolInspectionProps) => {
  const ToolInspectionValidatorSchema = Yup.object().shape({
    resultType: Yup.object().required(),
    date: Yup.string().required(),
  });

  const { open, onClose, onConfirm, selected, inspections = [] } = props;

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: ToolInspection) => {
    // Find the max idx of the inspections
    const maxIdx = getMaxValueOfList(inspections, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new ToolInspection(),
    validationSchema: ToolInspectionValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open={open} onClose={closeDialog} className="dialog">
      <DialogTitle className="title">
        {selected
          ? i18n.t("components.dialogToolInspection.titleEdit")
          : i18n.t("components.dialogToolInspection.titleCreate")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl className="form-control">
            <FormikSelect
              formik={formik}
              name="resultType"
              label={i18n.t("components.dialogToolInspection.resultType")}
              optionLabelFieldName="name"
              required
              options={TOOL_INSPECTION_RESULT_LIST}
            />
          </FormControl>
          <FormControl className="form-control">
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("components.dialogToolInspection.date")}
              value={
                formik.values.date
                  ? moment(formik.values.date, "YYYY-MM-DD")
                  : null
              }
              onChange={(date) =>
                formik.setFieldValue("date", date?.format("YYYY-MM-DD"))
              }
            />
            {formik.touched.date && formik.errors.date && (
              <FormLabel className="form-label">
                {i18n.t("formErrors.requiredField")}
              </FormLabel>
            )}
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="comments"
              label={i18n.t("components.dialogToolInspection.comments")}
              multiline
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogToolInspection;
