import CropProduct from "../../catalogue/CropProduct";

class FBVadeCrop {
  id?: number;
  code?: string;
  name?: string;
  latinName?: string;
  eppoCode?: string;
  products?: CropProduct[];

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.latinName = obj?.latinName;
    this.eppoCode = obj?.eppoCode;
    this.products = obj?.products?.map((product: any) =>
      new CropProduct().mapToClass(product)
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.products = this.products?.map((product) => product.mapToApiModel());
    return obj;
  }
}

export default FBVadeCrop;
