import PhytosanitaryTreatment from "../../phyto/PhytosanitaryTreatment";
import PhytosanitaryTreatmentApplicator from "../../phyto/PhytosanitaryTreatmentApplicator";
import PhytosanitaryTreatmentAdvisor from "../../phyto/PhytosanitaryTreatmentAdvisor";
import FacilityPhytosanitaryTreatmentFacility from "../../phyto/FacilityPhytosanitaryTreatmentFacility";

class FBFacilityPhytosanitaryTreatment extends PhytosanitaryTreatment {
  id?: number;
  facilities?: FacilityPhytosanitaryTreatmentFacility[];
  applicators?: PhytosanitaryTreatmentApplicator[];
  advisors?: PhytosanitaryTreatmentAdvisor[];

  constructor(
    id?: number,
    facilities?: FacilityPhytosanitaryTreatmentFacility[],
    applicators?: PhytosanitaryTreatmentApplicator[],
    advisors?: PhytosanitaryTreatmentAdvisor[]
  ) {
    super();
    this.id = id;
    this.facilities = facilities;
    this.applicators = applicators;
    this.advisors = advisors;
  }

  mapToBaseClass(obj: any): void {
    super.mapToClass(obj);
    this.id = obj?.id;
    this.facilities =
      obj?.facilities?.map((facility: any) =>
        new FacilityPhytosanitaryTreatmentFacility().mapToClass(facility)
      ) || [];
    this.applicators = obj?.applicators?.map((applicator: any) =>
      new PhytosanitaryTreatmentApplicator().mapToClass(applicator)
    );
    this.advisors = obj?.advisors?.map((advisor: any) =>
      new PhytosanitaryTreatmentAdvisor().mapToClass(advisor)
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = super.mapToApiModel();
    obj.facilities = this.facilities?.map(
      (facility: FacilityPhytosanitaryTreatmentFacility) =>
        facility.mapToApiModel()
    );
    obj.applicators = this.applicators?.map(
      (applicator: PhytosanitaryTreatmentApplicator) =>
        applicator.mapToApiModel()
    );
    obj.advisors = this.advisors?.map(
      (advisor: PhytosanitaryTreatmentAdvisor) => advisor.mapToApiModel()
    );
    return obj;
  }
}

export default FBFacilityPhytosanitaryTreatment;
