import moment from "moment";

import FBPhytosanitaryProduct from "../_apiModels/phyto/FBPhytosanitaryProduct";
import Document from "../files/Document";

export const MEASUREMENT_UNIT_CODES = [
  "3",
  "4",
  "5",
  "6",
  "11",
  "25",
  "26",
  "27",
];

class PhytosanitaryProduct extends FBPhytosanitaryProduct {
  isExpired?: boolean;
  expirationDateFormatted?: string;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(documents?: Document[]) {
    super();
    this.documents = documents;
  }

  mapToClass(obj: any): PhytosanitaryProduct | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.isExpired = obj?.expirationDate
      ? moment(obj.expirationDate, "YYYY-MM-DD").isBefore(moment())
      : false;
    if (obj?.expirationDate)
      this.expirationDateFormatted = moment(
        obj.expirationDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove custom variables
    delete obj.isExpired;
    delete obj.expirationDateFormatted;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isRegistered(): boolean {
    return this.type?.code === "1";
  }

  isExceptional(): boolean {
    return this.type?.code === "4";
  }
}

export default PhytosanitaryProduct;
