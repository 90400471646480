export const PUBLIC_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  EMAIL_VERIFICATION: "/email-verification",
  WHATSAPP_VERIFICATION: "/whatsapp-verification",
  POLICIES: "/policies",
};

export const PROTECTED_ROUTES = {
  ONBOARDING: "onboarding",
  DASHBOARD: "exploitation/dashboard",
  EXPLOITATIONS_PERMISSIONS: "exploitation/permissions",
  EXPLOITATION_PARTITIONS: "exploitation/partitions",
  EXPLOITATION_DATA: "exploitation/data",
  EXPLOITATION_LEADERSHIP: "exploitation/leadership",
  EXPLOITATION_CESSATION: "exploitation/cessation",
  EXPLOITATION_FACILITIES: "exploitation/facilities",
  EXPLOITATION_TOOLS: "exploitation/tools",
  EXPLOITATION_WATER_SOURCES: "exploitation/water-sources",
  EXPLOITATION_SECTORS: "exploitation/sectors",
  EXPLOITATION_CROPS: "exploitation/crops",
  EXPLOITATION_INVOLVED_ENTITIES: "exploitation/involved-entities",
  EXPLOITATION_TREATMENTS: "exploitation/treatments",
  EXPLOITATION_PLUVIOMETRY: "exploitation/pluviometry",
  EXPLOITATION_ANALYSIS: "exploitation/analysis",
  EXPLOITATION_STOCK_PHYTOSANITARY: "exploitation/stock/phytosanitary",
  EXPLOITATION_STOCK_FERTILIZER: "exploitation/stock/fertilizer",
  EXPLOITATION_IRRIGATIONS: "exploitation/irrigations",
  EXPLOITATION_FERTILIZATION: "exploitation/fertilization",
  EXPLOITATION_FERTILIZATION_PLANS: "exploitation/fertilization-plans",
  EXPLOITATION_FERTILIZATION_TANKS: "exploitation/fertilization-tanks",
  EXPLOITATION_FILES: "exploitation/files",
  EXPLOITATION_OFFERS: "exploitation/offers",
  EXPLOITATION_RECIPES: "exploitation/recipes",
  EXPLOITATION_EXPORT_DATA: "exploitation/export",
  EXPLOITATION_SIEX_ERRORS: "exploitation/siex-errors",
  SETTINGS: "settings",
  VADEMECUM_PHYTO: "vademecum/phyto",
  VADEMECUM_FERTILIZER: "vademecum/fertilizer",
  ACCOUNT_SYNC: "account-sync",
  SIEX_ADVISOR_REDIRECT:
    "siex/redirect/:agroBusinessId/:entityClassId/:entityClassName",
};

export const SAVED_ROUTES = {
  DASHBOARD: "exploitation/dashboard",
  EXPLOITATION_PARTITIONS: "exploitation/partitions",
  EXPLOITATION_DATA: "exploitation/data",
  EXPLOITATION_LEADERSHIP: "exploitation/leadership",
  EXPLOITATION_FACILITIES: "exploitation/facilities",
  EXPLOITATION_TOOLS: "exploitation/tools",
  EXPLOITATION_WATER_SOURCES: "exploitation/water-sources",
  EXPLOITATION_SECTORS: "exploitation/sectors",
  EXPLOITATION_CROPS: "exploitation/crops",
  EXPLOITATION_INVOLVED_ENTITIES: "exploitation/involved-entities",
  EXPLOITATION_TREATMENTS: "exploitation/treatments",
  EXPLOITATION_PLUVIOMETRY: "exploitation/pluviometry",
  EXPLOITATION_ANALYSIS: "exploitation/analysis",
  EXPLOITATION_STOCK_PHYTOSANITARY: "exploitation/stock/phytosanitary",
  EXPLOITATION_STOCK_FERTILIZER: "exploitation/stock/fertilizer",
  EXPLOITATION_IRRIGATIONS: "exploitation/irrigations",
  EXPLOITATION_FERTILIZATION: "exploitation/fertilization",
  EXPLOITATION_FERTILIZATION_PLANS: "exploitation/fertilization-plans",
  EXPLOITATION_FERTILIZATION_TANKS: "exploitation/fertilization-tanks",
  EXPLOITATION_FILES: "exploitation/files",
  EXPLOITATION_OFFERS: "exploitation/offers",
  EXPLOITATION_RECIPES: "exploitation/recipes",
  SETTINGS: "settings",
  VADEMECUM_PHYTO: "vademecum/phyto",
  VADEMECUM_FERTILIZER: "vademecum/fertilizer",
  EXPLOITATION_EXPORT_DATA: "exploitation/export",
};
