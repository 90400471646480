class FBToolInspection {
  id?: number;
  date?: string; //*
  result?: string; //*
  comments?: string;

  constructor(id?: number, date?: string, result?: string, comments?: string) {
    this.id = id;
    this.date = date;
    this.result = result;
    this.comments = comments;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.date = obj?.date;
    this.result = obj?.result;
    this.comments = obj?.comments;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBToolInspection;
