import { PersonPermissionRoleInterface } from "../../constants/interfaces";
import { PERSON_PERMISSION_ROLES_LIST } from "../../providers/YupProvider";
import FBPersonWithRole from "../_apiModels/account/FBPersonWithRole";

class PersonWithRole extends FBPersonWithRole {
  idx?: number;
  personRole?: PersonPermissionRoleInterface;

  constructor(idx?: number, personRole?: PersonPermissionRoleInterface) {
    super();
    this.idx = idx;
    this.personRole = personRole;
  }

  mapToClass(obj: any): PersonWithRole | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = obj?.person?.id;
    this.personRole = PERSON_PERMISSION_ROLES_LIST.find(
      (personRole) => personRole.role === obj.role
    );
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.role = this.personRole?.role;

    // Delete variables of this class
    delete obj.idx;
    delete obj.personRole;
    return obj;
  }
}

export default PersonWithRole;
