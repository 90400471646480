import { createContext, useState, useMemo, useContext, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useCookies } from "react-cookie";
import moment from "moment";
import ReactGA from "react-ga4";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";

import UserData from "../models/UserData";
import Person from "../models/Person";

import { FBPersonRole } from "../constants/enums";

interface Auth {
  user: UserData | null;
  idToken: string | undefined;
  setIdToken: (idToken: string | undefined) => void;
  setUserRole: (role: FBPersonRole) => void;
  setUserPerson: (person: Person) => void;
  saveRefreshToken: (refreshToken: string) => void;
  login: (userData: UserData) => void;
  logout: () => void;
}
const AuthContext = createContext<Auth>({} as Auth);

interface Props {
  children: React.ReactNode;
  userData: UserData | null;
}
export const AuthProvider = ({ children, userData }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [user, setUser] = useState<UserData | null>(userData);
  // Should be replace by user?.idToken
  const [idToken, setIdToken] = useState<string | undefined>(userData?.idToken);

  const removeAllCookies = () => {
    const cookieNames = Object.keys(cookies);
    cookieNames.forEach((cookieName) => {
      removeCookie(cookieName, { path: "/" });
    });
  };

  // Update user language
  useEffect(() => {
    try {
      const userLanguage = user?.person?.language;
      if (userLanguage) {
        i18n.changeLanguage(userLanguage); // Strings
        moment.locale(userLanguage); // Dates
        document.documentElement.lang = userLanguage; // HTML lang attribute
        document.title = i18n.t("browserTabTitle"); // Set tab title
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [user]);

  const login = (userData: UserData) => {
    setUser(userData);
    setIdToken(userData.idToken);

    // Send event to GA4
    ReactGA.event("login", { method: userData.authProvider });
  };

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
    setUser(null);
    setIdToken(undefined);
    removeAllCookies();

    // Remove user from Sentry
    Sentry.setUser(null);
  };

  const setUserRole = (role: FBPersonRole) => {
    if (user) setUser({ ...user, role } as UserData);
  };

  const setUserPerson = (person: Person) => {
    if (user) setUser({ ...user, person } as UserData);
  };

  const saveRefreshToken = (refreshToken: string) => {
    setCookie("refreshToken", refreshToken, { path: "/" });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      idToken,
      setIdToken,
      setUserRole,
      setUserPerson,
      saveRefreshToken,
    }),
    [user, idToken, setIdToken, setUserRole, setUserPerson, saveRefreshToken]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): Auth => {
  return useContext<Auth>(AuthContext);
};
