import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import { FormControl, FormGroup, Collapse } from "@mui/material";
import { Business } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";
import FacilityType from "../../models/catalogue/FacilityType";
import Facility from "../../models/agrobusiness/Facility";
import TenureType from "../../models/catalogue/TenureType";
import FacilitySuperType from "../../models/catalogue/FacilitySuperType";
import Document from "../../models/files/Document";

import { updateItemOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import SiexDownloadStatusBanner from "../../components/banners/SiexDownloadStatusBanner";

const ExploitationFacilityScreen = () => {
  const FacilityValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
    type: Yup.object()
      .shape({
        code: Yup.string().required(),
        superType: Yup.object().required(),
      })
      .required(),
  });

  const facilityColumns: Column[] = [
    { id: "name", label: i18n.t("facility.column.name"), numeric: false },
    {
      id: "area",
      label: i18n.t("facility.column.area"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "m2",
    },
    {
      id: "unitNumber",
      label: i18n.t("facility.column.unitNumber"),
      numeric: true,
    },
    {
      id: "storageVolume",
      label: i18n.t("facility.column.storageVolume"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "m3",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: facilities, isFetching } = useFetch<Facility[]>({
    queryKey: ["facilities", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? facilities?.find((f: Facility) => f.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, facilities]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );
  const isSelectedSiexSync = useMemo(
    () => selected?.siexEntity === true,
    [selected]
  );

  const handleSubmit = (values: Facility) => {
    switch (formMode) {
      case FormMode.CREATE:
        facilityCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        facilityEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new Facility(),
    validationSchema: FacilityValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("facility.drawerTitleCreate")
      : i18n.t("facility.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const facilityCreateMutation = useCrud<Facility>({
    key: "postFacility",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const facilityEditMutation = useCrud<Facility>({
    key: "putFacility",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const facilityDeleteMutation = useCrud({
    key: "deleteFacilities",
    values: facilities?.filter((f: Facility) =>
      selectedRowIds.includes(f?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    facilityDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <SiexBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("facility.backAlertTitle")}
        contentText={i18n.t("facility.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("facility.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("facility.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={facilityDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("facility.tableTitle")}
        headerText={i18n.t("facility.headerText")}
        colums={facilityColumns}
        rows={facilities}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Business sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("facility.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("facility.emptyTableSubtitleSIEX")
            : i18n.t("facility.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("facility.emptyTableBtnText")
            : undefined
        }
        isLoading={isFetching}
        addBtnVariant="contained"
        siexRows
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          facilityCreateMutation.isLoading || facilityEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <FacilityForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          isSelectedSiexSync={isSelectedSiexSync}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationFacilityScreen;

interface FacilityFormProps {
  formik: FormikProps<Facility>;
  isSiexActive: boolean;
  isSelectedSiexSync: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: Facility;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const FacilityForm = (props: FacilityFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    isSiexActive,
    isSelectedSiexSync,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<Facility>({
    queryKey: ["facility", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: Facility) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<Facility[]>(
        ["facilities", selectedABPartition?.id, selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive ? (
        <SiexDownloadStatusBanner
          isSync={isSelectedSiexSync}
          timestamp={selectedEditRow?.siexUpdatedOn}
        />
      ) : (
        <SiexBanner adviceText />
      )}
      <DefinitionForm {...props} />
      <DataForm {...props} />
      <DimensForm {...props} />
      <LocationForm {...props} />
      <TenureForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("facility.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const DefinitionForm = (props: FacilityFormProps) => {
  const { formik, forceExpanded } = props;

  return (
    <BrioCard
      title={i18n.t("facility.definitionForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("facility.definitionForm.name")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="description"
            label={i18n.t("facility.definitionForm.description")}
            multiline
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const DataForm = (props: FacilityFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: facilitySuperTypes, isFetching: isFetchingFacilitySuperTypes } =
    useFetch<FacilitySuperType[]>({
      queryKey: ["facilitySuperTypes", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const { data: facilityTypes, isFetching: isFetchingFacilityTypes } = useFetch<
    FacilityType[]
  >({
    queryKey: [
      "facilityTypesBySuperTypeCode",
      formik.values?.type?.superType?.code,
    ],
    selected: formik.values?.type?.superType?.code,
    enabled: !!formik.values?.type?.superType?.code,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("facility.dataForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="type.superType"
            label={i18n.t("facility.dataForm.superType")}
            optionLabelFieldName="name"
            options={facilitySuperTypes || []}
            required
            isLoading={isFetchingFacilitySuperTypes}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={!!formik.values.type?.superType}
          sx={{ marginBottom: !!formik.values.type?.superType ? "20px" : 0 }}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="type"
                label={i18n.t("facility.dataForm.type")}
                optionLabelFieldName="name"
                options={facilityTypes || []}
                required
                isLoading={isFetchingFacilityTypes}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="cadastralRef"
            label={i18n.t("facility.dataForm.cadastralRef")}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="YYYY"
            label={i18n.t("facility.dataForm.constructionYear")}
            view="year"
            views={["year"]}
            disableFuture
            closeOnSelect
            value={
              formik.values.constructionYear
                ? moment(formik.values.constructionYear, "YYYY")
                : null
            }
            disabled={isSelectedSiexSync}
            onChange={(date) =>
              formik.setFieldValue("constructionYear", date?.year())
            }
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const DimensForm = (props: FacilityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <BrioCard title={i18n.t("facility.dimensForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="area"
            type="number"
            label={i18n.t("facility.dimensForm.area")}
            valueUnit="m2"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="unitNumber"
            type="number"
            label={i18n.t("facility.dimensForm.unitNumber")}
            placeholder={i18n.t("facility.dimensForm.unitNumberPlaceholder")}
            helperText={i18n.t("facility.dimensForm.unitNumberHelperText")}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="storageVolume"
            type="number"
            label={i18n.t("facility.dimensForm.storageVolume")}
            placeholder={i18n.t("facility.dimensForm.storageVolumePlaceholder")}
            helperText={i18n.t("facility.dimensForm.storageVolumeHelperText")}
            valueUnit="m3"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const LocationForm = (props: FacilityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <BrioCard title={i18n.t("facility.locationForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="latitude"
            label={i18n.t("facility.locationForm.latitude")}
            type="number"
            disabled={isSelectedSiexSync}
          />
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="longitude"
            label={i18n.t("facility.locationForm.longitude")}
            type="number"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TenureForm = (props: FacilityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const { selectedABAccount } = useSession();

  const { data: tenureTypes, isFetching } = useFetch<TenureType[]>({
    queryKey: ["tenureTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
  });

  return (
    <BrioCard title={i18n.t("facility.tenureForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="tenureType"
            label={i18n.t("facility.tenureForm.tenureType")}
            optionLabelFieldName="name"
            options={tenureTypes || []}
            isLoading={isFetching}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <Collapse in={formik.values.isLessor()}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="lessorId"
                label={i18n.t("facility.tenureForm.lessorId")}
                placeholder={i18n.t("facility.tenureForm.lessorIdPlaceholder")}
                helperText={i18n.t("facility.tenureForm.lessorIdHelperText")}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl className="form-control">
              <FormikListItemSwitch
                formik={formik}
                name="foreignLessorId"
                primaryText={i18n.t("facility.tenureForm.foreignLessorId")}
                secondaryText={i18n.t(
                  "facility.tenureForm.foreignLessorIdSecondaryText"
                )}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};
