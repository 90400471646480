class FBCountry {
  id?: number;
  code?: string; //*
  name?: string; //*
  alpha2Code?: string;
  alpha3Code?: string;
  populated?: boolean;

  constructor(
    id?: number,
    code?: string,
    name?: string,
    alpha2Code?: string,
    alpha3Code?: string,
    populated?: boolean
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.alpha2Code = alpha2Code;
    this.alpha3Code = alpha3Code;
    this.populated = populated;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.code = obj?.code;
    this.name = obj?.name;
    this.alpha2Code = obj?.alpha2Code;
    this.alpha3Code = obj?.alpha3Code;
    this.populated = obj?.populated;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBCountry;
