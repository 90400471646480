import FBVadeAgent from "../_apiModels/vademecum/FBVadeAgent";

class VadeAgent extends FBVadeAgent {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default VadeAgent;
