import { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  Divider,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

import { ExpandMore, ExpandLess } from "@mui/icons-material";

import { MenuOption } from "../constants/interfaces";
import { SHORTCUTS_LIST } from "../providers/YupProvider";

interface Props {
  open: boolean;
  onChange?: (open: boolean) => void;
}
const ShortcutsMenu = (props: Props) => {
  const { open, onChange } = props;

  const handleClickMenu = () => onChange && onChange(!open);

  const handleClickMenuOption = () => onChange && onChange(false);

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      swipeAreaWidth={0}
      onOpen={() => onChange && onChange(true)}
      onClose={() => onChange && onChange(false)}
    >
      <ListItem
        sx={{ backgroundColor: "#c9faf7" }}
        disablePadding
        onClick={handleClickMenu}
      >
        <ListItemButton>
          <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
          <ListItemText primary={i18n.t("components.shortcutsMenu.title")} />
        </ListItemButton>
      </ListItem>
      {open && <Divider sx={{ width: "100%" }} />}

      {SHORTCUTS_LIST.map((option) => (
        <ShortcutOption
          key={option.id}
          option={option}
          onClick={handleClickMenuOption}
        />
      ))}
    </SwipeableDrawer>
  );
};

export default ShortcutsMenu;

interface ShortcutOptionProps {
  option: MenuOption;
  onClick?: () => void;
}
const ShortcutOption = (props: ShortcutOptionProps) => {
  const { option, onClick } = props;

  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const handleClickMenuOption = (option: MenuOption) => {
    if (option.children) {
      setOpen((state) => !state);
    } else if (option.route) {
      if (!option.route.startsWith("/")) navigate(`/${option.route}`);
      else if (option.route) {
        // Insert nested route to navigation history
        const nestedRoute = option.route.split("/");
        const lastPath = nestedRoute[nestedRoute.length - 1];
        nestedRoute.pop();
        navigate(nestedRoute.join("/"));
        nestedRoute.push(lastPath);
        navigate(nestedRoute.join("/"));
      }
      if (onClick) onClick();
    }
  };

  return (
    <div>
      <ListItemButton onClick={() => handleClickMenuOption(option)}>
        <ListItemIcon style={{ height: "12px !important" }}>
          {option.smallIcon}
        </ListItemIcon>
        <ListItemText primary={option.label} />
        {option.children && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>

      <Collapse in={open} sx={{ ml: "24px" }} timeout="auto">
        <List disablePadding>
          {option.children?.map((child) => (
            <ListItem
              key={child.id}
              disablePadding
              onClick={() => handleClickMenuOption(child)}
            >
              <ListItemButton>
                <ListItemIcon>{child.smallIcon}</ListItemIcon>
                <ListItemText primary={child.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
