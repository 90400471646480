import PlantProblem from "../../catalogue/PlantProblem";

class FBVadeAgent {
  id?: number;
  name?: string;
  plantProblem?: PlantProblem;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.plantProblem = new PlantProblem().mapToClass(obj?.plantProblem);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeAgent;
