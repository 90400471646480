import {
  useState,
  useRef,
  useEffect,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  FormGroup,
} from "@mui/material";
import { Work } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import SiexDownloadStatusBanner from "../../components/banners/SiexDownloadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import OwnerType from "../../models/catalogue/OwnerType";
import Province from "../../models/address/Province";
import Country from "../../models/address/Country";
import FarmerType from "../../models/catalogue/FarmerType";
import Owner from "../../models/agrobusiness/Owner";
import CoOwner from "../../models/agrobusiness/CoOwner";
import Entity from "../../models/agrobusiness/Entity";
import Address from "../../models/address/Address";
import Municipality from "../../models/address/Municipality";
import OwnerRepresentative from "../../models/agrobusiness/OwnerRepresentative";
import Manager from "../../models/agrobusiness/Manager";
import AgroBusinessLeadershipList from "../../models/address/AgroBusinessLeadershipList";
import ProfessionalTrainingType from "../../models/catalogue/ProfessionalTrainingType";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import {
  EntityTypeInterface,
  LeaderTypeInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import { FormMode, EntityType, LeaderType } from "../../constants/enums";
import { LEADER_TYPES } from "../../providers/YupProvider";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { ENTITY_SEX_TYPES, ENTITY_TYPES } from "../../providers/YupProvider";

const ExploitationLeadershipScreen = () => {
  const leadershipColumns: Column[] = [
    {
      id: "entity.nameAndSurname",
      label: i18n.t("leadership.column.name"),
      numeric: false,
    },
    {
      id: "leaderType.name",
      label: i18n.t("leadership.column.leaderType"),
      numeric: false,
    },
    {
      id: "entity.entityType.name",
      label: i18n.t("leadership.column.entityType"),
      numeric: false,
    },
    {
      id: "address.email",
      label: i18n.t("leadership.column.email"),
      numeric: false,
    },
  ];

  const leaderRef = useRef<LeadershipFormMethods>(null);

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [leaderType, setLeaderType] = useState<LeaderTypeInterface>(
    LEADER_TYPES[0]
  );
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [rows, setRows] = useState<any[]>([]);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedToEdit, setSelectedToEdit] = useState<
    Owner | CoOwner | OwnerRepresentative | Manager | undefined
  >(undefined);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);
  const [nDocuments, setNDocuments] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );
  const isSelectedSiexSync = useMemo(
    () => selectedToEdit?.siexEntity === true,
    [selectedToEdit]
  );

  const { data: leaderList, isFetching } = useFetch<AgroBusinessLeadershipList>(
    {
      queryKey: ["leaderList", selectedABAccount?.id, selectedABPartition?.id],
      enabled: !!selectedABAccount,
      onError: setSnackbarMsg,
    }
  );

  useEffect(() => {
    if (leaderList) {
      const leaderListArray = [];
      if (leaderList.owner) leaderListArray.push(leaderList.owner);
      if (leaderList.coOwners) leaderListArray.push(...leaderList.coOwners);
      if (leaderList.ownerRepresentative)
        leaderListArray.push(leaderList.ownerRepresentative);
      if (leaderList.manager) leaderListArray.push(leaderList.manager);
      setRows(leaderListArray);
      if (formMode === FormMode.EDIT && selectedToEdit?.leaderType)
        setLeaderType(selectedToEdit.leaderType);
      else if (
        leaderList.notCreatedLeadershipList &&
        leaderList.notCreatedLeadershipList.length > 0
      )
        setLeaderType(leaderList.notCreatedLeadershipList[0]);
    }
  }, [leaderList]);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (!isOpenFilesForm && isOpenDrawer) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    setSelectedToEdit(undefined);
    setSelectedRowIds([]);
    setNDocuments(0);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    navigate(-1);
    if (leaderRef?.current) leaderRef.current.clickClose();
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    cancelConfirmDeleteAlertDialog();
  };

  const ownerDeleteMutation = useCrud({
    key: "deleteOwner",
    onSuccess: () => {
      handleOnFormSuccess(i18n.t("apiResponses.ownerRemoveSuccess"));
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: setSnackbarMsg,
  });

  const coOwnerDeleteMutation = useCrud({
    key: "deleteCoOwner",
    onSuccess: () => {
      handleOnFormSuccess(i18n.t("apiResponses.coownerRemoveSuccess"));
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: setSnackbarMsg,
  });

  const ownerRepresentativeDeleteMutation = useCrud({
    key: "deleteOwnerRepresentative",
    onSuccess: () => {
      handleOnFormSuccess(
        i18n.t("apiResponses.ownerRepresentativeRemoveSuccess")
      );
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: setSnackbarMsg,
  });

  const managerDeleteMutation = useCrud({
    key: "deleteManager",
    onSuccess: () => {
      handleOnFormSuccess(i18n.t("apiResponses.managerRemoveSuccess"));
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: setSnackbarMsg,
  });

  const handleChangeLeaderType = (newValue: LeaderTypeInterface) => {
    setLeaderType(newValue);
  };

  const handleClickCloseDrawer = () => {
    openBackAlertDialog();
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = () => {
    if (leaderRef?.current) leaderRef.current.clickSave();
  };

  const handleClickEdit = (idx: number) => {
    const leader = rows?.find((leader) => leader.idx === idx);
    setSelectedToEdit(leader);
    setLeaderType(leader?.leaderType || LEADER_TYPES[0]);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleConfirmDeleteAlertDialog = () => {
    selectedRowIds.forEach((id) => {
      const leader = rows?.find((leader) => leader.idx === id);
      if (leader?.leaderType?.code === LeaderType.OWNER)
        ownerDeleteMutation.mutate({ id: leader.id });
      else if (leader?.leaderType?.code === LeaderType.COOWNER)
        coOwnerDeleteMutation.mutate({ id: leader.id });
      else if (leader?.leaderType?.code === LeaderType.OWNERREPRESENTATIVE)
        ownerRepresentativeDeleteMutation.mutate({ id: leader.id });
      else if (leader?.leaderType?.code === LeaderType.MANAGER)
        managerDeleteMutation.mutate({ id: leader.id });
    });
  };

  const handleOnFormSuccess = (msg: string) => {
    setSnackbarMsg({
      severity: "success",
      message: msg,
    });
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDrawer();
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("leadership.drawerTitleCreate")
      : i18n.t("leadership.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <SiexBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("leadership.backAlertTitle")}
        contentText={i18n.t("leadership.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("leadership.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("leadership.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={
          ownerDeleteMutation.isLoading ||
          coOwnerDeleteMutation.isLoading ||
          ownerRepresentativeDeleteMutation.isLoading ||
          managerDeleteMutation.isLoading
        }
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        idx="idx"
        title={i18n.t("leadership.tableTitle")}
        headerText={i18n.t("leadership.headerText")}
        colums={leadershipColumns}
        rows={rows}
        disableBtn={leaderList?.notCreatedLeadershipList?.length === 0}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Work sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("leadership.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("leadership.emptyTableSubtitleSIEX")
            : i18n.t("leadership.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("leadership.emptyTableBtnText")
            : undefined
        }
        addBtnVariant="contained"
        siexRows
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={leaderType?.name}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={isLoading}
        iconBtn={<AttachmentDocumentsBadge nDocuments={nDocuments} />}
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <LeadershipForm
          ref={leaderRef}
          isOpenFilesForm={isOpenFilesForm}
          leaderType={leaderType}
          leaderList={leaderList}
          formMode={formMode}
          selected={selectedToEdit}
          isSiexActive={isSiexActive}
          isSelectedSiexSync={isSelectedSiexSync}
          onChangeLeaderType={handleChangeLeaderType}
          onCloseAll={closeDialogAndUnselectedRows}
          onLoading={setIsLoading}
          onCloseFilesForm={handleCloseAttachments}
          onChangeNDocuments={setNDocuments}
          onSuccess={handleOnFormSuccess}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationLeadershipScreen;

interface LeadershipFormProps {
  isSiexActive: boolean;
  isSelectedSiexSync: boolean;
  isOpenFilesForm?: boolean;
  leaderType?: LeaderTypeInterface;
  leaderList?: AgroBusinessLeadershipList;
  formMode?: FormMode;
  selected?: Owner | CoOwner | OwnerRepresentative | Manager;
  description?: string;
  onChangeLeaderType?: (newValue: LeaderTypeInterface) => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onCloseAll?: () => void;
  onLoading?: (isLoading: boolean) => void;
  onChangeNDocuments?: (nDocuments: number) => void;
  onCloseFilesForm?: () => void;
}
interface LeadershipFormMethods {
  clickSave: () => void;
  clickClose: () => void;
}

const LeadershipForm = forwardRef<LeadershipFormMethods, LeadershipFormProps>(
  (props, ref) => {
    const {
      leaderList,
      leaderType,
      selected,
      isSiexActive,
      isSelectedSiexSync,
      onChangeLeaderType,
    } = props;

    const ownerRef = useRef<LeadershipFormMethods>(null);
    const coOwnerRef = useRef<LeadershipFormMethods>(null);
    const ownerRepresentativeRef = useRef<LeadershipFormMethods>(null);
    const managerRef = useRef<LeadershipFormMethods>(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          clickSave() {
            if (ownerRef?.current) ownerRef.current.clickSave();
            if (coOwnerRef?.current) coOwnerRef.current.clickSave();
            if (ownerRepresentativeRef?.current)
              ownerRepresentativeRef.current.clickSave();
            if (managerRef?.current) managerRef.current.clickSave();
          },
          clickClose() {
            if (ownerRef?.current) ownerRef.current.clickClose();
            if (coOwnerRef?.current) coOwnerRef.current.clickClose();
            if (ownerRepresentativeRef?.current)
              ownerRepresentativeRef.current.clickClose();
            if (managerRef?.current) managerRef.current.clickClose();
          },
        };
      },
      []
    );

    const handleChangeLeaderType = (newValue: LeaderTypeInterface) => {
      onChangeLeaderType && onChangeLeaderType(newValue);
    };

    return (
      <>
        {isSiexActive ? (
          <SiexDownloadStatusBanner
            isSync={isSelectedSiexSync}
            timestamp={selected?.siexUpdatedOn}
          />
        ) : (
          <SiexBanner adviceText />
        )}
        <LeaderTypeForm
          leaderList={leaderList}
          leaderType={leaderType}
          isDisabled={props.formMode === FormMode.EDIT}
          onChange={handleChangeLeaderType}
        />
        {leaderType?.code === LeaderType.OWNER && (
          <OwnerForm ref={ownerRef} {...props} />
        )}
        {leaderType?.code === LeaderType.COOWNER && (
          <CoOwnerForm ref={coOwnerRef} {...props} />
        )}
        {leaderType?.code === LeaderType.OWNERREPRESENTATIVE && (
          <OwnerRepresentativeForm ref={ownerRepresentativeRef} {...props} />
        )}
        {leaderType?.code === LeaderType.MANAGER && (
          <ManagerForm ref={managerRef} {...props} />
        )}
      </>
    );
  }
);

interface LeaderTypeProps {
  leaderList?: AgroBusinessLeadershipList;
  leaderType?: LeaderTypeInterface;
  isDisabled?: boolean;
  onChange: (leaderType: LeaderTypeInterface) => void;
}
const LeaderTypeForm = (props: LeaderTypeProps) => {
  const { leaderList, leaderType, isDisabled, onChange } = props;

  // Hide created leaderships in create mode
  const options =
    !isDisabled && leaderList?.notCreatedLeadershipList
      ? leaderList.notCreatedLeadershipList
      : LEADER_TYPES;

  const handleChange = (event: SelectChangeEvent) => {
    const leaderCode = event.target.value as LeaderType;
    const leaderType = LEADER_TYPES.find(
      (leaderType) => leaderType.code === leaderCode
    );
    if (leaderType) onChange(leaderType);
  };

  return (
    <BrioCard
      title={i18n.t("leadership.leaderTypeForm.title")}
      defaultExpanded
      required
    >
      <FormGroup className="form-group">
        <FormControl
          variant="outlined"
          className="form-control"
          disabled={isDisabled}
        >
          <InputLabel required>
            {i18n.t("leadership.leaderTypeForm.leaderType")}
          </InputLabel>
          <Select
            label={i18n.t("leadership.leaderTypeForm.leaderType")}
            value={leaderType?.code || ""}
            onChange={handleChange}
          >
            {options.map((leader) => (
              <MenuItem key={leader.id} value={leader.code}>
                {leader.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const OwnerForm = forwardRef<LeadershipFormMethods, LeadershipFormProps>(
  (props, ref) => {
    const {
      selected,
      onSuccess,
      onError,
      onCloseAll,
      onLoading,
      isOpenFilesForm = false,
      isSelectedSiexSync,
      onCloseFilesForm,
      onChangeNDocuments,
    } = props;

    const [expandCards, forceExpandCards] = useExpandCards();

    const resetForm = () => {
      formik.setStatus(null);
      formik.setErrors({});
      formik.resetForm();
    };

    const closeAll = () => {
      onCloseAll && onCloseAll();
      resetForm();
    };

    const onFetchOwnerSuccess = (owner: Owner) => {
      formik.setValues(owner);
      if (owner?.entity) entityFormik.setValues(owner.entity);
      if (owner?.address) addressFormik.setValues(owner.address);
      owner?.documents &&
        onChangeNDocuments &&
        onChangeNDocuments(owner.documents.length);
    };

    const { isFetching: isUpdating } = useFetch<Owner>({
      queryKey: ["owner", selected?.id],
      selected,
      enabled: !!selected?.id,
      onSuccess: onFetchOwnerSuccess,
      onError,
    });

    const handleSubmit = async (values: Owner) => {
      const entityFormErrors = await entityFormik.validateForm();
      const addressFormErrors = await addressFormik.validateForm();
      if (
        Object.keys(entityFormErrors).length > 0 ||
        Object.keys(addressFormErrors).length > 0
      ) {
        forceExpandCards();
        onError &&
          onError({
            severity: "warning",
            message: i18n.t("formErrors.requiredFields"),
          });
        // Mark all fields as touched to show errors
        entityFormik.setTouched(entityFormErrors as FormikTouched<any>);
        addressFormik.setTouched(addressFormErrors as FormikTouched<any>);
      } else {
        formik.values.entity = entityFormik.values;
        formik.values.address = addressFormik.values;
        ownerEditMutation.mutate({});
      }
    };

    const formik = useFormik<Owner>({
      initialValues: new Owner(),
      onSubmit: handleSubmit,
    });

    const entityFormik = useFormik<Entity>({
      initialValues: new Entity(),
      validationSchema: entityValidatorSchema,
      onSubmit: () => {},
    });

    const addressFormik = useFormik<Address>({
      initialValues: new Address(),
      onSubmit: () => {},
    });

    // Callback from parent
    useImperativeHandle(
      ref,
      () => {
        return {
          clickSave() {
            formik.submitForm();
          },
          clickClose() {
            resetForm();
          },
        };
      },
      []
    );

    const ownerEditMutation = useCrud<Owner>({
      key: "putOwner",
      values: formik.values,
      onSuccess: () => {
        onSuccess && onSuccess(i18n.t("apiResponses.ownerUpdateSuccess"));
        closeAll();
      },
      onError,
    });

    useEffect(() => {
      if (onLoading) onLoading(ownerEditMutation.isLoading);

      return () => {
        onLoading && onLoading(false);
      };
    }, [ownerEditMutation.isLoading]);

    const handleChangeFiles = (files: Document[]) => {
      formik.setFieldValue("documents", files);
      onChangeNDocuments && onChangeNDocuments(files.length);
    };

    return (
      <>
        <LoadingWithDelay isVisible={isUpdating} />
        <OwnerTypeForm
          formik={formik}
          forceExpanded={expandCards}
          isSelectedSiexSync={isSelectedSiexSync}
          onSuccess={onSuccess}
          onError={onError}
        />
        <EntityForm
          formik={entityFormik}
          forceExpanded={expandCards}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        <AddressContactDataForm
          formik={addressFormik}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        <AddressForm
          formik={addressFormik}
          isSelectedSiexSync={isSelectedSiexSync}
          onError={onError}
        />
        <FarmerTypesForm
          formik={formik}
          isSelectedSiexSync={isSelectedSiexSync}
          onError={onError}
        />
        <FilesForm
          open={isOpenFilesForm}
          files={formik.values.documents || []}
          drawerSubtitle={i18n.t("leadership.ownerForm.filesFormSubtitle")}
          onChangeFiles={handleChangeFiles}
          onClose={onCloseFilesForm}
          onError={onError}
        />
      </>
    );
  }
);

const CoOwnerForm = forwardRef<LeadershipFormMethods, LeadershipFormProps>(
  (props, ref) => {
    const {
      formMode,
      selected,
      onSuccess,
      onError,
      onCloseAll,
      onLoading,
      isOpenFilesForm = false,
      isSelectedSiexSync,
      onCloseFilesForm,
      onChangeNDocuments,
    } = props;

    const [expandCards, forceExpandCards] = useExpandCards();

    const resetForm = () => {
      formik.setStatus(null);
      formik.setErrors({});
      formik.resetForm();
    };

    const closeAll = () => {
      onCloseAll && onCloseAll();
      resetForm();
    };

    const onFetchCoOwnerSuccess = (coOwner: CoOwner) => {
      formik.setValues(coOwner);
      if (coOwner?.entity) entityFormik.setValues(coOwner.entity);
      if (coOwner?.address) addressFormik.setValues(coOwner.address);
      coOwner?.documents &&
        onChangeNDocuments &&
        onChangeNDocuments(coOwner.documents.length);
    };

    const { isFetching: isUpdating } = useFetch<CoOwner>({
      queryKey: ["coOwner", selected?.id],
      selected,
      enabled: !!selected?.id,
      onSuccess: onFetchCoOwnerSuccess,
      onError,
    });

    const handleSubmit = async (values: CoOwner) => {
      const entityFormErrors = await entityFormik.validateForm();
      const addressFormErrors = await addressFormik.validateForm();
      if (
        Object.keys(entityFormErrors).length > 0 ||
        Object.keys(addressFormErrors).length > 0
      ) {
        forceExpandCards();
        onError &&
          onError({
            severity: "warning",
            message: i18n.t("formErrors.requiredFields"),
          });
        // Mark all fields as touched to show errors
        entityFormik.setTouched(entityFormErrors as FormikTouched<any>);
        addressFormik.setTouched(addressFormErrors as FormikTouched<any>);
      } else {
        formik.values.entity = entityFormik.values;
        formik.values.address = addressFormik.values;
        if (formMode === FormMode.CREATE) coOwnerCreateMutation.mutate({});
        else if (formMode === FormMode.EDIT) coOwnerEditMutation.mutate({});
      }
    };

    const formik = useFormik<CoOwner>({
      initialValues: new CoOwner(),
      onSubmit: handleSubmit,
    });

    const entityFormik = useFormik<Entity>({
      initialValues: new Entity(),
      validationSchema: entityValidatorSchema,
      onSubmit: () => {},
    });

    const addressFormik = useFormik<Address>({
      initialValues: new Address(),
      onSubmit: () => {},
    });

    // Callback from parent
    useImperativeHandle(
      ref,
      () => {
        return {
          clickSave() {
            formik.submitForm();
          },
          clickClose() {
            resetForm();
          },
        };
      },
      []
    );

    const coOwnerCreateMutation = useCrud<CoOwner>({
      key: "postCoOwner",
      values: formik.values,
      onSuccess: () => {
        onSuccess && onSuccess(i18n.t("apiResponses.coownerCreateSuccess"));
        closeAll();
      },
      onError,
    });

    const coOwnerEditMutation = useCrud<CoOwner>({
      key: "putCoOwner",
      values: formik.values,
      onSuccess: () => {
        onSuccess && onSuccess(i18n.t("apiResponses.coownerUpdateSuccess"));
        closeAll();
      },
      onError,
    });

    useEffect(() => {
      if (onLoading)
        onLoading(
          coOwnerCreateMutation.isLoading && coOwnerEditMutation.isLoading
        );

      return () => {
        onLoading && onLoading(false);
      };
    }, [coOwnerCreateMutation.isLoading, coOwnerEditMutation.isLoading]);

    const handleChangeFiles = (files: Document[]) => {
      formik.setFieldValue("documents", files);
      onChangeNDocuments && onChangeNDocuments(files.length);
    };

    return (
      <>
        <LoadingWithDelay isVisible={isUpdating} />
        <EntityForm
          formik={entityFormik}
          forceExpanded={expandCards}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        <AddressContactDataForm
          formik={addressFormik}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        <AddressForm
          formik={addressFormik}
          isSelectedSiexSync={isSelectedSiexSync}
          onError={onError}
        />
        <FilesForm
          open={isOpenFilesForm}
          files={formik.values.documents || []}
          drawerSubtitle={i18n.t("leadership.coOwnerForm.filesFormSubtitle")}
          onChangeFiles={handleChangeFiles}
          onClose={onCloseFilesForm}
          onError={onError}
        />
      </>
    );
  }
);

const OwnerRepresentativeForm = forwardRef<
  LeadershipFormMethods,
  LeadershipFormProps
>((props, ref) => {
  const {
    formMode,
    selected,
    onSuccess,
    onError,
    onCloseAll,
    onLoading,
    isOpenFilesForm = false,
    isSelectedSiexSync,
    onCloseFilesForm,
    onChangeNDocuments,
  } = props;

  const [expandCards, forceExpandCards] = useExpandCards();

  const resetForm = () => {
    formik.setStatus(null);
    formik.setErrors({});
    formik.resetForm();
  };

  const closeAll = () => {
    onCloseAll && onCloseAll();
    resetForm();
  };

  const onFetchOwnerRepresentativeSuccess = (
    ownerRepresentative: OwnerRepresentative
  ) => {
    formik.setValues(ownerRepresentative);
    if (ownerRepresentative?.entity)
      entityFormik.setValues(ownerRepresentative.entity);
    if (ownerRepresentative?.address)
      addressFormik.setValues(ownerRepresentative.address);
    ownerRepresentative?.documents &&
      onChangeNDocuments &&
      onChangeNDocuments(ownerRepresentative.documents.length);
  };

  const { isFetching: isUpdating } = useFetch<OwnerRepresentative>({
    queryKey: ["ownerRepresentative", selected?.id],
    selected,
    enabled: !!selected?.id,
    onSuccess: onFetchOwnerRepresentativeSuccess,
    onError,
  });

  const handleSubmit = async (values: OwnerRepresentative) => {
    const entityFormErrors = await entityFormik.validateForm();
    const addressFormErrors = await addressFormik.validateForm();
    if (
      Object.keys(entityFormErrors).length > 0 ||
      Object.keys(addressFormErrors).length > 0
    ) {
      forceExpandCards();
      onError &&
        onError({
          severity: "warning",
          message: i18n.t("formErrors.requiredFields"),
        });
      // Mark all fields as touched to show errors
      entityFormik.setTouched(entityFormErrors as FormikTouched<any>);
      addressFormik.setTouched(addressFormErrors as FormikTouched<any>);
    } else {
      formik.values.entity = entityFormik.values;
      formik.values.address = addressFormik.values;
      if (formMode === FormMode.CREATE)
        ownerRepresentativeCreateMutation.mutate({});
      else if (formMode === FormMode.EDIT)
        ownerRepresentativeEditMutation.mutate({});
    }
  };

  const formik = useFormik<OwnerRepresentative>({
    initialValues: new OwnerRepresentative(),
    onSubmit: handleSubmit,
  });

  const entityFormik = useFormik<Entity>({
    initialValues: new Entity(),
    validationSchema: entityValidatorSchema,
    onSubmit: () => {},
  });

  const addressFormik = useFormik<Address>({
    initialValues: new Address(),
    onSubmit: () => {},
  });

  // Callback from parent
  useImperativeHandle(
    ref,
    () => {
      return {
        clickSave() {
          formik.submitForm();
        },
        clickClose() {
          resetForm();
        },
      };
    },
    []
  );

  const ownerRepresentativeCreateMutation = useCrud<OwnerRepresentative>({
    key: "postOwnerRepresentative",
    values: formik.values,
    onSuccess: () => {
      onSuccess &&
        onSuccess(i18n.t("apiResponses.ownerRepresentativeCreateSuccess"));
      closeAll();
    },
    onError,
  });

  const ownerRepresentativeEditMutation = useCrud<OwnerRepresentative>({
    key: "putOwnerRepresentative",
    values: formik.values,
    onSuccess: () => {
      onSuccess &&
        onSuccess(i18n.t("apiResponses.ownerRepresentativeUpdateSuccess"));
      closeAll();
    },
    onError,
  });

  useEffect(() => {
    if (onLoading)
      onLoading(
        ownerRepresentativeCreateMutation.isLoading ||
          ownerRepresentativeEditMutation.isLoading
      );

    return () => {
      onLoading && onLoading(false);
    };
  }, [
    ownerRepresentativeCreateMutation.isLoading,
    ownerRepresentativeEditMutation.isLoading,
  ]);

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
    onChangeNDocuments && onChangeNDocuments(files.length);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <EntityForm
        formik={entityFormik}
        forceExpanded={expandCards}
        isSelectedSiexSync={isSelectedSiexSync}
      />
      <AddressContactDataForm
        formik={addressFormik}
        isSelectedSiexSync={isSelectedSiexSync}
      />
      <AddressForm
        formik={addressFormik}
        isSelectedSiexSync={isSelectedSiexSync}
        onError={onError}
      />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t(
          "leadership.ownerRepresentativeForm.filesFormSubtitle"
        )}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
});

const ManagerForm = forwardRef<LeadershipFormMethods, LeadershipFormProps>(
  (props, ref) => {
    const {
      formMode,
      selected,
      onSuccess,
      onError,
      onCloseAll,
      onLoading,
      isOpenFilesForm = false,
      isSelectedSiexSync,
      onCloseFilesForm,
      onChangeNDocuments,
    } = props;

    const [expandCards, forceExpandCards] = useExpandCards();

    const resetForm = () => {
      formik.setStatus(null);
      formik.setErrors({});
      formik.resetForm();
    };

    const closeAll = () => {
      onCloseAll && onCloseAll();
      resetForm();
    };

    const onFetchManagerSuccess = (manager: Manager) => {
      formik.setValues(manager);
      if (manager?.entity) entityFormik.setValues(manager.entity);
      manager?.documents &&
        onChangeNDocuments &&
        onChangeNDocuments(manager.documents.length);
    };

    const { isFetching: isUpdating } = useFetch<Manager>({
      queryKey: ["manager", selected?.id],
      selected,
      enabled: !!selected?.id,
      onSuccess: onFetchManagerSuccess,
      onError,
    });

    const handleSubmit = async (values: Manager) => {
      const entityFormErrors = await entityFormik.validateForm();
      if (Object.keys(entityFormErrors).length > 0) {
        forceExpandCards();
        onError &&
          onError({
            severity: "warning",
            message: i18n.t("formErrors.requiredFields"),
          });
        // Mark all fields as touched to show errors
        entityFormik.setTouched(entityFormErrors as FormikTouched<any>);
      } else {
        formik.values.entity = entityFormik.values;
        if (formMode === FormMode.CREATE) managerCreateMutation.mutate({});
        else if (formMode === FormMode.EDIT) managerEditMutation.mutate({});
      }
    };

    const formik = useFormik<Manager>({
      initialValues: new Manager(),
      onSubmit: handleSubmit,
    });

    const entityFormik = useFormik<Entity>({
      initialValues: new Entity(),
      validationSchema: entityValidatorSchema,
      onSubmit: () => {},
    });

    // Callback from parent
    useImperativeHandle(
      ref,
      () => {
        return {
          clickSave() {
            formik.submitForm();
          },
          clickClose() {
            resetForm();
          },
        };
      },
      []
    );

    const managerCreateMutation = useCrud<Manager>({
      key: "postManager",
      values: formik.values,
      onSuccess: () => {
        onSuccess && onSuccess(i18n.t("apiResponses.managerCreateSuccess"));
        closeAll();
      },
      onError,
    });

    const managerEditMutation = useCrud<Manager>({
      key: "putManager",
      values: formik.values,
      onSuccess: () => {
        onSuccess && onSuccess(i18n.t("apiResponses.managerUpdateSuccess"));
        closeAll();
      },
      onError,
    });

    useEffect(() => {
      if (onLoading)
        onLoading(
          managerCreateMutation.isLoading || managerEditMutation.isLoading
        );

      return () => {
        onLoading && onLoading(false);
      };
    }, [managerCreateMutation.isLoading, managerEditMutation.isLoading]);

    const handleChangeFiles = (files: Document[]) => {
      formik.setFieldValue("documents", files);
      onChangeNDocuments && onChangeNDocuments(files.length);
    };

    return (
      <>
        <LoadingWithDelay isVisible={isUpdating} />
        <EntityForm
          formik={entityFormik}
          forceExpanded={expandCards}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        <ProfessionalTrainingForm
          formik={formik}
          isSelectedSiexSync={isSelectedSiexSync}
          onError={onError}
        />
        <FilesForm
          open={isOpenFilesForm}
          files={formik.values.documents || []}
          drawerSubtitle={i18n.t("leadership.managerForm.filesFormSubtitle")}
          onChangeFiles={handleChangeFiles}
          onClose={onCloseFilesForm}
          onError={onError}
        />
      </>
    );
  }
);

interface OwnerFormProps {
  formik: FormikProps<Owner>;
  isSelectedSiexSync: boolean;
  forceExpanded?: boolean;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
interface ManagerFormProps {
  formik: FormikProps<Manager>;
  isSelectedSiexSync: boolean;
  onError?: (snackBarError: SnackbarInterface) => void;
}
interface EntityFormProps {
  formik: FormikProps<Entity>;
  isSelectedSiexSync: boolean;
  forceExpanded?: boolean;
}
interface AddressFormProps {
  formik: FormikProps<Address>;
  isSelectedSiexSync: boolean;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const OwnerTypeForm = (props: OwnerFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: ownerTypes, isFetching } = useFetch<OwnerType[]>({
    queryKey: ["ownerTypes", selectedABAccount?.context?.id],
    enabled: !!formik.values,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("leadership.ownerTypeForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      optional
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="ownerType"
            label={i18n.t("leadership.ownerTypeForm.ownerType")}
            options={ownerTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetching}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const entityValidatorSchema = Yup.object().shape({
  name: Yup.string().required(),
});

const EntityForm = (props: EntityFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync } = props;

  return (
    <BrioCard
      title={
        formik.values?.entityType?.code === EntityType.LEGAL
          ? i18n.t("leadership.entityForm.titleLegal")
          : i18n.t("leadership.entityForm.titlePhysical")
      }
      defaultExpanded
      required
      forceExpanded={forceExpanded}
    >
      <FormGroup className="form-group">
        <EntityTypeForm
          formik={formik}
          isSelectedSiexSync={isSelectedSiexSync}
        />
        {formik.values?.entityType?.code === EntityType.PHYSICAL && (
          <EntityPersonalDataForm
            formik={formik}
            isSelectedSiexSync={isSelectedSiexSync}
          />
        )}
        {formik.values?.entityType?.code === EntityType.LEGAL && (
          <EntityLegalDataForm
            formik={formik}
            isSelectedSiexSync={isSelectedSiexSync}
          />
        )}
      </FormGroup>
    </BrioCard>
  );
};

const EntityTypeForm = (props: EntityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const handleChangeEntityType = (entityType: EntityTypeInterface) => {
    formik.setFieldValue("entityType", entityType);
    formik.setFieldValue("firstSurname", "");
    formik.setFieldValue("secondSurname", "");
  };

  return (
    <FormControl variant="outlined" className="form-control">
      <FormikSelect
        formik={formik}
        name="entityType"
        label={i18n.t("leadership.entityForm.entityType")}
        options={ENTITY_TYPES}
        optionLabelFieldName="name"
        onChange={handleChangeEntityType}
        disabled={isSelectedSiexSync}
      />
    </FormControl>
  );
};

const EntityPersonalDataForm = (props: EntityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <>
      <FormControl variant="outlined" className="form-control">
        <FormikListItemSwitch
          formik={formik}
          name="foreignId"
          primaryText={i18n.t("leadership.personalDataForm.foreignId")}
          secondaryText={i18n.t(
            "leadership.personalDataForm.foreignIdSubtitle"
          )}
          disabled={isSelectedSiexSync}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="name"
          label={i18n.t("leadership.personalDataForm.name")}
          required
          disabled={isSelectedSiexSync}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control-row">
        <FormikTextField
          formik={formik}
          className="form-input-row"
          name="firstSurname"
          label={i18n.t("leadership.personalDataForm.firstSurname")}
          disabled={isSelectedSiexSync}
        />
        <FormikTextField
          formik={formik}
          className="form-input-row"
          name="secondSurname"
          label={i18n.t("leadership.personalDataForm.secondSurname")}
          disabled={isSelectedSiexSync}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="entityId"
          label={i18n.t("leadership.personalDataForm.entityId")}
          disabled={isSelectedSiexSync}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control-row">
        <DatePicker
          className="form-input"
          format="DD/MM/YYYY"
          openTo="year"
          label={i18n.t("leadership.personalDataForm.birthDate")}
          value={
            formik.values?.birthDate
              ? moment(formik.values.birthDate, "YYYY-MM-DD")
              : moment()
          }
          onChange={(date) =>
            formik.setFieldValue("birthDate", date?.format("YYYY-MM-DD"))
          }
          disabled={isSelectedSiexSync}
        />
        <FormikSelect
          formik={formik}
          type="fullWidth"
          style={{ minWidth: "150px", marginLeft: "8px" }}
          name="sexType"
          label={i18n.t("leadership.personalDataForm.sexType")}
          options={ENTITY_SEX_TYPES}
          optionLabelFieldName="name"
          disabled={isSelectedSiexSync}
        />
      </FormControl>
    </>
  );
};

const EntityLegalDataForm = (props: EntityFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="name"
          label={i18n.t("leadership.legalDataForm.name")}
          required
          disabled={isSelectedSiexSync}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="entityId"
          label={i18n.t("leadership.legalDataForm.entityId")}
          disabled={isSelectedSiexSync}
        />
      </FormControl>
    </>
  );
};

const AddressContactDataForm = (props: AddressFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <BrioCard title={i18n.t("leadership.contactForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="email"
            label={i18n.t("leadership.contactForm.email")}
            type="email"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="phone1"
            label={i18n.t("leadership.contactForm.phone1")}
            type="tel"
            disabled={isSelectedSiexSync}
          />
          <FormikTextField
            formik={formik}
            className="form-input-row"
            name="phone2"
            label={i18n.t("leadership.contactForm.phone2")}
            type="tel"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const AddressForm = (props: AddressFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { data: countries, isFetching: isFetchingCountries } = useFetch<
    Country[]
  >({
    queryKey: ["countries"],
    enabled: !!formik.values,
    onError,
  });

  const { data: provinces, isFetching: isFetchingProvinces } = useFetch<
    Province[]
  >({
    queryKey: ["provincesByCountryCode", formik.values?.country?.code],
    selected: formik.values?.country,
    enabled: !!formik.values?.country?.code,
    onError,
  });

  const { data: municipalities, isFetching: isFetchingMunicipalities } =
    useFetch<Province[]>({
      queryKey: ["municipalitiesByProvinceId", formik.values?.province?.id],
      selected: formik.values?.province,
      enabled: !!formik.values?.province?.id,
      onError,
    });

  const handleChangeCountry = (country: Country) => {
    formik.setFieldValue("country", country);
    formik.setFieldValue("province", null);
    formik.setFieldValue("municipality", null);
    formik.setFieldValue("zipCode", null);
  };

  const handleChangeProvince = (province: Province) => {
    formik.setFieldValue("province", province);
    formik.setFieldValue("municipality", null);
    formik.setFieldValue("zipCode", null);
  };

  const handleChangeMunicipality = (municipality: Municipality) => {
    formik.setFieldValue("municipality", municipality);
    formik.setFieldValue("zipCode", null);
  };

  return (
    <BrioCard title={i18n.t("leadership.addressForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="addressLine1"
            label={i18n.t("leadership.addressForm.addressLine1")}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            className="form-input"
            name="addressLine2"
            label={i18n.t("leadership.addressForm.addressLine2")}
            placeholder={i18n.t(
              "leadership.addressForm.addressLine2Placeholder"
            )}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikAutocomplete
            className="form-input-row"
            formik={formik}
            name="country"
            label={i18n.t("leadership.addressForm.country")}
            placeholder={i18n.t("leadership.addressForm.countryPlaceholder")}
            options={countries || []}
            optionLabelFieldName="name"
            loading={isFetchingCountries}
            disabled={isSelectedSiexSync}
            onChange={handleChangeCountry}
          />
          <FormikAutocomplete
            className="form-input-row"
            formik={formik}
            name="province"
            label={i18n.t("leadership.addressForm.province")}
            placeholder={i18n.t("leadership.addressForm.provincePlaceholder")}
            options={provinces || []}
            optionLabelFieldName="name"
            loading={isFetchingProvinces}
            disabled={!formik.values?.country?.id || isSelectedSiexSync}
            onChange={handleChangeProvince}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikAutocomplete
            className="form-input-row-70"
            formik={formik}
            name="municipality"
            label={i18n.t("leadership.addressForm.municipality")}
            placeholder={i18n.t(
              "leadership.addressForm.municipalityPlaceholder"
            )}
            options={municipalities || []}
            optionLabelFieldName="name"
            loading={isFetchingMunicipalities}
            disabled={!formik.values?.province?.id || isSelectedSiexSync}
            onChange={handleChangeMunicipality}
          />
          <FormikTextField
            formik={formik}
            className="form-input-row-30"
            name="zipCode"
            label={i18n.t("leadership.addressForm.zipCode")}
            type="number"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FarmerTypesForm = (props: OwnerFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: farmerTypes, isFetching } = useFetch<FarmerType[]>({
    queryKey: ["farmerTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  return (
    <BrioCard title={i18n.t("leadership.farmerTypesForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="farmerTypes"
            label={i18n.t("leadership.farmerTypesForm.farmerTypes")}
            multiple
            options={farmerTypes || []}
            optionLabelFieldName="name"
            loading={isFetching}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const ProfessionalTrainingForm = (props: ManagerFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: professionalTrainingTypes, isFetching } = useFetch<
    ProfessionalTrainingType[]
  >({
    queryKey: ["professionalTrainingTypes", selectedABAccount?.context?.id],
    enabled: !!formik.values,
    onError,
  });

  return (
    <BrioCard title={i18n.t("leadership.trainningForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="agroWorkUnits"
            type="number"
            label={i18n.t("leadership.trainningForm.agroWorkUnits")}
            placeholder={i18n.t(
              "leadership.trainningForm.agroWorkUnitsPlaceholder"
            )}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="professionalTrainingType"
            label={i18n.t("leadership.trainningForm.professionalTrainingType")}
            options={professionalTrainingTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetching}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
