import FBEntity from "../_apiModels/agrobusiness/FBEntity";

import {
  EntitySexInterface,
  EntityTypeInterface,
} from "../../constants/interfaces";
import { ENTITY_SEX_TYPES, ENTITY_TYPES } from "../../providers/YupProvider";

class Entity extends FBEntity {
  sexType?: EntitySexInterface;
  entityType?: EntityTypeInterface;
  nameAndSurname?: string;

  constructor(
    sexType?: EntitySexInterface,
    entityType?: EntityTypeInterface,
    nameAndSurname?: string
  ) {
    super();
    this.sexType = sexType;
    this.entityType = entityType || ENTITY_TYPES[0];
    this.nameAndSurname = nameAndSurname;
  }

  mapToClass(obj: any): Entity | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (obj.sex)
      this.sexType = ENTITY_SEX_TYPES.find(
        (sexType) => sexType.code === obj.sex
      );
    this.entityType =
      ENTITY_TYPES.find((entityType) => entityType.code === obj.type) ||
      ENTITY_TYPES[0];
    this.nameAndSurname = this.getNameAndSurname();
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.sex = this.sexType?.code;
    obj.type = this.entityType?.code;
    // Remove custom variables
    delete obj.sexType;
    delete obj.entityType;
    delete obj.nameAndSurname;
    return obj;
  }

  getNameAndSurname(): string {
    return `${this.name} ${this.firstSurname || ""}`;
  }
}

export default Entity;
