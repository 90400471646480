import { useState } from "react";
import moment from "moment";
import i18n from "../../config/configI18n";

import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

import { FBESiexRequestStatus } from "../../constants/enums";
import SiexRequestItem from "../../models/siex/SiexRequestItem";
import useFetch from "../../hooks/useFetch";

const getSiexRequestStatusLabel = (status: FBESiexRequestStatus) => {
  switch (status) {
    case FBESiexRequestStatus.ADVISOR_PENDING:
      return i18n.t("components.siexStatusIcon.uploadAdvisorPendingLabel");
    case FBESiexRequestStatus.PENDING:
      return i18n.t("components.siexStatusIcon.uploadPendingLabel");
    case FBESiexRequestStatus.SENT_PENDING:
      return i18n.t("components.siexStatusIcon.uploadSentPendingLabel");
    case FBESiexRequestStatus.OK:
      return i18n.t("components.siexStatusIcon.uploadOkLabel");
    case FBESiexRequestStatus.NOSYNC:
    case FBESiexRequestStatus.ERROR:
      return i18n.t("components.siexStatusIcon.uploadErrorLabel");
    default:
      return "";
  }
};

interface Props {
  entityClassId?: number;
  status?: FBESiexRequestStatus;
  timestamp?: number;
  errorSiexRegister?: SiexRequestItem;
}
const SiexUploadStatusBanner = (props: Props) => {
  const { entityClassId, status, timestamp, errorSiexRegister } = props;

  const [isOpenContent, setIsOpenContent] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    errorSiexRegister?.errorMessage
  );

  useFetch<SiexRequestItem>({
    queryKey: ["siexError", entityClassId],
    selected: entityClassId,
    enabled:
      !errorSiexRegister?.errorMessage &&
      status === FBESiexRequestStatus.ERROR &&
      !!entityClassId,
    onSuccess: (data: SiexRequestItem) => setErrorMessage(data.errorMessage),
  });

  const onClickCollapse = () => setIsOpenContent((state) => !state);

  if (!status) return null;

  return (
    <div className={`siex-status-banner-${status.toLowerCase()}`}>
      <div className="siex-status-banner-head">
        <div className="siex-status-banner-head-left">
          <p>{i18n.t("components.siexStatusBanner.siexStatusTitle")}</p>
        </div>
        <div className="siex-status-banner-head-right">
          {timestamp && (
            <p className="siex-status-banner-date">
              {moment(timestamp).format("DD MMM HH:mm")}
            </p>
          )}
        </div>
      </div>
      <div className="siex-status-banner-status">
        <div className={`siex-status-banner-icon-${status.toLowerCase()}`} />
        <h1 className="siex-status-banner-title">
          {getSiexRequestStatusLabel(status)}
        </h1>
        <IconButton onClick={onClickCollapse}>
          {isOpenContent ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      {status === FBESiexRequestStatus.ERROR && errorMessage && (
        <p className="siex-error-reason-text">{`${i18n.t(
          "components.siexStatusBanner.errorReasonLabel"
        )}: ${errorMessage}`}</p>
      )}
      <Collapse in={isOpenContent}>
        <div className="siex-status-banner-content">
          <p>
            {i18n.t(
              `components.siexStatusBanner.${status.toLowerCase()}Description1`
            )}
          </p>
          <p>
            {i18n.t(
              `components.siexStatusBanner.${status.toLowerCase()}Description2`
            )}
          </p>
          {[FBESiexRequestStatus.ERROR, FBESiexRequestStatus.NOSYNC].includes(
            status
          ) && (
            <p>
              {i18n.t(
                `components.siexStatusBanner.${status.toLowerCase()}Description3`
              )}
            </p>
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default SiexUploadStatusBanner;
