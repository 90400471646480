import { ExportDataFormat } from "../../../constants/enums";

class FBCueExportData {
  agroBusinessId?: number;
  partitionId?: number;
  cropIds?: number[];
  startDate?: string;
  endDate?: string;
  format?: ExportDataFormat;

  constructor(
    agroBusinessId?: number,
    partitionId?: number,
    cropIds?: number[],
    startDate?: string,
    endDate?: string,
    format?: ExportDataFormat
  ) {
    this.agroBusinessId = agroBusinessId;
    this.partitionId = partitionId;
    this.cropIds = cropIds;
    this.startDate = startDate;
    this.endDate = endDate;
    this.format = format;
  }

  mapToBaseClass(obj: any): void {
    this.agroBusinessId = obj?.agroBusinessId;
    this.partitionId = obj?.partitionId;
    this.cropIds = obj?.cropIds;
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.format = obj?.format;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBCueExportData;
