import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useSession } from "../hooks/useSession";

import SiexRequestItem from "../models/siex/SiexRequestItem";

import { ENTITY_CLASS_NAMES } from "../providers/YupProvider";
import { PROTECTED_ROUTES } from "./routeNames";

/**
 * This urls is called from advisor webapp to redirect to the correct section of a siex register
 */
const SiexAdvisorRedirect = () => {
  const { agroBusinessId, entityClassId, entityClassName } = useParams<{
    agroBusinessId: string;
    entityClassId: string;
    entityClassName: string;
  }>();
  const { agroBusinessAccounts, changeSelectedABA } = useSession();
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate(PROTECTED_ROUTES.DASHBOARD, { replace: true });
  };

  useEffect(() => {
    const urlAgroBusinessAccount = agroBusinessAccounts.find(
      (aba) => aba.agroBusiness?.id === Number(agroBusinessId)
    );
    if (urlAgroBusinessAccount) {
      changeSelectedABA(urlAgroBusinessAccount);
      const entityClass = ENTITY_CLASS_NAMES.find(
        (entity) => entity.name === entityClassName
      );
      if (entityClass) {
        const siexRegister = new SiexRequestItem().mapToClass({
          entityClassId: Number(entityClassId),
          entityClassName,
        });
        navigate(`/${entityClass.routePath}`, {
          state: { siexRegister },
          replace: true,
        });
      } else redirectToDashboard();
    } else redirectToDashboard();
  }, [agroBusinessAccounts]);

  return null;
};

export default SiexAdvisorRedirect;
