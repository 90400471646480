import OwnerType from "../../catalogue/OwnerType";
import Entity from "../../agrobusiness/Entity";
import Address from "../../address/Address";
import FarmerType from "../../catalogue/FarmerType";

class FBOwner {
  id?: number;
  ownerType?: OwnerType;
  entity?: Entity; //*
  address?: Address; //*
  farmerTypes?: FarmerType[];
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    ownerType?: OwnerType,
    entity?: Entity,
    address?: Address,
    farmerTypes?: FarmerType[]
  ) {
    this.id = id;
    this.ownerType = ownerType;
    this.entity = entity;
    this.address = address;
    this.farmerTypes = farmerTypes;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.ownerType = new OwnerType().mapToClass(obj?.ownerType);
    this.entity = new Entity().mapToClass(obj?.entity);
    this.address = new Address().mapToClass(obj?.address);
    this.farmerTypes = obj?.farmerTypes?.map((farmerType: any) =>
      new FarmerType().mapToClass(farmerType)
    );
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.ownerType = this.ownerType?.mapToApiModel();
    obj.entity = this.entity?.mapToApiModel();
    obj.address = this.address?.mapToApiModel();
    obj.farmerTypes = this.farmerTypes?.map((farmerType: FarmerType) =>
      farmerType?.mapToApiModel()
    );
    return obj;
  }
}

export default FBOwner;
