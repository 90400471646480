import CropProduct from "../../catalogue/CropProduct";

class FBVariety {
  id?: number;
  code?: string;
  name?: string;
  productType?: CropProduct;

  constructor(
    id?: number,
    code?: string,
    name?: string,
    productType?: CropProduct
  ) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.productType = productType;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.code = obj?.code;
    this.name = obj?.name;
    this.productType = new CropProduct().mapToClass(obj?.productType);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.productType = this.productType?.mapToApiModel();
    return obj;
  }
}

export default FBVariety;
