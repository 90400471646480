class FBAgroBusinessPartition {
  id?: number;
  name?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    name?: string,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.name = name;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAgroBusinessPartition;
