import ToolInspection from "../../agrobusiness/ToolInspection";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";

class FBTool {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  name?: string; //*
  description?: string;
  registerId?: string;
  aeroRegisterId?: string;
  referenceId?: string;
  purchaseDate?: string;
  ownership?: boolean;
  enabledDate?: string;
  disabledDate?: string;
  inspections?: ToolInspection[];
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    name?: string,
    description?: string,
    registerId?: string,
    aeroRegisterId?: string,
    referenceId?: string,
    purchaseDate?: string,
    ownership?: boolean,
    enabledDate?: string,
    disabledDate?: string,
    inspections?: ToolInspection[]
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.name = name;
    this.description = description;
    this.registerId = registerId;
    this.aeroRegisterId = aeroRegisterId;
    this.referenceId = referenceId;
    this.purchaseDate = purchaseDate;
    this.ownership = ownership;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.inspections = inspections;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.name = obj?.name;
    this.description = obj?.description;
    this.registerId = obj?.registerId;
    this.aeroRegisterId = obj?.aeroRegisterId;
    this.referenceId = obj?.referenceId;
    this.purchaseDate = obj?.purchaseDate;
    this.ownership = obj?.ownership;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.inspections =
      obj?.inspections?.map((i: any) => new ToolInspection().mapToClass(i)) ||
      [];
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = obj.agroBusinessPartition?.mapToApiModel();
    obj.inspections = this.inspections?.map((i: ToolInspection) =>
      i.mapToApiModel()
    );
    return obj;
  }
}

export default FBTool;
