import React from "react";
import i18n from "../../config/configI18n";

import {
  Snackbar,
  Slide,
  Alert,
  Button,
  AlertTitle,
  SlideProps,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { InstallDesktop, InstallMobile, Close } from "@mui/icons-material";

type TransitionProps = Omit<SlideProps, "direction">;

interface Props {
  isOpen: boolean;
  onInstall?: () => void;
  onClose?: () => void;
}
const AddToHomeScreenBanner = (props: Props) => {
  const { isOpen, onInstall, onClose } = props;

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("medium"));

  const icon = /Mobi/.test(navigator.userAgent) ? (
    <InstallMobile sx={{ mt: "2px" }} />
  ) : (
    <InstallDesktop sx={{ mt: "2px" }} />
  );

  return React.useMemo(
    () => (
      <Snackbar
        sx={{
          mb: isLargeScreen ? "96px" : 0,
          display: isOpen ? "flex" : "none",
        }}
        TransitionComponent={(props: TransitionProps) => (
          <Slide {...props} direction="left" />
        )}
        open={isOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          severity="info"
          icon={icon}
          action={
            <React.Fragment>
              <Button color="info" onClick={onInstall}>
                {i18n.t("words.add")}
              </Button>
              <IconButton onClick={onClose}>
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          <AlertTitle sx={{ mr: 2, mt: "2px" }}>
            {i18n.t("components.addToHomescreenBanner.title")}
          </AlertTitle>
        </Alert>
      </Snackbar>
    ),
    [isOpen, isLargeScreen]
  );
};

export default AddToHomeScreenBanner;
