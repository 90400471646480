import FertilizerProduct from "../../fertiliz/FertilizerProduct";
import FertilizerHeavyMetal from "../../catalogue/FertilizerHeavyMetal";

class FBFertilizerProductHeavyMetal {
  id?: number;
  product?: FertilizerProduct;
  heavyMetal?: FertilizerHeavyMetal;
  percentage?: number;

  constructor(
    id?: number,
    product?: FertilizerProduct,
    heavyMetal?: FertilizerHeavyMetal,
    percentage?: number
  ) {
    this.id = id;
    this.product = product;
    this.heavyMetal = heavyMetal;
    this.percentage = percentage;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.product = new FertilizerProduct().mapToClass(obj?.product);
    this.heavyMetal = new FertilizerHeavyMetal().mapToClass(obj?.heavyMetal);
    this.percentage = obj?.percentage;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.product = this.product?.mapToApiModel();
    obj.heavyMetal = this.heavyMetal?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerProductHeavyMetal;
