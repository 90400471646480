import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationES from "../locales/es/translation.json";
import translationCA from "../locales/ca/translation.json";
import translationEU from "../locales/eu/translation.json";
import translationGL from "../locales/gl/translation.json";
import translationEN from "../locales/en/translation.json";
import translationFR from "../locales/fr/translation.json";
import translationIT from "../locales/it/translation.json";
import translationPT from "../locales/pt/translation.json";
import translationDE from "../locales/de/translation.json";

// Translations
const resources = {
  es: { translation: translationES },
  ca: { translation: translationCA },
  eu: { translation: translationEU },
  gl: { translation: translationGL },
  en: { translation: translationEN },
  fr: { translation: translationFR },
  it: { translation: translationIT },
  pt: { translation: translationPT },
  de: { translation: translationDE },
};

const detection = {
  order: [
    "querystring",
    "navigator",
    "cookie",
    "localStorage",
    "sessionStorage",
    "htmlTag",
    "path",
    "subdomain",
  ],
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection,
    resources,
    fallbackLng: "en", // Fallback to english
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
