import FBPhytosanitaryProductStock from "../_apiModels/phyto/FBPhytosanitaryProductStock";

class PhytosanitaryProductStock extends FBPhytosanitaryProductStock {
  constructor() {
    super();
  }

  mapToClass(obj: any): PhytosanitaryProductStock | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default PhytosanitaryProductStock;
