import moment from "moment";

import FBFertilization from "../_apiModels/fertiliz/FBFertilization";
import Document from "../files/Document";
import FertilizerStockMovement from "./FertilizerStockMovement";
import MeasurementUnit from "../catalogue/MeasurementUnit";
import FertilizerProduct from "./FertilizerProduct";

import { dateConverter } from "../../helpers/utils";
import {
  FBESiexRequestStatus,
  FertilizationOriginType,
  FertilizerStockMovementType,
} from "../../constants/enums";
import { FertilizationApplicationOriginInterface } from "../../constants/interfaces";
import {
  APPLICATION_ORIGIN_LIST,
  FERTILIZER_STOCK_MOVEMENT_TYPES,
} from "../../providers/YupProvider";

class Fertilization extends FBFertilization {
  applicationOrigin?: FertilizationApplicationOriginInterface;
  fertilizerProduct?: FertilizerProduct;
  beforeDose?: number;
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;

  constructor(
    applicationOrigin?: FertilizationApplicationOriginInterface,
    fertilizerProduct?: FertilizerProduct,
    beforeDose?: number,
    documents?: Document[]
  ) {
    super();
    this.applicationOrigin = applicationOrigin;
    this.fertilizerProduct = fertilizerProduct;
    this.beforeDose = beforeDose;
    this.documents = documents;
  }

  mapToClass(obj: any): Fertilization | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.applicationOrigin = this.fertigationWaterMix
      ? APPLICATION_ORIGIN_LIST[1]
      : APPLICATION_ORIGIN_LIST[0];
    if (this.stockMovements && this.stockMovements.length > 0)
      this.fertilizerProduct = this.stockMovements[0].product;
    this.beforeDose = this.dose;
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    this.dateFormatted = dateConverter({ date: this.startDate });
    if (this.siexStatus === null) this.siexStatus = FBESiexRequestStatus.NOSYNC;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Apply current date if empty
    if (!this.startDate) obj.startDate = moment().format("YYYY-MM-DD");
    // Fertilizer product
    if (
      //this.applicationOrigin?.code === FertilizationOriginType.PRODUCT &&
      this.fertilizerProduct
    ) {
      const stockMovementObj = {
        product: this.fertilizerProduct.mapToApiModel(),
        date: obj.startDate,
        type: FertilizerStockMovementType.FERTILIZATION,
        quantity: this.dose,
      };
      const stockMovement = new FertilizerStockMovement().mapToClass(
        stockMovementObj
      );
      if (stockMovement) {
        // Set fertilization as movement type
        stockMovement.movementType = FERTILIZER_STOCK_MOVEMENT_TYPES.find(
          (movementType) =>
            movementType.code === FertilizerStockMovementType.FERTILIZATION
        );
        obj.stockMovements = [stockMovement.mapToApiModel()];
      }
      obj.doseUnit = this.fertilizerProduct.measurementUnit;
      // It is product not mixture
      obj.fertigationWaterMix = null;
    }
    // Fertigation water mix
    else if (
      this.applicationOrigin?.code === FertilizationOriginType.MIXTURE &&
      this.fertigationWaterMix
    ) {
      obj.stockMovements = [];
      obj.doseUnit = this.waterMixUnit()?.mapToApiModel();
    }
    if (this.isFertirrigation() && this.cropIrrigation) {
      obj.cropIrrigation = this.cropIrrigation.mapToApiModel();
      obj.cropIrrigation.fertigationApplied = true;
      // Attach crop id to cropIrrigation
      obj.cropIrrigation.crop = { id: this.crop?.id };
    } else obj.cropIrrigation = null;
    obj.declareGoodPractices =
      this.goodPractices && this.goodPractices.length > 0;
    if (!this.advisor) obj.adviceDate = null;
    if (this.siexStatus === FBESiexRequestStatus.NOSYNC) obj.siexStatus = null;

    // Delete custom variables
    delete obj.applicationOrigin;
    delete obj.fertilizerProduct;
    delete obj.beforeDose;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    return obj;
  }

  isFertirrigation(): boolean {
    return ["5", "6"].includes(this.applicationMethod?.code || "");
  }

  waterMixUnit(): MeasurementUnit | undefined {
    return new MeasurementUnit().mapToClass({ id: 4, code: "4", name: "L" });
  }
}

export default Fertilization;
