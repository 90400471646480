import { FBPersonRole } from "../../../constants/enums";
import CueAccount from "../../cueAccount/CueAccount";

class FBCueAccountWithRole {
  cueAccount?: CueAccount;
  role?: FBPersonRole;

  constructor(cueAccount?: CueAccount, role?: FBPersonRole) {
    this.cueAccount = cueAccount;
    this.role = role;
  }

  mapToBaseClass(obj: any): void {
    this.cueAccount = new CueAccount().mapToClass(obj?.cueAccount);
    this.role = obj?.role as FBPersonRole;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBCueAccountWithRole;
