class IrrigationGoodPractice {
  id?: number;
  code?: string;
  description?: string;

  constructor(id?: number, code?: string, description?: string) {
    this.id = id;
    this.code = code;
    this.description = description;
  }

  mapToClass(obj: any): IrrigationGoodPractice | undefined {
    if (!obj) return undefined;
    this.id = obj?.id;
    this.code = obj?.code;
    this.description = obj?.description;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default IrrigationGoodPractice;
