import moment from "moment";

import FBFertilizerStockMovement from "../_apiModels/fertiliz/FBFertilizerStockMovement";
import Document from "../files/Document";
import { FertilizerStockMovementType } from "../../constants/enums";
import { dateConverter } from "../../helpers/utils";
import { FertilizerStockMovementTypeInterface } from "../../constants/interfaces";
import { FERTILIZER_STOCK_MOVEMENT_TYPES } from "../../providers/YupProvider";

class FertilizerStockMovement extends FBFertilizerStockMovement {
  idx?: number;
  movementType?: FertilizerStockMovementTypeInterface;
  beforeQuantity?: number;
  editDisabled?: boolean;
  childRows?: FertilizerStockMovement[];
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;
  priceFormatted?: string;
  totalPrice?: number;

  constructor(
    idx?: number,
    movementType?: FertilizerStockMovementTypeInterface,
    beforeQuantity?: number,
    editDisabled?: boolean,
    childRows?: FertilizerStockMovement[],
    documents?: Document[]
  ) {
    super();
    this.idx = idx;
    this.movementType = movementType;
    this.beforeQuantity = beforeQuantity;
    this.editDisabled = editDisabled;
    this.childRows = childRows;
    this.documents = documents;
  }

  mapToClass(obj: any): FertilizerStockMovement | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    if (obj.type)
      this.movementType = FERTILIZER_STOCK_MOVEMENT_TYPES.find(
        (movementType) => movementType.code === obj.type
      );
    this.quantity =
      this.movementType?.code !== FertilizerStockMovementType.STOCK_ADJUST
        ? Math.abs(this.quantity || 0)
        : this.quantity;
    this.beforeQuantity = this.quantity;
    this.editDisabled =
      this.movementType?.code === FertilizerStockMovementType.FERTILIZATION;
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    this.dateFormatted = dateConverter({ date: this.date });
    if (this.priceByUnit)
      this.priceFormatted = this.getPriceFormatted(this.priceByUnit);
    if (this.quantity && this.priceByUnit)
      this.totalPrice =
        Math.round(this.priceByUnit * this.quantity * 100) / 100;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.type = this.movementType?.code;
    if (
      this.movementType?.code === FertilizerStockMovementType.ELABORATION ||
      this.movementType?.code === FertilizerStockMovementType.FERTILIZATION
    )
      obj.quantity = -1 * Math.abs(this.quantity || 0);
    else if (this.movementType?.code === FertilizerStockMovementType.PURCHASE)
      obj.quantity = Math.abs(this.quantity || 0);
    // Set current date if not exits
    if (!obj.date) obj.date = moment().format("YYYY-MM-DD");
    // Remove custom variables
    delete obj.idx;
    delete obj.movementType;
    delete obj.beforeQuantity;
    delete obj.editDisabled;
    delete obj.childRows;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    delete obj.priceFormatted;
    delete obj.totalPrice;
    return obj;
  }

  getPriceFormatted(price: number): string {
    return `${price} €/${this.product?.measurementUnit?.name}`;
  }
}

export default FertilizerStockMovement;
