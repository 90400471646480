import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";
import { Search } from "@mui/icons-material";

import FormikTextField from "../elements/FormikTextField";
import FormikAutocomplete from "../elements/FormikAutocomplete";

import FertilizerProductMicronutrient from "../../models/fertiliz/FertilizerProductMicronutrient";
import FertilizerMicronutrient from "../../models/catalogue/FertilizerMicronutrient";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

interface DialogFertilizerProductMicronutrientProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (micronutrient: FertilizerProductMicronutrient) => void;
  selected?: FertilizerProductMicronutrient | null;
  micronutrients?: FertilizerProductMicronutrient[];
  productUnit?: MeasurementUnit;
}

const DialogFertilizerProductMicronutrient = (
  props: DialogFertilizerProductMicronutrientProps
) => {
  const YupValidatorSchema = Yup.object().shape({
    micronutrient: Yup.object().required(),
  });

  const {
    open,
    onClose,
    onConfirm,
    selected,
    micronutrients = [],
    productUnit,
  } = props;

  const { selectedABAccount } = useSession();

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: FertilizerProductMicronutrient) => {
    // Needs id to be unique in the table
    if (!values.idx) {
      // Find the max idx of the micronutrients
      const maxIdx = getMaxValueOfList(micronutrients, "idx") + 1;
      values.idx = maxIdx;
    }
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FertilizerProductMicronutrient(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: fertilizerProductMicronutrients, isFetching } = useFetch<
    FertilizerMicronutrient[]
  >({
    queryKey: [
      "fertilizerProductMicronutrients",
      selectedABAccount?.context?.id,
    ],
    enabled: !!selectedABAccount?.context?.id,
  });

  const handleConfirm = () => {
    formik.submitForm();
  };

  return (
    <Dialog open={open} onClose={onClose} className="dialog">
      <DialogTitle className="title">
        {i18n.t("components.dialogFertilizerProductMicronutrient.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t(
            "components.dialogFertilizerProductMicronutrient.description"
          )}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="micronutrient"
              label={i18n.t(
                "components.dialogFertilizerProductMicronutrient.substance"
              )}
              placeholder={i18n.t(
                "components.dialogFertilizerProductMicronutrient.substancePlaceholder"
              )}
              options={fertilizerProductMicronutrients || []}
              optionLabelFieldName="name"
              required
              startIcon={Search}
              loading={isFetching}
            />
          </FormControl>

          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="percentage"
              type="number"
              label={i18n.t(
                "components.dialogFertilizerProductMicronutrient.percentage"
              )}
              placeholder={i18n.t(
                "components.dialogFertilizerProductMicronutrient.percentagePlaceholder"
              )}
              valueUnit={productUnit?.name ? `%/${productUnit.name}` : "%"}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFertilizerProductMicronutrient;
