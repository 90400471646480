import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  FormGroup,
  FormControl,
  FormLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { Cloud, Search } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import TestBanner from "../../components/banners/TestBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import SectionTutorial from "../../components/elements/SectionTutorial";
import AlertEmptyList from "../../components/AlertEmptyList";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";
import Sector from "../../models/agrobusiness/Sector";
import PluviometryRegister from "../../models/pluvio/PluviometryRegister";
import Document from "../../models/files/Document";

import { updateItemOfList } from "../../helpers/utils";

import {
  SnackbarInterface,
  SectionTutorialInterface,
} from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PLUVIOMETRY_SOURCE_TYPES } from "../../providers/YupProvider";
import { helperTextStyle } from "../../components/elements/FormikTextField";

const ExploitationPluviometryScreen = () => {
  const tutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("pluviometry.tutorial.createSectors.label"),
      description: i18n.t("pluviometry.tutorial.createSectors.description"),
      nextBtnText: i18n.t("pluviometry.tutorial.createSectors.nextBtnText"),
    },
  ];

  const PluviometryRegisterValidatorSchema = Yup.object().shape({
    sourceType: Yup.object().required(),
    sourceId: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    totalPrecipitation: Yup.number().required(),
  });

  const pluviometryColumns: Column[] = [
    {
      id: "dateFormatted",
      label: i18n.t("pluviometry.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "timeRange",
      label: i18n.t("pluviometry.column.timeRange"),
      numeric: false,
    },
    {
      id: "totalPrecipitation",
      label: i18n.t("pluviometry.column.totalPrecipitation"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "l/m2",
    },
    {
      id: "sectors",
      label: i18n.t("pluviometry.column.sectors"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "name",
    },
    {
      id: "sourceType.name",
      label: i18n.t("pluviometry.column.sourceType"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: sectorsCount, isFetching: isFetchingSectors } =
    useFetch<number>({
      queryKey: [
        "sectorsCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount?.id,
    });

  const { data: pluviometryRegisters, isFetching } = useFetch<
    PluviometryRegister[]
  >({
    queryKey: [
      "pluviometryRegisters",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const handleSubmit = (values: PluviometryRegister) => {
    switch (formMode) {
      case FormMode.CREATE:
        pluviometryRegisterCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        pluviometryRegisterEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new PluviometryRegister(),
    validationSchema: PluviometryRegisterValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("pluviometry.drawerTitleCreate")
      : i18n.t("pluviometry.drawerTitleEdit");
  const drawerSubtitle = i18n.t("pluviometry.drawerSubtitle");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const pluviometryRegisterCreateMutation = useCrud<PluviometryRegister>({
    key: "postPluviometryRegister",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const pluviometryRegisterEditMutation = useCrud<PluviometryRegister>({
    key: "putPluviometryRegister",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const pluviometryRegisterDeleteMutation = useCrud({
    key: "deletePluviometryRegisters",
    values: pluviometryRegisters?.filter((pr: PluviometryRegister) =>
      selectedRowIds.includes(pr?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    pluviometryRegisterDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickCreateSector = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching || isFetchingSectors} />
      <TestBanner />
      {sectorsCount === 0 && (
        <SectionTutorial
          steps={tutorialSteps}
          activeStep={0}
          onGo={handleClickCreateSector}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("pluviometry.backAlertTitle")}
        contentText={i18n.t("pluviometry.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("pluviometry.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("pluviometry.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={pluviometryRegisterDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("pluviometry.tableTitle")}
        headerText={i18n.t("pluviometry.headerText")}
        colums={pluviometryColumns}
        rows={pluviometryRegisters}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Cloud sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("pluviometry.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("pluviometry.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("pluviometry.emptyTableBtnText")}
        addBtnVariant="contained"
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          pluviometryRegisterCreateMutation.isLoading ||
          pluviometryRegisterEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <PluviometryForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? pluviometryRegisters?.find(
                  (pr: PluviometryRegister) => pr.id === selectedRowIds[0]
                )
              : undefined
          }
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationPluviometryScreen;

interface PluviometryRegisterFormProps {
  formik: FormikProps<PluviometryRegister>;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: PluviometryRegister;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const PluviometryForm = (props: PluviometryRegisterFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<PluviometryRegister>({
    queryKey: ["pluviometryRegister", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: PluviometryRegister) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<PluviometryRegister[]>(
        [
          "pluviometryRegisters",
          selectedABPartition?.id,
          selectedABAccount?.id,
        ],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <div className="exploitation-pluviometry">
      <LoadingWithDelay isVisible={isUpdating} />
      <DataForm {...props} />
      <DatesForm {...props} />
      <SectorsForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("pluviometry.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const DataForm = (props: PluviometryRegisterFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: savedSourceIds, isFetching } = useFetch<string[]>({
    queryKey: ["pluviometrySourceIds", selectedABAccount?.id],
    enabled: !!selectedABAccount,
    onSuccess: (data: string[]) => formik.setFieldValue("sourceId", data[0]),
    onError,
  });

  const sourceIdError = useMemo(
    () => !formik.values.sourceId || formik.values.sourceId?.length === 0,
    [formik.values.sourceId]
  );

  const handleChangeSourceId = (event: any, value: string | null) => {
    formik.setFieldValue("sourceId", value);
  };

  return (
    <BrioCard
      title={i18n.t("pluviometry.dataForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="sourceType"
            label={i18n.t("pluviometry.dataForm.sourceType")}
            optionLabelFieldName="name"
            options={PLUVIOMETRY_SOURCE_TYPES}
            disabled
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <Autocomplete
            className="form-input"
            freeSolo
            options={savedSourceIds || []}
            loading={isFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={i18n.t("pluviometry.dataForm.sourceId")}
                placeholder={i18n.t("pluviometry.dataForm.sourceIdPlaceholder")}
                FormHelperTextProps={{
                  style: {
                    ...helperTextStyle,
                    color: sourceIdError ? "#c62828" : "",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {isFetching ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={sourceIdError}
              />
            )}
            value={formik.values.sourceId || ""}
            onChange={handleChangeSourceId}
            onInputChange={handleChangeSourceId}
          />
          {/*           <FormikAutocomplete
            formik={formik}
            name="sourceId"
            label={i18n.t("pluviometry.dataForm.sourceId")}
            placeholder={i18n.t("pluviometry.dataForm.sourceIdPlaceholder")}
            options={savedSourceIds || []}
            required
            freeSolo
            startIcon={Search}
            loading={isFetching}
            onInputChange={handleSourceIdInputChange}
          /> */}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="totalPrecipitation"
            type="number"
            label={i18n.t("pluviometry.dataForm.totalPrecipitation")}
            valueUnit="l/m2"
            required
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const DatesForm = (props: PluviometryRegisterFormProps) => {
  const { formik, forceExpanded } = props;

  const handleChangeStartDate = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD HH:mm:ss");
    formik.setFieldValue("startDate", dateStr);
    formik.setFieldValue("endDate", dateStr);
  };

  return (
    <BrioCard
      title={i18n.t("pluviometry.datesForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm"
            label={i18n.t("pluviometry.datesForm.startDate")}
            ampm={false}
            value={
              formik.values?.startDate
                ? moment(formik.values.startDate, "YYYY-MM-DD HH:mm:ss")
                : null
            }
            onChange={handleChangeStartDate}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm"
            label={i18n.t("pluviometry.datesForm.endDate")}
            ampm={false}
            value={
              formik.values?.endDate
                ? moment(formik.values.endDate, "YYYY-MM-DD HH:mm:ss")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue(
                "endDate",
                date?.format("YYYY-MM-DD HH:mm:ss")
              )
            }
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const SectorsForm = (props: PluviometryRegisterFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const handleClickNewOption = () => openRedirectAlertDialog();

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
  };

  return (
    <BrioCard title={i18n.t("pluviometry.sectorsForm.title")} optional>
      {!isFetching && sectors?.length === 0 && (
        <AlertEmptyList severity="info" onClick={handleClickNewOption}>
          {i18n.t("pluviometry.sectorsForm.emptyList")}
        </AlertEmptyList>
      )}
      <FormGroup className="form-group">
        <FormikAutocomplete
          formik={formik}
          name="sectors"
          label={i18n.t("pluviometry.sectorsForm.sectors")}
          placeholder={i18n.t("pluviometry.sectorsForm.sectorsPlaceholder")}
          multiple
          options={sectors || []}
          optionLabelFieldName="name"
          loading={isFetching}
          addNewOption
          onClickNewOption={handleClickNewOption}
        />
      </FormGroup>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t("pluviometry.sectorsForm.redirectAlertDialogTitle")}
        contentText={i18n.t(
          "pluviometry.sectorsForm.redirectAlertDialogDescription"
        )}
        open={isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </BrioCard>
  );
};
