import Sector from "../../agrobusiness/Sector";

class FBCropPhytosanitaryTreatmentSector {
  id?: number;
  sector?: Sector;
  treatedArea?: number;

  constructor(id?: number, sector?: Sector, treatedArea?: number) {
    this.id = id;
    this.sector = sector;
    this.treatedArea = treatedArea;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.sector = new Sector().mapToClass(obj?.sector);
    this.treatedArea = obj?.treatedArea;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.sector = this.sector?.mapToApiModel();
    return obj;
  }
}

export default FBCropPhytosanitaryTreatmentSector;
