import AgroActivity from "../../catalogue/AgroActivity";
import AgroActivityDetail from "../../catalogue/AgroActivityDetail";
import ReproductiveMaterialType from "../../catalogue/ReproductiveMaterialType";
import ReproductiveMaterialDetail from "../../catalogue/ReproductiveMaterialDetail";
import SeedProductionCompany from "../../catalogue/SeedProductionCompany";
import CropDestination from "../../catalogue/CropDestination";
import CropSystem from "../../catalogue/CropSystem";
import IrrigationSystemType from "../../catalogue/IrrigationSystemType";
import QualityRegime from "../../catalogue/QualityRegime";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import CropSector from "../../crop/CropSector";
import IrrigationGoodPractice from "../../catalogue/IrrigationGoodPractice";
import CropProductVariety from "../../catalogue/CropProductVariety";
import IrrigationWaterSourceType from "../../catalogue/IrrigationWaterSourceType";
import TreatedSeed from "../../phyto/TreatedSeed";
import CropDisableReason from "../../crop/CropDisableReason";
import LandCoverType from "../../catalogue/LandCoverType";
import CropExploitationSystem from "../../catalogue/CropExploitationSystem";
import OrganicProductionCertificateType from "../../catalogue/OrganicProductionCertificateType";
import { FBESiexRequestStatus } from "../../../constants/enums";

class FBCrop {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  name?: string;
  internalCode?: string;
  varieties?: CropProductVariety[];
  area?: number;
  agroActivity?: AgroActivity;
  agroActivityDetail?: AgroActivityDetail;
  startDate?: string;
  endDate?: string;
  exploitationSystem?: CropExploitationSystem;
  modificationDate?: string;
  reproductiveMaterialType?: ReproductiveMaterialType;
  reproductiveMaterialDetail?: ReproductiveMaterialDetail;
  organicCertification?: OrganicProductionCertificateType;
  integratedProduction?: boolean;
  cropDestination?: CropDestination;
  cropSystem?: CropSystem;
  landCoverTypes?: LandCoverType[];
  irrigationType?: IrrigationSystemType;
  irrigationEnergyType?: string;
  plantNumber?: number;
  plantingYear?: number;
  sowingVsPlantation?: boolean;
  seedQuantity?: number;
  sowingPlantationStartDate?: string;
  sowingPlantationEndDate?: string;
  seedProductionCompany?: SeedProductionCompany;
  plantationFrameHorizontal?: number;
  plantationFrameVertical?: number;
  plantDensity?: number;
  qualityRegimes?: QualityRegime[];
  cropSectors?: CropSector[];
  irrigationGoodPractices?: IrrigationGoodPractice[];
  irrigationWaterSourceTypes?: IrrigationWaterSourceType[];
  treatedSeed?: TreatedSeed;
  helpUserMarketHarvest?: boolean;
  enabledDate?: string;
  disabledDate?: string;
  disabledReason?: CropDisableReason;
  siexEntity?: boolean;
  siexUpdatedOn?: number;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    name?: string,
    internalCode?: string,
    varieties?: CropProductVariety[],
    area?: number,
    agroActivity?: AgroActivity,
    agroActivityDetail?: AgroActivityDetail,
    startDate?: string,
    endDate?: string,
    exploitationSystem?: CropExploitationSystem,
    modificationDate?: string,
    reproductiveMaterialType?: ReproductiveMaterialType,
    reproductiveMaterialDetail?: ReproductiveMaterialDetail,
    organicCertification?: OrganicProductionCertificateType,
    integratedProduction?: boolean,
    cropDestination?: CropDestination,
    cropSystem?: CropSystem,
    landCoverTypes?: LandCoverType[],
    irrigationType?: IrrigationSystemType,
    irrigationEnergyType?: string,
    plantNumber?: number,
    plantingYear?: number,
    sowingVsPlantation?: boolean,
    seedQuantity?: number,
    sowingPlantationStartDate?: string,
    sowingPlantationEndDate?: string,
    seedProductionCompany?: SeedProductionCompany,
    plantationFrameHorizontal?: number,
    plantationFrameVertical?: number,
    plantDensity?: number,
    qualityRegimes?: QualityRegime[],
    cropSectors?: CropSector[],
    irrigationGoodPractices?: IrrigationGoodPractice[],
    irrigationWaterSourceTypes?: IrrigationWaterSourceType[],
    treatedSeed?: TreatedSeed,
    helpUserMarketHarvest?: boolean,
    enabledDate?: string,
    disabledDate?: string,
    disabledReason?: CropDisableReason
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.name = name;
    this.internalCode = internalCode;
    this.varieties = varieties;
    this.area = area;
    this.agroActivity = agroActivity;
    this.agroActivityDetail = agroActivityDetail;
    this.startDate = startDate;
    this.endDate = endDate;
    this.exploitationSystem = exploitationSystem;
    this.modificationDate = modificationDate;
    this.reproductiveMaterialType = reproductiveMaterialType;
    this.reproductiveMaterialDetail = reproductiveMaterialDetail;
    this.organicCertification = organicCertification;
    this.integratedProduction = integratedProduction || false;
    this.cropDestination = cropDestination;
    this.cropSystem = cropSystem;
    this.landCoverTypes = landCoverTypes;
    this.irrigationType = irrigationType;
    this.irrigationEnergyType = irrigationEnergyType;
    this.plantNumber = plantNumber;
    this.plantingYear = plantingYear;
    this.sowingVsPlantation = sowingVsPlantation || false;
    this.seedQuantity = seedQuantity;
    this.sowingPlantationStartDate = sowingPlantationStartDate;
    this.sowingPlantationEndDate = sowingPlantationEndDate;
    this.seedProductionCompany = seedProductionCompany;
    this.plantationFrameHorizontal = plantationFrameHorizontal;
    this.plantationFrameVertical = plantationFrameVertical;
    this.plantDensity = plantDensity;
    this.qualityRegimes = qualityRegimes;
    this.cropSectors = cropSectors;
    this.irrigationGoodPractices = irrigationGoodPractices;
    this.irrigationWaterSourceTypes = irrigationWaterSourceTypes;
    this.treatedSeed = treatedSeed;
    this.helpUserMarketHarvest = helpUserMarketHarvest;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.disabledReason = disabledReason;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.name = obj?.name;
    this.internalCode = obj?.internalCode;
    this.varieties =
      obj?.varieties?.map((variety: any) =>
        new CropProductVariety().mapToClass(variety)
      ) || [];
    this.area = obj?.area;
    this.agroActivity = new AgroActivity().mapToClass(obj?.agroActivity);
    this.agroActivityDetail = new AgroActivityDetail().mapToClass(
      obj?.agroActivityDetail
    );
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.exploitationSystem = new CropExploitationSystem().mapToClass(
      obj?.exploitationSystem
    );
    this.modificationDate = obj?.modificationDate;
    this.reproductiveMaterialType = new ReproductiveMaterialType().mapToClass(
      obj?.reproductiveMaterialType
    );
    this.reproductiveMaterialDetail =
      new ReproductiveMaterialDetail().mapToClass(
        obj?.reproductiveMaterialDetail
      );
    this.organicCertification =
      new OrganicProductionCertificateType().mapToClass(
        obj?.organicCertification
      );
    this.integratedProduction = obj?.integratedProduction || false;
    this.cropDestination = new CropDestination().mapToClass(
      obj?.cropDestination
    );
    this.cropSystem = new CropSystem().mapToClass(obj?.cropSystem);
    this.landCoverTypes =
      obj?.landCoverTypes?.map((landCoverType: any) =>
        new LandCoverType().mapToClass(landCoverType)
      ) || [];
    this.irrigationType = new IrrigationSystemType().mapToClass(
      obj?.irrigationType
    );
    this.irrigationEnergyType = obj?.irrigationEnergyType;
    this.plantNumber = obj?.plantNumber;
    this.plantingYear = obj?.plantingYear;
    this.sowingVsPlantation = obj?.sowingVsPlantation || false;
    this.seedQuantity = obj?.seedQuantity;
    this.sowingPlantationStartDate = obj?.sowingPlantationStartDate;
    this.sowingPlantationEndDate = obj?.sowingPlantationEndDate;
    this.seedProductionCompany = new SeedProductionCompany().mapToClass(
      obj?.seedProductionCompany
    );
    this.plantationFrameHorizontal = obj?.plantationFrameHorizontal;
    this.plantationFrameVertical = obj?.plantationFrameVertical;
    this.plantDensity = obj?.plantDensity;
    this.qualityRegimes =
      obj?.qualityRegimes?.map((qualityRegime: any) =>
        new QualityRegime().mapToClass(qualityRegime)
      ) || [];
    this.cropSectors =
      obj?.cropSectors
        ?.map((cropSector: any) => new CropSector().mapToClass(cropSector))
        ?.filter(
          (cropSector: CropSector) => !cropSector?.sector?.disabledDate
        ) || [];
    this.irrigationGoodPractices =
      obj?.irrigationGoodPractices?.map((irrigationGoodPractice: any) =>
        new IrrigationGoodPractice().mapToClass(irrigationGoodPractice)
      ) || [];
    this.irrigationWaterSourceTypes =
      obj?.irrigationWaterSourceTypes?.map((iwst: any) =>
        new IrrigationWaterSourceType().mapToClass(iwst)
      ) || [];
    this.treatedSeed = new TreatedSeed().mapToClass(obj?.treatedSeed);
    this.helpUserMarketHarvest = obj?.helpUserMarketHarvest;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.disabledReason = new CropDisableReason().mapToClass(
      obj?.disabledReason
    );
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.varieties =
      this.varieties?.map((variety: CropProductVariety) =>
        variety.mapToApiModel()
      ) || [];
    obj.agroActivity = this.agroActivity?.mapToApiModel();
    obj.agroActivityDetail = this.agroActivityDetail?.mapToApiModel();
    obj.exploitationSystem = this.exploitationSystem?.mapToApiModel();
    obj.reproductiveMaterialType =
      this.reproductiveMaterialType?.mapToApiModel();
    obj.reproductiveMaterialDetail =
      this.reproductiveMaterialDetail?.mapToApiModel();
    obj.organicCertification = this.organicCertification?.mapToApiModel();
    obj.cropDestination = this.cropDestination?.mapToApiModel();
    obj.cropSystem = this.cropSystem?.mapToApiModel();
    obj.landCoverTypes =
      this.landCoverTypes?.map((landCoverType: LandCoverType) =>
        landCoverType.mapToApiModel()
      ) || [];
    obj.irrigationType = this.irrigationType?.mapToApiModel();
    obj.seedProductionCompany = this.seedProductionCompany?.mapToApiModel();
    obj.qualityRegimes =
      this.qualityRegimes?.map((qualityRegime: QualityRegime) =>
        qualityRegime.mapToApiModel()
      ) || [];
    obj.cropSectors =
      this.cropSectors?.map((cropSector: CropSector) =>
        cropSector.mapToApiModel()
      ) || [];
    obj.irrigationGoodPractices =
      this.irrigationGoodPractices?.map(
        (irrigationGoodPractice: IrrigationGoodPractice) =>
          irrigationGoodPractice.mapToApiModel()
      ) || [];
    obj.irrigationWaterSourceTypes =
      this.irrigationWaterSourceTypes?.map((iwst: IrrigationWaterSourceType) =>
        iwst.mapToApiModel()
      ) || [];
    obj.treatedSeed = this.treatedSeed?.mapToApiModel();
    obj.disabledReason = this.disabledReason?.mapToApiModel();
    return obj;
  }
}

export default FBCrop;
