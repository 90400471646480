import moment from "moment";

import FBDocument from "../_apiModels/files/FBDocument";

import { dateConverter } from "../../helpers/utils";

class Document extends FBDocument {
  idx?: number;
  file?: File;
  documentLink?: string;
  thumbnailLink?: string;
  dateFormatted?: string;
  creationDate?: string;

  constructor(
    idx?: number,
    file?: File,
    documentLink?: string,
    thumbnailLink?: string
  ) {
    super();
    this.idx = idx;
    this.file = file;
    this.documentLink = documentLink;
    this.thumbnailLink = thumbnailLink;
  }

  mapToClass(obj: any): Document | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    this.documentLink = `${process.env.REACT_APP_API_BASE_URL}${this.documentUrl}`;
    this.thumbnailLink = `${process.env.REACT_APP_API_BASE_URL}${this.thumbnailUrl}`;
    this.dateFormatted = dateConverter({ date: this.date });
    this.creationDate = moment((this.creationInstant as number) * 1000).format(
      "DD MMM YY HH:mm"
    );
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    if (!this.date) obj.date = moment().format("YYYY-MM-DD");

    // Remove custom variables
    delete obj.idx;
    delete obj.file;
    delete obj.documentLink;
    delete obj.thumbnailLink;
    delete obj.dateFormatted;
    delete obj.creationDate;
    return obj;
  }
}

export default Document;
