import FertilizerTank from "../../fertiliz/FertilizerTank";

class FBCropIrrigationFertilizerTank {
  id?: number;
  fertilizerTank?: FertilizerTank;
  quantity?: number;

  constructor(id?: number, fertilizerTank?: FertilizerTank, quantity?: number) {
    this.id = id;
    this.fertilizerTank = fertilizerTank;
    this.quantity = quantity;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.fertilizerTank = new FertilizerTank().mapToClass(obj?.fertilizerTank);
    this.quantity = obj?.quantity;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.fertilizerTank = this.fertilizerTank?.mapToApiModel();
    return obj;
  }
}

export default FBCropIrrigationFertilizerTank;
