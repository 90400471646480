import FBOwner from "../_apiModels/agrobusiness/FBOwner";
import Document from "../files/Document";

import { LeaderTypeInterface } from "../../constants/interfaces";
import { LEADER_TYPES } from "../../providers/YupProvider";
import { LeaderType } from "../../constants/enums";

class Owner extends FBOwner {
  idx?: string;
  leaderType?: LeaderTypeInterface;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(idx?: string, leaderType?: LeaderTypeInterface) {
    super();
    this.idx = idx;
    this.leaderType = leaderType || this.getOwnerType();
  }

  mapToClass(obj: any): Owner | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = LeaderType.OWNER;
    this.leaderType = LEADER_TYPES.find(
      (leaderType) => leaderType.code === LeaderType.OWNER
    );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.idx;
    delete obj.leaderType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  getOwnerType = (): LeaderTypeInterface | undefined => {
    return LEADER_TYPES.find(
      (leaderType) => leaderType.code === LeaderType.OWNER
    );
  };
}

export default Owner;
