import FBCropPhytosanitaryTreatment from "../_apiModels/phyto/FBCropPhytosanitaryTreatment";
import MeasurementUnit from "../catalogue/MeasurementUnit";
import PlantDisease from "../catalogue/PlantDisease";
import PlantPlague from "../catalogue/PlantPlague";
import PlantProblem from "../catalogue/PlantProblem";
import PlantRegulatorsAndOthers from "../catalogue/PlantRegulatorsAndOthers";
import PlantWeed from "../catalogue/PlantWeed";
import Document from "../files/Document";

import { PlantProblemType } from "../../constants/enums";
import {
  PHYTO_DEFENSE_ACTION_QTY_CODES,
  PHYTO_DEFENSE_ACTION_REGISTER_ID_CODES,
} from "../../constants/lists";

class CropPhytosanitaryTreatment extends FBCropPhytosanitaryTreatment {
  defenseAction?: boolean;
  plantProblems?: PlantProblem[];
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(defenseAction?: boolean, documents?: Document[]) {
    super();
    this.defenseAction = defenseAction || false;
    this.documents = documents;
  }

  mapToClass(obj: any): CropPhytosanitaryTreatment | undefined {
    if (!obj) return undefined;
    super.mapToBaseClass(obj);
    this.defenseAction = !!this.defenseActionType;
    this.plantProblems = this.getPlantProblems(
      this.weeds,
      this.plagues,
      this.diseases,
      this.regulators
    );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    if (!this.defenseAction) {
      obj.defenseActionType = null;
      obj.defenseActionQuantity = null;
      obj.defenseActionQuantityUnit = null;
      obj.defenseActionRegisterId = null;
    }

    [obj.weeds, obj.plagues, obj.diseases, obj.regulators] =
      this.setPlantProblems(this.plantProblems || []);

    // Delete not API fields
    delete obj.defenseAction;
    delete obj.plantProblems;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isDefenseQtyVisible(): boolean {
    return PHYTO_DEFENSE_ACTION_QTY_CODES.includes(
      this.defenseActionType?.code || "0"
    );
  }

  isDefenseRegisterIdVisible(): boolean {
    return PHYTO_DEFENSE_ACTION_REGISTER_ID_CODES.includes(
      this.defenseActionType?.code || "0"
    );
  }

  getMeasurementUnitsByDefenseAction(
    measurementUnits?: MeasurementUnit[]
  ): MeasurementUnit[] {
    if (!measurementUnits || !this.defenseActionType?.code) return [];

    let validCodes: string[] = [];
    switch (this.defenseActionType.code) {
      case "1":
        validCodes = ["15"];
        break;
      case "2":
        validCodes = ["15", "16"];
        break;
      case "3":
        validCodes = ["28"];
        break;
      case "13":
        validCodes = ["17", "18"];
        break;
      case "14":
        validCodes = ["16"];
        break;
      default:
        return measurementUnits;
    }
    return measurementUnits.filter((mu) =>
      validCodes.includes(mu?.code || "0")
    );
  }

  getPlantProblems = (
    weeds?: PlantWeed[],
    plagues?: PlantPlague[],
    diseases?: PlantDisease[],
    regulators?: PlantRegulatorsAndOthers[]
  ): PlantProblem[] => {
    const plantProblems: PlantProblem[] = [];
    if (weeds && weeds.length > 0) {
      weeds.forEach((weed) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...weed,
          type: PlantProblemType.WEED,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (plagues && plagues.length > 0) {
      plagues.forEach((plague) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...plague,
          type: PlantProblemType.PLAGUE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (diseases && diseases.length > 0) {
      diseases.forEach((disease) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...disease,
          type: PlantProblemType.DISEASE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (regulators && regulators.length > 0) {
      regulators.forEach((regulator) => {
        const plantProblem = new PlantProblem().mapToClass({
          ...regulator,
          type: PlantProblemType.REGULATORS,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    return plantProblems;
  };

  setPlantProblems(
    plantProblems: PlantProblem[]
  ): [
    weeds: PlantWeed[],
    plagues: PlantPlague[],
    diseases: PlantDisease[],
    regulators: PlantRegulatorsAndOthers[]
  ] {
    const weeds: PlantWeed[] = [];
    const plagues: PlantPlague[] = [];
    const diseases: PlantDisease[] = [];
    const regulators: PlantRegulatorsAndOthers[] = [];

    plantProblems.forEach((plantProblem) => {
      switch (plantProblem.type) {
        case PlantProblemType.WEED:
          const plantWeed = new PlantWeed().mapToClass({ ...plantProblem });
          if (plantWeed) weeds.push(plantWeed);
          break;
        case PlantProblemType.PLAGUE:
          const plantPlague = new PlantPlague().mapToClass({ ...plantProblem });
          if (plantPlague) plagues.push(plantPlague);
          break;
        case PlantProblemType.DISEASE:
          const plantDisease = new PlantDisease().mapToClass({
            ...plantProblem,
          });
          if (plantDisease) diseases.push(plantDisease);
          break;
        case PlantProblemType.REGULATORS:
          const plantRegulator = new PlantRegulatorsAndOthers().mapToClass({
            ...plantProblem,
          });
          if (plantRegulator) regulators.push(plantRegulator);
          break;

        default:
          break;
      }
    });

    return [weeds, plagues, diseases, regulators];
  }
}

export default CropPhytosanitaryTreatment;
