import moment from "moment";

import FBCropIrrigation from "../_apiModels/crop/FBCropIrrigation";
import Document from "../files/Document";
import MeasurementUnit from "../catalogue/MeasurementUnit";

import EnergyType from "../catalogue/EnergyType";
import { dateConverter } from "../../helpers/utils";
import { ENERGY_TYPES } from "../../providers/YupProvider";
import { FBESiexRequestStatus } from "../../constants/enums";

// To avoid call to the API
export const IRRIGATION_MEASUREMENT_UNIT_LIST: MeasurementUnit[] = [
  new MeasurementUnit(18, "L/ha", "18"),
  new MeasurementUnit(19, "m3/ha", "19"),
];

class CropIrrigation extends FBCropIrrigation {
  date?: string;
  timeRange?: string;
  eType?: EnergyType;
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;

  constructor(
    date?: string,
    timeRange?: string,
    eType?: EnergyType,
    documents?: Document[]
  ) {
    super();
    this.date = date;
    this.timeRange = timeRange;
    this.eType = eType || ENERGY_TYPES[0];
    this.documents = documents;
  }

  mapToClass(obj: any): CropIrrigation | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.startTime) {
      this.date = moment(this.startTime, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD"
      );
      this.dateFormatted = dateConverter({
        date: this.startTime,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
      });
    }
    if (this.startTime && this.endTime)
      this.timeRange = `${moment(this.startTime, "YYYY-MM-DD HH:mm:ss").format(
        "HH:mm"
      )}h - ${moment(this.endTime, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}h`;
    this.eType =
      ENERGY_TYPES.find(
        (energyType: EnergyType) => energyType.code === obj.energyType
      ) || ENERGY_TYPES[0];
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    if (this.siexStatus === null) this.siexStatus = FBESiexRequestStatus.NOSYNC;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.energyType = this.eType?.mapToApiModel();
    obj.goodPracticesDeclared =
      this.goodPractices && this.goodPractices.length > 0;
    if (this.siexStatus === FBESiexRequestStatus.NOSYNC) obj.siexStatus = null;

    // Remove custom variables
    delete obj.date;
    delete obj.timeRange;
    delete obj.eType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    return obj;
  }
}

export default CropIrrigation;
