import { useState, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FormikProps } from "formik";
import moment from "moment";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl, Collapse } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Search } from "@mui/icons-material";

import { BrioCard } from "../BrioCard";
import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import FormikSelect from "../elements/FormikSelect";
import FilesForm from "../../components/forms/FilesForm";
import LoadingWithDelay from "../elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import PhytosanitaryProduct, {
  MEASUREMENT_UNIT_CODES,
} from "../../models/phyto/PhytosanitaryProduct";
import RegisteredPhytosanitaryProduct from "../../models/vademecum/RegisteredPhytosanitaryProduct";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";
import PhytosanitaryProductType from "../../models/catalogue/PhytosanitaryProductType";
import Document from "../../models/files/Document";

import { PhytosanitaryStockMovementType } from "../../constants/enums";
import { SnackbarInterface } from "../../constants/interfaces";
import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../../constants/constants";

import { updateItemOfList } from "../../helpers/utils";

interface PhytosanitaryProductFormProps {
  movementType: PhytosanitaryStockMovementType;
  formik: FormikProps<PhytosanitaryProduct>;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: PhytosanitaryProduct;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const PhytosanitaryProductForm = (props: PhytosanitaryProductFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const queryClient = useQueryClient();

  const onFetchPhytoProductSuccess = (
    phytosanitaryProduct: PhytosanitaryProduct
  ) => {
    formik.setValues(phytosanitaryProduct);
    // Update in array without refetch
    queryClient.setQueryData<PhytosanitaryProduct[]>(
      ["phytosanitaryProducts", selectedABAccount?.id],
      (oldData) => updateItemOfList(oldData, phytosanitaryProduct, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<PhytosanitaryProduct>({
    queryKey: ["phytosanitaryProduct", selectedEditRow?.id],
    enabled: !!selectedEditRow?.id,
    selected: selectedEditRow,
    onSuccess: onFetchPhytoProductSuccess,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <PhytosanitaryProductDataForm {...props} onError={onError} />
      <PhytosanitaryProductCompositionForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t(
          "components.phytosanitaryProductForm.filesFormSubtitle"
        )}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

export default PhytosanitaryProductForm;

let searchWordTimeout: NodeJS.Timeout | null;
const PhytosanitaryProductDataForm = (props: PhytosanitaryProductFormProps) => {
  const { formik, forceExpanded, selectedEditRow, onError } = props;

  const { selectedABAccount } = useSession();

  const [searchWord, setSearchWord] = useState<string>("");

  const noOptionsText = useMemo(
    () =>
      searchWord.length === 0
        ? i18n.t("components.phytosanitaryProductForm.searchProductPlaceholder")
        : i18n.t("formErrors.notFoundResults"),
    [searchWord]
  );

  const {
    data: registeredPhytosanitaryProducts,
    isFetching: isFetchingRegisteredPhytosanitaryProducts,
  } = useFetch<RegisteredPhytosanitaryProduct[]>({
    queryKey: [
      "registeredPhytosanitaryProducts",
      selectedABAccount?.context?.id,
      searchWord,
    ],
    selected: searchWord,
    enabled: !!selectedABAccount && searchWord.length > 0,
    onError,
  });

  const handlePhytoProductTypesSuccess = (data: PhytosanitaryProductType[]) => {
    if (!formik.values.type) formik.setFieldValue("type", data[0]);
  };

  const { data: productTypes, isFetching: isFetchingProductTypes } = useFetch<
    PhytosanitaryProductType[]
  >({
    queryKey: ["phytosanitaryProductTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onSuccess: handlePhytoProductTypesSuccess,
    onError,
  });

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const handleChangePhytosanitaryProductSearchWord = (
    event: any,
    value: string
  ) => {
    if (searchWordTimeout) {
      clearTimeout(searchWordTimeout);
      searchWordTimeout = null;
    }
    searchWordTimeout = setTimeout(() => {
      const searchWord = encodeURIComponent(value.trim());
      setSearchWord(searchWord);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangePhytosanitaryProduct = (
    product: RegisteredPhytosanitaryProduct
  ) => {
    formik.setFieldValue("registeredPhytosanitaryProduct", product);
    formik.setFieldValue("name", product.name);
  };

  const handleChangeType = (type: PhytosanitaryProductType) => {
    formik.setFieldValue("type", type);
    formik.setFieldValue("registeredPhytosanitaryProduct", null);
    if (type.code === "1") {
      formik.resetForm();
      if (selectedEditRow) formik.setFieldValue("id", selectedEditRow.id);
    }
  };

  return (
    <BrioCard
      title={i18n.t("components.phytosanitaryProductForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="type"
            label={i18n.t("components.phytosanitaryProductForm.typeLabel")}
            required
            isLoading={isFetchingProductTypes}
            options={productTypes || []}
            optionLabelFieldName="name"
            onChange={handleChangeType}
          />
        </FormControl>
        {!formik.values.isExceptional() && (
          <FormControl className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="registeredPhytosanitaryProduct"
              label={i18n.t(
                "components.phytosanitaryProductForm.registeredProduct_v2"
              )}
              placeholder={i18n.t(
                "components.phytosanitaryProductForm.registeredProductPlaceholder"
              )}
              noOptionsText={noOptionsText}
              options={registeredPhytosanitaryProducts || []}
              optionLabelFieldName="name"
              startIcon={Search}
              loading={isFetchingRegisteredPhytosanitaryProducts}
              onInputChange={handleChangePhytosanitaryProductSearchWord}
              onChange={handleChangePhytosanitaryProduct}
            />
          </FormControl>
        )}

        <Collapse
          in={
            formik.values.isExceptional() ||
            !!formik.values?.registeredPhytosanitaryProduct
          }
        >
          <div className="grow-container">
            {!formik.values.isRegistered() && (
              <FormControl variant="outlined" className="form-control">
                <FormikTextField
                  formik={formik}
                  name="name"
                  label={i18n.t("components.phytosanitaryProductForm.name")}
                  required
                />
              </FormControl>
            )}
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name={
                  formik.values.isExceptional()
                    ? "owner"
                    : "registeredPhytosanitaryProduct.owner"
                }
                label={i18n.t("components.phytosanitaryProductForm.owner")}
                disabled={!formik.values.isExceptional()}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <DatePicker
                className="form-input"
                format="DD/MM/YYYY"
                label={i18n.t(
                  "components.phytosanitaryProductForm.expirationDate"
                )}
                value={moment(
                  formik.values.isExceptional()
                    ? formik.values.expirationDate
                    : formik.values.registeredPhytosanitaryProduct
                        ?.expirationDate,
                  "YYYY-MM-DD"
                )}
                onChange={(date) =>
                  formik.setFieldValue(
                    formik.values.isExceptional()
                      ? "expirationDate"
                      : "registeredPhytosanitaryProduct.expirationDate",
                    date?.format("YYYY-MM-DD")
                  )
                }
                disabled={!formik.values.isExceptional()}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control-row">
              <FormikTextField
                className="form-input"
                formik={formik}
                name={
                  formik.values.isExceptional()
                    ? "registerId"
                    : "registeredPhytosanitaryProduct.registerId"
                }
                label={i18n.t("components.phytosanitaryProductForm.registerId")}
                disabled={!formik.values.isExceptional()}
              />
              <FormikSelect
                formik={formik}
                type="row"
                style={{ minWidth: "150px" }}
                name="measurementUnit"
                label={i18n.t(
                  "components.phytosanitaryProductForm.measurementUnit"
                )}
                optionLabelFieldName="name"
                required
                options={measurementUnits || []}
                isLoading={isFetchingMeasurementUnits}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const PhytosanitaryProductCompositionForm = (
  props: PhytosanitaryProductFormProps
) => {
  const { formik } = props;

  return (
    <Collapse
      in={
        formik.values.isExceptional() ||
        !!formik.values?.registeredPhytosanitaryProduct
      }
    >
      <div className="grow-container">
        <BrioCard
          title={i18n.t("components.phytosanitaryProductCompositionForm.title")}
          defaultExpanded
          optional
        >
          <FormGroup className="form-group">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name={
                  formik.values.isExceptional()
                    ? "formulation"
                    : "registeredPhytosanitaryProduct.formulation"
                }
                label={i18n.t(
                  "components.phytosanitaryProductCompositionForm.formulation"
                )}
                disabled={!formik.values.isExceptional()}
              />
            </FormControl>
          </FormGroup>
        </BrioCard>
      </div>
    </Collapse>
  );
};
