import FBToolInspection from "../_apiModels/agrobusiness/FBToolInspection";
import { TOOL_INSPECTION_RESULT_LIST } from "../../providers/YupProvider";
import { ToolInspectionResultInterface } from "../../constants/interfaces";

class ToolInspection extends FBToolInspection {
  idx?: number;
  resultType?: ToolInspectionResultInterface;

  constructor(idx?: number, resultType?: ToolInspectionResultInterface) {
    super();
    this.idx = idx;
    this.resultType = resultType;
  }

  mapToClass(obj: any): ToolInspection | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    this.idx = this.id;
    if (obj?.result)
      this.resultType = TOOL_INSPECTION_RESULT_LIST.find(
        (resultType) => resultType.code === obj.result
      );
    return this;
  }

  mapToApiModel(): any {
    const obj = this.mapBaseToApiModel();
    obj.result = this.resultType?.code;

    // Delete custom variables
    delete obj.idx;
    delete obj.resultType;
    return obj;
  }
}

export default ToolInspection;
