import { Collapse, Alert, IconButton, AlertTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import i18n from "../../config/configI18n";

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
}
const TestBanner = (props: Props) => {
  const { isOpen = true, onClose } = props;

  const bannerAction = onClose && (
    <IconButton color="inherit" onClick={onClose}>
      <Close />
    </IconButton>
  );

  if (process.env.REACT_APP_ENV === "prod") return null;

  return (
    <Collapse in={isOpen}>
      <Alert severity="warning" action={bannerAction}>
        <AlertTitle>{i18n.t("components.testBanner.title")}</AlertTitle>
        <b>{i18n.t("components.testBanner.description")}</b>
      </Alert>
    </Collapse>
  );
};

export default TestBanner;
