import { useState } from "react";
import i18n from "../../config/configI18n";
import moment from "moment";

import { Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

interface Props {
  isSync?: boolean;
  timestamp?: number;
}
const SiexDownloadStatusBanner = (props: Props) => {
  const { isSync = false, timestamp } = props;

  const [isOpenContent, setIsOpenContent] = useState<boolean>(false);

  const onClickCollapse = () => setIsOpenContent((state) => !state);

  return (
    <div className={`siex-status-banner-${isSync ? "ok" : "error"}`}>
      <div className="siex-status-banner-head">
        <div className="siex-status-banner-head-left">
          <p>{i18n.t("components.siexStatusBanner.siexREAStatusTitle")}</p>
        </div>
        <div className="siex-status-banner-head-right">
          {timestamp && (
            <p className="siex-status-banner-date">
              {moment(timestamp * 1000).format("DD MMM HH:mm")}
            </p>
          )}
        </div>
      </div>
      <div className="siex-status-banner-status">
        <div className={`siex-status-banner-icon-${isSync ? "ok" : "error"}`} />
        <h1 className="siex-status-banner-title">
          {isSync
            ? i18n.t("components.siexStatusBanner.downloadSyncLabel")
            : i18n.t("components.siexStatusBanner.downloadManualLabel")}
        </h1>
        <IconButton onClick={onClickCollapse}>
          {isOpenContent ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </div>
      <Collapse in={isOpenContent}>
        <div className="siex-status-banner-content">
          <p>
            {i18n.t(
              `components.siexStatusBanner.${
                isSync ? "downloadSyncDescription" : "downloadManualDescription"
              }`
            )}
          </p>
          <p>
            {i18n.t(
              `components.siexStatusBanner.${
                isSync
                  ? "downloadSyncDescription2"
                  : "downloadManualDescription2"
              }`
            )}
          </p>
          <p>
            {i18n.t(
              `components.siexStatusBanner.${
                isSync
                  ? "downloadSyncDescription3"
                  : "downloadManualDescription3"
              }`
            )}
          </p>
        </div>
      </Collapse>
    </div>
  );
};

export default SiexDownloadStatusBanner;
