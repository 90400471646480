import { WaterSourceTypeInterface } from "../../constants/interfaces";
import { WATER_SOURCES_TYPES } from "../../providers/YupProvider";
import FBWaterSource from "../_apiModels/agrobusiness/FBWaterSource";
import Document from "../files/Document";

class WaterSource extends FBWaterSource {
  waterSourceType?: WaterSourceTypeInterface;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(
    waterSourceType?: WaterSourceTypeInterface,
    documents?: Document[]
  ) {
    super();
    this.waterSourceType = waterSourceType;
    this.documents = documents;
  }

  mapToClass(obj: any): WaterSource | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (obj.type)
      this.waterSourceType = WATER_SOURCES_TYPES.find(
        (waterSourceType) => waterSourceType.code === obj.type
      );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.type = this.waterSourceType?.code;
    // Remove custom variables
    delete obj.waterSourceType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default WaterSource;
