import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import {
  Stepper,
  Step,
  StepLabel,
  FormGroup,
  FormControl,
  Button,
  Collapse,
  Link,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AlertSnackbar from "../components/elements/AlertSnackbar";
import TestBanner from "../components/banners/TestBanner";
import FormikTextField from "../components/elements/FormikTextField";
import FormikListItemSwitch from "../components/elements/FormikListItemSwitch";
import FormikAutocomplete from "../components/elements/FormikAutocomplete";
import { TableBrioCard } from "../components/BrioCard";
import DialogSector from "../components/dialogs/DialogSector";
import DialogCrop from "../components/dialogs/DialogCrop";
import OnBoardingSiexBanner from "../components/banners/OnBoardingSiexBanner";

import RegisterRegion from "../models/RegisterRegion";
import LegalContext from "../models/LegalContext";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import Crop from "../models/crop/Crop";
import Sector from "../models/agrobusiness/Sector";
import { Column } from "../models/Generic";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useCrud from "../hooks/useCrud";
import useFetch from "../hooks/useFetch";

import { PUBLIC_ROUTES } from "../routes/routeNames";
import { SnackbarInterface } from "../constants/interfaces";
import { SIGPAC_MAP_URL } from "../constants/constants";

interface formProps {
  onSubscribeRequested?: () => void;
  onSkipTutorial?: () => void;
  onMessage?: (snackBarMessage: SnackbarInterface) => void;
  onCompleted?: () => void;
}
const RegisterForm = ({ onMessage, onCompleted }: formProps) => {
  const cueAccountValidatorSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t("formErrors.requiredField")),
    registerRegion: Yup.object().when("siexEnabled", {
      is: true,
      then: (schema) => schema.required(i18n.t("formErrors.requiredField")),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const { logout } = useAuth();
  const { changeSelectedABA } = useSession();

  const [policiesAccepted, setPoliciesAccepted] = useState<boolean>(false);

  const handleSubmit = (values: AgroBusinessAccount) => {
    if (policiesAccepted) personWithAgroBusinessCreateMutation.mutate(values);
    else
      onMessage &&
        onMessage({
          severity: "warning",
          message: i18n.t("formErrors.policiesError"),
        } as SnackbarInterface);
  };

  const handleCompleted = (data: AgroBusinessAccount) => {
    changeSelectedABA(data);
    onCompleted && onCompleted();
  };

  const formik = useFormik({
    initialValues: new AgroBusinessAccount(),
    validationSchema: cueAccountValidatorSchema,
    onSubmit: handleSubmit,
  });

  const { data: registerRegions, isFetching } = useFetch<RegisterRegion[]>({
    queryKey: ["registerRegions"],
    selected: { id: 1 } as LegalContext,
    enabled: !!formik.values?.siexEnabled,
    onError: onMessage,
  });

  const personWithAgroBusinessCreateMutation = useCrud<AgroBusinessAccount>({
    key: "postPersonWithAgroBusiness",
    values: formik.values,
    onSuccess: handleCompleted,
    onError: onMessage,
  });

  const handleChangePoliciesCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPoliciesAccepted(event.target.checked);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="form-section">
      <h3>{i18n.t("onBoarding.register.title")}</h3>
      <p>{i18n.t("onBoarding.register.span1")}</p>
      <p>{i18n.t("onBoarding.register.span2")}</p>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("onBoarding.register.name")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="siexEnabled"
            primaryText={i18n.t("onBoarding.register.siexEnabled")}
            secondaryText={i18n.t("onBoarding.register.siexEnabledSubtitle")}
          />
        </FormControl>
        <Collapse in={formik.values.siexEnabled}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikAutocomplete
                formik={formik}
                name="registerRegion"
                label={i18n.t("onBoarding.register.registerRegion")}
                optionLabelFieldName="name"
                required
                options={registerRegions || []}
                loading={isFetching}
                disabled={!formik.values?.siexEnabled}
              />
            </FormControl>
          </div>
        </Collapse>
        <FormControlLabel
          control={
            <Checkbox
              checked={policiesAccepted}
              onChange={handleChangePoliciesCheckbox}
            />
          }
          label={
            <Link
              sx={{
                color: (theme) => `${theme.palette.primary.light} !important`,
              }}
              href={`${process.env.REACT_APP_WEB_URL}${PUBLIC_ROUTES.POLICIES}`}
              target="_blank"
              rel="noopener noreferrer"
              underline="none"
            >
              {i18n.t("onBoarding.register.policiesLabel")}
            </Link>
          }
        />
      </FormGroup>
      <LoadingButton
        variant="contained"
        fullWidth
        sx={{ mt: 4 }}
        size="large"
        loading={personWithAgroBusinessCreateMutation.isLoading}
        onClick={() => formik.submitForm()}
      >
        {i18n.t("onBoarding.register.registerBtn")}
      </LoadingButton>
      <Button
        sx={{ mt: 4 }}
        color="inherit"
        variant="outlined"
        onClick={handleLogout}
      >
        {i18n.t("onBoarding.register.switchAccountBtn")}
      </Button>
    </div>
  );
};

const SectorsForm = ({
  onMessage,
  onSubscribeRequested,
  onSkipTutorial,
}: formProps) => {
  const sectorColumns: Column[] = [
    { id: "name", label: i18n.t("sectors.column.name"), numeric: false },
    {
      id: "area",
      label: i18n.t("sectors.column.area"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
  ];

  const { selectedABAccount, selectedABPartition } = useSession();

  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
  });

  const sectorDeleteMutation = useCrud({
    key: "deleteSectors",
    values: sectors?.filter((s: Sector) => selectedRowIds.includes(s?.id || 0)),
    onSuccess: () => {
      setSelectedRowIds([]);
    },
    onError: (error: SnackbarInterface) => {
      onMessage && onMessage(error);
      closeDialog();
    },
  });

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleCloseDialog = () => {
    setSelectedRowIds([]);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    openDialog();
  };

  const handleClickDelete = () => {
    sectorDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <div className="form-section">
      <div className="form-header">
        <h3>{i18n.t("onBoarding.sectors.title")}</h3>
        <OnBoardingSiexBanner
          onSubscribeRequested={onSubscribeRequested}
          onFinishTutorial={onSkipTutorial}
        />
        <p>{i18n.t("onBoarding.sectors.description")}</p>
        <p>
          {i18n.t("onBoarding.sectors.description2")}{" "}
          <a href={SIGPAC_MAP_URL} rel="noopener noreferrer" target="_blank">
            {SIGPAC_MAP_URL}
          </a>
        </p>
      </div>

      <TableBrioCard
        title={i18n.t("sectors.tableTitle")}
        headerText={i18n.t("sectors.headerText")}
        colums={sectorColumns}
        rows={sectors}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        hideBtnIcon
        addBtnVariant="contained"
        isLoading={isFetching || sectorDeleteMutation.isLoading}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={openDialog}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />

      <DialogSector
        open={isDialogOpen}
        onClose={handleCloseDialog}
        selected={
          selectedRowIds.length === 1
            ? sectors?.find((s: Sector) => s.id === selectedRowIds[0])
            : undefined
        }
        onMessage={onMessage}
      />
    </div>
  );
};

const CropsForm = ({
  onMessage,
  onSubscribeRequested,
  onSkipTutorial,
}: formProps) => {
  const cropColumns: Column[] = [
    {
      id: "productName",
      label: i18n.t("crops.column.productName"),
      numeric: false,
    },
    {
      id: "productVarietiesNames",
      label: i18n.t("crops.column.productVarietiesNames"),
      numeric: false,
    },
    {
      id: "cropSectors",
      label: i18n.t("crops.column.cropSectors"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "sector.name",
    },
  ];

  const { selectedABAccount, selectedABPartition } = useSession();

  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { data: crops, isFetching } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
  });

  const cropDeleteMutation = useCrud({
    key: "deleteCrops",
    values: crops?.filter((c: Crop) => selectedRowIds.includes(c?.id || 0)),
    onSuccess: () => {
      setSelectedRowIds([]);
    },
    onError: (error: SnackbarInterface) => {
      onMessage && onMessage(error);
      closeDialog();
    },
  });

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const handleCloseDialog = () => {
    setSelectedRowIds([]);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    openDialog();
  };

  const handleClickDelete = () => {
    cropDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <div className="form-section">
      <div className="form-header">
        <h3>{i18n.t("onBoarding.crops.title")}</h3>
        <OnBoardingSiexBanner
          onSubscribeRequested={onSubscribeRequested}
          onFinishTutorial={onSkipTutorial}
        />
        <p>{i18n.t("onBoarding.crops.description")}</p>
        <p>{i18n.t("onBoarding.crops.description2")}</p>
      </div>

      <TableBrioCard
        title={i18n.t("crops.tableTitle")}
        headerText={i18n.t("crops.headerText")}
        colums={cropColumns}
        rows={crops}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        hideBtnIcon
        addBtnVariant="contained"
        isLoading={isFetching || cropDeleteMutation.isLoading}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={openDialog}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />

      <DialogCrop
        open={isDialogOpen}
        onClose={handleCloseDialog}
        selected={
          selectedRowIds.length === 1
            ? crops?.find((s: Crop) => s.id === selectedRowIds[0])
            : undefined
        }
        onMessage={onMessage}
      />
    </div>
  );
};

interface OnBoardingStepInterface {
  id: number;
  label: string;
  description?: string;
  completed?: boolean;
  nextBtnText?: string;
  formNode?: React.ReactNode;
  stepIcon?: React.ReactNode;
}

const OnBoardingScreen = () => {
  const { user } = useAuth();
  const { agroBusinessAccounts, changeSelectedABA } = useSession();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  const siexSyncRequestMutation = useCrud({
    key: "siexSyncRequest",
    onSuccess: (data: AgroBusinessAccount) => {
      changeSelectedABA(data);
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.siexWantSyncSuccess"),
      });
    },
    onError: setSnackbarMsg,
  });

  const finishTutorial = () => navigate(0);
  const goToNextStep = () => {
    if (activeStep === steps.length - 1) finishTutorial();
    else setActiveStep((prevStep) => prevStep + 1);
  };
  const goToPreviousStep = () => setActiveStep((prevStep) => prevStep - 1);

  const handleSubscribeRequest = () => {
    siexSyncRequestMutation.mutate({});
  };

  const steps: OnBoardingStepInterface[] = [
    {
      id: 1,
      label: i18n.t("onBoarding.stepper.register.label"),
      description: i18n.t("onBoarding.stepper.register.description"),
      formNode: (
        <RegisterForm onMessage={setSnackbarMsg} onCompleted={goToNextStep} />
      ),
    },
    {
      id: 2,
      label: i18n.t("onBoarding.stepper.sectors.label"),
      description: i18n.t("onBoarding.stepper.sectors.description"),
      formNode: (
        <SectorsForm
          onMessage={setSnackbarMsg}
          onSubscribeRequested={handleSubscribeRequest}
          onSkipTutorial={finishTutorial}
        />
      ),
    },
    {
      id: 3,
      label: i18n.t("onBoarding.stepper.crops.label"),
      description: i18n.t("onBoarding.stepper.crops.description"),
      formNode: (
        <CropsForm
          onMessage={setSnackbarMsg}
          onSubscribeRequested={handleSubscribeRequest}
          onSkipTutorial={finishTutorial}
        />
      ),
    },
  ];

  if (user?.person && agroBusinessAccounts.length > 0) {
    return <Navigate to={PUBLIC_ROUTES.HOME} replace />;
  }

  return (
    <div className="onBoarding">
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <TestBanner />
      <div className="container">
        <div className="header">
          <div className="logo" />
          <Stepper activeStep={activeStep} sx={{ mb: 2 }} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.id} completed={step.completed}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="content">
          {steps[activeStep].formNode}
          {activeStep > 0 && (
            <>
              <Divider
                sx={{
                  width: "100%",
                  mt: 4,
                  mb: 2,
                  backgroundColor: "gray",
                }}
              />
              <div className="step-btns">
                <Button
                  disabled={activeStep === 1}
                  onClick={goToPreviousStep}
                  sx={{ mr: 1 }}
                >
                  {i18n.t("words.back")}
                </Button>
                <Button variant="outlined" onClick={goToNextStep}>
                  {activeStep === steps.length - 1
                    ? i18n.t("words.finish")
                    : i18n.t("words.continue")}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnBoardingScreen;
