import moment from "moment";

import FBPluviometryRegister from "../_apiModels/pluvio/FBPluviometryRegister";
import Document from "../files/Document";

import { dateConverter } from "../../helpers/utils";
import { SourceTypeInterface } from "../../constants/interfaces";
import { PLUVIOMETRY_SOURCE_TYPES } from "../../providers/YupProvider";

class PluviometryRegister extends FBPluviometryRegister {
  date?: string;
  timeRange?: string;
  sourceType?: SourceTypeInterface;
  documents?: Document[];
  lastSavedDocuments?: Document[];
  dateFormatted?: string;

  constructor(
    date?: string,
    timeRange?: string,
    sourceType?: SourceTypeInterface,
    documents?: Document[]
  ) {
    super();
    this.date = date;
    this.timeRange = timeRange;
    this.sourceType = sourceType || PLUVIOMETRY_SOURCE_TYPES[2]; // Manual by default
    this.documents = documents;
  }

  mapToClass(obj: any): PluviometryRegister | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.startDate) {
      this.date = moment(this.startDate, "YYYY-MM-DD HH:mm:ss").format(
        "YYYY-MM-DD"
      );
      this.dateFormatted = dateConverter({
        date: this.startDate,
        dateFormat: "YYYY-MM-DD HH:mm:ss",
      });
    }
    if (this.startDate && this.endDate)
      this.timeRange = `${moment(this.startDate, "YYYY-MM-DD HH:mm:ss").format(
        "HH:mm"
      )}h - ${moment(this.endDate, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}h`;
    this.sourceType =
      PLUVIOMETRY_SOURCE_TYPES.find(
        (sourceType) => sourceType.code === obj.source
      ) || PLUVIOMETRY_SOURCE_TYPES[2];
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    obj.source = this.sourceType?.code;

    // Remove properties that not exists in API
    delete obj.date;
    delete obj.timeRange;
    delete obj.sourceType;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    delete obj.dateFormatted;
    return obj;
  }
}

export default PluviometryRegister;
