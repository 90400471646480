import Province from "../../address/Province";

class FBMunicipality {
  id?: number;
  province?: Province; //*
  code?: string; //*
  name?: string; //*
  sigpacCode?: string;

  constructor(
    id?: number,
    province?: Province,
    code?: string,
    name?: string,
    sigpacCode?: string
  ) {
    this.id = id;
    this.province = province;
    this.code = code;
    this.name = name;
    this.sigpacCode = sigpacCode;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.province = new Province().mapToClass(obj?.province);
    this.code = obj?.code;
    this.name = obj?.name;
    this.sigpacCode = obj?.sigpacCode;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.province = this.province?.mapToApiModel();
    return obj;
  }
}

export default FBMunicipality;
