import * as yup from "yup";

/**
 * Function to validate spanish NIF using Yup
 */
export const validateNIF = (nif: string) => {
  return yup
    .string()
    .matches(/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i)
    .isValidSync(nif);
};

/**
 * Function to validate spanish CIF using Yup
 */
export const validateCIF = (cif: string) => {
  return yup
    .string()
    .matches(/^[ABCDEFGHJKLMNPQS]\d{7}[0-9A-J]$/i)
    .isValidSync(cif);
};

const isTwoDigitNumberRegex = /^0?\d{2}$/;
export const isTwoDigitNumber = (value: any): boolean => {
  if (typeof value === "undefined" || value === null) {
    return false;
  }
  return isTwoDigitNumberRegex.test(value.toString());
};

export const isValidEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};
