import Sector from "../../agrobusiness/Sector";

class FBCropIrrigationAreaSector {
  id?: number;
  sector?: Sector;
  irrigatedArea?: number;

  constructor(id?: number, sector?: Sector, irrigatedArea?: number) {
    this.id = id;
    this.sector = sector;
    this.irrigatedArea = irrigatedArea;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.sector = new Sector().mapToClass(obj?.sector);
    this.irrigatedArea = obj?.irrigatedArea;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.sector = this.sector?.mapToApiModel();
    return obj;
  }
}

export default FBCropIrrigationAreaSector;
