import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Search } from "@mui/icons-material";

import FormikAutocomplete from "../elements/FormikAutocomplete";

import Crop from "../../models/crop/Crop";
import Sector from "../../models/agrobusiness/Sector";
import CropProduct from "../../models/catalogue/CropProduct";
import CropProductVariety from "../../models/catalogue/CropProductVariety";

import useCrud from "../../hooks/useCrud";
import useFetch from "../../hooks/useFetch";
import { useSession } from "../../hooks/useSession";

import { SnackbarInterface } from "../../constants/interfaces";
import AlertEmptyList from "../AlertEmptyList";
import CropSector from "../../models/crop/CropSector";

interface DialogCropProps {
  open: boolean;
  onClose: () => void;
  selected?: Crop | null;
  onMessage?: (snackBarMessage: SnackbarInterface) => void;
}

const DialogCrop = (props: DialogCropProps) => {
  const YupValidatorSchema = Yup.object().shape({
    product: Yup.object().required(),
    varieties: Yup.array().required(),
  });

  const { open, onClose, onMessage, selected } = props;

  const { selectedABAccount, selectedABPartition } = useSession();

  const [cropSectors, setCropSectors] = useState<CropSector[]>([]);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: Crop) => {
    if (selected) cropEditMutation.mutate(values);
    else {
      // Remove temp id for cropSectors list
      const cropSectors =
        values.cropSectors?.map(
          (cropSector) => ({ sector: cropSector.sector } as CropSector)
        ) || [];
      const crop = new Crop().mapToClass({ ...values, cropSectors });
      cropCreateMutation.mutate(crop);
    }
  };

  const formik = useFormik({
    initialValues: selected || new Crop(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setValues(selected || new Crop());
  }, [selected]);

  const { data: cropProducts, isFetching: isFetchingProducts } = useFetch<
    CropProduct[]
  >({
    queryKey: ["cropProducts", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError: onMessage,
  });

  const { data: varieties, isFetching: isFetchingVarieties } = useFetch<
    CropProductVariety[]
  >({
    queryKey: ["varieties", formik.values.product?.id],
    selected: formik.values?.product?.id,
    enabled: !!formik.values.product?.id,
    onError: onMessage,
  });

  useEffect(() => {
    if (
      varieties &&
      varieties?.[0]?.productType?.id !==
        formik.values.varieties?.[0]?.productType?.id
    ) {
      formik.setFieldValue(
        "varieties",
        varieties?.length === 1 ? varieties : []
      );
    }
  }, [varieties]);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onSuccess: (data) => {
      setCropSectors(
        data.map((sector) => {
          const cropSector = new CropSector();
          cropSector.id = sector.id; // For autocomplete list
          cropSector.sector = sector;
          return cropSector;
        })
      );
    },
    onError: onMessage,
  });

  const cropCreateMutation = useCrud<Crop>({
    key: "postCrop",
    values: formik.values,
    onSuccess: closeDialog,
    onError: onMessage,
  });

  const cropEditMutation = useCrud<Crop>({
    key: "putCrop",
    values: formik.values,
    onSuccess: closeDialog,
    onError: onMessage,
  });

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleChangeVarieties = (
    options: CropProductVariety[] | CropProductVariety
  ) => {
    formik.setFieldValue(
      "varieties",
      options instanceof Array ? options : [options]
    );
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("onBoarding.crops.dialog.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("onBoarding.crops.dialog.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="product"
              label={i18n.t("crops.definitionForm.product")}
              placeholder={i18n.t("crops.definitionForm.productPlaceholder")}
              options={cropProducts || []}
              optionLabelFieldName="name"
              required
              startIcon={Search}
              loading={isFetchingProducts}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="varieties"
              label={i18n.t("crops.definitionForm.varieties")}
              placeholder={i18n.t("crops.definitionForm.varietiesPlaceholder")}
              helperText={i18n.t("crops.definitionForm.varietiesHelperText")}
              disabled={!formik.values.product}
              required
              multiple={formik.values.product?.fruity}
              options={varieties || []}
              optionLabelFieldName="name"
              loading={isFetchingVarieties}
              onChange={handleChangeVarieties}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            {sectors?.length === 0 && (
              <AlertEmptyList btn={false}>
                {i18n.t("onBoarding.crops.dialog.sectorsEmpty")}
              </AlertEmptyList>
            )}
            <FormikAutocomplete
              formik={formik}
              name="cropSectors"
              label={i18n.t("onBoarding.crops.dialog.sectorsLabel")}
              helperText={i18n.t("onBoarding.crops.dialog.sectorsHelperText")}
              multiple
              options={cropSectors}
              optionLabelFieldName="sector.name"
              loading={isFetching}
              disabled={cropSectors.length === 0 || !!selected}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeDialog}
          color="primary"
          disabled={cropCreateMutation.isLoading || cropEditMutation.isLoading}
        >
          {i18n.t("words.cancel")}
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          color="primary"
          loading={cropCreateMutation.isLoading || cropEditMutation.isLoading}
        >
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCrop;
