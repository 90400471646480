import React from "react";
import { FormikProps } from "formik";
import { TextField, TextFieldProps, InputAdornment } from "@mui/material";
import { Close } from "@mui/icons-material";

import { getFieldValueByColumnNotation } from "../../helpers/utils";

const getValue = (name: string, obj: any) => {
  const fieldValue = getFieldValueByColumnNotation(name, obj);
  if (fieldValue === 0) return fieldValue;
  return fieldValue || "";
};

interface Props {
  name: string;
  formik: FormikProps<any>;
  valueUnit?: string;
  helperLabel?: string;
  onClear?: () => void;
}
const FormikTextField = (props: Props & TextFieldProps) => {
  const { name, formik, valueUnit, helperLabel, onClear, ...rest } = props;
  const errorTouched = getFieldValueByColumnNotation(name, formik.touched);
  const errorFormik = getFieldValueByColumnNotation(name, formik.errors);
  const error = errorTouched && Boolean(errorFormik);
  const errorMsg = (errorFormik as string) || "";
  const value = getValue(name, formik.values);

  const UnitTag = () => (
    <InputAdornment position="end">{valueUnit}</InputAdornment>
  );

  const CloseTag = () => (
    <InputAdornment position="end" onClick={onClear}>
      <Close />
    </InputAdornment>
  );

  return (
    <TextField
      id={name}
      name={name}
      className="form-input"
      variant="outlined"
      type="text"
      value={value}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={error}
      helperText={error ? errorMsg : helperLabel || ""}
      FormHelperTextProps={{
        style: { ...helperTextStyle, color: error ? "#c62828" : "" },
      }}
      InputProps={{
        endAdornment:
          (valueUnit || (onClear && value && value.length > 0)) &&
          (valueUnit ? <UnitTag /> : <CloseTag />),
      }}
      {...rest}
    />
  );
};

export default FormikTextField;

export const helperTextStyle: React.CSSProperties = {
  fontSize: 12,
  margin: 4,
  marginBottom: 0,
  color: "#c62828",
};
