import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";

class FBWaterSource {
  id?: number;
  name?: string;
  description?: string;
  type?: string;
  distanceToFarm?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    name?: string,
    description?: string,
    type?: string,
    distanceToFarm?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.type = type;
    this.distanceToFarm = distanceToFarm;
    this.agroBusinessPartition = agroBusinessPartition;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.description = obj?.description;
    this.type = obj?.type;
    this.distanceToFarm = obj?.distanceToFarm;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBWaterSource;
