import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import Facility from "../../agrobusiness/Facility";
import FertilizerType from "../../catalogue/FertilizerType";
import MeasurementUnit from "../../catalogue/MeasurementUnit";

class FBFertilizerProductStock {
  id?: number;
  registerId?: string;
  name?: string;
  owner?: string;
  type?: FertilizerType;
  measurementUnit?: MeasurementUnit;
  enabledDate?: string;
  disabledDate?: string;
  stock?: number;
  facility?: Facility;
  agroBusinessPartition?: AgroBusinessPartition;

  constructor(
    id?: number,
    registerId?: string,
    name?: string,
    owner?: string,
    type?: FertilizerType,
    measurementUnit?: MeasurementUnit,
    enabledDate?: string,
    disabledDate?: string,
    stock?: number,
    facility?: Facility,
    agroBusinessPartition?: AgroBusinessPartition
  ) {
    this.id = id;
    this.registerId = registerId;
    this.name = name;
    this.owner = owner;
    this.type = type;
    this.measurementUnit = measurementUnit;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
    this.stock = stock;
    this.facility = facility;
    this.agroBusinessPartition = agroBusinessPartition;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.owner = obj?.owner;
    this.type = new FertilizerType().mapToClass(obj?.type);
    this.measurementUnit = new MeasurementUnit().mapToClass(
      obj?.measurementUnit
    );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.stock = obj?.stock;
    this.facility = new Facility().mapToClass(obj?.facility);
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.measurementUnit = this.measurementUnit?.mapToApiModel();
    obj.facility = this.facility?.mapToApiModel();
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerProductStock;
