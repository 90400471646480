import { LanguageInterface } from "./interfaces";

export const LESSOR_TENURES_CODES = ["2", "3", "4", "6"];

export const ANALYSIS_SOIL_TYPES_CODES = ["3"]; // SIEX soil code

export const PHYTO_DEFENSE_ACTION_QTY_CODES = ["1", "2", "11", "13", "14"];

export const PHYTO_DEFENSE_ACTION_REGISTER_ID_CODES = ["1", "2", "14"];

export const LANGUAGE_LIST: LanguageInterface[] = [
  { id: 1, name: "Español", code: "es" },
  { id: 2, name: "Català", code: "ca" },
  { id: 3, name: "Euskara", code: "eu" },
  { id: 4, name: "Galego", code: "gl" },
  { id: 5, name: "English", code: "en" },
  { id: 6, name: "Français", code: "fr" },
  { id: 7, name: "Italiano", code: "it" },
  { id: 8, name: "Português", code: "pt" },
  { id: 9, name: "Deutsch", code: "de" },
];
