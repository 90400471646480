import { useState, useEffect, useMemo } from "react";
import { FormikTouched, useFormik } from "formik";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";

import FormikTextField from "../elements/FormikTextField";
import FormAlertDialog from "./FormAlertDialog";

import PhytosanitaryStockMovement from "../../models/phyto/PhytosanitaryStockMovement";
import PhytosanitaryProduct, {
  MEASUREMENT_UNIT_CODES,
} from "../../models/phyto/PhytosanitaryProduct";
import PlantProblem from "../../models/catalogue/PlantProblem";
import Crop from "../../models/crop/Crop";
import RegisteredPhytosanitaryProduct from "../../models/vademecum/RegisteredPhytosanitaryProduct";

import { getMaxValueOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import {
  FormMode,
  PhytosanitaryStockMovementType,
} from "../../constants/enums";
import { PHYTOSANITARY_STOCK_MOVEMENT_TYPES } from "../../providers/YupProvider";
import {
  PlantProblemList,
  PlantProblemsInput,
  ProductInput,
} from "../../screens/exploitation/ExploitationRecipesScreen";
import FormikSelect from "../elements/FormikSelect";
import useFetch from "../../hooks/useFetch";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";
import { useSession } from "../../hooks/useSession";
import { Sanitizer } from "@mui/icons-material";

interface Props {
  open: boolean;
  title?: string;
  body?: string;
  selected?: PhytosanitaryStockMovement | null;
  selectedCrop?: Crop;
  cropTreatedArea?: number;
  stockMovements?: PhytosanitaryStockMovement[];
  onClose: () => void;
  onConfirm: (
    phytoStockMovement: PhytosanitaryStockMovement,
    plantProblems: PlantProblem[],
    cropTreatedArea?: number
  ) => void;
  onError?: (snackbarError: SnackbarInterface) => void;
}
const DialogPhytosanitaryProduct = (props: Props) => {
  const {
    open,
    selected,
    selectedCrop,
    cropTreatedArea,
    stockMovements,
    onClose,
    onConfirm,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const [
    isOpenConfirmWithConflictsDialog,
    setIsOpenConfirmWithConflictsDialog,
  ] = useState<boolean>(false);
  const [plantProblemConflicts, setPlantProblemConflicts] =
    useState<boolean>(false);
  const [treatedArea, setTreatedArea] = useState<number | undefined>(undefined);
  const [dose, setDose] = useState<number>(0);
  const [plantProblems, setPlantProblems] = useState<PlantProblem[]>([]);

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
    setPlantProblems([]);
  };

  const validateForm = (values: PhytosanitaryStockMovement) => {
    const errors: any = {};
    if (!values?.quantity || values?.quantity <= 0)
      errors.quantity = i18n.t("formErrors.requiredField");
    if (!values?.product && !values?.registeredProduct)
      errors.product = i18n.t("formErrors.requiredField");
    if (values?.registeredProduct && !values?.registeredProductMeasurementUnit)
      errors.registeredProductMeasurementUnit = i18n.t(
        "formErrors.requiredField"
      );
    if (isSiexActive) {
      if (plantProblems.length === 0)
        errors.plantProblems = i18n.t("formErrors.requiredField");
    }

    return errors;
  };

  const handleSubmit = (values: PhytosanitaryStockMovement) => {
    // Find the max idx of the array
    const maxIdx = getMaxValueOfList(stockMovements, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    // Set purchase as movement type
    values.type = PhytosanitaryStockMovementType.TREATMENT;
    values.movementType = PHYTOSANITARY_STOCK_MOVEMENT_TYPES.find(
      (movementType) =>
        movementType.code === PhytosanitaryStockMovementType.TREATMENT
    );
    values.setQuantityUnitFormatted(); // Update quantity unit formatted for the table
    onConfirm(values, plantProblems, treatedArea);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new PhytosanitaryStockMovement(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) {
      formik.setValues(selected);
      if (selected.quantity && cropTreatedArea && cropTreatedArea > 0)
        setDose(Math.round((selected.quantity / cropTreatedArea) * 100) / 100);
    }
  }, [selected]);

  useEffect(() => {
    setTreatedArea(cropTreatedArea);
  }, [cropTreatedArea]);

  const openConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(true);
  const cancelConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(false);

  const handleOnChangeProduct = (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  ) => {
    if (!product || product instanceof RegisteredPhytosanitaryProduct) {
      formik.setFieldValue("registeredProduct", product);
      formik.setFieldValue("product", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", false);
    } else if (product.registeredPhytosanitaryProduct) {
      formik.setFieldValue(
        "registeredProduct",
        product.registeredPhytosanitaryProduct
      );
      formik.setFieldValue(
        "registeredProductMeasurementUnit",
        product.measurementUnit
      );
      formik.setFieldValue("product", null);
      formik.setFieldValue("isInventoryProduct", true);
    } else {
      formik.setFieldValue("product", product);
      formik.setFieldValue("registeredProduct", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", true);
    }
  };

  const handleOnChangePlantProblems = (plantProblems: PlantProblem[]) => {
    setPlantProblems(plantProblems);
  };

  const handleOnRemovePlantProblem = (plantProblem: PlantProblem) => {
    const pp = plantProblems.filter(
      (problem) => problem.id !== plantProblem.id
    );
    setPlantProblems(pp);
  };

  const handleChangeTreatedArea = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setTreatedArea(value);
    if (value > 0 && formik.values.quantity)
      setDose(Math.round((formik.values.quantity / value) * 100) / 100);
  };

  const handleChangeDose = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setDose(value);
    if (treatedArea)
      formik.setFieldValue(
        "quantity",
        Math.round(value * treatedArea * 100) / 100
      );
  };

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseFloat(e.target.value);
    formik.setFieldValue("quantity", quantity);
    if (treatedArea && treatedArea > 0)
      setDose(Math.round((quantity / treatedArea) * 100) / 100);
  };

  const handleConfirm = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      onError &&
        onError({
          severity: "warning",
          message: i18n.t("formErrors.requiredFields"),
        });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else if (plantProblemConflicts) openConfirmWithConflictsAlertDialog();
    else formik.submitForm();
  };

  const handleConfirmWithConflictsAlertDialog = () => {
    cancelConfirmWithConflictsAlertDialog();
    formik.submitForm();
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">{props.title}</DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">{props.body}</DialogContentText>
        <FormGroup className="form-group">
          <ProductInput
            selectedCrop={selectedCrop}
            selectedPlantProblems={plantProblems}
            value={formik.values.registeredProduct || formik.values.product}
            inputError={
              (formik.touched.product && !!formik.errors.product) ||
              (formik.touched.registeredProduct &&
                !!formik.errors.registeredProduct)
            }
            onChange={handleOnChangeProduct}
            onError={onError}
          />

          {(formik.values.product || formik.values.registeredProduct) &&
            !formik.values.isInventoryProduct && (
              <FormControl variant="outlined" className="form-control">
                <FormikSelect
                  formik={formik}
                  name="registeredProductMeasurementUnit"
                  label={i18n.t(
                    "components.dialogPhytosanitaryProduct.registeredProductMeasurementUnitLabel"
                  )}
                  optionLabelFieldName="name"
                  required
                  options={measurementUnits || []}
                  isLoading={isFetchingMeasurementUnits}
                />
              </FormControl>
            )}
          <PlantProblemsInput
            selectedCrop={selectedCrop}
            selectedProduct={
              formik.values.registeredProduct || formik.values.product
            }
            value={plantProblems}
            inputError={
              isSiexActive &&
              Object.entries(formik.touched).length > 0 &&
              plantProblems.length === 0
            }
            onChange={handleOnChangePlantProblems}
            onError={onError}
          />
          <div className="recipes-view">
            <PlantProblemList
              plantProblems={plantProblems}
              selectedCrop={selectedCrop}
              selectedProduct={
                formik.values.registeredProduct || formik.values.product
              }
              onConflicts={setPlantProblemConflicts}
              onRemove={handleOnRemovePlantProblem}
              onError={onError}
            />
          </div>
          <FormControl
            variant="outlined"
            className="form-control"
            sx={{ mt: plantProblems.length > 0 ? 2 : 0 }}
          >
            <TextField
              type="number"
              label={i18n.t(
                "components.dialogPhytosanitaryProduct.cropTreatedAreaLabel"
              )}
              helperText={i18n.t(
                "components.dialogPhytosanitaryProduct.cropTreatedAreaHelperText"
              )}
              value={treatedArea}
              onChange={handleChangeTreatedArea}
              InputProps={{ endAdornment: "ha" }}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <TextField
              type="number"
              className="form-input-row"
              label={i18n.t("components.dialogPhytosanitaryProduct.doseLabel")}
              value={dose}
              onChange={handleChangeDose}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Sanitizer style={{ color: "black" }} />
                  </InputAdornment>
                ),
                endAdornment: `${
                  formik.values.product?.measurementUnit?.name ||
                  formik.values.registeredProductMeasurementUnit?.name ||
                  "?"
                }/ha`,
              }}
            />
            <FormikTextField
              formik={formik}
              name="quantity"
              className="form-input-row"
              label={i18n.t(
                "components.dialogPhytosanitaryProduct.quantityLabel"
              )}
              type="number"
              required
              valueUnit={
                formik.values.product?.measurementUnit?.name ||
                formik.values.registeredProductMeasurementUnit?.name
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Sanitizer style={{ color: "black" }} />
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeQuantity}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="confirmWithConflictsAlertDialog"
        title={i18n.t(
          "components.dialogPhytosanitaryProduct.confirmWithConflictsAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogPhytosanitaryProduct.confirmWithConflictsAlertDialogDescription"
        )}
        confirmBtnText={i18n.t(
          "components.dialogPhytosanitaryProduct.confirmWithConflictsAlertDialogConfirmBtnText"
        )}
        open={isOpenConfirmWithConflictsDialog}
        formAction={FormMode.DELETE}
        onCancel={cancelConfirmWithConflictsAlertDialog}
        onConfirm={handleConfirmWithConflictsAlertDialog}
      />
    </Dialog>
  );
};

export default DialogPhytosanitaryProduct;
