import MeasurementUnit from "../../catalogue/MeasurementUnit";
import ActiveSubstance from "../../catalogue/ActiveSubstance";
import PhytosanitaryProductType from "../../catalogue/PhytosanitaryProductType";
import RegisteredPhytosanitaryProduct from "../../vademecum/RegisteredPhytosanitaryProduct";

class FBPhytosanitaryProduct {
  id?: number;
  type?: PhytosanitaryProductType;
  productId?: number;
  registerId?: string;
  name?: string; //*
  owner?: string;
  formulation?: string;
  activeSubstances?: ActiveSubstance[];
  expirationDate?: string;
  measurementUnit?: MeasurementUnit; //*
  registeredPhytosanitaryProduct?: RegisteredPhytosanitaryProduct;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    type?: PhytosanitaryProductType,
    productId?: number,
    registerId?: string,
    name?: string,
    owner?: string,
    formulation?: string,
    activeSubstances?: ActiveSubstance[],
    expirationDate?: string,
    measurementUnit?: MeasurementUnit,
    registeredPhytosanitaryProduct?: RegisteredPhytosanitaryProduct,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.type = type;
    this.productId = productId;
    this.registerId = registerId;
    this.name = name;
    this.owner = owner;
    this.formulation = formulation;
    this.activeSubstances = activeSubstances;
    this.expirationDate = expirationDate;
    this.measurementUnit = measurementUnit;
    this.registeredPhytosanitaryProduct = registeredPhytosanitaryProduct;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.type = new PhytosanitaryProductType().mapToClass(obj?.type);
    this.productId = obj?.productId;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.owner = obj?.owner;
    this.formulation = obj?.formulation;
    this.activeSubstances = obj?.activeSubstances?.map((activeSubstance: any) =>
      new ActiveSubstance().mapToClass(activeSubstance)
    );
    this.expirationDate = obj?.expirationDate;
    this.measurementUnit = new MeasurementUnit().mapToClass(
      obj?.measurementUnit
    );
    this.registeredPhytosanitaryProduct =
      new RegisteredPhytosanitaryProduct().mapToClass(
        obj?.registeredPhytosanitaryProduct
      );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.activeSubstances = this.activeSubstances?.map((activeSubstance) =>
      activeSubstance.mapToApiModel()
    );
    obj.measurementUnit = this.measurementUnit?.mapToApiModel();
    obj.registeredPhytosanitaryProduct =
      this.registeredPhytosanitaryProduct?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryProduct;
