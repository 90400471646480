import { FBESiexRequestStatus } from "../../../constants/enums";
import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import Sector from "../../agrobusiness/Sector";
import AnalizedMaterialType from "../../catalogue/AnalizedMaterialType";
import AnalysisType from "../../catalogue/AnalysisType";

class FBAnalysis {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  date?: string; //*
  types?: AnalysisType[]; //*
  analizedMaterial?: AnalizedMaterialType; //*
  laboratory?: string;
  laboratoryId?: number;
  bulletinNumber?: string;
  soilParameters?: boolean;
  soilOrganicMaterial?: number;
  soilSand?: number;
  soilSilt?: number;
  soilClay?: number;
  soilPH?: number;
  soilAvailablePhosphorus?: number;
  soilAvailablePotassium?: number;
  soilTotalNitrogen?: number;
  soilConductivity?: number;
  sectors?: Sector[];
  enabledDate?: string;
  disabledDate?: string;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    date?: string,
    types?: AnalysisType[],
    analizedMaterial?: AnalizedMaterialType,
    laboratory?: string,
    laboratoryId?: number,
    bulletinNumber?: string,
    soilParameters?: boolean,
    soilOrganicMaterial?: number,
    soilSand?: number,
    soilSilt?: number,
    soilClay?: number,
    soilPH?: number,
    soilAvailablePhosphorus?: number,
    soilAvailablePotassium?: number,
    soilTotalNitrogen?: number,
    soilConductivity?: number,
    sectors?: Sector[],
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.date = date;
    this.types = types;
    this.analizedMaterial = analizedMaterial;
    this.laboratory = laboratory;
    this.laboratoryId = laboratoryId;
    this.bulletinNumber = bulletinNumber;
    this.soilParameters = soilParameters;
    this.soilOrganicMaterial = soilOrganicMaterial;
    this.soilSand = soilSand;
    this.soilSilt = soilSilt;
    this.soilClay = soilClay;
    this.soilPH = soilPH;
    this.soilAvailablePhosphorus = soilAvailablePhosphorus;
    this.soilAvailablePotassium = soilAvailablePotassium;
    this.soilTotalNitrogen = soilTotalNitrogen;
    this.soilConductivity = soilConductivity;
    this.sectors = sectors;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.date = obj?.date;
    this.types =
      obj?.types?.map((x: any) => new AnalysisType().mapToClass(x)) || [];
    this.analizedMaterial = new AnalizedMaterialType().mapToClass(
      obj?.analizedMaterial
    );
    this.laboratory = obj?.laboratory;
    this.laboratoryId = obj?.laboratoryId;
    this.bulletinNumber = obj?.bulletinNumber;
    this.soilParameters = obj?.soilParameters;
    this.soilOrganicMaterial = obj?.soilOrganicMaterial;
    this.soilSand = obj?.soilSand;
    this.soilSilt = obj?.soilSilt;
    this.soilClay = obj?.soilClay;
    this.soilPH = obj?.soilPH;
    this.soilAvailablePhosphorus = obj?.soilAvailablePhosphorus;
    this.soilAvailablePotassium = obj?.soilAvailablePotassium;
    this.soilTotalNitrogen = obj?.soilTotalNitrogen;
    this.soilConductivity = obj?.soilConductivity;
    this.sectors =
      obj?.sectors?.map((x: any) => new Sector().mapToClass(x)) || [];
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.types = this.types?.map((x: AnalysisType) => x.mapToApiModel());
    obj.analizedMaterial = this.analizedMaterial?.mapToApiModel();
    obj.sectors = this.sectors?.map((x: Sector) => x.mapToApiModel());
    return obj;
  }
}

export default FBAnalysis;
