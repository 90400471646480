import { useState } from "react";

/**
 * Set state to undefined after timeout time
 */
const useExpandCards = (): [boolean, () => void] => {
  const [expandCards, setExpandCards] = useState<boolean>(false);

  // Enable force expanded again
  const forceExpandCards = () => {
    setExpandCards(true);
    setTimeout(() => {
      setExpandCards(false);
    }, 1000);
  };

  return [expandCards, forceExpandCards];
};

export default useExpandCards;
