import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import { FormGroup, FormControl, Collapse, FormLabel } from "@mui/material";
import { FileUpload, InsertDriveFile } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";

import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";
import { FBDocumentType } from "../../models/_apiModels/files/FBDocument";

import { dateConverter } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";

const REFRESH_INTERVAL = 30 * 1000; // 30 seconds
const ExploitationDocumentsScreen = () => {
  const documentsColumns: Column[] = [
    {
      id: "avatar",
      label: i18n.t("files.column.avatar"),
      numeric: false,
      valueLabel: "listItem",
      primaryTextFieldName: "name",
      secondaryTextFieldName: "description",
      avatarSrcFieldName: "thumbnailLink",
    },
    {
      id: "creationDate",
      label: i18n.t("files.column.creationDate"),
      numeric: false,
    },
    {
      id: "dateFormatted",
      label: i18n.t("files.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "type.name",
      label: i18n.t("files.column.type"),
      numeric: false,
    },
  ];

  const DocumentsSchema = Yup.object().shape({
    name: Yup.string().required(),
    type: Yup.object().required(),
    date: Yup.string().required(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  // eslint-disable-next-line
  const [dateRange, setDateRange] = useState<string[]>(["", ""]);
  // eslint-disable-next-line
  const [typeId, setTypeId] = useState<number | string>("");

  const { data: documents, isFetching } = useFetch<Document[]>({
    queryKey: [
      "documents",
      selectedABAccount?.id,
      selectedABPartition?.id,
      typeId,
      dateRange[0],
      dateRange[1],
    ],
    params: {
      typeId: typeId as string, //TODO: Check
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
    },
    refetchOnWindowFocus: false, // Input file not work if true
    refetchInterval: REFRESH_INTERVAL,
    enabled: !!selectedABAccount?.id && (!!typeId || !!dateRange), // TODO: Check
    onError: setSnackbarMsg,
  });

  const handleSubmit = (values: Document) => {
    switch (formMode) {
      case FormMode.CREATE:
        documentCreateMutation.mutate({});
        break;
      case FormMode.EDIT:
        documentEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new Document(),
    validationSchema: DocumentsSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("files.drawerTitleCreate")
      : i18n.t("files.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isOpenDrawer && formik.dirty && formik.status === FormMode.CREATE) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const documentCreateMutation = useCrud<Document>({
    key: "postDocument",
    values: formik.values,
    onSuccess: (data: Document) => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      // Update list
      queryClient.refetchQueries({ queryKey: ["documents"], type: "active" });
      closeDialogAndUnselectedRows();
    },
    onError: setSnackbarMsg,
  });

  const documentEditMutation = useCrud<Document>({
    key: "putDocument",
    values: formik.values,
    onSuccess: (data: Document) => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      // Update list
      queryClient.refetchQueries({ queryKey: ["documents"], type: "active" });
      closeDialogAndUnselectedRows();
    },
    onError: setSnackbarMsg,
  });

  const documentDeleteMutation = useCrud({
    key: "deleteDocuments",
    values: documents?.filter((d: Document) =>
      selectedRowIds.includes(d?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      // Update list
      queryClient.refetchQueries({ queryKey: ["documents"], type: "active" });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = () => {
    formik.handleSubmit();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleClickCell = async (
    e: React.MouseEvent,
    columnId: string,
    row: any
  ) => {
    e.stopPropagation(); // Avoid click in parent row

    if (columnId === "avatar") {
      // Find document
      const doc = row as Document;
      if (doc?.documentLink)
        window.open(doc.documentLink, "_blank", "noopener,noreferrer");
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    documentDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("files.backAlertTitle")}
        contentText={i18n.t("files.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("files.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("files.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={documentDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("files.tableTitle")}
        headerText={i18n.t("files.headerText")}
        colums={documentsColumns}
        rows={documents}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="creationInstant"
        addBtnVariant="contained"
        emptyTableIcon={
          <InsertDriveFile sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("files.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("files.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("files.emptyTableBtnText")}
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickCell={handleClickCell}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          documentCreateMutation.isLoading || documentEditMutation.isLoading
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <DocumentForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? documents?.find((d: Document) => d.id === selectedRowIds[0])
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationDocumentsScreen;

interface DocumentFormProps {
  formik: FormikProps<Document>;
  selectedEditRow?: Document;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const DocumentForm = (props: DocumentFormProps) => {
  const { formik, selectedEditRow, onError } = props;

  const { isFetching: isUpdating } = useFetch<Document>({
    queryKey: ["document", selectedEditRow?.id],
    selected: selectedEditRow,
    refetchOnWindowFocus: false, // Input file not work if true
    enabled: !!selectedEditRow?.id,
    onSuccess: (data: Document) => formik.setValues(data),
    onError,
  });

  return (
    <div>
      <LoadingWithDelay isVisible={isUpdating} />
      <DefinitionForm {...props} />
    </div>
  );
};

const DefinitionForm = (props: DocumentFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: types, isFetching: isFetchingTypes } = useFetch<
    FBDocumentType[]
  >({
    queryKey: ["documentTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    refetchOnWindowFocus: false, // Input file not work if true
    onError,
  });

  const handleClickUpload = () => {
    const inputElement = document.getElementById("fileInput");
    if (inputElement) inputElement.click();
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      formik.setFieldValue("file", file);
      formik.setFieldValue("name", file.name);
      formik.setFieldValue("mimeType", file.type);
    }
  };

  const handleChangeDate = (date: any) => {
    const dateStr = date?.format("YYYY-MM-DD");
    if (dateStr) {
      formik.setFieldValue("date", dateStr);
      formik.setFieldValue("dateFormatted", dateConverter({ date: dateStr }));
    }
  };

  return (
    <BrioCard
      title={i18n.t("files.definitionForm.title")}
      actionBtn
      rightBtnTitle={i18n.t("files.definitionForm.rightBtnTitle")}
      rightIcon={<FileUpload />}
      disableAccordion
      defaultExpanded
      onClickAdd={handleClickUpload}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={handleChangeFile}
          />
        </FormControl>
        <Collapse in={!!formik.values?.file || formik.status === FormMode.EDIT}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="name"
                label={i18n.t("files.definitionForm.name")}
                required
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="description"
                label={i18n.t("files.definitionForm.description")}
                multiline
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                className="form-input"
                formik={formik}
                name="type"
                label={i18n.t("files.definitionForm.type")}
                required
                options={types || []}
                optionLabelFieldName="name"
                isLoading={isFetchingTypes}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control" required>
              <DatePicker
                className="form-input"
                format="DD/MM/YYYY"
                label={i18n.t("files.definitionForm.date")}
                value={
                  formik.values?.date
                    ? moment(formik.values.date, "YYYY-MM-DD")
                    : null
                }
                onChange={handleChangeDate}
              />
              {formik.touched.date && formik.errors.date && (
                <FormLabel className="form-label">
                  {i18n.t("formErrors.requiredField")}
                </FormLabel>
              )}
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};
