import moment from "moment";

import FBPersonMessage from "../_apiModels/messages/FBPersonMessage";

class PersonMessage extends FBPersonMessage {
  dateFormatted?: string;
  expirationDateFormatted?: string;
  isUnread?: boolean;

  constructor(
    dateFormatted?: string,
    expirationDateFormatted?: string,
    isUnread?: boolean
  ) {
    super();
    this.dateFormatted = dateFormatted;
    this.expirationDateFormatted = expirationDateFormatted;
    this.isUnread = isUnread;
  }

  mapToClass(obj: any): PersonMessage | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.timestamp)
      this.dateFormatted = moment(this.timestamp * 1000).format(
        "DD MMM, HH:mm"
      );
    if (this.expirationTimestamp)
      this.expirationDateFormatted = moment(
        this.expirationTimestamp * 1000
      ).format("DD MMM, HH:mm");
    this.isUnread = !this.messageRead;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    // Remove not API fields
    delete obj.dateFormatted;
    delete obj.expirationDateFormatted;
    delete obj.isUnread;
    return obj;
  }
}

export default PersonMessage;
