import { useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  Unsubscribe,
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { useCookies } from "react-cookie";
import moment from "moment";
import i18n from "../config/configI18n";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import AddToHomeScreenBanner from "../components/banners/AddToHomescreenBanner";

import { AuthProvider } from "../hooks/useAuth";
import { SessionProvider } from "../hooks/useSession";
import { useAddToHomescreenPrompt } from "../hooks/useAddToHomeScreenPrompt";

import UserData from "../models/UserData";
import Person from "../models/Person";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { includedInRoutes } from "../helpers/utils";

import { SAVED_ROUTES } from "./routeNames";
import { SnackbarInterface, TokensInterface } from "../constants/interfaces";
import { FBPersonRole } from "../constants/enums";

const MAX_ADD_TO_HOMESCREEN_BANNER_SHOWED_NUMBER = 3;

export const getPerson = async (idToken?: string): Promise<Person | null> => {
  if (!idToken) return Promise.resolve(null);
  return fetch(`${process.env.REACT_APP_API_URL}/loginPerson`, {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}` },
  })
    .then(onPersonResponse)
    .catch((error) => null);
};

const onPersonResponse = async (response: Response): Promise<Person | null> => {
  if (response.status !== 200) return null;
  const data = await response.json();
  const person = new Person().mapToClass(data);
  if (person?.email?.includes(".whatsapp@farmlog.es")) person.email = ""; // User register by WhatsApp
  return person;
};

const fetchAgroBusinessAccounts = async (
  idToken?: string
): Promise<AgroBusinessAccount[] | null> => {
  if (!idToken) return Promise.resolve(null);
  return fetch(`${process.env.REACT_APP_API_URL}/agroBusinessAccount/list`, {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}` },
  })
    .then(onAgroBussinessAccountsResponse)
    .catch((error) => null);
};

const onAgroBussinessAccountsResponse = async (
  response: Response
): Promise<AgroBusinessAccount[] | null> => {
  if (response.status !== 200) return null;
  const data = await response.json();
  // Filter roles NONE
  const authorizedAbAccounts = data.filter(
    (d: any) => d.role !== FBPersonRole.NONE
  );
  // Sort by name
  return authorizedAbAccounts
    .map((d: any) =>
      new AgroBusinessAccount().mapToClass(d.agroBusinessAccount)
    )
    .sort((a: AgroBusinessAccount, b: AgroBusinessAccount) =>
      a.name && b.name ? a.name.localeCompare(b.name) : 0
    );
};

export const refreshTokens = async (
  refreshToken?: string
): Promise<TokensInterface | null> => {
  if (!refreshToken) return Promise.resolve(null);
  return fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refreshToken }),
  })
    .then(onTokensResponse)
    .catch((error) => null);
};

const onTokensResponse = async (
  response: Response
): Promise<TokensInterface | null> => {
  if (response.status !== 200) return null;
  const data = await response.json();
  return data as TokensInterface;
};

export default function AuthLayout() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();

  const [isFetchingToken, setIsFetchingToken] = useState<boolean>(true);
  const [user, setUser] = useState<UserData | null>(null);
  const [agroBusinessAccounts, setAgroBusinessAccounts] = useState<
    AgroBusinessAccount[] | null
  >([]);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isVisibleAddToHomeScreenBanner, setIsVisibleAddToHomeScreenBanner] =
    useState<boolean>(false);

  const authUser = async (idToken: string): Promise<void> => {
    try {
      const person = await getPerson(idToken);
      const agrobusinessAccounts = await fetchAgroBusinessAccounts(idToken);
      setUser(new UserData(person, idToken));
      setAgroBusinessAccounts(agrobusinessAccounts);

      // Set user in Sentry
      if (person?.id) Sentry.setUser({ id: person?.id });

      return Promise.resolve();
    } catch (error: Error | any) {
      return Promise.reject(error);
    }
  };

  const manageAuthSuccess = () => {
    setIsFetchingToken(false);
  };

  const manageAuthError = (error: Error | any) => {
    setIsFetchingToken(false);
    const errorMsg = error?.message || i18n.t("apiResponses.loginError");
    setSnackbarMsg({
      severity: "error",
      message: errorMsg,
    });
    setUser(null);
    removeCookie("refreshToken", { path: "/" });

    Sentry.captureException(error);
  };

  useEffect(() => {
    let unsubscribe: Unsubscribe | undefined;
    const refreshToken = cookies["refreshToken"];

    const userAuthentication = async () => {
      // Server auth
      if (refreshToken) {
        try {
          const tokens = await refreshTokens(refreshToken);
          const idToken = tokens?.accessToken;
          const newRefreshToken = tokens?.refreshToken;
          if (idToken) await authUser(idToken);
          manageAuthSuccess();
          // Save the new refresh token
          setCookie("refreshToken", newRefreshToken, { path: "/" });
        } catch (error: Error | any) {
          manageAuthError(error);
        }
      }
      // Firebase auth
      else {
        const auth = getAuth();
        // Listen Firebase auth changes
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          try {
            const idToken = await user?.getIdToken();
            if (idToken) await authUser(idToken);
            manageAuthSuccess();
          } catch (error: Error | any) {
            signOut(auth);
            manageAuthError(error);
          }
        });
      }
    };

    userAuthentication();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  // Listen route changes and save the last protected route in the cookies
  useEffect(() => {
    const currentPath = location.pathname.substring(1);
    const routeToSave = includedInRoutes(
      location.pathname.substring(1),
      SAVED_ROUTES
    );
    if (currentPath && currentPath.length > 1 && routeToSave !== null)
      setCookie("lastURLPath", routeToSave, { path: "/" });
  }, [location]);

  // Show browser install prompt under some conditions.
  useEffect(() => {
    if (
      prompt &&
      includedInRoutes(location.pathname.substring(1), SAVED_ROUTES) &&
      !isVisibleAddToHomeScreenBanner
    ) {
      const nextShow = cookies["addToHomescreenNextShow"];
      const nShowed = cookies["addToHomescreenShowedNumber"];
      if (
        (!nShowed || nShowed < MAX_ADD_TO_HOMESCREEN_BANNER_SHOWED_NUMBER) &&
        (!nextShow || moment(nextShow).isBefore(moment()))
      ) {
        setIsVisibleAddToHomeScreenBanner(true);
        prompt.userChoice.then(({ outcome }: any) => {
          setIsVisibleAddToHomeScreenBanner(false);
          // Send event to GA4
          if (outcome === "accepted") ReactGA.event("browserAppInstalled");
        });
      }
    }
  }, [prompt, location.pathname, isVisibleAddToHomeScreenBanner]);

  useEffect(() => {
    if (!includedInRoutes(location.pathname.substring(1), SAVED_ROUTES))
      setIsVisibleAddToHomeScreenBanner(false);
  }, [location.pathname]);

  const handleCloseAddToHomescreenBanner = () => {
    setIsVisibleAddToHomeScreenBanner(false);
    const nShowed = cookies["addToHomescreenShowedNumber"] || 0;
    setCookie("addToHomescreenShowedNumber", nShowed + 1, { path: "/" });
    setCookie("addToHomescreenNextShow", moment().add(2, "months").valueOf(), {
      path: "/",
    });
  };

  if (isFetchingToken) return <LoadingWithDelay isVisible />;

  return (
    <AuthProvider userData={user}>
      <SessionProvider agroBusinessAccounts={agroBusinessAccounts}>
        <AlertSnackbar
          open={!!snackbarMsg}
          snackbarMsg={snackbarMsg}
          onClose={() => setSnackbarMsg(null)}
        />
        <AddToHomeScreenBanner
          isOpen={isVisibleAddToHomeScreenBanner}
          onInstall={promptToInstall}
          onClose={handleCloseAddToHomescreenBanner}
        />

        <Outlet />
      </SessionProvider>
    </AuthProvider>
  );
}
