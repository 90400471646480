import { useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";

import { Button, CircularProgress } from "@mui/material";

import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import FormikTextField from "../components/elements/FormikTextField";

import useCrud from "../hooks/useCrud";

import { SnackbarInterface, TokensInterface } from "../constants/interfaces";
import LocalAuthSendCodeRequest from "../models/auth/LocalAuthSendCodeRequest";
import { useAuth } from "../hooks/useAuth";
import { PUBLIC_ROUTES } from "../routes/routeNames";

const RESEND_CODE_BTN_INTERVAL = 1000 * 60;

const EmailCodeScreen = () => {
  const ValidatorSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    code: Yup.string()
      .length(6, i18n.t("formErrors.emailCodeError"))
      .required(i18n.t("formErrors.requiredField")),
  });

  const location = useLocation();
  const email = location.state?.email || "";
  const navigate = useNavigate();
  const { saveRefreshToken } = useAuth();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [resendCodeBtnEnabled, setResendCodeBtnEnabled] =
    useState<boolean>(true);

  const handleSubmit = (values: LocalAuthSendCodeRequest) => {
    postAuthWithEmailCodeMutation.mutate(values);
  };

  const formik = useFormik({
    initialValues: new LocalAuthSendCodeRequest(),
    validationSchema: ValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setFieldValue("email", email);
  }, [email]);

  // Preload recaptcha script
  useEffect(() => {
    const loadRecaptchaScript = () => {
      const url = `https://www.google.com/recaptcha/enterprise.js?render=${
        process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ""
      }`;
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();
  }, []);

  const postAuthWithEmailCodeMutation = useCrud<any>({
    key: "postAuthWithEmailCode",
    onSuccess: (data: TokensInterface) => {
      const refreshToken = data?.refreshToken;
      if (refreshToken) {
        saveRefreshToken(refreshToken);
        navigate(0);
      }
    },
    onError: setSnackbarMsg,
  });

  const sendAuthCodeByEmailMutation = useCrud<any>({
    key: "sendAuthCodeByEmail",
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.resendEmailCodeSuccess"),
      });
      // Disable resend code for a while
      setResendCodeBtnEnabled(false);
      setTimeout(() => {
        setResendCodeBtnEnabled(true);
      }, RESEND_CODE_BTN_INTERVAL);
    },
    onError: setSnackbarMsg,
  });

  const handleCheckCode = () => {
    try {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "", {
            action: "login",
          })
          .then((token) => {
            const values = new LocalAuthSendCodeRequest().mapToClass({
              email,
              code: formik.values.code,
              reCaptchaToken: token,
            });
            if (values) postAuthWithEmailCodeMutation.mutate(values);
          })
          .catch((error) => {
            setSnackbarMsg({
              severity: "error",
              message: i18n.t("apiResponses.recaptchaError"),
            });
            // Send recaptcha error to Sentry
            Sentry.withScope((scope) => {
              scope.setExtra("data", "Google recatpcha error when resend code");
              Sentry.captureException(error);
            });
          });
      });
    } catch (error) {
      setSnackbarMsg({
        severity: "error",
        message: i18n.t("apiResponses.recaptchaError"),
      });
      Sentry.withScope((scope) => {
        scope.setExtra("data", "Typescript recaptcha global type error");
        Sentry.captureException(error);
      });
    }
  };

  const handleResendCode = () => {
    try {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "", {
            action: "send_auth_code_email",
          })
          .then((token) => {
            const values = new LocalAuthSendCodeRequest().mapToClass({
              email,
              reCaptchaToken: token,
            });
            if (values) sendAuthCodeByEmailMutation.mutate(values);
          })
          .catch((error) => {
            setSnackbarMsg({
              severity: "error",
              message: i18n.t("apiResponses.recaptchaError"),
            });
            // Send recaptcha error to Sentry
            Sentry.withScope((scope) => {
              scope.setExtra("data", "Google recatpcha error when resend code");
              Sentry.captureException(error);
            });
          });
      });
    } catch (error) {
      setSnackbarMsg({
        severity: "error",
        message: i18n.t("apiResponses.recaptchaError"),
      });
      Sentry.withScope((scope) => {
        scope.setExtra("data", "Typescript recaptcha global type error");
        Sentry.captureException(error);
      });
    }
  };

  if (!email) return <Navigate to={PUBLIC_ROUTES.HOME} replace />;

  return (
    <div className="emailCode">
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <TestBanner />

      <div className="container">
        <div className="content">
          <h1>{i18n.t("emailCode.title")}</h1>
          <p>
            {i18n.t("emailCode.description")}
            {email}
          </p>
          <FormikTextField
            formik={formik}
            fullWidth
            sx={{ mt: 2, mb: 4 }}
            name="code"
            label={i18n.t("emailCode.codeLabel")}
          />
          <Button
            variant="contained"
            fullWidth
            endIcon={
              postAuthWithEmailCodeMutation.isLoading && (
                <CircularProgress size={20} />
              )
            }
            disabled={postAuthWithEmailCodeMutation.isLoading}
            onClick={handleCheckCode}
          >
            {i18n.t("words.continue")}
          </Button>

          <div className="resend-code-container">
            <span>{i18n.t("emailCode.resendCodeLabel")}</span>
            <Button
              variant="text"
              disabled={!resendCodeBtnEnabled}
              onClick={handleResendCode}
            >
              {i18n.t("emailCode.resendCodeBtn")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCodeScreen;
