import FBAgroBusiness from "../_apiModels/agrobusiness/FBAgrobusiness";

class AgroBusiness extends FBAgroBusiness {
  siexUpdatedOnMillis?: number;

  constructor() {
    super();
  }

  mapToClass(obj: any): AgroBusiness | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (obj?.siexUpdatedOn)
      this.siexUpdatedOnMillis = obj?.siexUpdatedOn * 1000;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.siexUpdatedOnMillis;
    return obj;
  }
}

export default AgroBusiness;
