import FBFertigationWaterMix from "../_apiModels/fertiliz/FBFertigationWaterMix";
import AgroBusinessPartition from "../agrobusiness/AgroBusinessPartition";
import Document from "../files/Document";

class FertigationWaterMix extends FBFertigationWaterMix {
  agroBusinessPartition?: AgroBusinessPartition; // AgroBusinessPartition from Stock movements
  description?: string;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(
    agroBusinessPartition?: AgroBusinessPartition,
    description?: string,
    documents?: Document[]
  ) {
    super();
    this.agroBusinessPartition = agroBusinessPartition;
    this.description = description;
    this.documents = documents;
  }

  mapToClass(obj: any): FertigationWaterMix | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (this.stockMovements && this.stockMovements.length > 0)
      this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
        this.stockMovements[0]?.agroBusinessPartition
      );
    this.documents = obj?.documents?.map((doc: any) =>
      new Document().mapToClass(doc)
    );
    this.lastSavedDocuments = this.documents;
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    // Set mix description and agrobusiness partition to all mixes stock movements
    if (obj.stockMovements)
      obj.stockMovements = obj.stockMovements.map((stockMovement: any) => ({
        ...stockMovement,
        description: this.description,
        agroBusinessPartition: this.agroBusinessPartition?.mapToApiModel(),
      }));
    // Remove custom variables
    delete obj.agroBusinessPartition;
    delete obj.description;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default FertigationWaterMix;
