import VadeProductComposition from "../../vademecum/VadeProductComposition";
import VadeProductOtherName from "../../vademecum/VadeProductOtherName";
import VadeProductUse from "../../vademecum/VadeProductUse";

class FBRegisteredPhytosanitaryProduct {
  id?: number;
  productId?: number;
  registerId?: string;
  name?: string;
  formulation?: string;
  expirationDate?: string;
  cancellationDate?: string;
  saleDeadlineDate?: string;
  registrationDate?: string;
  conditions?: string;
  registerStatus?: string;
  owner?: string;
  manufacturer?: string;
  factory?: string;
  composition?: VadeProductComposition[];
  otherNames?: VadeProductOtherName[];
  uses?: VadeProductUse[];
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    productId?: number,
    registerId?: string,
    name?: string,
    formulation?: string,
    expirationDate?: string,
    cancellationDate?: string,
    saleDeadlineDate?: string,
    registrationDate?: string,
    conditions?: string,
    registerStatus?: string,
    owner?: string,
    manufacturer?: string,
    factory?: string,
    composition?: VadeProductComposition[],
    otherNames?: VadeProductOtherName[],
    uses?: VadeProductUse[],
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.productId = productId;
    this.registerId = registerId;
    this.name = name;
    this.formulation = formulation;
    this.expirationDate = expirationDate;
    this.cancellationDate = cancellationDate;
    this.saleDeadlineDate = saleDeadlineDate;
    this.registrationDate = registrationDate;
    this.conditions = conditions;
    this.registerStatus = registerStatus;
    this.owner = owner;
    this.manufacturer = manufacturer;
    this.factory = factory;
    this.composition = composition;
    this.otherNames = otherNames;
    this.uses = uses;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.productId = obj?.productId;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.formulation = obj?.formulation;
    this.expirationDate = obj?.expirationDate;
    this.cancellationDate = obj?.cancellationDate;
    this.saleDeadlineDate = obj?.saleDeadlineDate;
    this.registrationDate = obj?.registrationDate;
    this.conditions = obj?.conditions;
    this.registerStatus = obj?.registerStatus;
    this.owner = obj?.owner;
    this.manufacturer = obj?.manufacturer;
    this.factory = obj?.factory;
    this.composition = obj?.composition?.map(
      (comp: any) => new VadeProductComposition(comp)
    );
    this.otherNames = obj?.otherNames?.map(
      (otherName: any) => new VadeProductOtherName(otherName)
    );
    this.uses = obj?.uses?.map((use: any) => new VadeProductUse(use));
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.composition = this.composition?.map((comp) => comp.mapBaseToApiModel());
    obj.otherNames = this.otherNames?.map((otherName) =>
      otherName.mapBaseToApiModel()
    );
    obj.uses = this.uses?.map((use) => use.mapBaseToApiModel());
    return obj;
  }
}

export default FBRegisteredPhytosanitaryProduct;
