import FBRegisteredFertilizerProduct from "../_apiModels/vademecumFertiliz/FBRegisteredFertilizerProduct";

class RegisteredFertilizerProduct extends FBRegisteredFertilizerProduct {
  nitrogenTotal?: string;
  ammoniacalNitrogen?: string;
  nitricNitrogen?: string;
  organicNitrogen?: string;
  ureicNitrogen?: string;
  P2O5?: string;
  waterP2O5?: string;
  K2O?: string;
  SO3?: string;
  Na2O?: string;
  CaO?: string;
  MgO?: string;
  B?: string;
  Co?: string;
  Cu?: string;
  Fe?: string;
  Mn?: string;
  Mb?: string;
  Zn?: string;
  nutrientPH?: string;
  cadmiumLabel?: string;
  copperLabel?: string;
  nickelLabel?: string;
  leadLabel?: string;
  zincLabel?: string;
  mercuryLabel?: string;
  chromeTotalLabel?: string;
  chromeVILabel?: string;
  productClassificationLabel?: string;
  organicMaterialPercentageLabel?: string;
  organicCarbonPercentageLabel?: string;
  humicExtractTotalPercentageLabel?: string;
  humicAcidsTotalPercentageLabel?: string;
  fulvicAcidsTotalPercentageLabel?: string;
  clurideContentLabel?: string;
  maxHumidityLabel?: string;
  electricalConductivityLabel?: string;
  densityLabel?: string;
  phLabel?: string;
  CNRelationLabel?: string;
  solubilityDegreeTotalPercentageLabel?: string;
  polyphenolsPercentageLabel?: string;
  waterSolubleProductLabel?: string;

  constructor() {
    super();
  }

  mapToClass(obj: any): RegisteredFertilizerProduct | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    if (
      obj?.nitrogenTotalPercentage &&
      obj.nitrogenTotalPercentage.length > 0 &&
      obj.nitrogenTotalPercentage !== "-"
    )
      this.nitrogenTotal = obj.nitrogenTotalPercentage;
    if (
      obj?.ammoniacalNitrogenPercentage &&
      obj.ammoniacalNitrogenPercentage.length > 0 &&
      obj.ammoniacalNitrogenPercentage !== "-"
    )
      this.ammoniacalNitrogen = obj.ammoniacalNitrogenPercentage;
    if (
      obj?.nitricNitrogenPercentage &&
      obj.nitricNitrogenPercentage.length > 0 &&
      obj.nitricNitrogenPercentage !== "-"
    )
      this.nitricNitrogen = obj.nitricNitrogenPercentage;
    if (
      obj?.organicNitrogenPercentage &&
      obj.organicNitrogenPercentage.length > 0 &&
      obj.organicNitrogenPercentage !== "-"
    )
      this.organicNitrogen = obj.organicNitrogenPercentage;
    if (
      obj?.ureicNitrogenPercentage &&
      obj.ureicNitrogenPercentage.length > 0 &&
      obj.ureicNitrogenPercentage !== "-"
    )
      this.ureicNitrogen = obj.ureicNitrogenPercentage;
    if (
      obj?.P2O5Percentage &&
      obj.P2O5Percentage.length > 0 &&
      obj.P2O5Percentage !== "-"
    )
      this.P2O5 = obj.P2O5Percentage;
    if (
      obj?.waterP2O5Percentage &&
      obj.waterP2O5Percentage.length > 0 &&
      obj.waterP2O5Percentage !== "-"
    )
      this.waterP2O5 = obj.waterP2O5Percentage;
    if (
      obj?.K2OPercentage &&
      obj.K2OPercentage.length > 0 &&
      obj.K2OPercentage !== "-"
    )
      this.K2O = obj.K2OPercentage;
    if (
      obj?.SO3Percentage &&
      obj.SO3Percentage.length > 0 &&
      obj.SO3Percentage !== "-"
    )
      this.SO3 = obj.SO3Percentage;
    if (
      obj?.Na2OPercentage &&
      obj.Na2OPercentage.length > 0 &&
      obj.Na2OPercentage !== "-"
    )
      this.Na2O = obj.Na2OPercentage;
    if (
      obj?.CaOPercentage &&
      obj.CaOPercentage.length > 0 &&
      obj.CaOPercentage !== "-"
    )
      this.CaO = obj.CaOPercentage;
    if (
      obj?.MgOPercentage &&
      obj.MgOPercentage.length > 0 &&
      obj.MgOPercentage !== "-"
    )
      this.MgO = obj.MgOPercentage;
    if (
      obj?.BPercentage &&
      obj.BPercentage.length > 0 &&
      obj.BPercentage !== "-"
    )
      this.B = obj.BPercentage;
    if (
      obj?.CoPercentage &&
      obj.CoPercentage.length > 0 &&
      obj.CoPercentage !== "-"
    )
      this.Co = obj.CoPercentage;
    if (
      obj?.CuPercentage &&
      obj.CuPercentage.length > 0 &&
      obj.CuPercentage !== "-"
    )
      this.Cu = obj.CuPercentage;
    if (
      obj?.FePercentage &&
      obj.FePercentage.length > 0 &&
      obj.FePercentage !== "-"
    )
      this.Fe = obj.FePercentage;
    if (
      obj?.MnPercentage &&
      obj.MnPercentage.length > 0 &&
      obj.MnPercentage !== "-"
    )
      this.Mn = obj.MnPercentage;
    if (
      obj?.MbPercentage &&
      obj.MbPercentage.length > 0 &&
      obj.MbPercentage !== "-"
    )
      this.Mb = obj.MbPercentage;
    if (
      obj?.ZnPercentage &&
      obj.ZnPercentage.length > 0 &&
      obj.ZnPercentage !== "-"
    )
      this.Zn = obj.ZnPercentage;
    if (
      obj?.minPH &&
      obj?.maxPH &&
      obj.minPH.length > 0 &&
      obj.maxPH.length > 0 &&
      obj.minPH !== "-" &&
      obj.maxPH !== "-"
    )
      this.nutrientPH = obj.minPH + " - " + obj.maxPH;
    if (obj?.cadmium && obj.cadmium.length > 0 && obj.cadmium !== "-")
      this.cadmiumLabel = obj.cadmium;
    if (obj?.copper && obj.copper.length > 0 && obj.copper !== "-")
      this.copperLabel = obj.copper;
    if (obj?.nickel && obj.nickel.length > 0 && obj.nickel !== "-")
      this.nickelLabel = obj.nickel;
    if (obj?.lead && obj.lead.length > 0 && obj.lead !== "-")
      this.leadLabel = obj.lead;
    if (obj?.zinc && obj.zinc.length > 0 && obj.zinc !== "-")
      this.zincLabel = obj.zinc;
    if (obj?.mercury && obj.mercury.length > 0 && obj.mercury !== "-")
      this.mercuryLabel = obj.mercury;
    if (
      obj?.chromeTotal &&
      obj.chromeTotal.length > 0 &&
      obj.chromeTotal !== "-"
    )
      this.chromeTotalLabel = obj.chromeTotal;
    if (obj?.chromeVI && obj.chromeVI.length > 0 && obj.chromeVI !== "-")
      this.chromeVILabel = obj.chromeVI;
    if (
      obj?.productClassification &&
      obj.productClassification.length > 0 &&
      obj.productClassification !== "-"
    )
      this.productClassificationLabel = obj.productClassification;
    if (
      obj?.organicMaterialPercentage &&
      obj.organicMaterialPercentage.length > 0 &&
      obj.organicMaterialPercentage !== "-"
    )
      this.organicMaterialPercentageLabel = obj.organicMaterialPercentage;
    if (
      obj?.organicCarbonPercentage &&
      obj.organicCarbonPercentage.length > 0 &&
      obj.organicCarbonPercentage !== "-"
    )
      this.organicCarbonPercentageLabel = obj.organicCarbonPercentage;
    if (
      obj?.humicExtractTotalPercentage &&
      obj.humicExtractTotalPercentage.length > 0 &&
      obj.humicExtractTotalPercentage !== "-"
    )
      this.humicExtractTotalPercentageLabel = obj.humicExtractTotalPercentage;
    if (
      obj?.humicAcidsTotalPercentage &&
      obj.humicAcidsTotalPercentage.length > 0 &&
      obj.humicAcidsTotalPercentage !== "-"
    )
      this.humicAcidsTotalPercentageLabel = obj.humicAcidsTotalPercentage;
    if (
      obj?.fulvicAcidsTotalPercentage &&
      obj.fulvicAcidsTotalPercentage.length > 0 &&
      obj.fulvicAcidsTotalPercentage !== "-"
    )
      this.fulvicAcidsTotalPercentageLabel = obj.fulvicAcidsTotalPercentage;
    if (
      obj?.clurideContent &&
      obj.clurideContent.length > 0 &&
      obj.clurideContent !== "-"
    )
      this.clurideContentLabel = obj.clurideContent;
    if (
      obj?.maxHumidity &&
      obj.maxHumidity.length > 0 &&
      obj.maxHumidity !== "-"
    )
      this.maxHumidityLabel = obj.maxHumidity;
    if (
      obj?.electricalConductivity &&
      obj.electricalConductivity.length > 0 &&
      obj.electricalConductivity !== "-"
    )
      this.electricalConductivityLabel = obj.electricalConductivity;
    if (obj?.density && obj.density.length > 0 && obj.density !== "-")
      this.densityLabel = obj.density;
    if (obj?.ph && obj.ph.length > 0 && obj.ph !== "-") this.phLabel = obj.ph;
    if (obj?.CNRelation && obj.CNRelation.length > 0 && obj.CNRelation !== "-")
      this.CNRelationLabel = obj.CNRelation;
    if (
      obj?.solubilityDegreeTotalPercentage &&
      obj.solubilityDegreeTotalPercentage.length > 0 &&
      obj.solubilityDegreeTotalPercentage !== "-"
    )
      this.solubilityDegreeTotalPercentageLabel =
        obj.solubilityDegreeTotalPercentage;
    if (
      obj?.polyphenolsPercentage &&
      obj.polyphenolsPercentage.length > 0 &&
      obj.polyphenolsPercentage !== "-"
    )
      this.polyphenolsPercentageLabel = obj.polyphenolsPercentage;
    if (
      obj?.waterSolubleProduct &&
      obj.waterSolubleProduct.length > 0 &&
      obj.waterSolubleProduct !== "-"
    )
      this.waterSolubleProductLabel = obj.waterSolubleProduct;

    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.nitrogenTotal;
    delete obj.ammoniacalNitrogen;
    delete obj.nitricNitrogen;
    delete obj.organicNitrogen;
    delete obj.ureicNitrogen;
    delete obj.P2O5;
    delete obj.waterP2O5;
    delete obj.K2O;
    delete obj.SO3;
    delete obj.Na2O;
    delete obj.CaO;
    delete obj.MgO;
    delete obj.B;
    delete obj.Co;
    delete obj.Cu;
    delete obj.Fe;
    delete obj.Mn;
    delete obj.Mb;
    delete obj.Zn;
    delete obj.nutrientPH;
    delete obj.cadmiumLabel;
    delete obj.copperLabel;
    delete obj.nickelLabel;
    delete obj.leadLabel;
    delete obj.zincLabel;
    delete obj.mercuryLabel;
    delete obj.chromeTotalLabel;
    delete obj.chromeVILabel;
    delete obj.productClassificationLabel;
    delete obj.organicMaterialPercentageLabel;
    delete obj.organicCarbonPercentageLabel;
    delete obj.humicExtractTotalPercentageLabel;
    delete obj.humicAcidsTotalPercentageLabel;
    delete obj.fulvicAcidsTotalPercentageLabel;
    delete obj.clurideContentLabel;
    delete obj.maxHumidityLabel;
    delete obj.electricalConductivityLabel;
    delete obj.densityLabel;
    delete obj.phLabel;
    delete obj.CNRelationLabel;
    delete obj.solubilityDegreeTotalPercentageLabel;
    delete obj.polyphenolsPercentageLabel;
    delete obj.waterSolubleProductLabel;

    return obj;
  }
}

export default RegisteredFertilizerProduct;
