import FBVadeFertilizerManufacturer from "../_apiModels/vademecumFertiliz/FBVadeFertilizerManufacturer";

class VadeFertilizerManufacturer extends FBVadeFertilizerManufacturer {
  constructor() {
    super();
  }

  mapToClass(obj: any): VadeFertilizerManufacturer | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default VadeFertilizerManufacturer;
