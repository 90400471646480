import {
  FBESiexOperationResult,
  FBESiexOperationType,
} from "../../../constants/enums";
import Person from "../../Person";
import SiexRequest from "../../siex/SiexRequest";

class FBSiexRequestItem {
  id?: number;
  request?: SiexRequest;
  entityClassName?: string;
  entityClassId?: number;
  operationType?: FBESiexOperationType;
  operationResult?: FBESiexOperationResult;
  errorMessage?: string;
  siexId?: number;
  siexBookId?: number;
  revisedBy?: Person;
  revisedTimestamp?: number;
  discarded?: boolean;

  constructor(
    id?: number,
    request?: SiexRequest,
    entityClassName?: string,
    entityClassId?: number,
    operationType?: FBESiexOperationType,
    operationResult?: FBESiexOperationResult,
    errorMessage?: string,
    siexId?: number,
    siexBookId?: number,
    revisedBy?: Person,
    revisedTimestamp?: number,
    discarded?: boolean
  ) {
    this.id = id;
    this.request = request;
    this.entityClassName = entityClassName;
    this.entityClassId = entityClassId;
    this.operationType = operationType;
    this.operationResult = operationResult;
    this.errorMessage = errorMessage;
    this.siexId = siexId;
    this.siexBookId = siexBookId;
    this.revisedBy = revisedBy;
    this.revisedTimestamp = revisedTimestamp;
    this.discarded = discarded;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.request = new SiexRequest().mapToClass(obj?.request);
    this.entityClassName = obj?.entityClassName;
    this.entityClassId = obj?.entityClassId;
    this.operationType = obj?.operationType;
    this.operationResult = obj?.operationResult;
    this.errorMessage = obj?.errorMessage;
    this.siexId = obj?.siexId;
    this.siexBookId = obj?.siexBookId;
    this.revisedBy = new Person().mapToClass(obj?.revisedBy);
    this.revisedTimestamp = obj?.revisedTimestamp;
    this.discarded = obj?.discarded;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.request = this.request?.mapToApiModel();
    return obj;
  }
}

export default FBSiexRequestItem;
