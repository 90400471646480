import React, { useState, useEffect } from "react";
import { FormikProps } from "formik";
import i18n from "../../config/configI18n";

import {
  Autocomplete,
  TextField,
  Checkbox,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  SvgIconComponent,
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

import AddNewOptionListItem from "./AddNewOptionListItem";

import {
  getFieldValueByColumnNotation,
  stringMatching,
} from "../../helpers/utils";

const getLabel = (option: any, optionLabelFieldName: string) => {
  if (optionLabelFieldName.length === 0) return option;
  let label = option?.name || "";
  if (optionLabelFieldName) {
    const optionLabel = getFieldValueByColumnNotation(
      optionLabelFieldName,
      option
    );
    if (optionLabel) label = optionLabel;
  }
  return label;
};

interface Props {
  className?: string;
  name: string;
  formik: FormikProps<any>;
  options: any[];
  optionLabelFieldName?: string;
  label: string;
  placeholder?: string;
  noOptionsText?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  defaultValue?: any;
  multiple?: boolean;
  altOptionLabel?: string;
  startIcon?: SvgIconComponent;
  endIcon?: SvgIconComponent;
  startIconPosition?: "start" | "end";
  endIconPosition?: "start" | "end";
  addNewOption?: boolean;
  filterOptions?: (options: any[], state: any) => any[];
  onChange?: (option: any | any[]) => void;
  onInputChange?: (event: any, value: any) => void;
  onClickNewOption?: () => void;
  groupBy?: (option: any) => string;
  freeSolo?: boolean;
  clearOnBlur?: boolean;
}
const FormikAutocomplete = (props: Props) => {
  return props.multiple ? (
    <FormikAutocompleteMultiple {...props} />
  ) : (
    <FormikAutocompleteSimple {...props} />
  );
};

export default FormikAutocomplete;

const FormikAutocompleteSimple = (props: Props) => {
  const {
    className,
    name,
    formik,
    options,
    optionLabelFieldName = "",
    label,
    placeholder,
    helperText = "",
    noOptionsText = i18n.t("formErrors.notFoundResults"),
    required = false,
    disabled = false,
    loading = false,
    addNewOption = false,
    startIcon: StartIcon,
    endIcon: EndIcon,
    startIconPosition = "start",
    endIconPosition = "end",
    filterOptions,
    groupBy,
    freeSolo,
    clearOnBlur,
    altOptionLabel,
    onChange,
    onInputChange,
    onClickNewOption,
  } = props;
  const errorTouched = getFieldValueByColumnNotation(name, formik.touched);
  const errorFormik = getFieldValueByColumnNotation(name, formik.errors);
  const error = errorTouched && Boolean(errorFormik);
  const errorMsg =
    typeof errorFormik === "string" ? (errorFormik as string) : "";
  const value = getFieldValueByColumnNotation(name, formik.values) || "";
  const simpleValue = value[0] || value;

  const [menuOptions, setMenuOptions] = useState<any[]>(options);

  // Add new option to menu options
  useEffect(() => {
    if (addNewOption) setMenuOptions([...options, { id: "new" }]);
    else setMenuOptions(options);
  }, [addNewOption, options]);

  const handleChange = (event: any, value: any) => {
    if (value?.id === "new") onClickNewOption && onClickNewOption();
    else !!onChange ? onChange(value) : formik.setFieldValue(name, value);
  };

  const defaultFilterOptions = (options: any[], params: any) => {
    const { inputValue } = params;
    if (!inputValue) return options;

    return options.filter((option) => {
      const optionValue = option[optionLabelFieldName] || "";
      const value = inputValue || "";
      return stringMatching(optionValue, value);
    });
  };

  return (
    <Autocomplete
      id={name}
      className={className || "form-input"}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      options={menuOptions}
      getOptionLabel={(option) =>
        getLabel(option, optionLabelFieldName) || altOptionLabel || ""
      }
      groupBy={groupBy}
      filterOptions={filterOptions || defaultFilterOptions}
      noOptionsText={noOptionsText}
      loading={loading}
      loadingText={i18n.t("words.loadingDots")}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={simpleValue}
      onChange={handleChange}
      onInputChange={onInputChange}
      onBlur={formik.handleBlur}
      renderOption={(props, option) =>
        option.id === "new" ? (
          <AddNewOptionListItem key={option.id} onClick={onClickNewOption} />
        ) : (
          <li {...props} key={option.id}>
            {getLabel(option, optionLabelFieldName)}
          </li>
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder}
          helperText={error ? errorMsg : helperText}
          FormHelperTextProps={{
            style: { ...helperTextStyle, color: error ? "#c62828" : "" },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: StartIcon && (
              <InputAdornment position={startIconPosition}>
                <StartIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : EndIcon ? (
                  <InputAdornment position={endIconPosition}>
                    <EndIcon />
                  </InputAdornment>
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          error={error}
        />
      )}
    />
  );
};

const FormikAutocompleteMultiple = (props: Props) => {
  const {
    className,
    name,
    formik,
    options,
    optionLabelFieldName = "",
    label,
    placeholder,
    helperText = "",
    noOptionsText = i18n.t("formErrors.notFoundResults"),
    required = false,
    disabled = false,
    loading = false,
    addNewOption = false,
    groupBy,
    filterOptions,
    onChange,
    onInputChange,
    onClickNewOption,
    freeSolo,
    clearOnBlur,
  } = props;
  const errorTouched = getFieldValueByColumnNotation(name, formik.touched);
  const errorFormik = getFieldValueByColumnNotation(name, formik.errors);
  const error = errorTouched && Boolean(errorFormik);
  const errorMsg =
    typeof errorFormik === "string" ? (errorFormik as string) : "";
  const value = getFieldValueByColumnNotation(name, formik.values) || [];

  const [menuOptions, setMenuOptions] = useState<any[]>(options);

  // Add new option to menu options
  useEffect(() => {
    if (addNewOption) setMenuOptions([...options, { id: "new" }]);
    else setMenuOptions(options);
  }, [addNewOption, options]);

  const handleChange = (event: any, value: any[]) => {
    (onChange && onChange(value)) || formik.setFieldValue(name, value);
  };

  const defaultFilterOptions = (options: any[], params: any) => {
    const { inputValue } = params;
    if (!inputValue) return options;

    return options.filter((option) => {
      const optionValue = option[optionLabelFieldName] || "";
      const value = inputValue || "";
      return stringMatching(optionValue, value);
    });
  };

  return (
    <Autocomplete
      id={name}
      className={className || "form-input"}
      freeSolo={freeSolo}
      clearOnBlur={clearOnBlur}
      multiple
      loading={loading}
      loadingText={i18n.t("words.loadingDots")}
      disabled={disabled}
      disableCloseOnSelect
      options={menuOptions}
      getOptionLabel={(option) => getLabel(option, optionLabelFieldName)}
      noOptionsText={noOptionsText}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      groupBy={groupBy}
      filterOptions={filterOptions || defaultFilterOptions}
      value={value}
      onChange={handleChange}
      onInputChange={onInputChange}
      onBlur={formik.handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          placeholder={placeholder}
          helperText={error ? errorMsg : helperText}
          FormHelperTextProps={{
            style: { ...helperTextStyle, color: error ? "#c62828" : "" },
          }}
          error={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { selected }) =>
        option.id === "new" ? (
          <AddNewOptionListItem key={option.id} onClick={onClickNewOption} />
        ) : (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {getLabel(option, optionLabelFieldName)}
          </li>
        )
      }
    />
  );
};

const helperTextStyle: React.CSSProperties = {
  fontSize: 12,
  margin: 4,
  marginBottom: 0,
  color: "#c62828",
};
