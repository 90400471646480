import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import AlertEmptyList from "../AlertEmptyList";

import Sector from "../../models/agrobusiness/Sector";
import Crop from "../../models/crop/Crop";
import CropSector from "../../models/crop/CropSector";
import FertilizationSector from "../../models/fertiliz/FertilizationSector";

import { getMaxValueOfList } from "../../helpers/utils";
import useFetch from "../../hooks/useFetch";

import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { SnackbarInterface } from "../../constants/interfaces";

interface DialogFertilizationTreatedSectorProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (fertilizerSector: FertilizationSector) => void;
  crop?: Crop;
  selected?: FertilizationSector | null;
  fertilizerSectors?: FertilizationSector[];
  onError?: (snackbarError: SnackbarInterface) => void;
}

const filter = createFilterOptions();
const DialogFertilizationTreatedSector = (
  props: DialogFertilizationTreatedSectorProps
) => {
  const YupValidatorSchema = Yup.object().shape({
    sector: Yup.object().required(),
    fertilizedArea: Yup.number().required(),
  });

  const {
    open,
    onClose,
    onConfirm,
    crop,
    selected,
    fertilizerSectors = [],
    onError,
  } = props;

  const navigate = useNavigate();

  const [cropSectors, setCropSectors] = useState<CropSector[]>([]);
  const [selectedCropSectorArea, setSelectedCropSectorArea] =
    useState<number>(0);
  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const onFetchCropSuccess = (crop: Crop) => {
    setCropSectors(crop?.cropSectors || []);
  };

  const { isFetching } = useFetch<Crop>({
    queryKey: ["crop", crop?.id],
    selected: crop,
    enabled: !!crop?.id,
    onSuccess: onFetchCropSuccess,
    onError,
  });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
    setSelectedCropSectorArea(0);
  };

  const handleSubmit = (values: FertilizationSector) => {
    // Find the max idx of the cropSectors
    const maxIdx = getMaxValueOfList(fertilizerSectors, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FertilizationSector(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) {
      const cropSector = cropSectors.find(
        (cs) => cs.sector?.id === selected.sector?.id
      );
      const treatedArea = cropSector?.area || selected?.sector?.area || 0;
      setSelectedCropSectorArea(treatedArea);
      formik.setValues(selected);
    }
  }, [selected, cropSectors]);

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
  };

  const handleConfirm = () => {
    formik.handleSubmit();
  };

  const handleChangeCropSector = (cropSector: CropSector) => {
    const treatedArea = cropSector?.area || cropSector?.sector?.area || 0;
    setSelectedCropSectorArea(treatedArea);
    formik.setFieldValue("fertilizedArea", treatedArea);
    if (cropSector?.sector) {
      formik.setFieldValue("sector", cropSector.sector);
    }
  };

  const handleChangeTreatedArea = (event: any) => {
    const treatedArea = event.target.value || 0;
    if (treatedArea > selectedCropSectorArea)
      event.target.value = selectedCropSectorArea;
  };

  // Filter selected sectors
  const cropSectorsFilter = (options: CropSector[], params: any) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (cs: any) =>
        !fertilizerSectors.find((fs) => fs.sector?.id === cs?.sector?.id)
    );
    if (selected) filterSelected.unshift(selected.sector as Sector);
    return filterSelected;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("components.dialogFertilizationTreatedSector.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogFertilizationTreatedSector.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetching && cropSectors?.length === 0 && (
              <AlertEmptyList
                severity="info"
                btnText="Asociar ahora"
                onClick={handleClickNewOption}
              >
                {i18n.t(
                  "components.dialogFertilizationTreatedSector.emptyListSectors"
                )}
              </AlertEmptyList>
            )}
            {!isFetching &&
              !selected &&
              cropSectors.length !== 0 &&
              cropSectors?.length === fertilizerSectors?.length && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {i18n.t(
                    "components.dialogFertilizationTreatedSector.noMoreSectors"
                  )}
                </Alert>
              )}
            <FormikAutocomplete
              formik={formik}
              name="sector"
              label={i18n.t(
                "components.dialogFertilizationTreatedSector.sector"
              )}
              optionLabelFieldName="sector.name"
              required
              filterOptions={cropSectorsFilter}
              options={cropSectors}
              loading={isFetching}
              disabled={!!selected}
              onChange={handleChangeCropSector}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <FormikTextField
              formik={formik}
              className="form-input-row-60"
              name="fertilizedArea"
              label={i18n.t(
                "components.dialogFertilizationTreatedSector.fertilizedArea"
              )}
              type="number"
              valueUnit="ha"
              inputProps={{ min: 0, max: selectedCropSectorArea }}
              onInput={handleChangeTreatedArea}
            />
            <TextField
              name="totalArea"
              disabled
              className="form-input-row-40"
              variant="outlined"
              type="number"
              label={i18n.t(
                "components.dialogFertilizationTreatedSector.totalArea"
              )}
              helperText={i18n.t(
                "components.dialogFertilizationTreatedSector.totalAreaHelperText"
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ha</InputAdornment>
                ),
              }}
              value={selectedCropSectorArea}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogFertilizationTreatedSector.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogFertilizationTreatedSector.redirectAlertDialogContentText"
        )}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogFertilizationTreatedSector;
