import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import {
  FormGroup,
  FormControl,
  Tabs,
  Tab,
  Collapse,
  ListItem,
  ListItemText,
  AppBar,
  Link,
} from "@mui/material";
import { Dashboard, Water } from "@mui/icons-material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import { IOSSwitch as Switch } from "../../components/elements/IOSSwitch";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import DialogAddWaterSource from "../../components/dialogs/DialogAddWaterSource";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import PartitionForm from "../../components/forms/PartitionForm";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import SiexDownloadStatusBanner from "../../components/banners/SiexDownloadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import Sector from "../../models/agrobusiness/Sector";
import WaterSource from "../../models/agrobusiness/WaterSource";
import TenureType from "../../models/catalogue/TenureType";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import {
  EMPTY_TABLE_ICON_SIZE,
  SIGPAC_MAP_URL,
} from "../../constants/constants";

import { updateItemOfList } from "../../helpers/utils";

const ExploitationSectorsScreen = () => {
  const sectorColumns: Column[] = [
    { id: "name", label: i18n.t("sectors.column.name"), numeric: false },
    {
      id: "area",
      label: i18n.t("sectors.column.area"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
    {
      id: "waterSources",
      valueLabel: "count",
      label: i18n.t("sectors.column.waterSources"),
      numeric: true,
    },
  ];

  const YupValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
    area: Yup.number().required(),
    cadastralRef: Yup.string().when("isCadastralRef", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const validateSigpacCode = (values: Sector) => {
    const errors: any = {};
    if (values.sigpacCode && values.sigpacCode.length > 0) {
      const sigpacCode = values.sigpacCode?.split(":");
      const filteredSigpacCode = sigpacCode?.filter(
        (code: string) => code?.length > 0
      );
      if (!filteredSigpacCode || filteredSigpacCode.length < 2) {
        errors.sigpacCode = i18n.t("formErrors.sigpacCode1");
      } else if (
        values.sigpacCode &&
        values.sigpacCode.replaceAll(":", "").length > 14
      ) {
        errors.sigpacCode = i18n.t("formErrors.sigpacCode2");
      }
    }
    return errors;
  };

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? sectors?.find((s: Sector) => s.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, sectors]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );
  const isSelectedSiexSync = useMemo(
    () => selected?.siexEntity === true,
    [selected]
  );

  const handleSubmit = (values: Sector) => {
    switch (formMode) {
      case FormMode.CREATE:
        sectorCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        sectorEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new Sector(),
    validate: validateSigpacCode,
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("sectors.drawerTitleCreate")
      : i18n.t("sectors.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setTabValue(0);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const sectorCreateMutation = useCrud<Sector>({
    key: "postSector",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const sectorEditMutation = useCrud<Sector>({
    key: "putSector",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const sectorDeleteMutation = useCrud({
    key: "deleteSectors",
    values: sectors?.filter((s: Sector) => selectedRowIds.includes(s?.id || 0)),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      // If there are errors, set tab to the first tab with errors
      setTabValue(0);
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    sectorDeleteMutation.mutate(selectedRowIds);
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <SiexBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("sectors.backAlertTitle")}
        contentText={i18n.t("sectors.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("sectors.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("sectors.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={sectorDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("sectors.tableTitle")}
        headerText={i18n.t("sectors.headerText")}
        colums={sectorColumns}
        rows={sectors}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Dashboard sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("sectors.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("sectors.emptyTableSubtitleSIEX")
            : i18n.t("sectors.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("sectors.emptyTableBtnText")
            : undefined
        }
        addBtnVariant="contained"
        isLoading={isFetching}
        siexRows
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          sectorCreateMutation.isLoading || sectorEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <SectorsForm
          formik={formik}
          tabValue={tabValue}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          isSelectedSiexSync={isSelectedSiexSync}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationSectorsScreen;

interface SectorsFormProps {
  formik: FormikProps<Sector>;
  tabValue: number;
  isSiexActive: boolean;
  isSelectedSiexSync: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: Sector;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}
const SectorsForm = (props: SectorsFormProps) => {
  const {
    formik,
    tabValue,
    selectedEditRow,
    isOpenFilesForm = false,
    isSiexActive,
    isSelectedSiexSync,
    onCloseFilesForm,
    onError,
    onChangeTabValue,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<Sector>({
    queryKey: ["sector", selectedEditRow?.id],
    selected: selectedEditRow,
    enabled: !!selectedEditRow?.id,
    onSuccess: (data: Sector) => {
      formik.setValues(data);
      // Update sector in array without refetch
      queryClient.setQueryData<Sector[]>(
        ["sectors", selectedABAccount?.id, selectedABPartition?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive ? (
        <SiexDownloadStatusBanner
          isSync={isSelectedSiexSync}
          timestamp={selectedEditRow?.siexUpdatedOn}
        />
      ) : (
        <SiexBanner adviceText />
      )}
      <AppBar
        className="tabbar"
        component="nav"
        position="sticky"
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            className="tab"
            wrapped
            label={i18n.t("sectors.tab.sector")}
            icon={<Dashboard />}
          />
          <Tab
            className="tab"
            wrapped
            label={i18n.t("sectors.tab.waterSources")}
            icon={<Water />}
          />
        </Tabs>
      </AppBar>

      {tabValue === 1 ? (
        <WaterSourcesTab {...props} />
      ) : (
        <SectorTab {...props} />
      )}

      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("sectors.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const SectorTab = (props: SectorsFormProps) => {
  return (
    <>
      <LocationForm {...props} />
      <AreaForm {...props} />
      <CadastralForm {...props} />
      <CropTypesForm {...props} />
      <TenureForm {...props} />
    </>
  );
};

const LocationForm = (props: SectorsFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync } = props;

  const SigpacLink = () => (
    <Link
      style={helperTextStyle}
      sx={{
        color: (theme) => `${theme.palette.primary.light} !important`,
      }}
      href={SIGPAC_MAP_URL}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      {i18n.t("sectors.sectorTab.locationForm.sigpacLink")}
    </Link>
  );

  const handleChangeSigpacCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remove no numeric characters except ":"
    const newValue = value.replace(/[^0-9:]/g, "");
    formik.setFieldValue("sigpacCode", newValue);
  };

  return (
    <BrioCard
      title={i18n.t("sectors.sectorTab.locationForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("sectors.sectorTab.locationForm.name")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="sigpacCode"
            label={i18n.t("sectors.sectorTab.locationForm.sigpacCode")}
            placeholder={i18n.t(
              "sectors.sectorTab.locationForm.sigpacCodePlaceholder"
            )}
            onChange={handleChangeSigpacCode}
            disabled={isSelectedSiexSync}
          />
          {!isSelectedSiexSync && <SigpacLink />}
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
const helperTextStyle: React.CSSProperties = {
  fontSize: 12,
  margin: 4,
  marginBottom: 0,
  color: "#c62828",
};

const AreaForm = (props: SectorsFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync } = props;

  return (
    <BrioCard
      title={i18n.t("sectors.sectorTab.areaForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="area"
            label={i18n.t("sectors.sectorTab.areaForm.area")}
            type="number"
            required
            valueUnit="ha"
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const CadastralForm = (props: SectorsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    formik.setFieldValue("isCadastralRef", checked);
  };

  return (
    <BrioCard title={i18n.t("sectors.sectorTab.cadastralForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <ListItem className="list-item-field">
            <ListItemText
              primary={i18n.t("sectors.sectorTab.cadastralForm.isCadastralRef")}
              secondary={i18n.t(
                "sectors.sectorTab.cadastralForm.isCadastralRefSubtitle"
              )}
              primaryTypographyProps={{
                variant: "subtitle1",
                className: "list-item-field-primary-text",
              }}
              secondaryTypographyProps={{
                variant: "subtitle2",
                className: "list-item-field-secondary-text",
              }}
            />
            <Switch
              checked={formik.values.isCadastralRef}
              onChange={handleChangeSwitch}
              disabled={isSelectedSiexSync}
            />
          </ListItem>
        </FormControl>
        <Collapse in={formik.values.isCadastralRef}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={props.formik}
                name="cadastralRef"
                label={i18n.t("sectors.sectorTab.cadastralForm.cadastralRef")}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const CropTypesForm = (props: SectorsFormProps) => {
  const { isSelectedSiexSync } = props;
  return (
    <BrioCard title={i18n.t("sectors.sectorTab.cropTypesForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={props.formik}
            name="communalPasture"
            primaryText={i18n.t(
              "sectors.sectorTab.cropTypesForm.communalPasture"
            )}
            secondaryText={i18n.t(
              "sectors.sectorTab.cropTypesForm.communalPastureSubtitle"
            )}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={props.formik}
            name="communalGrass"
            primaryText={i18n.t(
              "sectors.sectorTab.cropTypesForm.communalGrass"
            )}
            secondaryText={i18n.t(
              "sectors.sectorTab.cropTypesForm.communalGrassSubtitle"
            )}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TenureForm = (props: SectorsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const { selectedABAccount } = useSession();

  const { data: tenureTypes, isFetching } = useFetch<TenureType[]>({
    queryKey: ["tenureTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
  });

  return (
    <BrioCard title={i18n.t("sectors.sectorTab.tenureForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="tenureType"
            label={i18n.t("sectors.sectorTab.tenureForm.tenureType")}
            optionLabelFieldName="name"
            options={tenureTypes || []}
            isLoading={isFetching}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <Collapse in={formik.values.isLessor()}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="lessorId"
                label={i18n.t("sectors.sectorTab.tenureForm.lessorId")}
                placeholder={i18n.t(
                  "sectors.sectorTab.tenureForm.lessorIdPlaceholder"
                )}
                helperText={i18n.t(
                  "sectors.sectorTab.tenureForm.lessorIdHelperText"
                )}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl className="form-control">
              <FormikListItemSwitch
                formik={formik}
                name="foreignLessorId"
                primaryText={i18n.t(
                  "sectors.sectorTab.tenureForm.foreignLessorId"
                )}
                secondaryText={i18n.t(
                  "sectors.sectorTab.tenureForm.foreignLessorIdSubtitle"
                )}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const WaterSourcesTab = (props: SectorsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const waterSourcesColumns: Column[] = [
    {
      id: "name",
      label: i18n.t("sectors.waterSourcesTab.column.name"),
      numeric: false,
    },
    {
      id: "waterSourceType.name",
      label: i18n.t("sectors.waterSourcesTab.column.type"),
      numeric: false,
    },
  ];
  const btnText =
    formik.values.waterSources && formik.values.waterSources.length > 0
      ? i18n.t("words.update")
      : i18n.t("words.add");

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const handleClickAdd = () => setIsOpenDialog(true);
  const closeDialog = () => setIsOpenDialog(false);

  const handleConfirmDialog = (selected: WaterSource[]) => {
    formik.setFieldValue("waterSources", selected);
    closeDialog();
  };

  const handleClickDelete = (ids: number[]) => {
    setSelectedRowIds([]);
    const newWaterSources = formik.values.waterSources?.filter(
      (ws: WaterSource) => !ids.includes(ws?.id || -1)
    );
    formik.setFieldValue("waterSources", newWaterSources);
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        title={i18n.t("sectors.waterSourcesTab.tableTitle")}
        headerText={i18n.t("sectors.waterSourcesTab.headerText")}
        btnText={btnText}
        colums={waterSourcesColumns}
        rows={formik.values.waterSources}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickDelete={handleClickDelete}
        hideBtn={isSelectedSiexSync}
        disabled={isSelectedSiexSync}
      />
      <DialogAddWaterSource
        open={isOpenDialog}
        selected={formik.values.waterSources}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};
