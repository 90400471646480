import { PhytoRecipeStatus, PhytoRecipeType } from "../../../constants/enums";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import PhytosanitaryActionJustificationType from "../../catalogue/PhytosanitaryActionJustificationType";
import PlantDisease from "../../catalogue/PlantDisease";
import PlantPlague from "../../catalogue/PlantPlague";
import PlantRegulatorsAndOthers from "../../catalogue/PlantRegulatorsAndOthers";
import PlantWeed from "../../catalogue/PlantWeed";
import RegisteredPhytosanitaryProduct from "../../vademecum/RegisteredPhytosanitaryProduct";
import Crop from "../../crop/Crop";
import PhytosanitaryProduct from "../../phyto/PhytosanitaryProduct";
import PhytoRecipeStatusHistory from "../../phytoRecipe/PhytoRecipeStatusHistory";
import VadeProductUse from "../../vademecum/VadeProductUse";

class FBPhytoRecipe {
  id?: number;
  type?: PhytoRecipeType;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  status?: PhytoRecipeStatus;
  statusHistory?: PhytoRecipeStatusHistory[];
  date?: string;
  description?: string;
  startRecipeDate?: string;
  endRecipeDate?: string;
  crop?: Crop;
  product?: PhytosanitaryProduct;
  registeredProduct?: RegisteredPhytosanitaryProduct;
  productsUses?: VadeProductUse[];
  weeds?: PlantWeed[];
  plagues?: PlantPlague[];
  diseases?: PlantDisease[];
  regulators?: PlantRegulatorsAndOthers[];
  observations?: string;
  dose?: number;
  doseUnit?: MeasurementUnit;
  justifications?: PhytosanitaryActionJustificationType[];
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    type?: PhytoRecipeType,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    status?: PhytoRecipeStatus,
    statusHistory?: PhytoRecipeStatusHistory[],
    date?: string,
    description?: string,
    startRecipeDate?: string,
    endRecipeDate?: string,
    crop?: Crop,
    product?: PhytosanitaryProduct,
    registeredProduct?: RegisteredPhytosanitaryProduct,
    productsUses?: VadeProductUse[],
    weeds?: PlantWeed[],
    plagues?: PlantPlague[],
    diseases?: PlantDisease[],
    regulators?: PlantRegulatorsAndOthers[],
    observations?: string,
    dose?: number,
    doseUnit?: MeasurementUnit,
    justifications?: PhytosanitaryActionJustificationType[],
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.type = type;
    this.advisor = advisor;
    this.status = status;
    this.statusHistory = statusHistory;
    this.date = date;
    this.description = description;
    this.startRecipeDate = startRecipeDate;
    this.endRecipeDate = endRecipeDate;
    this.crop = crop;
    this.product = product;
    this.registeredProduct = registeredProduct;
    this.productsUses = productsUses;
    this.weeds = weeds;
    this.plagues = plagues;
    this.diseases = diseases;
    this.regulators = regulators;
    this.observations = observations;
    this.dose = dose;
    this.doseUnit = doseUnit;
    this.justifications = justifications;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj.id;
    this.type = obj.type;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.status = obj.status;
    this.statusHistory = obj?.statusHistory?.map((statusHistory: any) =>
      new PhytoRecipeStatusHistory().mapToClass(statusHistory)
    );
    this.date = obj.date;
    this.description = obj.description;
    this.startRecipeDate = obj.startRecipeDate;
    this.endRecipeDate = obj.endRecipeDate;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.product = new PhytosanitaryProduct().mapToClass(obj?.product);
    this.registeredProduct = new RegisteredPhytosanitaryProduct().mapToClass(
      obj?.registeredProduct
    );
    this.productsUses = obj?.productsUses?.map(
      (productUse: any) => new VadeProductUse(productUse)
    );
    this.weeds = obj?.weeds?.map((weed: any) =>
      new PlantWeed().mapToClass(weed)
    );
    this.plagues = obj?.plagues?.map((plague: any) =>
      new PlantPlague().mapToClass(plague)
    );
    this.diseases = obj?.diseases?.map((disease: any) =>
      new PlantDisease().mapToClass(disease)
    );
    this.regulators = obj?.regulators?.map((regulator: any) =>
      new PlantRegulatorsAndOthers().mapToClass(regulator)
    );
    this.observations = obj.observations;
    this.dose = obj.dose;
    this.doseUnit = new MeasurementUnit().mapToClass(obj?.doseUnit);
    this.justifications = obj.justifications?.map((justification: any) =>
      new PhytosanitaryActionJustificationType().mapToClass(justification)
    );
    this.enabledDate = obj.enabledDate;
    this.disabledDate = obj.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.advisor = this.advisor?.mapToApiModel();
    obj.crop = this.crop?.mapToApiModel();
    obj.product = this.product?.mapToApiModel();
    obj.registeredProduct = this.registeredProduct?.mapToApiModel();
    obj.productsUses = this.productsUses?.map((productUse: VadeProductUse) =>
      productUse?.mapToApiModel()
    );
    obj.weeds = this.weeds?.map((weed: PlantWeed) => weed?.mapToApiModel());
    obj.plagues = this.plagues?.map((plague: PlantPlague) =>
      plague?.mapToApiModel()
    );
    obj.diseases = this.diseases?.map((disease: PlantDisease) =>
      disease?.mapToApiModel()
    );
    obj.regulators = this.regulators?.map(
      (regulator: PlantRegulatorsAndOthers) => regulator?.mapToApiModel()
    );
    obj.doseUnit = this.doseUnit?.mapToApiModel();
    obj.justifications = this.justifications?.map(
      (justification: PhytosanitaryActionJustificationType) =>
        justification?.mapToApiModel()
    );

    return obj;
  }
}

export default FBPhytoRecipe;
