import {
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import moment from "moment";
import { getAuth } from "firebase/auth";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";
import { Cookies } from "react-cookie";

import { useAuth } from "./useAuth";
import { useSession } from "./useSession";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessPartition from "../models/agrobusiness/AgroBusinessPartition";
import Fertilization from "../models/fertiliz/Fertilization";
import Crop from "../models/crop/Crop";
import FBDocument from "../models/_apiModels/files/FBDocument";
import Document from "../models/files/Document";
import Sector from "../models/agrobusiness/Sector";
import FertilizerProduct from "../models/fertiliz/FertilizerProduct";
import RegisteredFertilizerProduct from "../models/vademecumFertiliz/RegisteredFertilizerProduct";
import PhytosanitaryProduct from "../models/phyto/PhytosanitaryProduct";
import RegisteredPhytosanitaryProduct from "../models/vademecum/RegisteredPhytosanitaryProduct";
import WaterSource from "../models/agrobusiness/WaterSource";
import FertilizerStockMovement from "../models/fertiliz/FertilizerStockMovement";
import FertilizerProductPurchase from "../models/fertiliz/FertilizerProductPurchase";
import FertigationWaterMix from "../models/fertiliz/FertigationWaterMix";
import PhytosanitaryStockMovement from "../models/phyto/PhytosanitaryStockMovement";
import PhytosanitaryProductPurchase from "../models/phyto/PhytosanitaryProductPurchase";
import Facility from "../models/agrobusiness/Facility";
import Tool from "../models/agrobusiness/Tool";
import CropIrrigation from "../models/crop/CropIrrigation";
import PluviometryRegister from "../models/pluvio/PluviometryRegister";
import AgroBusiness from "../models/agrobusiness/AgroBusiness";
import AgroBusinessClassification from "../models/agrobusiness/AgroBusinessClassification";
import AgroBusinessWineInfo from "../models/agrobusiness/AgroBusinessWineInfo";
import Owner from "../models/agrobusiness/Owner";
import CoOwner from "../models/agrobusiness/CoOwner";
import OwnerRepresentative from "../models/agrobusiness/OwnerRepresentative";
import Manager from "../models/agrobusiness/Manager";
import Analysis from "../models/analysis/Analysis";
import AgroBusinessPhytosanitaryInvolvedEntity from "../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import CropPhytosanitaryTreatment from "../models/phyto/CropPhytosanitaryTreatement";
import PostHarvestPhytosanitaryTreatment from "../models/phyto/PostHarvestPhytosanitaryTreatment";
import FacilityPhytosanitaryTreatment from "../models/phyto/FacilityPhytosanitaryTreatment";
import GipPreventiveAction from "../models/phyto/GipPreventiveAction";
import TreatedSeed from "../models/phyto/TreatedSeed";
import PersonWithRole from "../models/account/PersonWithRole";
import FertilizationPlan from "../models/fertiliz/FertilizationPlan";
import Person from "../models/Person";
import PersonMessage from "../models/messages/PersonMessage";
import LocalAuthSendCodeRequest from "../models/auth/LocalAuthSendCodeRequest";
import Notification from "../models/notification/Notification";

import {
  ChangePhytoRecipeStatusInterface,
  ErrorCause,
  SendLinkCodeInterface,
  SnackbarInterface,
  TokensInterface,
} from "../constants/interfaces";
import {
  ENTITY_NOT_FOUND_ERROR,
  ENTITY_NOT_FOUND_EXCEPTION_ERROR,
  EXPIRED_TOKEN_ERROR,
  EXPIRED_TOKEN_REFETCH_TIMEOUT,
  ILEGAL_DATA_OPERATION_ERROR,
  INVALID_TOKEN_ERROR,
  UPLOAD_FILE_ERROR,
} from "../constants/constants";
import { getResponseData } from "../helpers/utils";
import PhytoRecipe from "../models/phytoRecipe/PhytoRecipe";
import { refreshTokens } from "../routes/AuthLayout";
import FertilizerTank from "../models/fertiliz/FertilizerTank";
import CueExportData from "../models/cueExport/CueExportData";

const FILE_ERROR = new Error(i18n.t("apiResponses.uploadFileError"), {
  cause: UPLOAD_FILE_ERROR,
});

type T = any;
interface Props<T> {
  key: string;
  values?: T;
  onSuccess?: (data: T) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
function useCrud<T>(props: Props<T>) {
  const { values, onSuccess, onError } = props;

  const { idToken, setIdToken, logout } = useAuth();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryQlient = useQueryClient();

  const handleSuccess = (data: T) => {
    onSuccess && onSuccess(data);
  };

  const handleError = async (error: Error) => {
    const errorCause = error?.cause as ErrorCause;
    const errorClassName = errorCause?.data?.exceptionClassName;
    // Refresh token if invalid token
    if (
      errorCause?.status === 401 &&
      (errorClassName === INVALID_TOKEN_ERROR ||
        errorClassName === EXPIRED_TOKEN_ERROR)
    ) {
      try {
        const cookies = new Cookies();
        const refreshToken = cookies.get("refreshToken");
        let idToken: string | undefined = undefined;
        if (refreshToken) {
          const tokens = await refreshTokens(refreshToken);
          idToken = tokens?.accessToken;
          const newRefreshToken = tokens?.refreshToken;
          cookies.set("refreshToken", newRefreshToken, { path: "/" });
        } else {
          const auth = getAuth();
          idToken = await auth.currentUser?.getIdToken();
        }

        // Retry after 5s
        if (idToken) {
          setIdToken(idToken);
          setTimeout(() => {
            mutation.mutate(values as any);
          }, EXPIRED_TOKEN_REFETCH_TIMEOUT);
        } else {
          logout();
          Sentry.captureException("Token expires but should not expire never.");
        }
      } catch (error) {
        logout();
        Sentry.captureException(error);
      }
    } else if (errorClassName === ILEGAL_DATA_OPERATION_ERROR) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("apiResponses.ilegalDataOperationError"),
        });
    } else if (
      errorClassName === ENTITY_NOT_FOUND_ERROR ||
      errorClassName === ENTITY_NOT_FOUND_EXCEPTION_ERROR
    ) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("apiResponses.userNotRegisteredByWhatsappError"),
        });
    } else {
      onError &&
        onError({
          severity: "error",
          message: error?.toString(),
          hasDocError: errorCause?.status === UPLOAD_FILE_ERROR,
        });
      // Send unexpected error to Sentry
      Sentry.withScope((scope) => {
        scope.setExtra("status", errorCause?.status);
        scope.setExtra("data", errorCause?.data);
        Sentry.captureException(error);
      });
    }
  };

  // If invalid token (error 401), not retry, refresh token and relogin
  const handleRetry = (failureCount: number, error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const responseStatus = errorCause?.status;
    // If server responds, not retry
    if (responseStatus) return false; // Generates the error, not retry again
    return true;
  };

  const crudProps: CrudProps = {
    queryClient: queryQlient,
    idToken,
    agroBusinessId: selectedABAccount?.agroBusiness?.id,
    selectedABAccount: selectedABAccount,
    selectedABPartition: selectedABPartition,
    values,
  };

  const mutation = useMutation({
    mutationFn: (extraData?: Object) => cruder(props.key, crudProps, extraData),
    retry: handleRetry,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return mutation;
}

export default useCrud;

const cruder = (
  key: string,
  props: CrudProps,
  extraData?: Object
): Promise<T> => {
  switch (key) {
    case "sendAuthCodeByEmail":
      return sendAuthCodeByEmail({
        ...props,
        values: extraData || props.values,
      });
    case "sendAuthCodeByWhatsapp":
      return sendAuthCodeByWhatsapp({
        ...props,
        values: extraData || props.values,
      });
    case "postAuthWithEmailCode":
      return postAuthWithEmailCode({
        ...props,
        values: extraData || props.values,
      });
    case "postAuthWithWhatsappCode":
      return postAuthWithWhatsappCode({
        ...props,
        values: extraData || props.values,
      });
    case "sendLinkCode":
      return sendLinkCode({
        ...props,
        values: extraData || props.values,
      });
    case "attachEmailPhone":
      return attachEmailPhone({
        ...props,
        values: extraData || props.values,
      });
    case "postPersonWithAgroBusiness":
      return postPersonWithAgroBusiness(props);
    case "moveToAdvisorCueAccount":
      return moveToAdvisorCueAccount({ ...props, values: extraData });
    case "putAgroBusiness":
      return putAgroBusiness(props);
    case "putAgroBusinessClassification":
      return putAgroBusinessClassification({ ...props, values: extraData });
    case "deleteAgroBusinessClassifications":
      return deleteAgroBusinessClassifications(props);
    case "postAgroBusinessWineInfo":
      return postAgroBusinessWineInfo({ ...props, values: extraData });
    case "putAgroBusinessWineInfo":
      return putAgroBusinessWineInfo({ ...props, values: extraData });
    case "deleteAgroBusinessWinesInfo":
      return deleteAgroBusinessWinesInfo(props);
    case "putOwner":
      return putOwner(props);
    case "deleteOwner":
      return deleteOwner({ ...props, values: extraData });
    case "postCoOwner":
      return postCoOwner(props);
    case "putCoOwner":
      return putCoOwner(props);
    case "deleteCoOwner":
      return deleteCoOwner({ ...props, values: extraData });
    case "postOwnerRepresentative":
      return postOwnerRepresentative(props);
    case "putOwnerRepresentative":
      return putOwnerRepresentative(props);
    case "deleteOwnerRepresentative":
      return deleteOwnerRepresentative({ ...props, values: extraData });
    case "postManager":
      return postManager(props);
    case "putManager":
      return putManager(props);
    case "deleteManager":
      return deleteManager({ ...props, values: extraData });
    case "postPhytosanitaryInvolvedEntity":
      return postPhytosanitaryInvolvedEntity(props);
    case "putPhytosanitaryInvolvedEntity":
      return putPhytosanitaryInvolvedEntity(props);
    case "deletePhytosanitaryInvolvedEntities":
      return deletePhytosanitaryInvolvedEntities(props);
    case "setAgroBusinessAccountPermission":
      return setAgroBusinessAccountPermission(props);
    case "deleteAgroBusinessAccountRolePermissions":
      return deleteAgroBusinessAccountRolePermissions(props);
    case "postFertilization":
      return postFertilization(props);
    case "putFertilization":
      return putFertilization(props);
    case "deleteFertilizations":
      return deleteFertilizations(props);
    case "postFertilizerProduct":
      return postFertilizerProduct(props);
    case "putFertilizerProduct":
      return putFertilizerProduct(props);
    case "deleteFertilizerProducts":
      return deleteFertilizerProducts(props);
    case "postFertilizerStockMovement":
      return postFertilizerStockMovement(props);
    case "putFertilizerStockMovement":
      return putFertilizerStockMovement(props);
    case "deleteFertilizerStockMovements":
      return deleteFertilizerStockMovements(props);
    case "postFertilizationPlan":
      return postFertilizationPlan(props);
    case "putFertilizationPlan":
      return putFertilizationPlan(props);
    case "deleteFertilizationPlans":
      return deleteFertilizationPlans(props);
    case "postFertilizerTank":
      return postFertilizerTank(props);
    case "putFertilizerTank":
      return putFertilizerTank(props);
    case "deleteFertilizerTanks":
      return deleteFertilizerTanks(props);
    case "postPhytosanitaryStockMovement":
      return postPhytosanitaryStockMovement(props);
    case "putPhytosanitaryStockMovement":
      return putPhytosanitaryStockMovement(props);
    case "deletePhytosanitaryStockMovements":
      return deletePhytosanitaryStockMovements(props);
    case "postCrop":
      return postCrop(props);
    case "putCrop":
      return putCrop(props);
    case "deleteCrops":
      return deleteCrops(props);
    case "postCropIrrigation":
      return postCropIrrigation(props);
    case "putCropIrrigation":
      return putCropIrrigation(props);
    case "deleteCropIrrigations":
      return deleteCropIrrigations(props);
    case "postPluviometryRegister":
      return postPluviometryRegister(props);
    case "putPluviometryRegister":
      return putPluviometryRegister(props);
    case "deletePluviometryRegisters":
      return deletePluviometryRegisters(props);
    case "postPhytosanitaryProduct":
      return postPhytosanitaryProduct(props);
    case "putPhytosanitaryProduct":
      return putPhytosanitaryProduct(props);
    case "deletePhytosanitaryProducts":
      return deletePhytosanitaryProducts(props);
    case "registeredFertilizerProducts":
      return fetchRegisteredFertilizerProducts({ ...props, ...extraData });
    case "registeredPhytosanitaryProducts":
      return fetchRegisteredPhytosaniaryProducts({ ...props, ...extraData });
    case "postDocument":
      return postDocument(props);
    case "putDocument":
      return putDocument(props);
    case "deleteDocuments":
      return deleteDocuments(props);
    case "postAgroBusinessPartition":
      return postAgroBusinessPartition(props);
    case "putAgroBusinessPartition":
      return putAgroBusinessPartition(props);
    case "deleteAgroBusinessPartitions":
      return deleteAgroBusinessPartitions(props);
    case "postSector":
      return postSector(props);
    case "putSector":
      return putSector(props);
    case "deleteSectors":
      return deleteSectors(props);
    case "postAgroBusinessAccount":
      return postAgroBusinessAccount(props);
    case "putAgroBusinessAccount":
      return putAgroBusinessAccount(props);
    case "deleteAgroBusinessAccounts":
      return deleteAgroBusinessAccounts(props);
    case "postFacility":
      return postFacility(props);
    case "putFacility":
      return putFacility(props);
    case "deleteFacilities":
      return deleteFacilities(props);
    case "postTool":
      return postTool(props);
    case "putTool":
      return putTool(props);
    case "deleteTools":
      return deleteTools(props);
    case "postWaterSource":
      return postWaterSource(props);
    case "putWaterSource":
      return putWaterSource(props);
    case "deleteWaterSources":
      return deleteWaterSources(props);
    case "postFertilizerProductPurchase":
      return postFertilizerProductPurchase(props);
    case "putFertilizerProductPurchase":
      return putFertilizerProductPurchase(props);
    case "deleteFertilizerProductPurchases":
      return deleteFertilizerProductPurchases(props);
    case "postPhytosanitaryProductPurchase":
      return postPhytosanitaryProductPurchase(props);
    case "putPhytosanitaryProductPurchase":
      return putPhytosanitaryProductPurchase(props);
    case "deletePhytosanitaryProductPurchases":
      return deletePhytosanitaryProductPurchases(props);
    case "postFertigationWaterMix":
      return postFertigationWaterMix(props);
    case "putFertigationWaterMix":
      return putFertigationWaterMix(props);
    case "deleteFertigationWaterMixes":
      return deleteFertigationWaterMixes(props);
    case "postAnalysis":
      return postAnalysis(props);
    case "putAnalysis":
      return putAnalysis(props);
    case "deleteAnalysis":
      return deleteAnalysis(props);
    case "postCropPhytosanitaryTreatment":
      return postCropPhytosanitaryTreatment(props);
    case "putCropPhytosanitaryTreatment":
      return putCropPhytosanitaryTreatment(props);
    case "deleteCropPhytosanitaryTreatments":
      return deleteCropPhytosanitaryTreatments(props);
    case "postPostHarvestPhytosanitaryTreatment":
      return postPostHarvestPhytosanitaryTreatment(props);
    case "putPostHarvestPhytosanitaryTreatment":
      return putPostHarvestPhytosanitaryTreatment(props);
    case "deletePostHarvestPhytosanitaryTreatments":
      return deletePostHarvestPhytosanitaryTreatments(props);
    case "postFacilityPhytosanitaryTreatment":
      return postFacilityPhytosanitaryTreatment(props);
    case "putFacilityPhytosanitaryTreatment":
      return putFacilityPhytosanitaryTreatment(props);
    case "deleteFacilityPhytosanitaryTreatments":
      return deleteFacilityPhytosanitaryTreatments(props);
    case "postGipPreventiveAction":
      return postGipPreventiveAction(props);
    case "putGipPreventiveAction":
      return putGipPreventiveAction(props);
    case "deleteGipPreventiveActions":
      return deleteGipPreventiveActions(props);
    case "postTreatedSeed":
      return postTreatedSeed(props);
    case "putTreatedSeed":
      return putTreatedSeed(props);
    case "deleteTreatedSeeds":
      return deleteTreatedSeeds(props);
    case "putPersonLanguage":
      return putPersonLanguage({ ...props, values: extraData });
    case "deleteOffers":
      return deleteOffers(props);
    case "setOfferAsRead":
      return setOfferAsRead({ ...props, values: extraData });
    case "changePhytoRecipeStatus":
      return changePhytoRecipeStatus({ ...props, values: extraData });
    case "postPhytoRecipe":
      return postPhytoRecipe(props);
    case "putPhytoRecipe":
      return putPhytoRecipe(props);
    case "deletePhytoRecipe":
      return deletePhytoRecipe(props);
    case "markNotificationAsRead":
      return markNotificationAsRead({ ...props, values: extraData });
    case "exportCUEData":
      return exportCUEData(props);
    case "siexSyncRequest":
      return siexSyncRequest(props);
    default:
      return Promise.reject("Function not found");
  }
};

interface CrudProps {
  queryClient: QueryClient;
  idToken?: string;
  agroBusinessId?: number;
  selectedABAccount?: AgroBusinessAccount | null;
  selectedABPartition?: AgroBusinessPartition | null;
  values?: T;
}
interface CrudResponseProps {
  response: Response;
  crudProps: CrudProps;
}

/**
 * AUTH CRUD endpoints
 */
const sendAuthCodeByEmail = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  return fetch(`${process.env.REACT_APP_API_URL}/auth/sendAuthCodeByEmail`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(localAuthSendCodeReq),
  }).then(onSendAuthCodeByEmailResponse);
};

const onSendAuthCodeByEmailResponse = async (
  response: Response
): Promise<void> => {
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.sendAuthCodeByEmailResponseError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};

const sendAuthCodeByWhatsapp = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  const phone = localAuthSendCodeReq.phone?.replace(/[^0-9]/g, "") || "";
  localAuthSendCodeReq.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/auth/sendAuthCodeByWhatsapp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(localAuthSendCodeReq),
  }).then(onSendAuthCodeByWhatsappResponse);
};

const onSendAuthCodeByWhatsappResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.sendAuthCodeByWhatsappResponseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postAuthWithEmailCode = async (
  props: CrudProps
): Promise<TokensInterface | undefined> => {
  const { idToken, values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  localAuthSendCodeReq.phone = "";
  return fetch(`${process.env.REACT_APP_API_URL}/auth/get`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(localAuthSendCodeReq.mapToApiModel()),
  }).then(onPostAuthWithEmailCodeResponse);
};

const onPostAuthWithEmailCodeResponse = async (
  response: Response
): Promise<TokensInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as TokensInterface;
  } else {
    throw new Error(i18n.t("apiResponses.postAuthWithEmailCodeResponseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postAuthWithWhatsappCode = async (
  props: CrudProps
): Promise<TokensInterface | undefined> => {
  const { idToken, values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  localAuthSendCodeReq.email = "";
  const phone = localAuthSendCodeReq.phone?.replace(/[^0-9]/g, "") || "";
  localAuthSendCodeReq.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/auth/get`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(localAuthSendCodeReq.mapToApiModel()),
  }).then(onPostAuthWithWhatsappCodeResponse);
};

const onPostAuthWithWhatsappCodeResponse = async (
  response: Response
): Promise<TokensInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as TokensInterface;
  } else {
    throw new Error(
      i18n.t("apiResponses.postAuthWithWhatsappCodeResponseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const sendLinkCode = async (
  props: CrudProps
): Promise<SendLinkCodeInterface | undefined> => {
  const { idToken, values } = props;
  const sendLinkCodeValues = values as SendLinkCodeInterface;
  sendLinkCodeValues.language = i18n.language; // Set language
  const phone = sendLinkCodeValues.phone?.replace(/[^0-9]/g, "") || "";
  sendLinkCodeValues.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/person/sendLinkCode`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sendLinkCodeValues),
  }).then(onSendLinkCodeResponse);
};

const onSendLinkCodeResponse = async (
  response: Response
): Promise<SendLinkCodeInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as SendLinkCodeInterface;
  } else {
    throw new Error(i18n.t("apiResponses.sendLinkCodeResponseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const attachEmailPhone = async (props: CrudProps): Promise<void> => {
  const { idToken, values } = props;
  return fetch(`${process.env.REACT_APP_API_URL}/person/attachEmailPhone`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${idToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: values?.token || "",
      code: values?.code || "",
    }),
  }).then(onAttachEmailPhoneResponse);
};

const onAttachEmailPhoneResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    if (data.message?.includes("user with this whatsapp"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeOtherUserPhoneError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );
    if (data.message?.includes("user with this email"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeOtherUserEmailError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );
    if (data.message?.includes("Invalid attach code"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeInvalidCodeError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );

    throw new Error(i18n.t("apiResponses.attachEmailPhoneCodeError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};

/**
 * AGROBUSINESS CRUD endpoints
 */
const postPersonWithAgroBusiness = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { idToken, values } = props;
  const agroBusinessAccount = values as AgroBusinessAccount;
  const agroBusinessName = agroBusinessAccount.name;
  const siexEnabled = agroBusinessAccount.siexEnabled || false;
  const registerRegionId = agroBusinessAccount.registerRegion?.id || "";
  const browserLanguage = i18n.language;
  return fetch(
    `${process.env.REACT_APP_API_URL}/person/createWithAgroBusinessFromWeb?agroBusinessName=${agroBusinessName}&siexEnabled=${siexEnabled}&registerRegionId=${registerRegionId}&language=${browserLanguage}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    }
  ).then(onPostPersonWithAgroBusinessResponse);
};

const onPostPersonWithAgroBusinessResponse = async (
  response: Response
): Promise<AgroBusinessAccount | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new AgroBusinessAccount().mapToClass(data);
  } else {
    if (data?.exceptionClassName === ILEGAL_DATA_OPERATION_ERROR)
      throw new Error(
        i18n.t("apiResponses.agroBusinessAccountAlreadyActiveError"),
        {
          cause: { status: response.status } as ErrorCause,
        }
      );
    throw new Error(i18n.t("apiResponses.postPersonWithAgroBusinessError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const moveToAdvisorCueAccount = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { idToken, values, selectedABAccount } = props;
  const agroBusinessAccountId = selectedABAccount?.id || "";
  const cueAccountId = values || "";
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/moveToAdvisorCueAccount/${cueAccountId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    }
  ).then(onMoveToAdvisorCueAccountResponse);
};

const onMoveToAdvisorCueAccountResponse = async (
  response: Response
): Promise<AgroBusinessAccount | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new AgroBusinessAccount().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.moveToAdvisorCueAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusiness = async (
  props: CrudProps
): Promise<AgroBusiness | undefined> => {
  const { idToken, values } = props;
  const agroBusiness = values as AgroBusiness;
  const agroBusinessId = agroBusiness.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusiness.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessResponse = async (
  props: CrudResponseProps
): Promise<AgroBusiness | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedAgroBusiness = new AgroBusiness().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agrobusinessById"],
      type: "active",
    });
    return updatedAgroBusiness;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

interface AgroBusinessClassificationProps {
  values?: Object;
}
const putAgroBusinessClassification = async (
  props: CrudProps & AgroBusinessClassificationProps
): Promise<AgroBusinessClassification | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const agroBusinessClassification = values as AgroBusinessClassification;
  const classTypeId = agroBusinessClassification?.classificationType?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/classification/${classTypeId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusinessClassification.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessClassificationResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessClassificationResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessClassification | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedAgroBusinessClassification =
      new AgroBusinessClassification().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessClassifications"],
      type: "active",
    });
    return updatedAgroBusinessClassification;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessClassificationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAgroBusinessClassifications = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedClassifications = values as AgroBusinessClassification[];
  return Promise.all(
    selectedClassifications.map(
      (abClassification: AgroBusinessClassification) => {
        const classTypeId = abClassification?.classificationType?.id;
        return fetch(
          `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/classification/${classTypeId}`,
          {
            method: "DELETE",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
      }
    )
  ).then((response: Response[]) =>
    onDeleteAgroBusinessClassificationsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAgroBusinessClassificationsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessClassifications"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deleteAgroBusinessClassificationsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

interface AgroBusinessWineInfoProps {
  values?: Object;
}
const postAgroBusinessWineInfo = async (
  props: CrudProps & AgroBusinessWineInfoProps
): Promise<AgroBusinessWineInfo | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const agroBusinessWineInfo = values as AgroBusinessWineInfo;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/wineInfo`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusinessWineInfo.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPostAgroBusinessWineInfoResponse({ response, crudProps: props })
  );
};

const onPostAgroBusinessWineInfoResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessWineInfo | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newAgroWineInfo = new AgroBusinessWineInfo().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessWinesInfo"],
      type: "active",
    });
    return newAgroWineInfo;
  } else {
    throw new Error(i18n.t("apiResponses.postAgroBusinessWineInfoError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusinessWineInfo = async (
  props: CrudProps & AgroBusinessWineInfoProps
): Promise<AgroBusinessWineInfo | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const agroBusinessWineInfo = values as AgroBusinessWineInfo;
  const wineInfoId = agroBusinessWineInfo?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/wineInfo/${wineInfoId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusinessWineInfo.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessWineInfoResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessWineInfoResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessWineInfo | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedAgroWineInfo = new AgroBusinessWineInfo().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessWinesInfo"],
      type: "active",
    });
    return updatedAgroWineInfo;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessWineInfoError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAgroBusinessWinesInfo = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedWinesInfo = values as AgroBusinessWineInfo[];
  return Promise.all(
    selectedWinesInfo.map((abWineInfo: AgroBusinessWineInfo) => {
      const wineInfoId = abWineInfo?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/wineInfo/${wineInfoId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessWinesInfoResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAgroBusinessWinesInfoResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessWinesInfo"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteAgroBusinessWinesInfoError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};

const putOwnerObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Owner | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutOwnerObjResponse({ response, crudProps: props })
  );
};

const onPutOwnerObjResponse = async (
  props: CrudResponseProps
): Promise<Owner | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedOwner = new Owner().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return updatedOwner;
  } else {
    throw new Error(i18n.t("apiResponses.putOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putOwner = async (props: CrudProps): Promise<Owner | undefined> => {
  const { values } = props;
  const ownerData = values as Owner;

  const owner = await putOwnerObj(props, ownerData.mapToApiModel());

  if (owner && owner.id && ownerData.documents)
    await attachDocuments({
      crudProps: props,
      documents: ownerData.documents,
      entityClassName: "FBOwner",
      entityClassId: owner.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = ownerData.lastSavedDocuments?.filter(
    (document: Document) =>
      !ownerData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});
  return owner;
};

const deleteOwner = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const ownerId = values?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner/${ownerId}/disable`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then((response: Response) =>
    onDeleteOwnerResponse({ response, crudProps: props })
  );
};

const onDeleteOwnerResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteCoOwner = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const coOwnerId = values?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/coOwner/${coOwnerId}/disable`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then((response: Response) =>
    onDeleteCoOwnerResponse({ response, crudProps: props })
  );
};

const onDeleteCoOwnerResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteCoOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteOwnerRepresentative = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const ownerRepresentativeId = values?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner/representative/${ownerRepresentativeId}/disable`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then((response: Response) =>
    onDeleteOwnerRepresentativeResponse({ response, crudProps: props })
  );
};

const onDeleteOwnerRepresentativeResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteOwnerRepresentativeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteManager = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const managerId = values?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/manager/${managerId}/disable`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then((response: Response) =>
    onDeleteManagerResponse({ response, crudProps: props })
  );
};

const onDeleteManagerResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteManagerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postCoOwnerObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CoOwner | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/coOwner`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostCoOwnerObjResponse({ response, crudProps: props })
  );
};

const onPostCoOwnerObjResponse = async (
  props: CrudResponseProps
): Promise<Manager | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newCoOwner = new CoOwner().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return newCoOwner;
  } else {
    throw new Error(i18n.t("apiResponses.postCoOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postCoOwner = async (props: CrudProps): Promise<CoOwner | undefined> => {
  const { values } = props;
  const coOwnerData = values as CoOwner;

  const coOwner = await postCoOwnerObj(props, coOwnerData.mapToApiModel());
  if (coOwner && coOwner.id && coOwnerData.documents)
    await attachDocuments({
      crudProps: props,
      documents: coOwnerData.documents,
      entityClassName: "FBCoOwner",
      entityClassId: coOwner.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return coOwner;
};

const putCoOwnerObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CoOwner | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const coOwner = values as CoOwner;
  const coOwnerId = coOwner?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/coOwner/${coOwnerId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutCoOwnerObjResponse({ response, crudProps: props })
  );
};

const onPutCoOwnerObjResponse = async (
  props: CrudResponseProps
): Promise<CoOwner | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedCoOwner = new CoOwner().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return updatedCoOwner;
  } else {
    throw new Error(i18n.t("apiResponses.putCoOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putCoOwner = async (props: CrudProps): Promise<CoOwner | undefined> => {
  const { values } = props;
  const coOwnerData = values as CoOwner;

  const coOwner = await putCoOwnerObj(props, coOwnerData.mapToApiModel());

  if (coOwner && coOwner.id && coOwnerData.documents)
    await attachDocuments({
      crudProps: props,
      documents: coOwnerData.documents,
      entityClassName: "FBCoOwner",
      entityClassId: coOwner.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = coOwnerData.lastSavedDocuments?.filter(
    (document: Document) =>
      !coOwnerData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});
  return coOwner;
};

const postOwnerRepresentativeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<OwnerRepresentative | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner/representative`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostOwnerRepresentativeObjResponse({ response, crudProps: props })
  );
};

const onPostOwnerRepresentativeObjResponse = async (
  props: CrudResponseProps
): Promise<OwnerRepresentative | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newOwnerRepresentative = new OwnerRepresentative().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return newOwnerRepresentative;
  } else {
    throw new Error(i18n.t("apiResponses.postOwnerRepresentativeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postOwnerRepresentative = async (
  props: CrudProps
): Promise<OwnerRepresentative | undefined> => {
  const { values } = props;
  const ownerRepresentativeData = values as OwnerRepresentative;

  const ownerRepresentative = await postOwnerRepresentativeObj(
    props,
    ownerRepresentativeData.mapToApiModel()
  );
  if (
    ownerRepresentative &&
    ownerRepresentative.id &&
    ownerRepresentativeData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: ownerRepresentativeData.documents,
      entityClassName: "FBOwnerRepresentative",
      entityClassId: ownerRepresentative.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return ownerRepresentative;
};

const putOwnerRepresentativeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<OwnerRepresentative | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner/representative`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutOwnerRepresentativeObjResponse({ response, crudProps: props })
  );
};

const onPutOwnerRepresentativeObjResponse = async (
  props: CrudResponseProps
): Promise<OwnerRepresentative | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedOwnerRepresentative = new OwnerRepresentative().mapToClass(
      data
    );
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return updatedOwnerRepresentative;
  } else {
    throw new Error(i18n.t("apiResponses.putOwnerRepresentativeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putOwnerRepresentative = async (
  props: CrudProps
): Promise<OwnerRepresentative | undefined> => {
  const { values } = props;
  const ownerRepresentativeData = values as OwnerRepresentative;

  const ownerRepresentative = await putOwnerRepresentativeObj(
    props,
    ownerRepresentativeData.mapToApiModel()
  );

  if (
    ownerRepresentative &&
    ownerRepresentative.id &&
    ownerRepresentativeData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: ownerRepresentativeData.documents,
      entityClassName: "FBOwnerRepresentative",
      entityClassId: ownerRepresentative.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    ownerRepresentativeData.lastSavedDocuments?.filter(
      (document: Document) =>
        !ownerRepresentativeData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});
  return ownerRepresentative;
};

const postManagerObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Manager | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/manager`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostManagerObjResponse({ response, crudProps: props })
  );
};

const onPostManagerObjResponse = async (
  props: CrudResponseProps
): Promise<Manager | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newManager = new Manager().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return newManager;
  } else {
    throw new Error(i18n.t("apiResponses.postManagerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postManager = async (props: CrudProps): Promise<Manager | undefined> => {
  const { values } = props;
  const managerData = values as Manager;

  const manager = await postManagerObj(props, managerData.mapToApiModel());
  if (manager && manager.id && managerData.documents)
    await attachDocuments({
      crudProps: props,
      documents: managerData.documents,
      entityClassName: "FBManager",
      entityClassId: manager.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return manager;
};

const putManagerObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Manager | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/manager`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutManagerObjResponse({ response, crudProps: props })
  );
};

const onPutManagerObjResponse = async (
  props: CrudResponseProps
): Promise<Manager | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const udpatedManager = new Manager().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["leaderList"],
      type: "active",
    });
    return udpatedManager;
  } else {
    throw new Error(i18n.t("apiResponses.putManagerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putManager = async (props: CrudProps): Promise<Manager | undefined> => {
  const { values } = props;
  const managerData = values as Manager;

  const manager = await putManagerObj(props, managerData.mapToApiModel());

  if (manager && manager.id && managerData.documents)
    await attachDocuments({
      crudProps: props,
      documents: managerData.documents,
      entityClassName: "FBManager",
      entityClassId: manager.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = managerData.lastSavedDocuments?.filter(
    (document: Document) =>
      !managerData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});
  return manager;
};

const postPhytosanitaryInvolvedEntityObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostPhytosanitaryInvolvedEntityObjResponse({ response, crudProps: props })
  );
};

const onPostPhytosanitaryInvolvedEntityObjResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newPhytoInvolvedEntity =
      new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytoInvolvedEntities"],
      type: "active",
    });
    return newPhytoInvolvedEntity;
  } else {
    throw new Error(
      i18n.t("apiResponses.postPhytosanitaryInvolvedEntityError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPhytosanitaryInvolvedEntity = async (
  props: CrudProps
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { values } = props;
  const phytoInvolvedEntityData =
    values as AgroBusinessPhytosanitaryInvolvedEntity;

  const phytoInvolvedEntity = await postPhytosanitaryInvolvedEntityObj(
    props,
    phytoInvolvedEntityData.mapToApiModel()
  );

  if (
    phytoInvolvedEntity &&
    phytoInvolvedEntity.id &&
    phytoInvolvedEntityData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: phytoInvolvedEntityData.documents,
      entityClassName: "FBAgroBusinessPhytosanitaryInvolvedEntity",
      entityClassId: phytoInvolvedEntity.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return phytoInvolvedEntity;
};

const putPhytosanitaryInvolvedEntityObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const phytoInvolvedEntity = values as AgroBusinessPhytosanitaryInvolvedEntity;
  const phytoInvolvedEntityId = phytoInvolvedEntity?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/${phytoInvolvedEntityId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutPhytosanitaryInvolvedEntityObjResponse({ response, crudProps: props })
  );
};

const onPutPhytosanitaryInvolvedEntityObjResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedPhytoInvolvedEntity =
      new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytoInvolvedEntities"],
      type: "active",
    });
    return updatedPhytoInvolvedEntity;
  } else {
    throw new Error(
      i18n.t("apiResponses.putPhytosanitaryInvolvedEntityError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const putPhytosanitaryInvolvedEntity = async (
  props: CrudProps
): Promise<AgroBusinessPhytosanitaryInvolvedEntity | undefined> => {
  const { values } = props;
  const phytoInvolvedEntityData =
    values as AgroBusinessPhytosanitaryInvolvedEntity;

  const phytoInvolvedEntity = await putPhytosanitaryInvolvedEntityObj(
    props,
    phytoInvolvedEntityData.mapToApiModel()
  );

  if (
    phytoInvolvedEntity &&
    phytoInvolvedEntity.id &&
    phytoInvolvedEntityData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: phytoInvolvedEntityData.documents,
      entityClassName: "FBAgroBusinessPhytosanitaryInvolvedEntity",
      entityClassId: phytoInvolvedEntity.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    phytoInvolvedEntityData.lastSavedDocuments?.filter(
      (document: Document) =>
        !phytoInvolvedEntityData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return phytoInvolvedEntity;
};

const deletePhytosanitaryInvolvedEntities = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedPhytoInvolvedEntities =
    values as AgroBusinessPhytosanitaryInvolvedEntity[];
  return Promise.all(
    selectedPhytoInvolvedEntities.map(
      (phytoInvolvedEntity: AgroBusinessPhytosanitaryInvolvedEntity) => {
        const phytoInvolvedEntityId = phytoInvolvedEntity?.id;
        return fetch(
          `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/${phytoInvolvedEntityId}/disable`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
      }
    )
  ).then((response: Response[]) =>
    onDeletePhytosanitaryInvolvedEntitiesResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePhytosanitaryInvolvedEntitiesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytoInvolvedEntities"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deletePhytosanitaryInvolvedEntitiesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const setAgroBusinessAccountPermission = async (
  props: CrudProps
): Promise<void> => {
  const { idToken, selectedABAccount, values } = props;
  const personWithRole = values as PersonWithRole;
  const agroBusinessAccountId = selectedABAccount?.id;
  const email = personWithRole?.person?.email;
  const role = personWithRole?.personRole?.role;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/role?email=${email}&role=${role}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    }
  ).then((response: Response) =>
    onSetAgroBusinessAccountPermissionResponse({ response, crudProps: props })
  );
};

const onSetAgroBusinessAccountPermissionResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccountPermissions"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.setAgroBusinessAccountPermissionError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const deleteAgroBusinessAccountRolePermissions = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, selectedABAccount } = props;
  const abaId = selectedABAccount?.id;
  const personWithRoles = values as PersonWithRole[];
  return Promise.all(
    personWithRoles.map((personWithRole: PersonWithRole) => {
      const email = personWithRole?.person?.email;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${abaId}/role?email=${email}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessAccountRolePermissionsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAgroBusinessAccountRolePermissionsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccountPermissions"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deleteAgroBusinessAccountRolePermissionsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

/**
 * Fertilization CRUD endpoints
 */
const postFertilizationObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Fertilization | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertilization = values as Fertilization;
  const cropId = fertilization?.crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/fertilization`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFertilizationObjResponse({ response, crudProps: props })
  );
};

const onPostFertilizationObjResponse = async (
  props: CrudResponseProps
): Promise<Fertilization | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newFertilization = new Fertilization().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizations"],
      type: "active",
    });
    return newFertilization;
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilization = async (
  props: CrudProps
): Promise<Fertilization | undefined> => {
  const { values } = props;
  const fertilizationData = values as Fertilization;

  const fertilization = await postFertilizationObj(
    props,
    fertilizationData.mapToApiModel()
  );

  if (fertilization && fertilization.id && fertilizationData.documents)
    await attachDocuments({
      crudProps: props,
      documents: fertilizationData.documents,
      entityClassName: "FBFertilization",
      entityClassId: fertilization.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return fertilization;
};

const putFertilizationObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Fertilization | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertilization = values as Fertilization;
  const cropId = fertilization?.crop?.id;
  const fertilizationId = fertilization?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/fertilization/${fertilizationId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFertilizationObjResponse({ response, crudProps: props })
  );
};

const onPutFertilizationObjResponse = async (
  props: CrudResponseProps
): Promise<Fertilization | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedFertilization = new Fertilization().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizations"],
      type: "active",
    });
    return updatedFertilization;
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilization = async (
  props: CrudProps
): Promise<Fertilization | undefined> => {
  const { values } = props;
  const fertilizationData = values as Fertilization;

  const fertilization = await putFertilizationObj(
    props,
    fertilizationData.mapToApiModel()
  );

  if (fertilization && fertilization.id && fertilizationData.documents)
    await attachDocuments({
      crudProps: props,
      documents: fertilizationData.documents,
      entityClassName: "FBFertilization",
      entityClassId: fertilization.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = fertilizationData.lastSavedDocuments?.filter(
    (document: Document) =>
      !fertilizationData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return fertilization;
};

const deleteFertilizations = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedFertilizations = values as Fertilization[];
  return Promise.all(
    selectedFertilizations.map((fertilization: Fertilization) => {
      const fertilizationId = fertilization?.id;
      const cropId = fertilization?.crop?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/fertilization/${fertilizationId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizationsResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteFertilizationsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizations"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteFertilizationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerProductObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerProduct | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFertilizerProductObjResponse({ response, crudProps: props })
  );
};

const onPostFertilizerProductObjResponse = async (props: CrudResponseProps) => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newFertilizerProduct = new FertilizerProduct().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerProducts"],
      type: "active",
    });
    return newFertilizerProduct;
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizerProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerProduct = async (
  props: CrudProps
): Promise<FertilizerProduct | undefined> => {
  const { values } = props;
  const fertilizerProductData = values as FertilizerProduct;

  const fertilizerProduct = await postFertilizerProductObj(
    props,
    fertilizerProductData.mapToApiModel()
  );

  if (
    fertilizerProduct &&
    fertilizerProduct.id &&
    fertilizerProductData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizerProductData.documents,
      entityClassName: "FBFertilizerProduct",
      entityClassId: fertilizerProduct.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return fertilizerProduct;
};

const putFertilizerProductObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerProduct | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertilizerProduct = values as FertilizerProduct;
  const productId = fertilizerProduct?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct/${productId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFertilizerProductObjResponse({ response, crudProps: props })
  );
};

const onPutFertilizerProductObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizerProduct | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedProduct = new FertilizerProduct().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerProducts"],
      type: "active",
    });
    return updatedProduct;
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizerProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilizerProduct = async (
  props: CrudProps
): Promise<FertilizerProduct | undefined> => {
  const { values } = props;
  const fertilizerProductData = values as FertilizerProduct;

  const fertilizerProduct = await putFertilizerProductObj(
    props,
    fertilizerProductData.mapToApiModel()
  );

  if (
    fertilizerProduct &&
    fertilizerProduct.id &&
    fertilizerProductData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizerProductData.documents,
      entityClassName: "FBFertilizerProduct",
      entityClassId: fertilizerProduct.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    fertilizerProductData.lastSavedDocuments?.filter(
      (document: Document) =>
        !fertilizerProductData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return fertilizerProduct;
};

const deleteFertilizerProducts = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedProducts = values as FertilizerProduct[];
  return Promise.all(
    selectedProducts.map((fertilizerProduct: FertilizerProduct) => {
      const productId = fertilizerProduct?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct/${productId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizerProductsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertilizerProductsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerProducts"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteFertilizerProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizationPlanObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizationPlan | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizationPlan`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFertilizationPlanObjResponse({ response, crudProps: props })
  );
};

const onPostFertilizationPlanObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizationPlan | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newFertilizationPlan = new FertilizationPlan().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizationPlans"],
      type: "active",
    });
    return newFertilizationPlan;
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizationPlanError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizationPlan = async (
  props: CrudProps
): Promise<FertilizationPlan | undefined> => {
  const { values } = props;
  const fertilizationPlanData = values as FertilizationPlan;

  const fertilizationPlan = await postFertilizationPlanObj(
    props,
    fertilizationPlanData.mapToApiModel()
  );

  if (
    fertilizationPlan &&
    fertilizationPlan.id &&
    fertilizationPlanData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizationPlanData.documents,
      entityClassName: "FBFertilizationPlan",
      entityClassId: fertilizationPlan.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return fertilizationPlan;
};

const putFertilizationPlanObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizationPlan | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertilizationPlan = values as FertilizationPlan;
  const fertilizationPlanId = fertilizationPlan?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizationPlan/${fertilizationPlanId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFertilizationPlanObjResponse({ response, crudProps: props })
  );
};

const onPutFertilizationPlanObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizationPlan | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedFertilizationPlan = new FertilizationPlan().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizationPlans"],
      type: "active",
    });
    return updatedFertilizationPlan;
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizationPlanError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilizationPlan = async (
  props: CrudProps
): Promise<FertilizationPlan | undefined> => {
  const { values } = props;
  const fertilizationPlanData = values as FertilizationPlan;

  const fertilizationPlan = await putFertilizationPlanObj(
    props,
    fertilizationPlanData.mapToApiModel()
  );

  if (
    fertilizationPlan &&
    fertilizationPlan.id &&
    fertilizationPlanData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizationPlanData.documents,
      entityClassName: "FBFertilizationPlan",
      entityClassId: fertilizationPlan.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    fertilizationPlanData.lastSavedDocuments?.filter(
      (document: Document) =>
        !fertilizationPlanData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return fertilizationPlan;
};

const deleteFertilizationPlans = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedFertilizationPlans = values as FertilizationPlan[];
  return Promise.all(
    selectedFertilizationPlans.map((fertilizationPlan: FertilizationPlan) => {
      const fertilizationPlanId = fertilizationPlan?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizationPlan/${fertilizationPlanId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizationPlansResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertilizationPlansResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizationPlans"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteFertilizationPlansError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerTankObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerTank | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerTank`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFertilizerTankObjResponse({ response, crudProps: props })
  );
};

const onPostFertilizerTankObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizerTank | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newFertilizerTank = new FertilizerTank().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerTanks"],
      type: "active",
    });
    return newFertilizerTank;
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizerTankError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerTank = async (
  props: CrudProps
): Promise<FertilizerTank | undefined> => {
  const { values } = props;
  const fertilizerTankData = values as FertilizerTank;

  const fertilizerTank = await postFertilizerTankObj(
    props,
    fertilizerTankData.mapToApiModel()
  );

  /*   if (
    fertilizerTank &&
    fertilizerTank.id &&
    fertilizerTankData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizerTankData.documents,
      entityClassName: "FBFertilizationPlan",
      entityClassId: fertilizerTank.id,
    }).catch(() => {
      throw FILE_ERROR;
    }); */
  return fertilizerTank;
};

const putFertilizerTankObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerTank | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertilizerTank = values as FertilizerTank;
  const fertilizerTankId = fertilizerTank?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerTank/${fertilizerTankId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFertilizerTankObjResponse({ response, crudProps: props })
  );
};

const onPutFertilizerTankObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizerTank | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedFertilizerTank = new FertilizerTank().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerTanks"],
      type: "active",
    });
    return updatedFertilizerTank;
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizerTankError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilizerTank = async (
  props: CrudProps
): Promise<FertilizerTank | undefined> => {
  const { values } = props;
  const fertilizerTankData = new FertilizerTank().mapToClass(values);

  const fertilizerTank = await putFertilizerTankObj(
    props,
    fertilizerTankData?.mapToApiModel()
  );
  /* 
  if (
    fertilizerTank &&
    fertilizerTank.id &&
    fertilizerTankData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertilizerTankData.documents,
      entityClassName: "FBFertilizationPlan",
      entityClassId: fertilizerTank.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    fertilizerTankData.lastSavedDocuments?.filter(
      (document: Document) =>
        !fertilizerTankData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {}); */

  return fertilizerTank;
};

const deleteFertilizerTanks = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedFertilizerTanks = values as FertilizerTank[];
  return Promise.all(
    selectedFertilizerTanks.map((fertilizerTank: FertilizerTank) => {
      const fertilizerTankId = fertilizerTank?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerTank/${fertilizerTankId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizerTanksResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertilizerTanksResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerTanks"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteFertilizerTanksError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * Crop CRUD endpoints
 */
const postCropObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Crop | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostCropObjResponse({ response, crudProps: props })
  );
};

const onPostCropObjResponse = async (
  props: CrudResponseProps
): Promise<Crop | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newCrop = new Crop().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["crops"],
      type: "active",
    });
    return newCrop;
  } else {
    throw new Error(i18n.t("apiResponses.postCropError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postCrop = async (props: CrudProps): Promise<Crop | undefined> => {
  const { values } = props;
  const cropData = values as Crop;

  const crop = await postCropObj(props, cropData.mapToApiModel());

  if (crop && crop.id && cropData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropData.documents,
      entityClassName: "FBCrop",
      entityClassId: crop.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return crop;
};

const putCropObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Crop> => {
  const { idToken, agroBusinessId, values } = props;
  const crop = values as Crop;
  const cropId = crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutCropObjResponse({ response, crudProps: props })
  );
};

const onPutCropObjResponse = async (
  props: CrudResponseProps
): Promise<Crop> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedCrop = new Crop();
    updatedCrop.mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["crops"],
      type: "active",
    });
    return updatedCrop;
  } else {
    throw new Error(i18n.t("apiResponses.putCropError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putCrop = async (props: CrudProps): Promise<Crop> => {
  const { values } = props;
  const cropData = values as Crop;

  const crop = await putCropObj(props, cropData.mapToApiModel());

  if (crop && crop.id && cropData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropData.documents,
      entityClassName: "FBCrop",
      entityClassId: crop.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = cropData.lastSavedDocuments?.filter(
    (document: Document) =>
      !cropData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return crop;
};

const deleteCrops = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedCrops = values as Crop[];
  return Promise.all(
    selectedCrops.map((crop: Crop) => {
      const cropId = crop?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteCropsResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteCropsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["crops"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteCropsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * Crop Irrigation CRUD endpoints
 */
const postCropIrrigationObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CropIrrigation | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const cropIrrigation = values as CropIrrigation;
  const cropId = cropIrrigation?.crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/irrigation`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostCropIrrigationObjResponse({ response, crudProps: props })
  );
};

const onPostCropIrrigationObjResponse = async (
  props: CrudResponseProps
): Promise<CropIrrigation | undefined> => {
  const { response, crudProps } = props;
  const data = await getResponseData(response);
  const { queryClient } = crudProps;
  if (response.status === 200) {
    // Mapping
    const newCropIrrigation = new CropIrrigation().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["cropIrrigations"],
      type: "active",
    });
    return newCropIrrigation;
  } else {
    throw new Error(i18n.t("apiResponses.postCropIrrigationError"), {
      cause: { status: response.status, data },
    });
  }
};

const postCropIrrigation = async (
  props: CrudProps
): Promise<CropIrrigation | undefined> => {
  const { values } = props;
  const cropIrrigationData = values as CropIrrigation;

  const cropIrrigation = await postCropIrrigationObj(
    props,
    cropIrrigationData.mapToApiModel()
  );

  if (cropIrrigation && cropIrrigation.id && cropIrrigationData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropIrrigationData.documents,
      entityClassName: "FBCropIrrigation",
      entityClassId: cropIrrigation.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return cropIrrigation;
};

const putCropIrrigationObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CropIrrigation | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const cropIrrigation = values as CropIrrigation;
  const cropIrrigationId = cropIrrigation?.id;
  const cropId = cropIrrigation?.crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/irrigation/${cropIrrigationId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutCropIrrigationObjResponse({ response, crudProps: props })
  );
};

const onPutCropIrrigationObjResponse = async (
  props: CrudResponseProps
): Promise<CropIrrigation | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedCropIrrigation = new CropIrrigation().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["cropIrrigations"],
      type: "active",
    });
    return updatedCropIrrigation;
  } else {
    throw new Error(i18n.t("apiResponses.putCropIrrigationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putCropIrrigation = async (
  props: CrudProps
): Promise<CropIrrigation | undefined> => {
  const { values } = props;
  const cropIrrigationData = values as CropIrrigation;

  const cropIrrigation = await putCropIrrigationObj(
    props,
    cropIrrigationData.mapToApiModel()
  );

  if (cropIrrigation && cropIrrigation.id && cropIrrigationData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropIrrigationData.documents,
      entityClassName: "FBCropIrrigation",
      entityClassId: cropIrrigation.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = cropIrrigationData.lastSavedDocuments?.filter(
    (document: Document) =>
      !cropIrrigationData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return cropIrrigation;
};

const deleteCropIrrigations = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedCropIrrigations = values as CropIrrigation[];
  return Promise.all(
    selectedCropIrrigations.map((cropIrrigation: CropIrrigation) => {
      const cropIrrigationId = cropIrrigation?.id;
      const cropId = cropIrrigation?.crop?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/irrigation/${cropIrrigationId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteCropIrrigationsResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteCropIrrigationsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["cropIrrigations"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteCropIrrigationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * Pluviometry Register CRUD endpoints
 */
const postPluviometryRegisterObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PluviometryRegister | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometryRegister`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostPluviometryRegisterObjResponse({ response, crudProps: props })
  );
};

const onPostPluviometryRegisterObjResponse = async (
  props: CrudResponseProps
): Promise<PluviometryRegister | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newPluviometryRegister = new PluviometryRegister().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["pluviometryRegisters"],
      type: "active",
    });
    return newPluviometryRegister;
  } else {
    throw new Error(i18n.t("apiResponses.postPluviometryRegisterError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPluviometryRegister = async (
  props: CrudProps
): Promise<PluviometryRegister | undefined> => {
  const { values } = props;
  const pluviometryRegisterData = values as PluviometryRegister;

  const pluviometryRegister = await postPluviometryRegisterObj(
    props,
    pluviometryRegisterData.mapToApiModel()
  );

  if (
    pluviometryRegister &&
    pluviometryRegister.id &&
    pluviometryRegisterData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: pluviometryRegisterData.documents,
      entityClassName: "FBPluviometryRegister",
      entityClassId: pluviometryRegister.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return pluviometryRegister;
};

const putPluviometryRegisterObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PluviometryRegister | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const pluviometryRegister = values as PluviometryRegister;
  const pluviometryRegisterId = pluviometryRegister?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometryRegister/${pluviometryRegisterId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutPluviometryRegisterObjResponse({ response, crudProps: props })
  );
};

const onPutPluviometryRegisterObjResponse = async (
  props: CrudResponseProps
): Promise<PluviometryRegister | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedPluviometryRegister = new PluviometryRegister().mapToClass(
      data
    );
    // Update list
    queryClient.refetchQueries({
      queryKey: ["pluviometryRegisters"],
      type: "active",
    });
    return updatedPluviometryRegister;
  } else {
    throw new Error(i18n.t("apiResponses.putPluviometryRegisterError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPluviometryRegister = async (
  props: CrudProps
): Promise<PluviometryRegister | undefined> => {
  const { values } = props;
  const pluviometryRegisterData = values as PluviometryRegister;

  const pluviometryRegister = await putPluviometryRegisterObj(
    props,
    pluviometryRegisterData.mapToApiModel()
  );

  if (
    pluviometryRegister &&
    pluviometryRegister.id &&
    pluviometryRegisterData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: pluviometryRegisterData.documents,
      entityClassName: "FBPluviometryRegister",
      entityClassId: pluviometryRegister.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    pluviometryRegisterData.lastSavedDocuments?.filter(
      (document: Document) =>
        !pluviometryRegisterData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return pluviometryRegister;
};

const deletePluviometryRegisters = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedRegisters = values as PluviometryRegister[];
  return Promise.all(
    selectedRegisters.map((pluviometryRegister: PluviometryRegister) => {
      const pluviometryRegisterId = pluviometryRegister?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometryRegister/${pluviometryRegisterId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeletePluviometryRegistersResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePluviometryRegistersResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["pluviometryRegisters"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deletePluviometryRegistersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytosanitaryProductObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryProduct | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostPhytosanitaryProductObjResponse);
};

const onPostPhytosanitaryProductObjResponse = async (
  response: Response
): Promise<PhytosanitaryProduct | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new PhytosanitaryProduct().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postPhytosanitaryProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytosanitaryProduct = async (
  props: CrudProps
): Promise<PhytosanitaryProduct | undefined> => {
  const { values } = props;
  const phytosanitaryProductData = values as PhytosanitaryProduct;

  const phytosanitaryProduct = await postPhytosanitaryProductObj(
    props,
    phytosanitaryProductData.mapToApiModel()
  );

  if (
    phytosanitaryProduct &&
    phytosanitaryProduct.id &&
    phytosanitaryProductData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: phytosanitaryProductData.documents,
      entityClassName: "FBPhytosanitaryProduct",
      entityClassId: phytosanitaryProduct.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return phytosanitaryProduct;
};

const putPhytosanitaryProductObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryProduct | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const phytoProduct = values as PhytosanitaryProduct;
  const productId = phytoProduct?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/${productId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutPhytoProductObjResponse({ response, crudProps: props })
  );
};

const onPutPhytoProductObjResponse = async (
  props: CrudResponseProps
): Promise<PhytosanitaryProduct | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedProduct = new PhytosanitaryProduct().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytosanitaryProducts"],
      type: "active",
    });
    return updatedProduct;
  } else {
    throw new Error(i18n.t("apiResponses.putPhytosanitaryProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPhytosanitaryProduct = async (
  props: CrudProps
): Promise<PhytosanitaryProduct | undefined> => {
  const { values } = props;
  const phytoProductData = values as PhytosanitaryProduct;

  const phytoProduct = await putPhytosanitaryProductObj(
    props,
    phytoProductData.mapToApiModel()
  );

  if (phytoProduct && phytoProduct.id && phytoProductData.documents)
    await attachDocuments({
      crudProps: props,
      documents: phytoProductData.documents,
      entityClassName: "FBPhytosanitaryProduct",
      entityClassId: phytoProduct.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = phytoProductData.lastSavedDocuments?.filter(
    (document: Document) =>
      !phytoProductData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return phytoProduct;
};

const deletePhytosanitaryProducts = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedProducts = values as PhytosanitaryProduct[];
  return Promise.all(
    selectedProducts.map((phytosanitaryProduct: PhytosanitaryProduct) => {
      const productId = phytosanitaryProduct?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/${productId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeletePhytosanitaryProductsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePhytosanitaryProductsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytosanitaryProducts"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deletePhytosanitaryProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * STOCK CRUD endpoints
 */
interface RegisteredFertilizerProductProps {
  searchWord?: string;
}
const fetchRegisteredFertilizerProducts = async (
  props: CrudProps & RegisteredFertilizerProductProps
): Promise<RegisteredFertilizerProduct[]> => {
  const { selectedABAccount, idToken, searchWord = "" } = props;
  const contextId = selectedABAccount?.context?.id;
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/registeredFertilizerProduct/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisteredFertilizerProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onRegisteredFertilizerProductsResponse = async (
  response: Response
): Promise<RegisteredFertilizerProduct[]> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data.map((d: any) =>
      new RegisteredFertilizerProduct().mapToClass(d)
    );
  } else return [];
  //TODO: Uncomment when catalogue exits -> else throw Error("No se han podido obtener el listado de productos.");
};

interface RegisteredPhytosanitaryProductProps {
  searchWord?: string;
}
const fetchRegisteredPhytosaniaryProducts = async (
  props: CrudProps & RegisteredPhytosanitaryProductProps
) => {
  const { selectedABAccount, idToken, searchWord = "" } = props;
  const contextId = selectedABAccount?.context?.id;
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/registeredPhytosanitaryProduct/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisteredPhytosanitaryProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onRegisteredPhytosanitaryProductsResponse = async (
  response: Response
) => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data.map((d: any) =>
      new RegisteredPhytosanitaryProduct().mapToClass(d)
    );
  } else
    throw new Error(
      i18n.t("apiResponses.fetchRegisteredPhytosaniaryProductsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const postFertilizerProductPurchaseObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerProductPurchase | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductPurchase`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostFertilizerProductPurchaseObjResponse);
};

const onPostFertilizerProductPurchaseObjResponse = async (
  response: Response
): Promise<FertilizerProductPurchase | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new FertilizerProductPurchase().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizerProductPurchaseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerProductPurchase = async (
  props: CrudProps
): Promise<FertilizerProductPurchase | undefined> => {
  const { values } = props;
  const purchaseData = values as FertilizerProductPurchase;

  const purchase = await postFertilizerProductPurchaseObj(
    props,
    purchaseData.mapToApiModel()
  );

  if (purchase && purchase.id && purchaseData.documents)
    await attachDocuments({
      crudProps: props,
      documents: purchaseData.documents,
      entityClassName: "FBFertilizerProductPurchase",
      entityClassId: purchase.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return purchase;
};

const putFertilizerProductPurchaseObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerProductPurchase | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const purchaseData = values as FertilizerProductPurchase;
  const purchaseId = purchaseData?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductPurchase/${purchaseId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPutFertilizerProductPurchaseObjResponse);
};

const onPutFertilizerProductPurchaseObjResponse = async (
  response: Response
): Promise<FertilizerProductPurchase | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new FertilizerProductPurchase().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizerProductPurchaseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilizerProductPurchase = async (
  props: CrudProps
): Promise<FertilizerProductPurchase | undefined> => {
  const { values } = props;
  const purchaseData = values as FertilizerProductPurchase;

  const purchase = await putFertilizerProductPurchaseObj(
    props,
    purchaseData.mapToApiModel()
  );

  if (purchase && purchase.id && purchaseData.documents)
    await attachDocuments({
      crudProps: props,
      documents: purchaseData.documents,
      entityClassName: "FBFertilizerProductPurchase",
      entityClassId: purchase.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = purchaseData.lastSavedDocuments?.filter(
    (document: Document) =>
      !purchaseData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return purchase;
};

const deleteFertilizerProductPurchases = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedPurchases = values as FertilizerProductPurchase[];
  if (selectedPurchases.length === 0) return Promise.resolve();
  return Promise.all(
    selectedPurchases.map((fertilizerPurchase: FertilizerProductPurchase) => {
      const purchaseId = fertilizerPurchase?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductPurchase/${purchaseId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizerProductPurchasesResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertilizerProductPurchasesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.deleteFertilizerProductPurchasesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPhytosanitaryProductPurchaseObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductPurchase`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostPhytosanitaryProductPurchaseObjResponse);
};

const onPostPhytosanitaryProductPurchaseObjResponse = async (
  response: Response
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytosanitaryProductPurchase().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.postPhytosanitaryProductPurchaseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPhytosanitaryProductPurchase = async (
  props: CrudProps
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const { values } = props;
  const purchaseData = values as PhytosanitaryProductPurchase;

  const purchase = await postPhytosanitaryProductPurchaseObj(
    props,
    purchaseData.mapToApiModel()
  );

  if (purchase && purchase.id && purchaseData.documents)
    await attachDocuments({
      crudProps: props,
      documents: purchaseData.documents,
      entityClassName: "FBPhytosanitaryProductPurchase",
      entityClassId: purchase.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return purchase;
};

const putPhytosanitaryProductPurchaseObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const purchaseData = values as PhytosanitaryProductPurchase;
  const purchaseId = purchaseData?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductPurchase/${purchaseId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPutPhytosanitaryProductPurchaseObjResponse);
};

const onPutPhytosanitaryProductPurchaseObjResponse = async (
  response: Response
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytosanitaryProductPurchase().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.putPhytosanitaryProductPurchaseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const putPhytosanitaryProductPurchase = async (
  props: CrudProps
): Promise<PhytosanitaryProductPurchase | undefined> => {
  const { values } = props;
  const purchaseData = values as PhytosanitaryProductPurchase;

  const purchase = await putPhytosanitaryProductPurchaseObj(
    props,
    purchaseData.mapToApiModel()
  );

  if (purchase && purchase.id && purchaseData.documents)
    await attachDocuments({
      crudProps: props,
      documents: purchaseData.documents,
      entityClassName: "FBPhytosanitaryProductPurchase",
      entityClassId: purchase.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = purchaseData.lastSavedDocuments?.filter(
    (document: Document) =>
      !purchaseData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return purchase;
};

const deletePhytosanitaryProductPurchases = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedPurchases = values as PhytosanitaryProductPurchase[];
  if (selectedPurchases.length === 0) return Promise.resolve();
  return Promise.all(
    selectedPurchases.map((phytoPurchase: PhytosanitaryProductPurchase) => {
      const purchaseId = phytoPurchase?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductPurchase/${purchaseId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeletePhytosanitaryProductPurchasesResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePhytosanitaryProductPurchasesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.deletePhytosanitaryProductPurchasesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postFertigationWaterMixObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertigationWaterMix | undefined> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedValues),
      }
    ).then(onPostFertigationWaterMixObjResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPostFertigationWaterMixObjResponse = async (
  response: Response
): Promise<FertigationWaterMix | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new FertigationWaterMix().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postFertigationWaterMixError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertigationWaterMix = async (
  props: CrudProps
): Promise<FertigationWaterMix | undefined> => {
  const { values } = props;
  const fertigationWaterMixData = values as FertigationWaterMix;

  const fertigationWaterMix = await postFertigationWaterMixObj(
    props,
    fertigationWaterMixData.mapToApiModel()
  );

  if (
    fertigationWaterMix &&
    fertigationWaterMix.id &&
    fertigationWaterMixData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertigationWaterMixData.documents,
      entityClassName: "FBFertigationWaterMix",
      entityClassId: fertigationWaterMix.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return fertigationWaterMix;
};

const putFertigationWaterMixObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertigationWaterMix | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const fertigationWaterMix = values as FertigationWaterMix;
  const fertigationWaterMixId = fertigationWaterMix?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix/${fertigationWaterMixId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedValues),
      }
    ).then(onPutFertigationWaterMixObjResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPutFertigationWaterMixObjResponse = async (
  response: Response
): Promise<FertigationWaterMix | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new FertigationWaterMix().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putFertigationWaterMixError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertigationWaterMix = async (
  props: CrudProps
): Promise<FertigationWaterMix | undefined> => {
  const { values } = props;
  const fertigationWaterMixData = values as FertigationWaterMix;

  const fertigationWaterMix = await putFertigationWaterMixObj(
    props,
    fertigationWaterMixData.mapToApiModel()
  );

  if (
    fertigationWaterMix &&
    fertigationWaterMix.id &&
    fertigationWaterMixData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: fertigationWaterMixData.documents,
      entityClassName: "FBFertigationWaterMix",
      entityClassId: fertigationWaterMix.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    fertigationWaterMixData.lastSavedDocuments?.filter(
      (document: Document) =>
        !fertigationWaterMixData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return fertigationWaterMix;
};

const deleteFertigationWaterMixes = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedMixes = values as FertigationWaterMix[];
  if (selectedMixes.length === 0) return Promise.resolve();
  return Promise.all(
    selectedMixes.map((fertigationWaterMix: FertigationWaterMix) => {
      const fertigationWaterMixId = fertigationWaterMix?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix/${fertigationWaterMixId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertigationWaterMixesResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertigationWaterMixesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteFertigationWaterMixesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerStockMovementObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerStockMovement | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerStockMovement`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFertilizerStockMovementObjResponse({ response, crudProps: props })
  );
};

const onPostFertilizerStockMovementObjResponse = async (
  props: CrudResponseProps
): Promise<FertilizerStockMovement | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newStockMovement = new FertilizerStockMovement().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerStockMovements"],
      type: "active",
    });
    return newStockMovement;
  } else {
    throw new Error(i18n.t("apiResponses.postFertilizerStockMovementError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFertilizerStockMovement = async (
  props: CrudProps
): Promise<FertilizerStockMovement | undefined> => {
  const { values } = props;
  const stockMovementData = values as FertilizerStockMovement;

  const stockMovement = await postFertilizerStockMovementObj(
    props,
    stockMovementData.mapToApiModel()
  );

  if (stockMovement && stockMovement.id && stockMovementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: stockMovementData.documents,
      entityClassName: "FBFertilizerStockMovement",
      entityClassId: stockMovement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return stockMovement;
};

const putFertilizerStockMovementObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FertilizerStockMovement | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const stockMovement = values as FertilizerStockMovement;
  const stockMovementId = stockMovement?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerStockMovement/${stockMovementId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFertilizerStockMovementResponse({ response, crudProps: props })
  );
};

const onPutFertilizerStockMovementResponse = async (
  props: CrudResponseProps
): Promise<FertilizerStockMovement | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedMovement = new FertilizerStockMovement().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerStockMovements"],
      type: "active",
    });
    return updatedMovement;
  } else {
    throw new Error(i18n.t("apiResponses.putFertilizerStockMovementError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFertilizerStockMovement = async (
  props: CrudProps
): Promise<FertilizerStockMovement | undefined> => {
  const { values } = props;
  const stockMovementData = values as FertilizerStockMovement;

  const stockMovement = await putFertilizerStockMovementObj(
    props,
    stockMovementData.mapToApiModel()
  );

  if (stockMovement && stockMovement.id && stockMovementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: stockMovementData.documents,
      entityClassName: "FBFertilizerStockMovement",
      entityClassId: stockMovement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = stockMovementData.lastSavedDocuments?.filter(
    (document: Document) =>
      !stockMovementData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return stockMovement;
};

const deleteFertilizerStockMovements = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedMovements = values as FertilizerStockMovement[];
  if (selectedMovements.length === 0) return Promise.resolve();
  return Promise.all(
    selectedMovements.map((stockMovement: FertilizerStockMovement) => {
      const movementId = stockMovement?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerStockMovement/${movementId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFertilizerStockMovementsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFertilizerStockMovementsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["fertilizerStockMovements"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deleteFertilizerStockMovementsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const deletePhytosanitaryStockMovements = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedMovements = values as PhytosanitaryStockMovement[];
  if (selectedMovements.length === 0) return Promise.resolve();
  return Promise.all(
    selectedMovements.map((stockMovement: PhytosanitaryStockMovement) => {
      const movementId = stockMovement?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryStockMovement/${movementId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeletePhytosanitaryStockMovementsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePhytosanitaryStockMovementsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytosanitaryStockMovements"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deletePhytosanitaryStockMovementsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPhytosanitaryStockMovementObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryStockMovement`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostPhytosanitaryStockMovementObjResponse({ response, crudProps: props })
  );
};

const onPostPhytosanitaryStockMovementObjResponse = async (
  props: CrudResponseProps
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newStockMovement = new PhytosanitaryStockMovement().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytosanitaryStockMovements"],
      type: "active",
    });
    return newStockMovement;
  } else {
    throw new Error(
      i18n.t("apiResponses.postPhytosanitaryStockMovementError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPhytosanitaryStockMovement = async (
  props: CrudProps
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { values } = props;
  const stockMovementData = values as PhytosanitaryStockMovement;

  const stockMovement = await postPhytosanitaryStockMovementObj(
    props,
    stockMovementData.mapToApiModel()
  );

  if (stockMovement && stockMovement.id && stockMovementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: stockMovementData.documents,
      entityClassName: "FBPhytosanitaryStockMovement",
      entityClassId: stockMovement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return stockMovement;
};

const putPhytosanitaryStockMovementObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const stockMovement = values as PhytosanitaryStockMovement;
  const stockMovementId = stockMovement?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryStockMovement/${stockMovementId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutPhytosanitaryStockMovementObjResponse({ response, crudProps: props })
  );
};

const onPutPhytosanitaryStockMovementObjResponse = async (
  props: CrudResponseProps
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedMovement = new PhytosanitaryStockMovement().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["phytosanitaryStockMovements"],
      type: "active",
    });
    return updatedMovement;
  } else {
    throw new Error(i18n.t("apiResponses.putPhytosanitaryStockMovementError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPhytosanitaryStockMovement = async (
  props: CrudProps
): Promise<PhytosanitaryStockMovement | undefined> => {
  const { values } = props;
  const stockMovementData = values as PhytosanitaryStockMovement;

  const stockMovement = await putPhytosanitaryStockMovementObj(
    props,
    stockMovementData.mapToApiModel()
  );

  if (stockMovement && stockMovement.id && stockMovementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: stockMovementData.documents,
      entityClassName: "FBPhytosanitaryStockMovement",
      entityClassId: stockMovement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = stockMovementData.lastSavedDocuments?.filter(
    (document: Document) =>
      !stockMovementData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return stockMovement;
};

/**
 * DOCUMENT CRUD endpoints
 */
interface UploadDocumentFileInterface {
  documentId?: number;
  file?: File;
}
const uploadDocumentFile = async (
  props: UploadDocumentFileInterface & CrudProps
) => {
  const { agroBusinessId, idToken, documentId, file } = props;
  if (agroBusinessId && idToken && documentId && file) {
    const data = new FormData();
    data.append("file", file);
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/document/${documentId}/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        body: data,
      }
    ).then(onUploadDocumentFileResponse);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onUploadDocumentFileResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data;
  } else {
    throw new Error(i18n.t("apiResponses.uploadDocumentFileError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postDocumentObject = async (
  props: CrudProps,
  formattedValues: any
): Promise<Document | undefined> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/document`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedValues),
      }
    ).then(onPostDocumentObjectResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPostDocumentObjectResponse = async (
  response: Response
): Promise<Document | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Document().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postDocumentError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putDocumentObject = async (
  props: CrudProps,
  formattedValues: any
): Promise<Document | undefined> => {
  const { idToken, agroBusinessId } = props;
  const documentId = formattedValues?.id;
  if (agroBusinessId && idToken && documentId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/document/${documentId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedValues),
      }
    ).then(onPutDocumentObjectResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPutDocumentObjectResponse = async (
  response: Response
): Promise<Document | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Document().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putDocumentError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postDocument = async (
  props: CrudProps
): Promise<Document | undefined> => {
  const { values, selectedABAccount, selectedABPartition } = props;

  const doc = values as Document;
  const formattedDoc = doc.mapToApiModel();
  formattedDoc.agroBusinessPartition = selectedABPartition;
  if (!doc.entityClassName) formattedDoc.entityClassName = "FBAgroBusiness";
  if (!doc.entityClassId)
    formattedDoc.entityClassId = selectedABAccount?.agroBusiness?.id;

  try {
    const file = doc.file;

    // Create document object
    const document = await postDocumentObject(props, formattedDoc);

    // Upload file
    await uploadDocumentFile({
      documentId: document?.id,
      file,
      ...props,
    });
    return document;
  } catch (error) {
    return Promise.reject("error inesperado");
  }
};

const putDocument = async (props: CrudProps): Promise<Document | undefined> => {
  const { values, selectedABAccount, selectedABPartition } = props;

  const doc = values as Document;
  const formattedDoc = doc.mapToApiModel();
  formattedDoc.agroBusinessPartition = selectedABPartition;
  if (!doc.entityClassName) formattedDoc.entityClassName = "FBAgroBusiness";
  if (!doc.entityClassId)
    formattedDoc.entityClassId = selectedABAccount?.agroBusiness?.id;

  try {
    const file = doc.file;

    // Update document object
    const document = await putDocumentObject(props, formattedDoc);

    // Upload file if changed
    if (file)
      await uploadDocumentFile({
        documentId: document?.id,
        file,
        ...props,
      });
    return document;
  } catch (error) {
    return Promise.reject("error inesperado");
  }
};

const deleteDocuments = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedDocuments = values as FBDocument[];
  return Promise.all(
    selectedDocuments.map((doc: FBDocument) => {
      const documentId = doc?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/document/${documentId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then(onDeleteDocumentsResponse);
};

//TODO: Check all responses?
const onDeleteDocumentsResponse = async (
  response: Response[]
): Promise<void> => {
  if (response[0].status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteDocumentsError"), {
      cause: response[0].status,
    });
  }
};

interface AttachDocumentsProps {
  crudProps: CrudProps;
  documents: Document[];
  entityClassName: string;
  entityClassId: number;
  documentDate?: string;
}
// Create document and upload files if not exits (document has not id). If exits, update document with the entityClassName and entityClassId
const attachDocuments = async (props: AttachDocumentsProps): Promise<void> => {
  const { crudProps, documents, entityClassName, entityClassId, documentDate } =
    props;
  return Promise.all(
    documents.map(async (document: Document) => {
      try {
        document.entityClassName = entityClassName;
        document.entityClassId = entityClassId;
        if (!document.date)
          document.date = documentDate || moment().format("YYYY-MM-DD");
        const payload = { ...crudProps, values: document };
        document.id ? await putDocument(payload) : await postDocument(payload);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    })
  )
    .then(() => Promise.resolve())
    .catch(() => Promise.reject());
};

interface DettachDocumentsProps {
  crudProps: CrudProps;
  documents: Document[];
}
// Mark as uncategorized the documents (dettach documents from entity)
const dettachDocuments = async (
  props: DettachDocumentsProps
): Promise<void> => {
  const { crudProps, documents } = props;
  const { agroBusinessId } = crudProps;

  const entityClassName = "FBAgroBusiness";
  const entityClassId = agroBusinessId;
  return Promise.all(
    documents.map(async (document: Document) => {
      try {
        document.entityClassName = entityClassName;
        document.entityClassId = entityClassId;
        const payload = { ...crudProps, values: document };
        await putDocument(payload);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    })
  )
    .then(() => Promise.resolve())
    .catch(() => Promise.reject());
};

/**
 * AGROBUSINESS PARTITION CRUD endpoints
 */
const postAgroBusinessPartition = async (
  props: CrudProps
): Promise<AgroBusinessPartition | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const agroBusinessPartition = values as AgroBusinessPartition;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/partition`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusinessPartition.mapBaseToApiModel()), // Not mapToApiModel to avoid null as when is field not payload
    }
  ).then((response: Response) =>
    onPostAgroBusinessPartitionResponse({ response, crudProps: props })
  );
};

const onPostAgroBusinessPartitionResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessPartition | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Add created ABP to array without refetch
    const newABP = new AgroBusinessPartition().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessPartitions"],
      type: "active",
    });
    return newABP;
  } else {
    throw new Error(i18n.t("apiResponses.postAgroBusinessPartitionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusinessPartition = async (
  props: CrudProps
): Promise<AgroBusinessPartition | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const agroBusinessPartition = values as AgroBusinessPartition;
  const agroBusinessPartitionId = agroBusinessPartition?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/partition/${agroBusinessPartitionId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(agroBusinessPartition.mapBaseToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessPartitionResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessPartitionResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessPartition | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update ABP in array without refetch
    const updatedABP = new AgroBusinessPartition().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessPartitions"],
      type: "active",
    });
    return updatedABP;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessPartitionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAgroBusinessPartitions = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedPartitions = values as AgroBusinessPartition[];
  return Promise.all(
    selectedPartitions.map((abp) => {
      const abpId = abp?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/partition/${abpId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessPartitionResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAgroBusinessPartitionResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessPartitions"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteAgroBusinessPartitionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * Sector CRUD endpoints
 */
const postSectorObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Sector | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostSectorObjResponse({ response, crudProps: props })
  );
};

const onPostSectorObjResponse = async (
  props: CrudResponseProps
): Promise<Sector | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newSector = new Sector().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["sectors"],
      type: "active",
    });
    return newSector;
  } else {
    throw new Error(i18n.t("apiResponses.postSectorError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postSector = async (props: CrudProps): Promise<Sector | undefined> => {
  const { values } = props;
  const sectorData = values as Sector;

  const sector = await postSectorObj(props, sectorData.mapToApiModel());

  if (sector && sector.id && sectorData.documents)
    await attachDocuments({
      crudProps: props,
      documents: sectorData.documents,
      entityClassName: "FBSector",
      entityClassId: sector.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return sector;
};

const putSectorObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Sector | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const sector = values as Sector;
  const sectorId = sector?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector/${sectorId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutSectorObjResponse({ response, crudProps: props })
  );
};

const onPutSectorObjResponse = async (
  props: CrudResponseProps
): Promise<Sector | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedSector = new Sector().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["sectors"],
      type: "active",
    });
    return updatedSector;
  } else {
    throw new Error(i18n.t("apiResponses.putSectorError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putSector = async (props: CrudProps): Promise<Sector | undefined> => {
  const { values } = props;
  const sectorData = values as Sector;

  const sector = await putSectorObj(props, sectorData.mapToApiModel());

  if (sector && sector.id && sectorData.documents)
    await attachDocuments({
      crudProps: props,
      documents: sectorData.documents,
      entityClassName: "FBSector",
      entityClassId: sector.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = sectorData.lastSavedDocuments?.filter(
    (document: Document) =>
      !sectorData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return sector;
};

const deleteSectors = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedSectors = values as Sector[];
  return Promise.all(
    selectedSectors.map((sector: Sector) => {
      const sectorId = sector?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector/${sectorId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteSectorsResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteSectorsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["sectors"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteSectorsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postAgroBusinessAccount = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { idToken, values } = props;
  const aba = values as AgroBusinessAccount;
  const cueAccountId = aba?.cueAccount?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/agroBusinessAccount`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(aba.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPostAgroBusinessAccountResponse({ response, crudProps: props })
  );
};

const onPostAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessAccount | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    const newABA = new AgroBusinessAccount().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
    return newABA;
  } else {
    throw new Error(i18n.t("apiResponses.postAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusinessAccount = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { idToken, values } = props;
  const aba = values as AgroBusinessAccount;
  const abaId = aba?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${abaId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(aba.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessAccountResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessAccount | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    const updatedABA = new AgroBusinessAccount().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
    return updatedABA;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAgroBusinessAccounts = async (props: CrudProps): Promise<void> => {
  const { idToken, values } = props;
  const selectedABAs = values as AgroBusinessAccount[];
  return Promise.all(
    selectedABAs.map((aba: AgroBusinessAccount) => {
      //TODO: if(aba.id === 0) return Promise.resolve()
      const agrobusinessId = aba.agroBusiness?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessAccountResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

const onDeleteAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteAgroBusinessAccountsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * FACILITY CRUD endpoints
 */
const postFacilityObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Facility | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostFacilityObjResponse({ response, crudProps: props })
  );
};

const onPostFacilityObjResponse = async (
  props: CrudResponseProps
): Promise<Facility | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newFacility = new Facility().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["facilities"],
      type: "active",
    });
    return newFacility;
  } else {
    throw new Error(i18n.t("apiResponses.postFacilityError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postFacility = async (
  props: CrudProps
): Promise<Facility | undefined> => {
  const { values } = props;
  const facilityData = values as Facility;

  const facility = await postFacilityObj(props, facilityData.mapToApiModel());

  if (facility && facility.id && facilityData.documents)
    await attachDocuments({
      crudProps: props,
      documents: facilityData.documents,
      entityClassName: "FBFacility",
      entityClassId: facility.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return facility;
};

const putFacilityObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Facility | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const facility = values as Facility;
  const facilityId = facility?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility/${facilityId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFacilityObjResponse({ response, crudProps: props })
  );
};

const onPutFacilityObjResponse = async (
  props: CrudResponseProps
): Promise<Facility | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedFacility = new Facility().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["facilities"],
      type: "active",
    });
    return updatedFacility;
  } else {
    throw new Error(i18n.t("apiResponses.putFacilityError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putFacility = async (props: CrudProps): Promise<Facility | undefined> => {
  const { values } = props;
  const facilityData = values as Facility;

  const facility = await putFacilityObj(props, facilityData.mapToApiModel());

  if (facility && facility.id && facilityData.documents)
    await attachDocuments({
      crudProps: props,
      documents: facilityData.documents,
      entityClassName: "FBFacility",
      entityClassId: facility.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = facilityData.lastSavedDocuments?.filter(
    (document: Document) =>
      !facilityData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return facility;
};

const deleteFacilities = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedFacilities = values as Facility[];
  return Promise.all(
    selectedFacilities.map((facility: Facility) => {
      const facilityId = facility?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility/${facilityId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteFacilitiesResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteFacilitiesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["facilities"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteFacilitiesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * TOOL CRUD endpoints
 */
const postToolObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Tool | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/tool`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostToolObjResponse({ response, crudProps: props })
  );
};

const onPostToolObjResponse = async (
  props: CrudResponseProps
): Promise<Tool | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newTool = new Tool().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["tools"],
      type: "active",
    });
    return newTool;
  } else {
    throw new Error(i18n.t("apiResponses.postToolError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postTool = async (props: CrudProps): Promise<Tool | undefined> => {
  const { values } = props;
  const toolData = values as Tool;

  const tool = await postToolObj(props, toolData.mapToApiModel());

  if (tool && tool.id && toolData.documents)
    await attachDocuments({
      crudProps: props,
      documents: toolData.documents,
      entityClassName: "FBTool",
      entityClassId: tool.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return tool;
};

const putToolObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Tool | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const tool = values as Tool;
  const toolId = tool?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/tool/${toolId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutToolObjResponse({ response, crudProps: props })
  );
};

const onPutToolObjResponse = async (
  props: CrudResponseProps
): Promise<Tool | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedTool = new Tool().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["tools"],
      type: "active",
    });
    return updatedTool;
  } else {
    throw new Error(i18n.t("apiResponses.putToolError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putTool = async (props: CrudProps): Promise<Tool | undefined> => {
  const { values } = props;
  const toolData = values as Tool;

  const tool = await putToolObj(props, toolData.mapToApiModel());

  if (tool && tool.id && toolData.documents)
    await attachDocuments({
      crudProps: props,
      documents: toolData.documents,
      entityClassName: "FBTool",
      entityClassId: tool.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = toolData.lastSavedDocuments?.filter(
    (document: Document) =>
      !toolData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return tool;
};

const deleteTools = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedTools = values as Tool[];
  return Promise.all(
    selectedTools.map((tool: Tool) => {
      const toolId = tool?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/tool/${toolId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteToolsResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteToolsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["tools"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteToolsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * WATER SOURCE CRUD endpoints
 */
const postWaterSourceObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<WaterSource | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/waterSource`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPostWaterSourceObjResponse({ response, crudProps: props })
  );
};

const onPostWaterSourceObjResponse = async (
  props: CrudResponseProps
): Promise<WaterSource | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const newWS = new WaterSource().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["waterSources"],
      type: "active",
    });
    return newWS;
  } else {
    throw new Error(i18n.t("apiResponses.postWaterSourceError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postWaterSource = async (
  props: CrudProps
): Promise<WaterSource | undefined> => {
  const { values } = props;
  const waterSourceData = values as WaterSource;

  const waterSource = await postWaterSourceObj(
    props,
    waterSourceData.mapToApiModel()
  );

  if (waterSource && waterSource.id && waterSourceData.documents)
    await attachDocuments({
      crudProps: props,
      documents: waterSourceData.documents,
      entityClassName: "FBWaterSource",
      entityClassId: waterSource.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return waterSource;
};

const putWaterSourceObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<WaterSource | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const waterSource = values as WaterSource;
  const waterSourceId = waterSource?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/waterSource/${waterSourceId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutAgroWaterSourceObjResponse({ response, crudProps: props })
  );
};

const onPutAgroWaterSourceObjResponse = async (
  props: CrudResponseProps
): Promise<WaterSource | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    const updatedWS = new WaterSource().mapToClass(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["waterSources"],
      type: "active",
    });
    return updatedWS;
  } else {
    throw new Error(i18n.t("apiResponses.putWaterSourceError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putWaterSource = async (
  props: CrudProps
): Promise<WaterSource | undefined> => {
  const { values } = props;
  const waterSourceData = values as WaterSource;

  const waterSource = await putWaterSourceObj(
    props,
    waterSourceData.mapToApiModel()
  );

  if (waterSource && waterSource.id && waterSourceData.documents)
    await attachDocuments({
      crudProps: props,
      documents: waterSourceData.documents,
      entityClassName: "FBWaterSource",
      entityClassId: waterSource.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = waterSourceData.lastSavedDocuments?.filter(
    (document: Document) =>
      !waterSourceData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return waterSource;
};

const deleteWaterSources = async (props: CrudProps): Promise<void> => {
  const { idToken, agroBusinessId, values } = props;
  const selectedWaterSources = values as WaterSource[];
  return Promise.all(
    selectedWaterSources.map((waterSource: WaterSource) => {
      const waterSourceId = waterSource?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/waterSource/${waterSourceId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteWaterSourcesResponse({ response: response[0], crudProps: props })
  );
};

//TODO: Check all responses?
const onDeleteWaterSourcesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["waterSources"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteWaterSourcesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * ANALYSIS CRUD endpoints
 */
const postAnalysisObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Analysis | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/analysis`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostAnalysisObjResponse);
};

const onPostAnalysisObjResponse = async (
  response: Response
): Promise<Analysis | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Analysis().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postAnalysisError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAnalysisObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<Analysis | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const analysis = values as Analysis;
  const analysisId = analysis?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/analysis/${analysisId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPutAnalysisObjResponse);
};

const onPutAnalysisObjResponse = async (
  response: Response
): Promise<Analysis | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Analysis().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putAnalysisError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postAnalysis = async (
  props: CrudProps
): Promise<Analysis | undefined> => {
  const { values } = props;
  const analysisData = values as Analysis;

  const analysis = await postAnalysisObj(props, analysisData.mapToApiModel());
  if (analysis && analysis.id && analysisData.documents)
    await attachDocuments({
      crudProps: props,
      documents: analysisData.documents,
      entityClassName: "FBAnalysis",
      entityClassId: analysis.id,
      documentDate: analysis.date,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return analysis;
};

const putAnalysis = async (props: CrudProps): Promise<Analysis | undefined> => {
  const { values } = props;
  const analysisData = values as Analysis;

  const analysis = await putAnalysisObj(props, analysisData.mapToApiModel());

  if (analysis && analysis.id && analysisData.documents)
    await attachDocuments({
      crudProps: props,
      documents: analysisData.documents,
      entityClassName: "FBAnalysis",
      entityClassId: analysis.id,
      documentDate: analysis.date,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = analysisData.lastSavedDocuments?.filter(
    (document: Document) =>
      !analysisData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return analysis;
};

const deleteAnalysis = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedAnalysis = values as Analysis[];
  if (selectedAnalysis.length === 0) return Promise.resolve();
  return Promise.all(
    selectedAnalysis.map((analysis: Analysis) => {
      const analysisId = analysis?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/analysis/${analysisId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAnalysisResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAnalysisResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteAnalysisError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * PHYTOSANITARY TREATMENTS CRUD endpoints
 */

const postCropPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropPhytosanitaryTreatment`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostCropPhytosanitaryTreatmentObjResponse);
};

const onPostCropPhytosanitaryTreatmentObjResponse = async (
  response: Response
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new CropPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.postCropPhytosanitaryTreatmentError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postCropPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const cropTreatementData = values as CropPhytosanitaryTreatment;

  const cropTreatement = await postCropPhytosanitaryTreatmentObj(
    props,
    cropTreatementData.mapToApiModel()
  );
  if (cropTreatement && cropTreatement.id && cropTreatementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropTreatementData.documents,
      entityClassName: "FBCropPhytosanitaryTreatment",
      entityClassId: cropTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return cropTreatement;
};

const putCropPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const cropTreatement = values as CropPhytosanitaryTreatment;
  const cropTreatementId = cropTreatement?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropPhytosanitaryTreatment/${cropTreatementId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutCropPhytosanitaryTreatmentObjResponse({ response, crudProps: props })
  );
};

const onPutCropPhytosanitaryTreatmentObjResponse = async (
  props: CrudResponseProps
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new CropPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putCropPhytosanitaryTreatmentError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putCropPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<CropPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const cropTreatementData = values as CropPhytosanitaryTreatment;

  const cropTreatement = await putCropPhytosanitaryTreatmentObj(
    props,
    cropTreatementData.mapToApiModel()
  );

  if (cropTreatement && cropTreatement.id && cropTreatementData.documents)
    await attachDocuments({
      crudProps: props,
      documents: cropTreatementData.documents,
      entityClassName: "FBCropPhytosanitaryTreatment",
      entityClassId: cropTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = cropTreatementData.lastSavedDocuments?.filter(
    (document: Document) =>
      !cropTreatementData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return cropTreatement;
};

const deleteCropPhytosanitaryTreatments = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedTreatements = values as CropPhytosanitaryTreatment[];
  return Promise.all(
    selectedTreatements.map((cropTreatement: CropPhytosanitaryTreatment) => {
      const cropTreatementId = cropTreatement?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropPhytosanitaryTreatment/${cropTreatementId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteCropPhytosanitaryTreatmentsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteCropPhytosanitaryTreatmentsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.deleteCropPhytosanitaryTreatmentsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPostHarvestPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/postHarvestPhytosanitaryTreatment`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostPostHarvestPhytosanitaryTreatmentObjResponse);
};

const onPostPostHarvestPhytosanitaryTreatmentObjResponse = async (
  response: Response
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new PostHarvestPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.postPostHarvestPhytosanitaryTreatmentError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postPostHarvestPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const postHarvestTreatementData = values as PostHarvestPhytosanitaryTreatment;

  const postHarvestTreatement = await postPostHarvestPhytosanitaryTreatmentObj(
    props,
    postHarvestTreatementData.mapToApiModel()
  );

  if (
    postHarvestTreatement &&
    postHarvestTreatement.id &&
    postHarvestTreatementData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: postHarvestTreatementData.documents,
      entityClassName: "FBPostHarvestPhytosanitaryTreatment",
      entityClassId: postHarvestTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return postHarvestTreatement;
};

const putPostHarvestPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const postHarvestTreatement = values as PostHarvestPhytosanitaryTreatment;
  const postHarvestTreatementId = postHarvestTreatement?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/postHarvestPhytosanitaryTreatment/${postHarvestTreatementId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutPostHarvestPhytosanitaryTreatmentObjResponse({
      response,
      crudProps: props,
    })
  );
};

const onPutPostHarvestPhytosanitaryTreatmentObjResponse = async (
  props: CrudResponseProps
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new PostHarvestPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.putPostHarvestPhytosanitaryTreatmentError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const putPostHarvestPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<PostHarvestPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const postHarvestTreatementData = values as PostHarvestPhytosanitaryTreatment;

  const postHarvestTreatement = await putPostHarvestPhytosanitaryTreatmentObj(
    props,
    postHarvestTreatementData.mapToApiModel()
  );

  if (
    postHarvestTreatement &&
    postHarvestTreatement.id &&
    postHarvestTreatementData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: postHarvestTreatementData.documents,
      entityClassName: "FBPostHarvestPhytosanitaryTreatment",
      entityClassId: postHarvestTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    postHarvestTreatementData.lastSavedDocuments?.filter(
      (document: Document) =>
        !postHarvestTreatementData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return postHarvestTreatement;
};

const deletePostHarvestPhytosanitaryTreatments = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedTreatements = values as PostHarvestPhytosanitaryTreatment[];
  return Promise.all(
    selectedTreatements.map(
      (postHarvestTreatement: PostHarvestPhytosanitaryTreatment) => {
        const postHarvestTreatementId = postHarvestTreatement?.id;
        return fetch(
          `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/postHarvestPhytosanitaryTreatment/${postHarvestTreatementId}/disable`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
      }
    )
  ).then((response: Response[]) =>
    onDeletePostHarvestPhytosanitaryTreatmentsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeletePostHarvestPhytosanitaryTreatmentsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.deletePostHarvestPhytosanitaryTreatmentsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postFacilityPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facilityPhytosanitaryTreatment`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostFacilityPhytosanitaryTreatmentObjResponse);
};

const onPostFacilityPhytosanitaryTreatmentObjResponse = async (
  response: Response
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new FacilityPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.postFacilityPhytosanitaryTreatmentError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postFacilityPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const facilityTreatementData = values as FacilityPhytosanitaryTreatment;

  const facilityTreatement = await postFacilityPhytosanitaryTreatmentObj(
    props,
    facilityTreatementData.mapToApiModel()
  );

  if (
    facilityTreatement &&
    facilityTreatement.id &&
    facilityTreatementData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: facilityTreatementData.documents,
      entityClassName: "FBFacilityPhytosanitaryTreatment",
      entityClassId: facilityTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return facilityTreatement;
};

const putFacilityPhytosanitaryTreatmentObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const facilityTreatement = values as FacilityPhytosanitaryTreatment;
  const facilityTreatementId = facilityTreatement?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facilityPhytosanitaryTreatment/${facilityTreatementId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutFacilityPhytosanitaryTreatmentObjResponse({
      response,
      crudProps: props,
    })
  );
};

const onPutFacilityPhytosanitaryTreatmentObjResponse = async (
  props: CrudResponseProps
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new FacilityPhytosanitaryTreatment().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.putFacilityPhytosanitaryTreatmentError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const putFacilityPhytosanitaryTreatment = async (
  props: CrudProps
): Promise<FacilityPhytosanitaryTreatment | undefined> => {
  const { values } = props;
  const facilityTreatementData = values as FacilityPhytosanitaryTreatment;

  const facilityTreatement = await putFacilityPhytosanitaryTreatmentObj(
    props,
    facilityTreatementData.mapToApiModel()
  );

  if (
    facilityTreatement &&
    facilityTreatement.id &&
    facilityTreatementData.documents
  )
    await attachDocuments({
      crudProps: props,
      documents: facilityTreatementData.documents,
      entityClassName: "FBFacilityPhytosanitaryTreatment",
      entityClassId: facilityTreatement.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    facilityTreatementData.lastSavedDocuments?.filter(
      (document: Document) =>
        !facilityTreatementData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return facilityTreatement;
};

const deleteFacilityPhytosanitaryTreatments = async (
  props: CrudProps
): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedTreatements = values as FacilityPhytosanitaryTreatment[];
  return Promise.all(
    selectedTreatements.map(
      (facilityTreatement: FacilityPhytosanitaryTreatment) => {
        const facilityTreatementId = facilityTreatement?.id;
        return fetch(
          `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facilityPhytosanitaryTreatment/${facilityTreatementId}/disable`,
          {
            method: "PUT",
            headers: { Authorization: `Bearer ${idToken}` },
          }
        );
      }
    )
  ).then((response: Response[]) =>
    onDeleteFacilityPhytosanitaryTreatmentsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteFacilityPhytosanitaryTreatmentsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.deleteFacilityPhytosanitaryTreatmentsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postGipPreventiveActionObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<GipPreventiveAction | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/gipPreventiveAction`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostGipPreventiveActionObjResponse);
};

const onPostGipPreventiveActionObjResponse = async (
  response: Response
): Promise<GipPreventiveAction | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new GipPreventiveAction().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postGipPreventiveActionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postGipPreventiveAction = async (
  props: CrudProps
): Promise<GipPreventiveAction | undefined> => {
  const { values } = props;
  const preventiveActionData = values as GipPreventiveAction;

  const preventiveAction = await postGipPreventiveActionObj(
    props,
    preventiveActionData.mapToApiModel()
  );

  if (preventiveAction && preventiveAction.id && preventiveActionData.documents)
    await attachDocuments({
      crudProps: props,
      documents: preventiveActionData.documents,
      entityClassName: "FBGipPreventiveAction",
      entityClassId: preventiveAction.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return preventiveAction;
};

const putGipPreventiveActionObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<GipPreventiveAction | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const preventiveAction = values as GipPreventiveAction;
  const preventiveActionId = preventiveAction?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/gipPreventiveAction/${preventiveActionId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutGipPreventiveActionObjResponse({
      response,
      crudProps: props,
    })
  );
};

const onPutGipPreventiveActionObjResponse = async (
  props: CrudResponseProps
): Promise<GipPreventiveAction | undefined> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new GipPreventiveAction().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putGipPreventiveActionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putGipPreventiveAction = async (
  props: CrudProps
): Promise<GipPreventiveAction | undefined> => {
  const { values } = props;
  const preventiveActionData = values as GipPreventiveAction;

  const preventiveAction = await putGipPreventiveActionObj(
    props,
    preventiveActionData.mapToApiModel()
  );

  if (preventiveAction && preventiveAction.id && preventiveActionData.documents)
    await attachDocuments({
      crudProps: props,
      documents: preventiveActionData.documents,
      entityClassName: "FBGipPreventiveAction",
      entityClassId: preventiveAction.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments =
    preventiveActionData.lastSavedDocuments?.filter(
      (document: Document) =>
        !preventiveActionData.documents?.find((d) => d.id === document.id)
    );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return preventiveAction;
};

const deleteGipPreventiveActions = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedPreventiveActions = values as GipPreventiveAction[];
  return Promise.all(
    selectedPreventiveActions.map((preventiveAction: GipPreventiveAction) => {
      const preventiveActionId = preventiveAction?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/gipPreventiveAction/${preventiveActionId}/disable`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteGipPreventiveActionsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteGipPreventiveActionsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteGipPreventiveActionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postTreatedSeedObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<TreatedSeed | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const treatedSeed = values as TreatedSeed;
  const cropId = treatedSeed.crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/treatedSeed`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostTreatedSeedObjResponse);
};

const onPostTreatedSeedObjResponse = async (
  response: Response
): Promise<TreatedSeed | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new TreatedSeed().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.postTreatedSeedError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postTreatedSeed = async (
  props: CrudProps
): Promise<TreatedSeed | undefined> => {
  const { values } = props;
  const treatedSeedData = values as TreatedSeed;

  const treatedSeed = await postTreatedSeedObj(
    props,
    treatedSeedData.mapToApiModel()
  );

  if (treatedSeed && treatedSeed.id && treatedSeedData.documents)
    await attachDocuments({
      crudProps: props,
      documents: treatedSeedData.documents,
      entityClassName: "FBTreatedSeed",
      entityClassId: treatedSeed.id,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return treatedSeed;
};

const putTreatedSeedObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<TreatedSeed | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const treatedSeed = values as TreatedSeed;
  const treatedSeedId = treatedSeed?.id;
  const cropId = treatedSeed.crop?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/treatedSeed/${treatedSeedId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then((response: Response) =>
    onPutTreatedSeedObjResponse({
      response,
      crudProps: props,
    })
  );
};

const onPutTreatedSeedObjResponse = async (
  props: CrudResponseProps
): Promise<TreatedSeed | undefined> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Mapping
    return new TreatedSeed().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.putTreatedSeedError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putTreatedSeed = async (
  props: CrudProps
): Promise<TreatedSeed | undefined> => {
  const { values } = props;
  const treatedSeedData = values as TreatedSeed;

  const treatedSeed = await putTreatedSeedObj(
    props,
    treatedSeedData.mapToApiModel()
  );

  if (treatedSeed && treatedSeed.id && treatedSeedData.documents)
    await attachDocuments({
      crudProps: props,
      documents: treatedSeedData.documents,
      entityClassName: "FBTreatedSeed",
      entityClassId: treatedSeed.id,
    }).catch(() => {
      throw FILE_ERROR;
    });

  const uncategorizedDocuments = treatedSeedData.lastSavedDocuments?.filter(
    (document: Document) =>
      !treatedSeedData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {});

  return treatedSeed;
};

const deleteTreatedSeeds = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedTreatments = values as TreatedSeed[];
  return Promise.all(
    selectedTreatments.map((treatedSeed: TreatedSeed) => {
      const treatedSeedId = treatedSeed?.id;
      const cropId = treatedSeed.crop?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/treatedSeed/${treatedSeedId}`,
        {
          method: "DELETE",
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteTreatedSeedsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteTreatedSeedsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteTreatedSeedsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPersonLanguage = async (
  props: CrudProps
): Promise<Person | undefined> => {
  const { idToken, values } = props;
  const languageCode = values as string;
  return fetch(
    `${process.env.REACT_APP_API_URL}/person/language?language=${languageCode}`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onPutPersonLanguageResponse);
};

const onPutPersonLanguageResponse = async (
  response: Response
): Promise<Person | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Person(data);
  } else {
    throw new Error(i18n.t("apiResponses.updatePersonLanguageError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteOffers = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken } = props;
  const selectedOffers = values as PersonMessage[];
  if (selectedOffers.length === 0) return Promise.resolve();
  return Promise.all(
    selectedOffers.map((offer: PersonMessage) => {
      const offerId = offer?.id;
      return fetch(`${process.env.REACT_APP_API_URL}/message/${offerId}`, {
        method: "DELETE",
        headers: { Authorization: `Bearer ${idToken}` },
      });
    })
  ).then((response: Response[]) =>
    onDeleteOffersResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteOffersResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response } = props;
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deleteOfferError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const setOfferAsRead = async (
  props: CrudProps
): Promise<PersonMessage | undefined> => {
  const { idToken, values } = props;
  const personMessage = values as PersonMessage;
  const messageId = personMessage?.id;
  return fetch(`${process.env.REACT_APP_API_URL}/message/${messageId}/read`, {
    method: "PUT",
    headers: { Authorization: `Bearer ${idToken}` },
  }).then(onSetOfferAsReadResponse);
};

const onSetOfferAsReadResponse = async (
  response: Response
): Promise<PersonMessage | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PersonMessage().mapToClass(data);
  } else return undefined;
};

const changePhytoRecipeStatus = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { idToken, selectedABAccount, values } = props;
  const statusValues = values as ChangePhytoRecipeStatusInterface;
  const agroBusinessId = selectedABAccount?.agroBusiness?.id;
  const phytoRecipeId = statusValues.id;
  const status = statusValues.status;

  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${phytoRecipeId}/changeStatus`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    }
  ).then(onChangePhytoRecipeStatusResponse);
};

const onChangePhytoRecipeStatusResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.changePhytoRecipeStatusError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytoRecipeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytoRecipe | undefined> => {
  const { idToken, agroBusinessId } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostPhytoRecipeObjResponse);
};

const onPostPhytoRecipeObjResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe().mapToClass(data);
  } else if (data?.message?.includes("is not a phyto advisor")) {
    throw new Error(
      i18n.t("apiResponses.postPhytoRecipeNotPhytoAdvisorError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  } else {
    throw new Error(i18n.t("apiResponses.postPhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPhytoRecipeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytoRecipe | undefined> => {
  const { idToken, agroBusinessId, values } = props;
  const recipe = values as PhytoRecipe;
  const recipeId = recipe?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${recipeId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedValues),
    }
  ).then(onPutPhytoRecipeObjResponse);
};

const onPutPhytoRecipeObjResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe().mapToClass(data);
  } else if (data?.message?.includes("is not a phyto advisor")) {
    throw new Error(
      i18n.t("apiResponses.postPhytoRecipeNotPhytoAdvisorError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  } else {
    throw new Error(i18n.t("apiResponses.putPhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytoRecipe = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { values } = props;
  const recipeData = values as PhytoRecipe;

  const recipe = await postPhytoRecipeObj(props, recipeData.mapToApiModel());
  //TODO:if (recipe && recipe.id && recipeData.documents)
  if (recipe && recipe.id)
    await attachDocuments({
      crudProps: props,
      //TODO:documents: recipeData.documents,
      documents: [],
      entityClassName: "FBPhytoRecipe",
      entityClassId: recipe.id,
      documentDate: recipe.date,
    }).catch(() => {
      throw FILE_ERROR;
    });
  return recipe;
};

const putPhytoRecipe = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { values } = props;
  const recipeData = values as PhytoRecipe;

  const recipe = await putPhytoRecipeObj(props, recipeData.mapToApiModel());

  //TODO:if (recipe && recipe.id && recipeData.documents)
  if (recipe && recipe.id)
    await attachDocuments({
      crudProps: props,
      //TODO: documents: recipeData.documents,
      documents: [],
      entityClassName: "FBPhytoRecipe",
      entityClassId: recipe.id,
      documentDate: recipe.date,
    }).catch(() => {
      throw FILE_ERROR;
    });

  /* TODO:  const uncategorizedDocuments = recipeData.lastSavedDocuments?.filter(
    (document: Document) =>
      !recipeData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {}); */

  return recipe;
};

const deletePhytoRecipe = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const { idToken, agroBusinessId } = props;
  const selectedRecipe = values as PhytoRecipe;
  const recipeId = selectedRecipe?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${recipeId}/disable`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onDeletePhytoRecipeResponse);
};

const onDeletePhytoRecipeResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deletePhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const markNotificationAsRead = async (
  props: CrudProps
): Promise<Notification | undefined> => {
  const { idToken, values } = props;
  const notification = values as Notification;
  const notificationId = notification?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/notification/${notificationId}/read`,
    {
      method: "PUT",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onMarkNotificationAsReadResponse);
};

const onMarkNotificationAsReadResponse = async (
  response: Response
): Promise<Notification | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Notification().mapToClass(data);
  } else return undefined;
};

export const exportCUEData = async (props: CrudProps): Promise<string> => {
  const { idToken, agroBusinessId, values } = props;
  const exportData = values as CueExportData;
  const startDate = exportData.startDate || "";
  const endDate = exportData.endDate || "";
  const format = exportData.format?.toLowerCase() || "";
  const exportDataAPIModel = exportData.mapToApiModel();
  const partitionId = exportDataAPIModel.partitionId || "";
  const cropsIds = exportDataAPIModel.cropIds?.join(",") || "";
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/exportCue?startDate=${startDate}&endDate=${endDate}&format=${format}&partitionId=${partitionId}&cropsIds=${cropsIds}`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onExportCUEDataResponse);
};

const onExportCUEDataResponse = async (response: Response): Promise<string> => {
  if (response.status === 200) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
  } else {
    throw new Error(i18n.t("apiResponses.exportCUEDataError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};

const siexSyncRequest = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { idToken, selectedABAccount } = props;
  const agroBusinessAccountId = selectedABAccount?.id;

  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/siex/wantSync`,
    {
      method: "POST",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onSiexSyncRequestResponse);
};

const onSiexSyncRequestResponse = async (
  response: Response
): Promise<AgroBusinessAccount | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new AgroBusinessAccount().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.siexWantSyncError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};
