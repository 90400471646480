import Country from "../../address/Country";
import Municipality from "../../address/Municipality";

class FBAddress {
  id?: number;
  addressLine1?: string;
  addressLine2?: string;
  zipCode?: string;
  municipality?: Municipality;
  country?: Country;
  municipalityName?: string;
  provinceName?: string;
  phone1?: string;
  phone2?: string;
  email?: string;

  constructor(
    id?: number,
    addressLine1?: string,
    addressLine2?: string,
    zipCode?: string,
    municipality?: Municipality,
    country?: Country,
    municipalityName?: string,
    provinceName?: string,
    phone1?: string,
    phone2?: string,
    email?: string
  ) {
    this.id = id;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.zipCode = zipCode;
    this.municipality = municipality;
    this.country = country;
    this.municipalityName = municipalityName;
    this.provinceName = provinceName;
    this.phone1 = phone1;
    this.phone2 = phone2;
    this.email = email;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.addressLine1 = obj?.addressLine1;
    this.addressLine2 = obj?.addressLine2;
    this.zipCode = obj?.zipCode;
    this.municipality = new Municipality().mapToClass(obj?.municipality);
    this.country = new Country().mapToClass(obj?.country);
    this.municipalityName = obj?.municipalityName;
    this.provinceName = obj?.provinceName;
    this.phone1 = obj?.phone1;
    this.phone2 = obj?.phone2;
    this.email = obj?.email;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.municipality = this.municipality?.mapToApiModel();
    obj.country = this.country?.mapToApiModel();
    return obj;
  }
}

export default FBAddress;
