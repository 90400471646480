import Country from "../../address/Country";

class FBProvince {
  id?: number;
  country?: Country; //*
  code?: string; //*
  name?: string; //*
  sigpacCode?: string; //*

  constructor(
    id?: number,
    country?: Country,
    code?: string,
    name?: string,
    sigpacCode?: string
  ) {
    this.id = id;
    this.country = country;
    this.code = code;
    this.name = name;
    this.sigpacCode = sigpacCode;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.country = new Country().mapToClass(obj?.country);
    this.code = obj?.code;
    this.name = obj?.name;
    this.sigpacCode = obj?.sigpacCode;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.country = this.country?.mapToApiModel();
    return obj;
  }
}

export default FBProvince;
