import { useSession } from "../../hooks/useSession";

import i18n from "../../config/configI18n";

interface Props {
  isVisible: boolean;
  style?: React.CSSProperties;
}
const AdvisorAccountLabel = (props: Props) => {
  const { isVisible, style } = props;

  const { selectedABAccount } = useSession();

  if (!isVisible || !selectedABAccount?.cueAccount?.advisor) return null;

  return (
    <div className="advisor-account-label" style={style}>
      <span className="title">
        {`${i18n.t("components.advisorAccountLabel.title")} ${
          selectedABAccount?.cueAccount?.name
        }`}
      </span>
    </div>
  );
};

export default AdvisorAccountLabel;
