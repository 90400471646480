import { useMemo, useState } from "react";
import moment from "moment";
import i18n from "../../config/configI18n";

import { Button, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { AutoFixHigh, InfoOutlined } from "@mui/icons-material";

import { useSession } from "../../hooks/useSession";
import useCrud from "../../hooks/useCrud";

import AgroBusinessAccount from "../../models/account/AgroBusinessAccount";

interface Props {
  adviceText?: boolean;
}
const SiexBanner = (props: Props) => {
  const { adviceText = false } = props;

  const { selectedABAccount, isUpdatingFromREA, changeSelectedABA } =
    useSession();

  const siexEnabled = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity || false,
    [selectedABAccount]
  );

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const siexSyncRequestMutation = useCrud({
    key: "siexSyncRequest",
    onSuccess: (data: AgroBusinessAccount) => {
      changeSelectedABA(data);
    },
  });

  const openTooltip = () => setTooltipOpen(true);
  const closeTooltip = () => setTooltipOpen(false);

  const handleClick = () => {
    siexSyncRequestMutation.mutate({});
  };

  // Not SIEX Legal Context
  if (!selectedABAccount?.siexEnabled) return null;

  // SIEX requested
  if (!siexEnabled && selectedABAccount?.userRequestSiexSync)
    return (
      <div className="siex-banner siex-banner__requested">
        <div className="content">
          <AutoFixHigh className="icon__requested" />

          <div className="body">
            <h1>{i18n.t("components.siexBanner.titleSyncRequested")}</h1>
            <h2>{i18n.t("components.siexBanner.subtitleSyncRequested")}</h2>
            <h2>{i18n.t("components.siexBanner.titleNotAvailable")}</h2>
          </div>
          {siexSyncRequestMutation.isLoading && <CircularProgress size={20} />}
        </div>
      </div>
    );

  return (
    <div className="siex-banner">
      <div className="content">
        <AutoFixHigh className="icon" />

        <div className="body">
          <h1>
            {i18n.t(
              siexEnabled
                ? "components.siexBanner.titleAvailable"
                : "components.siexBanner.titleNotAvailable"
            )}
          </h1>
          {!siexEnabled && (
            <h2>{i18n.t("components.siexBanner.subscribeNotice")}</h2>
          )}
          {siexEnabled && (
            <h2>
              {i18n.t("components.siexBanner.createOrUpdateRemember")}{" "}
              <Tooltip
                open={tooltipOpen}
                onClose={closeTooltip}
                disableFocusListener
                disableTouchListener
                title={i18n.t("components.siexBanner.createRememberTooltip")}
              >
                <IconButton
                  sx={{ m: 0, p: 0 }}
                  onMouseEnter={openTooltip}
                  onMouseLeave={closeTooltip}
                >
                  <InfoOutlined sx={{ fontSize: 18 }} />
                </IconButton>
              </Tooltip>
            </h2>
          )}
          {siexEnabled && isUpdatingFromREA && (
            <h3>{i18n.t("components.siexBanner.downloadingLabel")}</h3>
          )}
          {siexEnabled &&
            !isUpdatingFromREA &&
            selectedABAccount?.agroBusiness?.siexUpdatedOnMillis && (
              <h3>{`${i18n.t(
                "components.siexBanner.lastDownloadLabel"
              )} ${moment(
                selectedABAccount.agroBusiness.siexUpdatedOnMillis
              ).format("DD MMMM HH:mm")}`}</h3>
            )}
          {!siexEnabled && adviceText && (
            <h2>{i18n.t("components.siexBanner.createNotice")}</h2>
          )}
        </div>

        {!siexEnabled && (
          <Button variant="text" className="btn" onClick={handleClick}>
            {i18n.t("components.siexBanner.actionBtnLabel_v2")}
          </Button>
        )}
        {siexEnabled && isUpdatingFromREA && <CircularProgress size={20} />}
      </div>
    </div>
  );
};

export default SiexBanner;
