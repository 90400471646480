import { useState } from "react";
import i18n from "../../config/configI18n";

import { Link, LinkOff } from "@mui/icons-material";

import { TableBrioCard } from "../BrioCard";

import Document from "../../models/files/Document";
import { Column } from "../../models/Generic";
import RightDrawer from "../RightDrawer";
import DialogFile from "../dialogs/DialogFile";

import { FormMode } from "../../constants/enums";
import { SnackbarInterface } from "../../constants/interfaces";

interface Props {
  open: boolean;
  files: Document[];
  drawerSubtitle?: string;
  onChangeFiles: (files: Document[]) => void;
  onClose?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const FilesForm = (props: Props) => {
  const fileColumns: Column[] = [
    {
      id: "avatar",
      label: i18n.t("components.filesForm.column.avatar"),
      numeric: false,
      valueLabel: "listItem",
      primaryTextFieldName: "name",
      secondaryTextFieldName: "description",
      avatarSrcFieldName: "thumbnailLink",
    },
    {
      id: "date",
      label: i18n.t("components.filesForm.column.date"),
      numeric: false,
    },
  ];

  const { open, files, drawerSubtitle, onChangeFiles, onClose, onError } =
    props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedFile, setSelectedFile] = useState<Document | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedFile(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = files.find((file: Document) => file.idx === idx);
    if (selected) {
      setSelectedFile(selected);
      openDialog();
    }
  };

  // Set remove flag to true in selected files to remove
  const handleClickDelete = (idxs: number[]) => {
    onChangeFiles(files.filter((file) => !idxs.includes(file?.idx || -1)));
    closeDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (selected: Document) => {
    if (formMode === FormMode.CREATE) {
      onChangeFiles([...files, selected]);
    } else if (formMode === FormMode.EDIT) {
      onChangeFiles(
        files.map((file) => (file.idx === selected.idx ? selected : file))
      );
    }
    closeDialog();
  };

  const handleClickCell = async (
    e: React.MouseEvent,
    columnId: string,
    row: any
  ) => {
    e.stopPropagation(); // Avoid click in parent row

    if (columnId === "avatar") {
      // Find document
      const doc = row as Document;
      if (doc?.documentLink)
        window.open(doc.documentLink, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <RightDrawer
      title={i18n.t("components.filesForm.drawerTitle")}
      subtitle={drawerSubtitle}
      titleBtn={i18n.t("components.filesForm.drawerBtnText")}
      isOpen={open}
      actionBtn={false}
      onClose={onClose}
    >
      <TableBrioCard
        idx="idx"
        title={i18n.t("components.filesForm.tableTitle")}
        headerText={i18n.t("components.filesForm.headerText")}
        btnText={i18n.t("components.filesForm.btnText")}
        btnIcon={<Link />}
        colums={fileColumns}
        rows={files}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        disableIcon={<LinkOff />}
        disableTooltip={i18n.t("components.filesForm.unattachFiles")}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
        onClickCell={handleClickCell}
      />
      <DialogFile
        open={isOpenDialog}
        selected={selectedFile}
        files={files}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
        onError={onError}
      />
    </RightDrawer>
  );
};

export default FilesForm;
