import { useState, useMemo } from "react";
import { useFormik } from "formik";
import moment from "moment";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl, CircularProgress } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import FormikTextField from "../../components/elements/FormikTextField";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import DialogABClassType from "../../components/dialogs/DialogABClassType";
import DialogABWineArea from "../../components/dialogs/DialogABWineArea";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";

import { Column } from "../../models/Generic";
import AgroBusiness from "../../models/agrobusiness/AgroBusiness";
import AgroBusinessClassification from "../../models/agrobusiness/AgroBusinessClassification";
import AgroBusinessWineInfo from "../../models/agrobusiness/AgroBusinessWineInfo";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";

const ExploitationDataScreen = () => {
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  const handleOnFormSuccess = (msg: string) => {
    setSnackbarMsg({
      severity: "success",
      message: msg,
    });
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <SiexBanner adviceText />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <DataForm onSuccess={handleOnFormSuccess} onError={handleOnFormError} />
      <ClasificationForm
        onSuccess={handleOnFormSuccess}
        onError={handleOnFormError}
      />
      <WinerySurfacesForm
        onSuccess={handleOnFormSuccess}
        onError={handleOnFormError}
      />
    </ScreenContentLayout>
  );
};

export default ExploitationDataScreen;

interface DataFormProps {
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const DataForm = (props: DataFormProps) => {
  const { onSuccess, onError } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const handleSubmit = (values: AgroBusiness) => {
    agroBusinessEditMutation.mutate(values);
  };

  const formik = useFormik({
    initialValues: new AgroBusiness(),
    onSubmit: handleSubmit,
  });

  const { isFetching } = useFetch<AgroBusiness>({
    queryKey: ["agrobusinessById", selectedABAccount?.agroBusiness?.id],
    enabled: !!selectedABAccount?.agroBusiness?.id,
    onSuccess: (data: AgroBusiness) => formik.setValues(data),
    onError,
  });

  const agroBusinessEditMutation = useCrud<AgroBusiness>({
    key: "putAgroBusiness",
    values: formik.values,
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.updateSuccess")),
    onError,
  });

  const handleClickSave = () => {
    formik.submitForm();
  };

  return (
    <BrioCard
      title={i18n.t("exploitationData.dataForm.title")}
      actionBtn={!isSiexActive}
      rightBtnTitle={i18n.t("exploitationData.dataForm.rightBtnTitle")}
      rightIcon={
        (isFetching || agroBusinessEditMutation.isLoading) && (
          <CircularProgress size={20} />
        )
      }
      rightBtnDisabled={isFetching || agroBusinessEditMutation.isLoading}
      addBtnVariant="contained"
      defaultExpanded
      disableAccordion
      onClickAdd={handleClickSave}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="regionRegisterId"
            label={i18n.t("exploitationData.dataForm.regionRegisterId")}
            placeholder={i18n.t(
              "exploitationData.dataForm.regionRegisterIdPlaceholder"
            )}
            disabled={isSiexActive}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            openTo="year"
            label={i18n.t("exploitationData.dataForm.registerDate")}
            value={
              formik.values.registerDate
                ? moment(formik.values.registerDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue("registerDate", date?.format("YYYY-MM-DD"))
            }
            disabled={isSiexActive}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="autocontrol"
            primaryText={i18n.t("exploitationData.dataForm.autocontrol")}
            secondaryText={i18n.t(
              "exploitationData.dataForm.autocontrolSubtitle"
            )}
            disabled={isSiexActive}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="directSale"
            primaryText={i18n.t("exploitationData.dataForm.directSale")}
            secondaryText={i18n.t(
              "exploitationData.dataForm.directSaleSubtitle"
            )}
            disabled={isSiexActive}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const ClasificationForm = (props: DataFormProps) => {
  const clasifColumns: Column[] = [
    {
      id: "classificationType.name",
      label: i18n.t("exploitationData.clasificationForm.column.name"),
      numeric: false,
    },
    {
      id: "startDate",
      label: i18n.t("exploitationData.clasificationForm.column.startDate"),
      numeric: false,
    },
  ];

  const { onSuccess, onError } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedClassification, setSelectedClassification] =
    useState<AgroBusinessClassification | null>(null);

  const { data: agroBusinessClassifications, isFetching } = useFetch<
    AgroBusinessClassification[]
  >({
    queryKey: [
      "agroBusinessClassifications",
      selectedABAccount?.agroBusiness?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedClassification(null);
  };

  const abClassificationEditMutation = useCrud<AgroBusinessClassification>({
    key: "putAgroBusinessClassification",
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.updateSuccess")),
    onError,
  });

  const abClassificationsDeleteMutation = useCrud({
    key: "deleteAgroBusinessClassifications",
    values: agroBusinessClassifications?.filter(
      (abClassification: AgroBusinessClassification) =>
        selectedRowIds.includes(abClassification?.id || 0)
    ),
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.deleteSuccess")),
    onError,
  });

  const handleClickAdd = () => {
    openDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (selected: AgroBusinessClassification) => {
    abClassificationEditMutation.mutate(selected);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    const selected = agroBusinessClassifications?.find(
      (abClassification: AgroBusinessClassification) =>
        abClassification.id === id
    );
    if (selected) {
      setSelectedClassification(selected);
      openDialog();
    }
  };

  const handleClickDelete = (ids: number[]) => {
    abClassificationsDeleteMutation.mutate(selectedRowIds);
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        title={i18n.t("exploitationData.clasificationForm.tableTitle")}
        headerText={i18n.t("exploitationData.clasificationForm.headerText")}
        colums={clasifColumns}
        rows={agroBusinessClassifications || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        addBtnVariant="contained"
        hideBtn={isSiexActive}
        disabled={isSiexActive}
        isLoading={isFetching || abClassificationEditMutation.isLoading}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogABClassType
        open={isOpenDialog}
        selected={selectedClassification}
        classifications={agroBusinessClassifications || []}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

const WinerySurfacesForm = (props: DataFormProps) => {
  const wineryColumns: Column[] = [
    {
      id: "potentialAreaType.name",
      label: i18n.t("exploitationData.wineryForm.column.potentialAreaType"),
      numeric: false,
    },
    {
      id: "potentialArea",
      label: i18n.t("exploitationData.wineryForm.column.potentialArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
    {
      id: "province.name",
      label: i18n.t("exploitationData.wineryForm.column.province"),
      numeric: false,
    },
    {
      id: "dopProtectedMarkName.name",
      label: i18n.t("exploitationData.wineryForm.column.dopProtectedMarkName"),
      numeric: false,
    },
  ];

  const { onSuccess, onError } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedWineInfo, setSelectedWineInfo] =
    useState<AgroBusinessWineInfo | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const { data: agroBusinessWinesInfo, isFetching } = useFetch<
    AgroBusinessWineInfo[]
  >({
    queryKey: ["agroBusinessWinesInfo", selectedABAccount?.agroBusiness?.id],
    enabled: !!selectedABAccount,
  });

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedWineInfo(null);
  };

  const abWineInfoCreateMutation = useCrud<AgroBusinessWineInfo>({
    key: "postAgroBusinessWineInfo",
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.createSuccess")),
    onError,
  });

  const abWineInfoEditMutation = useCrud<AgroBusinessWineInfo>({
    key: "putAgroBusinessWineInfo",
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.updateSuccess")),
    onError,
  });

  const abWineInfoDeleteMutation = useCrud({
    key: "deleteAgroBusinessWinesInfo",
    values: agroBusinessWinesInfo?.filter((abWineInfo: AgroBusinessWineInfo) =>
      selectedRowIds.includes(abWineInfo?.id || 0)
    ),
    onSuccess: () =>
      onSuccess && onSuccess(i18n.t("apiResponses.deleteSuccess")),
    onError,
  });

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (selected: AgroBusinessWineInfo) => {
    if (formMode === FormMode.CREATE) abWineInfoCreateMutation.mutate(selected);
    else if (formMode === FormMode.EDIT)
      abWineInfoEditMutation.mutate(selected);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    setFormMode(FormMode.EDIT);
    const selected = agroBusinessWinesInfo?.find(
      (abWineInfo: AgroBusinessWineInfo) => abWineInfo.id === id
    );
    if (selected) {
      setSelectedWineInfo(selected);
      openDialog();
    }
  };

  const handleClickDelete = (ids: number[]) => {
    abWineInfoDeleteMutation.mutate(selectedRowIds);
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        title={i18n.t("exploitationData.wineryForm.tableTitle")}
        headerText={i18n.t("exploitationData.wineryForm.headerText")}
        colums={wineryColumns}
        rows={agroBusinessWinesInfo || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        hideBtn={isSiexActive}
        disabled={isSiexActive}
        addBtnVariant="contained"
        isLoading={
          isFetching ||
          abWineInfoCreateMutation.isLoading ||
          abWineInfoEditMutation.isLoading
        }
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />

      <DialogABWineArea
        open={isOpenDialog}
        selected={selectedWineInfo}
        winesInfo={agroBusinessWinesInfo || []}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};
