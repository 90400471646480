import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import GipPreventiveActionType from "../../catalogue/GipPreventiveActionType";
import TreatmentEfficacy from "../../catalogue/TreatmentEfficacy";

class FBGipPreventiveAction {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  actionType?: GipPreventiveActionType; //*
  effectiveness?: TreatmentEfficacy; //*
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    actionType?: GipPreventiveActionType,
    effectiveness?: TreatmentEfficacy,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.actionType = actionType;
    this.effectiveness = effectiveness;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.actionType = new GipPreventiveActionType().mapToClass(obj?.actionType);
    this.effectiveness = new TreatmentEfficacy().mapToClass(obj?.effectiveness);
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.actionType = this.actionType?.mapToApiModel();
    obj.effectiveness = this.effectiveness?.mapToApiModel();
    return obj;
  }
}

export default FBGipPreventiveAction;
