import FBVariety from "../_apiModels/catalogue/FBVariety";

class CropProductVariety extends FBVariety {
  constructor() {
    super();
  }

  mapToClass(obj: any): CropProductVariety | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default CropProductVariety;
