import { useEffect, useState } from "react";
import { FormikProps, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import i18n from "../../config/configI18n";

import {
  useTheme,
  useMediaQuery,
  Box,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  createFilterOptions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FileDownload, Grass } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import TestBanner from "../../components/banners/TestBanner";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import { BrioCard } from "../../components/BrioCard";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";

import CueExportData from "../../models/cueExport/CueExportData";
import Crop from "../../models/crop/Crop";
import AgroBusinessPartition from "../../models/agrobusiness/AgroBusinessPartition";

import { useAuth } from "../../hooks/useAuth";
import useFetch from "../../hooks/useFetch";
import { useSession } from "../../hooks/useSession";

import { exportCUEData } from "../../hooks/useCrud";
import { SnackbarInterface } from "../../constants/interfaces";
import { ExportDataFormat } from "../../constants/enums";

const ExploitationExportDataScreen = () => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("medium"));

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  const formik = useFormik({
    initialValues: new CueExportData(),
    onSubmit: () => {},
  });

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <Box sx={{ mt: isLargeScreen ? "8px" : 0 }}>
        <ExportDataForm formik={formik} onError={setSnackbarMsg} />
      </Box>
    </ScreenContentLayout>
  );
};

export default ExploitationExportDataScreen;

interface FormProps {
  formik: FormikProps<CueExportData>;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const filter = createFilterOptions();
const ExportDataForm = (props: FormProps) => {
  const { formik, onError } = props;

  const { idToken } = useAuth();
  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: crops, isFetching: isFetchingCrops } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  useEffect(() => {
    if (!formik.values.partitionId) {
      formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
  }, [selectedABPartition]);

  useEffect(() => {
    if (!formik.values.format) {
      formik.setFieldValue("format", ExportDataFormat.PDF);
    }
  }, []);

  const handleChangeStartDate = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD");
    formik.setFieldValue("startDate", dateStr);
    formik.setFieldValue("endDate", dateStr);
  };

  const handleChangeFormat = (event: SelectChangeEvent) => {
    formik.setFieldValue("format", event.target.value);
  };

  const handleClickExport = async () => {
    try {
      setIsLoading(true);
      const agroBusinessId = selectedABAccount?.agroBusiness?.id;
      let newWindow: Window | null = null;
      if (formik.values.format === ExportDataFormat.PDF) {
        newWindow = window.open(
          i18n.language.includes("es")
            ? "../cue-export_es.html"
            : "../cue-export_en.html",
          "_blank"
        );
      }

      const url = await exportCUEData({
        queryClient,
        idToken,
        agroBusinessId,
        values: formik.values,
      });

      if (formik.values.format === ExportDataFormat.PDF && newWindow?.location)
        newWindow.location.replace(url);
      else window.open(url, "_blank");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      onError &&
        onError({
          message: i18n.t("apiResponses.exportCUEDataError"),
          severity: "error",
        });
    }
  };

  const partitionsFilter = (options: AgroBusinessPartition[], params: any) => {
    const filtered = filter(options, params);
    // All partitions option
    const allPartitionsOption = new AgroBusinessPartition().mapToClass({
      id: -1,
      name: i18n.t("export.exportDataForm.allPartitionsOptionLabel"),
    });
    if (allPartitionsOption) filtered.unshift(allPartitionsOption);
    return filtered;
  };

  return (
    <BrioCard
      title={i18n.t("export.exportDataForm.title")}
      headerText={i18n.t("export.exportDataForm.exportDataLabel")}
      defaultExpanded
      disableAccordion
      actionBtn
      rightBtnTitle={i18n.t("export.exportDataForm.exportBtnLabel")}
      addBtnVariant="contained"
      rightIcon={<FileDownload sx={{ color: "white" }} />}
      rightBtnDisabled={isLoading}
      onClickAdd={handleClickExport}
    >
      <FormGroup className="form-group" sx={{ mt: 2 }}>
        <FormControl variant="outlined" className="form-control-row">
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={i18n.t("export.exportDataForm.startDateLabel")}
            value={
              formik.values?.startDate
                ? moment(formik.values.startDate, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeStartDate}
          />
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={i18n.t("export.exportDataForm.endDateLabel")}
            value={
              formik.values?.endDate
                ? moment(formik.values.endDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue("endDate", date?.format("YYYY-MM-DD"))
            }
          />
        </FormControl>
        {agroBusinessPartitions?.length > 0 && (
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="agroBusinessPartition"
              options={agroBusinessPartitions}
              filterOptions={partitionsFilter}
              label={i18n.t("export.exportDataForm.partitionLabel")}
              optionLabelFieldName="name"
            />
          </FormControl>
        )}
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="crops"
            label={i18n.t("export.exportDataForm.cropsLabel")}
            options={crops || []}
            optionLabelFieldName="productNameWithVarieties"
            multiple
            startIcon={Grass}
            loading={isFetchingCrops}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <InputLabel>{i18n.t("export.exportDataForm.formatLabel")}</InputLabel>
          <Select
            label={i18n.t("export.exportDataForm.formatLabel")}
            value={formik.values.format || ""}
            onChange={handleChangeFormat}
          >
            {Object.values(ExportDataFormat).map((option) => (
              <MenuItem key={option} value={option}>
                <Typography variant="subtitle1" overflow="auto">
                  {option}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>
      <LoadingButton
        variant="contained"
        sx={{ mt: 4 }}
        size="large"
        startIcon={<FileDownload sx={{ color: "white" }} />}
        loading={isLoading}
        onClick={handleClickExport}
      >
        {i18n.t("export.exportDataForm.exportBtnLabel")}
      </LoadingButton>
    </BrioCard>
  );
};
