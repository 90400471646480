import { useEffect, useState } from "react";
import { FormikTouched, useFormik } from "formik";
import * as Yup from "yup";
import i18n from "../../config/configI18n";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import AlertEmptyList from "../AlertEmptyList";
import FormAlertDialog from "./FormAlertDialog";

import FertilizerStockMovement from "../../models/fertiliz/FertilizerStockMovement";
import FertilizerProduct from "../../models/fertiliz/FertilizerProduct";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { getMaxValueOfList } from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode, FertilizerStockMovementType } from "../../constants/enums";
import { FERTILIZER_STOCK_MOVEMENT_TYPES } from "../../providers/YupProvider";

interface Props {
  open: boolean;
  movementType: FertilizerStockMovementType;
  title?: string;
  body?: string;
  onClose: () => void;
  onConfirm: (fertilizerStockMovement: FertilizerStockMovement) => void;
  onError?: (snackbarError: SnackbarInterface) => void;
  onAddNew?: () => void;
  selected?: FertilizerStockMovement | null;
  stockMovements?: FertilizerStockMovement[];
}
const DialogFertilizerProduct = (props: Props) => {
  const YupValidatorSchema = Yup.object().shape({
    product: Yup.object().required(),
    quantity: Yup.number().required(),
  });

  const {
    open,
    stockMovements,
    onClose,
    onConfirm,
    onError,
    onAddNew,
    selected,
  } = props;

  const { selectedABAccount } = useSession();

  const [isOpenEmptyPriceAlertDialog, setIsOpenEmptyPriceAlertDialog] =
    useState<boolean>(false);

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const openEmptyPriceAlertDialog = () => setIsOpenEmptyPriceAlertDialog(true);
  const cancelEmptyPriceAlertDialog = () =>
    setIsOpenEmptyPriceAlertDialog(false);

  const handleSubmit = (values: FertilizerStockMovement) => {
    // Find the max idx of the array
    const maxIdx = getMaxValueOfList(stockMovements, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    // Set purchase as movement type
    values.movementType = FERTILIZER_STOCK_MOVEMENT_TYPES.find(
      (movementType) => movementType.code === props.movementType
    );
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FertilizerStockMovement(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  const { data: fertilizerProducts, isFetching } = useFetch<
    FertilizerProduct[]
  >({
    queryKey: ["fertilizerProducts", selectedABAccount?.id],
    enabled: !!selectedABAccount,
    onError: onError && onError,
  });

  const checkConfirm = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      formik.setTouched(errors as FormikTouched<any>);
    } else if (!formik.values.priceByUnit || formik.values.priceByUnit <= 0)
      openEmptyPriceAlertDialog();
    else confirmDialog();
  };

  const confirmDialog = () => {
    cancelEmptyPriceAlertDialog();
    formik.handleSubmit();
  };

  const handleClickNewOption = () => {
    onAddNew && onAddNew();
  };

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const quantity = Number(value);
    formik.setFieldValue("quantity", value.length === 0 ? "" : quantity);
    if (formik.values.priceByUnit && formik.values.priceByUnit > 0)
      formik.setFieldValue(
        "totalPrice",
        Math.round(formik.values.priceByUnit * quantity * 100) / 100
      );
    else if (formik.values.totalPrice && formik.values.totalPrice > 0) {
      const priceByUnit =
        Math.round((formik.values.totalPrice / quantity) * 100) / 100;
      formik.setFieldValue("priceByUnit", priceByUnit);
      formik.setFieldValue(
        "priceFormatted",
        formik.values.getPriceFormatted(priceByUnit)
      );
    }
  };

  const handleChangePriceByUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const priceByUnit = Number(value);
    formik.setFieldValue("priceByUnit", value.length === 0 ? "" : priceByUnit);
    formik.setFieldValue(
      "priceFormatted",
      formik.values.getPriceFormatted(priceByUnit)
    );
    if (formik.values.quantity)
      formik.setFieldValue(
        "totalPrice",
        Math.round(priceByUnit * formik.values.quantity * 100) / 100
      );
  };

  const handleChangeTotalPrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const totalPrice = Number(value);
    formik.setFieldValue("totalPrice", value.length === 0 ? "" : totalPrice);
    if (formik.values.quantity) {
      const priceByUnit =
        Math.round((totalPrice / formik.values.quantity) * 100) / 100;
      formik.setFieldValue("priceByUnit", priceByUnit);
      formik.setFieldValue(
        "priceFormatted",
        formik.values.getPriceFormatted(priceByUnit)
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">{props.title}</DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">{props.body}</DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetching && fertilizerProducts?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewOption}>
                {i18n.t("components.dialogFertilizerProduct.emptyList")}
              </AlertEmptyList>
            )}
            <FormikAutocomplete
              formik={formik}
              name="product"
              label={i18n.t("components.dialogFertilizerProduct.product")}
              optionLabelFieldName="name"
              required
              options={fertilizerProducts || []}
              loading={isFetching}
              addNewOption
              onClickNewOption={handleClickNewOption}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="quantity"
              label={i18n.t("components.dialogFertilizerProduct.quantity")}
              type="number"
              required
              valueUnit={formik.values?.product?.measurementUnit?.name}
              inputProps={{ min: 0 }}
              onChange={handleChangeQuantity}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <FormikTextField
              formik={formik}
              className="form-input-row"
              name="priceByUnit"
              label={i18n.t("components.dialogFertilizerProduct.priceByUnit")}
              type="number"
              disabled={
                !formik.values?.product?.measurementUnit?.name ||
                (formik.values.quantity || 0) <= 0
              }
              valueUnit={
                formik.values?.product?.measurementUnit?.name &&
                `€/${formik.values?.product?.measurementUnit?.name}`
              }
              onChange={handleChangePriceByUnit}
            />
            <FormikTextField
              formik={formik}
              className="form-input-row"
              name="totalPrice"
              label={i18n.t("components.dialogFertilizerProduct.totalPrice")}
              type="number"
              disabled={
                !formik.values?.product?.measurementUnit?.name ||
                (formik.values.quantity || 0) <= 0
              }
              valueUnit="€"
              onChange={handleChangeTotalPrice}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={checkConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="confirmNotPriceAddedAlertDialog"
        title={i18n.t(
          "components.dialogFertilizerProduct.confirmNotPriceAddedAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogFertilizerProduct.confirmNotPriceAddedAlertDialogDescription"
        )}
        open={isOpenEmptyPriceAlertDialog}
        formAction={FormMode.DELETE}
        confirmBtnText={i18n.t(
          "components.dialogFertilizerProduct.confirmNotPriceAddedAlertDialogConfirmBtnLabel"
        )}
        cancelBtnText={i18n.t(
          "components.dialogFertilizerProduct.confirmNotPriceAddedAlertDialogCancelBtnLabel"
        )}
        onCancel={cancelEmptyPriceAlertDialog}
        onConfirm={confirmDialog}
      />
    </Dialog>
  );
};

export default DialogFertilizerProduct;
