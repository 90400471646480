import { QueryKey, useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";
import { Cookies } from "react-cookie";
import moment from "moment";

import LegalContext from "../models/LegalContext";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessPartition from "../models/agrobusiness/AgroBusinessPartition";
import Fertilization from "../models/fertiliz/Fertilization";
import FertilizationType from "../models/catalogue/FertilizationType";
import FertilizationGoodPracticeType from "../models/catalogue/FertilizationGoodPracticeType";
import FertilizerApplicationMethod from "../models/catalogue/FertilizerApplicationMethod";
import FertilizerProduct from "../models/fertiliz/FertilizerProduct";
import FertilizerType from "../models/catalogue/FertilizerType";
import FertigationWaterMix from "../models/fertiliz/FertigationWaterMix";
import IrrigationSystemType from "../models/catalogue/IrrigationSystemType";
import IrrigationWaterSourceType from "../models/catalogue/IrrigationWaterSourceType";
import IrrigationGoodPractice from "../models/catalogue/IrrigationGoodPractice";
import AgroBusinessPhytosanitaryInvolvedEntity from "../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import Crop from "../models/crop/Crop";
import CropProduct from "../models/catalogue/CropProduct";
import CropProductVariety from "../models/catalogue/CropProductVariety";
import AgroActivity from "../models/catalogue/AgroActivity";
import AgroActivityDetail from "../models/catalogue/AgroActivityDetail";
import CropDestination from "../models/catalogue/CropDestination";
import CropSystem from "../models/catalogue/CropSystem";
import ReproductiveMaterialType from "../models/catalogue/ReproductiveMaterialType";
import ReproductiveMaterialDetail from "../models/catalogue/ReproductiveMaterialDetail";
import SeedProductionCompany from "../models/catalogue/SeedProductionCompany";
import SeedTreatmentType from "../models/catalogue/SeedTreatmentType";
import PhytosanitaryProduct from "../models/phyto/PhytosanitaryProduct";
import MeasurementUnit from "../models/catalogue/MeasurementUnit";
import QualityRegime from "../models/catalogue/QualityRegime";
import WaterSource from "../models/agrobusiness/WaterSource";
import Sector from "../models/agrobusiness/Sector";
import { FBDocumentType } from "../models/_apiModels/files/FBDocument";
import Document from "../models/files/Document";
import TenureType from "../models/catalogue/TenureType";
import RegisterRegion from "../models/RegisterRegion";
import FertilizerProductStock from "../models/fertiliz/FertilizerProductStock";
import FertilizerStockMovement from "../models/fertiliz/FertilizerStockMovement";
import FertilizerProductPurchase from "../models/fertiliz/FertilizerProductPurchase";
import PhytosanitaryStockMovement from "../models/phyto/PhytosanitaryStockMovement";
import PhytosanitaryProductPurchase from "../models/phyto/PhytosanitaryProductPurchase";
import PhytosanitaryProductStock from "../models/phyto/PhytosanitaryProductStock";
import Facility from "../models/agrobusiness/Facility";
import FacilitySuperType from "../models/catalogue/FacilitySuperType";
import FacilityType from "../models/catalogue/FacilityType";
import Tool from "../models/agrobusiness/Tool";
import CropIrrigation from "../models/crop/CropIrrigation";
import PluviometryRegister from "../models/pluvio/PluviometryRegister";
import AgroBusiness from "../models/agrobusiness/AgroBusiness";
import AgroBusinessClassification from "../models/agrobusiness/AgroBusinessClassification";
import AgroBusinessClassType from "../models/catalogue/AgroBusinessClassType";
import AgroBusinessWineInfo from "../models/agrobusiness/AgroBusinessWineInfo";
import CueAccountWithRole from "../models/account/CueAccountWithRole";
import Owner from "../models/agrobusiness/Owner";
import CoOwner from "../models/agrobusiness/CoOwner";
import OwnerRepresentative from "../models/agrobusiness/OwnerRepresentative";
import Manager from "../models/agrobusiness/Manager";
import AgroBusinessLeadershipList from "../models/address/AgroBusinessLeadershipList";
import OwnerType from "../models/catalogue/OwnerType";
import PotentialAreaType from "../models/catalogue/PotentialAreaType";
import Country from "../models/address/Country";
import Province from "../models/address/Province";
import Municipality from "../models/address/Municipality";
import FarmerType from "../models/catalogue/FarmerType";
import ProfessionalTrainingType from "../models/catalogue/ProfessionalTrainingType";
import Analysis from "../models/analysis/Analysis";
import AnalysisType from "../models/catalogue/AnalysisType";
import AnalizedMaterialType from "../models/catalogue/AnalizedMaterialType";
import CropPhytosanitaryTreatment from "../models/phyto/CropPhytosanitaryTreatement";
import PhytosanitaryActionJustificationType from "../models/catalogue/PhytosanitaryActionJustificationType";
import PlantDisease from "../models/catalogue/PlantDisease";
import PlantPlague from "../models/catalogue/PlantPlague";
import PlantWeed from "../models/catalogue/PlantWeed";
import PlantRegulatorsAndOthers from "../models/catalogue/PlantRegulatorsAndOthers";
import PhytosanitaryActionType from "../models/catalogue/PhytosanitaryActionType";
import PostHarvestPhytosanitaryTreatment from "../models/phyto/PostHarvestPhytosanitaryTreatment";
import VegetableProduct from "../models/catalogue/VegetableProduct";
import FacilityPhytosanitaryTreatment from "../models/phyto/FacilityPhytosanitaryTreatment";
import GipPreventiveAction from "../models/phyto/GipPreventiveAction";
import GipPreventiveActionType from "../models/catalogue/GipPreventiveActionType";
import Entity from "../models/agrobusiness/Entity";
import TreatedSeed from "../models/phyto/TreatedSeed";
import LandCoverType from "../models/catalogue/LandCoverType";
import PersonWithRole from "../models/account/PersonWithRole";
import FertilizationPlan from "../models/fertiliz/FertilizationPlan";
import CropExploitationSystem from "../models/catalogue/CropExploitationSystem";
import OrganicProductionCertificateType from "../models/catalogue/OrganicProductionCertificateType";
import TreatmentEfficacy from "../models/catalogue/TreatmentEfficacy";
import CueAccount from "../models/cueAccount/CueAccount";
import PersonMessage from "../models/messages/PersonMessage";
import RegisteredPhytosanitaryProduct from "../models/vademecum/RegisteredPhytosanitaryProduct";
import VadeAgent from "../models/vademecum/VadeAgent";
import VadeSubstance from "../models/vademecum/VadeSubstance";
import VadeCrop from "../models/vademecum/VadeCrop";
import Person from "../models/Person";
import PhytoRecipe from "../models/phytoRecipe/PhytoRecipe";
import PlantProblem from "../models/catalogue/PlantProblem";
import FertilizerTank from "../models/fertiliz/FertilizerTank";
import PhytosanitaryProductType from "../models/catalogue/PhytosanitaryProductType";
import Notification from "../models/notification/Notification";
import RegisteredFertilizerProduct from "../models/vademecumFertiliz/RegisteredFertilizerProduct";
import VadeFertilizerProductType from "../models/vademecumFertiliz/VadeFertilizerProductType";
import VadeFertilizerManufacturer from "../models/vademecumFertiliz/VadeFertilizerManufacturer";
import FertilizerMacronutrient from "../models/catalogue/FertilizerMacronutrient";
import FertilizerMicronutrient from "../models/catalogue/FertilizerMicronutrient";
import FertilizerHeavyMetal from "../models/catalogue/FertilizerHeavyMetal";
import SiexRequestItem from "../models/siex/SiexRequestItem";

import { useAuth } from "./useAuth";
import { useSession } from "./useSession";

import {
  ErrorCause,
  SearchFieldInterface,
  SearchPhytoRecipePlantProblemsInterface,
  SearchPhytoRecipeProductsInterface,
  SearchVadeFertilizProductInterface,
  SearchVadePhytoProductInterface,
  SnackbarInterface,
} from "../constants/interfaces";
import {
  EXPIRED_TOKEN_ERROR,
  EXPIRED_TOKEN_REFETCH_TIMEOUT,
  INVALID_TOKEN_ERROR,
} from "../constants/constants";
import { FBPersonRole } from "../constants/enums";

import { getResponseData, groupBy } from "../helpers/utils";
import { refreshTokens } from "../routes/AuthLayout";

type T = any;

interface Props<T> {
  queryKey: QueryKey;
  enabled?: boolean;
  selected?: T | any;
  params?: DocumentSearchProps;
  refetchOnWindowFocus?: boolean;
  refetchInterval?: number;
  filter?: (data: T) => T;
  onSuccess?: (data: T) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}

export interface DocumentSearchProps {
  entityClassName?: string;
  entityId?: string;
  dateFrom?: string;
  dateTo?: string;
  typeId?: string;
}

function useFetch<T>(props: Props<T>) {
  const {
    queryKey,
    enabled,
    refetchOnWindowFocus,
    refetchInterval,
    selected,
    params,
    filter,
    onSuccess,
    onError,
  } = props;

  const { idToken, setIdToken, logout } = useAuth();
  const { selectedABAccount, selectedABPartition } = useSession();

  const handleSuccess = (data: T) => {
    onSuccess && onSuccess(data);
  };

  const handleError = async (error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const errorClassName = errorCause?.data?.exceptionClassName;
    // Refresh the idToken
    if (
      errorCause?.status === 401 &&
      (errorClassName === INVALID_TOKEN_ERROR ||
        errorClassName === EXPIRED_TOKEN_ERROR)
    ) {
      try {
        const cookies = new Cookies();
        const refreshToken = cookies.get("refreshToken");
        let idToken: string | undefined = undefined;
        if (refreshToken) {
          const tokens = await refreshTokens(refreshToken);
          idToken = tokens?.accessToken;
          const newRefreshToken = tokens?.refreshToken;
          cookies.set("refreshToken", newRefreshToken, { path: "/" });
        } else {
          const auth = getAuth();
          idToken = await auth.currentUser?.getIdToken();
        }

        // Retry after 5s
        if (idToken) {
          setIdToken(idToken);
          setTimeout(() => {
            query.refetch();
          }, EXPIRED_TOKEN_REFETCH_TIMEOUT);
        } else {
          logout();
          Sentry.captureException("Token expires but should not expire never.");
        }
      } catch (error) {
        logout();
        Sentry.captureException(error);
      }
    } else {
      onError && onError({ severity: "error", message: error?.toString() });
      // Send unexpected error to Sentry
      Sentry.withScope((scope) => {
        scope.setExtra("status", errorCause?.status);
        scope.setExtra("data", errorCause?.data);
        Sentry.captureException(error);
      });
    }
  };

  // If invalid token (error 401), not retry, refresh token and relogin
  const handleRetry = (failureCount: number, error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const responseStatus = errorCause?.status;
    // If server responds, not retry
    if (responseStatus) return false; // Generates the error, not retry again
    return true;
  };

  const fetchProps: FetchProps = {
    idToken,
    agroBusinessId: selectedABAccount?.agroBusiness?.id,
    selectedABAccount,
    selectedABPartition,
    selected,
    params,
  };

  const fnKey = queryKey[0]?.toString() || "";
  const query = useQuery<T>({
    queryKey,
    queryFn: () => fetcher(fnKey, fetchProps),
    select: filter,
    enabled,
    refetchOnWindowFocus,
    refetchInterval,
    retry: handleRetry,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    data: query.data,
    isFetching: query.isFetching,
    refresh: query.refetch,
  };
}

export default useFetch;

const fetcher = (key: string, props: FetchProps): Promise<T> => {
  switch (key) {
    case "agrobusinessById":
      return fetchAgroBusinessById(props);
    case "listByAdvisorEmail":
      return fetchCueAccountsByAdvisorEmail(props);
    case "agroBusinessClassifications":
      return fetchAgroBusinessClassifications(props);
    case "agroBusinessWinesInfo":
      return fetchAgroBusinessWinesInfo(props);
    case "leaderList":
      return fetchLeadershipList(props);
    case "owner":
      return fetchOwner(props);
    case "coOwner":
      return fetchCoOwner(props);
    case "ownerRepresentative":
      return fetchOwnerRepresentative(props);
    case "manager":
      return fetchManager(props);
    case "phytoInvolvedEntities":
      return fetchPhytosanitaryInvolvedEntities(props);
    case "phytoAdvisorInvolvedEntities":
      return fetchPhytosanitaryAdvisorInvolvedEntities(props);
    case "phytoApplicatorInvolvedEntities":
      return fetchPhytosanitaryApplicatorInvolvedEntities(props);
    case "fertilizerAdvisorInvolvedEntities":
      return fetchFertilizerAdvisorInvolvedEntities(props);
    case "fertilizerApplicatorInvolvedEntities":
      return fetchFertilizerApplicatorInvolvedEntities(props);
    case "phytoInvolvedEntitiesWithoutAdvisors":
      return fetchPhytosanitaryInvolvedEntitiesWithoutAdvisors(props);
    case "phytoInvolvedEntity":
      return fetchPhytosanitaryInvolvedEntity(props);
    case "entities":
      return fetchEntities(props);
    case "agroBusinessAccountPermissions":
      return fetchAgroBusinessAccountPermissions(props);
    case "fertilizations":
      return fetchFertilizations(props);
    case "fertilization":
      return fetchFertilization(props);
    case "agroBusinessClassificationTypes":
      return fetchAgroBusinessClassificationTypes(props);
    case "potentialWineAreas":
      return fetchPotentialWineAreas(props);
    case "fertilizationTypes":
      return fetchFertilizationTypes(props);
    case "fertilizationGoodPractices":
      return fetchFertilizationGoodPractices(props);
    case "fertilizerApplicationMethods":
      return fetchFertilizerApplicationMethods(props);
    case "fertilizerProducts":
      return fetchFertilizerProducts(props);
    case "fertilizerProduct":
      return fetchFertilizerProduct(props);
    case "fertilizerProductStock":
      return fetchFertilizerProductStock(props);
    case "fertilizerProductsStock":
      return fetchFertilizerProductsStock(props);
    case "fertilizationWaterMixs":
      return fetchFertilizationWaterMixs(props);
    case "fertigationWaterMix":
      return fetchFertilizationWaterMix(props);
    case "fertilizationPlans":
      return fetchFertilizationPlans(props);
    case "fertilizationPlan":
      return fetchFertilizationPlan(props);
    case "fertilizerTanks":
      return fetchFertilizerTanks(props);
    case "fertilizerTank":
      return fetchFertilizerTank(props);
    case "fertilizerStockMovements":
      return fetchFertilizerStockMovements(props);
    case "fertilizerStockMovement":
      return fetchFertilizerStockMovement(props);
    case "fertilizerProductPurchase":
      return fetchFertilizerProductPurchase(props);
    case "cropSystemTypes":
      return fetchCropSystemTypes(props);
    case "organicCertificationTypes":
      return fetchOrganicCertificationTypes(props);
    case "treatmentEfficacyTypes":
      return fetchTreatmentEfficacyTypes(props);
    case "irrigationSystemTypes":
      return fetchIrrigationSystemTypes(props);
    case "irrigationWaterSourceTypes":
      return fetchIrrigationWaterSourceTypes(props);
    case "irrigationGoodPractices":
      return fetchIrrigationGoodPractices(props);
    case "crops":
      return fetchCrops(props);
    case "crop":
      return fetchCrop(props);
    case "cropIrrigations":
      return fetchCropIrrigations(props);
    case "cropIrrigation":
      return fetchCropIrrigation(props);
    case "pluviometryRegisters":
      return fetchPluviometryRegisters(props);
    case "pluviometryRegister":
      return fetchPluviometryRegister(props);
    case "pluviometrySourceIds":
      return fetchPluviometrySourceIds(props);
    case "cropProducts":
      return fetchCropProducts(props);
    case "varieties":
      return fetchVarieties(props);
    case "agroActivities":
      return fetchAgroActivities(props);
    case "agroActivityDetails":
      return fetchAgroActivityDetails(props);
    case "cropDestinations":
      return fetchCropDestinations(props);
    case "cropSystems":
      return fetchCropSystems(props);
    case "reproductiveMaterials":
      return fetchReproductiveMaterials(props);
    case "reproductiveMaterialDetails":
      return fetchReproductiveMaterialDetails(props);
    case "seedProductionCompanies":
      return fetchSeedProductionCompanies(props);
    case "seedTreatmentTypes":
      return fetchSeedTreatmentTypes(props);
    case "phytosanitaryProducts":
      return fetchPhytosanitaryProducts(props);
    case "phytosanitaryProduct":
      return fetchPhytosanitaryProduct(props);
    case "measurementUnits":
      return fetchMeasurementUnits(props);
    case "qualityRegimes":
      return fetchQualityRegimes(props);
    case "facilities":
      return fetchFacilities(props);
    case "facility":
      return fetchFacility(props);
    case "tools":
      return fetchTools(props);
    case "tool":
      return fetchTool(props);
    case "waterSources":
      return fetchWaterSources(props);
    case "waterSource":
      return fetchWaterSource(props);
    case "sectors":
      return fetchSectors(props);
    case "sector":
      return fetchSector(props);
    case "agroBusinessPartitions":
      return fetchAgroBusinessPartitions(props);
    case "cueAccountsWithRole":
      return fetchCueAccountsWithRole(props);
    case "agroBusinessAccounts":
      return fetchAgroBusinessAccounts(props);
    case "agroBusinessAccount":
      return fetchAgroBusinessAccount(props);
    case "documents":
      return fetchDocuments(props);
    case "document":
      return fetchDocument(props);
    case "documentTypes":
      return fetchDocumentTypes(props);
    case "tenureTypes":
      return fetchTenureTypes(props);
    case "fertilizerProductMacronutrients":
      return fetchFertilizerMacronutrients(props);
    case "fertilizerProductMicronutrients":
      return fetchFertilizerMicronutrients(props);
    case "fertilizerProductHeavyMetals":
      return fetchFertilizerHeavyMetals(props);
    case "fertilizerTypes":
      return fetchFertilizerTypes(props);
    case "registerRegions":
      return fetchRegisterRegions(props);
    case "facilitySuperTypes":
      return fetchFacilitySuperTypes(props);
    case "facilityTypesBySuperTypeCode":
      return fetchFacilityTypesBySuperTypeCode(props);
    case "ownerTypes":
      return fetchOwnerTypes(props);
    case "countries":
      return fetchCountries(props);
    case "provincesByCountryCode":
      return fetchProvincesByCountryCode(props);
    case "municipalitiesByProvinceId":
      return fetchMunicipalitiesByProvinceId(props);
    case "farmerTypes":
      return fetchFarmerTypes(props);
    case "professionalTrainingTypes":
      return fetchProfessionalTrainingTypes(props);
    case "analysisTypes":
      return fetchAnalysisTypes(props);
    case "analizedMaterialTypes":
      return fetchAnalizedMaterialTypes(props);
    case "phytosanitaryActionJustificationTypes":
      return fetchPhytoActionJustificationTypes(props);
    case "plantDiseases":
      return fetchPlantDiseases(props);
    case "plantPlagues":
      return fetchPlantPlagues(props);
    case "plantWeeds":
      return fetchPlantWeeds(props);
    case "plantRegulatorsAndOthers":
      return fetchPlantRegulatorsAndOthers(props);
    case "phytosanitaryActionTypes":
      return fetchPhytoActionTypes(props);
    case "vegetableProducts":
      return fetchVegetableProducts(props);
    case "gipPreventiveActionTypes":
      return fetchGipPreventiveActionTypes(props);
    case "landCoverTypes":
      return fetchLandCoverTypes(props);
    case "phytosanitaryProductTypes":
      return fetchPhytosanitaryProductTypes(props);
    case "registeredPhytosanitaryProducts":
      return fetchRegisteredPhytoProducts(props);
    case "phytosanitaryStockMovements":
      return fetchPhytosanitaryStockMovements(props);
    case "phytosanitaryStockMovement":
      return fetchPhytosanitaryStockMovement(props);
    case "phytosanitaryProductStock":
      return fetchPhytosanitaryProductStock(props);
    case "phytosanitaryProductsStock":
      return fetchPhytosanitaryProductsStock(props);
    case "phytosanitaryProductPurchase":
      return fetchPhytosanitaryProductPurchase(props);
    case "analysis":
      return fetchAnalysis(props);
    case "analysisById":
      return fetchAnalysisById(props);
    case "cropPhytoTreatments":
      return fetchPhytosanitaryCropTreatments(props);
    case "cropPhytosanitaryTreatment":
      return fetchPhytosanitaryCropTreatment(props);
    case "postHarvestPhytoTreatments":
      return fetchPhytosanitaryPostHarvestTreatments(props);
    case "postHarvestPhytosanitaryTreatment":
      return fetchPhytosanitaryPostHarvestTreatment(props);
    case "facilityPhytoTreatments":
      return fetchPhytosanitaryFacilityTreatments(props);
    case "facilityPhytosanitaryTreatment":
      return fetchPhytosanitaryFacilityTreatment(props);
    case "gipPreventiveActions":
      return fetchGipPreventiveActions(props);
    case "gipPreventiveAction":
      return fetchGipPreventiveAction(props);
    case "treatedSeeds":
      return fetchTreatedSeeds(props);
    case "treatedSeed":
      return fetchTreatedSeed(props);
    case "offers":
      return fetchOffers(props);
    case "unreadOffers":
      return fetchUnreadOffers(props);
    case "offerById":
      return fetchOfferById(props);
    case "cropsCount":
      return fetchCropsCount(props);
    case "fertilizerProductsCount":
      return fetchFertilizerProductsCount(props);
    case "phytosanitaryProductsCount":
      return fetchPhytosanitaryProductsCount(props);
    case "fertilizationWaterMixCount":
      return fetchFertigationWaterMixCount(props);
    case "sectorsCount":
      return fetchSectorsCount(props);
    case "phytoInvolvedEntitiesCount":
      return fetchPhytoInvolvedEntitiesCount(props);
    case "fertilizerApplicatorsCount":
      return fetchFertilizerApplicatorsCount(props);
    case "facilitiesCount":
      return fetchFacilitiesCount(props);
    case "vadePhytoProducts":
      return fetchVadePhytoProducts(props);
    case "vadeAgents":
      return fetchVadeAgents(props);
    case "vadeSubstances":
      return fetchVadeSubstances(props);
    case "vadeCrops":
      return fetchVadeCrops(props);
    case "vadeFertilizProducts":
      return fetchVadeFertilizerProducts(props);
    case "vadeFertilizerProductTypes":
      return fetchVadeFertilizProductTypes(props);
    case "vadeFertilizerManufacturers":
      return fetchVadeFertilizManufacturers(props);
    case "registeredPhytoProductById":
      return fetchRegisteredPhytoProductById(props);
    case "registeredFertilizProductById":
      return fetchRegisteredFertilizProductById(props);
    case "writerUserList":
      return fetchWriterList(props);
    case "phytoRecipes":
      return fetchPhytoRecipes(props);
    case "phytoRecipeById":
      return fetchPhytoRecipeById(props);
    case "notifications":
      return fetchUnreadNotifications(props);
    case "isPhytoAdvisor":
      return isPhytoAdvisor(props);
    case "phytoRecipeProducts":
      return fetchPhytoRecipeProducts(props);
    case "plantProblems":
      return fetchPhytoRecipePlantProblems(props);
    case "plantProblemsCatalogue":
      return fetchPhytoRecipePlantProblemsByName(props);
    case "updateFromREA":
      return updateFromREA(props);
    case "siexErrors":
      return fetchSiexErrors(props);
    case "siexError":
      return fetchSiexError(props);
    default:
      return Promise.reject("Function not found");
  }
};

interface FetchProps {
  idToken?: string;
  agroBusinessId?: number;
  selectedABAccount?: AgroBusinessAccount | null;
  selectedABPartition?: AgroBusinessPartition | null;
  selected?: T;
  params?: DocumentSearchProps;
}

/**
 * FERTILIZATION Endpoints
 */
const fetchFertilizations = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilization/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizationsSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Fertilization().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilization = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const fertilizationId = selected?.id;
  const cropId = selected?.crop?.id;

  if (agroBusinessId && cropId && fertilizationId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/fertilization/${fertilizationId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onFertilizationResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertilization";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Fertilization().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerProductsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerProductsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerProduct().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerProduct = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const productId = selected?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct/${productId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onFertilizerProductSuccess(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerProductSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertilizerProduct";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new FertilizerProduct().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerProductsStock = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductStock/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerProductsStockSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerProductsStockSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Filter disabled partitions
    const productsStock = data
      .map((d: any) => new FertilizerProductStock().mapToClass(d))
      .filter(
        (product: FertilizerProductStock) =>
          !product?.agroBusinessPartition ||
          !product?.agroBusinessPartition?.disabledDate
      );
    // Sum products stock by unique id
    const productsById = groupBy(productsStock, "id");
    const totalStock = [];
    for (const key in productsById) {
      const product = productsById[key];
      const stock = product.reduce(
        (acc: number, product: FertilizerProductStock) =>
          acc + (product?.stock || 0),
        0
      );
      totalStock.push({ ...product[0], stock });
    }
    return totalStock;
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerProductsStockError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerProductStock = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition, selected } = props;
  const productId = selected?.id;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken && productId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductStock/${productId}?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerProductStockResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

interface FertilizerProductStockInterface {
  stock: number;
  unit: string;
}
const onFertilizerProductStockResponse = async (
  response: Response
): Promise<FertilizerProductStockInterface> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const products = data
      .map((d: any) => new FertilizerProductStock().mapToClass(d))
      .filter(
        (product: FertilizerProductStock) =>
          !product?.agroBusinessPartition ||
          !product.agroBusinessPartition?.disabledDate
      );
    const stock = products.reduce(
      (acc: number, product: FertilizerProductStock) =>
        acc + (product?.stock || 0),
      0
    );
    return { stock, unit: data?.[0]?.measurementUnit?.name };
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizerProductStockError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizationWaterMixs = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizationWaterMixsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationWaterMixsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertigationWaterMix().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationWaterMixsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizationWaterMix = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const fertigationWaterMix = selected as FertigationWaterMix;
  const fertigationWaterMixId = fertigationWaterMix?.id;
  if (agroBusinessId && idToken && fertigationWaterMixId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix/${fertigationWaterMixId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onFertilizationWaterMixSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationWaterMixSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertigationWaterMix";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new FertigationWaterMix().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationWaterMixError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizationPlans = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizationPlan/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizationPlansSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationPlansSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizationPlan().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationPlansError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizationPlan = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const fertilizationPlanId = selected?.id;

  if (agroBusinessId && fertilizationPlanId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizationPlan/${fertilizationPlanId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onFertilizationPlanResponse(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationPlanResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertilizationPlan";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new FertilizationPlan().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizationPlanError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerTanks = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerTank/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerTanksSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerTanksSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerTank().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerTanksError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerTank = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const fertilizerTankId = selected?.id;

  if (agroBusinessId && fertilizerTankId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerTank/${fertilizerTankId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerTankResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerTankResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new FertilizationPlan().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerTankError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * CROP Endpoints
 */
const fetchCrops = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropsSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Crop().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchCrop = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const cropId = selected?.id;
  if (agroBusinessId && cropId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onCropResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBCrop";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Crop().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * CROP IRRIGATION Endpoints
 */
const fetchCropIrrigations = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropIrrigation/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropIrrigationsSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropIrrigationsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropIrrigation().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropIrrigationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchCropIrrigation = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const cropIrrigation = selected as CropIrrigation;
  const cropId = cropIrrigation?.crop?.id;
  const cropIrrigationId = selected?.id;
  if (agroBusinessId && cropId && cropIrrigationId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/irrigation/${cropIrrigationId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onCropIrrigationResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropIrrigationResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBCropIrrigation";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new CropIrrigation().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropIrrigationError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * PLUVIOMETRY REGISTER Endpoints
 */
const fetchPluviometryRegisters = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;

  if (agroBusinessId && idToken) {
    const partitionId = selectedABPartition?.id || "";
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometryRegister/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPluviometryRegistersSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPluviometryRegistersSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PluviometryRegister().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchPluviometryRegistersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPluviometryRegister = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const pluviometryRegister = selected as PluviometryRegister;
  const pluviometryRegisterId = pluviometryRegister?.id;
  if (agroBusinessId && pluviometryRegisterId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometryRegister/${pluviometryRegisterId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPluviometryRegisterResponse(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPluviometryRegisterResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBPluviometryRegister";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new PluviometryRegister().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchPluviometryRegisterError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPluviometrySourceIds = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;

  if (agroBusinessId && idToken) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/pluviometrySourceId/distinctList`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPluviometrySourceIdsSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPluviometrySourceIdsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else {
    throw new Error(i18n.t("apiResponses.fetchPluviometrySourceIdsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * RECIPES endpoints
 */
const fetchWriterList = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agroBusinessId = selectedABAccount?.agroBusiness?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/writerUserList`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onWriterListSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onWriterListSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Person().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchWriterUserListError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agroBusinessId = selectedABAccount?.agroBusiness?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoRecipesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytoRecipesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const recipes = data.map((d: any) => new PhytoRecipe().mapToClass(d));
    // Sort by date, desc
    return recipes.sort(
      (a: PhytoRecipe, b: PhytoRecipe) =>
        moment(b?.date).valueOf() - moment(a?.date).valueOf()
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchPhytoRecipeListError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipeById = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const agrobusinessId = selectedABAccount?.agroBusiness?.id;
  const phytoRecipe = selected as PhytoRecipe;
  const phytoRecipeId = phytoRecipe?.id;

  if (agrobusinessId && phytoRecipeId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/phytoRecipe/${phytoRecipeId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoRecipeByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipeByIdResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new PhytoRecipe().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchPhytoRecipeByIdError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchUnreadNotifications = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agrobusinessId = selectedABAccount?.agroBusiness?.id;

  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/notification/list?includeFutures=true&includeExpired=true`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onUnreadNotificationsSuccess);
};

const onUnreadNotificationsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Notification().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchNotificationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const isPhytoAdvisor = async (props: FetchProps): Promise<boolean> => {
  const { idToken, selectedABAccount } = props;
  const agroBusinessId = selectedABAccount?.agroBusiness?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/getUserAsAdvisor`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onIsPhytoAdvisorSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onIsPhytoAdvisorSuccess = async (
  response: Response
): Promise<boolean> => {
  return response.status === 200;
};

const fetchPhytoRecipeProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const filters = selected as SearchPhytoRecipeProductsInterface;
  const { nameContains, siexProductId, plantProblemIds } = filters;
  const plantProblemIdList = plantProblemIds?.join(",");

  if (contextId)
    return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/vademecumPhyto/product/list?contextId=${contextId}&nameContains=${nameContains}&siexProductId=${
        siexProductId || ""
      }&plantProblemIdList=${plantProblemIdList}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoRecipeProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipeProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new RegisteredPhytosanitaryProduct().mapToClass(d)
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipePlantProblems = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const filters = selected as SearchPhytoRecipePlantProblemsInterface;
  const { nameContains, siexProductId } = filters;

  if (contextId)
    return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/vademecumPhyto/plantProblem/list?contextId=${contextId}&nameContains=${
        nameContains || ""
      }&siexProductId=${siexProductId || ""}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoRecipePlantProblemsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipePlantProblemsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantProblem().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoPlantProblemsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipePlantProblemsByName = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const filters = selected as SearchPhytoRecipePlantProblemsInterface;
  const { nameContains } = filters;
  const nameLike = `%25${nameContains}%25`;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantProblem/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoRecipePlantProblemsByNameResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipePlantProblemsByNameResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantProblem().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoPlantProblemsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * CATALOGUE endpoints
 */

const fetchAgroBusinessClassificationTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/agroBusinessClassification/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessClassificationTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessClassificationTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroBusinessClassType().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchAgroBusinessClassificationTypesError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
};

const fetchPotentialWineAreas = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/potentialWineArea/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPotentialWineAreasResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPotentialWineAreasResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PotentialAreaType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPotentialWineAreasError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizationTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizationType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizationTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizationType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizationTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizationGoodPractices = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizationGoodPracticeType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizationGoodPracticesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizationGoodPracticesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new FertilizationGoodPracticeType().mapToClass(d)
    );
  } else
    throw new Error(
      i18n.t("apiResponses.fetchFertilizationGoodPracticesError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
};

const fetchFertilizerApplicationMethods = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizerApplicationMethod/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerApplicationMethodsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerApplicationMethodsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new FertilizerApplicationMethod().mapToClass(d)
    );
  } else
    throw new Error(
      i18n.t("apiResponses.fetchFertilizerApplicationMethodsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
};

const fetchCropProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/product/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropProductsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropProduct().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchCropProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchVarieties = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const productId = selected;
  if (productId && contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/product/${productId}/variety/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVarietiesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onVarietiesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropProductVariety().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchVarietiesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchAgroActivities = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/agroActivityType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroActivitiesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroActivitiesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroActivity().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchAgroActivitiesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchAgroActivityDetails = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const agroActivityCode = selected;
  if (agroActivityCode && contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/agroActivityDetailType/list?contextId=${contextId}&agroActivityCode=${agroActivityCode}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroActivityDetailsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroActivityDetailsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroActivityDetail().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchAgroActivityDetailsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchCropSystemTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/cropExploitationSystem/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropSystemTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropSystemTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropExploitationSystem().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchIrrigationSystemTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchOrganicCertificationTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/organicProductionCertificationType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onOrganicCertificationTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onOrganicCertificationTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new OrganicProductionCertificateType().mapToClass(d)
    );
  } else
    throw new Error(
      i18n.t("apiResponses.fetchOrganicCertificationTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchTreatmentEfficacyTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/treatmentEfficacy/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onTreatmentEfficacyResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onTreatmentEfficacyResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new TreatmentEfficacy().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchTreatmentEfficacyTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchIrrigationSystemTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/irrigationSystemType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onIrrigationSystemTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onIrrigationSystemTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new IrrigationSystemType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchIrrigationSystemTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchIrrigationWaterSourceTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/irrigationWaterSourceType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onIrrigationWaterSourceTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onIrrigationWaterSourceTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new IrrigationWaterSourceType().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchIrrigationWaterSourceTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchIrrigationGoodPractices = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/irrigationGoodPractice/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onIrrigationGoodPracticesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onIrrigationGoodPracticesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new IrrigationGoodPractice().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchIrrigationGoodPracticesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchCropDestinations = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/cropDestinationType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropDestinationsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropDestinationsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropDestination().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchCropDestinationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchCropSystems = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/cropSystemType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropSystemsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropSystemsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropSystem().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchCropSystemsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchReproductiveMaterials = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantReproductiveMaterialType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onReproductiveMaterialsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onReproductiveMaterialsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new ReproductiveMaterialType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchReproductiveMaterialsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchReproductiveMaterialDetails = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const typeCode = selected;
  if (typeCode && contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantReproductiveMaterialDetailType/list?contextId=${contextId}&typeCode=${typeCode}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onReproductiveMaterialDetailsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onReproductiveMaterialDetailsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new ReproductiveMaterialDetail().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchReproductiveMaterialDetailsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
};

const fetchSeedProductionCompanies = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/seedProductionCompany/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSeedProductionCompaniesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onSeedProductionCompaniesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new SeedProductionCompany().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchSeedProductionCompaniesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchSeedTreatmentTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/seedTreatmentType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSeedTreatmentTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onSeedTreatmentTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new SeedTreatmentType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchSeedTreatmentTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchMeasurementUnits = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/measurementUnitType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onMeasurementUnitsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onMeasurementUnitsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new MeasurementUnit().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchMeasurementUnitsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizerTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizerType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizerTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizerMacronutrients = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizerMacronutrient/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerMacronutrientsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerMacronutrientsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerMacronutrient().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizerMacronutrientsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizerMicronutrients = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizerMicronutrient/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerMicronutrientsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerMicronutrientsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerMicronutrient().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizerMicronutrientsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFertilizerHeavyMetals = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/fertilizerHeavyMetal/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerHeavyMetalsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerHeavyMetalsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerHeavyMetal().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFertilizerHeavyMetalsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchQualityRegimes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/qualityRegime/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onQualityRegimesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onQualityRegimesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const qualityRegimes = data.map((d: any) =>
      new QualityRegime().mapToClass(d)
    );
    // Sort by category name, neccesary for Autocomplete groupBy
    return qualityRegimes.sort((a: QualityRegime, b: QualityRegime) =>
      a.category?.name && b.category?.name
        ? a.category.name.localeCompare(b.category.name)
        : 0
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchQualityRegimesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchDocumentTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/documentType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onDocumentTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onDocumentTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FBDocumentType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchDocumentTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchTenureTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/tenureType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onTenureTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onTenureTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new TenureType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchTenureTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchRegisterRegions = async (props: FetchProps): Promise<T> => {
  const { idToken, selected } = props;
  const legalContext = selected as LegalContext;
  const contextId = legalContext?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/registerRegion/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisterRegionsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onRegisterRegionsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new RegisterRegion().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchRegisterRegionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFacilitySuperTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/facilitySuperType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFacilitySuperTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFacilitySuperTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FacilitySuperType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFacilitySuperTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchFacilityTypesBySuperTypeCode = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const facilitySuperTypeCode = selected;
  if (facilitySuperTypeCode && contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/facilityType/list?contextId=${contextId}&superTypeCode=${facilitySuperTypeCode}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFacilityTypesBySuperTypeCodeResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFacilityTypesBySuperTypeCodeResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FacilityType().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchFacilityTypesBySuperTypeCodeError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
};

const fetchOwnerTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/ownerType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onOwnerTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onOwnerTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new OwnerType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchOwnerTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchCountries = async (props: FetchProps): Promise<T> => {
  const { idToken } = props;
  if (idToken)
    return fetch(`${process.env.REACT_APP_API_URL}/catalogue/country/list`, {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }).then(onCountriesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCountriesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Country().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchCountriesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchProvincesByCountryCode = async (props: FetchProps): Promise<T> => {
  const { idToken, selected } = props;
  const country = selected as Country;
  const countryCode = country?.code;
  if (countryCode && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/country/byCode/${countryCode}/province/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onProvincesByCountryCodeResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onProvincesByCountryCodeResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Province().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchProvincesByCountryCodeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchMunicipalitiesByProvinceId = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selected } = props;
  const province = selected as Province;
  const provinceId = province?.id;
  if (idToken && provinceId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/province/${provinceId}/municipality/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onMunicipalitiesByProvinceIdResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onMunicipalitiesByProvinceIdResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Municipality().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchMunicipalitiesByProvinceIdError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchFarmerTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/farmerType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFarmTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFarmTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FarmerType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchFarmerTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchProfessionalTrainingTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/professionalTrainingType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onProfessionalTrainingTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onProfessionalTrainingTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new ProfessionalTrainingType().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchProfessionalTrainingTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchAnalysisTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/analysisType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAnalysisTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAnalysisTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AnalysisType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchAnalysisTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchAnalizedMaterialTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/analizedMaterialType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAnalizedMaterialTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAnalizedMaterialTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AnalizedMaterialType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchAnalizedMaterialTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytoActionJustificationTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/phytosanitaryActionJustificationType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoActionJustificationTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytoActionJustificationTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new PhytosanitaryActionJustificationType().mapToClass(d)
    );
  } else
    throw new Error(
      i18n.t("apiResponses.fetchPhytoActionJustificationTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchPlantDiseases = async (props: FetchProps) => {
  const { selectedABAccount, idToken, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const searchWord = selected || "";
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantDesease/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPlantDiseasesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPlantDiseasesResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantDisease().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPlantDiseasesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPlantPlagues = async (props: FetchProps) => {
  const { selectedABAccount, idToken, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const searchWord = selected || "";
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantPlague/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPlantPlaguesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPlantPlaguesResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantPlague().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPlantPlaguesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPlantWeeds = async (props: FetchProps) => {
  const { selectedABAccount, idToken, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const searchWord = selected || "";
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantWeed/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPlantWeedsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPlantWeedsResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantWeed().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPlantWeedsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPlantRegulatorsAndOthers = async (props: FetchProps) => {
  const { selectedABAccount, idToken, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const searchWord = selected || "";
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantRegulatorsAndOthers/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPlantRegulatorsAndOthersResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPlantRegulatorsAndOthersResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantRegulatorsAndOthers().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPlantRegulatorsAndOthersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytoActionTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/phytosanitaryActionType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoActionTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytoActionTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryActionType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytoActionTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchVegetableProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/vegetableProduct/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVegetableProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onVegetableProductsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VegetableProduct().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchVegetableProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchGipPreventiveActionTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (idToken && contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/gipPreventiveActionType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onGipPreventiveActionTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onGipPreventiveActionTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new GipPreventiveActionType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchGipPreventiveActionTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchLandCoverTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/landCoverType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onLandCoverTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onLandCoverTypesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new LandCoverType().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchLandCoverTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytosanitaryProductTypes = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const contextId = selectedABAccount?.context?.id;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/phytosanitaryProductType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryProductTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryProductType().mapToClass(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryProductTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchRegisteredPhytoProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const searchWord = selected || "";
  const nameLike = `%25${searchWord}%25`;
  if (contextId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/registeredPhytosanitaryProduct/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisteredPhytoProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onRegisteredPhytoProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new RegisteredPhytosanitaryProduct().mapToClass(d)
    );
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytosanitaryProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

/**
 * FERTILIZER STOCK endpoints
 */
const fetchFertilizerStockMovements = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerStockMovement/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerStockMovementsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerStockMovementsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new FertilizerStockMovement().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerStockMovementsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerStockMovement = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const stockMovement = selected as FertilizerStockMovement;
  const stockMovementId = stockMovement?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerStockMovement/${stockMovementId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onFertilizerStockMovementSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerStockMovementSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertilizerStockMovement";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new FertilizerStockMovement().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFertilizerStockMovementError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFertilizerProductPurchase = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const values = selected as FertilizerProductPurchase;
  const purchaseId = values?.id;
  if (agroBusinessId && idToken && purchaseId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProductPurchase/${purchaseId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onFertilizerProductPurchaseSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFertilizerProductPurchaseSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFertilizerProductPurchase";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new FertilizerProductPurchase().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchFertilizerProductPurchaseError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

/**
 * PHYTOSANITARY STOCK endpoints
 */
const fetchPhytosanitaryStockMovements = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryStockMovement/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryStockMovementsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryStockMovementsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryStockMovement().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryStockMovementsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryStockMovement = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const stockMovement = selected as PhytosanitaryStockMovement;
  const stockMovementId = stockMovement?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryStockMovement/${stockMovementId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryStockMovementSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryStockMovementSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBPhytosanitaryStockMovement";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new PhytosanitaryStockMovement().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryStockMovementError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryProductsStock = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductStock/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryProductsStockSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductsStockSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Filter disabled partitions
    const productsStock = data
      .map((d: any) => new PhytosanitaryProductStock().mapToClass(d))
      .filter(
        (product: PhytosanitaryProductStock) =>
          !product?.agroBusinessPartition ||
          !product?.agroBusinessPartition?.disabledDate
      );
    // Sum products stock by unique id
    const productsById = groupBy(productsStock, "id");
    const totalStock: PhytosanitaryProductStock[] = [];
    for (const key in productsById) {
      const product = productsById[key];
      const stock = product.reduce(
        (acc: number, product: PhytosanitaryProductStock) =>
          acc + (product?.stock || 0),
        0
      );
      const newStock = new PhytosanitaryProductStock().mapToClass({
        ...product[0],
        stock,
      });
      if (newStock) totalStock.push(newStock);
    }
    return totalStock;
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryProductsStockError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryProductPurchase = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const values = selected as PhytosanitaryProductPurchase;
  const purchaseId = values?.id;
  if (agroBusinessId && idToken && purchaseId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductPurchase/${purchaseId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryProductPurchaseSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductPurchaseSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBPhytosanitaryProductPurchase";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new PhytosanitaryProductPurchase().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryProductPurchaseError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryProducts = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryProduct().mapToClass(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytosanitaryProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytosanitaryProduct = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const selectedPhytosanitaryProduct = selected as PhytosanitaryProduct;
  const productId = selectedPhytosanitaryProduct?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/${productId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryProductResponse(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBPhytosanitaryProduct";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new PhytosanitaryProduct().mapToClass(data);
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytosanitaryProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytosanitaryProductStock = async (props: FetchProps) => {
  const { agroBusinessId, idToken, selectedABPartition, selected } = props;
  const selectedPhytosanitaryProduct = selected as PhytosanitaryProduct;
  const productId = selectedPhytosanitaryProduct?.id;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken && productId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProductStock/${productId}?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryProductStockResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductStockResponse = async (response: Response) => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const products = data
      .map((d: any) => new PhytosanitaryProductStock().mapToClass(d))
      .filter(
        (product: PhytosanitaryProductStock) =>
          !product?.agroBusinessPartition ||
          !product.agroBusinessPartition?.disabledDate
      );
    const stock = products.reduce(
      (acc: number, product: PhytosanitaryProductStock) =>
        acc + (product?.stock || 0),
      0
    );
    return { stock, unit: data?.[0]?.measurementUnit?.name };
  } else
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryProductStockError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

/**
 * FACILITIES endpoints
 */
const fetchFacilities = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFacilitiesSuccess);
};

const onFacilitiesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Facility().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchFacilitiesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchFacility = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const selectedFacility = selected as Facility;
  const facilityId = selectedFacility?.id;
  if (agroBusinessId && facilityId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility/${facilityId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onFacilityResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFacilityResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFacility";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Facility().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchFacilityError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * TOOLS endpoints
 */
const fetchTools = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/tool/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onToolsSuccess);
};

const onToolsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Tool().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchToolsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchTool = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const selectedTool = selected as Tool;
  const toolId = selectedTool?.id;
  if (agroBusinessId && toolId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/tool/${toolId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onToolResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onToolResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBTool";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Tool().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchToolError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * WATER SOURCES endpoints
 */
const fetchWaterSources = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/waterSource/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onWaterSourcesSuccess);
};

const onWaterSourcesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new WaterSource().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchWaterSourcesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchWaterSource = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const selectedWaterSource = selected as WaterSource;
  const waterSourceId = selectedWaterSource?.id;
  if (agroBusinessId && waterSourceId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/waterSource/${waterSourceId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onWaterSourceResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onWaterSourceResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBWaterSource";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new WaterSource().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchWaterSourceError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * SECTORS endpoints
 */
const fetchSectors = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSectorsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onSectorsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Sector().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchSectorsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchSector = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const sectorId = selected?.id;
  if (agroBusinessId && sectorId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector/${sectorId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onSectorResponse(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onSectorResponse = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBSector";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Sector().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchSectorError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * AGROBUSINESS endpoints
 */
const fetchAgroBusinessById = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessByIdSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessByIdSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new AgroBusiness().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessByIdError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchCueAccountsByAdvisorEmail = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selected } = props;
  const email = selected || "";
  if (email && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/listByAdvisorEmail?email=${email}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCueAccountsByAdvisorEmailSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCueAccountsByAdvisorEmailSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CueAccount().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchCueAccountsByAdvisorEmailError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchAgroBusinessClassifications = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/classification/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessClassificationsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessClassificationsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroBusinessClassification().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchAgroBusinessClassificationsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchAgroBusinessWinesInfo = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/wineInfo/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessWinesInfoSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessWinesInfoSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroBusinessWineInfo().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessWinesInfoError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchLeadershipList = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/leadership`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onLeadershipSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onLeadershipSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new AgroBusinessLeadershipList().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchLeadershipListError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchOwner = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onOwnerSuccess(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onOwnerSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBOwner";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Owner().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchCoOwner = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const coOwner = selected as CoOwner;
  const coOwnerId = coOwner.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/coOwner/${coOwnerId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onCoOwnerSuccess(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCoOwnerSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBCoOwner";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new CoOwner().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchCoOwnerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchOwnerRepresentative = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/owner/representative`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onOwnerRepresentativeSuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onOwnerRepresentativeSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBOwnerRepresentative";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new OwnerRepresentative().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchOwnerRepresentativeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchManager = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId } = props;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/manager`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onManagerSuccess(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onManagerSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBManager";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Manager().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchManagerError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytosanitaryInvolvedEntities = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const fetchPhytosanitaryAdvisorInvolvedEntities = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}&phytosanitaryAdvisor=true`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const fetchPhytosanitaryApplicatorInvolvedEntities = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}&phytosanitaryApplicator=true`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const fetchFertilizerAdvisorInvolvedEntities = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}&fertilizationAdvisor=true`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const fetchFertilizerApplicatorInvolvedEntities = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}&fertilizationApplicator=true`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryInvolvedEntitiesSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(d)
    );
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryInvolvedEntitiesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchPhytosanitaryInvolvedEntitiesWithoutAdvisors = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}&advisor=false`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryInvolvedEntitiesWithoutAdvisorsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryInvolvedEntitiesWithoutAdvisorsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(d)
    );
  } else {
    throw new Error(
      i18n.t(
        "apiResponses.fetchPhytosanitaryInvolvedEntitiesWithoutAdvisorsError"
      ),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchPhytosanitaryInvolvedEntity = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const phytoInvolvedEntity =
    selected as AgroBusinessPhytosanitaryInvolvedEntity;
  const phytoInvolvedEntityId = phytoInvolvedEntity?.id;
  if (agroBusinessId && idToken && phytoInvolvedEntityId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/${phytoInvolvedEntityId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryInvolvedEntitySuccess(response, props)
    );
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryInvolvedEntitySuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBAgroBusinessPhytosanitaryInvolvedEntity";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(data);
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryInvolvedEntityError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchEntities = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agroBusinessAccountId = selectedABAccount?.id;
  if (agroBusinessAccountId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/entity/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onEntitiesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onEntitiesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Entity().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchEntitiesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAgroBusinessAccountPermissions = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selected } = props;
  const agroBusinessAccountId = selected?.id;
  if (agroBusinessAccountId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/permissions`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessAccountPermissionsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessAccountPermissionsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PersonWithRole().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchAgroBusinessAccountPermissionsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

/**
 * PARTITION endpoints
 */
const fetchAgroBusinessPartitions = async (props: FetchProps): Promise<T> => {
  const { idToken, selected } = props;
  const selectedABAccount = selected as AgroBusinessAccount; // Not exists when useFetch is used in useSession
  const agroBusinessId = selectedABAccount?.agroBusiness?.id;
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/partition/list`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPartitionsSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPartitionsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data
      .map((d: any) => new AgroBusinessPartition().mapToClass(d))
      .sort((a: AgroBusinessPartition, b: AgroBusinessPartition) =>
        a.name && b.name ? a.name.localeCompare(b.name) : 0
      );
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessPartitionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * AgroBusiness endpoints
 */
const fetchCueAccountsWithRole = async (props: FetchProps): Promise<T> => {
  const { idToken } = props;
  if (idToken)
    return fetch(`${process.env.REACT_APP_API_URL}/cueAccount/listByUser`, {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }).then(onAgroCueAccountsWithRoleResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroCueAccountsWithRoleResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data
      .map((d: any) => new CueAccountWithRole().mapToClass(d))
      .sort((a: CueAccountWithRole, b: CueAccountWithRole) =>
        a.cueAccount?.name && b.cueAccount?.name
          ? a.cueAccount.name.localeCompare(b.cueAccount.name)
          : 0
      );
  } else {
    throw new Error(i18n.t("apiResponses.fetchCueAccountsWithRoleError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAgroBusinessAccounts = async (props: FetchProps): Promise<T> => {
  const { idToken } = props;
  if (idToken)
    return fetch(`${process.env.REACT_APP_API_URL}/agroBusinessAccount/list`, {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }).then(onAgroBussinessAccountsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBussinessAccountsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Filter roles NONE
    const authorizedAbAccounts = data.filter(
      (d: any) => d.role !== FBPersonRole.NONE
    );
    // Sort by name
    return authorizedAbAccounts
      .map((d: any) =>
        new AgroBusinessAccount().mapToClass(d.agroBusinessAccount)
      )
      .sort((a: AgroBusinessAccount, b: AgroBusinessAccount) =>
        a.name && b.name ? a.name.localeCompare(b.name) : 0
      );
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessAccountsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAgroBusinessAccount = async (props: FetchProps): Promise<T> => {
  const { idToken, selected } = props;
  const selectedAgroBusinessAccount = selected as AgroBusinessAccount;
  const agroBusinessAccountId = selectedAgroBusinessAccount?.id;
  if (agroBusinessAccountId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAgroBusinessAccountResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAgroBusinessAccountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new AgroBusinessAccount().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * DOCUMENT endpoints
 */
export const fetchDocuments = async (props: FetchProps) => {
  const { agroBusinessId, idToken, selectedABPartition, params } = props;
  const {
    entityClassName,
    entityId = "",
    typeId = "",
    dateFrom = "",
    dateTo = "",
  } = params as DocumentSearchProps;

  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/agroBusiness/${agroBusinessId}/document/list?partitionId=${partitionId}&typeId=${typeId}&dateFrom=${dateFrom}&dateTo=${dateTo}&entityId=${entityId}${
        entityClassName ? `&entityClassName=${entityClassName}` : ""
      }`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFetchDocumentListResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onFetchDocumentListResponse = async (
  response: Response
): Promise<Document[]> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((doc: any) => new Document().mapToClass(doc));
  } else {
    throw new Error(i18n.t("apiResponses.fetchDocumentsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchDocument = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const documentId = selected?.id;
  if (agroBusinessId && documentId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/document/${documentId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onDocumentResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onDocumentResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new Document().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchDocumentError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * ANALYSIS endpoints
 */
const fetchAnalysis = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId && idToken)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/analysis/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onAnalysisSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAnalysisSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Analysis().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchAnalysisError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAnalysisById = async (props: FetchProps): Promise<T> => {
  const { idToken, agroBusinessId, selected } = props;
  const analysis = selected as Analysis;
  const analysisId = analysis?.id;
  if (agroBusinessId && idToken && analysisId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/analysis/${analysisId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onAnalysisByIdSuccess(response, props));
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onAnalysisByIdSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBAnalysis";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new Analysis().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchAnalysisByIdError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * PHYTOSANITARY TREATMENT endpoints
 */
const fetchPhytosanitaryCropTreatments = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropPhytosanitaryTreatment/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropPhytosanitaryTreatmentsSuccess);
};

const onCropPhytosanitaryTreatmentsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new CropPhytosanitaryTreatment().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryCropTreatmentsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryCropTreatment = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const cropTreatment = selected as CropPhytosanitaryTreatment;
  const cropTreatmentId = cropTreatment?.id;
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/cropPhytosanitaryTreatment/${cropTreatmentId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryCropTreatmentSuccess(response, props)
    );
};

const onPhytosanitaryCropTreatmentSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBCropPhytosanitaryTreatment";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    // Avoid treatment edit disabled by default
    const cropTreatment = new CropPhytosanitaryTreatment().mapToClass(data);
    const products = data?.products?.map((p: any) => {
      const stockMovement = new PhytosanitaryStockMovement().mapToClass(p);
      delete stockMovement?.editDisabled;
      return stockMovement;
    });
    if (cropTreatment) cropTreatment.products = products;
    return cropTreatment;
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryCropTreatmentError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryPostHarvestTreatments = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/postHarvestPhytosanitaryTreatment/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPostHarvestPhytosanitaryTreatmentsSuccess);
};

const onPostHarvestPhytosanitaryTreatmentsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new PostHarvestPhytosanitaryTreatment().mapToClass(d)
    );
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryPostHarvestTreatmentsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryPostHarvestTreatment = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const postHarvestTreatment = selected as PostHarvestPhytosanitaryTreatment;
  const postHarvestTreatmentId = postHarvestTreatment?.id;
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/postHarvestPhytosanitaryTreatment/${postHarvestTreatmentId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryPostHarvestTreatmentSuccess(response, props)
    );
};

const onPhytosanitaryPostHarvestTreatmentSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBPostHarvestPhytosanitaryTreatment";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    // Avoid treatment edit disabled by default
    const postHarvestTreatment =
      new PostHarvestPhytosanitaryTreatment().mapToClass(data);
    const products = data?.products?.map((p: any) => {
      const stockMovement = new PhytosanitaryStockMovement().mapToClass(p);
      delete stockMovement?.editDisabled;
      return stockMovement;
    });
    if (postHarvestTreatment) postHarvestTreatment.products = products;
    return postHarvestTreatment;
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryPostHarvestTreatmentError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryFacilityTreatments = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facilityPhytosanitaryTreatment/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFacilityPhytosanitaryTreatmentsSuccess);
};

const onFacilityPhytosanitaryTreatmentsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new FacilityPhytosanitaryTreatment().mapToClass(d)
    );
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryFacilityTreatmentsError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchPhytosanitaryFacilityTreatment = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const facilityTreatment = selected as FacilityPhytosanitaryTreatment;
  const facilityTreatmentId = facilityTreatment?.id;
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facilityPhytosanitaryTreatment/${facilityTreatmentId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onPhytosanitaryFacilityTreatmentSuccess(response, props)
    );
};

const onPhytosanitaryFacilityTreatmentSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBFacilityPhytosanitaryTreatment";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    // Avoid treatment edit disabled by default
    const facilityTreatment = new FacilityPhytosanitaryTreatment().mapToClass(
      data
    );
    const products = data?.products?.map((p: any) => {
      const stockMovement = new PhytosanitaryStockMovement().mapToClass(p);
      delete stockMovement?.editDisabled;
      return stockMovement;
    });
    if (facilityTreatment) facilityTreatment.products = products;
    return facilityTreatment;
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchPhytosanitaryFacilityTreatmentError"),
      { cause: { status: response.status, data } as ErrorCause }
    );
  }
};

const fetchGipPreventiveActions = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/gipPreventiveAction/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onGipPreventiveActionsSuccess);
};

const onGipPreventiveActionsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new GipPreventiveAction().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchGipPreventiveActionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchGipPreventiveAction = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const preventiveAction = selected as GipPreventiveAction;
  const preventiveActionId = preventiveAction?.id;
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/gipPreventiveAction/${preventiveActionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) =>
      onGipPreventiveActionSuccess(response, props)
    );
};

const onGipPreventiveActionSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBGipPreventiveAction";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new GipPreventiveAction().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchGipPreventiveActionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchTreatedSeeds = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/treatedSeed/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onTreatedSeedsSuccess);
};

const onTreatedSeedsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new TreatedSeed().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchTreatedSeedsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchTreatedSeed = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selected } = props;
  const treatedSeed = selected as TreatedSeed;
  const treatedSeedId = treatedSeed?.id;
  const cropId = treatedSeed?.crop?.id;
  if (agroBusinessId && cropId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}/treatedSeed/${treatedSeedId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then((response: Response) => onTreatedSeedSuccess(response, props));
};

const onTreatedSeedSuccess = async (
  response: Response,
  props: FetchProps
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Get entity attached files
    const entityClassName = "FBTreatedSeed";
    const entityId = data.id?.toString();
    const params = { entityClassName, entityId };
    const foundDocs = await fetchDocuments({
      ...props,
      params,
    });
    data.documents = foundDocs;
    return new TreatedSeed().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchTreatedSeedError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchOffers = async (props: FetchProps): Promise<T> => {
  const { idToken } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/message/list?includeExpired=true`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onOffersSuccess);
};

const onOffersSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PersonMessage().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchOffersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchUnreadOffers = async (props: FetchProps): Promise<T> => {
  const { idToken } = props;
  return fetch(
    `${process.env.REACT_APP_API_URL}/message/list?read=false&includeExpired=true`,
    {
      method: "GET",
      headers: { Authorization: `Bearer ${idToken}` },
    }
  ).then(onUnreadOffersSuccess);
};

const onUnreadOffersSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PersonMessage().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchOffersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchOfferById = async (props: FetchProps): Promise<T> => {
  const { idToken, selected } = props;
  const offer = selected as PersonMessage;
  const offerId = offer?.id;
  return fetch(`${process.env.REACT_APP_API_URL}/message/${offerId}`, {
    method: "GET",
    headers: { Authorization: `Bearer ${idToken}` },
  }).then(onOfferByIdSuccess);
};

const onOfferByIdSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new PersonMessage().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchOffersError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * COUNT ENDPOINTS
 */
const fetchCropsCount = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onCropsCountResponse);
};

const onCropsCountResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchFertilizerProductsCount = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertilizerProduct/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerProductsCountResponse);
};

const onFertilizerProductsCountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchPhytosanitaryProductsCount = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytosanitaryProductsCountResponse);
};

const onPhytosanitaryProductsCountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchFertigationWaterMixCount = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/fertigationWaterMix/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertigationWaterMixCountResponse);
};

const onFertigationWaterMixCountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchSectorsCount = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/sector/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSectorsCountResponse);
};

const onSectorsCountResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchPhytoInvolvedEntitiesCount = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/list?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onPhytoInvolvedEntitiesCountResponse);
};

const onPhytoInvolvedEntitiesCountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const phytoEntities = data.map((d: any) =>
      new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(d)
    );
    return phytoEntities.filter(
      (involvedEntity: AgroBusinessPhytosanitaryInvolvedEntity) =>
        involvedEntity.phytosanitaryAdvisor ||
        involvedEntity.phytosanitaryApplicator
    ).length;
  } else return -1;
};

const fetchFertilizerApplicatorsCount = async (
  props: FetchProps
): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/agroBusinessPhytosanitaryInvolvedEntity/count?partitionId=${partitionId}&fertilizationApplicator=true`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFertilizerApplicatorsCountResponse);
};

const onFertilizerApplicatorsCountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

const fetchFacilitiesCount = async (props: FetchProps): Promise<T> => {
  const { agroBusinessId, idToken, selectedABPartition } = props;
  const partitionId = selectedABPartition?.id || "";
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/facility/count?partitionId=${partitionId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onFacilitiesCountResponse);
};

const onFacilitiesCountResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data;
  } else return -1;
};

/**
 * VADEMECUM endpoints
 */
const fetchVadePhytoProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const filters = selected as SearchVadePhytoProductInterface;
  const nameContains = filters.productSearch.searchWord;
  const ownerContains = filters.ownerSearch.searchWord;
  const formulationContains = filters.formulationSearch.searchWord;
  const agentId = filters.agentSearch.selected?.id || "";
  const substanceId = filters.substanceSearch.selected?.id || "";
  const cropId =
    filters.cropSearch.selected instanceof VadeCrop
      ? filters.cropSearch.selected.id
      : "";
  const siexProductId =
    filters.cropSearch.selected instanceof Crop
      ? filters.cropSearch.selected.id
      : "";

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/product/list?contextId=${contextId}&nameContains=${nameContains}&ownerContains=${ownerContains}&formulationContains=${formulationContains}&agentId=${agentId}&substanceId=${substanceId}&cropId=${cropId}&siexProductId=${siexProductId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadePhytoProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadePhytoProductsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new RegisteredPhytosanitaryProduct().mapToClass(d)
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeAgents = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const agentSearch = selected as SearchFieldInterface<T>;
  const nameContains = agentSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/agent/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeAgentsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeAgentsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeAgent(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeAgentsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeSubstances = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const substanceSearch = selected as SearchFieldInterface<T>;
  const nameContains = substanceSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/substance/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeSubstancesResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeSubstancesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeSubstance(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeSubstancesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeCrops = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const cropSearch = selected as SearchFieldInterface<T>;
  const nameContains = cropSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/crop/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeCropsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeCropsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeCrop(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeCropsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchRegisteredPhytoProductById = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selected } = props;
  const registeredPhytoProduct = selected as RegisteredPhytosanitaryProduct;
  const registeredPhytoProductId = registeredPhytoProduct?.id;

  if (registeredPhytoProductId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/product/${registeredPhytoProductId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisteredPhytoProductByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onRegisteredPhytoProductByIdResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new RegisteredPhytosanitaryProduct().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchRegisteredProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizerProducts = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const filters = selected as SearchVadeFertilizProductInterface;
  const nameContains = filters?.productSearch?.searchWord || "";
  const typeContains = filters?.typeSearch?.searchWord || "";
  const manufacturerContains = filters?.manufacturerSearch?.searchWord || "";

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/product/list?contextId=${contextId}&nameContains=${nameContains}&typeContains=${typeContains}&manufacturerContains=${manufacturerContains}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeFertilizerProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizerProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) =>
      new RegisteredFertilizerProduct().mapToClass(d)
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeFertilizerProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizProductTypes = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const typeSearch = selected as SearchFieldInterface<T>;
  const nameContains = typeSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/productType/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeFertilizProductTypesResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizProductTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeFertilizerProductType().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeFertilizProductTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizManufacturers = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const contextId = selectedABAccount?.context?.id;
  const manufacturerSearch = selected as SearchFieldInterface<T>;
  const nameContains = manufacturerSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/manufacturer/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onVadeFertilizManufacturersResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizManufacturersResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeFertilizerManufacturer().mapToClass(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchVadeFertilizManufacturersError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchRegisteredFertilizProductById = async (
  props: FetchProps
): Promise<T> => {
  const { idToken, selected } = props;
  const registeredFertilizProduct = selected as RegisteredFertilizerProduct;
  const registeredFertilizProductId = registeredFertilizProduct?.id;

  if (registeredFertilizProductId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/product/${registeredFertilizProductId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onRegisteredFertilizProductByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onRegisteredFertilizProductByIdResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new RegisteredFertilizerProduct().mapToClass(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchRegisteredProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const updateFromREA = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agrobusinessId = selectedABAccount?.agroBusiness?.id;
  const siexEnabled = selectedABAccount?.agroBusiness?.siexEntity;

  if (agrobusinessId && siexEnabled)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/siex/updateAgroBusinessFromREA`,
      {
        method: "POST",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onUpdateFromREAResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onUpdateFromREAResponse = (response: Response): Promise<T> => {
  if (response.status === 200) {
    return Promise.resolve(true);
  } else {
    throw new Error(i18n.t("apiResponses.updateFromREAError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};

const fetchSiexErrors = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount } = props;
  const agrobusinessId = selectedABAccount?.agroBusiness?.id;
  const siexEnabled = selectedABAccount?.agroBusiness?.siexEntity;

  if (agrobusinessId && siexEnabled)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/siex/requestItem/list?operationResult=ERROR`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSiexErrorsResponse);
  else return Promise.resolve([]);
};

const onSiexErrorsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new SiexRequestItem().mapToClass(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchSiexErrorsError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};

const fetchSiexError = async (props: FetchProps): Promise<T> => {
  const { idToken, selectedABAccount, selected } = props;
  const agrobusinessId = selectedABAccount?.agroBusiness?.id;
  const siexEnabled = selectedABAccount?.agroBusiness?.siexEntity;
  const entityClassId = selected as number;

  if (agrobusinessId && siexEnabled && entityClassId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/siex/requestItem/list?entityClassId=${entityClassId}&operationResult=ERROR`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${idToken}` },
      }
    ).then(onSiexErrorResponse);
  else return Promise.resolve([]);
};

const onSiexErrorResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new SiexRequestItem().mapToClass(data[0]);
  } else {
    throw new Error(i18n.t("apiResponses.fetchSiexErrorsError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};
