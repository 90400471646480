import VadeSubstance from "../../vademecum/VadeSubstance";

class FBVadeProductComposition {
  id?: number;
  substance?: VadeSubstance;
  concentration?: number;
  concentrationUnit?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.substance = new VadeSubstance(obj?.substance);
    this.concentration = obj?.concentration;
    this.concentrationUnit = obj?.concentrationUnit;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeProductComposition;
