import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  FormGroup,
  FormControl,
  Tabs,
  Tab,
  Collapse,
  useMediaQuery,
  Divider,
  TextField,
  InputAdornment,
  CircularProgress,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Alert,
  FormLabel,
} from "@mui/material";
import { Search, PestControl, Close, Sanitizer } from "@mui/icons-material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import PhytosanitaryStockMovement from "../../models/phyto/PhytosanitaryStockMovement";
import DialogTreatmentApplicator from "../../components/dialogs/DialogTreatmentApplicator";
import DialogTreatmentAdvisor from "../../components/dialogs/DialogTreatmentAdvisor";
import DialogPhytosanitaryProduct from "../../components/dialogs/DialogPhytosanitaryProduct";
import TestBanner from "../../components/banners/TestBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import SectionTutorial from "../../components/elements/SectionTutorial";
import AlertEmptyList from "../../components/AlertEmptyList";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import DialogCropTreatedSector from "../../components/dialogs/DialogCropTreatedSector";
import SiexUploadStatusBanner from "../../components/banners/SiexUploadStatusBanner";
import DialogTreatmentFacility from "../../components/dialogs/DialogTreatmentFacility";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import Crop from "../../models/crop/Crop";
import { Column } from "../../models/Generic";
import PhytosanitaryTreatmentApplicator from "../../models/phyto/PhytosanitaryTreatmentApplicator";
import PhytosanitaryTreatmentAdvisor from "../../models/phyto/PhytosanitaryTreatmentAdvisor";
import CropPhytosanitaryTreatment from "../../models/phyto/CropPhytosanitaryTreatement";
import PhytosanitaryActionType from "../../models/catalogue/PhytosanitaryActionType";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";
import PostHarvestPhytosanitaryTreatment, {
  MEASUREMENT_UNIT_CODES,
} from "../../models/phyto/PostHarvestPhytosanitaryTreatment";
import VegetableProduct from "../../models/catalogue/VegetableProduct";
import FacilityPhytosanitaryTreatment from "../../models/phyto/FacilityPhytosanitaryTreatment";
import CropPhytosanitaryTreatmentSector from "../../models/phyto/CropPhytosanitaryTreatmentSector";
import GipPreventiveAction from "../../models/phyto/GipPreventiveAction";
import GipPreventiveActionType from "../../models/catalogue/GipPreventiveActionType";
import SeedTreatmentType from "../../models/catalogue/SeedTreatmentType";
import PhytosanitaryProduct, {
  MEASUREMENT_UNIT_CODES as PRODUCT_MEASUREMENT_UNIT_CODES,
} from "../../models/phyto/PhytosanitaryProduct";
import TreatedSeed from "../../models/phyto/TreatedSeed";
import Document from "../../models/files/Document";
import TreatmentEfficacy from "../../models/catalogue/TreatmentEfficacy";
import PlantProblem from "../../models/catalogue/PlantProblem";
import PhytosanitaryActionJustificationType from "../../models/catalogue/PhytosanitaryActionJustificationType";
import PhytoRecipe, {
  MEASUREMENT_UNIT_CODES as RECIPE_MEASUREMENT_UNIT_CODES,
} from "../../models/phytoRecipe/PhytoRecipe";
import RegisteredPhytosanitaryProduct from "../../models/vademecum/RegisteredPhytosanitaryProduct";
import FacilityPhytosanitaryTreatmentFacility from "../../models/phyto/FacilityPhytosanitaryTreatmentFacility";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
  dateConverter,
  uniqueById,
} from "../../helpers/utils";

import {
  FBEntitiesTypes,
  FormMode,
  PhytoRecipeType,
  PhytosanitaryStockMovementType,
  PhytosanitaryTreatmentType,
  PlantProblemType,
} from "../../constants/enums";
import {
  SnackbarInterface,
  SectionTutorialInterface,
} from "../../constants/interfaces";
import {
  EMPTY_TABLE_ICON_SIZE,
  SECTION_TABLE_FONT_SIZE,
} from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import {
  PlantProblemList,
  PlantProblemsInput,
  ProductInput,
} from "./ExploitationRecipesScreen";
import SiexRequestItem from "../../models/siex/SiexRequestItem";

const calculateTotalTreatedArea = (
  treatedSectors?: CropPhytosanitaryTreatmentSector[]
) =>
  treatedSectors?.reduce(
    (acc: number, treatedSector: CropPhytosanitaryTreatmentSector) =>
      acc + (treatedSector?.treatedArea || 0),
    0
  ) || 0;

const ExploitationTreatmentsScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");
  const location = useLocation();

  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    switch (recipeType) {
      case PhytoRecipeType.CROP:
        setTabValue(0);
        break;
      case PhytoRecipeType.POSTHARVEST:
        setTabValue(2);
        break;
      case PhytoRecipeType.FACILITY:
        setTabValue(3);
        break;

      default:
        setTabValue(0);
        break;
    }
  }, [recipeType]);

  useEffect(() => {
    if (siexRegister) {
      switch (siexRegister.entityClassName) {
        case FBEntitiesTypes.CROP_PHYTO_TREATMENT:
          return setTabValue(0);
        case FBEntitiesTypes.TREATED_SEED:
          return setTabValue(1);
        case FBEntitiesTypes.POSTHARVEST_PHYTO_TREATMENT:
          return setTabValue(2);
        case FBEntitiesTypes.FACILITY_PHYTO_TREATMENT:
          return setTabValue(3);
        default:
          break;
      }
    }
  }, [siexRegister]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <CropTab />;
      case 1:
        return <TreatedSeedTab />;
      case 2:
        return <PostHarvestTab />;
      case 3:
        return <FacilitiesTab />;
      case 4:
        return <PreventiveActionTab />;
      default:
        return <div></div>;
    }
  };

  return (
    <ScreenContentLayout>
      {!isLargeScreen && <Divider />}
      <TestBanner />
      <Tabs
        className="tab-container"
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("treatments.tab.crops")}
        />
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("treatments.tab.treatedSeed")}
        />
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("treatments.tab.postHarvest")}
        />
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("treatments.tab.buildings")}
        />
        <Tab
          sx={{ fontSize: SECTION_TABLE_FONT_SIZE }}
          wrapped
          label={i18n.t("treatments.tab.preventiveActions")}
        />
      </Tabs>
      {renderTab()}
    </ScreenContentLayout>
  );
};

export default ExploitationTreatmentsScreen;

const CropTab = () => {
  const cropTutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("treatments.tutorial.createCrop.label"),
      description: i18n.t("treatments.tutorial.createCrop.description"),
      nextBtnText: i18n.t("treatments.tutorial.createCrop.nextBtnText"),
    },
    {
      label: i18n.t("treatments.tutorial.createAdvisor.label"),
      description: i18n.t("treatments.tutorial.createAdvisor.description"),
      nextBtnText: i18n.t("treatments.tutorial.createAdvisor.nextBtnText"),
    },
  ];
  const cropTreatmentsColumns: Column[] = [
    {
      id: "crop.productNameWithVarieties",
      label: i18n.t("treatments.column.crop"),
      numeric: false,
    },
    {
      id: "dateRange",
      label: i18n.t("treatments.column.dateRange"),
      numeric: false,
    },
    {
      id: "treatedArea",
      label: i18n.t("treatments.column.treatedArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
    {
      id: "efficacy.name",
      label: i18n.t("treatments.column.efficacy"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<
    CropPhytosanitaryTreatment | undefined
  >(undefined);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoadingTutorialData, setIsLoadingTutorialData] =
    useState<boolean>(true);
  const [isTutorialFinished, setIsTutorialFinished] = useState<boolean>(true);
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const { data: cropsCount, isFetching: isFetchingCrops } = useFetch<number>({
    queryKey: ["cropsCount", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  const {
    data: phytoInvolvedEntitiesCount,
    isFetching: isFetchingPhytoInvolvedEntities,
  } = useFetch<number>({
    queryKey: [
      "phytoInvolvedEntitiesCount",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const { data: cropPhytoTreatments, isFetching } = useFetch<
    CropPhytosanitaryTreatment[]
  >({
    queryKey: [
      "cropPhytoTreatments",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  useEffect(() => {
    setIsLoadingTutorialData(
      isFetchingCrops || isFetchingPhytoInvolvedEntities
    );
    return () => {
      setIsLoadingTutorialData(false);
    };
  }, [isFetchingCrops, isFetchingPhytoInvolvedEntities]);

  useEffect(() => {
    if (!isLoadingTutorialData && !isFetching) {
      let step = 0;
      cropTutorialSteps[0].completed = (cropsCount || 0) > 0;
      cropTutorialSteps[1].completed = (phytoInvolvedEntitiesCount || 0) > 0;

      if (!cropTutorialSteps[0].completed) step = 0;
      else if (!cropTutorialSteps[1].completed) step = 1;
      setIsTutorialFinished(
        (cropTutorialSteps[0].completed && cropTutorialSteps[1].completed) ||
          false
      );
      setTutorialStep(step);
    }
  }, [isLoadingTutorialData, isFetching]);

  const validateForm = (values: CropPhytosanitaryTreatment) => {
    const errors: any = {};
    if (!values.crop) errors.crop = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.plantProblems || values.plantProblems.length === 0)
        errors.plantProblems = i18n.t("formErrors.requiredField");
      if (!values.startDate || !values.startTime)
        errors.startDate = i18n.t("formErrors.requiredField");
      if (!values.endDate || !values.endTime)
        errors.endDate = i18n.t("formErrors.requiredField");
      if (!values.applicators || values.applicators.length === 0)
        errors.applicators = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: CropPhytosanitaryTreatment) => {
    switch (formMode) {
      case FormMode.CREATE:
        cropPhytoTreatmentCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        cropPhytoTreatmentEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<CropPhytosanitaryTreatment>({
    initialValues: new CropPhytosanitaryTreatment(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("treatments.drawerTitleApply")
      : formik.status === FormMode.CREATE
      ? i18n.t("treatments.drawerTitleCreate")
      : i18n.t("treatments.drawerTitleEdit");
  const drawerSubtitle = i18n.t("treatments.drawerSubtitle");
  const drawerBtnText =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("words.apply")
      : formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from phyto treatment apply
  useEffect(() => {
    if (recipeId && recipeType === PhytoRecipeType.CROP)
      openDrawer(FormMode.CREATE);
  }, [recipeId, recipeType]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.CROP_PHYTO_TREATMENT &&
      siexRegister.entityClassId &&
      cropPhytoTreatments &&
      cropPhytoTreatments.length > 0
    ) {
      const cropTreatment =
        cropPhytoTreatments?.find((c) => c.id === siexRegister.entityClassId) ||
        new CropPhytosanitaryTreatment().mapToClass({
          id: siexRegister.entityClassId,
        });
      setSelectedRowToEdit(cropTreatment);
      setIsOpenDrawer(true);
    }
  }, [siexRegister, cropPhytoTreatments]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setTabValue(0);
    setSelectedRowToEdit(undefined);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, {
      state: { recipeId, recipeType, siexRegister },
    });
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedRowToEdit(undefined);
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}`);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    setTabValue(0);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const cropPhytoTreatmentCreateMutate = useCrud<CropPhytosanitaryTreatment>({
    key: "postCropPhytosanitaryTreatment",
    values: formik.values,
    onSuccess: (data: CropPhytosanitaryTreatment) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["cropPhytoTreatments"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropPhytoTreatmentEditMutate = useCrud<CropPhytosanitaryTreatment>({
    key: "putCropPhytosanitaryTreatment",
    values: formik.values,
    onSuccess: (data: CropPhytosanitaryTreatment) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["cropPhytoTreatments"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropPhytoTreatmentDeleteMutate = useCrud({
    key: "deleteCropPhytosanitaryTreatments",
    values: cropPhytoTreatments?.filter(
      (cropTreatment: CropPhytosanitaryTreatment) =>
        selectedRowIds.includes(cropTreatment?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["cropPhytoTreatments"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setTabValue(
        errors.crop
          ? 0
          : errors.plantProblems || errors.startDate || errors.endDate
          ? 1
          : 2
      );
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else {
      formik.submitForm();
    }
  };

  const handleClickEdit = (id: number) => {
    const cropTreatment =
      cropPhytoTreatments?.find((c) => c.id === id) ||
      new CropPhytosanitaryTreatment().mapToClass({ id });
    setTabValue(0);
    setSelectedRowToEdit(cropTreatment);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    cropPhytoTreatmentDeleteMutate.mutate({});
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleClickTutorialGo = () => {
    switch (tutorialStep) {
      case 0:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
      case 1:
        return navigate(
          `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}`
        );
      case 2:
      case 3:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES}`);
      default:
        break;
    }
  };

  const handleClickTutorialContinue = () => {
    if (tutorialStep < cropTutorialSteps.length - 1)
      setTutorialStep(tutorialStep + 1);
    else setIsTutorialFinished(true);
  };

  const handleClickTutorialBack = () => {
    if (tutorialStep > 0) setTutorialStep(tutorialStep - 1);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isLoadingTutorialData || isFetching} />
      {!isTutorialFinished && (
        <SectionTutorial
          steps={cropTutorialSteps}
          activeStep={tutorialStep}
          onGo={handleClickTutorialGo}
          onNext={handleClickTutorialContinue}
          onBack={handleClickTutorialBack}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("treatments.backAlertTitle")}
        contentText={i18n.t("treatments.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("treatments.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("treatments.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={cropPhytoTreatmentDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("treatments.tableTitle")}
        headerText={i18n.t("treatments.headerText")}
        colums={cropTreatmentsColumns}
        rows={cropPhytoTreatments}
        defaultOrderFieldName="startDate"
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <PestControl sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("treatments.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("treatments.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("treatments.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          cropPhytoTreatmentCreateMutate.isLoading ||
          cropPhytoTreatmentEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <CropTreatmentForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          tabValue={tabValue}
          selectedEditRow={selectedRowToEdit}
          isSiexActive={isSiexActive}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface PhytoTreatmentFormProps {
  formik:
    | FormikProps<CropPhytosanitaryTreatment>
    | FormikProps<FacilityPhytosanitaryTreatment>
    | FormikProps<PostHarvestPhytosanitaryTreatment>;
  isSiexActive: boolean;
  forceExpanded?: boolean;
  onError?: (snackBarError: SnackbarInterface) => void;
}

interface CropTreatmentFormProps {
  formik: FormikProps<CropPhytosanitaryTreatment>;
  tabValue: number;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: CropPhytosanitaryTreatment;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}

const CropTreatmentForm = (props: CropTreatmentFormProps) => {
  const {
    formik,
    isSiexActive,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    tabValue,
    onChangeTabValue,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const location = useLocation();
  const queryClient = useQueryClient();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  // Convert PhytoRecipe into CropPhytosanitaryTreatment
  const handleRecipeTreatment = (phytoRecipe: PhytoRecipe) => {
    const cropTreatment = new CropPhytosanitaryTreatment();
    cropTreatment.plantProblems = phytoRecipe.plantProblems;
    cropTreatment.crop = phytoRecipe.crop;
    if (phytoRecipe.advisor) {
      const advisor = new PhytosanitaryTreatmentAdvisor().mapToClass({
        id: phytoRecipe.advisor.id,
        advisor: phytoRecipe.advisor,
      });
      if (advisor) {
        delete advisor.id;
        cropTreatment.advisors = [advisor];
      }
    }
    cropTreatment.justifications = phytoRecipe.justifications;
    const stockMovementObj: any = {};
    stockMovementObj.idx = 1; // Needed for React table
    stockMovementObj.date = phytoRecipe.date;
    stockMovementObj.product = phytoRecipe.product;
    stockMovementObj.registeredProduct = phytoRecipe.registeredProduct;
    if (phytoRecipe.registeredProduct)
      stockMovementObj.registeredProductMeasurementUnit = phytoRecipe.doseUnit;
    stockMovementObj.type = PhytosanitaryStockMovementType.TREATMENT;
    stockMovementObj.quantity = phytoRecipe.dose;
    const stockMovement = new PhytosanitaryStockMovement().mapToClass(
      stockMovementObj
    );
    if (stockMovement) cropTreatment.products = [stockMovement];
    cropTreatment.recipe = phytoRecipe;
    formik.setValues(cropTreatment);
  };

  const { isFetching: isFetchingPhytoRecipe } = useFetch<PhytoRecipe>({
    queryKey: ["phytoRecipeById", recipeId],
    selected: { id: recipeId },
    enabled: !!recipeId && recipeType === PhytoRecipeType.CROP,
    onSuccess: handleRecipeTreatment,
    onError,
  });

  const onFetchCropPhytosanitaryTreatmentSuccess = (
    cropTreatment: CropPhytosanitaryTreatment
  ) => {
    formik.setValues(cropTreatment);
    // Update in array without refetch
    queryClient.setQueryData<CropPhytosanitaryTreatment[]>(
      ["cropPhytoTreatments", selectedABAccount?.id, selectedABPartition?.id],
      (oldData) => updateItemOfList(oldData, cropTreatment, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<CropPhytosanitaryTreatment>({
    queryKey: ["cropPhytosanitaryTreatment", selectedEditRow?.id],
    enabled:
      !!selectedEditRow?.id &&
      (!recipeId || recipeType !== PhytoRecipeType.CROP),
    selected: selectedEditRow,
    onSuccess: onFetchCropPhytosanitaryTreatmentSuccess,
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <TreatmentCropTab {...props} />;
      case 1:
        return <TreatmentProblemsTab {...props} />;
      case 2:
        return <TreatmentInvolvedEntitiesTab {...props} />;
      case 3:
        return <TreatmentAdvisorsTab {...props} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      {formik.values.recipe && (
        <Alert severity="info">
          {formik.status === FormMode.CREATE
            ? i18n.t("treatments.recipeAlertTextCreate")
            : i18n.t("treatments.recipeAlertTextEdit")}
        </Alert>
      )}
      <LoadingWithDelay isVisible={isUpdating || isFetchingPhytoRecipe} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <Tabs
        className="tab-container"
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.crop.tab.crop")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.crop.tab.treatment")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.crop.tab.applicator")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.crop.tab.advisor")}
        />
      </Tabs>
      {renderTab()}
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("treatments.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const TreatmentCropTab = (props: CropTreatmentFormProps) => {
  const { formik } = props;

  const { agroBusinessPartitions } = useSession();

  return (
    <div>
      <TreatmentCropForm {...props} />
      <TreatmentSectorsForm {...props} />
      <TreatmentDefenseActionForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const TreatmentCropForm = (props: CropTreatmentFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const { data: crops, isFetching } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  // Crop object not complete in the phytoRecipe
  useEffect(() => {
    if (formik.values.recipe && formik.values.crop?.id && crops) {
      const selectedCrop = crops.find(
        (crop: Crop) => crop.id === formik.values.crop?.id
      );
      if (selectedCrop) formik.setFieldValue("crop", selectedCrop);
    }
  }, [formik.values.recipe, formik.values.crop, crops]);

  const handleChangeCrop = (crop: Crop) => {
    formik.setFieldValue("crop", crop);
    formik.setFieldValue("treatedSectors", []);
  };

  const handleClickNewOption = () =>
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);

  return (
    <BrioCard
      title={i18n.t("treatments.crop.cropForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          {!isFetching && crops?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewOption}>
              {i18n.t("treatments.crop.cropForm.emptyCrops")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="crop"
            label={i18n.t("treatments.crop.cropForm.crop")}
            placeholder={i18n.t("treatments.crop.cropForm.cropPlaceholder")}
            options={crops || []}
            optionLabelFieldName="productNameWithVarieties"
            required
            startIcon={Search}
            loading={isFetching}
            addNewOption
            onClickNewOption={handleClickNewOption}
            onChange={handleChangeCrop}
            disabled={formik.status === FormMode.EDIT || !!formik.values.recipe}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="treatedArea"
            type="number"
            label={i18n.t("treatments.crop.cropForm.treatedArea")}
            valueUnit="ha"
            helperText={i18n.t(
              "treatments.crop.cropForm.treatedAreaHelperText"
            )}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentSectorsForm = (props: CropTreatmentFormProps) => {
  const cropTreatedSectorColumns: Column[] = [
    {
      id: "sector.name",
      label: i18n.t("treatments.crop.sectorsForm.column.name"),
      numeric: false,
    },
    {
      id: "treatedArea",
      label: i18n.t("treatments.crop.sectorsForm.column.treatedArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
  ];

  const { formik, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedTreatedSector, setSelectedTreatedSector] =
    useState<CropPhytosanitaryTreatmentSector | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedTreatedSector(null);
  };

  const handleClickAdd = () => {
    if (!formik.values.crop && onError) {
      onError({
        severity: "info",
        message: i18n.t("formErrors.selectOneOption"),
      } as SnackbarInterface);
    } else {
      setFormMode(FormMode.CREATE);
      openDialog();
    }
  };

  // Only dialog to create or edit treated sector
  const handleConfirmDialog = (selected: CropPhytosanitaryTreatmentSector) => {
    const updatedTreatedSectors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.treatedSectors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.treatedSectors, selected, "idx");
    formik.setFieldValue("treatedSectors", updatedTreatedSectors);
    formik.setFieldValue(
      "treatedArea",
      calculateTotalTreatedArea(updatedTreatedSectors)
    );
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.treatedSectors?.find(
      (cs: CropPhytosanitaryTreatmentSector) => cs.idx === idx
    );
    if (selected) {
      setSelectedTreatedSector(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    const updatedTreatedSectors = deleteItemsOfListByField(
      formik.values.treatedSectors,
      idxs,
      "idx"
    );
    formik.setFieldValue("treatedSectors", updatedTreatedSectors);
    formik.setFieldValue(
      "treatedArea",
      calculateTotalTreatedArea(updatedTreatedSectors)
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("treatments.crop.sectorsForm.tableTitle")}
        headerText={i18n.t("treatments.crop.sectorsForm.headerText")}
        btnText={i18n.t("treatments.crop.sectorsForm.tableBtnText")}
        colums={cropTreatedSectorColumns}
        rows={formik.values.treatedSectors}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogCropTreatedSector
        open={isOpenDialog}
        crop={formik.values.crop}
        selected={selectedTreatedSector}
        treatedSectors={formik.values.treatedSectors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

const CropTreatmentDatesForm = (props: PhytoTreatmentFormProps) => {
  const { formik, forceExpanded, isSiexActive } = props;

  if (!(formik.values instanceof CropPhytosanitaryTreatment)) return null;

  const handleChangeStartDateTime = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD");
    const timeStr = date?.format("HH:mm:ss");
    formik.setFieldValue("startDate", dateStr);
    formik.setFieldValue("startTime", timeStr);
    formik.setFieldValue("endDate", dateStr);
    formik.setFieldValue("endTime", timeStr);
  };

  const handleChangeEndDateTime = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD");
    const timeStr = date?.format("HH:mm:ss");
    formik.setFieldValue("endDate", dateStr);
    formik.setFieldValue("endTime", timeStr);
  };

  return (
    <BrioCard
      title={i18n.t("treatments.crop.datesForm.title")}
      defaultExpanded={isSiexActive}
      forceExpanded={isSiexActive && forceExpanded}
      required={isSiexActive}
      optional={!isSiexActive}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm:ss"
            label={`${i18n.t("treatments.crop.cropDatesForm.startDate")}${
              isSiexActive ? "*" : ""
            }`}
            value={
              formik.values?.startDate && formik.values?.startTime
                ? moment(
                    `${formik.values.startDate} ${formik.values.startTime}`,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : null
            }
            onChange={handleChangeStartDateTime}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm:ss"
            label={`${i18n.t("treatments.crop.cropDatesForm.endDate")}${
              isSiexActive ? "*" : ""
            }`}
            value={
              formik.values?.endDate && formik.values?.endTime
                ? moment(
                    `${formik.values.endDate} ${formik.values.endTime}`,
                    "YYYY-MM-DD HH:mm:ss"
                  )
                : null
            }
            onChange={handleChangeEndDateTime}
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentDatesForm = (props: PhytoTreatmentFormProps) => {
  const { formik, forceExpanded, isSiexActive } = props;

  const handleChangeStartDate = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD");
    formik.setFieldValue("startDate", dateStr);
    formik.setFieldValue("endDate", dateStr);
  };

  return (
    <BrioCard
      title={i18n.t("treatments.crop.datesForm.title")}
      defaultExpanded={isSiexActive}
      forceExpanded={isSiexActive && forceExpanded}
      required={isSiexActive}
      optional={!isSiexActive}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={`${i18n.t("treatments.crop.datesForm.startDate")}${
              isSiexActive ? "*" : ""
            }`}
            value={
              formik.values?.startDate
                ? moment(formik.values.startDate, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeStartDate}
          />
          {formik.touched.startDate && formik.errors.startDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={i18n.t("treatments.crop.datesForm.endDate")}
            value={
              formik.values?.endDate
                ? moment(formik.values.endDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue("endDate", date?.format("YYYY-MM-DD"))
            }
          />
          {formik.touched.endDate && formik.errors.endDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentProductsForm = (props: PhytoTreatmentFormProps) => {
  const phytosanitaryProductColumns: Column[] = [
    {
      id: "product.name",
      label: i18n.t("treatments.crop.productsForm.column.name"),
      numeric: false,
      valueLabel: "or",
      id2: "registeredProduct.name",
    },
    {
      id: "quantityUnitFormatted",
      label: i18n.t("treatments.crop.productsForm.column.quantity"),
      numeric: true,
    },
  ];

  const { formik, isSiexActive, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [editSelected, setEditSelected] = useState<
    PhytosanitaryStockMovement | undefined
  >(undefined);
  const [formMode, setFormMode] = useState<FormMode | null>(null);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => setIsOpenDialog(false);

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };
  const handleClickClose = () => {
    closeDialog();
    setEditSelected(undefined);
  };

  const handleClickSave = (
    phytoStockMovement: PhytosanitaryStockMovement,
    plantProblems: PlantProblem[],
    treatedArea?: number
  ) => {
    const stockMovements = formik.values?.products || [];
    const newStockMovements =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            stockMovements,
            phytoStockMovement,
            "idx"
          )
        : updateItemOfList(stockMovements, phytoStockMovement, "idx");
    formik.setFieldValue("products", newStockMovements);
    // Join plantProblems to formik plantProblems values uniques by plantProblem.id
    const newPlantProblems = uniqueById([
      ...(formik.values.plantProblems || []),
      ...plantProblems,
    ]);
    formik.setFieldValue("plantProblems", newPlantProblems);
    formik.setFieldValue("treatedArea", treatedArea);

    setFormMode(null);
    setSelectedRows([]);
    closeDialog();
  };

  const handleClickEdit = (id: number) => {
    setFormMode(FormMode.EDIT);
    setEditSelected(
      formik.values?.products?.find(
        (sm: PhytosanitaryStockMovement) => sm.idx === id
      )
    );
    openDialog();
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      const stockMovements = formik.values?.products || [];
      const newStockMovements = deleteItemsOfListByField(
        stockMovements,
        ids,
        "idx"
      );
      formik.setFieldValue("products", newStockMovements);
      setFormMode(null);
      setSelectedRows([]);
    }
  };

  const handleOnRemovePlantProblem = (plantProblem: PlantProblem) => {
    const plantProblems = formik.values.plantProblems?.filter(
      (problem) => problem.id !== plantProblem.id
    );
    formik.setFieldValue("plantProblems", plantProblems);
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("treatments.crop.productsForm.tableTitlev2")}
        headerText={i18n.t("treatments.crop.productsForm.headerTextv2")}
        colums={phytosanitaryProductColumns}
        rows={formik.values?.products || []}
        selectedRows={selectedRows}
        emptyTableCard={false}
        disablePagination
        required={isSiexActive}
        hideBtn={!!formik.values.recipe}
        disabled={!!formik.values.recipe}
        onChangeSelectedRows={setSelectedRows}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <TreatmentPlantProblemsList
        plantProblems={formik.values.plantProblems}
        isFromPhytoRecipe={!!formik.values.recipe}
        onRemove={handleOnRemovePlantProblem}
      />
      <DialogPhytosanitaryProduct
        title={i18n.t("treatments.crop.productsForm.dialog.title")}
        body={i18n.t("treatments.crop.productsForm.dialog.description")}
        open={isOpenDialog}
        selected={editSelected}
        selectedCrop={
          formik.values instanceof CropPhytosanitaryTreatment
            ? formik.values.crop
            : undefined
        }
        cropTreatedArea={
          formik.values instanceof CropPhytosanitaryTreatment
            ? formik.values.treatedArea
            : undefined
        }
        stockMovements={formik.values?.products || []}
        onConfirm={handleClickSave}
        onClose={handleClickClose}
        onError={onError}
      />
    </>
  );
};

interface TreatmentPostHarvestProductFormProps {
  selected?: PhytosanitaryStockMovement;
  plantProblems?: PlantProblem[];
  postHarvestQuantity?: number;
  postHarvestUnit?: MeasurementUnit;
  disabled?: boolean;
  onChangePlantProblems?: (plantProblems: PlantProblem[]) => void;
  onChangeStockMovement?: (stockMovement: PhytosanitaryStockMovement) => void;
  onConflicts?: (conflicts: boolean) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const TreatmentPostHarvestProductForm = (
  props: TreatmentPostHarvestProductFormProps
) => {
  const {
    selected,
    plantProblems = [],
    postHarvestQuantity,
    postHarvestUnit,
    disabled = false,
    onChangePlantProblems,
    onChangeStockMovement,
    onConflicts,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const [dose, setDose] = useState<number>(0);

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      PRODUCT_MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const validateForm = (values: PhytosanitaryStockMovement) => {
    const errors: any = {};
    if (!values?.quantity || values?.quantity <= 0)
      errors.quantity = i18n.t("formErrors.requiredField");
    if (!values?.product && !values?.registeredProduct)
      errors.product = i18n.t("formErrors.requiredField");
    if (values?.registeredProduct && !values?.registeredProductMeasurementUnit)
      errors.registeredProductMeasurementUnit = i18n.t(
        "formErrors.requiredField"
      );
    return errors;
  };

  const formik = useFormik<PhytosanitaryStockMovement>({
    initialValues:
      selected ||
      new PhytosanitaryStockMovement().mapToClass({
        type: PhytosanitaryStockMovementType.TREATMENT,
      }) ||
      new PhytosanitaryStockMovement(),
    validate: validateForm,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (selected) {
      formik.setValues(selected);
      if (selected.quantity && postHarvestQuantity && postHarvestQuantity > 0)
        setDose(
          Math.round((selected.quantity / postHarvestQuantity) * 100) / 100
        );
    }
  }, [selected]);

  useEffect(() => {
    if (
      postHarvestQuantity &&
      postHarvestQuantity > 0 &&
      formik.values.quantity &&
      formik.values.quantity > 0
    ) {
      setDose(
        Math.round((formik.values.quantity / postHarvestQuantity) * 100) / 100
      );
    }
  }, [postHarvestQuantity]);

  useEffect(() => {
    onChangeStockMovement && onChangeStockMovement(formik.values);
    formik.setTouched(formik.errors as FormikTouched<any>);
    (formik.touched.quantity ||
      formik.touched.product ||
      formik.touched.registeredProduct ||
      formik.touched.registeredProductMeasurementUnit) &&
      formik.submitForm();
  }, [formik.values]);

  const handleOnChangeProduct = (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  ) => {
    if (!product || product instanceof RegisteredPhytosanitaryProduct) {
      formik.setFieldValue("registeredProduct", product);
      formik.setFieldValue("product", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", false);
    } else if (product.registeredPhytosanitaryProduct) {
      formik.setFieldValue(
        "registeredProduct",
        product.registeredPhytosanitaryProduct
      );
      formik.setFieldValue(
        "registeredProductMeasurementUnit",
        product.measurementUnit
      );
      formik.setFieldValue("product", null);
      formik.setFieldValue("isInventoryProduct", true);
    } else {
      formik.setFieldValue("product", product);
      formik.setFieldValue("registeredProduct", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", true);
    }
  };

  const handleOnChangePlantProblems = (plantProblems: PlantProblem[]) => {
    // Filter weed problems
    const plantProblemsFiltered = plantProblems.filter(
      (problem) => problem.type !== PlantProblemType.WEED
    );
    onChangePlantProblems && onChangePlantProblems(plantProblemsFiltered);
  };

  const handleOnRemovePlantProblem = (plantProblem: PlantProblem) => {
    const pp =
      plantProblems?.filter((problem) => problem.id !== plantProblem.id) || [];
    onChangePlantProblems && onChangePlantProblems(pp);
  };

  const handleChangeDose = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setDose(value);
    if (postHarvestQuantity)
      formik.setFieldValue(
        "quantity",
        Math.round(value * postHarvestQuantity * 100) / 100
      );
  };

  const handleChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = parseFloat(e.target.value);
    formik.setFieldValue("quantity", quantity);
    if (postHarvestQuantity && postHarvestQuantity > 0)
      setDose(Math.round((quantity / postHarvestQuantity) * 100) / 100);
  };

  return (
    <BrioCard
      title={i18n.t("treatments.productForm.title")}
      disableAccordion
      defaultExpanded
      required
    >
      <FormGroup className="form-group">
        <ProductInput
          selectedPlantProblems={plantProblems}
          value={
            formik.values.registeredProduct ||
            formik.values.product?.registeredPhytosanitaryProduct ||
            formik.values.product
          }
          inputError={
            Object.entries(formik.touched).length > 0 &&
            (!!formik.errors.product || !!formik.errors.registeredProduct)
          }
          disabled={disabled}
          onChange={handleOnChangeProduct}
          onError={onError}
        />
        {(formik.values.product || formik.values.registeredProduct) &&
          !formik.values.isInventoryProduct && (
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="registeredProductMeasurementUnit"
                label={i18n.t("treatments.productForm.quantityUnitLabel")}
                optionLabelFieldName="name"
                required
                options={measurementUnits || []}
                isLoading={isFetchingMeasurementUnits}
              />
            </FormControl>
          )}
        {!disabled && (
          <PlantProblemsInput
            type={PhytosanitaryTreatmentType.POST_HARVEST}
            selectedProduct={
              formik.values.registeredProduct ||
              formik.values.product?.registeredPhytosanitaryProduct ||
              formik.values.product
            }
            value={plantProblems}
            inputError={
              isSiexActive &&
              Object.entries(formik.touched).length > 0 &&
              plantProblems.length === 0
            }
            onChange={handleOnChangePlantProblems}
            onError={onError}
          />
        )}
        <div className="recipes-view">
          <PlantProblemList
            plantProblems={plantProblems}
            selectedProduct={
              formik.values.registeredProduct ||
              formik.values.product?.registeredPhytosanitaryProduct ||
              formik.values.product
            }
            disabled={disabled}
            onConflicts={onConflicts}
            onRemove={handleOnRemovePlantProblem}
            onError={onError}
          />
        </div>
        <FormControl
          variant="outlined"
          className="form-control-row"
          sx={{ mt: plantProblems.length > 0 ? 2 : 0 }}
        >
          <TextField
            type="number"
            className="form-input-row"
            label={i18n.t("treatments.productForm.doseLabel")}
            value={dose}
            onChange={handleChangeDose}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Sanitizer style={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: `${
                formik.values.product?.measurementUnit?.name ||
                formik.values.registeredProductMeasurementUnit?.name ||
                "?"
              }/${postHarvestUnit?.name || "?"}`,
            }}
          />
          <FormikTextField
            formik={formik}
            name="quantity"
            className="form-input-row"
            label={i18n.t("treatments.productForm.quantityLabel")}
            type="number"
            required
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Sanitizer style={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: formik.values.isInventoryProduct && (
                <InputAdornment position="end">
                  {formik.values.product?.measurementUnit?.name ||
                    formik.values.registeredProductMeasurementUnit?.name}
                </InputAdornment>
              ),
            }}
            onChange={handleChangeQuantity}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

interface TreatmentFacilityProductFormProps {
  selected?: PhytosanitaryStockMovement;
  plantProblems?: PlantProblem[];
  disabled?: boolean;
  onChangePlantProblems?: (plantProblems: PlantProblem[]) => void;
  onChangeStockMovement?: (stockMovement: PhytosanitaryStockMovement) => void;
  onConflicts?: (conflicts: boolean) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const TreatmentFacilityProductForm = (
  props: TreatmentFacilityProductFormProps
) => {
  const {
    selected,
    plantProblems = [],
    disabled = false,
    onChangePlantProblems,
    onChangeStockMovement,
    onConflicts,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      RECIPE_MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const validateForm = (values: PhytosanitaryStockMovement) => {
    const errors: any = {};
    if (!values?.quantity || values?.quantity <= 0)
      errors.quantity = i18n.t("formErrors.requiredField");
    if (!values?.product && !values?.registeredProduct)
      errors.product = i18n.t("formErrors.requiredField");
    if (values?.registeredProduct && !values?.registeredProductMeasurementUnit)
      errors.registeredProductMeasurementUnit = i18n.t(
        "formErrors.requiredField"
      );
    return errors;
  };

  const formik = useFormik<PhytosanitaryStockMovement>({
    initialValues:
      selected ||
      new PhytosanitaryStockMovement().mapToClass({
        type: PhytosanitaryStockMovementType.TREATMENT,
      }) ||
      new PhytosanitaryStockMovement(),
    validate: validateForm,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (selected) formik.setValues(selected);
  }, [selected]);

  useEffect(() => {
    onChangeStockMovement && onChangeStockMovement(formik.values);
    formik.setTouched(formik.errors as FormikTouched<any>);
    (formik.touched.quantity ||
      formik.touched.product ||
      formik.touched.registeredProduct ||
      formik.touched.registeredProductMeasurementUnit) &&
      formik.submitForm();
  }, [formik.values]);

  const handleOnChangeProduct = (
    product: RegisteredPhytosanitaryProduct | PhytosanitaryProduct | null
  ) => {
    if (!product || product instanceof RegisteredPhytosanitaryProduct) {
      formik.setFieldValue("registeredProduct", product);
      formik.setFieldValue("product", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", false);
    } else if (product.registeredPhytosanitaryProduct) {
      formik.setFieldValue(
        "registeredProduct",
        product.registeredPhytosanitaryProduct
      );
      formik.setFieldValue(
        "registeredProductMeasurementUnit",
        product.measurementUnit
      );
      formik.setFieldValue("product", null);
      formik.setFieldValue("isInventoryProduct", true);
    } else {
      formik.setFieldValue("product", product);
      formik.setFieldValue("registeredProduct", null);
      formik.setFieldValue("registeredProductMeasurementUnit", null);
      formik.setFieldValue("isInventoryProduct", true);
    }
  };

  const handleOnChangePlantProblems = (plantProblems: PlantProblem[]) => {
    // Filter weeds and regulators
    const plantProblemsFiltered = plantProblems.filter(
      (problem) =>
        problem.type !== PlantProblemType.WEED &&
        problem.type !== PlantProblemType.REGULATORS
    );
    onChangePlantProblems && onChangePlantProblems(plantProblemsFiltered);
  };

  const handleOnRemovePlantProblem = (plantProblem: PlantProblem) => {
    const pp =
      plantProblems?.filter((problem) => problem.id !== plantProblem.id) || [];
    onChangePlantProblems && onChangePlantProblems(pp);
  };

  return (
    <BrioCard
      title={i18n.t("treatments.productForm.title")}
      disableAccordion
      defaultExpanded
      required
    >
      <FormGroup className="form-group">
        <ProductInput
          selectedPlantProblems={plantProblems}
          value={
            formik.values.registeredProduct ||
            formik.values.product?.registeredPhytosanitaryProduct ||
            formik.values.product
          }
          inputError={
            Object.entries(formik.touched).length > 0 &&
            (!!formik.errors.product || !!formik.errors.registeredProduct)
          }
          disabled={disabled}
          onChange={handleOnChangeProduct}
          onError={onError}
        />
        {!disabled && (
          <PlantProblemsInput
            type={PhytosanitaryTreatmentType.FACILITY}
            selectedProduct={
              formik.values.registeredProduct ||
              formik.values.product?.registeredPhytosanitaryProduct ||
              formik.values.product
            }
            value={plantProblems}
            inputError={
              isSiexActive &&
              Object.entries(formik.touched).length > 0 &&
              plantProblems.length === 0
            }
            onChange={handleOnChangePlantProblems}
            onError={onError}
          />
        )}

        <div className="recipes-view">
          <PlantProblemList
            plantProblems={plantProblems}
            selectedProduct={
              formik.values.registeredProduct ||
              formik.values.product?.registeredPhytosanitaryProduct ||
              formik.values.product
            }
            disabled={disabled}
            onConflicts={onConflicts}
            onRemove={handleOnRemovePlantProblem}
            onError={onError}
          />
        </div>
        <FormControl
          variant="outlined"
          className="form-control-row"
          sx={{ mt: plantProblems.length > 0 ? 2 : 0 }}
        >
          <FormikTextField
            formik={formik}
            name="quantity"
            className="form-input"
            label={i18n.t("treatments.productForm.quantityLabel")}
            type="number"
            required
            disabled={disabled}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Sanitizer style={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: formik.values.isInventoryProduct && (
                <InputAdornment position="end">
                  {formik.values.product?.measurementUnit?.name ||
                    formik.values.registeredProductMeasurementUnit?.name}
                </InputAdornment>
              ),
            }}
          />
          {!formik.values.isInventoryProduct && (
            <FormikSelect
              formik={formik}
              type="row"
              name="registeredProductMeasurementUnit"
              label={i18n.t("treatments.productForm.quantityUnitLabel")}
              optionLabelFieldName="name"
              required
              options={measurementUnits || []}
              isLoading={isFetchingMeasurementUnits}
            />
          )}
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentPlantProblemsList = ({
  plantProblems = [],
  isFromPhytoRecipe = false,
  onRemove,
}: {
  plantProblems?: PlantProblem[];
  isFromPhytoRecipe?: boolean;
  onRemove?: (plantProblem: PlantProblem) => void;
}) => {
  const plantProblemsByType = useMemo(() => {
    const problemsByType = new Map<string, PlantProblem[]>();
    plantProblems.forEach((problem) => {
      const type = problem.problemType?.name || "";
      if (problemsByType.has(type)) {
        problemsByType.get(type)?.push(problem);
      } else {
        problemsByType.set(type, [problem]);
      }
    });
    return problemsByType;
  }, [plantProblems]);

  return (
    <List className="plant-problem">
      {Array.from(plantProblemsByType.keys()).map((type) => (
        <React.Fragment key={type}>
          <ListSubheader className="subheader">{type}</ListSubheader>
          {plantProblemsByType.get(type)?.map((problem) => (
            <ListItem key={problem.id} className="list-item">
              <ListItemText>{problem.name}</ListItemText>
              {!isFromPhytoRecipe && (
                <ListItemSecondaryAction>
                  <IconButton onClick={() => onRemove && onRemove(problem)}>
                    <Close />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </React.Fragment>
      ))}
    </List>
  );
};

const TreatmentJustificationForm = (props: PhytoTreatmentFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: phytoActionJustificationTypes, isFetching } = useFetch<
    PhytosanitaryActionJustificationType[]
  >({
    queryKey: [
      "phytosanitaryActionJustificationTypes",
      selectedABAccount?.context?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("treatments.crop.justificationForm.title")}
      optional
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="justifications"
            label={i18n.t("treatments.crop.justificationForm.justifications")}
            placeholder={i18n.t(
              "treatments.crop.justificationForm.justificationPlaceholder"
            )}
            multiple
            options={phytoActionJustificationTypes || []}
            optionLabelFieldName="name"
            loading={isFetching}
            disabled={!!formik.values.recipe}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentResultForm = (
  props: PhytoTreatmentFormProps | TreatedSeedFormProps
) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: treatmentEfficacyTypes, isFetching } = useFetch<
    TreatmentEfficacy[]
  >({
    queryKey: ["treatmentEfficacyTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("treatments.crop.treatmentResultForm.title")}
      optional
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            className="form-input"
            formik={formik}
            name="efficacy"
            label={i18n.t("treatments.crop.treatmentResultForm.efficacy")}
            options={treatmentEfficacyTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetching}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="observations"
            label={i18n.t("treatments.crop.treatmentResultForm.observations")}
            multiline
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentProblemsTab = (props: PhytoTreatmentFormProps) => {
  const { formik } = props;

  const { agroBusinessPartitions } = useSession();

  return (
    <>
      <TreatmentProductsForm {...props} />
      <CropTreatmentDatesForm {...props} />
      <TreatmentJustificationForm {...props} />
      <TreatmentResultForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const TreatmentDefenseActionForm = (props: CropTreatmentFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: phytosanitaryActionTypes, isFetching: isFetchingActionTypes } =
    useFetch<PhytosanitaryActionType[]>({
      queryKey: ["phytosanitaryActionTypes", selectedABAccount?.context?.id],
      enabled: formik.values.defenseAction,
      onError,
    });

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      enabled: formik.values.isDefenseQtyVisible(),
      onError,
    });

  return (
    <BrioCard
      title={i18n.t("treatments.crop.defenseActionForm.title")}
      defaultExpanded
      optional
    >
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="defenseAction"
            primaryText={i18n.t(
              "treatments.crop.defenseActionForm.defenseAction"
            )}
            secondaryText={i18n.t(
              "treatments.crop.defenseActionForm.defenseActionSubtitle"
            )}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={formik.values?.defenseAction}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                className="form-input"
                formik={formik}
                name="defenseActionType"
                label={i18n.t(
                  "treatments.crop.defenseActionForm.defenseActionType"
                )}
                options={phytosanitaryActionTypes || []}
                optionLabelFieldName="name"
                isLoading={isFetchingActionTypes}
              />
            </FormControl>
            <Collapse
              className="collapse-container"
              in={formik.values.isDefenseQtyVisible()}
            >
              <div className="grow-container">
                <FormControl variant="outlined" className="form-control-row">
                  <FormikTextField
                    formik={formik}
                    className="form-input"
                    name="defenseActionQuantity"
                    type="number"
                    label={i18n.t(
                      "treatments.crop.defenseActionForm.defenseActionQuantity"
                    )}
                  />
                  <FormikSelect
                    formik={formik}
                    type="row"
                    name="defenseActionQuantityUnit"
                    label={i18n.t(
                      "treatments.crop.defenseActionForm.defenseActionQuantityUnit"
                    )}
                    optionLabelFieldName="name"
                    options={formik.values.getMeasurementUnitsByDefenseAction(
                      measurementUnits
                    )}
                    isLoading={isFetchingMeasurementUnits}
                  />
                </FormControl>
              </div>
            </Collapse>

            <Collapse
              className="collapse-container"
              in={formik.values.isDefenseRegisterIdVisible()}
            >
              <div className="grow-container">
                <FormControl
                  sx={{ marginTop: "20px" }}
                  variant="outlined"
                  className="form-control"
                >
                  <FormikTextField
                    formik={formik}
                    className="form-input"
                    name="defenseActionRegisterId"
                    label={i18n.t(
                      "treatments.crop.defenseActionForm.defenseActionRegisterId"
                    )}
                    placeholder={i18n.t(
                      "treatments.crop.defenseActionForm.defenseActionRegisterIdPlaceholder"
                    )}
                  />
                </FormControl>
              </div>
            </Collapse>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentInvolvedEntitiesTab = (props: PhytoTreatmentFormProps) => {
  const { formik } = props;

  const { agroBusinessPartitions } = useSession();

  return (
    <div>
      <TreatmentInvolvedEntitiesForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const TreatmentInvolvedEntitiesForm = (props: PhytoTreatmentFormProps) => {
  const treatmentApplicatorColumns: Column[] = [
    {
      id: "applicator.entity.name",
      label: i18n.t("treatments.crop.involvedEntitiesForm.column.name"),
      numeric: false,
    },
    {
      id: "applicator.trainingLevelType.name",
      label: i18n.t(
        "treatments.crop.involvedEntitiesForm.column.trainingLevel"
      ),
      numeric: false,
    },
    {
      id: "tools",
      label: i18n.t("treatments.crop.involvedEntitiesForm.column.tools"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "name",
    },
  ];

  const { formik, isSiexActive, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedApplicator, setSelectedApplicator] =
    useState<PhytosanitaryTreatmentApplicator | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedApplicator(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (selected: PhytosanitaryTreatmentApplicator) => {
    const updatedEntities =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.applicators,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.applicators, selected, "idx");
    formik.setFieldValue("applicators", updatedEntities);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.applicators?.find(
      (applicator: PhytosanitaryTreatmentApplicator) => applicator.idx === idx
    );
    if (selected) {
      setSelectedApplicator(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "applicators",
      deleteItemsOfListByField(formik.values.applicators, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("treatments.crop.involvedEntitiesForm.tableTitle")}
        headerText={i18n.t("treatments.crop.involvedEntitiesForm.headerText")}
        btnText={i18n.t("treatments.crop.involvedEntitiesForm.tableBtnText")}
        colums={treatmentApplicatorColumns}
        rows={formik.values.applicators || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        required={isSiexActive}
        optional={!isSiexActive}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogTreatmentApplicator
        open={isOpenDialog}
        isSiexActive={isSiexActive}
        selected={selectedApplicator}
        applicators={formik.values.applicators}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
        onError={onError}
      />
    </>
  );
};

const TreatmentAdvisorsTab = (props: PhytoTreatmentFormProps) => {
  const { formik } = props;

  const { agroBusinessPartitions } = useSession();

  return (
    <div>
      <TreatmentAdvisorsForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const TreatmentAdvisorsForm = (props: PhytoTreatmentFormProps) => {
  const treatmentAdvisorColumns: Column[] = [
    {
      id: "advisor.entity.name",
      label: i18n.t("treatments.crop.advisorForm.column.name"),
      numeric: false,
    },
    {
      id: "validationDate",
      label: i18n.t("treatments.crop.advisorForm.column.validationDate"),
      numeric: false,
    },
    {
      id: "validationConfirmed",
      label: i18n.t("treatments.crop.advisorForm.column.validationConfirmed"),
      numeric: false,
      valueLabel: "boolean",
    },
  ];

  const { formik, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedAdvisor, setSelectedAdvisor] =
    useState<PhytosanitaryTreatmentAdvisor | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedAdvisor(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (selected: PhytosanitaryTreatmentAdvisor) => {
    const updatedAdvisors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.advisors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.advisors, selected, "idx");
    formik.setFieldValue("advisors", updatedAdvisors);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.advisors?.find(
      (advisor: PhytosanitaryTreatmentAdvisor) => advisor.idx === idx
    );
    if (selected) {
      setSelectedAdvisor(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "advisors",
      deleteItemsOfListByField(formik.values.advisors, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("treatments.crop.advisorForm.tableTitle")}
        headerText={i18n.t("treatments.crop.advisorForm.headerText")}
        btnText={i18n.t("treatments.crop.advisorForm.tableBtnText")}
        colums={treatmentAdvisorColumns}
        rows={formik.values.advisors || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogTreatmentAdvisor
        open={isOpenDialog}
        selected={selectedAdvisor}
        advisors={formik.values.advisors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
        onError={onError}
      />
    </>
  );
};

const PostHarvestTab = () => {
  const postHarvestTreatmentsColumns: Column[] = [
    {
      id: "product.name",
      label: i18n.t("treatments.postHarvest.column.name"),
      numeric: false,
    },
    {
      id: "dateRange",
      label: i18n.t("treatments.postHarvest.column.dateRange"),
      numeric: false,
    },
    {
      id: "quantity",
      label: i18n.t("treatments.postHarvest.column.quantity_v2"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "quantityUnit.name",
    },
    {
      id: "efficacy.name",
      label: i18n.t("treatments.postHarvest.column.efficacy"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<
    PostHarvestPhytosanitaryTreatment | undefined
  >(undefined);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);
  const [
    isOpenConfirmWithConflictsDialog,
    setIsOpenConfirmWithConflictsDialog,
  ] = useState<boolean>(false);
  const [plantProblemConflicts, setPlantProblemConflicts] =
    useState<boolean>(false);

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const { data: postHarvestPhytoTreatments, isFetching } = useFetch<
    PostHarvestPhytosanitaryTreatment[]
  >({
    queryKey: [
      "postHarvestPhytoTreatments",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const validateForm = (values: PostHarvestPhytosanitaryTreatment) => {
    const errors: any = {};
    if (!values.product) errors.product = i18n.t("formErrors.requiredField");
    if (
      !values.products ||
      values.products.length === 0 ||
      (!values.products[0].product && !values.products[0].registeredProduct)
    )
      errors.products = i18n.t("formErrors.requiredField");
    if (
      values.products?.[0]?.registeredProduct &&
      !values.products?.[0]?.registeredProductMeasurementUnit
    )
      errors.products = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.quantity)
        errors.quantity = i18n.t("formErrors.requiredField");
      if (!values.plantProblems || values.plantProblems.length === 0)
        errors.plantProblems = i18n.t("formErrors.requiredField");
      if (!values.startDate)
        errors.startDate = i18n.t("formErrors.requiredField");
      if (!values.applicators || values.applicators.length === 0)
        errors.applicators = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: PostHarvestPhytosanitaryTreatment) => {
    switch (formMode) {
      case FormMode.CREATE:
        postHarvestPhytoTreatmentCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        postHarvestPhytoTreatmentEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<PostHarvestPhytosanitaryTreatment>({
    initialValues: new PostHarvestPhytosanitaryTreatment(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("treatments.postHarvest.drawerTitleApply")
      : formik.status === FormMode.CREATE
      ? i18n.t("treatments.postHarvest.drawerTitleCreate")
      : i18n.t("treatments.postHarvest.drawerTitleEdit");
  const drawerSubtitle = i18n.t("treatments.postHarvest.drawerSubtitle");
  const drawerBtnText =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("words.apply")
      : formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from phyto treatment apply
  useEffect(() => {
    if (recipeId && recipeType === PhytoRecipeType.POSTHARVEST)
      openDrawer(FormMode.CREATE);
  }, [recipeId, recipeType]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName ===
        FBEntitiesTypes.POSTHARVEST_PHYTO_TREATMENT &&
      siexRegister.entityClassId &&
      postHarvestPhytoTreatments &&
      postHarvestPhytoTreatments.length > 0
    ) {
      const postHarvestTreatment =
        postHarvestPhytoTreatments?.find(
          (t) => t.id === siexRegister.entityClassId
        ) ||
        new PostHarvestPhytosanitaryTreatment().mapToClass({
          id: siexRegister.entityClassId,
        });
      setSelectedRowToEdit(postHarvestTreatment);
      setIsOpenDrawer(true);
    }
  }, [siexRegister, postHarvestPhytoTreatments]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setTabValue(0);
    setSelectedRowToEdit(undefined);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, {
      state: { recipeId, recipeType, siexRegister },
    });
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedRowToEdit(undefined);
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}`);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const openConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(true);
  const cancelConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    setTabValue(0);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const postHarvestPhytoTreatmentCreateMutate =
    useCrud<PostHarvestPhytosanitaryTreatment>({
      key: "postPostHarvestPhytosanitaryTreatment",
      values: formik.values,
      onSuccess: (data: PostHarvestPhytosanitaryTreatment) => {
        // Update list
        queryClient.refetchQueries({
          queryKey: ["postHarvestPhytoTreatments"],
          type: "active",
        });
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.createSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const postHarvestPhytoTreatmentEditMutate =
    useCrud<PostHarvestPhytosanitaryTreatment>({
      key: "putPostHarvestPhytosanitaryTreatment",
      values: formik.values,
      onSuccess: (data: PostHarvestPhytosanitaryTreatment) => {
        // Update list
        queryClient.refetchQueries({
          queryKey: ["postHarvestPhytoTreatments"],
          type: "active",
        });
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.updateSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const postHarvestPhytoTreatmentDeleteMutate = useCrud({
    key: "deletePostHarvestPhytosanitaryTreatments",
    values: postHarvestPhytoTreatments?.filter(
      (postHarvestTreatment: PostHarvestPhytosanitaryTreatment) =>
        selectedRowIds.includes(postHarvestTreatment?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["postHarvestPhytoTreatments"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setTabValue(
        Object.keys(errors).length === 1 && errors.applicators ? 1 : 0
      );
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else if (plantProblemConflicts && !formik.values.recipe)
      openConfirmWithConflictsAlertDialog();
    else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const postHarvestTreatment =
      postHarvestPhytoTreatments?.find((t) => t.id === id) ||
      new PostHarvestPhytosanitaryTreatment().mapToClass({ id });
    setTabValue(0);
    setSelectedRowToEdit(postHarvestTreatment);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    postHarvestPhytoTreatmentDeleteMutate.mutate({});
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleConfirmWithConflictsAlertDialog = () => {
    cancelConfirmWithConflictsAlertDialog();
    formik.submitForm();
  };

  return (
    <>
      <LoadingWithDelay isVisible={isFetching} />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("treatments.backAlertTitle")}
        contentText={i18n.t("treatments.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("treatments.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("treatments.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={postHarvestPhytoTreatmentDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <FormAlertDialog
        id="confirmRecipeWithConflictsAlertDialog"
        title={i18n.t("recipes.confirmWithConflictsAlertDialogTitle")}
        contentText={i18n.t(
          "recipes.confirmWithConflictsAlertDialogDescription"
        )}
        confirmBtnText={i18n.t(
          "recipes.confirmWithConflictsAlertDialogConfirmBtnText"
        )}
        open={isOpenConfirmWithConflictsDialog}
        formAction={FormMode.DELETE}
        onCancel={cancelConfirmWithConflictsAlertDialog}
        onConfirm={handleConfirmWithConflictsAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("treatments.postHarvest.tableTitle")}
        headerText={i18n.t("treatments.postHarvest.headerText")}
        colums={postHarvestTreatmentsColumns}
        rows={postHarvestPhytoTreatments}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="startDate"
        emptyTableIcon={
          <PestControl sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("treatments.postHarvest.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("treatments.postHarvest.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("treatments.postHarvest.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          postHarvestPhytoTreatmentCreateMutate.isLoading ||
          postHarvestPhytoTreatmentEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <PostHarvestTreatmentForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          tabValue={tabValue}
          forceExpanded={expandCards}
          selectedEditRow={selectedRowToEdit}
          isSiexActive={isSiexActive}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onConflicts={setPlantProblemConflicts}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface PostHarvestTreatmentFormProps {
  formik: FormikProps<PostHarvestPhytosanitaryTreatment>;
  tabValue: number;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: PostHarvestPhytosanitaryTreatment;
  onCloseFilesForm?: () => void;
  onConflicts?: (conflicts: boolean) => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}
const PostHarvestTreatmentForm = (props: PostHarvestTreatmentFormProps) => {
  const {
    formik,
    selectedEditRow,
    isSiexActive,
    isOpenFilesForm = false,
    onCloseFilesForm,
    tabValue,
    onChangeTabValue,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const location = useLocation();
  const queryClient = useQueryClient();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  // Convert PhytoRecipe into PostHarvestPhytosanitaryTreatment
  const handleRecipeTreatment = (phytoRecipe: PhytoRecipe) => {
    const postHarvestTreatment = new PostHarvestPhytosanitaryTreatment();
    postHarvestTreatment.plantProblems = phytoRecipe.plantProblems;
    if (phytoRecipe.advisor) {
      const advisor = new PhytosanitaryTreatmentAdvisor().mapToClass({
        id: phytoRecipe.advisor.id,
        advisor: phytoRecipe.advisor,
      });
      if (advisor) {
        delete advisor.id;
        postHarvestTreatment.advisors = [advisor];
      }
    }
    postHarvestTreatment.justifications = phytoRecipe.justifications;
    const stockMovementObj: any = {};
    stockMovementObj.idx = 1; // Needed for React table
    stockMovementObj.date = phytoRecipe.date;
    stockMovementObj.product = phytoRecipe.product;
    stockMovementObj.registeredProduct = phytoRecipe.registeredProduct;
    if (phytoRecipe.registeredProduct)
      stockMovementObj.registeredProductMeasurementUnit = phytoRecipe.doseUnit;
    stockMovementObj.type = PhytosanitaryStockMovementType.TREATMENT;
    stockMovementObj.quantity = phytoRecipe.dose;
    const stockMovement = new PhytosanitaryStockMovement().mapToClass(
      stockMovementObj
    );
    if (stockMovement) postHarvestTreatment.products = [stockMovement];
    postHarvestTreatment.recipe = phytoRecipe;
    formik.setValues(postHarvestTreatment);
  };

  const { isFetching: isFetchingPhytoRecipe } = useFetch<PhytoRecipe>({
    queryKey: ["phytoRecipeById", recipeId],
    selected: { id: recipeId },
    enabled: !!recipeId && recipeType === PhytoRecipeType.POSTHARVEST,
    onSuccess: handleRecipeTreatment,
    onError,
  });

  const onFetchPostHarvestPhytosanitaryTreatmentSuccess = (
    postHarvestTreatment: PostHarvestPhytosanitaryTreatment
  ) => {
    formik.setValues(postHarvestTreatment);
    // Update in array without refetch
    queryClient.setQueryData<PostHarvestPhytosanitaryTreatment[]>(
      [
        "postHarvestPhytoTreatments",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      (oldData) => updateItemOfList(oldData, postHarvestTreatment, "id")
    );
  };

  const { isFetching: isUpdating } =
    useFetch<PostHarvestPhytosanitaryTreatment>({
      queryKey: ["postHarvestPhytosanitaryTreatment", selectedEditRow?.id],
      enabled:
        !!selectedEditRow?.id &&
        (!recipeId || recipeType !== PhytoRecipeType.POSTHARVEST),
      selected: selectedEditRow,
      onSuccess: onFetchPostHarvestPhytosanitaryTreatmentSuccess,
      onError,
    });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <TreatmentPostharvestTab {...props} />;
      case 1:
        return <TreatmentInvolvedEntitiesTab {...props} />;
      case 2:
        return <TreatmentAdvisorsTab {...props} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      {formik.values.recipe && (
        <Alert severity="info">
          {formik.status === FormMode.CREATE
            ? i18n.t("treatments.recipeAlertTextCreate")
            : i18n.t("treatments.recipeAlertTextEdit")}
        </Alert>
      )}
      <LoadingWithDelay isVisible={isUpdating || isFetchingPhytoRecipe} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <Tabs
        className="tab-container"
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.postHarvest.treatmentForm.tab.treatment")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.postHarvest.treatmentForm.tab.applicators")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.postHarvest.treatmentForm.tab.advisors")}
        />
      </Tabs>
      {renderTab()}
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t(
          "treatments.postHarvest.treatmentForm.filesFormSubtitle"
        )}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const TreatmentPostharvestTab = (props: PostHarvestTreatmentFormProps) => {
  const { formik, onConflicts } = props;

  const { agroBusinessPartitions } = useSession();

  const handleChangePlantProblems = (plantProblems: PlantProblem[]) => {
    formik.setFieldValue("plantProblems", plantProblems);
  };

  const handleChangeStockMovement = (
    stockMovement: PhytosanitaryStockMovement
  ) => {
    formik.setFieldValue("products", [stockMovement]);
  };

  return (
    <div>
      <TreatmentPostharvestProductForm {...props} />
      <TreatmentPostHarvestProductForm
        selected={formik.values.products?.[0]}
        plantProblems={formik.values.plantProblems}
        disabled={!!formik.values.recipe}
        postHarvestQuantity={formik.values.quantity}
        postHarvestUnit={formik.values.quantityUnit}
        onChangePlantProblems={handleChangePlantProblems}
        onChangeStockMovement={handleChangeStockMovement}
        onConflicts={onConflicts}
        onError={props.onError}
      />
      <TreatmentDatesForm {...props} />
      <TreatmentJustificationForm {...props} />
      <TreatmentResultForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const TreatmentPostharvestProductForm = (
  props: PostHarvestTreatmentFormProps
) => {
  const { formik, forceExpanded, isSiexActive, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: vegetableProducts, isFetching: isFetchingProducts } = useFetch<
    VegetableProduct[]
  >({
    queryKey: ["vegetableProducts", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount,
      onError,
    });

  return (
    <BrioCard
      title={i18n.t("treatments.postHarvest.treatmentForm.productForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="product"
            label={i18n.t(
              "treatments.postHarvest.treatmentForm.productForm.product"
            )}
            placeholder={i18n.t(
              "treatments.postHarvest.treatmentForm.productForm.productPlaceholder"
            )}
            options={vegetableProducts || []}
            optionLabelFieldName="name"
            required
            startIcon={Search}
            loading={isFetchingProducts}
            disabled={formik.status === FormMode.EDIT}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input"
            name="quantity"
            type="number"
            label={i18n.t(
              "treatments.postHarvest.treatmentForm.productForm.quantity"
            )}
            required={isSiexActive}
          />
          <FormikSelect
            formik={formik}
            type="row"
            name="quantityUnit"
            label={i18n.t(
              "treatments.postHarvest.treatmentForm.productForm.quantityUnit"
            )}
            optionLabelFieldName="name"
            options={measurementUnits || []}
            isLoading={isFetchingMeasurementUnits}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FacilitiesTab = () => {
  const facilityTutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("treatments.facility.tutorial.createFacility.label"),
      description: i18n.t(
        "treatments.facility.tutorial.createFacility.description"
      ),
      nextBtnText: i18n.t(
        "treatments.facility.tutorial.createFacility.nextBtnText"
      ),
    },
  ];
  const facilityTreatmentsColumns: Column[] = [
    {
      id: "facilitiesNames",
      label: i18n.t("treatments.facility.column.name_v2"),
      numeric: false,
    },
    {
      id: "dateRange",
      label: i18n.t("treatments.facility.column.dateRange"),
      numeric: false,
    },
    {
      id: "efficacy.name",
      label: i18n.t("treatments.facility.column.efficacy"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<
    FacilityPhytosanitaryTreatment | undefined
  >(undefined);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);
  const [
    isOpenConfirmWithConflictsDialog,
    setIsOpenConfirmWithConflictsDialog,
  ] = useState<boolean>(false);
  const [plantProblemConflicts, setPlantProblemConflicts] =
    useState<boolean>(false);

  const { data: facilitiesCount, isFetching: isFetchingFacilities } =
    useFetch<number>({
      queryKey: [
        "facilitiesCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
    });

  const { data: facilityPhytoTreatments, isFetching } = useFetch<
    FacilityPhytosanitaryTreatment[]
  >({
    queryKey: [
      "facilityPhytoTreatments",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const validateForm = (values: FacilityPhytosanitaryTreatment) => {
    const errors: any = {};
    if (!values.facilities || values.facilities.length === 0)
      errors.facilities = i18n.t("formErrors.requiredField");
    if (
      !values.products ||
      values.products.length === 0 ||
      (!values.products[0].product && !values.products[0].registeredProduct)
    )
      errors.products = i18n.t("formErrors.requiredField");
    if (
      values.products?.[0]?.registeredProduct &&
      !values.products?.[0]?.registeredProductMeasurementUnit
    )
      errors.products = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.plantProblems || values.plantProblems.length === 0)
        errors.plantProblems = i18n.t("formErrors.requiredField");
      if (!values.startDate)
        errors.startDate = i18n.t("formErrors.requiredField");
      if (!values.applicators || values.applicators.length === 0)
        errors.applicators = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: FacilityPhytosanitaryTreatment) => {
    switch (formMode) {
      case FormMode.CREATE:
        facilityPhytoTreatmentCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        facilityPhytoTreatmentEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<FacilityPhytosanitaryTreatment>({
    initialValues: new FacilityPhytosanitaryTreatment(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("treatments.facility.drawerTitleApply")
      : formik.status === FormMode.CREATE
      ? i18n.t("treatments.facility.drawerTitleCreate")
      : i18n.t("treatments.facility.drawerTitleEdit");
  const drawerSubtitle = i18n.t("treatments.facility.drawerSubtitle");
  const drawerBtnText =
    formik.values.recipe && formik.status === FormMode.CREATE
      ? i18n.t("words.apply")
      : formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from phyto treatment apply
  useEffect(() => {
    if (recipeId && recipeType === PhytoRecipeType.FACILITY)
      openDrawer(FormMode.CREATE);
  }, [recipeId, recipeType]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName ===
        FBEntitiesTypes.FACILITY_PHYTO_TREATMENT &&
      siexRegister.entityClassId &&
      facilityPhytoTreatments &&
      facilityPhytoTreatments.length > 0
    ) {
      const facilityTreatment =
        facilityPhytoTreatments?.find(
          (t) => t.id === siexRegister.entityClassId
        ) ||
        new FacilityPhytosanitaryTreatment().mapToClass({
          id: siexRegister.entityClassId,
        });
      setSelectedRowToEdit(facilityTreatment);
      setIsOpenDrawer(true);
    }
  }, [siexRegister, facilityPhytoTreatments]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setTabValue(0);
    setSelectedRowToEdit(undefined);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, {
      state: { recipeId, recipeType, siexRegister },
    });
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    setSelectedRowToEdit(undefined);
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}`);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const openConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(true);
  const cancelConfirmWithConflictsAlertDialog = () =>
    setIsOpenConfirmWithConflictsDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    setTabValue(0);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const facilityPhytoTreatmentCreateMutate =
    useCrud<FacilityPhytosanitaryTreatment>({
      key: "postFacilityPhytosanitaryTreatment",
      values: formik.values,
      onSuccess: (data: FacilityPhytosanitaryTreatment) => {
        // Update list
        queryClient.refetchQueries({
          queryKey: ["facilityPhytoTreatments"],
          type: "active",
        });
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.createSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const facilityPhytoTreatmentEditMutate =
    useCrud<FacilityPhytosanitaryTreatment>({
      key: "putFacilityPhytosanitaryTreatment",
      values: formik.values,
      onSuccess: (data: FacilityPhytosanitaryTreatment) => {
        // Update list
        queryClient.refetchQueries({
          queryKey: ["facilityPhytoTreatments"],
          type: "active",
        });
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.updateSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const facilityPhytoTreatmentDeleteMutate = useCrud({
    key: "deleteFacilityPhytosanitaryTreatments",
    values: facilityPhytoTreatments?.filter(
      (facilityPhytoTreatment: FacilityPhytosanitaryTreatment) =>
        selectedRowIds.includes(facilityPhytoTreatment?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["facilityPhytoTreatments"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setTabValue(
        Object.keys(errors).length === 1 && errors.applicators ? 1 : 0
      );
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else if (plantProblemConflicts && !formik.values.recipe)
      openConfirmWithConflictsAlertDialog();
    else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const facilityTreatment =
      facilityPhytoTreatments?.find((t) => t.id === id) ||
      new FacilityPhytosanitaryTreatment().mapToClass({ id });
    setTabValue(0);
    setSelectedRowToEdit(facilityTreatment);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    facilityPhytoTreatmentDeleteMutate.mutate({});
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleClickCreateFacility = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_FACILITIES}`);
  };

  const handleConfirmWithConflictsAlertDialog = () => {
    cancelConfirmWithConflictsAlertDialog();
    formik.submitForm();
  };

  return (
    <>
      <LoadingWithDelay isVisible={isFetching || isFetchingFacilities} />
      {(facilitiesCount || 0) <= 0 && (
        <SectionTutorial
          steps={facilityTutorialSteps}
          activeStep={0}
          onGo={handleClickCreateFacility}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("treatments.backAlertTitle")}
        contentText={i18n.t("treatments.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("treatments.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("treatments.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={facilityPhytoTreatmentDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <FormAlertDialog
        id="confirmRecipeWithConflictsAlertDialog"
        title={i18n.t("recipes.confirmWithConflictsAlertDialogTitle")}
        contentText={i18n.t(
          "recipes.confirmWithConflictsAlertDialogDescription"
        )}
        confirmBtnText={i18n.t(
          "recipes.confirmWithConflictsAlertDialogConfirmBtnText"
        )}
        open={isOpenConfirmWithConflictsDialog}
        formAction={FormMode.DELETE}
        onCancel={cancelConfirmWithConflictsAlertDialog}
        onConfirm={handleConfirmWithConflictsAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("treatments.facility.tableTitle")}
        headerText={i18n.t("treatments.facility.headerText")}
        colums={facilityTreatmentsColumns}
        rows={facilityPhytoTreatments}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="startDate"
        emptyTableIcon={
          <PestControl sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("treatments.facility.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("treatments.facility.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("treatments.facility.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          facilityPhytoTreatmentCreateMutate.isLoading ||
          facilityPhytoTreatmentEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <FacilityTreatmentForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          tabValue={tabValue}
          forceExpanded={expandCards}
          selectedEditRow={selectedRowToEdit}
          isSiexActive={isSiexActive}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onConflicts={setPlantProblemConflicts}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface FacilityTreatmentFormProps {
  formik: FormikProps<FacilityPhytosanitaryTreatment>;
  tabValue: number;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: FacilityPhytosanitaryTreatment;
  onCloseFilesForm?: () => void;
  onConflicts?: (conflicts: boolean) => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}
const FacilityTreatmentForm = (props: FacilityTreatmentFormProps) => {
  const {
    formik,
    selectedEditRow,
    isSiexActive,
    isOpenFilesForm = false,
    onCloseFilesForm,
    tabValue,
    onChangeTabValue,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const location = useLocation();
  const queryClient = useQueryClient();

  const recipeId = location.state?.recipeId;
  const recipeType = location.state?.recipeType;
  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  // Convert PhytoRecipe into FacilityPhytosanitaryTreatment
  const handleRecipeTreatment = (phytoRecipe: PhytoRecipe) => {
    const facilityTreatment = new FacilityPhytosanitaryTreatment();
    facilityTreatment.plantProblems = phytoRecipe.plantProblems;
    if (phytoRecipe.advisor) {
      const advisor = new PhytosanitaryTreatmentAdvisor().mapToClass({
        id: phytoRecipe.advisor.id,
        advisor: phytoRecipe.advisor,
      });
      if (advisor) {
        delete advisor.id;
        facilityTreatment.advisors = [advisor];
      }
    }
    facilityTreatment.justifications = phytoRecipe.justifications;
    const stockMovementObj: any = {};
    stockMovementObj.idx = 1; // Needed for React table
    stockMovementObj.date = phytoRecipe.date;
    stockMovementObj.product = phytoRecipe.product;
    stockMovementObj.registeredProduct = phytoRecipe.registeredProduct;
    if (phytoRecipe.registeredProduct)
      stockMovementObj.registeredProductMeasurementUnit = phytoRecipe.doseUnit;
    stockMovementObj.type = PhytosanitaryStockMovementType.TREATMENT;
    stockMovementObj.quantity = phytoRecipe.dose;
    const stockMovement = new PhytosanitaryStockMovement().mapToClass(
      stockMovementObj
    );
    if (stockMovement) facilityTreatment.products = [stockMovement];
    facilityTreatment.recipe = phytoRecipe;
    formik.setValues(facilityTreatment);
  };

  const { isFetching: isFetchingPhytoRecipe } = useFetch<PhytoRecipe>({
    queryKey: ["phytoRecipeById", recipeId],
    selected: { id: recipeId },
    enabled: !!recipeId && recipeType === PhytoRecipeType.FACILITY,
    onSuccess: handleRecipeTreatment,
    onError,
  });

  const onFetchFacilityPhytosanitaryTreatmentSuccess = (
    facilityTreatment: FacilityPhytosanitaryTreatment
  ) => {
    formik.setValues(facilityTreatment);
    // Update in array without refetch
    queryClient.setQueryData<FacilityPhytosanitaryTreatment[]>(
      [
        "facilityPhytoTreatments",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      (oldData) => updateItemOfList(oldData, facilityTreatment, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<FacilityPhytosanitaryTreatment>({
    queryKey: ["facilityPhytosanitaryTreatment", selectedEditRow?.id],
    enabled:
      !!selectedEditRow?.id &&
      (!recipeId || recipeType !== PhytoRecipeType.FACILITY),
    selected: selectedEditRow,
    onSuccess: onFetchFacilityPhytosanitaryTreatmentSuccess,
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <TreatmentFacilitiesTab {...props} />;
      case 1:
        return <TreatmentInvolvedEntitiesTab {...props} />;
      case 2:
        return <TreatmentAdvisorsTab {...props} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      {formik.values.recipe && (
        <Alert severity="info">
          {formik.status === FormMode.CREATE
            ? i18n.t("treatments.recipeAlertTextCreate")
            : i18n.t("treatments.recipeAlertTextEdit")}
        </Alert>
      )}
      <LoadingWithDelay isVisible={isUpdating || isFetchingPhytoRecipe} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <Tabs
        className="tab-container"
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={handleTabChange}
      >
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.facility.tab.treatment")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.facility.tab.applicators")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("treatments.facility.tab.advisors")}
        />
      </Tabs>
      {renderTab()}
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("treatments.facility.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const TreatmentFacilitiesTab = (props: FacilityTreatmentFormProps) => {
  const { formik, onConflicts } = props;

  const { agroBusinessPartitions } = useSession();

  const handleChangePlantProblems = (plantProblems: PlantProblem[]) => {
    formik.setFieldValue("plantProblems", plantProblems);
  };

  const handleChangeStockMovement = (
    stockMovement: PhytosanitaryStockMovement
  ) => {
    formik.setFieldValue("products", [stockMovement]);
  };

  return (
    <div>
      <TreatmentFacilityForm {...props} />
      <TreatmentFacilityProductForm
        selected={formik.values.products?.[0]}
        plantProblems={formik.values.plantProblems}
        disabled={!!formik.values.recipe}
        onChangePlantProblems={handleChangePlantProblems}
        onChangeStockMovement={handleChangeStockMovement}
        onConflicts={onConflicts}
        onError={props.onError}
      />
      <TreatmentDatesForm {...props} />
      <TreatmentJustificationForm {...props} />
      <TreatmentResultForm {...props} />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </div>
  );
};

const TreatmentFacilityForm = (props: FacilityTreatmentFormProps) => {
  const treatmentFacilityColumns: Column[] = [
    {
      id: "facility.name",
      label: i18n.t("treatments.facility.treatmentForm.column.facility"),
      numeric: false,
    },
    {
      id: "treatedVolume",
      label: i18n.t("treatments.facility.treatmentForm.column.treatedVolume"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "m3",
    },
  ];

  const { formik, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedFacility, setSelectedFacility] =
    useState<FacilityPhytosanitaryTreatmentFacility | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedFacility(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit
  const handleConfirmDialog = (
    selected: FacilityPhytosanitaryTreatmentFacility
  ) => {
    const updatedFacilities =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.facilities,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.facilities, selected, "idx");
    formik.setFieldValue("facilities", updatedFacilities);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.facilities?.find(
      (facility: FacilityPhytosanitaryTreatmentFacility) => facility.idx === idx
    );
    if (selected) {
      setSelectedFacility(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "facilities",
      deleteItemsOfListByField(formik.values.facilities, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("treatments.facility.treatmentForm.tableTitle")}
        headerText={i18n.t("treatments.facility.treatmentForm.headerText")}
        btnText={i18n.t("treatments.facility.treatmentForm.tableBtnText")}
        colums={treatmentFacilityColumns}
        rows={formik.values.facilities || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        required
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogTreatmentFacility
        open={isOpenDialog}
        selected={selectedFacility}
        treatmentFacilities={formik.values.facilities}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
        onError={onError}
      />
    </>
  );
};

const PreventiveActionTab = () => {
  const preventiveActionsColumns: Column[] = [
    {
      id: "actionType.name",
      label: i18n.t("treatments.preventiveAction.column.name"),
      numeric: false,
    },
    {
      id: "effectiveness.name",
      label: i18n.t("treatments.preventiveAction.column.efficacy"),
      numeric: false,
    },
  ];
  const PreventiveActionValidatorSchema = Yup.object().shape({
    actionType: Yup.object().required(),
    effectiveness: Yup.object().required(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<
    GipPreventiveAction | undefined
  >(undefined);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: gipPreventiveActions, isFetching } = useFetch<
    GipPreventiveAction[]
  >({
    queryKey: [
      "gipPreventiveActions",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const handleSubmit = (values: GipPreventiveAction) => {
    switch (formMode) {
      case FormMode.CREATE:
        gipPreventiveActionCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        gipPreventiveActionEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<GipPreventiveAction>({
    initialValues: new GipPreventiveAction(),
    validationSchema: PreventiveActionValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("treatments.preventiveAction.drawerTitleCreate")
      : i18n.t("treatments.preventiveAction.drawerTitleEdit");
  const drawerSubtitle = i18n.t("treatments.preventiveAction.drawerSubtitle");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setSelectedRowToEdit(undefined);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const gipPreventiveActionCreateMutate = useCrud<GipPreventiveAction>({
    key: "postGipPreventiveAction",
    values: formik.values,
    onSuccess: (data: GipPreventiveAction) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["gipPreventiveActions"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const gipPreventiveActionEditMutate = useCrud<GipPreventiveAction>({
    key: "putGipPreventiveAction",
    values: formik.values,
    onSuccess: (data: GipPreventiveAction) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["gipPreventiveActions"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const gipPreventiveActionDeleteMutate = useCrud({
    key: "deleteGipPreventiveActions",
    values: gipPreventiveActions?.filter(
      (gipPreventiveAction: GipPreventiveAction) =>
        selectedRowIds.includes(gipPreventiveAction?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["gipPreventiveActions"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const preventiveAction: GipPreventiveAction | undefined =
      gipPreventiveActions?.find((pa) => pa.id === id);
    if (preventiveAction) {
      setSelectedRowToEdit(preventiveAction);
      formik.setStatus(FormMode.EDIT);
      setFormMode(FormMode.EDIT);
      openDrawer(FormMode.EDIT);
    }
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    gipPreventiveActionDeleteMutate.mutate({});
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isFetching} />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("treatments.backAlertTitle")}
        contentText={i18n.t("treatments.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("treatments.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("treatments.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={gipPreventiveActionDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("treatments.preventiveAction.tableTitle")}
        headerText={i18n.t("treatments.preventiveAction.headerText")}
        colums={preventiveActionsColumns}
        rows={gipPreventiveActions}
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <PestControl sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("treatments.preventiveAction.emptyTableTitle")}
        emptyTableSubtitle={i18n.t(
          "treatments.preventiveAction.emptyTableSubtitle"
        )}
        emptyTableBtnText={i18n.t(
          "treatments.preventiveAction.emptyTableBtnText"
        )}
        addBtnVariant="contained"
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          gipPreventiveActionCreateMutate.isLoading ||
          gipPreventiveActionEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <PreventiveActionForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          gipPreventiveActions={gipPreventiveActions || []}
          forceExpanded={expandCards}
          selectedEditRow={selectedRowToEdit}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface PreventiveActionFormProps {
  formik: FormikProps<GipPreventiveAction>;
  gipPreventiveActions: GipPreventiveAction[];
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: GipPreventiveAction;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const PreventiveActionForm = (props: PreventiveActionFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const onFetchGipPreventiveActionSuccess = (
    preventiveAction: GipPreventiveAction
  ) => {
    formik.setValues(preventiveAction);
    // Update in array without refetch
    queryClient.setQueryData<GipPreventiveAction[]>(
      ["gipPreventiveActions", selectedABAccount?.id, selectedABPartition?.id],
      (oldData) => updateItemOfList(oldData, preventiveAction, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<GipPreventiveAction>({
    queryKey: ["gipPreventiveAction", selectedEditRow?.id],
    enabled: !!selectedEditRow?.id,
    selected: selectedEditRow,
    onSuccess: onFetchGipPreventiveActionSuccess,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <PreventiveActionDataForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("treatments.preventiveAction.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const PreventiveActionDataForm = (props: PreventiveActionFormProps) => {
  const { formik, gipPreventiveActions, forceExpanded, onError } = props;

  const { selectedABAccount } = useSession();

  const [
    gipPreventiveActionTypesFiltered,
    setGipPreventiveActionTypesFiltered,
  ] = useState<GipPreventiveActionType[]>([]);

  // Filter added preventive action types
  const onPreventiveActionTypesResponse = (
    preventiveActionTypes: GipPreventiveActionType[]
  ) => {
    const preventiveActions = preventiveActionTypes.filter(
      (pat) =>
        !gipPreventiveActions.find((pa) => pa.actionType?.code === pat.code)
    );
    setGipPreventiveActionTypesFiltered(preventiveActions);
  };

  const { isFetching: isFetchingPreventiveActionTypes } = useFetch<
    GipPreventiveActionType[]
  >({
    queryKey: ["gipPreventiveActionTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onSuccess: onPreventiveActionTypesResponse,
    onError,
  });

  const {
    data: treatmentEfficacyTypes,
    isFetching: isFetchingTreatmentEfficacyTypes,
  } = useFetch<TreatmentEfficacy[]>({
    queryKey: ["treatmentEfficacyTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  // Add selected preventive action to the filtered list in edit mode
  useEffect(() => {
    if (formik.values.actionType && formik.status === FormMode.EDIT)
      setGipPreventiveActionTypesFiltered([
        formik.values.actionType,
        ...gipPreventiveActionTypesFiltered,
      ]);
  }, [formik.values.actionType]);

  return (
    <BrioCard
      title={i18n.t("treatments.preventiveAction.dataForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            className="form-input"
            formik={formik}
            name="actionType"
            label={i18n.t("treatments.preventiveAction.dataForm.actionType")}
            required
            options={gipPreventiveActionTypesFiltered || []}
            optionLabelFieldName="name"
            isLoading={isFetchingPreventiveActionTypes}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            className="form-input"
            formik={formik}
            name="effectiveness"
            label={i18n.t("treatments.preventiveAction.dataForm.effectiveness")}
            required
            options={treatmentEfficacyTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetchingTreatmentEfficacyTypes}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatedSeedTab = () => {
  const treatedSeedTutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("treatments.treatedSeed.tutorial.createCrop.label"),
      description: i18n.t(
        "treatments.treatedSeed.tutorial.createCrop.description"
      ),
      nextBtnText: i18n.t(
        "treatments.treatedSeed.tutorial.createCrop.nextBtnText"
      ),
    },
  ];
  const treatedSeedColumns: Column[] = [
    {
      id: "crop.productNameWithVarieties",
      label: i18n.t("treatments.treatedSeed.column.crop"),
      numeric: false,
    },
    {
      id: "dateFormatted",
      label: i18n.t("treatments.treatedSeed.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "sowingArea",
      label: i18n.t("treatments.treatedSeed.column.sowingArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
    {
      id: "efficacy.name",
      label: i18n.t("treatments.treatedSeed.column.efficacy"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const [expandCards, forceExpandCards] = useExpandCards();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<
    TreatedSeed | undefined
  >(undefined);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: cropsCount, isFetching: isFetchingCrops } = useFetch<number>({
    queryKey: ["cropsCount", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  const { data: treatedSeeds, isFetching } = useFetch<TreatedSeed[]>({
    queryKey: ["treatedSeeds", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const validateForm = (values: TreatedSeed) => {
    const errors: any = {};
    if (!values.crop) errors.crop = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.treatmentType)
        errors.treatmentType = i18n.t("formErrors.requiredField");
      if (!values.date) errors.date = i18n.t("formErrors.requiredField");
      if (!values.quantity)
        errors.quantity = i18n.t("formErrors.requiredField");
      if (values.isTreatedInSitu() && !values.productMovement?.product)
        errors["productMovement.product"] = i18n.t("formErrors.requiredField");
      if (values.isAcquiredSeed() && !values.lotNumber)
        errors.lotNumber = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: TreatedSeed) => {
    switch (formMode) {
      case FormMode.CREATE:
        treatedSeedCreateMutate.mutate({});
        break;
      case FormMode.EDIT:
        treatedSeedEditMutate.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<TreatedSeed>({
    initialValues: new TreatedSeed(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("treatments.treatedSeed.drawerTitleCreate")
      : i18n.t("treatments.treatedSeed.drawerTitleEdit");
  const drawerSubtitle = i18n.t("treatments.treatedSeed.drawerSubtitle");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.TREATED_SEED &&
      siexRegister.entityClassId &&
      treatedSeeds &&
      treatedSeeds.length > 0
    ) {
      const treatedSeed =
        treatedSeeds?.find((ts) => ts.id === siexRegister.entityClassId) ||
        new TreatedSeed().mapToClass({ id: siexRegister.entityClassId });
      setSelectedRowToEdit(treatedSeed);
      setIsOpenDrawer(true);
    }
  }, [siexRegister, treatedSeeds]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (
        !isOpenFilesForm &&
        isOpenDrawer &&
        formik.dirty &&
        formik.status === FormMode.CREATE
      ) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, isOpenFilesForm, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    setSelectedRowToEdit(undefined);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, { state: { siexRegister } });
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const treatedSeedCreateMutate = useCrud<TreatedSeed>({
    key: "postTreatedSeed",
    values: formik.values,
    onSuccess: (data: TreatedSeed) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["treatedSeeds"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const treatedSeedEditMutate = useCrud<TreatedSeed>({
    key: "putTreatedSeed",
    values: formik.values,
    onSuccess: (data: TreatedSeed) => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["treatedSeeds"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const treatedSeedDeleteMutate = useCrud({
    key: "deleteTreatedSeeds",
    values: treatedSeeds?.filter((treatedSeed: TreatedSeed) =>
      selectedRowIds.includes(treatedSeed?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["treatedSeeds"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const treatedSeed =
      treatedSeeds?.find((ts) => ts.id === id) ||
      new TreatedSeed().mapToClass({ id });
    setSelectedRowToEdit(treatedSeed);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    treatedSeedDeleteMutate.mutate({});
  };

  const handleClickCreateCrop = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isFetching || isFetchingCrops} />
      {(cropsCount || 0) <= 0 && (
        <SectionTutorial
          steps={treatedSeedTutorialSteps}
          activeStep={0}
          onGo={handleClickCreateCrop}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("treatments.backAlertTitle")}
        contentText={i18n.t("treatments.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("treatments.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("treatments.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={treatedSeedDeleteMutate.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("treatments.treatedSeed.tableTitle")}
        headerText={i18n.t("treatments.treatedSeed.headerText")}
        colums={treatedSeedColumns}
        rows={treatedSeeds}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="date"
        emptyTableIcon={
          <PestControl sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("treatments.treatedSeed.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("treatments.treatedSeed.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("treatments.treatedSeed.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        subtitle={drawerSubtitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          treatedSeedCreateMutate.isLoading || treatedSeedEditMutate.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <TreatedSeedForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selectedRowToEdit}
          isSiexActive={isSiexActive}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </>
  );
};

interface TreatedSeedFormProps {
  formik: FormikProps<TreatedSeed>;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: TreatedSeed;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const TreatedSeedForm = (props: TreatedSeedFormProps) => {
  const {
    formik,
    selectedEditRow,
    isSiexActive,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const location = useLocation();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const onFetchTreatedSeedSuccess = (treatedSeed: TreatedSeed) => {
    formik.setValues(treatedSeed);
    // Update in array without refetch
    queryClient.setQueryData<TreatedSeed[]>(
      ["treatedSeeds", selectedABAccount?.id, selectedABPartition?.id],
      (oldData) => updateItemOfList(oldData, treatedSeed, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<TreatedSeed>({
    queryKey: ["treatedSeed", selectedEditRow?.id],
    enabled: !!selectedEditRow?.id,
    selected: selectedEditRow,
    onSuccess: onFetchTreatedSeedSuccess,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <TreatedSeedDataForm {...props} />
      {formik.values.isTreatedInSitu() && (
        <SeedPhytosanitaryProductForm {...props} />
      )}
      <TreatmentResultForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("treatments.treatedSeed.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const TreatedSeedDataForm = (props: TreatedSeedFormProps) => {
  const { formik, forceExpanded, isSiexActive, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const { data: crops, isFetching: isFetchingCrops } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const { data: seedTreatmentTypes, isFetching: isFetchingSeedTreatmentTypes } =
    useFetch<SeedTreatmentType[]>({
      queryKey: ["seedTreatmentTypes", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount,
      onError,
    });

  // Set default treatment type (code === 2)
  useEffect(() => {
    if (!formik.values?.treatmentType) {
      const defaultTreatmentType = seedTreatmentTypes?.find(
        (stt: SeedTreatmentType) => stt.code === "2"
      );
      if (defaultTreatmentType)
        formik.setFieldValue("treatedSeed.treatmentType", defaultTreatmentType);
    }
  }, [seedTreatmentTypes]);

  const handleClickNewOption = () =>
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);

  const handleChangeDate = (date: any) => {
    const dateStr = date?.format("YYYY-MM-DD");
    if (dateStr) {
      formik.setFieldValue("date", dateStr);
      formik.setFieldValue("dateFormatted", dateConverter({ date: dateStr }));
    }
  };

  return (
    <BrioCard
      title={i18n.t("treatments.treatedSeed.dataForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          {!isFetchingCrops && crops?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewOption}>
              {i18n.t("treatments.treatedSeed.dataForm.emptyList")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="crop"
            label={i18n.t("treatments.treatedSeed.dataForm.crop")}
            placeholder={i18n.t(
              "treatments.treatedSeed.dataForm.cropPlaceholder"
            )}
            options={crops || []}
            optionLabelFieldName="productNameWithVarieties"
            required
            startIcon={Search}
            loading={isFetchingCrops}
            addNewOption
            onClickNewOption={handleClickNewOption}
            disabled={formik.status === FormMode.EDIT}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="sowingArea"
            label={i18n.t("treatments.treatedSeed.dataForm.sowingArea")}
            helperText={i18n.t(
              "treatments.treatedSeed.dataForm.sowingAreaHelperText"
            )}
            type="number"
            valueUnit="ha"
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="treatmentType"
            label={i18n.t("treatments.treatedSeed.dataForm.treatmentType")}
            optionLabelFieldName="name"
            options={seedTreatmentTypes || []}
            isLoading={isFetchingSeedTreatmentTypes}
            required={isSiexActive}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={`${i18n.t("treatments.treatedSeed.dataForm.date")}${
              isSiexActive ? "*" : ""
            }`}
            value={
              formik.values?.date
                ? moment(formik.values?.date, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeDate}
          />
          {formik.touched.date && formik.errors.date && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="lotNumber"
            label={i18n.t("treatments.treatedSeed.dataForm.lotNumber")}
            required={isSiexActive && formik.values?.isAcquiredSeed()}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="quantity"
            label={i18n.t("treatments.treatedSeed.dataForm.quantity_v2")}
            type="number"
            valueUnit="Kg"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const SeedPhytosanitaryProductForm = (props: TreatedSeedFormProps) => {
  const { formik, isSiexActive, forceExpanded, onError } = props;
  const { productMovement } = formik.values;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const {
    data: phytosanitaryProducts,
    isFetching: isFetchingPhytosanitaryProducts,
  } = useFetch<PhytosanitaryProduct[]>({
    queryKey: [
      "phytosanitaryProducts",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: formik.values?.isTreatedInSitu(),
    onError,
  });

  const { data: phytosanitaryProductStock, isFetching: isFetchingStock } =
    useFetch<any>({
      queryKey: ["phytosanitaryProductStock", productMovement?.product?.id],
      selected: productMovement?.product,
      enabled: !!productMovement?.product?.id,
      onError,
    });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  // Only positive numbers
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = Number(event.target.value);
    formik.setFieldValue("productMovement.quantity", Math.abs(quantity || 0));
  };

  const handleChangeProduct = (option: PhytosanitaryProduct) => {
    if (option) {
      const newStockMovement = new PhytosanitaryStockMovement().mapToClass({
        id: productMovement?.id,
        product: option,
        type: PhytosanitaryStockMovementType.TREATMENT,
      });
      formik.setFieldValue("productMovement", newStockMovement);
    }
  };

  const handleClickCreateProduct = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}`);
  };

  const handleClickNew = () => openRedirectAlertDialog();

  // If editing, ignore saved quantity to calculate the stock
  const resultStock =
    Math.round(
      ((phytosanitaryProductStock?.stock || 0) -
        (productMovement?.quantity || 0) +
        (formik.status === FormMode.EDIT
          ? productMovement?.beforeQuantity || 0
          : 0)) *
        100
    ) / 100;

  return (
    <BrioCard
      title={i18n.t("treatments.treatedSeed.productForm.title")}
      defaultExpanded={isSiexActive}
      forceExpanded={forceExpanded}
      required={isSiexActive}
      optional={!isSiexActive}
    >
      <FormGroup className="form-group">
        <FormAlertDialog
          id="redirectAlertDialog"
          title={i18n.t(
            "treatments.treatedSeed.productForm.redirectAlertDialogTitle"
          )}
          contentText={i18n.t(
            "treatments.treatedSeed.productForm.redirectAlertDialogDescription"
          )}
          open={isOpenRedirectAlertDialog}
          onCancel={cancelRedirectAlertDialog}
          onConfirm={handleConfirmRedirectAlertDialog}
        />
        <FormControl className="form-control">
          {!isFetchingPhytosanitaryProducts &&
            phytosanitaryProducts?.length === 0 && (
              <AlertEmptyList onClick={handleClickCreateProduct}>
                {i18n.t("treatments.treatedSeed.productForm.emptyList")}
              </AlertEmptyList>
            )}
          <FormikAutocomplete
            formik={formik}
            name="productMovement.product"
            label={i18n.t("treatments.treatedSeed.productForm.product")}
            placeholder={i18n.t(
              "treatments.treatedSeed.productForm.productPlaceholder"
            )}
            options={phytosanitaryProducts || []}
            optionLabelFieldName="name"
            startIcon={Search}
            loading={isFetchingPhytosanitaryProducts}
            addNewOption
            required={isSiexActive}
            onClickNewOption={handleClickNew}
            onChange={handleChangeProduct}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input-row-40"
            name="productMovement.quantity"
            label={i18n.t("treatments.treatedSeed.productForm.quantity")}
            type="number"
            valueUnit={productMovement?.product?.measurementUnit?.name}
            required={!!productMovement?.product}
            disabled={!productMovement?.product}
            onChange={handleChangeQuantity}
          />
          <TextField
            id="resultStock"
            name="resultStock"
            disabled
            className="form-input-row-60"
            variant="outlined"
            type="number"
            label={i18n.t("treatments.treatedSeed.productForm.resultStock")}
            InputProps={{
              endAdornment: isFetchingStock ? (
                <CircularProgress size={20} />
              ) : (
                <InputAdornment position="end">
                  {productMovement?.product?.measurementUnit?.name || ""}
                </InputAdornment>
              ),
            }}
            value={resultStock}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
